// services
import {accountService} from '../services/account.service';
// utils
import {Role} from './role';

export function getAccountManagers(query, key, currentAM, AMs) {
  if (AMs.length > 0 && [Role.Admin].includes(accountService.userValue.role)) {
    let currentUser = currentAM;
    if (query[key]) {
      currentUser = AMs.filter((manager) => manager.email === query[key]);
    }
    return currentUser;
  }
}
