import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  infoPage: {
    '& > h4': {
      paddingBottom: '70px',
    },
    padding: '80px 50px',
    '& a': {
      color: theme.palette.secondary.main,
    },
    '& ol': {
      margin: '10px 0 ',
    },
    '& ol > li': {
      paddingBottom: '10px',
    },
    '& h6': {
      padding: '15px 0 10px 0',
      color: theme.palette.secondary.main,
    },
    '& p': {
      padding: '5px 0',
    },
    [theme.breakpoints.down(767)]: {
      padding: '80px 20px',
    },
  },
  homePage: {
    background: theme.palette.mode === 'dark' ? theme.palette.background.default : '#f9f9f9',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '100px 30px 0 30px!important',
    [theme.breakpoints.down(767)]: {
      padding: '60px 20px 0 20px!important',
    },
    '& > div': {
      maxWidth: '1600px',
    },
    '& .header': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      '& > h2': {
        padding: '50px 0',
        color: theme.palette.secondary.main,
        fontWeight: 500,
      },
      '& span': {
        width: '100%',
        display: 'block',
      },
      '& > span': {
        fontSize: '12px',
      },
      '& button': {
        margin: '30px 0 10px 0',
      },
      '& .cards': {
        padding: '60px 0',
        width: '100%',
        maxWidth: '1200px',
        '& img': {
          width: '100%',
          maxWidth: '300px',
        },
      },
      [theme.breakpoints.down(680)]: {
        '& > h2': {
          fontSize: '42px',
        },
      },
      [theme.breakpoints.down(400)]: {
        '& > h2': {
          fontSize: '32px',
          padding: '20px 0 30px 0',
        },
      },
    },
    '& .section-1': {
      padding: '50px 0',
      maxWidth: '1200px',
      '& h5': {
        fontSize: '65px',
        color: theme.palette.secondary.main,
      },
      '& > div': {
        padding: '10px 25px',
      },
      '& > div:first-of-type h6': {
        fontSize: '22px',
      },
      '& > div:last-of-type h6': {
        fontSize: '18px',
      },
      '& span': {
        fontSize: '12px',
      },
      [theme.breakpoints.down(900)]: {
        textAlign: 'center',
      },
      [theme.breakpoints.down(680)]: {
        padding: '0px 0 50px 0',
        '& h5': {
          fontSize: '42px',
        },
        '& > div:first-of-type h6': {
          fontSize: '18px',
        },
        '& > div:last-of-type h6': {
          fontSize: '15px',
        },
        '& > div': {
          padding: '10px 0px',
        },
      },
    },
    '& .section-2': {
      margin: '40px 0',
      padding: '10px 10px',
      display: 'flex',
      justifyContent: 'space-between',
      background: theme.palette.mode === 'dark' ? '#cbcbcb' : '#eeeeee',
      alignItems: 'center',
      textAlign: 'center',
      '& img': {
        width: '100%',
        maxWidth: '140px',
      },
      [theme.breakpoints.down(900)]: {
        margin: '20px 0',
        '& img': {
          width: '100%',
          maxWidth: '80px',
        },
      },
    },
    '& .section-3': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '30px 0 90px 0',
      '& > div:first-of-type': {
        padding: '50px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& h4, & h5, & h6': {
          padding: '10px 0 10px 0',
        },
        '& h4': {
          fontSize: '65px',
          color: theme.palette.secondary.main,
        },
        '& h5': {
          fontSize: '22px',
        },
        '& h6': {
          fontSize: '20px',
        },
      },
      '& > div:last-of-type': {
        width: '100%',
        maxWidth: '1000px',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '30px 0',
        '& img': {
          width: '100%',
          maxWidth: '300px',
          height: '100%',
          maxHeight: '300px',
        },
        '&  p': {
          padding: '10px',
        },
      },
      [theme.breakpoints.down(900)]: {
        textAlign: 'center',
      },
      [theme.breakpoints.down(767)]: {
        '& > div:first-of-type': {
          padding: '20px 0',
          '& h4, & h5, & h6': {
            padding: '10px 0 10px 0',
          },
          '& h4': {
            fontSize: '40px!important',
          },
          '& h5': {
            fontSize: '18px!important',
          },
          '& h6': {
            fontSize: '15px!important',
          },
        },
        '& > div:last-of-type': {
          '& img': {
            height: 'auto!important',
            maxHeight: 'auto!important',
          },
        },
      },
    },
    '& .section-4': {
      padding: '50px 30px',
      background: theme.palette.mode === 'dark' ? '#cbcbcb' : '#eeeeee',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#212121',
      '& img': {
        padding: '20px',
      },
      [theme.breakpoints.down(680)]: {
        textAlign: 'center',
        '& p': {
          padding: '10px 0',
        },
      },
    },
    '& .section-5': {
      padding: '80px 0',
      display: 'flex',
      justifyContent: 'center',
      '& > div:last-of-type': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: '50px 0',
        '& h4, & h5, & h6': {
          padding: '10px 0 10px 0',
        },
        '& h5': {
          fontSize: '40px',
          color: theme.palette.secondary.main,
        },
        '& h6': {
          fontSize: '25px',
        },
      },
      '& > div:first-of-type, & > div:last-of-type': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: '50px 0',
        '& h4, & h5, & h6': {
          padding: '10px 0 10px 0',
        },
        '& h4': {
          fontSize: '40px',
          color: theme.palette.secondary.main,
        },
        '& h5': {
          fontSize: '22px',
        },
        '& h6': {
          fontSize: '18px',
        },
      },

      '& .cards': {
        width: '100%',
        maxWidth: '1000px',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '30px 0',
        '& img': {
          width: '100%',
          maxWidth: '300px',
        },
        '& h5': {
          fontSize: '20px',
          color: theme.palette.secondary.main,
          padding: '10px 0',
        },
        '& h6': {
          fontSize: '15px',
        },
      },
      [theme.breakpoints.down(767)]: {
        '& > div:last-of-type': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          padding: '20px 0',
          '& h4, & h5, & h6': {
            padding: '10px 0 10px 0',
          },
          '& h5': {
            fontSize: '32px!important',
          },
          '& h6': {
            fontSize: '20px!important',
          },
        },
        '& > div:first-of-type': {
          padding: '20px 0 10px 0',
          '& h4, & h5, & h6': {
            padding: '10px 0 10px 0',
          },
          '& h4': {
            fontSize: '30px!important',
          },
          '& h5': {
            fontSize: '18px!important',
          },
          '& h6': {
            fontSize: '15px!important',
          },
        },
        '& .cards': {
          '& h5': {
            fontSize: '18px!important',
            padding: '5px 0!important',
          },
          '& h6': {
            fontSize: '12px!important',
          },
        },
      },
    },
    '& .section-6': {
      width: '100%',
      padding: '0 0 50px 0',
      display: 'flex',
      justifyContent: 'center',
      '& .card': {
        textAlign: 'center',
        '& .MuiCardContent-root': {
          '& > h4': {
            fontSize: '52px',
          },
          '& > h4:before': {
            content: "'$'",
            fontSize: '25px',
            position: 'relative',
            top: '-15px',
          },
          '& h4:after': {
            content: "'99'",
            fontSize: '25px',
            position: 'relative',
            top: '-15px',
          },
        },
        '& .MuiCardHeader-content': {
          padding: '10px 15px',
          color: theme.palette.secondary.main,
          fontWeight: 800,
        },
        '& .MuiList-root': {
          padding: '40px 0 20px 0',
          '& .MuiListItemIcon-root': {
            color: theme.palette.secondary.main,
          },
        },
        '& .MuiCardActions-root': {
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0 40px 0',
        },
      },
      [theme.breakpoints.down(767)]: {
        '& .MuiCardHeader-content span': {
          fontSize: '20px!important',
        },
      },
    },
    '& .section-7': {
      padding: '80px 0 70px 0',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      '& h5': {
        fontSize: '40px',
        width: '100%',
        color: theme.palette.secondary.main,
      },
      '& h6': {
        fontSize: '25px',
        width: '100%',
      },
      '& > div': {
        margin: '40px 0',
        border: '1px solid #646464',
        borderRadius: '5px',
        width: '100%',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px',
          height: '10px',
        },
        '&::-webkit-scrollbar-track': {
          background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#999',
          borderRadius: '3px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#777',
        },
        '& table': {
          minWidth: '800px',
        },
        '& .MuiTableHead-root th': {
          background: theme.palette.background.paper,
          color: theme.palette.secondary.main,
          fontWeight: 700,
        },
      },
      '& .custom-cell': {
        background: theme.palette.background.paper,
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
          fontSize: '24px',
        },
        '& button': {
          display: 'flex',
          flexDirection: 'column',
          '& span': {
            display: 'block',
            width: '100%',
            fontSize: '10px',
          },
        },
      },
      [theme.breakpoints.down(767)]: {
        padding: '20px 0',
        '& h5': {
          fontSize: '32px',
        },
        '& h6': {
          fontSize: '20px',
          width: '100%',
        },
      },
    },
    '& .section-8': {
      padding: '0 0 50px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& h4, & h6': {
        padding: '10px 0 0 0',
        textAlign: 'center',
      },

      '& h4': {
        fontSize: '50px',
        width: '100%',
        color: theme.palette.secondary.main,
      },
      '& h6': {
        fontSize: '25px',
        width: '100%',
      },
      '& .collapse': {
        margin: '50px 0',
        width: '100%',
        '& .MuiAccordionSummary-content > p': {
          color: theme.palette.secondary.main,
        },
      },
      [theme.breakpoints.down(767)]: {
        padding: '0 0 30px 0',
        '& h4': {
          fontSize: '32px',
        },
        '& h6': {
          fontSize: '20px',
          width: '100%',
        },
      },
    },
    '& .section-9': {
      padding: '10px 0 100px 0',
      '& > div:first-of-type': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      '& h4, & h5, & h6': {
        padding: '15px 0 10px 0',
      },
      '& h3': {
        fontSize: '58px',
        fontWeight: 700,
        color: theme.palette.secondary.main,
      },
      '& h4': {
        fontsize: '40px',
        fontWeight: 700,
        color: theme.palette.secondary.main,
      },
      '& h5': {
        fontsize: '28px',
      },
      '& h6': {
        fontsize: '20px',
      },
      '& button': {
        marginTop: '30px',
      },
      '& span': {
        width: '100%',
        fontSize: '14px',
        display: 'block',
      },
      [theme.breakpoints.down(767)]: {
        padding: '0 0 40px 0',
        textAlign: 'center',
        '& h4, & h5, & h6': {
          padding: '10px 0 10px 0',
        },
        '& h3': {
          fontSize: '42px',
        },
        '& h4': {
          fontsize: '30px',
        },
        '& h5': {
          fontsize: '22px',
        },
        '& h6': {
          fontsize: '16px',
        },
        '& button': {
          marginTop: '20px',
        },
        '& span': {
          fontSize: '12px',
        },
      },
    },
    '& .section-10': {
      '&, & > div': {
        padding: '10px 7px',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        '& ol': {
          display: 'flex',
          justifyContent: 'center',
        },
      },
      '& > div:last-of-type': {
        justifyContent: 'flex-end',
        '& a': {
          padding: '0 2px',
        },
        '& svg': {
          color: theme.palette.mode === 'dark' ? '#c5c5c5' : '#7e7e7e',
        },
      },
      '& > div p': {
        fontSize: '14px',
      },
      '& > div li': {
        fontSize: '16px',
      },
      [theme.breakpoints.down('lg')]: {
        '& > div:last-of-type': {
          justifyContent: 'center',
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& > div': {
          padding: '5px',
        },
      },
    },
  },
}));

export {useStyles};
