import {useEffect, useRef} from 'react';
import * as Yup from 'yup';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useHistory, useLocation} from 'react-router-dom';
// components
import Logo from '../../assets/images/ECOM3K_logo.png';
import ECOM3KRegistrationView from '../../components/LoginView/ecom3kRegistrationView';
import {CustomAlert} from '../Login/components/CustomAlert';
// utils
import {withTracker} from '../../utils/withTracker';
import {Api} from '../../utils/api';
// services
import mixPanel from '../../services/mixpanel';
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';

declare global {
  interface Window {
    grecaptcha: any;
    LinkMink: any;
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  loginPaper: {
    textAlign: 'center',
    padding: '1em 2em 3em 2em',
    margin: '2em 0em',
    maxWidth: '600px',
    width: '100%',
  },
  inputField: {
    margin: '0.5em',
    width: '95%',
    '& .MuiInputLabel-shrink': {
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main,
    },
  },
  loginButton: {
    margin: '0.3em',
  },
  forgotPwButton: {
    marginTop: '1.5em',
  },
  logoImg: {
    width: '15em',
    margin: '2em',
  },
  image: {
    width: '100%',
    maxWidth: '600px',
  },
  grid: {
    padding: '0 65px 0 0',
    minHeight: 'calc(90vh - 70px)',
    maxWidth: '100%',
    '& > .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
      padding: ' 0 25px',
      textAlign: 'center',
    },
    [theme.breakpoints.down(1024)]: {
      padding: '0 15px',
    },
    [theme.breakpoints.down(900)]: {
      '& > .MuiGrid-item:first-of-type': {
        display: 'none',
      },
    },
  },
}));

function ECOM3KRegistration() {
  let history = useHistory();
  const linkMink = window.LinkMink('pub_live_OPW3qCtW5JxhJ5Xvwua5');
  const lm_data = linkMink?.getReferralData();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  document.title = 'ECOM3K Registration';

  useEffect(() => {
    if (accountService.userValue) {
      history.push('/account/connectors');
    }
  }, []);

  useEffect(() => {
    if (urlParams.get('couponCode')) {
      window.localStorage.setItem('couponCode', urlParams.get('couponCode'));
    }
    if (urlParams.get('distinct_id')) {
      mixPanel({
        eventName: 'ECOM3K Registration Page View',
        registrationId: urlParams.get('distinct_id'),
      });
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);
  }, []);

  const classes = useStyles();

  const initialValues = {
    email: '',
    password: '',
    passwordConfirmation: '',
    captcha: '',
  };
  const formRef = useRef(null);

  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Email is required')
      .matches(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        'Email not valid'
      ),
    brandName: Yup.string().required('Brand name is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Password must contain at least 8 characters, one uppercase, one number and one special case character'
      ),
    passwordConfirmation: Yup.string()
      .required('Please retype your password')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  });

  async function onSubmit(data, {setSubmitting}) {
    setSubmitting(true);
    alertService.clear();
    alertService.info(<> Creating account ...</>);
    try {
      return await new Promise((resolve, reject) => {
        window.grecaptcha.ready((_) => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, {action: 'submit'})
            .then(async (token) => {
              if (token) {
                try {
                  const response: any = await Api.post('users/sign-up/ecom3k?ecom3kRegistrationComplete', {
                    email: data.email.toLowerCase(),
                    password: data.password,
                    brandName: data.brandName,
                    recatpchaToken: token,
                    linkMinkData: lm_data || null,
                  });
                  if (response?.statusText === 'Created' && response.data.id) {
                    alertService.success(<> Account created succesfully. Signing in now...</>, {autoClose: false});
                    mixPanel({
                      eventName: 'ECOM3K Registration Complete',
                      registrationId: data.email.toLowerCase(),
                    });
                    withTracker('Submit', 'ECOM3K Registration Complete', data.brandName);
                    setTimeout(
                      () =>
                        accountService
                          .login(data.email, data.password)
                          .then(() => {
                            if (urlParams.get('couponCode')) {
                              history.push('/account/billing?couponCode=' + urlParams.get('couponCode').toString());
                            } else {
                              history.push('/account/connectors');
                            }
                            setSubmitting(false);
                            resolve(true);
                          })
                          .catch((error) => {
                            setSubmitting(false);
                            if (error.response && error.response.status === 401) {
                              reject('Email or password is incorrect');
                            } else {
                              reject(error.message);
                            }
                          }),
                      3000
                    );
                  }
                } catch (error: any) {
                  setSubmitting(false);
                  reject(error?.response?.data?.message || error);
                }
              }
            });
        });
      });
    } catch (error: any) {
      setSubmitting(false);
      alertService.clear();
      alertService.error(error);
    }
  }

  const loginFields = [
    {
      label: 'Email',
      name: 'email',
      icon: 'email',
      type: 'text',
    },
    {
      label: 'Seller Central Account Name',
      name: 'brandName',
      icon: 'user',
      type: 'text',
    },
    {
      label: 'Password',
      name: 'password',
      icon: 'password',
      type: 'password',
    },
    {
      label: 'Confirm Password',
      name: 'passwordConfirmation',
      icon: 'password',
      type: 'password',
    },
  ];

  return (
    <>
      <ECOM3KRegistrationView
        itemRef={formRef}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        submitText="Create an Account"
        fields={loginFields}
        formDescription="To begin using ECOM3K, please complete your registration details below."
        logo={<img className={classes.logoImg} src={Logo} alt="ecom3k Logo" />}
      />
      <CustomAlert id="default-alert" className={classes.inputField} />
    </>
  );
}

export {ECOM3KRegistration};
