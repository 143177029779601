import * as React from 'react';
import DraggableListItem from './DraggableListItem';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';

type DraggableListProps = {
  items: any[];
  setItems: any;
  ignoreItems?: any[];
};

const DraggableList = React.memo(({items, setItems, ignoreItems}: DraggableListProps) => {
  let newItems = items;
  const reorder = (list: any, startIndex: number, endIndex: number): any => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({destination, source}: any) => {
    if (!destination) return;
    const data = reorder(items, source.index, destination.index);
    setItems(data);
    newItems = data;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {newItems.map((item, index) => {
              ignoreItems?.includes(item.field) && (item.hide = true);
              return <DraggableListItem item={item} index={index} key={item.id} />;
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DraggableList;
