import moment from 'moment';
import {Typography} from '@mui/material';
import {getMarketplaceById} from '@bizon/amazon-ids';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';
// components
import Icons from '../../../components/Icons/Icons';
// services
import {accountService} from '../../../services/account.service';

const selectedColumns: GridColDef[] = [
  {
    field: 'parent_asin',
    headerName: 'Parent Asin',
    minWidth: 220,
    sortable: false,
    filterable: false,
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: 'child_asins',
    headerName: 'Image',
    filterable: false,
    minWidth: 100,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridCellParams) => {
      const image = params.row?.child_asins?.[0]?.mainImage;
      if (image) return <img src={image} alt="" style={{maxWidth: '35px', maxHeight: '35px'}} />;
      else return <Typography>{'N/A'}</Typography>;
    },
  },
  {
    field: 'brand_code',
    headerName: 'Brand Code',
    flex: 1,
    sortable: false,
    filterable: false,
    minWidth: 220,
    disableColumnMenu: true,
  },
  {
    field: 'brand_name',
    headerName: 'Brand Name',
    flex: 1,
    sortable: false,
    filterable: false,
    minWidth: 220,
    disableColumnMenu: true,
  },
];

const defaultColumns: (groupBy: any, newColumns: any, classes: any) => GridColDef[] = (
  groupBy,
  newColumns,
  classes
) => [
  ...(!groupBy && accountService.userValue.brand_type !== 'ffp' ? newColumns : []),
  {
    field: 'mainImage',
    headerName: 'Image',
    minWidth: 100,
    filterable: false,
    sortable: false,
    align: 'center',
    disableColumnMenu: true,
    renderCell: (params: GridCellParams) => {
      if (params.row.mainImage)
        return <img src={params.row.mainImage} alt="" style={{maxWidth: '35px', maxHeight: '35px'}} />;
      else return <Typography>{'N/A'}</Typography>;
    },
  },
  {
    field: 'itemName',
    headerName: 'Title',
    flex: 1,
    minWidth: 500,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    hide: false,
  },
  {
    field: 'seller_sku',
    headerName: 'SKU',
    minWidth: 280,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridCellParams) => {
      return accountService.userValue.brand_type !== 'ffp' ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/products/${encodeURIComponent(params.row.seller_sku)}/detail`}
        >
          <Typography color="secondary">{params.row.seller_sku}</Typography>
        </a>
      ) : (
        <Typography color="secondary">{params.row.seller_sku}</Typography>
      );
    },
  },
  {
    field: 'asin',
    headerName: 'ASIN',
    flex: 1,
    minWidth: 120,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    hide: false,
  },
  {
    field: 'issues',
    headerName: 'Issues',
    minWidth: 200,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridCellParams) => {
      if (params.row.warningCnt + params.row.errorCnt === 0) return <Typography color={'#469236'}>No Issue</Typography>;
      else
        return (
          <>
            {params.row.warningCnt > 0 && (
              <Typography color={'orange'}>Warning({params.row.warningCnt}) &nbsp; </Typography>
            )}
            {params.row.errorCnt > 0 && <Typography color={'red'}>Error({params.row.errorCnt})</Typography>}
          </>
        );
    },
  },
  {
    field: 'marketplace_id',
    headerName: 'MarketPlace',
    minWidth: 120,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridCellParams) => {
      const data = params.row.marketplace_id ? getMarketplaceById(params.row.marketplace_id) : null;
      return (
        <Typography className={data?.code && classes.iconFlag}>
          {data?.code ? <Icons name={data?.code.toUpperCase()} tooltip /> : 'N/A'}
        </Typography>
      );
    },
  },
  {
    field: 'lastUpdatedDate',
    headerName: 'Updated At',
    flex: 0.2,
    minWidth: 120,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    hide: false,
    renderCell: (params: any) => {
      return <Typography>{moment(params.row.lastUpdatedDate).utc().format('YYYY-MM-DD')}</Typography>;
    },
  },
  {
    field: 'brand_name',
    headerName: 'Brand Name',
    minWidth: 200,
    flex: 1,
    align: 'left',
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
  },
];

export {selectedColumns, defaultColumns};
