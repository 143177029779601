// services
import {accountService} from '../services/account.service';
// utils
import {FFPArticles, ecom3kArticles} from './FFPArticles';
import {Api} from '../utils/api';
declare global {
  interface Window {
    Beacon: any;
  }
}

let mounted = false;
let selectedArticle;

async function getArticleViews() {
  try {
    const {data} = await Api.get(`users/me`);
    return data.viewed_articles;
  } catch (error) {}
}

export function closeBeacon() {
  const Beacon = window.Beacon;
  if (Beacon) {
    Beacon('close');
  }
}

async function saveArticleViews() {
  try {
    await Api.put(`users/${accountService.userValue.userId}/articles`, {
      viewed_article: selectedArticle,
    });
    getArticleViews();
    closeBeacon();
  } catch (error) {}
}

function initBeacon(Beacon, left?) {
  const user = accountService.userValue;
  if (!mounted && user) {
    Beacon('init', process.env.REACT_APP_BEACON_KEY);
    Beacon('config', {
      docsEnabled: true,
      mode: 'askFirst',
      display: {
        style: 'icon',
        position: left ? 'left' : 'right',
      },
    });
    Beacon('identify', {
      name: user.name,
      email: user.email,
    });

    mounted = true;
  }
}

function onClose(article) {
  const iframe = document.getElementById('BeaconInlineArticlesFrame') as HTMLIFrameElement;
  const component = iframe?.contentWindow.document.getElementsByClassName('c-CloseButton')[0];
  const saveArticle = (e) => {
    e.stopImmediatePropagation();
    e.stopPropagation();
    e.preventDefault();
    saveArticleViews();
  };
  component?.addEventListener('click', saveArticle);
}

function openArticle(article, Beacon, type, viewed) {
  if (Beacon && article) {
    if (!viewed?.includes(article)) {
      Beacon('article', article, {type: type});
      selectedArticle = article;
      setTimeout(() => {
        onClose(article);
      }, 1000);
    }
  }
}

export function destroyBeacon() {
  const Beacon = window.Beacon;
  if (Beacon) {
    Beacon('destroy');
    mounted = false;
  }
}

function verifyArticle(user, Beacon, viewedArticles, key) {
  if (['ffp', 'ecom3k'].includes(user.brand_type) && Beacon) {
    const userArticles = user.brand_type === 'ffp' ? FFPArticles : ecom3kArticles;
    initBeacon(Beacon);

    if (key === '/account/connectors') {
      const viewed = viewedArticles?.includes(userArticles[key][0].id);
      const article = !viewed ? userArticles[key][0] : userArticles[key][1];
      openArticle(article.id, Beacon, article.type, viewedArticles);
    } else {
      const article = userArticles[key];
      if (article) {
        openArticle(article.id, Beacon, article.type ? article.type : 'sidebar', viewedArticles);
      }
    }
  }
}

export function getArticle({
  filterPath,
  withSearch,
  beacon,
}: {
  filterPath?: boolean;
  withSearch?: boolean;
  beacon?: any;
}) {
  const user = accountService.userValue;
  const Beacon = beacon ? beacon : window.Beacon;
  const path = window.location.pathname.split('/');
  const key = filterPath
    ? `/${path[path.length - 1]}`
    : `${window.location.pathname}${withSearch ? window.location.search : ''}`;
  const data = async (): Promise<any> => {
    await getArticleViews().then((res) => {
      verifyArticle(user, Beacon, res, key);
    });
  };
  data();
}
