import {COLORS} from './colors';

const transformPurchaserData = (data) => {
  return [
    {
      metric: 'One Time Percentage',
      value: parseFloat(data.one_time_percentage),
      value_porcent: parseFloat(data.one_time_percentage),
      color: '#06b998',
      old_value: data.old_one_time_percentage ? parseFloat(data.old_one_time_percentage) : 0,
    },
    {
      metric: 'Repeating Percentage',
      value: parseFloat(data.repeating_percentage),
      value_porcent: parseFloat(data.repeating_percentage),
      color: '#f1b42f',
      old_value: data.old_repeating_percentage ? parseFloat(data.old_repeating_percentage) : 0,
    },
  ];
};

const transformPurchaserBrandData = (data, code?) =>
  data.map((item) => {
    return [
      {
        item: item && item[code],
        label: item.brand_name,
        metric: 'One Time Percentage',
        value: item.one_time_percentage,
        value_porcent: item.one_time_percentage,
        color: '#06b998',
        old_value: item.old_one_time_percentage ? item.old_one_time_percentage : 0,
      },
      {
        item: item && item[code],
        label: item.brand_name,
        metric: 'Repeating Percentage',
        value: item.repeating_percentage,
        value_porcent: item.repeating_percentage,
        color: '#f1b42f',
        invertedColor: true,
        old_value: item.old_repeating_percentage ? item.old_repeating_percentage : 0,
      },
    ];
  });

const transformSubsAndSaveBrandData = (data, code?) =>
  data
    .sort((a, b) => b.percentage - a.percentage)
    .map((item, index) => {
      return {
        item: item && item[code],
        label: item.brand_name,
        color: COLORS[index % COLORS.length],
        items: [
          {
            metric: 'Percentage',
            value: item.percentage,
            value_porcent: item.percentage,
            invertedColor: true,
            old_value: item.old_percentage ? item.old_percentage : 0,
            fixed: 6,
            format: '%',
          },
          {
            metric: 'Subscribe and Save',
            value: item.sub_and_save,
            value_porcent: item.sub_and_save,
            invertedColor: true,
            old_value: item.old_sub_and_save ? item.old_sub_and_save : 0,
            format: '',
          },
          {
            metric: 'Total',
            value: item.total,
            value_porcent: item.total,
            invertedColor: true,
            old_value: item.old_total ? item.old_total : 0,
            format: '',
          },
        ],
      };
    });

const transformReturnRateData = ({
  data,
  complete,
  color,
  brands,
  total,
}: {
  data: any;
  complete?: boolean;
  color?: boolean;
  brands?: boolean;
  total?: any;
}) => {
  const result = [];
  data.forEach((item, index) => {
    complete && brands
      ? result.push([
          {
            item: item.brand_code || item.seller_sku,
            label: item.brand_name,
            metric: 'Units Returned',
            format: '',
            color: color ? COLORS[index % COLORS.length] : null,
            value: parseFloat(item.units_returned),
            value_porcent: parseFloat(item.units_returned),
            old_value: item.old_units_returned ? parseFloat(item.old_units_returned) : 0,
          },
          {
            item: item.brand_code || item.seller_sku,
            label: item.brand_name,
            metric: 'Units Sold',
            format: '',
            color: color ? COLORS[index % COLORS.length] : null,
            invertedColor: true,
            value: parseFloat(item.units_sold),
            value_porcent: parseFloat(item.units_sold),
            old_value: item.old_units_sold ? parseFloat(item.old_units_sold) : 0,
          },
          {
            item: item.brand_code || item.seller_sku,
            label: item.brand_name,
            metric: 'Return Rate',
            format: '%',
            color: color ? COLORS[index % COLORS.length] : null,
            value: (parseFloat(item.return_rate) * 100).toFixed(2),
            value_porcent: (parseFloat(item.return_rate) * 100).toFixed(2),
            old_value: item.old_return_rate ? parseFloat(item.old_return_rate) : 0,
          },
        ])
      : complete && !brands
      ? result.push(
          {
            metric: 'Units Returned',
            format: '',
            color: color ? COLORS[index % COLORS.length] : null,
            value: parseFloat(item.units_returned),
            value_porcent: parseFloat(item.units_returned),
            old_value: item.old_units_returned ? parseFloat(item.old_units_returned) : 0,
          },
          {
            metric: 'Units Sold',
            format: '',
            color: color ? COLORS[index % COLORS.length] : null,
            invertedColor: true,
            value: parseFloat(item.units_sold),
            value_porcent: parseFloat(item.units_sold),
            old_value: item.old_units_sold ? parseFloat(item.old_units_sold) : 0,
          },
          {
            metric: item.brand_code ? item.brand_code : 'Return Rate',
            format: '%',
            color: color ? COLORS[index % COLORS.length] : null,
            value: (parseFloat(item.return_rate) * 100).toFixed(2),
            value_porcent: (parseFloat(item.return_rate) * 100).toFixed(2),
            old_value: item.old_return_rate ? parseFloat(item.old_return_rate) : 0,
          }
        )
      : result.push({
          metric: item.brand_code ? item.brand_code : item.seller_sku ? item.seller_sku : 'Return Rate',
          format: '%',
          color: color ? COLORS[index % COLORS.length] : null,
          value: parseFloat(item.units_returned),
          value_porcent: total
            ? ((parseFloat(item.units_returned) * 100) / total).toFixed(2)
            : parseFloat(item.units_returned),
          old_value: item.old_return_rate ? parseFloat(item.old_return_rate) : 0,
        });
  });
  return result.filter((item) => item !== undefined);
};

const transformSummaryData = (data) => {
  return data.map((item, index) => {
    const countProperties = Object.entries(item).filter(
      ([key, value]) =>
        (key.includes('count') || key.includes('ship_service_level') || key.includes('marketplaceId')) &&
        !key.includes('total') &&
        !key.includes('old_')
    );
    const summaryProperties = Object.entries(item).filter(
      ([key, value]) => key.includes('total') && !key.includes('old_')
    );
    const oldValues = Object.entries(item).filter(([key, value]) => key.includes('old_'));
    const getOldValue = (currentValue): any => {
      const oldValue = oldValues.find((oldValue) => (oldValue[0] === `old_${currentValue[0]}` ? oldValue : null));
      const newOldValue = oldValue !== null && oldValue !== undefined ? oldValue[1] : null;
      return newOldValue;
    };

    return {
      id: index,
      brandCode: item.brand_code ? item.brand_code : null,
      brandName: item.brand_name || null,
      sellerSku: item.seller_sku ? item.seller_sku : null,
      count: countProperties.map((item: any, index) => {
        return {
          id: index,
          label: item[0].includes('discount') ? item[0] : item[0].replaceAll('count_', ''),
          value: item[1],
          oldValue: getOldValue(item),
        };
      }),
      summary: summaryProperties.map((item: any, index) => {
        return {
          id: index,
          label: item[0].replaceAll('amount', ''),
          value: item[1],
          oldValue: getOldValue(item),
        };
      }),
    };
  });
};

const sumValues = (array, value) => array.reduce((acc, curr) => acc + curr[value], 0);

const transformGeoData = (array, type) => {
  const newData = Object.entries(array).map(([key, value]: any, index): any => {
    return {
      label: key,
      name: value[0].brand_name,
      buyers: value,
      type: type,
      data: [
        {
          metric: 'Buyers',
          value: value.length,
        },
        {
          metric: 'revenue',
          value: sumValues(value, 'revenue').toFixed(1),
          old_value: sumValues(value, 'old_revenue').toFixed(1) || null,
        },
        {
          metric: 'units',
          value: sumValues(value, 'units').toFixed(1),
          old_value: sumValues(value, 'old_units').toFixed(1) || null,
        },
      ],
    };
  });
  return newData;
};

const transformHeatmapData = (selectedData) => {
  return Object.entries(selectedData).map((item) => {
    return item[1];
  });
};

const transformHeatmapBrandData = (data, type) => {
  const getData = (selectedData) => {
    return Object.entries(selectedData).map((item) => {
      return item[1];
    });
  };
  return Object.entries(data).map(([key, value]) => {
    return {
      label: key,
      type: type,
      name: value[Object.keys(value)[0]][0]['brand_name'] || '',
      labels: Object.keys(value).map((i: any) => i.substring(0, 3)),
      data: getData(value),
    };
  });
};

const transformFBAData = (data) =>
  data
    .sort((a, b) => b.count - a.count)
    .map((item, index) => {
      return {
        ...item,
        color: COLORS[index % COLORS.length],
        value: item.count,
        value_porcent: item.percentage,
        old_value: item.old_count,
        old_porcent: item.old_percentage,
      };
    });

const transformFBACountData = (data) =>
  data
    .sort((a, b) => b.count - a.count)
    .map((item, index) => {
      const porcent = data?.reduce((acc, curr) => acc + curr.count, 0);
      const calc = porcent > 0 ? porcent : 100;
      return {
        metric: item.seller_sku,
        value: item.count,
        old_value: item.old_count,
        color: COLORS[index % COLORS.length],
        value_porcent: ((parseFloat(item.count) * 100) / calc).toFixed(1),
      };
    });

const transformAverageOrderBrandData = (data) =>
  data
    .map((item) => {
      return {
        metric: item.brand_code,
        label: item.brand_name,
        value: item.average_order,
        old_value: item.old_average_order,
        invertedColor: true,
      };
    })
    .sort((a, b) => b.value - a.value);

const transformAverageOrderSkuData = (data) =>
  data
    .map((item) => {
      return {
        metric: item.seller_sku,
        value: item.average_order,
        old_value: item.old_average_order,
        invertedColor: true,
      };
    })
    .sort((a, b) => b.value - a.value);

const transformSubsAndSaveData = (data) => {
  return [
    {
      metric: 'Total',
      value: parseFloat(data.total),
      value_porcent: parseFloat(data.total),
      old_value: data.old_total ? parseFloat(data.old_total) : 0,
      format: '',
      invertedColor: true,
    },
    {
      metric: 'Percentage',
      value: parseFloat(data.percentage),
      value_porcent: parseFloat(data.percentage),
      old_value: data.old_percentage ? parseFloat(data.old_percentage) : 0,
      invertedColor: true,
      fixed: 6,
    },
    {
      metric: 'Subcribe and Save',
      value: parseFloat(data.sub_and_save),
      value_porcent: parseFloat(data.sub_and_save),
      old_value: data.old_sub_and_save ? parseFloat(data.old_sub_and_save) : 0,
      format: '',
      invertedColor: true,
    },
  ];
};

const transformSubsAndSaveBrandChartData = (data, value) =>
  data
    .sort((a, b) => b.percentage - a.percentage)
    .map((item, index) => {
      return {
        ...item,
        metric: item[value],
        color: COLORS[index % COLORS.length],
        value: item.percentage,
        value_porcent: item.percentage,
        old_value: item.old_percentage,
        old_porcent: item.old_percentage,
      };
    })
    .filter((item) => item.percentage > 0.009);

const transformPromotionCodesData = (data) =>
  data
    .map((item) => {
      return {
        metric: item.promo_code,
        label: item.promo_code,
        value: item.count,
        old_value: item.old_count,
        invertedColor: true,
      };
    })
    .sort((a, b) => b.value - a.value);

const transformPromotionCodesBrandData = (data) =>
  Object.entries(data).map(([key, item]: any) => {
    const values = item.map((item) => {
      let old_value = null;
      if (item.old_count !== null) {
        old_value = item.old_count;
      } else {
        old_value = 0;
      }
      return {
        metric: item.promo_code,
        label: item.promo_code,
        value: item.count,
        old_value: old_value,
        invertedColor: true,
        brand_name: item.brand_name,
      };
    });
    return {
      metric: values[0]?.brand_name || key,
      label: values[0]?.brand_name || key,
      brand_code: key,
      values: values.sort((a, b) => b.value - a.value),
      value: item.reduce((acc, item) => acc + item.count, 0),
      old_value: values.reduce((acc, item) => acc + item.old_value, 0) || null,
      invertedColor: true,
    };
  });

const transformPromotionCodesSkuData = (data, compare) =>
  Object.entries(data).map(([key, item]: any) => {
    const values = item.map((item) => {
      let old_value = null;
      if (compare) {
        if (item.old_count !== null) {
          old_value = item.old_count;
        } else {
          old_value = 0;
        }
      }
      return {
        metric: item.promo_code,
        label: item.promo_code,
        value: item.count,
        old_value: old_value,
        invertedColor: true,
      };
    });
    return {
      metric: key,
      label: key,
      values: values.sort((a, b) => b.value - a.value),
      value: item.reduce((acc, item) => acc + item.count, 0),
      old_value: compare ? values.reduce((acc, item) => acc + item.old_value, 0) : null,
      invertedColor: true,
    };
  });

export {
  transformReturnRateData,
  transformPurchaserData,
  transformPurchaserBrandData,
  transformSummaryData,
  transformGeoData,
  transformHeatmapData,
  transformHeatmapBrandData,
  transformSubsAndSaveBrandData,
  transformFBAData,
  transformFBACountData,
  transformAverageOrderBrandData,
  transformAverageOrderSkuData,
  transformSubsAndSaveData,
  transformSubsAndSaveBrandChartData,
  transformPromotionCodesData,
  transformPromotionCodesBrandData,
  transformPromotionCodesSkuData,
};
