import {useState} from 'react';
import {
  CircularProgress,
  Theme,
  Typography,
  Paper,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Tooltip,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Summarize, Download} from '@mui/icons-material';
import moment from 'moment';
// utils
import {Api} from '../../../utils/api';
// services
import {AlertType} from '../../../services/alert.service';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: '10px',
  },
  button: {
    width: '100%',
    margin: '3px 3px 0 0',
    '& > button': {
      textTransform: 'none',
      width: '100%',
    },
    [theme.breakpoints.down(767)]: {
      '&.MuiBox-root': {
        padding: 0,
      },
    },
  },
}));

function CsvExportButton({apiUrl, reportFileName, fromTo = null, handleAlert = null}) {
  const classes = useStyles();
  const [downloading, setDownloading] = useState(false);

  async function getData() {
    setDownloading(true);
    const params = fromTo
      ? {
          from: moment(fromTo.from).format('YYYY-MM-DD'),
          to: moment(fromTo.to).format('YYYY-MM-DD'),
        }
      : null;

    try {
      const res = await Api.get(apiUrl, {
        params: {...params, reportFileName},
      });
      switch (res.status) {
        case 200:
          handleAlert({type: AlertType.Success, message: res.data?.message});
          break;
        case 202:
          handleAlert({type: AlertType.Info, message: res.data});
          break;
        case 204:
          handleAlert({type: AlertType.Warning, message: 'No data!'});
          break;
        default:
          handleAlert({type: AlertType.Info, message: res.data?.message});
          break;
      }
    } catch (e: any) {
      if (e.response && e.response.status !== 401) {
        handleAlert({type: AlertType.Error, message: `Unable to get ${reportFileName}.`});
      }
    } finally {
      setDownloading(false);
    }
  }

  return (
    <Paper elevation={2} className={classes.button}>
      <ListItem
        secondaryAction={
          <Tooltip title="Download">
            <IconButton edge="end" aria-label="delete" onClick={getData} className="default-icon download">
              <Download />
            </IconButton>
          </Tooltip>
        }
      >
        <ListItemAvatar>
          <Avatar>
            <Summarize />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography>
              {downloading && (
                <>
                  {'Downloading ... '}
                  <CircularProgress size={12} color="inherit" />
                </>
              )}
              {!downloading && `${reportFileName}`}
            </Typography>
          }
          secondary={`${moment(fromTo.from).format('MMMM Do YYYY')} - ${moment(fromTo.to).format('MMMM Do YYYY')}`}
        />
      </ListItem>
    </Paper>
  );
}

export {CsvExportButton};
