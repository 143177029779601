import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  pageHeader: {
    display: 'flex',
    '& .page-title': {
      textAlign: 'right',
    },
    [theme.breakpoints.down(1200)]: {
      '& .page-title': {
        textAlign: 'left',
      },
      flexDirection: 'column-reverse',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '850px',

    '& .MuiButton-outlined:not(:focus, :active)': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#f9f9f9',
    },
    '&.MuiButtonGroup-root .MuiButton-root': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down(1300)]: {
      '&.new-group': {
        maxWidth: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
          borderRadius: '0',
          borderTopLeftRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(-n+4)': {
          borderBottomColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(4)': {
          borderRadius: '0',
          borderTopRightRadius: '3px!important',
          borderRightColor: 'rgba(60, 141, 199, 0.5)',
        },
        '& .MuiButton-root:nth-of-type(4n)': {
          borderRadius: '0',
        },
        '& .MuiButton-root:nth-of-type(8)': {
          borderBottomRightRadius: '3px',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(.new-group)': {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
          borderRadius: '0',
          borderTopLeftRadius: '3px',
        },
        '&.MuiButtonGroup-root .MuiButton-root': {
          border: theme.palette.mode === 'dark' ? '1px solid #3887be' : '1px solid #174580',
          borderBottomColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(3n)': {
          borderLeftColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(3)': {
          borderTopRightRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(4n)': {
          borderRightColor: 'transparent',
          '&:hover': {
            borderRightColor: 'transparent',
          },
        },
        '& .MuiButton-root:last-of-type': {
          gridArea: '4 / 1 / 4/ 4',
          borderBottomColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
          borderRadius: '0',
          borderBottomRightRadius: '3px',
          borderBottomLeftRadius: '3px',
        },
        '& .MuiButton-outlined': {
          border: 'none',
          color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
          '&:last-of-type': {
            backgroundColor: '#e9e9e9',
          },
        },
        '& .MuiButton-containedPrimary': {
          border: 'none',
          '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
            outline: 'none',
          },
        },
      },
      '&.new-group': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',

        '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
          borderRadius: '0',
          borderTopLeftRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(-n+6)': {
          borderBottomColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(2)': {
          borderRadius: '0',
          borderTopRightRadius: '3px!important',
          borderRightColor: 'rgba(60, 141, 199, 0.5)',
        },
        '& .MuiButton-root:nth-of-type(6), ': {
          borderRightColor: 'rgba(60, 141, 199, 0.5)',
        },
        '& .MuiButton-root:nth-of-type(4n)': {
          borderRadius: '0',
        },
        '& .MuiButton-root:nth-of-type(7)': {
          borderBottomLeftRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(8)': {
          borderBottomRightRadius: '3px',
        },
      },
    },
  },
}));

export {useStyles};
