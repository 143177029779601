import {Avatar, Chip, Paper, Typography, Tooltip} from '@mui/material';
import {Alert} from '@mui/lab';

function CommissionRule({rule, temp = false}) {
  return (
    <Paper variant={'outlined'} style={{padding: 25, marginTop: 10}}>
      {temp ? (
        <Alert severity="warning">Override rule for selected month</Alert>
      ) : (
        <Typography color={'secondary'} variant={'body1'}>
          General rule
        </Typography>
      )}
      <br />
      <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
        FIXED COMMISSION PART
      </Typography>
      <Typography component="h5" variant="h5" color="primary" gutterBottom>
        {rule?.fixed}
      </Typography>
      <br />
      <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
        VARIABLE COMMISSION PART
      </Typography>
      <Typography component="h5" variant="h6" color="primary" gutterBottom>
        MINIMUM: {rule?.minimum}
      </Typography>
      <br />
      <Typography component="h5" variant="h6" color="primary" gutterBottom>
        TIERS{' '}
        <Tooltip title={'OPERATOR'} placement={'top'}>
          <Chip style={{cursor: 'pointer'}} label={rule?.operator === 'AND' ? 'SUM' : 'OR'} />
        </Tooltip>
      </Typography>
      {rule?.tiers.map((tier, i) => {
        return (
          <Typography component="h5" variant="subtitle1" color="primary" gutterBottom>
            <Chip
              color={'primary'}
              avatar={<Avatar color={'primary'}>IF</Avatar>}
              label={`${tier.compare_var} > ${tier.compare_threshold}`}
            />
            {' → '}
            <Chip
              color={'primary'}
              label={`${tier.value}${tier.value_var === 'FIXED' ? '' : '%'} ${tier.value_var}`}
            />{' '}
            {tier.value_var !== 'FIXED'
              ? tier.value_to
                ? `from ${tier.value_from} to ${tier.value_to}`
                : `over ${tier.value_from}`
              : ''}
          </Typography>
        );
      })}
      <Paper variant={'outlined'} style={{padding: 15, marginTop: 25, textAlign: 'center'}}>
        <Typography component="h5" variant="subtitle1" color="secondary" gutterBottom>
          fixed + GREATER(minimum, OPERATOR(tier1, tier2, ...))
        </Typography>
      </Paper>
    </Paper>
  );
}

export {CommissionRule};
