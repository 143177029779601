import {Grid} from '@mui/material';
import {styled} from '@mui/system';

const Container = styled(Grid)(({theme}) => ({
  padding: '30px 25px',
  position: 'relative',
  width: '100%',
  '& .MuiGrid-root': {
    padding: '0',
  },
  '& .MuiGrid-item > div:not(:first-of-type)': {
    marginTop: '10px',
  },

  '&.sub-page .MuiGrid-item > div:not(:first-of-type)': {
    marginTop: 0,
  },

  [theme.breakpoints.down(1220)]: {
    '&.MuiGrid-root': {
      justifyContent: 'center',
    },
  },
  [theme.breakpoints.down(960)]: {
    '&.sub-page-details .MuiGrid-item': {
      paddingRight: '20px',
      '.MuiGrid-container': {
        flexWrap: 'nowrap',
      },
    },
    '&.sub-page-details .MuiTypography-subtitle1': {
      fontSize: '1.2rem',
    },
    '&.sub-page-details .MuiTypography-h5': {
      fontSize: '0.9rem',
    },
  },
  [theme.breakpoints.down(767)]: {
    '& .MuiBox-root': {
      padding: '20px 0',
    },
    '&.sub-page-details': {
      padding: '0 25px',
    },
    '&.sub-page': {
      padding: '0 25px',
    },
  },
  [theme.breakpoints.down(420)]: {
    '.MuiGrid-container': {
      flexWrap: 'wrap',
    },
  },
}));

export default Container;
