import moment from 'moment';

export const Helper = {
  arrayGroupBy: (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  },
  getMonthsBetweenDates: (startDate, endDate = null) => {
    const dateStart = moment(new Date(startDate));
    const dateEnd = endDate ? moment(new Date(endDate)) : moment(new Date());
    const timeValues = [];

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      timeValues.push(dateStart.format('YYYY-MM'));
      dateStart.add(1, 'month');
    }
    return timeValues.reverse();
  },
  formatAmount(data: number): string {
    return new Intl.NumberFormat().format(data);
  },
  encodeSlash(uri: string): string {
    return uri.replace(/\//g, '%2F');
  },
  formatAmountAndUnitLabel(amount: number | undefined | null, unit: string) {
    if (!amount || amount < 1) return `No ${unit}`;
    else if (amount === 1) return `${amount} ${unit}`;
    else return `${amount} ${unit}s`;
  }
};
