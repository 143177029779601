import moment from 'moment';

interface onFromToChangeProps {
  from: string;
  to: string;
  cFrom?: any;
  cTo?: any;
  preset: string;
  compare?: boolean;
  loadData?: (preset, fromValue, toValue, compareFromValue, compareToValue, init) => void;
  setFromTo?: (fromTo: any) => void;
}

export function onFromToChange({from, to, cFrom, cTo, preset, compare, loadData, setFromTo}: onFromToChangeProps) {
  const fromValue = moment(from).format('YYYY-MM-DD');
  const toValue = moment(to).format('YYYY-MM-DD');
  let compareFrom = cFrom;
  let compareTo = cTo;

  if (compare) {
    switch (preset) {
      case 'q':
        compareFrom = moment(fromValue).subtract(1, 'quarter');
        compareTo = moment().quarter(moment(compareFrom).quarter()).endOf('quarter');
        break;
      case 'cm':
        compareFrom = moment(fromValue).subtract(1, 'month');
        compareTo = moment(compareFrom).endOf('month');
        break;
      case 'cw':
        compareFrom = moment(fromValue).subtract(1, 'week');
        compareTo = moment(compareFrom).endOf('week');
        break;
      default:
        const diff = moment(toValue).diff(fromValue, 'days');
        compareFrom = moment(fromValue).subtract(diff + 1, 'days');
        compareTo = moment(toValue).subtract(diff + 1, 'days');
        break;
    }
  }
  const compareFromValue = compareFrom ? moment(compareFrom).format('YYYY-MM-DD') : null;
  const compareToValue = compareTo ? moment(compareTo).format('YYYY-MM-DD') : null;
  loadData && loadData(preset, fromValue, toValue, compareFromValue, compareToValue, false);
  setFromTo && setFromTo({from: fromValue, to: toValue, compareFrom: compareFromValue, compareTo: compareToValue});
}
