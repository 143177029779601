import {IconButton, Tooltip} from '@mui/material';

export const fixedPlaceHolder = ({width = 120, content, buttons}: {width?: number; content?: any; buttons?: any}) => {
  return {
    field: 'Actions',
    headerName: '',
    flex: 1,
    minWidth: width,
    align: 'right',
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      if (buttons && buttons.length > 0) {
        return (
          <div style={{minWidth: '120px', maxWidth: '120px'}} className="customButtons">
            {buttons.map((button, j) => {
              const {url, icon, onClick} = button;
              const newIcon = typeof icon === 'function' ? icon(params.row) : icon;
              if (onClick) {
                return (
                  <IconButton key={j} onClick={() => onClick(params.row)}>
                    {newIcon}
                  </IconButton>
                );
              } else {
                return (
                  <a key={j} target="_blank" rel="noreferrer" href={url && url(params.row)}>
                    <IconButton>{newIcon}</IconButton>
                  </a>
                );
              }
            })}
          </div>
        );
      } else {
        return null;
      }
    },
  };
};

export const CustomColumn = ({thisRef, rows, buttons, height = '52px', width = '120px'}) => (
  <div ref={thisRef} id={`fixed-column`} className="fixed-column-container" style={{width: width}}>
    {rows?.map((row, i) => (
      <div
        key={i}
        className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell MuiDataGrid-cell--textLeft MuiDataGrid-cell--editable"
        style={{minWidth: width, maxWidth: width, minHeight: height, maxHeight: height}}
      >
        {buttons.map((button, j) => {
          const {url, icon, tooltip, onClick, customButton} = button;
          const newIcon = typeof icon === 'function' ? icon(row) : icon;
          const newTooltip = typeof tooltip === 'function' ? tooltip(row) : tooltip;
          const newButton = url ? (
            <a key={j} target="_blank" rel="noreferrer" href={url(row)}>
              <IconButton>{newIcon}</IconButton>
            </a>
          ) : (
            <IconButton key={j} onClick={() => onClick && onClick(row)}>
              {newIcon}
            </IconButton>
          );
          const component = tooltip ? (
            <Tooltip key={j} title={newTooltip}>
              {newButton}
            </Tooltip>
          ) : customButton ? (
            <div key={j} style={{padding: '0 10px'}}>
              {customButton.label && customButton.label}
              <div onClick={() => onClick && onClick(row)}>{customButton.component}</div>
            </div>
          ) : (
            newButton
          );

          return component;
        })}
      </div>
    ))}
  </div>
);

export const makeColumnFreeze = (thisRef, visibilityModel, rows) => {
  if (document.getElementById('fixed-column')) {
    document.getElementById('fixed-column')?.remove();
  }
  const row = document.getElementsByClassName('MuiDataGrid-virtualScrollerContent')[0];
  if (!visibilityModel.hasOwnProperty('Actions')) {
    if (!document.getElementById(`fixed-column`) && row && rows.length > 0) {
      row.appendChild(thisRef.current);
    }
  }
};
