import {useEffect, useState, useRef, useMemo} from 'react';
import {
  Typography,
  Link,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Grid,
} from '@mui/material';
import {
  DataGrid,
  getGridStringOperators,
  GridColDef,
  GridColTypeDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridPagination,
  GridCellParams,
} from '@mui/x-data-grid';
import moment from 'moment';
import {LinkOff, Visibility, Send, Assignment} from '@mui/icons-material';
import io from 'socket.io-client';
// components
import {CustomAlert} from '../../Login/components/CustomAlert';
import TableContainer from '../../../components/TableContainer/TableContainer';
import Button from '../../../components/Button/Button';
import ModalButton from '../../../components/ModalButton/ModalButton';
import DropDownButton from '../../../components/DropdownButton/DropDownButton';
import DraggableList from '../../../components/DraggableList/DraggableList';
import {brandReportTypes} from '../subpages/BrandsReports/utils/BrandReportProperties';
import TableSelectInput from '../../../components/TableSelectInput/TableSelectInput';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
// utils
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../../utils/tableSettings';
import {rowsPerPageOptions} from '../../../utils/constants';
import getLabel from '../../../utils/getLabel';
import {Api, ApiWithCustomToken, errorAlert} from '../../../utils/api';
import {onFromToChange} from '../../../utils/OnFromToChange';
import {CustomColumn, makeColumnFreeze, fixedPlaceHolder} from '../../../utils/makeColumnFreeze';
// services
import {accountService} from '../../../services/account.service';
import {alertService} from '../../../services/alert.service';
import {emitterService} from '../../../services/emitter.service';
// styles
import {useStyles} from '../Styles';
// assets
import GoogleIcon from '../../../assets/images/google-icon.png';

declare let google: any;
declare let gapi: any;

let accessToken: string;
let tokenClient: any;

const stringColumnType: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

type CallbackDoc = {
  downloadUrl?: string;
  uploadState?: string;
  description: string;
  driveSuccess: boolean;
  embedUrl: string;
  iconUrl: string;
  id: string;
  isShared: boolean;
  lastEditedUtc: number;
  mimeType: string;
  name: string;
  rotation: number;
  rotationDegree: number;
  serviceId: string;
  sizeBytes: number;
  type: string;
  url: string;
};

type PickerCallback = {
  action: string;
  docs: CallbackDoc[];
};

type authResult = {
  access_token: string;
  authuser: string;
  client_id: string;
  cookie_policy: string;
  expires_at: string;
  expires_in: string;
  issued_at: string;
  login_hint: string;
  response_type: string | undefined;
  scope: string;
  session_state: null;
  status: {signed_in: boolean; method: string; google_logged_in: boolean};
  token_type: string;
  error: boolean | undefined;
};

type ViewIdOptions =
  | 'DOCS'
  | 'DOCS_IMAGES'
  | 'DOCS_IMAGES_AND_VIDEOS'
  | 'DOCS_VIDEOS'
  | 'DOCUMENTS'
  | 'DRAWINGS'
  | 'FOLDERS'
  | 'FORMS'
  | 'PDFS'
  | 'SPREADSHEETS';

type PickerConfiguration = {
  clientId: string;
  developerKey: string;
  viewId?: ViewIdOptions;
  viewMimeTypes?: string;
  setIncludeFolders?: boolean;
  setSelectFolderEnabled?: boolean;
  disableDefaultView?: boolean;
  token?: string;
  multiselect?: boolean;
  disabled?: boolean;
  appId?: string;
  supportDrives?: boolean;
  showUploadView?: boolean;
  showUploadFolders?: boolean;
  setParentFolder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customViews?: any[];
  locale?: string;
  customScopes?: string[];
};

const defaultConfiguration: PickerConfiguration = {
  clientId: '',
  developerKey: '',
  viewId: 'DOCS',
};

type HookOptions = {
  onCancel?: () => any;
};

function useDrivePicker({
  onCancel,
}: HookOptions): [
  (config: PickerConfiguration) => boolean | undefined,
  PickerCallback | undefined,
  authResult | undefined
] {
  const defaultScopes = 'https://www.googleapis.com/auth/drive';
  const [pickerApiLoaded, setpickerApiLoaded] = useState(false);
  const [callBackInfo, setCallBackInfo] = useState<PickerCallback>();
  const [openAfterAuth, setOpenAfterAuth] = useState(false);
  const [authWindowVisible, setAuthWindowVisible] = useState(false);
  const [config, setConfig] = useState<PickerConfiguration>(defaultConfiguration);
  const [authRes, setAuthRes] = useState<authResult>();

  // if (accessToken) {
  //   setConfig((prev) => ({...prev, token: accessToken}));
  // }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let picker: any;

  // get the apis from googleapis
  useEffect(() => {
    if (!pickerApiLoaded) {
      loadApis();
    }
  }, [pickerApiLoaded]);

  useEffect(() => {
    if (authRes) {
      accessToken = authRes.access_token;
    }
  }, [authRes]);

  // use effect to open picker after auth
  useEffect(() => {
    if (openAfterAuth && config.token && pickerApiLoaded) {
      createPicker(config);
      setOpenAfterAuth(false);
    }
  }, [openAfterAuth, config.token, pickerApiLoaded]);

  // open the picker
  const openPicker = (config: PickerConfiguration) => {
    // global scope given conf
    setConfig(config);

    // if we didnt get token generate token.
    if (!config.token) {
      setAuthWindowVisible(true);
    }

    // if we have token and everything is loaded open the picker
    if (config.token && pickerApiLoaded) {
      return createPicker(config);
    }
  };

  // load the Drive picker api
  const loadApis = () => {
    gapi?.load('picker', {callback: onPickerApiLoad});
  };

  const onPickerApiLoad = () => {
    setpickerApiLoaded(true);
  };

  // Open auth window after given config state is ready
  useEffect(() => {
    if (authWindowVisible) {
      tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: config.clientId,
        callback: handleAuthResult,
        scope: defaultScopes,
      });
      if (!config.token) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        tokenClient.requestAccessToken({prompt: 'consent'});
      } else {
        // Skip display of account chooser and consent dialog for an existing session.
        tokenClient.requestAccessToken({prompt: ''});
      }
    }
  }, [authWindowVisible, config.clientId, config.token]);

  const handleAuthResult = (authResult: authResult) => {
    setAuthWindowVisible(false);

    if (authResult && !authResult.error) {
      setAuthRes(authResult);
      setConfig((prev) => ({...prev, token: authResult.access_token}));
      setOpenAfterAuth(true);
    }
  };

  const createPicker = ({
    token,
    appId = '',
    supportDrives = false,
    developerKey,
    viewId = 'DOCS',
    disabled,
    multiselect,
    showUploadView = false,
    showUploadFolders,
    setParentFolder = '',
    viewMimeTypes,
    customViews,
    locale = 'en',
    setIncludeFolders,
    setSelectFolderEnabled,
    disableDefaultView = false,
  }: PickerConfiguration) => {
    if (disabled) return false;

    const view = new google.picker.DocsView(google.picker.ViewId[viewId]);
    if (viewMimeTypes) view.setMimeTypes(viewMimeTypes);
    if (setIncludeFolders) view.setSelectFolderEnabled(true);
    if (setSelectFolderEnabled) view.setSelectFolderEnabled(true);

    const uploadView = new google.picker.DocsUploadView();
    if (viewMimeTypes) uploadView.setMimeTypes(viewMimeTypes);
    if (showUploadFolders) uploadView.setIncludeFolders(true);
    if (setParentFolder) uploadView.setParent(setParentFolder);

    picker = new google.picker.PickerBuilder()
      .setAppId(appId)
      .setOAuthToken(token)
      .setDeveloperKey(developerKey)
      .setCallback(pickerCallback)
      .setLocale(locale);

    if (!disableDefaultView) {
      picker.addView(view);
    }

    if (customViews) {
      customViews.map((view) => picker.addView(view));
    }

    if (multiselect) {
      picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
    }

    if (showUploadView) picker.addView(uploadView);

    if (supportDrives) {
      picker.enableFeature(google.picker.Feature.SUPPORT_DRIVES);
    }

    picker.build().setVisible(true);
    return true;
  };

  // A simple callback implementation.
  const pickerCallback = (data: PickerCallback) => {
    if (data.action === google.picker.Action.CANCEL && onCancel) {
      onCancel();
    }

    if (data.action === google.picker.Action.PICKED) {
      setCallBackInfo(data);
    }
  };

  return [openPicker, callBackInfo, authRes];
}

function GoogleDrive(props) {
  const [openPicker, pickerData] = useDrivePicker({
    onCancel: () => console.log('User closed picker with close/cancel button'),
  });
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState('');
  const [orderColumns, setOrderColumns] = useState([]);
  const [visibilityModel, setVisibilityModel] = useState<any>({});
  const [tableLoading, setTableLoading] = useState(false);
  const [currentReportName, setCurrentReportName] = useState('');
  const [currentReportType, setCurrentReportType] = useState('');
  const [folders, setFolders] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [fromTo, setFromTo] = useState({
    from: moment().format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  });
  const [reportToSend, setReportToSend] = useState(null);
  const mobile = useMediaQuery('(max-width:767px)');

  const columns: GridColDef[] = useMemo(()=>{return [
    {
      field: 'reportName',
      headerName: 'Report',
      minWidth: 380,
      sortable: false,
    },
    {
      field: 'lastSuccess',
      headerName: 'Last Success',
      minWidth: 600,
      renderCell: (params: GridCellParams) => {
        if (params.row.reportType && props.lastSuccess.length) {
          const lastSuccessByReportType = props.lastSuccess?.find(
            (ls) => (ls?.reportResponse?.reportType || ls?.reportType) === params.row.reportType
          );
          if (lastSuccessByReportType) {
            let ReportResponseElement = () => {
              return <></>;
            };
            if (lastSuccessByReportType?.reportResponse) {
              ReportResponseElement = () => {
                return (
                  <>
                    <Button
                      size="small"
                      color="info"
                      style={{
                        padding: '5px 10px',
                        float: lastSuccessByReportType.fileId || lastSuccessByReportType.error ? 'right' : 'left',
                      }}
                      startIcon={<Visibility />}
                      variant="text"
                      onClick={() => {
                        setSelectedReport(lastSuccessByReportType);
                      }}
                    >
                      Report Response
                    </Button>
                  </>
                );
              };
            }

            if (lastSuccessByReportType?.error) {
              return (
                <Grid container>
                  <Grid lg={12}>
                    <Typography component="span" color="error">
                      {lastSuccessByReportType.error}
                    </Typography>
                  </Grid>
                  <Grid lg={12}>
                    <ReportResponseElement />
                  </Grid>
                </Grid>
              );
            }

            const href = `https://drive.google.com/open?id=${lastSuccessByReportType.fileId}`;

            return (
              <Grid container>
                <Grid lg={12}>
                  <Typography>
                    <Link href={href} target="_blank" rel="noreferrer noopener">
                      {lastSuccessByReportType?.fileName}
                    </Link>
                  </Typography>
                </Grid>
                <Grid lg={12}>
                  <ReportResponseElement />
                </Grid>
              </Grid>
            );
          }

          return <></>;
        }

        return <></>;
      },
      sortable: false,
    },
    {
      field: 'folderName',
      headerName: 'Folder Name',
      flex: 1,
      minWidth: 300,
      editable: true,
      renderCell: (params) => {
        return (
          <TableSelectInput
            readOnly
            value={params.row?.folderId}
            loading={loading}
            renderValue={params.row?.folderName}
          />
        );
      },
      renderEditCell: (params: any) => {
        const ids = rows.map((o) => o.folderId);
        const optionsArray = rows.filter(
          (item, index) => !ids.includes(item.folderId, index + 1) && item.folderId && item.folderId !== ''
        );

        return (
          <TableSelectInput
            value={params.row.folderId}
            renderValue={params.row.folderName}
            onChange={(event, label) => {
              props.brandReports[params.row.id].folderId = event === '' ? '' : event;
              props.brandReports[params.row.id].folderName = label === 'N/A' ? '' : label;
              setFolders(props.brandReports);
              props.setValues(props.keyValue, props.brandReports);
            }}
            options={optionsArray?.map((item, index) => (
              <MenuItem key={index} value={item.folderId}>
                {item.folderName ? item.folderName : 'Unnamed'}
              </MenuItem>
            ))}
          />
        );
      },
      sortable: false,
    },
  ]}, [loading, props, rows]) ;

  useEffect(() => {
    const socketOptions = {
      extraHeaders: {
        Authorization: `${accountService.userValue.accessToken}`,
      },
    };
    const socket = io(process.env.REACT_APP_API_URL, socketOptions);

    socket.on('googleDriveReportReady', (data) => {
      const lastSuccessByReportTypeIndex = props.lastSuccess?.findIndex(
        (ls) =>
          (ls?.reportResponse?.reportType || ls?.reportType) === data.responseLastSuccess.reportResponse.reportType
      );

      if (lastSuccessByReportTypeIndex > -1) {
        props.lastSuccess[lastSuccessByReportTypeIndex] = data.responseLastSuccess;
      }
    });
  }, [props.lastSuccess]);

  useEffect(() => {
    // do anything with the selected/uploaded files
    if (pickerData) {
      pickerData.docs.map(async (i) => {
        const index = props.brandReports.findIndex((b) => b.reportType === currentReportType);

        props.brandReports[index] = {
          reportName: currentReportName,
          reportType: currentReportType,
          folderName: i.name,
          folderId: i.id,
        };
        // Set the service account as a writer
        try {
          await ApiWithCustomToken.post(
            `https://www.googleapis.com/drive/v3/files/${i.id}/permissions`,
            {
              type: 'user',
              role: 'writer',
              emailAddress: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
        } catch (err) {
          alertService.warn('Write Permission Setting Error on service account.');
        }
      });
      loadData();
      props.onSubmit(props.brandReports, props.isSubmitting);
    }
  }, [pickerData]);

  useEffect(() => {
    async function getTableData() {
      const data = await getUserPreferences({
        list: columns,
        tableName: `users-${accountService.userValue.role}`,
        visibilityModel: visibilityModel,
        defaultVisibilityModel: {},
        loading: setTableLoading,
      });
      if (data) {
        setOrderColumns(data.columns);
        setVisibilityModel(data.visibility);
      }
    }
    getTableData();
  }, [columns, loading, visibilityModel]);

  const classes = useStyles();

  function loadData() {
    setLoading(true);

    brandReportTypes.forEach((r) => {
      let brandReportType = props.brandReports?.find((b) => b.reportType === r.reportType);

      if (!brandReportType) {
        const emptyData = {
          reportType: r.reportType,
          reportName: r.name,
          folderName: '',
          folderId: '',
        };

        props.brandReports.push(emptyData);
      }
    });

    setCount(props.brandReports.length);

    setRows(
      props.brandReports.map((r, id) => {
        return {
          ...r,
          id,
        };
      })
    );
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [props.brandReports, props.lastSuccess]);

  useEffect(() => {
    emitterService.on('topFolder', (brandReports) => {
      setRows(
        brandReports.map((r, id) => {
          return {
            ...r,
            id,
          };
        })
      );

      brandReports.forEach((report, index) => {
        props.brandReports[index] = report;
      });
    });
  }, [props.brandReports]);

  useEffect(() => {
    emitterService.on('topFolderLoading', (isLoading) => {
      setTableLoading(isLoading);
    });
  }, [tableLoading]);

  const handleOpenPicker = (v) => {
    setCurrentReportName(v.reportName);
    setCurrentReportType(v.reportType);

    openPicker({
      clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
      developerKey: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY,
      viewId: 'FOLDERS',
      token: accessToken,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      setSelectFolderEnabled: true,
      // customViews: customViewsArray, // custom view
    });
  };

  const handleSendReportNow = async (v) => {
    if (!v) return;
    if (!v.folderId) {
      alertService.warn('No Folder ID set for this report type.');
      return;
    }
    setLoading(true);
    try {
      const body: any = {
        reportType: v.reportType,
        brandCode: props.brandCode,
      };

      if (fromTo.from && fromTo.to) {
        body.dataStartTime = fromTo.from;
        body.dataEndTime = fromTo.to;
      }
      const {data} = await Api.post(`reports/send-now`, body);
      alertService.success(data);
    } catch (e: any) {
      errorAlert(e.message, e);
    } finally {
      setLoading(false);
    }
  };

  const DataGridToolbar = () => (
    <GridToolbarContainer className="toolbar-container">
      <GridToolbarColumnsButton className={classes.toolbarButton} disabled={loading || tableLoading} />
      <DropDownButton buttonType="text" buttonText="Order Columns" bold loading={loading || tableLoading}>
        <DraggableList
          items={[...orderColumns]}
          ignoreItems={['actions']}
          setItems={(v) => {
            setOrderColumns(v);
            setUserPreferences(
              {
                columnVisibilityModel: visibilityModel,
                columnsOrder: v.map((x) => x.field),
              },
              `users-${accountService.userValue.role}`,
              setTableLoading
            );
          }}
        />
      </DropDownButton>
      <GridPagination />
    </GridToolbarContainer>
  );

  const thisRef = useRef<any>(null);

  const customButtons = [
    {
      customButton: {
        label: <Typography className={classes.googleLabel}>Sign In With Google and Select Folder:</Typography>,
        component: (
          <Button className={classes.googleButton}>
            <img src={GoogleIcon} width={30} height={30} alt="google" />
            <Typography>Google</Typography>
          </Button>
        ),
      },
      onClick: (v) => handleOpenPicker(v),
    },
    {
      icon: <LinkOff className={classes.driveIcons} />,
      onClick: (v) => setDeleteFolder(v),
      tooltip: 'Unlink',
    },
    {
      icon: <Send className={classes.driveIcons} />,
      onClick: (v) => setReportToSend(v),
      tooltip: 'Send Now',
    },
  ];

  function setDeleteFolder(v) {
    props.brandReports[v.id] = {
      reportType: v.reportType,
      reportName: v.reportName,
      folderName: '',
      folderId: '',
    };
    loadData();
    props.onSubmit(props.brandReports, props.isSubmitting);
  }

  useEffect(() => {
    makeColumnFreeze(thisRef, visibilityModel, rows);
  }, [visibilityModel, rows]);

  return (
    <TableContainer>
      <div style={{display: 'none'}}>
        <CustomColumn
          thisRef={thisRef}
          rows={rows}
          buttons={customButtons}
          width={mobile ? '175px' : '260px'}
          height="90px"
        />
      </div>
      <Grid item lg={12} style={{width: '100%'}}>
        <Grid item lg={12} position="relative">
          <DataGrid
            components={{
              Pagination: DataGridToolbar,
              Toolbar: DataGridToolbar,
            }}
            className={`${classes.grid} custom-table drive ${tableLoading && !loading ? 'load-headers' : ''}`}
            onCellEditCommit={(params) => {
              props.onSubmit(folders, props.isSubmitting);
              loadData();
            }}
            style={{width: '100%'}}
            rowHeight={90}
            autoHeight={true}
            pageSize={pageSize}
            page={page}
            loading={loading}
            rowCount={count}
            columnTypes={{filterString: stringColumnType}}
            pagination
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            disableSelectionOnClick={true}
            rows={rows}
            columns={[...orderColumns, fixedPlaceHolder({width: 260})]}
            columnVisibilityModel={visibilityModel}
            rowsPerPageOptions={rowsPerPageOptions}
            onColumnVisibilityModelChange={(newModel) => {
              let data = {};
              Object.entries(newModel).forEach(([x, v]) => {
                if (v === false) {
                  data = {...data, [x]: v};
                }
              });
              const newOrder = getColumnsItems({
                list: columns,
                columnsVisibility: data,
                columnsOrder: orderColumns,
                currentOrder: orderColumns,
              });
              setVisibilityModel(data);
              setOrderColumns(newOrder);
              setUserPreferences(
                {
                  columnVisibilityModel: data,
                  columnsOrder: newOrder.map((x) => x.field),
                },
                `users-${accountService.userValue.role}`,
                setTableLoading
              );
            }}
          />
        </Grid>
        <CustomAlert id="default-alert" />
      </Grid>
      <ModalButton
        modalStyle={classes.reportModal}
        openModal={reportToSend !== null}
        modalTitle="Send for Selected Date Range"
        hideButton
        closable
        onCloseText="Cancel"
        onCloseAction={() => setReportToSend(null)}
        actions={(closeModal): JSX.Element => (
          <Button
            onClick={() => {
              handleSendReportNow(reportToSend);
              closeModal();
            }}
          >
            Send Now
          </Button>
        )}
      >
        {(closeModal): JSX.Element => {
          return (
            <Grid container style={{width: '100%', minWidth: '300px', height: '400px'}}>
              {fromTo.to && fromTo.from && (
                <DateRangePicker
                  customPicker
                  width="320px"
                  disabled={loading}
                  from={fromTo.from}
                  to={fromTo.to}
                  onChange={(x) => {
                    setPeriod && setPeriod('custom');
                    onFromToChange({
                      from: x.selection?.startDate,
                      to: x.selection?.endDate,
                      preset: 'custom',
                      loadData: loadData && loadData,
                      setFromTo: setFromTo && setFromTo,
                    });
                  }}
                />
              )}
            </Grid>
          );
        }}
      </ModalButton>
      <ModalButton
        openModal={selectedReport !== null}
        modalTitle={`Report: ${selectedReport?.fileName}`}
        hideButton
        closable
        onCloseAction={() => setSelectedReport(null)}
      >
        {(closeModal): JSX.Element => {
          return (
            selectedReport && (
              <List dense>
                {Object.entries(selectedReport?.reportResponse).map(([key, value]: any) => {
                  const validate: any = new Date(value).toString();
                  const date = validate !== 'Invalid Date' && moment(validate).format('LLL');
                  return (
                    <ListItem>
                      <ListItemIcon>
                        <Assignment />
                      </ListItemIcon>
                      <ListItemText
                        style={{textTransform: 'capitalize'}}
                        primary={getLabel(key)}
                        secondary={date ? date : value}
                      />
                    </ListItem>
                  );
                })}
              </List>
            )
          );
        }}
      </ModalButton>
    </TableContainer>
  );
}

export {GoogleDrive, useDrivePicker, accessToken};
