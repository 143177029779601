import {useState, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Theme,
} from '@mui/material';
import {DataGrid, GridCellParams, GridColDef} from '@mui/x-data-grid';
import {Edit, Delete} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
// services
import {alertService} from '../../../../services/alert.service';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// components
import Button from '../../../../components/Button/Button';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {buildMenu} from '../../components/menu';
import {MenuStore} from '../../../../App';

const useStyles = makeStyles((theme: Theme) => ({
  inputField: {
    margin: '0.75em 0',
    width: '95%',
  },
  updateBrandForm: {
    fontWeight: 'normal',
  },
  noteLabel: {
    fontSize: 14,
    padding: '1rem .75rem',
    paddingBottom: '0.2rem',
  },
  panel: {
    padding: theme.spacing(1),
  },
}));

function AsinKeywords() {
  const classes = useStyles();
  const rowsLimit = 75;
  const {id} = useParams<Record<string, string | undefined>>();
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('Add');
  const [newKeyword, setNewKeyword] = useState('');
  const [updateKeywordId, setUpdateKeywordId] = useState(null);
  const [count, setCount] = useState(0);
  const [selectedIdxes, setSelectedIdxes] = useState([]);

  const columns: GridColDef[] = [
    {
      field: 'keyword',
      headerName: 'Keyword',
      flex: 0.8,
      type: 'filterString',
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.2,
      type: 'filterString',
      renderCell: (params: GridCellParams) => (
        <>
          <IconButton>
            <Edit onClick={() => handleEditKeyword(params.row)} />
          </IconButton>
          <IconButton>
            <Delete onClick={() => handleDeleteKeyword(params.row)} />
          </IconButton>
        </>
      ),
    },
  ];
  let history = useHistory();
  const menuItems = buildMenu('keyword', id, history);

  async function loadData() {
    try {
      const {data} = await Api.get(`asins/${id}/keywords`);
      setCount(data.count);
      setRows(data.rows);
    } catch (e) {
      errorAlert('Unable to get Asin Keywords', e);
    }
  }

  function handleCreateKeyword() {
    setModalType('Add');
    setModalOpen(true);
    setUpdateKeywordId(null);
  }

  const handleClose = () => {
    setNewKeyword('');
    setModalOpen(false);
  };

  async function handleSave() {
    try {
      await Api.put(`asins/${id}/keywords`, {keyword: newKeyword, keywordId: updateKeywordId ?? null});
      loadData();
      alertService.success('Asin keyword saved successfully');
    } catch (e) {
      errorAlert('Unable to save Asin keyword', e);
    } finally {
      setModalOpen(false);
      setNewKeyword('');
    }
  }

  function handleEditKeyword(record) {
    setModalOpen(true);
    setModalType('Update');
    setNewKeyword(record.keyword);
    setUpdateKeywordId(record.id);
  }

  async function handleDeleteKeyword(record) {
    try {
      await Api.post(`asins/${id}/keywords`, {keywordIds: [record.id]});
      loadData();
      alertService.success('Asin keyword deleted successfully');
    } catch (e) {
      errorAlert('Something went wrong with adding keyword. Please try again later.', e);
    }
  }

  async function handleDeleteKeywords() {
    try {
      await Api.post(`asins/${id}/keywords`, {keywordIds: selectedIdxes});
      loadData();
      alertService.success('Asin keyword deleted successfully');
    } catch (e) {
      errorAlert('Something went wrong with adding keyword. Please try again later.', e);
    }
  }

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = menuItems;
    });
    loadData();
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, []);

  return (
    <Grid container spacing={1} style={{paddingTop: 30}}>
      <Grid container style={{padding: '10px 0'}}>
        <Grid item lg={6} xs={12}>
          <Typography component="h2" variant="h4" color="primary" gutterBottom style={{margin: 0}}>
            Asin Keywords
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            {count <= rowsLimit && (
              <Button size="small" onClick={handleCreateKeyword}>
                Add Keyword
              </Button>
            )}
            &nbsp;&nbsp;&nbsp;
            <Button size="small" onClick={handleDeleteKeywords}>
              Delete Selected
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{modalType} Keywords</DialogTitle>
        <DialogContent dividers={true}>
          <div style={{textAlign: 'center'}}>
            <div className={classes.noteLabel}>
              <label>* Please enter keywords separating by comma!</label>
            </div>
            <TextField
              id="newKeyword"
              label="Keywords"
              multiline
              rows={10}
              variant="outlined"
              className={classes.inputField}
              value={newKeyword}
              onChange={(event) => setNewKeyword(event.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Grid item xs={12}>
        <DataGrid
          className="custom-table"
          autoHeight={true}
          rowCount={count}
          pageSize={10}
          rows={rows}
          columns={columns}
          disableSelectionOnClick={true}
          checkboxSelection={true}
          selectionModel={selectedIdxes}
          onSelectionModelChange={(x: any) => {
            setSelectedIdxes(x.selectionModel);
          }}
        ></DataGrid>
      </Grid>
      <Grid item xs={12}>
        <CustomAlert className={classes.inputField} id="default-alert" />
      </Grid>
    </Grid>
  );
}

export {AsinKeywords};
