import {useEffect, useState, useContext} from 'react';
import {useParams, Link as CustomLink} from 'react-router-dom';
import {Typography, Grid, TextField, Link, Autocomplete, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// services
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// components
import Button from '../../../../components/Button/Button';
import SkeletonLoader from '../../../../components/SkeletonLoader/SkeletonLoader';
// context
import {AppContext} from '../../../../context/AppContext/AppContext';

const useStyles = makeStyles((theme: Theme) => ({
  inputField: {
    margin: '0.75em 0',
    width: '95%',
  },
  buttons: {
    margin: '0.75em 0',
    '& > button:first-of-type': {
      marginRight: '0.5em',
    },
  },
  customGrid: {
    width: '100%',
    minWidth: '350px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px 60px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

export default function CustomAttributes({brand}: {brand?: string}) {
  const {sku, id: brandCode} = useParams<Record<string, string | undefined>>();
  const {marketplaceId} = useParams<Record<string, string | undefined>>();
  const classes = useStyles();
  const [editAttributes, setEditAttributes] = useState(new Set());
  const [isSubmitting, setIsSubmitting] = useState(new Set());
  const [editValues, setEditValues] = useState({});
  const [attributes, setAttributes] = useState([]);
  const [loading, setLoading] = useState(false);

  const {getBrandRoles, brandUtils} = useContext(AppContext);

  async function fetchProduct() {
    setLoading(true);
    const query = brand ? `brand-custom-attribute/${brandCode}` : `/custom-attribute/${marketplaceId}/${sku}`;
    try {
      const {data} = await Api.get(query);
      setAttributes(data);
    } catch (e) {
      errorAlert('Unable to get custom attributes', e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProduct();
    getBrandRoles();
  }, [sku, marketplaceId]);

  const editAttribute = async (name, value) => {
    isSubmitting.add(name);
    setIsSubmitting(new Set(isSubmitting));
    const query = brand ? '/brand-custom-attribute' : `/custom-attribute`;
    const body = brand
      ? {
          brand_code: brandCode,
          name: name,
          value: value,
        }
      : {
          seller_sku: sku,
          marketplace_id: marketplaceId,
          name: name,
          value: value,
        };

    try {
      await Api.post(query, body);
      alertService.success('Successfully edited custom attribute');
      fetchProduct();
      editAttributes.delete(name);
      setEditAttributes(new Set(editAttributes));
      isSubmitting.delete(name);
      setIsSubmitting(new Set(isSubmitting));
    } catch (e) {
      errorAlert('Unable to edit custom attribute', e);
      isSubmitting.delete(name);
      setIsSubmitting(new Set(isSubmitting));
    }
  };

  return (
    <>
      {brand && accountService.userValue.role === 'admin' && (
        <Grid container justifyContent={'flex-end'}>
          <CustomLink to={`/brands-custom-attributes?brand=${brand}`}>
            <Button>Add Custom Attributes</Button>
          </CustomLink>
        </Grid>
      )}
      <Grid container style={{paddingTop: '80px', margin: 'auto', maxWidth: '600px'}}>
        {brand && (
          <Typography component="h2" variant="h5" color="primary" gutterBottom style={{paddingBottom: '30px'}}>
            Custom Attributes for Brand {brandCode}
          </Typography>
        )}

        {loading ? (
          <Grid container>
            <SkeletonLoader numberOfLines={10} xs={12} height={25} />
          </Grid>
        ) : (
          <Grid item className={classes.customGrid}>
            {attributes?.map((x) => {
              return (
                <div key={x.name}>
                  <Typography component="h2" variant="h6" color="secondary" gutterBottom>
                    {x?.name}
                  </Typography>
                  {editAttributes.has(x.name) ? (
                    <div>
                      {x?.type === 'USER' ? (
                        <Autocomplete
                          options={brandUtils?.brandRoles}
                          style={{width: '100%'}}
                          size="small"
                          defaultValue={brandUtils?.brandRoles?.find((y) => y.id === x.value)}
                          getOptionLabel={(x) => x.email}
                          onChange={(e, v) => setEditValues({...editValues, [x.name]: v?.id ? v.id : ''})}
                          renderInput={(params) => <TextField size="small" {...params} label="Users" />}
                        />
                      ) : (
                        <TextField
                          id="filled-basic"
                          style={{width: '100%'}}
                          size="small"
                          label={x.name}
                          variant="outlined"
                          defaultValue={x.value}
                          onChange={(e) => setEditValues({...editValues, [x.name]: e.target.value})}
                        />
                      )}
                      <div className={classes.buttons}>
                        <Button
                          style={{margin: '10px 5px 15px 0'}}
                          color="primary"
                          onClick={() => {
                            if (editValues[x.name] && editValues[x.name] !== x.type) {
                              editAttribute(x.name, editValues[x.name]);
                            } else {
                              editAttributes.delete(x.name);
                              setEditAttributes(new Set(editAttributes));
                            }
                          }}
                          disabled={isSubmitting.has(x.name)}
                        >
                          Save
                        </Button>
                        <Button
                          style={{margin: '10px 5px 15px 0'}}
                          onClick={() => {
                            editAttributes.delete(x.name);
                            setEditAttributes(new Set(editAttributes));
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {x?.type === 'LINK' ? (
                        <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                          <Link href={x.value}>
                            {x.value}
                            <br />
                          </Link>
                        </Typography>
                      ) : x?.type === 'USER' ? (
                        <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                          {x?.value ? brandUtils?.brandRoles?.find((y) => y.id === x.value).email : 'N/A'}
                        </Typography>
                      ) : (
                        <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                          {x?.value ? x.value : 'N/A'}
                        </Typography>
                      )}
                      <div className={classes.buttons}>
                        <Button
                          style={{margin: '10px 5px 15px 0'}}
                          onClick={() => {
                            editAttributes.add(x.name);
                            setEditAttributes(new Set(editAttributes));
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </Grid>
        )}
      </Grid>
      <CustomAlert className={classes.inputField} id="default-alert" />
      {/*</Grid>*/}
      {/*</Grid>*/}
    </>
  );
}
