import {useEffect, useState} from 'react';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import {Grid, Typography, Divider, ListItem, ListItemText, ListItemIcon} from '@mui/material';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';
import {Error, Warning, CheckCircle, DateRange, Person, Email, History, Code, Public} from '@mui/icons-material/';
import {getMarketplaceById} from '@bizon/amazon-ids';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// services
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';
import {rowsPerPageOptions} from '../../../../utils/constants';
// components
import CollapsibleDataGrid from '../../../../components/CollapsibleDataGrid/CollapsibleDataGrid';
import Icons from '../../../../components/Icons/Icons';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// styles
import {useStyles} from '../../Styles';

export default function AmazonListingsItemActivity() {
  const classes = useStyles();
  const {marketplaceId, sku} = useParams<Record<string, string | undefined>>();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [listingRows, setListingRows] = useState([]);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Update Time',
      width: 180,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => (
        <Typography>{moment(params.row.created_at).format('YYYY-MM-DD HH:mm:ss')}</Typography>
      ),
    },
    {field: 'version', headerName: 'Version', width: 120, filterable: false, sortable: false, disableColumnMenu: true},
    {
      field: 'new_version',
      headerName: 'New Version',
      width: 120,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'submissionId',
      headerName: 'Submission ID',
      width: 300,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'marketplace_id',
      headerName: 'MarketPlace',
      width: 120,
      filterable: false,
      sortable: false,
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const {code} = params.row.marketplace_id ? getMarketplaceById(params.row.marketplace_id) : null;
        return (
          <Typography className={code && classes.iconFlag}>
            {code ? <Icons name={code.toUpperCase()} tooltip /> : 'N/A'}
          </Typography>
        );
      },
    },
    {
      field: 'seller_id',
      headerName: 'Seller Id',
      width: 150,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'user_id',
      headerName: 'User',
      flex: 1,
      minWidth: 230,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return <Typography>{params.row.user?.email || 'N/A'}</Typography>;
      },
    },
    {field: 'status', headerName: 'Status', width: 180, filterable: false, sortable: false, disableColumnMenu: true},
    {
      field: 'issues',
      headerName: 'Issues',
      width: 120,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return <Typography>{params.row.issues?.length || 0}</Typography>;
      },
    },
  ];

  let defaultQuery;
  function getDefaultQuery() {
    const params = {filter: null, page, pageSize, sort};
    return params;
  }

  useEffect(() => {
    defaultQuery = getDefaultQuery();
    loadData(defaultQuery);
  }, []);

  async function loadData(params) {
    setLoadingData(true);
    setSelectedRow(null);
    try {
      const {data} = await Api.get(`amazon-listings/user-activity?marketplace_id=${marketplaceId}&sku=${sku}`, {
        params,
      });
      setCount(data.count);
      setListingRows(
        data.rows.map((x, i) => {
          return {id: i, ...x};
        })
      );
    } catch (e) {
      errorAlert('Unable to load data', e);
    } finally {
      setLoadingData(false);
    }
  }

  function handleChangePage(params) {
    setPage(params.page);
    loadData({filter, page: params.page, pageSize, sort});
  }

  function handleChangePageSize(params: any) {
    setPageSize(params);
    setPage(0);
    loadData({filter, page, pageSize: params, sort});
  }

  const activityData = {
    status: {label: 'Status', icon: <CheckCircle />},
    created_at: {label: 'Created At', icon: <DateRange />},
    version: {label: 'Version', icon: <History />},
    new_version: {label: 'New Version', icon: <History />},
    marketplace_id: {label: 'MarketPlace', icon: <Public />},
    seller_id: {label: 'Seller ID', icon: <Person />},
    user_id: {label: 'User ID', icon: <Person />},
    user: {label: 'User Email', icon: <Email />},
    sku: {label: 'SKU', icon: <Code />},
    submissionId: {label: 'Submission ID', icon: <Code />},
  };

  const issueDetails = (index) => (
    <div>
      {listingRows[index]?.status === 'INVALID' ? (
        listingRows[index]?.issues.map((issue, i) => {
          return (
            <div
              className={issue.severity === 'ERROR' ? 'issue-error' : 'issue-warning'}
              key={issue.severity + '_' + i}
            >
              <Typography style={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                {issue.severity === 'ERROR' ? <Error /> : <Warning />} {issue.severity} {issue.code}
              </Typography>
              <Typography>{issue.message}</Typography>
              <Divider style={{margin: '20px 0'}} />
            </div>
          );
        })
      ) : (
        <div>
          {listingRows[index] &&
            Object.entries(listingRows[index]).map(([key, value]) => {
              let data = listingRows[index]?.[key];
              if (key === 'user') {
                data = listingRows[index]?.[key].email;
              }
              if (key === 'marketplace_id') {
                const code = getMarketplaceById(listingRows[index]?.[key])?.code;
                data = code ? (
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Icons name={code.toUpperCase()} tooltip /> {code.toUpperCase()}
                  </div>
                ) : (
                  code
                );
              }
              if (key === 'created_at') {
                data = moment(listingRows[index]?.[key]).format('YYYY-MM-DD HH:mm:ss');
              }
              return (
                activityData[key] &&
                listingRows[index].new_version && (
                  <ListItem key={key} className="activity-details">
                    <ListItemIcon>{activityData[key]?.icon}</ListItemIcon>

                    <ListItemText primary={activityData[key]?.label} secondary={data} />
                  </ListItem>
                )
              );
            })}
        </div>
      )}
    </div>
  );

  return (
    <Grid container spacing={4} justifyContent="center" className={classes.activityIssues}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CollapsibleDataGrid
          className="custom-table"
          pageSize={pageSize}
          rowCount={count}
          loading={loadingData}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          disableSelectionOnClick={true}
          onPageChange={(params) => handleChangePage(params)}
          onPageSizeChange={(v) => handleChangePageSize(v)}
          rows={listingRows}
          columns={columns}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          accordionContent={issueDetails(selectedRow)}
          rowClassName="hover-row"
          clickable={true}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CustomAlert id="default-alert" />
      </Grid>
    </Grid>
  );
}
