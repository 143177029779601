import {useEffect, useRef} from 'react';
import * as Yup from 'yup';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useHistory} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
// components
import LoginView from '../../components/LoginView/LoginView';
import {CustomAlert} from '../Login/components/CustomAlert';
// utils
import {Api} from '../../utils/api';
import {withTracker} from '../../utils/withTracker';
// services
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
import mixPanel from '../../services/mixpanel';
declare global {
  interface Window {
    grecaptcha: any;
    LinkMink: any;
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  logoImg: {
    width: '100%',
    maxWidth: '18em',
    margin: '3em 0',
  },
  inputField: {
    margin: '0.5em',
    width: '95%',
  },
}));

function FFPRegistration() {
  let history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const linkMink = window.LinkMink('pub_live_OPW3qCtW5JxhJ5Xvwua5');
  const lm_data = linkMink?.getReferralData();
  document.title = 'FFP Registration';

  useEffect(() => {
    if (accountService.userValue) {
      history.push('/account/connectors');
    }
  }, []);

  useEffect(() => {
    if (urlParams.get('couponCode')) {
      window.localStorage.setItem('couponCode', urlParams.get('couponCode'));
    }
    if (urlParams.get('distinct_id')) {
      mixPanel({
        eventName: 'FFP Registration',
        registrationId: urlParams.get('distinct_id'),
      });
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);
  }, []);

  const classes = useStyles();

  const initialValues = {
    email: '',
    password: '',
    passwordConfirmation: '',
    captcha: '',
  };
  const formRef = useRef(null);

  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Email is required')
      .matches(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        'Email not valid'
      ),
    brandName: Yup.string().required('Brand name is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Password must contain at least 8 characters, one uppercase, one number and one special case character'
      ),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  async function onSubmit(data, {setSubmitting}) {
    setSubmitting(true);
    alertService.clear();
    alertService.info(<> Creating account ...</>);
    try {
      await window.grecaptcha.ready((_) => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, {action: 'submit'})
          .then(async (token) => {
            if (token) {
              const response = await Api.post('ffp/sign-up', {
                email: data.email.toLowerCase(),
                password: data.password,
                brandName: data.brandName,
                recatpchaToken: token,
                linkMinkData: lm_data || null,
              });
              if (response.statusText === 'Created' && response.data.id) {
                alertService.success(<> Account created succesfully. Signing in now...</>, {autoClose: false});
                mixPanel({
                  eventName: 'FFP Registration Complete',
                  registrationId: data.email.toLowerCase(),
                });
                withTracker('Submit', 'FFP Registration Complete', data.brandName);
                setTimeout(
                  () =>
                    accountService
                      .login(data.email, data.password)
                      .then(() => {
                        setSubmitting(false);
                        if (urlParams.get('couponCode')) {
                          history.push('/account/billing?couponCode=' + urlParams.get('couponCode').toString());
                        } else {
                          history.push('/account/connectors');
                        }
                      })
                      .catch((error) => {
                        setSubmitting(false);
                        alertService.clear();
                        if (error.response && error.response.status === 401) {
                          alertService.error('Email or password is incorrect');
                        } else {
                          alertService.error(error.message);
                        }
                      }),
                  3000
                );
              }
            }
          });
      });
    } catch (error: any) {
      setSubmitting(false);
      if (error.response.data && error.response.data.message) {
        alertService.error(error.response.data.message);
      } else {
        alertService.error(error.message);
      }
    }
  }

  const loginFields = [
    {
      label: 'Email',
      name: 'email',
      icon: 'email',
      type: 'text',
    },
    {
      label: 'Seller Central Account Name',
      name: 'brandName',
      icon: 'user',
      type: 'text',
    },
    {
      label: 'Password',
      name: 'password',
      icon: 'password',
      type: 'password',
    },
    {
      label: 'Confirm Password',
      name: 'passwordConfirmation',
      icon: 'password',
      type: 'password',
    },
  ];

  return (
    <>
      <LoginView
        ref={formRef}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        submitText="Create an Account"
        fields={loginFields}
        formDescription="To begin using Flat File Pro, please complete your registration details below."
        logo={
          <img className={classes.logoImg} src={process.env.PUBLIC_URL + '/FFP_logo.png'} alt="Flat File Pro Logo" />
        }
      />
      <CustomAlert id="default-alert" className={classes.inputField} />
    </>
  );
}

export {FFPRegistration};
