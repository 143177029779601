import {useEffect} from 'react';
import {Typography, Grid} from '@mui/material';
import {useHistory} from 'react-router-dom';
// utils
import {accountService} from '../../../../services/account.service';
// styles
import {useStyles} from '../../Styles';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';

export default function TermsOfService(props) {
  const classes = useStyles();
  let history = useHistory();

  useEffect(() => {
    if (accountService.userValue && accountService.userValue.brand_type !== 'ecom3k') {
      history.push('/');
    }
  }, []);

  const pages = ['/terms-of-service', '/privacy-policy', '/cookie-policy'];

  const page = pages.find((item) => props.location.pathname.includes(item));

  const content = {
    '/terms-of-service': {
      title: 'Terms Of Service',
      content: [
        {
          title: '1. Terms',
          content: (
            <Typography>
              By accessing the website at <a href="/">{window.location.hostname}</a>, you are
              agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you
              are responsible for compliance with any applicable local laws. If you do not agree with any of these
              terms, you are prohibited from using or accessing this site. The materials contained in this website are
              protected by applicable copyright and trademark law.
            </Typography>
          ),
        },
        {
          title: '2. Use License',
          content: (
            <Grid>
              <ol>
                <li>
                  Permission is granted to temporarily download one copy of the materials (information or software) on
                  Sunken Stone’s website for personal, non-commercial transitory viewing only. This is the grant of a
                  license, not a transfer of title, and under this license you may not:
                  <ol>
                    <li>modify or copy the materials;</li>
                    <li>
                      use the materials for any commercial purpose, or for any public display (commercial or
                      non-commercial);
                    </li>
                    <li>attempt to decompile or reverse engineer any software contained on Sunken Stone’s website;</li>
                    <li>remove any copyright or other proprietary notations from the materials; or</li>
                    <li>transfer the materials to another person or “mirror” the materials on any other server.</li>
                  </ol>
                </li>
                <li>
                  This license shall automatically terminate if you violate any of these restrictions and may be
                  terminated by Sunken Stone at any time. Upon terminating your viewing of these materials or upon the
                  termination of this license, you must destroy any downloaded materials in your possession whether in
                  electronic or printed format.
                </li>
              </ol>
            </Grid>
          ),
        },
        {
          title: '3. Disclaimer',
          content: (
            <Grid>
              <ol>
                <li>
                  The materials on Sunken Stone’s website are provided on an ‘as is’ basis. Sunken Stone makes no
                  warranties, expressed or implied, and hereby disclaims and negates all other warranties including,
                  without limitation, implied warranties or conditions of merchantability, fitness for a particular
                  purpose, or non-infringement of intellectual property or other violation of rights.
                </li>
                <li>
                  Further, Sunken Stone does not warrant or make any representations concerning the accuracy, likely
                  results, or reliability of the use of the materials on its website or otherwise relating to such
                  materials or on any sites linked to this site.
                </li>
              </ol>
            </Grid>
          ),
        },
        {
          title: '4. Limitations',
          content: `In no event shall Sunken Stone or its suppliers be liable for any damages (including, without limitation,
                damages for loss of data or profit, or due to business interruption) arising out of the use or inability to
                use the materials on Sunken Stone’s website, even if Sunken Stone or a Sunken Stone authorized representative
                has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not
                allow limitations on implied warranties, or limitations of liability for consequential or incidental damages,
                these limitations may not apply to you.`,
        },
        {
          title: '5. Accuracy of materials',
          content: `The materials appearing on Sunken Stone’s website could include technical, typographical, or photographic
            errors. Sunken Stone does not warrant that any of the materials on its website are accurate, complete or
            current. Sunken Stone may make changes to the materials contained on its website at any time without notice.
            However Sunken Stone does not make any commitment to update the materials.`,
        },
        {
          title: '6. Links',
          content: `Sunken Stone has not reviewed all of the sites linked to its website and is not responsible for the contents
            of any such linked site. The inclusion of any link does not imply endorsement by Sunken Stone of the site. Use
            of any such linked website is at the user’s own risk.`,
        },
        {
          title: '7. Modifications',
          content: `Sunken Stone may revise these terms of service for its website at any time without notice. By using this
        website you are agreeing to be bound by the then current version of these terms of service.`,
        },
        {
          title: '8. Governing Law',
          content: `These terms and conditions are governed by and construed in accordance with the laws of California and you
            irrevocably submit to the exclusive jurisdiction of the courts in that State or location.`,
        },
      ],
    },
    '/privacy-policy': {
      title: 'Privacy Policy',
      header: (
        <Grid>
          <Typography variant="body1">
            Our site uses technologies of third-party partners, such as NextRoll, to help us recognize your device and
            understand how you use our site(s) so that we can improve our services to reflect your interests and serve
            you advertisements about the [products and/or services] that are likely to be of more interest to you.
            Specifically, NextRoll and other third-party partners collect information about your activity on our site(s)
            to enable us to:
          </Typography>
          <ul>
            <li>measure and analyze traffic and browsing activity on our site(s);</li>
            <li>show advertisements for our products and/or services to you on third-party sites;</li>
            <li>measure and analyze the performance of our advertising campaigns;</li>
          </ul>
          <Typography variant="h6" color="secondary">
            Cross-Device
          </Typography>
          <Typography variant="body1">
            We may share data, such as hashed email derived from emails or other online identifiers collected on our
            site(s) with NextRoll. This allows our partners to recognize and deliver your ads across devices and
            browsers. To read more about the technologies used by NextRoll and their cross-device capabilities please
            refer to <a href="https://www.nextroll.com/privacy">NextRoll’s Privacy Notice</a>.
          </Typography>
          <Typography variant="h6" color="secondary">
            Opting-Out
          </Typography>
          <Typography variant="body1">
            Our partners, such as NextRoll, may use non-cookie technologies that may not be impacted by browser settings
            that block cookies. Your browser may not permit you to block such technologies. For this reason, you can use
            the following third-party tools to decline the collection and use of information for the purpose of serving
            you interest-based advertising:
            <ul>
              <li>
                <a href="http://www.networkadvertising.org/choices/">The NAI’s opt-out platform</a>
              </li>
              <li>
                <a href="http://www.youronlinechoices.com/">The EDAA’s opt-out platform</a>
              </li>
              <li>
                <a href="https://optout.aboutads.info/?c=2&lang=EN">The DAA’s opt-out platform</a>
              </li>
            </ul>
            Your privacy is important to us. It is Sunken Stone’s policy to respect your privacy regarding any
            information we may collect from you across our website,{' '}
            <a href="/">{window.location.hostname}</a>, and other sites we own and operate.
          </Typography>
        </Grid>
      ),
      content: [
        {
          title: '1. Information we collect',
          content: (
            <Grid>
              <Typography variant="h6" color="secondary">
                Log data
              </Typography>
              <Typography variant="body1">
                When you visit our website, our servers may automatically log the standard data provided by your web
                browser. It may include your computer’s Internet Protocol (IP) address, your browser type and version,
                the pages you visit, the time and date of your visit, the time spent on each page, and other details.
              </Typography>
              <Typography variant="h6" color="secondary">
                Device data
              </Typography>
              <Typography variant="body1">
                We may also collect data about the device you’re using to access our website. This data may include the
                device type, operating system, unique device identifiers, device settings, and geo-location data. What
                we collect can depend on the individual settings of your device and software. We recommend checking the
                policies of your device manufacturer or software provider to learn what information they make available
                to us.
              </Typography>
              <Typography variant="h6" color="secondary">
                Personal information
              </Typography>
              <Typography variant="body1">
                We may ask for personal information, such as your:
                <ul>
                  <li>Name</li>
                  <li>Email</li>
                  <li>Social media profiles</li>
                  <li>Date of birth</li>
                  <li>Phone/mobile number</li>
                  <li>Home/Mailing address</li>
                  <li>Work address</li>
                  <li>Payment information</li>
                </ul>
              </Typography>
            </Grid>
          ),
        },
        {
          title: 'Legal bases for processing',
          content: (
            <Grid>
              We will process your personal information lawfully, fairly and in a transparent manner. We collect and
              process information about you only where we have legal bases for doing so. These legal bases depend on the
              services you use and how you use them, meaning we collect and use your information only where:
              <ul>
                <li>
                  it’s necessary for the performance of a contract to which you are a party or to take steps at your
                  request before entering into such a contract (for example, when we provide a service you request from
                  us);
                </li>
                <li>
                  it satisfies a legitimate interest (which is not overridden by your data protection interests), such
                  as for research and development, to market and promote our services, and to protect our legal rights
                  and interests;
                </li>
                <li>
                  you give us consent to do so for a specific purpose (for example, you might consent to us sending you
                  our newsletter); or
                </li>
                <li>we need to process your data to comply with a legal obligation.</li>
              </ul>
              Where you consent to our use of information about you for a specific purpose, you have the right to change
              your mind at any time (but this will not affect any processing that has already taken place). We don’t
              keep personal information for longer than is necessary. While we retain this information, we will protect
              it within commercially acceptable means to prevent loss and theft, as well as unauthorised access,
              disclosure, copying, use or modification. That said, we advise that no method of electronic transmission
              or storage is 100% secure and cannot guarantee absolute data security. If necessary, we may retain your
              personal information for our compliance with a legal obligation or in order to protect your vital
              interests or the vital interests of another natural person.
            </Grid>
          ),
        },
        {
          title: '3. Collection and use of information',
          content: (
            <Grid>
              We may collect, hold, use and disclose information for the following purposes and personal information
              will not be further processed in a manner that is incompatible with these purposes:
              <ul>
                <li>to enable you to customise or personalise your experience of our website;</li>
                <li>
                  to enable you to access and use our website, associated applications and associated social media
                  platforms;
                </li>
                <li>to contact and communicate with you;</li>
                <li>for internal record keeping and administrative purposes;</li>
                <li>
                  for analytics, market research and business development, including to operate and improve our website,
                  associated applications and associated social media platforms;
                </li>
                <li>to run competitions and/or offer additional benefits to you;</li>
                <li>
                  for advertising and marketing, including to send you promotional information about our products and
                  services and information about third parties that we consider may be of interest to you;
                </li>
                <li>to comply with our legal obligations and resolve any disputes that we may have; and</li>
                <li>to consider your employment application.</li>
              </ul>
            </Grid>
          ),
        },
        {
          title: '4. Disclosure of personal information to third parties',
          content: (
            <Grid>
              We may disclose personal information to:
              <ul>
                <li>
                  third party service providers for the purpose of enabling them to provide their services, including
                  (without limitation) IT service providers, data storage, web-hosting and server providers, debt
                  collectors, maintenance or problem-solving providers, marketing or advertising providers, professional
                  advisors and payment systems operators;
                </li>
                <li>our employees, contractors and/or related entities;</li>
                <li>sponsors or promoters of any competition we run;</li>
                <li>
                  third parties, including agents or sub-contractors, who assist us in providing information, products,
                  services or direct marketing to you; and
                </li>
                <li>third parties to collect and process data.</li>
              </ul>
            </Grid>
          ),
        },
        {
          title: '5. International transfers of personal information',
          content: (
            <Grid>
              <Typography gutterBottom>
                The personal information we collect is stored and processed in United States, or where we or our
                partners, affiliates and third-party providers maintain facilities. By providing us with your personal
                information, you consent to the disclosure to these overseas third parties.
              </Typography>
              <Typography gutterBottom>
                We will ensure that any transfer of personal information from countries in the European Economic Area
                (EEA) to countries outside the EEA will be protected by appropriate safeguards, for example by using
                standard data protection clauses approved by the European Commission, or the use of binding corporate
                rules or other legally accepted means.
              </Typography>
              <Typography gutterBottom>
                Where we transfer personal information from a non-EEA country to another country, you acknowledge that
                third parties in other jurisdictions may not be subject to similar data protection laws to the ones in
                our jurisdiction. There are risks if any such third party engages in any act or practice that would
                contravene the data privacy laws in our jurisdiction and this might mean that you will not be able to
                seek redress under our jurisdiction’s privacy laws.
              </Typography>
            </Grid>
          ),
        },
        {
          title: '6. Your rights and controlling your personal information',
          content: (
            <Grid>
              <Typography gutterBottom>
                <b> Choice and consent:</b> By providing personal information to us, you consent to us collecting,
                holding, using and disclosing your personal information in accordance with this privacy policy. If you
                are under 16 years of age, you must have, and warrant to the extent permitted by law to us, that you
                have your parent or legal guardian’s permission to access and use the website and they (your parents or
                guardian) have consented to you providing us with your personal information. You do not have to provide
                personal information to us, however, if you do not, it may affect your use of this website or the
                products and/or services offered on or through it.
              </Typography>
              <Typography gutterBottom>
                <b>Information from third parties:</b> If we receive personal information about you from a third party,
                we will protect it as set out in this privacy policy. If you are a third party providing personal
                information about somebody else, you represent and warrant that you have such person’s consent to
                provide the personal information to us.
              </Typography>
              <Typography gutterBottom>
                <b>Restrict:</b> You may choose to restrict the collection or use of your personal information. If you
                have previously agreed to us using your personal information for direct marketing purposes, you may
                change your mind at any time by contacting us using the details below. If you ask us to restrict or
                limit how we process your personal information, we will let you know how the restriction affects your
                use of our website or products and services.
              </Typography>
              <Typography gutterBottom>
                <b>Access and data portability:</b> You may request details of the personal information that we hold
                about you. You may request a copy of the personal information we hold about you. Where possible, we will
                provide this information in CSV format or other easily readable machine format. You may request that we
                erase the personal information we hold about you at any time. You may also request that we transfer this
                personal information to another third party.
              </Typography>
              <Typography gutterBottom>
                <b>Correction:</b> If you believe that any information we hold about you is inaccurate, out of date,
                incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable
                steps to correct any information found to be inaccurate, incomplete, misleading or out of date.
              </Typography>
              <Typography gutterBottom>
                <b>Notification of data breaches:</b> We will comply laws applicable to us in respect of any data
                breach.
              </Typography>
              <Typography gutterBottom>
                <b>Complaints:</b> If you believe that we have breached a relevant data protection law and wish to make
                a complaint, please contact us using the details below and provide us with full details of the alleged
                breach. We will promptly investigate your complaint and respond to you, in writing, setting out the
                outcome of our investigation and the steps we will take to deal with your complaint. You also have the
                right to contact a regulatory body or data protection authority in relation to your complaint.
              </Typography>
              <Typography>
                <b>Unsubscribe:</b> To unsubscribe from our e-mail database or opt-out of communications (including
                marketing communications), please contact us using the details below or opt-out using the opt-out
                facilities provided in the communication.
              </Typography>
            </Grid>
          ),
        },
        {
          title: '7. Cookies',
          content:
            'We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified. Please refer to our Cookie Policy for more information.',
        },
        {
          title: '8. Business transfers',
          content:
            'If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include data among the assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may continue to use your personal information according to this policy.',
        },
        {
          title: '9. Limits of our policy',
          content:
            'Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.',
        },
        {
          title: '10. Changes to this policy',
          content: `At our discretion, we may change our privacy policy to reflect current acceptable practices. We will take reasonable steps to let users know about changes via our website. Your continued use of this site after any changes to this policy will be regarded as acceptance of our practices around privacy and personal information.

            If we make a significant change to this privacy policy, for example changing a lawful basis on which we process your personal information, we will ask you to re-consent to the amended privacy policy.`,
        },
      ],
      footer: (
        <Grid>
          <Typography>
            <b>Sunken Stone Data Controller</b>
          </Typography>
          <Typography>Adam Weiler</Typography>
          <Typography>adam@sunkenstone.com</Typography>
          <br></br>
          <Typography>This policy is effective as of 21 November 2018.</Typography>
        </Grid>
      ),
    },
    '/cookie-policy': {
      title: 'Cookie Policy',
      content: [
        {
          title: 'What is a cookie?',
          content: (
            <Grid>
              <Typography gutterBottom>
                A cookie is a small piece of data that a website stores on your device when you visit, typically
                containing information about the website itself, a unique identifier that allows the site to recognise
                your web browser when you return, additional data that serves the purpose of the cookie, and the
                lifespan of the cookie itself.
              </Typography>
              <Typography gutterBottom>
                Cookies are used to enable certain features (eg. logging in), to track site usage (eg. analytics), to
                store your user settings (eg. timezone, notification preferences), and to personalise your content (eg.
                advertising, language).
              </Typography>
              <Typography gutterBottom>
                Cookies set by the website you are visiting are normally referred to as “first-party cookies”, and
                typically only track your activity on that particular site. Cookies set by other sites and companies
                (ie. third parties) are called “third-party cookies”, and can be used to track you on other websites
                that use the same third-party service.
              </Typography>
            </Grid>
          ),
        },
        {
          title: 'Types of cookies and how we use them',
          content: (
            <Grid>
              <Typography variant="h6">Essential cookies</Typography>
              <Typography>
                Essential cookies are crucial to your experience of a website, enabling core features like user logins,
                account management, shopping carts and payment processing. We use essential cookies to enable certain
                functions on our website.
              </Typography>

              <Typography variant="h6">Performance cookies</Typography>
              <Typography>
                Performance cookies are used in the tracking of how you use a website during your visit, without
                collecting personal information about you. Typically, this information is anonymous and aggregated with
                information tracked across all site users, to help companies understand visitor usage patterns, identify
                and diagnose problems or errors their users may encounter, and make better strategic decisions in
                improving their audience’s overall website experience. These cookies may be set by the website you’re
                visiting (first-party) or by third-party services. We use performance cookies on our site.
              </Typography>

              <Typography variant="h6">Functionality cookies</Typography>
              <Typography>
                Functionality cookies are used in collecting information about your device and any settings you may
                configure on the website you’re visiting (like language and timezone settings). With this information,
                websites can provide you with customised, enhanced or optimised content and services. These cookies may
                be set by the website you’re visiting (first-party) or by third-party service. We use functionality
                cookies for selected features on our site.
              </Typography>

              <Typography variant="h6">Targeting/advertising cookies</Typography>
              <Typography>
                Targeting/advertising cookies are used in determining what promotional content is more relevant and
                appropriate to you and your interests. Websites may use them to deliver targeted advertising or to limit
                the number of times you see an advertisement. This helps companies improve the effectiveness of their
                campaigns and the quality of content presented to you. These cookies may be set by the website you’re
                visiting (first-party) or by third-party services. Targeting/advertising cookies set by third-parties
                may be used to track you on other websites that use the same third-party service. We use
                targeting/advertising cookies on our site.
              </Typography>
            </Grid>
          ),
        },
        {
          title: 'Third-party cookies on our site',
          content: (
            <Grid>
              <Typography gutterBottom>
                We may employ third-party companies and individuals on our websites—for example, analytics providers and
                content partners. We grant these third parties access to selected information to perform specific tasks
                on our behalf. They may also set third-party cookies in order to deliver the services they are
                providing. Third-party cookies can be used to track you on other websites that use the same third-party
                service. As we have no control over third-party cookies, they are not covered by Sunken Stone’s cookie
                policy.
              </Typography>
              <Typography variant="h6" style={{padding: '20px 0 20px 0'}}>
                Our third-party privacy promise
              </Typography>
              <Typography gutterBottom>
                We review the privacy policies of all our third-party providers before enlisting their services to
                ensure their practices align with ours. We will never knowingly include third-party services that
                compromise or violate the privacy of our users.
              </Typography>
            </Grid>
          ),
        },
        {
          title: 'How you can control or opt out of cookies',
          content: (
            <Grid>
              <Typography gutterBottom>
                If you do not wish to accept cookies from us, you can instruct your browser to refuse cookies from our
                website. Most browsers are configured to accept cookies by default, but you can update these settings to
                either refuse cookies altogether, or to notify you when a website is trying to set or update a cookie.
              </Typography>
              <Typography gutterBottom>
                If you browse websites from multiple devices, you may need to update your settings on each individual
                device.
              </Typography>
              <Typography gutterBottom>
                Although some cookies can be blocked with little impact on your experience of a website, blocking all
                cookies may mean you are unable to access certain features and content across the sites you visit.
              </Typography>
            </Grid>
          ),
        },
      ],
    },
  };

  return (
    <TableContainer className={classes.infoPage}>
      <Typography variant="h4" color="secondary">
        {content[page]?.title}
      </Typography>
      <Grid>
        {content[page]?.header && content[page]?.header}
        {content[page]?.content?.map((item, key) => {
          return (
            <Grid key={key}>
              <Typography variant="h5" color="secondary" style={{padding: '30px 0 20px 0'}}>
                {item.title}
              </Typography>
              <Typography variant="body1">{item.content}</Typography>
            </Grid>
          );
        })}
        <Typography style={{padding: '50px 0'}}>{content[page]?.footer && content[page]?.footer}</Typography>
      </Grid>
    </TableContainer>
  );
}
