import {useEffect, useState} from 'react';
import {Grid, Tab, Tabs, useTheme} from '@mui/material';
import {TabPanel, TabContext} from '@mui/lab';
import {useHistory, useParams} from 'react-router-dom';
import {CustomAlert} from '../../Login/components/CustomAlert';
import CustomTabs from '../../../components/CustomTabs/CustomTabs';
import {AdvertisingPerformanceMain} from '../PerformanceMain';
// services
import {accountService} from '../../../services/account.service';
import mixPanel from '../../../services/mixpanel';

const tabNames = [
  {tabKey: 'sp', tabLabel: 'Sponsored Products'},
  {tabKey: 'sb', tabLabel: 'Sponsored Brands'},
  {tabKey: 'attribution', tabLabel: 'Attribution'},
  {tabKey: 'dsp', tabLabel: 'DSP'},
];

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

function AdvertisingPerformance() {
  let history = useHistory();
  const theme = useTheme();
  const {adsType} = useParams<Record<string, string | undefined>>();
  const [value, setValue] = useState(adsType);

  useEffect(() => {
    document.title = `Stonehenge.ai/Advertising Performance`;
  }, []);

  useEffect(() => {
    const tab = tabNames.find((tab) => tab.tabKey === value);
    ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
      mixPanel({
        eventName: `Advertising - ${tab.tabLabel}`,
      });
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigateTo = (tabName:string) => {
    history.push(`/advertising-performance/${tabName}`);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TabContext value={value}>
          <CustomTabs position={'static'} color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
              textColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
              variant="scrollable"
              scrollButtons="auto"
              style={{justifyContent: 'center'}}
            >
              {tabNames.map(({tabKey, tabLabel}, idx) => (
                <Tab
                  label={tabLabel}
                  value={tabKey}
                  key={tabKey}
                  {...a11yProps(idx)}
                  onClick={() => navigateTo(tabKey)}
                />
              ))}
            </Tabs>
          </CustomTabs>
          <TabPanel value={adsType} style={{padding: '24px 0'}}>
            <AdvertisingPerformanceMain adsType={adsType} />
          </TabPanel>
        </TabContext>
      </Grid>
      <Grid item xs={12}>
        <CustomAlert id="ads-performance-tab-alert" />
      </Grid>
    </Grid>
  );
}

export {AdvertisingPerformance};
