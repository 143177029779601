import {Draggable} from 'react-beautiful-dnd';
import {makeStyles} from '@mui/styles';
import {ListItem, ListItemText, ListItemIcon, Theme} from '@mui/material';
import {DragHandle} from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
  draggingListItem: {
    background: theme.palette.mode === 'dark' ? '#757575' : 'rgb(235,235,235)',
    textTransform: 'capitalize',
    padding: '3px 16px',
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
  },
  hideListItem: {
    visibility: 'hidden',
    height: 0,
    padding: 0,
  },
  listItem: {
    padding: '3px 16px',
    textTransform: 'capitalize',
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
  },
}));

type DraggableListItemProps = {
  item: any;
  index: number;
  disabled?: boolean;
};

const DraggableListItem = ({item, index, disabled}: DraggableListItemProps) => {
  const classes = useStyles();
  return disabled ? (
    <ListItem className={item.hide ? classes.hideListItem : classes.listItem}>
      <ListItemIcon>
        <DragHandle />
      </ListItemIcon>
      <ListItemText primary={item.headerName} />
    </ListItem>
  ) : (
    <Draggable draggableId={item.id} index={index} style={disabled ? {PointerEvent: 'none'} : {}}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={
            snapshot.isDragging ? classes.draggingListItem : item.hide ? classes.hideListItem : classes.listItem
          }
        >
          <ListItemIcon>
            <DragHandle />
          </ListItemIcon>
          <ListItemText primary={item.headerName} />
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
