import {Api} from '../../../utils/api';

const baseUrl = '/performance';

async function InsightsApiCall({
  url,
  data,
  sku,
}: {
  url: string;
  data: any;
  sku?: boolean;
  finally?: any;
  onSucess?: any;
  onError?: any;
}) {
  if (!data.from && !data.to) {
    return;
  }

  Object.entries(data).forEach(([key, value]: any) => {
    if (value === null || value.length === 0) {
      delete data[key];
    }
    if (sku) {
      delete data['brands'];
    }
  });

  const newQuery = Object.keys(data)
    .map((key) => `${key}=${data[key]}`)
    .join('&');

  const queryString = baseUrl + url + '?' + newQuery;

  const response = await Api.get(queryString);
  return response;
}

export {InsightsApiCall};
