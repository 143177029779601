import {memo} from 'react';
import {FastField, getIn} from 'formik';
import {
  Grid,
  FormControlLabel,
  Typography,
  Checkbox,
  AutocompleteRenderInputParams,
  TextField as TextFieldMaterial,
  FormHelperText,
} from '@mui/material';
import {DatePicker} from 'formik-mui-lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {Autocomplete, TextField} from 'formik-mui';
import {LocalizationProvider} from '@mui/lab';
// utils
import getLabel from '../../../utils/getLabel';
import moment from 'moment';

const shouldComponentUpdate = (nextProps, currentProps) => {
  return (
    nextProps.name !== currentProps.name ||
    nextProps.required !== currentProps.required ||
    nextProps.disabled !== currentProps.disabled ||
    nextProps.readOnly !== currentProps.readOnly ||
    nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting ||
    Object.keys(nextProps).length !== Object.keys(currentProps).length ||
    getIn(nextProps.formik.values, currentProps.name) !== getIn(currentProps.formik.values, currentProps.name)
  );
};
interface ListingsInputFieldsProps {
  name: string;
  label: string;
  id?: string;
  value?: string | string[] | any;
  onChange?: any;
  type?: string;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  step?: number;
  autoComplete?: string;
  options?: any[];
  optionsLabel?: any;
  optionsKey?: string;
  disabled?: boolean;
  onKeyPress?: any;
  className?: string;
  error?: any;
  helperText?: any;
  required?: boolean;
  styles?: any;
  autoFocus?: boolean;
  fullWidth?: boolean;
  showError?: any;
  multiple?: boolean;
}

export default memo(function ListingsInputFields({
  name,
  label,
  value,
  type,
  required,
  options,
  optionsLabel,
  disabled,
  onKeyPress,
  className,
  styles,
  autoFocus,
  fullWidth,
  showError,
  multiple,
  onChange,
}: ListingsInputFieldsProps) {
  let inputField;

  switch (type) {
    case 'date':
      inputField = (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FastField
              shouldUpdate={(x, y) => shouldComponentUpdate(x, y)}
              component={DatePicker}
              autoOk
              inputFormat="dd/MM/yyyy"
              format="yyyy-MM-dd"
              variant="outlined"
              label={getLabel(label)}
              id={name}
              size="small"
              name={name}
              disabled={disabled}
              value={value && value !== '' ?moment(value).startOf('day').toISOString():null}
              fullWidth
              onChange={(e) => {
                if (e) {
                  onChange(e.toString());
                } else {
                  onChange('');
                }
              }}
              onBlur={() => {
                return;
              }}
            />
          </LocalizationProvider>
          {showError && (
            <FormHelperText style={{color: 'red', textTransform: 'capitalize'}}>{showError}</FormHelperText>
          )}
        </>
      );
      break;
    case 'autocomplete':
      const newValue = value === '' || value === undefined ? null : value;
      inputField = (
        <>
          {multiple ? (
            <FastField
              id={name}
              name={name}
              size="small"
              shouldUpdate={(x, y) => shouldComponentUpdate(x, y)}
              component={Autocomplete}
              options={options}
              getOptionLabel={optionsLabel}
              required={required}
              disabled={disabled}
              multiple={multiple}
              value={value}
              onChange={(e, v) => {
                onChange(v);
              }}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextFieldMaterial {...params} label={label} variant={'outlined'} />
              )}
            />
          ) : (
            <FastField
              id={name}
              name={name}
              size="small"
              shouldUpdate={(x, y) => shouldComponentUpdate(x, y)}
              component={Autocomplete}
              options={options}
              getOptionLabel={optionsLabel}
              isOptionEqualToValue={(option, value) => option === value}
              required={required}
              disabled={disabled}
              value={newValue}
              onChange={(e, v) => {
                onChange(v);
              }}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextFieldMaterial {...params} label={label} variant={'outlined'} />
              )}
            />
          )}
          {showError && (
            <FormHelperText style={{color: 'red', textTransform: 'capitalize'}}>{showError}</FormHelperText>
          )}
        </>
      );
      break;
    case 'checkbox':
      inputField = (
        <>
          <Typography
            key={`${label}-checkbox-group`}
            id={`${name}-checkbox-group`}
            style={{textTransform: 'capitalize'}}
          >
            {getLabel(label)}
          </Typography>
          {options.map((status, statusLoop) => {
            return (
              <FastField
                name={name}
                key={`${label}-checkbox-group-${statusLoop}`}
                shouldUpdate={(x, y) => shouldComponentUpdate(x, y)}
              >
                {({field, form}) => (
                  <FormControlLabel
                    key={`${label}-checkbox-group-${statusLoop}`}
                    disabled={disabled}
                    control={
                      <Checkbox
                        checked={field.value.includes(status)}
                        onChange={() => {
                          if (field.value.includes(status)) {
                            const nextValue = field.value.filter((v) => v !== status);
                            form.setFieldValue(name, nextValue);
                          } else {
                            const nextValue = field.value.concat(status);
                            form.setFieldValue(name, nextValue);
                          }
                        }}
                      />
                    }
                    label={status}
                  />
                )}
              </FastField>
            );
          })}
        </>
      );
      break;
    default:
      inputField = (
        <>
          <FastField
            shouldUpdate={(x, y) => shouldComponentUpdate(x, y)}
            component={TextField}
            variant="outlined"
            label={getLabel(label)}
            id={name}
            size="small"
            name={name}
            fullWidth
            value={value}
            multiline
            disabled={disabled}
            readOnly={disabled}
            onKeyPress={(e) => onKeyPress && onKeyPress(e)}
            onChange={(e) => {
              onChange && onChange(e.target.value);
            }}
            autoFocus={autoFocus && autoFocus}
            style={{heigth: '100%'}}
            onBlur={() => {
              return;
            }}
          />
          {showError && (
            <FormHelperText style={{color: 'red', textTransform: 'capitalize'}}>{showError}</FormHelperText>
          )}
        </>
      );
  }

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={(type === 'string' && value?.length > 50) || (disabled && value?.length > 50) || fullWidth ? 12 : 6}
      lg={(type === 'string' && value?.length > 50) || (disabled && value?.length > 50) || fullWidth ? 12 : 6}
      key={name}
      style={styles}
      className={`${className ? className : ''}`}
    >
      {inputField}
    </Grid>
  );
});
