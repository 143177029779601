import React, {useEffect, useState, memo} from 'react';
import _, {isArray} from 'lodash';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Divider,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Tooltip,
} from '@mui/material';
import {Prompt} from 'react-router-dom';
import {
  Link,
  Code,
  Message,
  Assignment,
  SouthEast,
  ExpandMore,
  Error,
  Warning,
  Inventory,
  Image,
  Help,
} from '@mui/icons-material';
import {Formik, Form, useFormikContext, FieldArray} from 'formik';
// services
import {alertService} from '../../../../services/alert.service';
// styles
import {useStyles} from '../../Styles';
// components
import Spacer from '../../../../components/Spacer/Spacer';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import ListingsInputFields from '../../components/ListingsInputFields';
import ListingsAttrContainer from '../../components/ListingsAttrContainer';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import ImageCard from '../../components/ImageCard';
import AttributeList, {createNewField, getOptions, getError} from '../../components/attributeList';
// utils
import {getArticle} from '../../../../utils/FFPBeacon';
import {Api, errorAlert} from '../../../../utils/api';
import getLabel from '../../../../utils/getLabel';

const statuses = ['DISCOVERABLE', 'BUYABLE'];
const disableFields = {
  attributes: ['asin', 'fnsku', 'product_site_launch_date'],
};

function getButtonLabelObj(isSupportedProductType: boolean) {
  if (isSupportedProductType === true) {
    return {normal: 'Publish', loading: 'Publishing'};
  } else {
    return {normal: 'Download', loading: 'Downloading '};
  }
}

export default memo(function AmazonListingsItemDetail({
  marketplaceId,
  sku,
  version,
}: {
  marketplaceId: string;
  sku: string;
  version: string;
}) {
  const classes = useStyles();
  const [isDownloading, setDownloading] = useState(false);
  const [amazonListingsItem, setAmazonListingsItem] = useState(null);
  const [issues, setIssues] = useState({error: [], warning: []});
  const [imagesCount, setImagesCount] = useState(10);
  const [filter, setFilter] = useState('');
  const [filteredAttributes, setFilteredAttributes] = useState({});
  const [validations, setValidations] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [attributeDefaultValues, setAttributeDefaultValues] = useState<any>({});
  const [errorList, setErrorList] = useState<any>([]);
  const [itemRequiredFields, setItemRequiredFields] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const UnsavedChangesAlert = () => {
    const formik = useFormikContext();
    return (
      <Prompt
        when={formik?.dirty && formik?.submitCount === 0}
        message="Are you sure you want to leave? You have unsaved changes."
      />
    );
  };

  const orderData = (data) => {
    let newObj = {};
    const orderAttr = [
      'classification',
      'unit',
      'length',
      'width',
      'height',
      'end_at',
      'start_at',
      '_enums',
      'price',
      'quantity',
      'type',
      'currency',
    ];

    const specialAttr = ['hazmat', 'ghs', 'purchasable_offer', 'item_dimensions', 'item_package_dimensions'];

    if (data?.requiredAttributes) {
      for (const attr of data?.requiredAttributes) {
        if (data.attributes.hasOwnProperty(attr)) {
          if (
            Object.entries(data?.attributes[attr][0])
              .map(([k, v]) => typeof v === 'object')
              .filter((v) => v === true).length < 1
          ) {
            newObj[attr] = data.attributes[attr];
          }
        }
      }
      for (const attr of data?.requiredAttributes) {
        if (data.attributes.hasOwnProperty(attr)) {
          if (
            Object.entries(data?.attributes[attr][0])
              .map(([k, v]) => typeof v === 'object')
              .filter((v) => v === true).length >= 1
          ) {
            newObj[attr] = data.attributes[attr];
          }
        }
      }
    }
    for (const order in data?.attributes) {
      if (
        !specialAttr.includes(order) &&
        Object.entries(data?.attributes[order][0])
          .map(([k, v]) => typeof v === 'object')
          .filter((v) => v === true).length < 1 &&
        !orderAttr.some((r) => Object.keys(data?.attributes[order]?.[0]).includes(r))
      ) {
        newObj[order] = data?.attributes[order];
      }
    }
    for (const order in data?.attributes) {
      if (specialAttr.includes(order)) {
        newObj[order] = data?.attributes[order];
      }
    }
    for (const order in data?.attributes) {
      if (
        Object.entries(data?.attributes[order][0])
          .map(([k, v]) => typeof v === 'object')
          .filter((v) => v === true).length >= 1 ||
        orderAttr.some((r) => Object.keys(data?.attributes[order]?.[0]).includes(r)) ||
        !specialAttr.includes(order)
      ) {
        newObj[order] = data?.attributes[order];
      }
    }
    return {...newObj};
  };

  async function getAmazonListingsItemDetail() {
    setLoading(true);
    let uri = `amazon-listings/item-detail?marketplace_id=${marketplaceId}&sku=${sku}`;
    if (version) uri = uri + `&version=${version}`;
    try {
      const {data} = await Api.get(uri);
      if (data && data?.attributes) {
        setAmazonListingsItem({...data, attributes: orderData(data)});
        if (Object.keys(data.schema_data.properties).length > 0) {
          const getValidations = getValidationSchema(data.schema_data.properties);
          setValidations(getValidations);
          setAttributeDefaultValues(data.schema_data?.$defs);
        }
        setItemRequiredFields(data.schema_data.required);
        setIssues({error: data?.error || [], warning: data?.warning || []});
        setImagesCount(Object.keys(data.productImages).length);
        if (data.disabledAttributes?.length > 0) {
          disableFields.attributes = [...disableFields.attributes, ...data.disabledAttributes];
        }
      } else {
        alertService.error('No listings item found. Ridirecting amazon listings items page ...', {
          autoClose: false,
          id: 'error-alert',
        });
        setTimeout(() => {
          window.location.href = '/amazon-listings-items';
        }, 3000);
      }
    } catch (e) {
      errorAlert('Unable to fetch Amazon Listings Item Details', e);
    } finally {
      setLoading(false);
    }
  }

  async function validateRequiredFields(data) {
    const attributes = () => (
      <>
        <Typography>Please fix the errors before saving the changes</Typography>
        <List>
          {Object.entries(data).map(([key, value]: any, index) => (
            <ListItem key={`${key}-${index}`} onClick={() => scrollToField(`attributes.${key}`)}>
              <ListItemText
                primary={value.map((v, i) => {
                  return (
                    <ListItemText
                      className="error-item"
                      style={{cursor: 'pointer', textTransform: 'capitalize'}}
                      key={`${v.id}-${i}`}
                      primary={getLabel(v.label.split('.')[0])}
                      secondary={v.message}
                    />
                  );
                })}
              />
            </ListItem>
          ))}
        </List>
      </>
    );
    if (Object.keys(data).length > 0) {
      alertService.error(attributes(), {
        autoClose: false,
        id: 'error-alert',
      });
      return false;
    }
    return true;
  }

  const scrollToField = (field) => {
    const scrollDiv = document.getElementById(field)?.offsetTop;
    if (!scrollDiv) {
      return;
    }
    if (field && amazonListingsItem.isSupportedProductType) {
      scrollDiv &&
        document.getElementById(field).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      document.getElementById(field)?.focus();
    }
    if (field && !amazonListingsItem.isSupportedProductType) {
      document.getElementById(field).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      const newElement = document.getElementById(field);
      newElement.classList.add('focus-element');
    }
  };

  async function onSubmit(data: any) {
    setIsSubmitting(true);
    if (Object.keys(errorList).length > 0) {
      validateRequiredFields(errorList);
      setIsSubmitting(false);
      return;
    }

    if (data?.isSupportedProductType !== true) {
      alertService.warn('Product Type is not supported. You should use ffp.');
      setIsSubmitting(false);
      return;
    }

    delete data.original_attributes;
    delete data.schema_data;

    try {
      const res = await Api.post(`amazon-listings/item/edit`, {
        ...data,
        attributes: {...data.attributes, ...data.productImages},
      });
      if (res.data?.status === 'ACCEPTED') {
        alertService.success(
          'The listings submission was accepted for processing. It may take a few minutes to process!'
        );
        setAmazonListingsItem({...data, attributes: {...data.attributes, ...data.productImages}});
      } else {
        const issues = () => (
          <div>            
            The listings submission was not valid and was not accepted for processing. {res.data?.issues?.length} issues
            was detected.
            {res.data?.issues?.map((msg, i) => (
              msg?.attributeNames?.length > 0 && <div key={i}>
                <Tooltip title={msg?.message}>
                <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => scrollToField(msg?.attributeNames[0])} >
                  {msg?.attributeNames[0]}&nbsp;{i !== res.data?.issues?.length - 1 && ', '}
                </span>
                </Tooltip>
              </div>
            ))}
          </div>
        );
        alertService.error(issues(), {
          autoClose: false,
        });
      }
    } catch (e) {
      errorAlert('Error while saving the changes', e);
    } finally {
      setIsSubmitting(false);
    }
  }

  async function onFfpDownload(data: any) {
    setDownloading(true);
    try {
      const {data: itemData} = await Api.post(`amazon-listings/item-ffp-download`, data);
      if (itemData === true) {
        const link = document.createElement('a');
        link.href = itemData.message;
        link.setAttribute('download', `Flat file - ${data.seller_sku}.pdf`);
        link.setAttribute('rel', `noreferrer noopener`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        alertService.success('Ffp was generated successfully. Please check your email.');
      } else {
        alertService.error(itemData?.message);
      }
    } catch (e) {
      errorAlert('Error while downloading the ffp', e);
    } finally {
      setDownloading(false);
    }
  }

  function isDisabled(dataSetKey?: string, attributeKey?: string): boolean {
    return disableFields[dataSetKey]?.includes(attributeKey) || amazonListingsItem.isSupportedProductType !== true;
  }

  function getImageKey(count, index) {
    return index === 0
      ? 'main_product_image_locator'
      : index === count - 1
      ? 'swatch_product_image_locator'
      : `other_product_image_locator_${index}`;
  }

  function updateProductMediaLists(imageUrl: string, index: number, productImages: any) {
    const imageKey = getImageKey(imagesCount, index);
    if (isDisabled('attributes', imageKey)) return;
    const _productImages = {...productImages};
    _productImages[imageKey] = [{..._productImages[imageKey][0], media_location: imageUrl}];
    return _productImages;
  }

  function changeOrderImage(index: number, offSet: number, productImages: any) {
    if (index === 0 && offSet === -1) return;
    if (index === Object.keys(amazonListingsItem.productImages).length - 1 && offSet === 1) return;
    const sourceImageKey = getImageKey(imagesCount, index);
    const targetImagekey = getImageKey(imagesCount, index + offSet);
    if (isDisabled('attributes', targetImagekey)) {
      alertService.error(`You can not move to disabled image position.`);
      return;
    }
    const _productImages = {...productImages};
    const _tmp = _productImages[targetImagekey];
    _productImages[targetImagekey] = _productImages[sourceImageKey];
    _productImages[sourceImageKey] = _tmp;
    return _productImages;
  }

  useEffect(() => {
    setIssues({error: amazonListingsItem?.error || [], warning: amazonListingsItem?.warning || []});
  }, [amazonListingsItem?.error, amazonListingsItem?.warning]);

  const issuesList = (issue, type) => {
    const issues = {
      Code: {icon: <Code />, value: issue.code || 'N/A'},
      Attributes: {
        icon: <Assignment />,
        value: (
          <>
            {issue.attributeNames
              ? issue.attributeNames?.map((x, i) => {
                  const attribute = amazonListingsItem.attributes.hasOwnProperty(x);
                  return (
                    <span
                      key={i}
                      style={{display: 'block'}}
                      className={attribute ? 'attribute-text' : ''}
                      onClick={() => attribute && scrollToField(x)}
                    >
                      {getLabel(x)}
                      {i !== issue.attributeNames?.length - 1 && ','}
                      {attribute && <SouthEast style={{fontSize: '12px', margin: '0px 5px'}} />}
                    </span>
                  );
                })
              : 'N/A'}
          </>
        ),
      },
      Message: {icon: <Message />, value: issue.message || 'N/A'},
    };
    return (
      <List className={`item-issues ${type}`}>
        {Object.entries(issues).map(([key, value]) => {
          return (
            <ListItem key={`${value.value}_${key}`}>
              <ListItemIcon>{value.icon}</ListItemIcon>
              <ListItemText primary={`${key}: `} secondary={value.value || 'N/A'} />
            </ListItem>
          );
        })}
      </List>
    );
  };

  const onSearch = (attributes) => {
    let attr = {};
    const newFilter = Object.entries(attributes).filter(([key, value]) => {
      if (key === 'variation_theme') {
        key = 'parentage_variation_theme';
      }
      const newKey = key.replaceAll(new RegExp('_', 'g'), ' ').toLowerCase();
      const filterData = filter.toLowerCase();
      return newKey.includes(filterData);
    });
    newFilter.forEach(([key, value]) => {
      attr = {...attr, [key]: value};
    });
    setFilteredAttributes(attr);
  };

  useEffect(() => {
    if (amazonListingsItem?.attributes) {
      setFilteredAttributes(amazonListingsItem?.attributes);
    }
  }, [amazonListingsItem?.attributes]);

  useEffect(() => {
    getArticle({filterPath: true});
  }, [loading]);

  let allFields = {};

  const getValidationSchema = (value, currentKey?, parentField?) => {
    let fields = {};
    let keys = currentKey ? [...currentKey] : [];
    let parentFields = {...parentField};

    value &&
      Object.entries(value)
        .filter(([key, v]) => key !== 'marketplace_id' && key !== 'language_tag')
        .forEach(([key, v]: any, index) => {
          if (v.type && v.type === 'object') {
            getValidationSchema(v.properties, [...keys, key], {...parentFields, [[...keys, key].join('.')]: v});
          } else if (v.type && v.type === 'array') {
            if (v.items.properties) {
              getValidationSchema(v.items.properties, [...keys, key], {...parentFields, [[...keys, key].join('.')]: v});
            } else {
              fields = {
                value,
                ...fields,
                ...parentFields,
                [[...keys, key].join('.')]: {...v},
              };
            }
          } else {
            fields = {
              value,
              ...fields,
              ...parentFields,
              [[...keys, key].join('.')]: {...v},
            };
          }
        });

    allFields = {
      ...allFields,
      ...fields,
    };

    return allFields;
  };

  function getErrorLis(values) {
    let errorList = {};
    Object.keys(values.attributes).forEach((attribute) => {
      !attribute.includes('product_image_locator') &&
        values.attributes[attribute].forEach((value, index) => {
          const requiredFields = [];
          const arrayValidation = validations[attribute];
          let required = arrayValidation?.items?.required || arrayValidation?.required;
          let minUniqueItems = arrayValidation?.minUniqueItems;
          let duplicated = false;

          if (required && required?.length > 0) {
            const filteredRequired = required
              .filter((i) => {
                return !getOptions.includes(i);
              })
              .map((i, x) => {
                return `attributes.${attribute}.${i}`;
              });
            requiredFields.push(...filteredRequired);
          }

          if (minUniqueItems) {
            duplicated =
              values.attributes[attribute]
                ?.map((item) => JSON.stringify(item))
                .filter((item, index, self) => self.indexOf(item) !== index).length >= minUniqueItems;
          }

          const getErrors = (currentValue: any, attribute: any, attrKey?) => {
            let key = attribute ? [...attribute] : [];
            let label = attrKey ? [...attrKey] : [];
            const validation = validations?.[label.join('.')];

            let UTFSize = validation?.maxUtf8ByteLength;
            let minLength = validation?.minLength;
            let maxLength = validation?.maxLength;
            let min = validation?.minimum;
            let max = validation?.maximum;
            let title = validation?.title;
            let maxUniqueItems = validation?.maxUniqueItems || validation?.maxItems;
            let minUniqueItems = validation?.minUniqueItems;
            let required = validation?.items?.required || validation?.required;
            let duplicated = false;

            if (isArray(currentValue) && minUniqueItems) {
              duplicated =
                currentValue
                  ?.map((item) => JSON.stringify(item))
                  .filter((item, index, self) => self.indexOf(item) !== index).length >= minUniqueItems;
            }

            if (required && required.length > 0) {
              const filteredRequired = required
                .filter((i) => {
                  return !getOptions.includes(i);
                })
                .map((i, x) => {
                  return `attributes.${label.join('.')}.${i}`;
                });
              requiredFields.push(...filteredRequired);
            }

            const isRequired = requiredFields?.includes(`attributes.${label.join('.')}`);

            const messages = {
              required: `${title ? title : label?.[label?.length - 1]}`,
              min: `Minimum value for ${title ? title : label?.[label?.length - 1]} is ${min}`,
              max: `Maximum value for ${title ? title : label?.[label?.length - 1]} is ${max}`,
              minLength: `Value must be at least ${minLength} characters`,
              maxLength: `Maximum value length is ${maxLength} characters`,
              minUniqueItems: `Duplicate values are not allowed`,
              maxUniqueItems: `Maximum number of values is ${maxUniqueItems}`,
              UTFSize: `Maximun UTF-8 size is ${UTFSize}`,
            };
            const errors = getError({
              key,
              label,
              currentValue,
              maxLength,
              min,
              max,
              minLength,
              UTFSize,
              maxUniqueItems,
              minUniqueItems,
              isRequired,
              messages,
              duplicated,
              itemRequiredFields,
            });
            if (currentValue && typeof currentValue === 'object' && !isArray(currentValue)) {
              let currentValues = {};
              Object.entries(currentValue)
                .filter(([key, value]) => !getOptions.some((c) => key?.includes(c)))
                .forEach(([k, value]) => {
                  currentValues = {...currentValues, ...getErrors(value, [...key, k], [...label, k])};
                });
              return currentValues;
            } else if (currentValue && isArray(currentValue)) {
              let currentValues = {};
              currentValue.forEach((value, index) => {
                currentValues = {...currentValues, ...getErrors(value, [...key, index], [...label])};
              });
              return currentValues;
            } else {
              if (errors.length > 0 && errors[0].type === 'error') {
                errorList = {...errorList, [key.join('.')]: errors};
                return errors;
              }
            }
          };
          if (duplicated) {
            errorList = {
              ...errorList,
              [attribute]: [
                {
                  id: `attributes.${attribute}`,
                  label: attribute,
                  message: `Duplicate values are not allowed`,
                },
              ],
            };
          }
          getErrors(value, [attribute, index], [attribute]);
          setErrorList(errorList);
        });
    });
  }

  const updateMainImage = (data, setFieldValue) => {
    let mainProductImageLocator = data?.main_product_image_locator || undefined
    if (!mainProductImageLocator) {
      const checkMainImage = _.find(data, function (x) {
        if (x?.length > 0 && x[0]?.media_location !== '') {
          return x
        }
      })
      if (checkMainImage?.length > 0) {
        mainProductImageLocator = checkMainImage[0]
      }
    }
    setFieldValue('summaries.0.mainImage.link', mainProductImageLocator?.media_location)
  }

  useEffect(() => {
    getAmazonListingsItemDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container className={classes.itemDetailsContainer}>
      <CustomAlert id="error-alert" unique />
      {amazonListingsItem && !loading ? (
        <Grid item xs={12}>
          <Grid container direction="row" spacing={2} className={classes.itemInfo}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="h6" onClick={() => scrollToField('item_name')} className="clickable">
                {amazonListingsItem.summaries[0].itemName}
              </Typography>
              <Typography variant="h6">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.amazon.com/${sku}/dp/${amazonListingsItem.summaries[0].asin}`}
                >
                  ASIN: {amazonListingsItem.summaries[0].asin} <Link />
                </a>
              </Typography>
              <Typography variant="h6">Version: {amazonListingsItem.version}</Typography>
              {amazonListingsItem.offers?.length > 0 && (
                <>
                  <Typography variant="h6" onClick={() => scrollToField('purchasable_offer')} className="clickable">
                    Offers: {amazonListingsItem.offers[0].offerType} ${amazonListingsItem.offers[0].price.amount}{' '}
                    {amazonListingsItem.offers[0].price.currency}
                  </Typography>
                </>
              )}
              <Typography variant="h6">
                {amazonListingsItem.isSupportedProductType ? 'API Editable' : 'API Unsupported'}
              </Typography>
              {(amazonListingsItem?.error || amazonListingsItem?.warning) && (
                <>
                  <Typography variant="h6">Issues:</Typography>
                  <Spacer height={5} />
                  {issues?.warning?.length > 0 && (
                    <Accordion className="warning">
                      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>
                          <Warning /> Warnings
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {issues?.warning?.length > 0 &&
                          issues.warning.map((issue, index) => {
                            return (
                              <div key={`${issue.message}_${index}`}>
                                {issuesList(issue, 'warning')}
                                <Divider />
                              </div>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {issues?.error?.length > 0 && (
                    <Accordion className="error">
                      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>
                          <Error /> Errors
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {issues.error.map((issue, index) => {
                          return (
                            <div key={`${issue.message}_${index}`}>
                              {issuesList(issue, 'error')}
                              <Divider />
                            </div>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img
                className={amazonListingsItem.isSupportedProductType ? 'clickable' : ''}
                onClick={() => scrollToField('edit-image')}
                src={amazonListingsItem.summaries[0]?.mainImage?.link}
                alt={amazonListingsItem.summaries[0]?.itemName}
              />
            </Grid>
          </Grid>
          <Grid container style={{padding: '20px 0'}}>
            <Formik
              initialValues={amazonListingsItem}
              enableReinitialize={true}
              onSubmit={onSubmit}
              validateOnChange={false}
              validateOnBlur={false}
              validateOnMount={false}
              validate={(values) => {
                getErrorLis(values);
              }}
            >
              {({values: formValues, setFieldValue}) => {
                const isChanged = _.isEqual(formValues, amazonListingsItem);
                return (
                  <>
                    <UnsavedChangesAlert />
                    <Form className={isSubmitting ? classes.submitForm : ''}>
                      <Grid item xs={12} container>
                        {!version && (
                          <Grid container item xs={12} spacing={4}>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{padding: 0}}>
                              <CustomAlert id="default-alert" />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              lg={4}
                              display="flex"
                              style={{
                                textAlign: 'right',
                                position: 'fixed',
                                bottom: '20px',
                                right: '30px',
                                zIndex: 2,
                              }}
                            >
                              {amazonListingsItem?.isSupportedProductType && (
                                <Button
                                  style={{marginRight: '10px'}}
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  disabled={isSubmitting || isChanged}
                                >
                                  <Typography>
                                    {isSubmitting && (
                                      <>
                                        {getButtonLabelObj(amazonListingsItem?.isSupportedProductType).loading}
                                        <CircularProgress size={12} style={{color: 'blue'}} />
                                      </>
                                    )}
                                    {!isSubmitting &&
                                      getButtonLabelObj(amazonListingsItem?.isSupportedProductType).normal}
                                  </Typography>
                                </Button>
                              )}
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                disabled={isDownloading}
                                onClick={() => onFfpDownload(formValues)}
                              >
                                <Typography>
                                  Download FF {isDownloading && <CircularProgress size={12} style={{color: 'blue'}} />}
                                </Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                        {Object.keys(formValues).map((dataSetKey: string) => {
                          let formCmpnt = null;
                          switch (dataSetKey) {
                            case 'summaries':
                              formCmpnt = formValues.summaries.length > 0 && (
                                <ListingsAttrContainer
                                  key={dataSetKey}
                                  id={dataSetKey}
                                  label={dataSetKey}
                                  icon={<Assignment />}
                                  spacing={2}
                                  container
                                  columnSpacing={{xs: 1, sm: 1, md: 1}}
                                >
                                  <Grid item xs={12} sm={6} md={4} lg={3} container className="img-container">
                                    <img
                                      onClick={() => scrollToField('edit-image')}
                                      style={{
                                        cursor: amazonListingsItem.isSupportedProductType ? 'pointer' : 'default',
                                      }}
                                      src={amazonListingsItem.summaries[0]?.mainImage?.link}
                                      alt={amazonListingsItem.summaries[0]?.itemName}
                                    />
                                  </Grid>
                                  <Grid display="flex" alignItems="start" item xs={12} sm={6} md={8} lg={9}>
                                    {formValues.summaries.map((summary, summaryLoop) => {
                                      return (
                                        <Grid
                                          container
                                          rowSpacing={2}
                                          columnSpacing={{xs: 1, sm: 1, md: 1}}
                                          key={`${dataSetKey}_${summaryLoop}`}
                                        >
                                          {Object.keys(summary)
                                            .sort()
                                            .map((summaryKey) => {
                                              let summariesCmpnt = null;
                                              switch (summaryKey) {
                                                case 'marketplaceId':
                                                case 'mainImage':
                                                case 'lastUpdatedDate':
                                                case 'itemName':
                                                  break;
                                                case 'status':
                                                  summariesCmpnt = (
                                                    <ListingsInputFields
                                                      type="checkbox"
                                                      key={`${dataSetKey}_${summaryKey}`}
                                                      label={summaryKey}
                                                      name={`summaries[${summaryLoop}].${summaryKey}`}
                                                      options={statuses}
                                                      disabled
                                                    />
                                                  );
                                                  break;
                                                default:
                                                  summariesCmpnt = (
                                                    <ListingsInputFields
                                                      key={`summaries[${summaryLoop}].${summaryKey}`}
                                                      label={summaryKey}
                                                      name={`summaries[${summaryLoop}].${summaryKey}`}
                                                      disabled
                                                    />
                                                  );
                                              }
                                              return summariesCmpnt;
                                            })}
                                        </Grid>
                                      );
                                    })}
                                  </Grid>
                                </ListingsAttrContainer>
                              );
                              break;
                            case 'attributes':
                              formCmpnt = (
                                <Grid container key={dataSetKey} style={{margin: 0, overflow: 'hidden'}}>
                                  {!isDisabled() && (
                                    <Grid item xs={12} sm={12} md={8} lg={6} style={{padding: '20px 0'}}>
                                      <SearchBar
                                        placeholder="Search By Attribute Name"
                                        value={filter}
                                        onChange={(newValue) => setFilter(newValue)}
                                        onSearch={() => onSearch(formValues.attributes)}
                                      />
                                    </Grid>
                                  )}
                                  {Object.keys(filteredAttributes).length > 0 && (
                                    <ListingsAttrContainer
                                      key={dataSetKey}
                                      id={dataSetKey}
                                      label={dataSetKey}
                                      icon={<Inventory />}
                                      rowSpacing={2}
                                      container
                                      columnSpacing={{xs: 1, sm: 1, md: 1}}
                                    >
                                      {Object.keys(filteredAttributes).map((attributeKey, attributeIndex) => {
                                        const currentValidation: any = Object.entries(validations)
                                          .filter(([key, item]) => key.includes(attributeKey))
                                          .reduce((obj, [key, value]) => {
                                            obj[key] = value;
                                            return obj;
                                          }, {});
                                        const arrayValidation = validations?.[attributeKey];
                                        return attributeKey.indexOf('product_image_locator') > -1 ? null : (
                                          <ListingsAttrContainer
                                            key={attributeKey}
                                            id={attributeKey}
                                            label={attributeKey}
                                            icon={
                                              arrayValidation?.description && (
                                                <Tooltip title={arrayValidation?.description}>
                                                  <Help />
                                                </Tooltip>
                                              )
                                            }
                                            styles={{gridColumn: '1/3', padding: '15px 0'}}
                                            rowSpacing={2}
                                            columnSpacing={{xs: 1, sm: 1, md: 1}}
                                          >
                                            <FieldArray name={`attributes.${attributeKey}`} validateOnChange={false}>
                                              {(arrayHelpers) => {
                                                const duplicated =
                                                  formValues.attributes[attributeKey]
                                                    .map((item) => JSON.stringify(item))
                                                    .filter((item, index, self) => self.indexOf(item) !== index)
                                                    .length >= arrayValidation?.minUniqueItems;

                                                let required =
                                                  arrayValidation?.items?.required || arrayValidation?.items;
                                                let requiredFields = [];

                                                if (required && required?.length > 0) {
                                                  const filteredRequired = required
                                                    .filter((i) => {
                                                      return !getOptions.includes(i);
                                                    })
                                                    .map((i, x) => {
                                                      return `attributes.${attributeKey}.${i}`;
                                                    });
                                                  requiredFields = [...requiredFields, ...filteredRequired];
                                                }
                                                return (
                                                  <ListingsAttrContainer
                                                    key={`attributes.${attributeKey}`}
                                                    rowSpacing={2}
                                                    columnSpacing={{xs: 1, sm: 1, md: 1}}
                                                  >
                                                    {formValues.attributes[attributeKey].map(
                                                      (attributeItem: any, attributeLoop: number) => {
                                                        const attributeComponentKey = [
                                                          'attributes',
                                                          `${attributeKey}`,
                                                          `${attributeLoop}`,
                                                        ];

                                                        return (
                                                          <React.Fragment key={attributeComponentKey.join('.')}>
                                                            {formValues.attributes[attributeKey].length > 1 && (
                                                              <Typography
                                                                className={classes.attributeFieldHeader}
                                                                component="h6"
                                                              >
                                                                {arrayValidation?.title
                                                                  ? arrayValidation.title
                                                                  : getLabel(attributeKey)}{' '}
                                                                {formValues.attributes[attributeKey].length > 1 &&
                                                                  attributeLoop + 1}
                                                              </Typography>
                                                            )}
                                                            <AttributeList
                                                              attributes={attributeItem}
                                                              defaultKey={attributeComponentKey}
                                                              label={attributeKey}
                                                              validations={currentValidation}
                                                              setFieldValue={(key, value) => setFieldValue(key, value)}
                                                              parentRequiredFields={requiredFields}
                                                              defaultValues={attributeDefaultValues}
                                                              disabledForm={isDisabled()}
                                                              itemRequiredFields={itemRequiredFields}
                                                            />
                                                            {Object.keys(formValues.attributes[attributeKey]).length >
                                                              arrayValidation?.minUniqueItems && (
                                                              <div className={`${classes.customDivider} attribute`}>
                                                                <Divider />
                                                                <Chip
                                                                  label="- Delete"
                                                                  onClick={() => {
                                                                    arrayHelpers.remove(attributeLoop);
                                                                  }}
                                                                />
                                                              </div>
                                                            )}

                                                            {isDisabled() &&
                                                              Object.keys(formValues.attributes[attributeKey]).length >
                                                                1 && (
                                                                <div className={`${classes.customDivider} no-editable`}>
                                                                  <Divider />
                                                                </div>
                                                              )}
                                                          </React.Fragment>
                                                        );
                                                      }
                                                    )}
                                                    {duplicated && (
                                                      <Typography color="error" className={classes.errorMessage}>
                                                        Duplicate values are not allowed
                                                      </Typography>
                                                    )}
                                                    {!isDisabled() && arrayValidation && (
                                                      <>
                                                        {arrayValidation?.maxUniqueItems ||
                                                        arrayValidation?.maxItems ? (
                                                          (Object.keys(formValues.attributes[attributeKey]).length <
                                                            arrayValidation?.maxUniqueItems ||
                                                            Object.keys(formValues.attributes[attributeKey]).length <
                                                              arrayValidation?.maxItems) && (
                                                            <div className={`${classes.customDivider} add-btn`}>
                                                              <Chip
                                                                label="+ Add"
                                                                onClick={() => {
                                                                  arrayHelpers.push(
                                                                    createNewField(
                                                                      arrayValidation,
                                                                      attributeDefaultValues
                                                                    )
                                                                  );
                                                                }}
                                                              />
                                                              <Divider />
                                                            </div>
                                                          )
                                                        ) : (
                                                          <div className={`${classes.customDivider} add-btn`}>
                                                            <Chip
                                                              label="+ Add"
                                                              onClick={() => {
                                                                arrayHelpers.push(
                                                                  createNewField(
                                                                    arrayValidation,
                                                                    attributeDefaultValues
                                                                  )
                                                                );
                                                              }}
                                                            />
                                                            <Divider />
                                                          </div>
                                                        )}
                                                      </>
                                                    )}
                                                  </ListingsAttrContainer>
                                                );
                                              }}
                                            </FieldArray>
                                            {attributeIndex !== Object.keys(filteredAttributes).length - 1 && (
                                              <Divider style={{width: '100%', padding: '10px 0'}} />
                                            )}
                                          </ListingsAttrContainer>
                                        );
                                      })}
                                    </ListingsAttrContainer>
                                  )}
                                </Grid>
                              );

                              break;
                            case 'productImages':
                              if (Object.keys(formValues.productImages).length > 0)
                                formCmpnt = (
                                  <ListingsAttrContainer
                                    key={`fieldset_${dataSetKey}`}
                                    id="edit-image"
                                    label="Images: "
                                    container
                                    icon={<Image />}
                                    className={classes.productImages}
                                  >
                                    {Object.keys(formValues.productImages).map((key, index) => {
                                      return (
                                        <ImageCard
                                          key={key}
                                          imageKey={key}
                                          label={getLabel(key)}
                                          link={formValues.productImages[key][0]?.media_location || null}
                                          disabledDirection={index === 0 ? 'left' : index === 9 ? 'right' : null}
                                          disabled={isDisabled('attributes', key)}
                                          onMove={(offSet: number) => {
                                            const data = changeOrderImage(index, offSet, formValues.productImages);
                                            setFieldValue('productImages', data);
                                            updateMainImage(data, setFieldValue);
                                          }}
                                          handleChangeImagesArray={(imageUrl: string) => {
                                            const data = updateProductMediaLists(
                                              imageUrl,
                                              index,
                                              formValues.productImages
                                            );
                                            setFieldValue('productImages', data);
                                            updateMainImage(data, setFieldValue);
                                          }}
                                        />
                                      );
                                    })}
                                  </ListingsAttrContainer>
                                );
                              else formCmpnt = null;
                              break;
                          }
                          return formCmpnt;
                        })}
                      </Grid>
                      {isSubmitting && (
                        <Grid className={classes.formLoading}>
                          <CircularProgress size={40} />
                        </Grid>
                      )}
                    </Form>
                  </>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      ) : (
        <CircularProgress size={40} style={{color: '#174582'}} />
      )}
    </Grid>
  );
});
