const PATH = {
  DASHBOARD: '/',
  ALERTS: '/alerts',
  PERFORMANCE: '/performance',
  PERFORMANCE_SALES: '/performance-sales',
  PERFORMANCE_INSIGHTS: '/performance-insights',
  ADVERTISING_PERFORMANCE: '/advertising-performance/sp',
  BRANDS: '/brands',
  USERS: '/users',
  ASINS: '/asins',
  REPORTS: '/agency-reports',
  COMMISSIONS: '/commissions',
  LISTINGS: '/amazon-listings-items',
  ACCOUNT: '/account/billing',
  BRANDS_OVERVIEW: '/brands-overview',
};

export default PATH;
