import {CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Line, LineChart} from 'recharts';
import {LinearProgress, Paper, Typography, Grid} from '@mui/material';
import moment from 'moment';
// components
import {CustomAlert} from '../../Login/components/CustomAlert';

const metricsOptions = {
  commissions: {
    color: '#174582',
    color2: '#0090ff',
    label: 'Commissions',
    label2: 'Forecast',
  },
};

function CommissionChart({data, loading}) {
  function formatXAxis(tickItem) {
    return moment.utc(tickItem).format('YYYY-MM-DD');
  }

  return (
    <Grid container spacing={1} style={{paddingTop: 30}}>
      <Grid item lg={12} width={'100%'}>
        {data?.data.some((x) => x.data?.length) ? (
          <ResponsiveContainer height={450} width={'100%'}>
            <LineChart>
              <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
              <XAxis
                type={'number'}
                allowDuplicatedCategory={false}
                allowDataOverflow={true}
                domain={['dataMin', 'dataMax']}
                dataKey={(x) => moment.utc(x.date).valueOf()}
                tickFormatter={formatXAxis}
                minTickGap={100}
              />
              <Tooltip
                contentStyle={{
                  color: '#4b4b4b',
                }}
                labelFormatter={(label) => moment.utc(label).format('YYYY-MM')}
              />
              {data.data.map((x, i) => {
                return (
                  <Line
                    key={i}
                    data={x.type === 'current' ? x.data.slice(0, -1) : x.data}
                    type="monotone"
                    dataKey="value"
                    name={metricsOptions[x.metric].label}
                    stroke={metricsOptions[x.metric].color}
                    label={x.metric}
                    strokeWidth={4}
                    dot={false}
                    yAxisId={x.metric}
                    strokeDasharray={x.type === 'previous' ? '5 5' : ''}
                    connectNulls
                  />
                );
              })}
              {data.data.map((x, i) => {
                return x.type === 'current' && (x.data.length === 1 || new Set(x.data.slice(-2)).size !== 1) ? (
                  <Line
                    key={i + 'forecast'}
                    data={x.data.slice(-2).map((el, i, arr) => {
                      return i === arr.length - 1 ? {date: el.date, value: el.predicted} : el;
                    })}
                    type="monotone"
                    dataKey="value"
                    name={metricsOptions[x.metric].label2}
                    stroke={metricsOptions[x.metric].color}
                    label={x.metric}
                    strokeWidth={4}
                    dot={false}
                    yAxisId={x.metric}
                    strokeDasharray={'8 8'}
                    connectNulls
                  />
                ) : (
                  ''
                );
              })}
              ;
              {data.data.map((x, i) => {
                return (
                  <YAxis
                    key={`axis_${i}`}
                    domain={['auto', 'auto']}
                    hide={true}
                    axisLine={false}
                    yAxisId={x.metric}
                    type={'number'}
                  />
                );
              })}{' '}
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Paper
            variant={'outlined'}
            style={{
              height: '450px',
              textAlign: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              boxShadow: '0px 0px 6px 0px #94949433',
            }}
          >
            <Typography color={'textSecondary'} variant={'h4'}>
              No data
            </Typography>
          </Paper>
        )}
        {loading ? <LinearProgress color={'primary'} /> : <></>}
        <CustomAlert id="commission-chart" />
      </Grid>
    </Grid>
  );
}

export {CommissionChart};
