import {useEffect, useState, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField as TextFieldMaterial,
  Typography,
  Input,
  Grid,
  Button,
  AutocompleteRenderInputParams,
} from '@mui/material';
import {DatePicker} from 'formik-mui-lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {LocalizationProvider} from '@mui/lab';
import {Field, Form, Formik} from 'formik';
import {Select, TextField, CheckboxWithLabel, Autocomplete} from 'formik-mui';
import * as Yup from 'yup';
// services
import {alertService} from '../../../../services/alert.service';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {brandFields} from '../../utils/BrandProperties';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// styles
import {useStyles} from '../../Styles';
// context
import {AppContext} from '../../../../context/AppContext/AppContext';

const CustomFileUpload = (props: any) => (
  <FormControl>
    {props.label && <InputLabel shrink>{props.label}</InputLabel>}
    <InputLabel
      shrink
      htmlFor={props.label}
      style={{
        marginTop: '15px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '20px',
        color: 'black',
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL + '/uploadFile.svg'}`}
        alt=""
        style={{
          padding: '5px 20px',
          height: 40,
          background: 'white',
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        }}
      />
      {props.uploadedFile && <span style={{paddingLeft: 10}}>{props.uploadedFile}</span>}
    </InputLabel>
    <Input
      style={{display: 'none'}}
      inputProps={{
        id: props.label,
        type: 'file',
        disabled: props.disabled || props.form.isSubmitting,
        name: props.field.name,
        onChange: (event: any) => {
          const file = event.currentTarget.files[0];
          props.form.setFieldValue(props.field.name, file);
          props.onChange(file.name);
        },
      }}
    />
  </FormControl>
);

const validationSchema = Yup.object().shape({
  brand_code: Yup.string().required('Brand code is required'),
  name: Yup.string().required('Name is required'),
  type_id: Yup.number().typeError('A brand type is required').required('Brand Type is required'),
  status_id: Yup.number().required('Status is required'),
  create_as_sub_brand: Yup.boolean().required('Create as sub brand is required'),
  parent_brand_code: Yup.object()
    .nullable()
    .when('create_as_sub_brand', {
      is: true,
      then: Yup.object().typeError('A parent brand is required').required('Parent brand is required'),
    }),
  seller_central_account_location: Yup.object()
    .nullable()
    .when('type_id', {
      is: 2,
      then: Yup.object()
        .typeError('The Seller Central account location is required for Max Results brands')
        .required('Seller central account location is required for Max Results brands'),
    }),
});

function CreateBrand() {
  const classes = useStyles();
  const history = useHistory();
  const [logoImg, setLogoImg] = useState('');
  const [productImg, setProductImg] = useState('');
  const [fieldTypeCheckbox, setFieldTypeCheckbox] = useState([]);
  const [fieldTypeSelect, setFieldTypeSelect] = useState([]);
  const [fieldTypeText, setFieldTypeText] = useState([]);
  const [fieldTypeDate, setFieldTypeDate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<any>({
    status: 'Active',
    create_as_sub_brand: false,
    status_id: 1,
  });

  const {accountManagers, getBrandUtils, brandUtils} = useContext(AppContext);

  useEffect(() => {
    getBrandUtils();
  }, []);

  async function onSubmit(data: any, {setSubmitting}) {
    alertService.clear();
    if (data.type_id) data.type_id = parseInt(data.type_id);

    if (data.status_id === 1) {
      data.status = 'Active';
    } else {
      data.status = 'Inactive';
    }

    const fields = {...data, status_id: parseInt(data.status_id)};

    delete fields.logo;
    delete fields.product_image;

    // Make photos not required for new MC brands by default
    const mcType = brandUtils.brandTypes?.find((t) => t.name === 'MAX_CONTROL');

    if (mcType && parseInt(data.type_id) === mcType['id']) {
      fields.requires_verification_photos = false;
    }

    if (data.type_id === 2 && data.seller_central_account_location) {
      fields.seller_central_account_location = data.seller_central_account_location.brand_code;
    }

    if (data.create_as_sub_brand) {
      fields.parent_brand_code = data.parent_brand_code.brand_code;
      fields.addresses = data.parent_brand_code.addresses;
      fields.contacts = data.parent_brand_code.contacts;
      fields.contact_name = data.parent_brand_code.contact_name;
      fields.phone = data.parent_brand_code.phone;
      fields.email = data.parent_brand_code.email;
      fields.notes = data.parent_brand_code.notes;
      fields.website = data.parent_brand_code.website;
      fields.item_label_url = data.parent_brand_code.item_label_url;
      fields.cc = data.parent_brand_code.cc;
      fields.escalation_ccs = data.parent_brand_code.escalation_ccs;
      fields.requires_verification_photos = data.parent_brand_code.requires_verification_photos;
    }
    try {
      await Api.post('brands', fields);
      if (data.logo && typeof data.logo === 'object') {
        try {
          await uploadLogo(data.brand_code, data.logo);
        } catch (error: any) {
          alertService.error(error.response.data.message);
        }
      }
      if (data.product_image && typeof data.product_image === 'object') {
        try {
          await uploadProductImage(data.brand_code, data.product_image);
        } catch (error: any) {
          alertService.error(error.response.data.message);
        }
      }
      alertService.success('Brand created successfully');
      history.push(`/brands/${data.brand_code}/detail`);
    } catch (e: any) {
      errorAlert('Error creating brand', e);
    } finally {
      setSubmitting(false);
    }
  }

  async function uploadLogo(brandCode: string, file) {
    const formData = new FormData();
    formData.append('logo', file);
    return await Api.put(`brands/${brandCode}/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async function uploadProductImage(brandCode: string, file) {
    const formData = new FormData();
    formData.append('product_image', file);
    return await Api.put(`brands/${brandCode}/product-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  const onChangeLogo = (event: any) => {
    setLogoImg(event);
  };

  const onChangeProduct = (event: any) => {
    setProductImg(event);
  };

  const getFields = (type) => {
    let newFields = [];
    Object.entries(brandFields)
      .filter(([key, field]) => field.type === type)
      .map(([key, field]: any) => {
        return newFields.push({
          name: key,
          type: field.type,
          label: field.label,
          options: field.options,
          priority: field.priority,
          variant: field.variant,
        });
      });
    return newFields;
  };

  const getInitialValues = () => {
    setLoading(true);
    Object.entries(brandFields).map(([key, field]) => {
      return (
        key !== 'status' &&
        setInitialValues((p) => {
          return {...p, [key]: field.value};
        })
      );
    });
    setLoading(false);
  };

  useEffect(() => {
    if (brandFields) {
      getInitialValues();
      setFieldTypeCheckbox(getFields('boolean'));
      setFieldTypeText(getFields('text'));
      setFieldTypeSelect(getFields('select'));
      setFieldTypeDate(getFields('date'));
    }
  }, [brandFields]);

  const getFieldOptions = (field: string) => {
    const optionsArray =
      field === 'role'
        ? brandUtils.brandRoles
        : field === 'manager'
        ? accountManagers
        : field === 'type'
        ? brandUtils.brandTypes
        : field === 'status'
        ? brandUtils.brandStatuses
        : field === 'brands'
        ? brandUtils.brandList
        : field === 'ordering'
        ? brandUtils.orderMethods
        : field === 'agreement'
        ? brandUtils.agreementTypes
        : [];
    return optionsArray;
  };

  return (
    <TableContainer container spacing={1} justifyContent="center" style={{paddingTop: 60}}>
      <Grid item lg={10} md={12} sm={12} xs={12}>
        <Typography component="h2" variant="h4" color="primary" gutterBottom>
          Create brand
        </Typography>

        {!loading && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnChange={true}
          >
            {({errors, touched, isSubmitting, values: formValues, validateForm, setFieldValue}) => {
              return (
                <Form>
                  <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Field
                        className={classes.inputField}
                        component={TextField}
                        variant="outlined"
                        name="brand_code"
                        label="Brand code"
                        type="text"
                        size="small"
                      />
                      <Field
                        className={classes.inputField}
                        component={TextField}
                        variant="outlined"
                        name="name"
                        label="Brand name"
                        type="text"
                        size="small"
                      />
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Field
                          component={CheckboxWithLabel}
                          value={formValues.create_as_sub_brand}
                          defaultChecked={false}
                          label="create as sub brand"
                          name="create_as_sub_brand"
                          Label={{label: 'Create as sub Brand'}}
                        />
                      </div>
                      {formValues.create_as_sub_brand && (
                        <Field
                          className={classes.inputField}
                          name="parent_brand_code"
                          size="small"
                          component={Autocomplete}
                          options={brandUtils.brandList}
                          value={brandUtils.brandList?.find(
                            (brand) => brand?.brand_code === formValues?.parent_brand_code
                          )}
                          onChange={(event, value) => {
                            setFieldValue('parent_brand_code', value?.brand_code ?? '');
                          }}
                          getOptionLabel={(option) => `${option.brand_code} - ${option.name}`}
                          renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextFieldMaterial
                              {...params}
                              error={touched['parent_brand_code'] && !!errors['parent_brand_code']}
                              helperText={touched['parent_brand_code'] && errors['parent_brand_code']}
                              label="Parent Brand"
                              variant={'outlined'}
                            />
                          )}
                        />
                      )}

                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Field
                          className={classes.inputField}
                          uploadedFile={logoImg}
                          onChange={onChangeLogo}
                          component={CustomFileUpload}
                          variant="outlined"
                          name="logo"
                          label="Logo"
                          size="small"
                        />
                      </div>

                      <Field
                        className={classes.inputField}
                        uploadedFile={productImg}
                        onChange={onChangeProduct}
                        component={CustomFileUpload}
                        variant="outlined"
                        name="product_image"
                        label="Product Image"
                        size="small"
                      />

                      <div style={{height: 40, paddingTop: 30}} />

                      <FormControl
                        error={touched['status_id'] && !!errors['status_id']}
                        className={`${classes.inputField} ${classes.inputFieldSelect}`}
                        variant={'outlined'}
                      >
                        <InputLabel id="status-label">Status</InputLabel>
                        <Field
                          value={formValues.status_id}
                          size="small"
                          component={Select}
                          name="status_id"
                          label="Status"
                          labelId="status-label"
                        >
                          {brandUtils.brandStatuses?.map((brandStatus) => (
                            <MenuItem key={`status_${brandStatus.id}`} value={brandStatus.id}>
                              {brandStatus.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>

                      <FormControl
                        error={touched['type_id'] && !!errors['type_id']}
                        className={`${classes.inputField} ${classes.inputFieldSelect}`}
                        variant={'outlined'}
                      >
                        <InputLabel id="brand-type-label">Brand Type</InputLabel>
                        <Field
                          size="small"
                          component={Select}
                          name="type_id"
                          label="Brand Type"
                          labelId="brand-type-label"
                        >
                          {brandUtils?.brandTypes?.map((brandType) => (
                            <MenuItem key={`brand_type_${brandType.id}`} value={brandType.id}>
                              {brandType.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                      {formValues.type_id === 1 && (
                        <>
                          <div style={{height: 20, marginBottom: 50}}>
                            <Field
                              className={classes.inputField}
                              component={TextField}
                              variant="outlined"
                              name="seller_id"
                              label="Seller ID"
                              type="text"
                              size="small"
                            />
                          </div>
                        </>
                      )}

                      {formValues.type_id === 2 && (
                        <>
                          <div style={{height: 20, marginBottom: 80}}>
                            <Field
                              size="small"
                              className={classes.inputField}
                              name="seller_central_account_location"
                              component={Autocomplete}
                              options={brandUtils.brandMaxControl}
                              getOptionLabel={(option) => option.name || ''}
                              renderInput={(params: AutocompleteRenderInputParams) => (
                                <TextFieldMaterial
                                  {...params}
                                  error={
                                    touched['seller_central_account_location'] &&
                                    !!errors['seller_central_account_location']
                                  }
                                  helperText={
                                    touched['seller_central_account_location'] &&
                                    errors['seller_central_account_location']
                                  }
                                  label="Seller Central Account Location"
                                  variant={'outlined'}
                                />
                              )}
                            />
                          </div>
                        </>
                      )}
                      <Field
                        size="small"
                        className={classes.inputField}
                        name="account_manager_id"
                        component={Autocomplete}
                        options={accountManagers}
                        value={accountManagers.find((x) => x.id === formValues.account_manager_id ?? null)}
                        onChange={(event, value) => {
                          setFieldValue('account_manager_id', value.id);
                        }}
                        getOptionLabel={(option) => option.email}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                          <TextFieldMaterial
                            {...params}
                            error={touched['account_manager_id'] && !!errors['account_manager_id']}
                            helperText={touched['account_manager_id'] && errors['account_manager_id']}
                            label="Account Manager"
                            variant={'outlined'}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      {fieldTypeCheckbox?.map((field) => {
                        return (
                          <div key={field.name}>
                            <Field
                              value={formValues[field.name]}
                              component={CheckboxWithLabel}
                              name={field.name}
                              Label={{label: field.label}}
                              checked={formValues[field.name]}
                              label={field.label}
                            />
                          </div>
                        );
                      })}
                    </Grid>

                    <Grid container>
                      {formValues.type_id === 1 && (
                        <>
                          <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Field
                              className={classes.inputField}
                              component={TextField}
                              variant="outlined"
                              name="seller_id"
                              label="Seller ID"
                              type="text"
                              size="small"
                            />
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Field
                              className={classes.inputField}
                              component={TextField}
                              variant="outlined"
                              name="mws_auth_token"
                              label="MWS Auth Token"
                              type="text"
                              size="small"
                            />
                          </Grid>
                        </>
                      )}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {fieldTypeDate?.map((field) => {
                          return (
                            <Grid key={field.name} item lg={4} md={4} sm={6} xs={12} className={classes.inputFieldDate}>
                              <Field
                                size="small"
                                component={DatePicker}
                                value={formValues[field.name]}
                                name={field.name}
                                label={field.label}
                                inputVariant="outlined"
                                format="yyyy-MM-dd"
                                autoOk
                              />
                            </Grid>
                          );
                        })}
                      </LocalizationProvider>
                      {fieldTypeSelect?.map((field) => {
                        return (
                          !field.priority &&
                          field.options && (
                            <Grid key={field.name} item lg={4} md={4} sm={6} xs={12}>
                              <Field
                                size="small"
                                className={classes.inputField}
                                name={field.name}
                                component={Autocomplete}
                                options={getFieldOptions(field.options)}
                                onChange={(event, value) => {
                                  setFieldValue(field.name, value?.id ? value.id : null);
                                }}
                                value={getFieldOptions(field.options)?.find(
                                  (x) => x.id === formValues[field.name]?.toString()
                                )}
                                getOptionLabel={(option) => option.email || option.name}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                  <TextFieldMaterial
                                    {...params}
                                    error={touched[field.name] && !!errors[field.name]}
                                    helperText={touched[field.name] && errors[field.name]}
                                    label={field.label}
                                    variant={'outlined'}
                                  />
                                )}
                              />
                            </Grid>
                          )
                        );
                      })}
                      {fieldTypeText?.map((field) => {
                        return (
                          !field.priority && (
                            <Grid key={field.name} item lg={4} md={4} sm={6} xs={12}>
                              <Field
                                className={classes.inputField}
                                component={TextField}
                                variant="outlined"
                                name={field.name}
                                label={field.label}
                                type={field.variant ? field.variant : 'text'}
                                size="small"
                                // value={formValues[field.name] !== null ? formValues[field.name] : ''}
                                // onChange={(e) => {
                                //   setFieldValue(field.name, e.target.value);
                                // }}
                              />
                            </Grid>
                          )
                        );
                      })}
                    </Grid>
                    <Grid container justifyContent={'center'} style={{padding: '20px'}}>
                      <Button size="large" type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                        Save
                      </Button>
                      <CustomAlert className={classes.inputField} id="default-alert" />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        )}
      </Grid>
    </TableContainer>
  );
}

export {CreateBrand};
