import {useEffect, useState} from 'react';
import {Divider, FormControl, FormHelperText, InputLabel, MenuItem, Button} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Field, Form, Formik} from 'formik';
import {Select, TextField} from 'formik-mui';
import * as Yup from 'yup';
// utils
import {Api, errorAlert} from '../../../utils/api';
// services
import {alertService} from '../../../services/alert.service';
// components
import Spacer from '../../../components/Spacer/Spacer';

const useStyles = makeStyles({
  inputField: {
    margin: '0.75em 0',
    width: '95%',
  },
});

interface SellerCentralDirectedIdFormProps {
  brand: any;
  marketplaces: any[];
  onBack: () => void;
  initialValues: {[key: string]: string};
}

export function SellerCentralDirectedIdForm({
  brand,
  marketplaces,
  onBack,
  initialValues,
}: SellerCentralDirectedIdFormProps): JSX.Element {
  const [directedIds, setDirectedIds] = useState(['']);
  const [directedIdValidationSchema, setDirectedIdValidationSchema] = useState<any>(
    Yup.object().shape({
      directed_id_0: Yup.string().required('The Directed ID is required'),
    })
  );

  const classes = useStyles();

  useEffect(() => {
    if (initialValues) {
      const keys = Object.keys(initialValues);
      const halfTheKeys = keys.slice(0, keys.length / 2);
      setDirectedIds([...halfTheKeys]);
    }
  }, [initialValues]);

  useEffect(() => {
    const newSchema = {};
    for (let i = 0; i < directedIds.length; i++) {
      newSchema[`directed_id_${i}`] = Yup.string().required('The Directed ID is required');
      newSchema[`marketplace_id_${i}`] = Yup.string().required('The Marketplace is required');
    }

    setDirectedIdValidationSchema(Yup.object().shape(newSchema));
  }, [directedIds]);

  async function submitDirectedIds(data, {setSubmitting}) {
    const finalDirectedIds = [];
    for (const dataKey in data) {
      if (dataKey.startsWith('marketplace_id')) {
        continue;
      }

      const value = data[dataKey];

      const splitKey = dataKey.split('directed_id_');
      const newElement = {
        marketplace_id: data[`marketplace_id_${parseInt(splitKey[1])}`],
        directed_id: value,
      };
      if (newElement.marketplace_id && newElement.directed_id) {
        finalDirectedIds.push(newElement);
      }
    }
    try {
      await Api.put(`brands/${brand.brand_code}/seller-central-directed-ids`, {
        directedIds: finalDirectedIds,
      });
      alertService.success('Directed IDs saved successfully');
      onBack();
    } catch (e: any) {
      setSubmitting(false);
      errorAlert('Error saving Directed IDs', e);
    }
    setSubmitting(false);
  }

  function rowBuilder({touched, errors, index, formValues}) {
    function marketplaceIsNotSelected(marketplace) {
      for (const formValuesKey in formValues) {
        if (formValuesKey.startsWith('marketplace_id_')) {
          const value = formValues[formValuesKey];
          if (value === marketplace.marketplaceId) {
            return true;
          }
        }
      }

      return false;
    }
    if (!brand) return null;
    return (
      <div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <FormControl
            size="small"
            error={touched['marketplace_id'] && !!errors['marketplace_id']}
            className={classes.inputField}
            variant={'outlined'}
          >
            <InputLabel id="sc-marketplace-label">Marketplace</InputLabel>
            <Field
              component={Select}
              name={`marketplace_id_${index}`}
              label="Marketplace"
              labelId="sc-marketplace-label"
            >
              {marketplaces.map((marketplace) => (
                <MenuItem
                  key={`marketplace_${marketplace.marketplaceId}_${index}`}
                  value={marketplace.marketplaceId}
                  disabled={marketplaceIsNotSelected(marketplace)}
                >
                  {marketplace.name} ({marketplace.countryCode})
                </MenuItem>
              ))}
            </Field>
            <FormHelperText>
              {touched[`marketplace_id_${index}`] && !!errors[`marketplace_id_${index}`]
                ? errors[`marketplace_id_${index}`]
                : ''}
            </FormHelperText>
          </FormControl>
          <Spacer width={20} />
          <FormControl
            size="small"
            error={touched[`directed_id_${index}`] && !!errors[`directed_id_${index}`]}
            className={classes.inputField}
            variant={'outlined'}
          >
            <Field component={TextField} name={`directed_id_${index}`} label="Directed ID"></Field>
          </FormControl>
        </div>
        <Divider />
      </div>
    );
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={directedIdValidationSchema}
        onSubmit={submitDirectedIds}
        validateOnChange={true}
      >
        {({setFieldValue, errors, touched, isSubmitting, values: formValues}) => (
          <Form>
            {directedIds.map((directedId, index) => rowBuilder({errors, touched, index, formValues}))}
            <Spacer height={10} />
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Button
                onClick={() => {
                  const newDirectedIds = [...directedIds, ''];
                  setDirectedIds(newDirectedIds);
                  setFieldValue(`marketplace_id_${newDirectedIds.length}`, '');
                  setFieldValue(`directed_id_${newDirectedIds.length}`, '');
                }}
                size="small"
                type="button"
                variant="outlined"
                color="primary"
                disabled={isSubmitting}
              >
                Add new marketplace
              </Button>
              <Spacer width={5} />
              {directedIds.length > 1 && (
                <Button
                  onClick={() => {
                    const directedIdsCopy = Array.from(directedIds);
                    directedIdsCopy.pop();
                    setDirectedIds(directedIdsCopy);
                    setFieldValue(`directed_id_${directedIdsCopy.length}`, '');
                    setFieldValue(`marketplace_id_${directedIdsCopy.length}`, '');
                  }}
                  size="small"
                  type="button"
                  variant="outlined"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Remove last marketplace
                </Button>
              )}
            </div>

            <Spacer height={25} />
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Button
                onClick={onBack}
                size="large"
                type="button"
                variant="outlined"
                color="primary"
                disabled={isSubmitting}
              >
                Back
              </Button>
              <Spacer width={10} />
              <Button size="large" type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
