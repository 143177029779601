import {useEffect, useState, useContext} from 'react';
import moment from 'moment';
import {useParams, Link} from 'react-router-dom';
import {Visibility, CloudSync} from '@mui/icons-material';
import {Box, CircularProgress, Grid, Typography, IconButton, useMediaQuery} from '@mui/material';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';
import {getMarketplaceById} from '@bizon/amazon-ids';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {Helper} from '../../../../utils/helper';
import {getArticle} from '../../../../utils/FFPBeacon';
import {rowsPerPageOptions} from '../../../../utils/constants';
// services
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';
// components
import Button from '../../../../components/Button/Button';
import Icons from '../../../../components/Icons/Icons';
import CollapsibleDataGrid from '../../../../components/CollapsibleDataGrid/CollapsibleDataGrid';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import DiffList from '../../components/DiffList';
// styles
import {useStyles} from '../../Styles';
// context
import {ColorModeContext} from '../../../../context/ColorModeContext/ColorModeContext';

function getButtonLabelObj(isSupportedProductType: boolean) {
  if (isSupportedProductType === true) {
    return {normal: 'Restore', loading: 'Restoring '};
  } else {
    return {normal: 'Download', loading: 'Downloading '};
  }
}

export default function AmazonListingsItemVersions() {
  const classes = useStyles();
  const {colorMode} = useContext(ColorModeContext);

  const mobile = useMediaQuery('(max-width:1280px)');
  const {marketplaceId, sku} = useParams<Record<string, string | undefined>>();
  const [marketplaceObj, setMarketplaceObj] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [listingRows, setListingRows] = useState([]);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [isRestoring, setRestoring] = useState(false);
  const [activeSubmittingVersion, setActiveSubmittingVersion] = useState(null);
  const [selectedRow, setSelectedRow] = useState('all');

  const versionDetails = (version, prevVersion, colorMode) => {
    if (!version || !prevVersion) return;
    return <DiffList version={version} prevVersion={prevVersion} classes={classes} />;
  };

  const getVersionsContent = () => {
    return listingRows.map((row) => {
      const prevVersion = listingRows[row.id !== listingRows.length ? row.id + 1 : null];
      return row.id !== listingRows.length - 1 && {id: row.id, content: versionDetails(row, prevVersion, colorMode)};
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Report Date',
      flex: 0.2,
      minWidth: 120,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      hide: false,
      renderCell: (params: any) => {
        return <Typography>{moment(params.row.date).utc().format('YYYY-MM-DD')}</Typography>;
      },
    },
    {
      field: 'lastUpdatedDate',
      headerName: 'Last Updated Date',
      flex: 0.2,
      minWidth: 120,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      hide: false,
      renderCell: (params: any) => {
        return <Typography>{moment(params.row.lastUpdatedDate).utc().format('YYYY-MM-DD')}</Typography>;
      },
    },
    {
      field: 'version',
      headerName: 'Version',
      width: 100,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'mainImage',
      headerName: 'Image',
      width: 100,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (params: GridCellParams) => {
        if (params.row.summaries?.length > 0 && params.row.summaries[0]?.mainImage?.link)
          return (
            <img src={params.row.summaries[0]?.mainImage?.link} alt="" style={{maxWidth: '35px', maxHeight: '35px'}} />
          );
        else return <Typography>{'N/A'}</Typography>;
      },
    },
    {
      field: 'issues',
      headerName: 'Issues',
      width: 180,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const warningCnt = params.row.error?.length || 0;
        const errorCnt = params.row.warning?.length || 0;
        if (warningCnt + errorCnt === 0) return <Typography color="#469236">No Issue</Typography>;
        else {
          return (
            <>
              {warningCnt > 0 && <Typography color={'orange'}>Warning({warningCnt}) &nbsp; </Typography>}
              {errorCnt > 0 && <Typography color={'red'}>Error({errorCnt})</Typography>}
            </>
          );
        }
      },
    },
    {
      field: 'product_type',
      headerName: 'Product Type',
      width: 260,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return <Typography>{params.row.summaries[0]?.productType}</Typography>;
      },
    },
    {
      field: 'isSupportedProductType',
      headerName: 'Supported',
      flex: 0.3,
      minWidth: 150,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Typography style={{fontSize: '0.7rem', whiteSpace: 'normal'}}>
            {params.row.isSupportedProductType === true ? 'API Editable' : 'API Unsupported'}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: mobile ? 130 : 280,
      sortable: false,
      align: 'right',
      filterable: false,
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => (
        <Box display="flex" justifyContent="flex-end" style={{width: '100%'}}>
          <Link
            target="_blank"
            to={`/amazon-listings-item/${params.row.marketplace_id}/${Helper.encodeSlash(params.row.seller_sku)}/edit/${
              params.row.version
            }`}
          >
            <Button startIcon={<Visibility />} className={classes.buttonStyle}>
              View
            </Button>
          </Link>
          <Button
            startIcon={<CloudSync />}
            className={classes.buttonStyle}
            onClick={() => onRestore(params.row)}
            disabled={isRestoring}
          >
            {activeSubmittingVersion === params.row.version && (
              <>
                {getButtonLabelObj(params.row.isSupportedProductType).loading}
                <CircularProgress size={12} style={{color: 'blue'}} />
              </>
            )}
            {activeSubmittingVersion !== params.row.version &&
              getButtonLabelObj(params.row.isSupportedProductType).normal}
          </Button>

          <IconButton
            color="primary"
            className={classes.iconButtonStyle}
            onClick={() =>
              window.location.replace(
                `/amazon-listings-item/${Helper.encodeSlash(params.row.seller_sku)}/edit/${params.row.version}`
              )
            }
          >
            <Visibility />
          </IconButton>

          <IconButton
            color="primary"
            className={classes.iconButtonStyle}
            onClick={() => onRestore(params.row)}
            disabled={isRestoring}
          >
            <CloudSync />
          </IconButton>
        </Box>
      ),
    },
  ];

  async function onRestore(rowData) {
    setActiveSubmittingVersion(rowData.version);
    try {
      const {data} = await Api.post(`amazon-listings/item/restore`, rowData);
      if (data.status === 'ACCEPTED') {
        if (rowData.isSupportedProductType) alertService.success('Amazon Listings Item was updated successfully!');
        else
          alertService.success(
            'Amazon Listings Item Inventory File was generated successfully. You may receive the link of file soon.',
            {
              autoClose: true,
            }
          );
      } else {
        alertService.warn(`Request was sent, but failed to update with ${data.issues?.length} issues.`, {
          autoClose: true,
        });
      }
    } catch (e) {
      errorAlert('Failed to restore Amazon Listings Item', e);
    } finally {
      setActiveSubmittingVersion(null);
    }
  }

  let defaultQuery;
  function getDefaultQuery() {
    const params = {page, pageSize, sort};
    return params;
  }

  useEffect(() => {
    setMarketplaceObj(getMarketplaceById(marketplaceId));
    defaultQuery = getDefaultQuery();
    loadData(defaultQuery);
  }, []);

  async function loadData(params) {
    setSelectedRow('all');
    setLoadingData(true);
    setCount(0);
    setListingRows([]);
    try {
      const {data} = await Api.get(`amazon-listings/item-versions?marketplace_id=${marketplaceId}&sku=${sku}`, {
        params,
      });
      setCount(data.count);
      setListingRows(
        data.rows.map((x, i) => {
          return {id: i, ...x};
        })
      );
    } catch (e) {
      errorAlert('Failed to load data', e);
    } finally {
      setLoadingData(false);
    }
  }

  function handleChangePage(params) {
    setLoadingData(true);
    setPage(params);
    loadData({filter, page: params, pageSize, sort});
  }

  function handleChangePageSize(params: any) {
    setLoadingData(true);
    setPageSize(params);
    setPage(0);
    loadData({filter, page, pageSize: params, sort});
  }

  useEffect(() => {
    getArticle({filterPath: true});
  }, [loadingData]);

  return (
    <Grid container>
      {listingRows[0] && (
        <Grid container direction="row" className={classes.itemInfo}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">{listingRows[0].summaries[0].itemName}</Typography>
            <Typography variant="h6">Seller Id: {listingRows[0].seller_id}</Typography>
            <Typography variant="h6" style={{display: 'flex', alignItems: 'center'}}>
              Marketplace:&nbsp;
              {<Icons name={marketplaceObj.code.toUpperCase()} tooltip /> || 'N/A'}
            </Typography>
            <Typography variant="h6">ASIN: {listingRows[0].summaries[0].asin}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {listingRows[0].summaries[0]?.mainImage?.link && (
              <img src={listingRows[0].summaries[0]?.mainImage?.link} alt={listingRows[0].summaries[0]?.itemName} />
            )}
          </Grid>
        </Grid>
      )}
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.versionList}>
        <CollapsibleDataGrid
          className={`${classes.grid} custom-table`}
          pageSize={pageSize}
          rowCount={count}
          loading={loadingData}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={(v) => handleChangePageSize(v)}
          disableSelectionOnClick={true}
          onPageChange={(params) => handleChangePage(params)}
          rows={listingRows}
          columns={columns}
          accordionContent={getVersionsContent}
          selectedRow={selectedRow}
          setSelectedRow={(v) => setSelectedRow(v)}
          clickable={(params) => params.field !== 'actions' && params.row !== listingRows[listingRows.length - 1]}
          rowClassName={(params) =>
            params.field !== 'actions' && params.row !== listingRows[listingRows.length - 1] && 'hover-row'
          }
          multiple
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CustomAlert id="default-alert" />
      </Grid>
    </Grid>
  );
}
