import {Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
// components
import Images from '../../../components/Images/Images';

const useStyles = makeStyles((theme) => ({
  logoImg: {
    width: '100%',
    maxWidth: '12em',
    margin: '1em 0',
  },
}));

export default function LogoComponent(): JSX.Element {
  const classes = useStyles();

  return (
    <Grid container marginBottom={2}>
      <Grid item xs={12} lg={12}>
        <Images className={classes.logoImg} name={'Logo'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <img className={classes.logoImg} src={process.env.PUBLIC_URL + '/FFP_logo.png'} alt="Flat File Pro Logo" />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Images className={classes.logoImg} name={'ECOM3K'} />
      </Grid>
    </Grid>
  );
}
