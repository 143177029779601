import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  Sentry.init({
    dsn: 'https://3c3291c2c2df4c6fad4407f2894b8e35@o526149.ingest.sentry.io/5677583',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: 'stonehenge-app',
  });
}
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
