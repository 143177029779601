import {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import moment from 'moment';
import {Button, ButtonGroup, Checkbox, Grid, Typography} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {DataGrid, GridCellParams, GridColDef} from '@mui/x-data-grid';
// services
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {Helper} from '../../../../utils/helper';
// components
import DateRangePicker from '../../../../components/DateRangePicker/DateRangePicker';
import {MenuStore} from '../../../../App';
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {AsinPerformanceSummary} from './AsinPerformanceSummary';
import {PerformanceChart} from './PerformanceChart';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {buildMenu} from '../../components/menu';
// styles
import {useStyles} from '../../../Brands/Styles';

type DefaultQuery = {
  from: string;
  to: string;
  preset: string;
  selected: string[];
  metric: string[];
  compareFrom: string;
  compareTo: string;
};

const columns: GridColDef[] = [
  {field: 'seller_sku', headerName: 'SKU', flex: 0.6, filterable: false},
  {field: 'buy_box', headerName: 'BB%', flex: 0.2, filterable: false, renderCell: (x) => renderPercentageCell(x)},
  {
    field: 'session',
    align: 'right',
    headerName: 'Sessions',
    flex: 0.2,
    filterable: false,
    renderCell: (x) => renderAmountCell(x),
  },
  {
    field: 'revenue',
    align: 'right',
    headerName: 'Revenue',
    flex: 0.3,
    filterable: false,
    renderCell: (x) => renderAmountCell(x),
  },
  {
    field: 'conversion',
    headerName: 'Conversions',
    flex: 0.2,
    filterable: false,
    renderCell: (x) => renderPercentageCell(x),
  },
  {
    field: 'page_views_percentage',
    headerName: 'Page view %',
    flex: 0.2,
    filterable: false,
    renderCell: (x) => renderPercentageCell(x),
  },
  {
    field: 'units',
    align: 'right',
    headerName: 'Units',
    flex: 0.2,
    filterable: false,
    renderCell: (x) => renderAmountCell(x),
  },
];

function renderAmountCell(x: GridCellParams) {
  return (
    <div>
      <Typography color={'primary'}>
        {x.value != null ? Helper.formatAmount(parseFloat(x.value.toString())) : 'N/A'}
      </Typography>
      {'old_' + x.field in x.row ? (
        <Typography color={'textSecondary'}>
          {x.row['old_' + x.field] != null ? Helper.formatAmount(x.row['old_' + x.field]) : 'N/A'}
        </Typography>
      ) : (
        ''
      )}
    </div>
  );
}

function renderPercentageCell(x: GridCellParams) {
  return (
    <div>
      <Typography color={'primary'}>{x.value != null ? `${x.value}%` : 'N/A'}</Typography>
      {'old_' + x.field in x.row ? (
        <Typography color={'textSecondary'}>
          {x.row['old_' + x.field] != null ? `${x.row['old_' + x.field]}$` : 'N/A'}
        </Typography>
      ) : (
        ''
      )}
    </div>
  );
}

function AsinPerformance() {
  const location = useLocation();
  const classes = useStyles();
  const {id} = useParams<Record<string, string | undefined>>();
  const [fromTo, setFromTo] = useState({from: null, to: null, compareFrom: null, compareTo: null});
  const [metric, setMetric] = useState([]);
  const [period, setPeriod] = useState(null);

  const [skuRows, setSkuRows] = useState({rows: [], selected: []});
  const [metricData, setMetricData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);

  const [loadingSku, setLoadingSku] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [compare, setCompare] = useState(false);

  let history = useHistory();

  let defaultQuery;
  function getDefaultQuery() {
    const urlParams = new URLSearchParams(location.search);
    const fromQuery = urlParams.get('from');
    const toQuery = urlParams.get('to');
    const selectedQuery = urlParams.get('skus');
    const metricQuery = urlParams.get('metric');
    const compareFromQuery = urlParams.get('compareFrom');
    const compareToQuery = urlParams.get('compareTo');
    const query: DefaultQuery = {
      from: null,
      metric: null,
      preset: null,
      selected: null,
      to: null,
      compareFrom: null,
      compareTo: null,
    };

    query.metric = metricQuery && metricQuery !== '' ? metricQuery.split(',') : ['revenue'];
    query.selected = selectedQuery && selectedQuery !== '' ? selectedQuery.split(',') : [];

    if (!fromQuery || !toQuery) {
      query.to = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
      query.from = moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD');
      query.preset = '7d';
    } else if (!compareFromQuery || !compareToQuery) {
      query.from = moment(fromQuery).format('YYYY-MM-DD');
      query.to = moment(toQuery).format('YYYY-MM-DD');
    } else {
      query.from = moment(fromQuery).format('YYYY-MM-DD');
      query.to = moment(toQuery).format('YYYY-MM-DD');
      query.compareFrom = moment(compareFromQuery).format('YYYY-MM-DD');
      query.compareTo = moment(compareToQuery).format('YYYY-MM-DD');
    }
    return query;
  }

  function lastNDays(days) {
    const toValue = moment(new Date()).subtract(1, 'days');
    const fromValue = moment(toValue).subtract(days - 1, 'days');
    onFromToChange(fromValue, toValue, null, null, days + 'd', compare);
    setPeriod(days + 'd');
  }

  function quarterRange() {
    const toValue = moment(new Date()).subtract(1, 'days');
    const fromValue = moment().quarter(moment().quarter()).startOf('quarter');
    onFromToChange(fromValue, toValue, null, null, 'q', compare);
    setPeriod('q');
  }

  function yesterdayRange() {
    const toValue = moment(new Date()).subtract(1, 'days');
    const fromValue = toValue;
    onFromToChange(fromValue, toValue, null, null, 'y', compare);
    setPeriod('y');
  }

  function onFromToChange(from, to, cFrom, cTo, preset, compare) {
    const fromValue = moment(from).format('YYYY-MM-DD');
    const toValue = moment(to).format('YYYY-MM-DD');
    let compareFrom = cFrom;
    let compareTo = cTo;
    if (!compareFrom && !compareTo && compare) {
      if (preset === 'q') {
        compareFrom = moment(fromValue).subtract(1, 'quarter');
        compareTo = moment().quarter(moment(compareFrom).quarter()).endOf('quarter');
      } else {
        const diff = moment(toValue).diff(fromValue, 'days');
        compareFrom = moment(fromValue).subtract(diff + 1, 'days');
        compareTo = moment(toValue).subtract(diff + 1, 'days');
      }
    }
    const compareFromValue = compareFrom ? moment(compareFrom).format('YYYY-MM-DD') : null;
    const compareToValue = compareTo ? moment(compareTo).format('YYYY-MM-DD') : null;
    loadSkus(skuRows.selected, preset, fromValue, toValue, compareFrom, compareTo, false);
    setFromTo({from: fromValue, to: toValue, compareFrom: compareFromValue, compareTo: compareToValue});
    if (!preset) {
      setPeriod(null);
    }
  }

  function updateQueryParams() {
    let search = new URLSearchParams(location.search);
    if (metric && metric.length !== 0 && metric.length !== skuRows.rows.length) {
      search.set('metric', metric.toString());
    }
    search.set('from', fromTo.from);
    search.set('to', fromTo.to);
    if (fromTo.compareFrom && fromTo.compareTo) {
      search.set('compareFrom', fromTo.compareFrom);
      search.set('compareTo', fromTo.compareTo);
    } else {
      search.delete('compareFrom');
      search.delete('compareTo');
    }
    if (skuRows.selected.length !== 0 && skuRows.selected.length !== skuRows.rows.length) {
      search.set('skus', skuRows.selected.toString());
    } else search.delete('skus');
    const newUrl =
      window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + search.toString();
    window.history.pushState({path: newUrl}, '', newUrl);
  }

  const menuItems = buildMenu('performance', id, history);

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = menuItems;
    });
    defaultQuery = getDefaultQuery();
    loadSkus(
      defaultQuery.selected,
      defaultQuery.preset,
      defaultQuery.from,
      defaultQuery.to,
      defaultQuery.compareFrom,
      defaultQuery.compareTo,
      true
    );
    setFromTo({
      from: defaultQuery.from,
      to: defaultQuery.to,
      compareFrom: defaultQuery.compareFrom,
      compareTo: defaultQuery.compareTo,
    });
    setPeriod(defaultQuery.preset);
    setMetric(defaultQuery.metric);
    setCompare(defaultQuery.compareFrom != null);
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, []);

  useEffect(() => {
    updateQueryParams();
  }, [fromTo, skuRows, period, metric]);

  async function loadSkus(selected, period, from, to, compareFrom, compareTo, init) {
    setLoadingSku(true);
    try {
      const {data: skuData} = await Api.get(
        `asins/performance/skus/${id}?from=${from}&to=${to}${compareFrom ? `&compareFrom=${compareFrom}` : ''}${
          compareTo ? `&compareTo=${compareTo}` : ''
        }`
      );
      const data = skuData.map((x, i) => {
        return {id: x.seller_sku, ...x};
      });
      let model;
      if (selected.length === 0) {
        model = skuData.map((x) => x.seller_sku);
      } else {
        model = selected.filter((x) => data.some((y) => y.id === x));
      }
      setSkuRows({rows: data, selected: model});
      if (!init && JSON.stringify(model) === JSON.stringify(selected.filter((x) => data.some((y) => y.id === x)))) {
        onSelect(data, model, from, to, compareFrom, compareTo, false);
      }
    } catch (e) {
      errorAlert('Unable to get SKUs', e, {id: 'sku-performance'});
    } finally {
      setLoadingSku(false);
    }
  }

  async function loadSummary(selected, from, to, compareFrom, compareTo) {
    try {
      const {data} = await Api.get(
        `asins/performance/summary/${id}?skus=${selected}&from=${from}&to=${to}${
          compareFrom ? `&compareFrom=${compareFrom}` : ''
        }${compareTo ? `&compareTo=${compareTo}` : ''}`
      );
      setSummaryData(data);
    } catch (e) {
      errorAlert('Unable to get Asins', e, {id: 'asin-summary'});
    }
  }

  function getInterval(from, to, compareFrom, compareTo) {
    const fromValue = compareFrom < from ? compareFrom : from;
    const toValue = compareTo < to ? compareTo : to;
    if (moment(toValue).diff(fromValue, 'hours') < 24) {
      return 'minute';
    }
    return moment(toValue).diff(fromValue, 'days') > 5 ? 'day' : 'hour';
  }

  async function loadMetric(selected, from, to, compareFrom, compareTo, metric) {
    if (metric.length === 0) return;
    setLoadingChart(true);
    const interval = getInterval(from, to, compareFrom, compareTo);
    try {
      const {data} = await Api.get(
        `asins/performance/metric/${id}` +
          `?metric=${metric}` +
          `&skus=${selected}` +
          `&from=${from}` +
          `&to=${to}` +
          `${compareFrom ? `&compareFrom=${compareFrom}` : ''}` +
          `${compareTo ? `&compareTo=${compareTo}` : ''}` +
          `&interval=${interval}`
      );
      setMetricData({interval: interval, data: data});
    } catch (e) {
      errorAlert('Unable to get metric data', e, {id: 'performance-chart'});
    } finally {
      setLoadingChart(false);
    }
  }

  function onSelect(data, model, from, to, compareFrom, compareTo, updateSelected) {
    if (model && model.length === 0) {
      model = data.map((x) => x.seller_sku);
    } else {
      loadSummary(model, from, to, compareFrom, compareTo);
      loadMetric(model, from, to, compareFrom, compareTo, metric);
    }
    if (updateSelected) setSkuRows({rows: skuRows.rows, selected: model});
    if (model.length === 0) {
      setSummaryData(null);
      setMetricData(null);
    }
  }

  function toggleCompare(checked) {
    setCompare(checked);
    onFromToChange(fromTo.from, fromTo.to, null, null, period, checked);
  }

  const changeMetric = useCallback(
    (x) => {
      loadMetric(skuRows.selected, fromTo.from, fromTo.to, fromTo.compareFrom, fromTo.compareTo, x);
      setMetric(x);
    },
    [skuRows, fromTo, metric]
  );

  return (
    <>
      <TableContainer className="sub-page">
        <Grid container>
          <Grid item lg={8} xs={12}>
            <ButtonGroup
              className={classes.buttonGroup}
              size={'small'}
              variant={'outlined'}
              color={'primary'}
              disabled={loadingSku || loadingChart}
              fullWidth
              style={{marginBottom: '10px'}}
            >
              <Button
                variant={period === 'y' ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => yesterdayRange()}
              >
                Yesterday
              </Button>
              <Button
                variant={period === '7d' ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => lastNDays(7)}
              >
                7 days
              </Button>
              <Button
                variant={period === '30d' ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => lastNDays(30)}
              >
                30 days
              </Button>
              <Button
                variant={period === '60d' ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => lastNDays(60)}
              >
                60 days
              </Button>
              <Button
                variant={period === '90d' ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => lastNDays(90)}
              >
                90 days
              </Button>
              <Button
                variant={period === 'q' ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => quarterRange()}
              >
                Quarter
              </Button>
              <Button variant={!period ? 'contained' : 'outlined'} color={'primary'} onClick={() => setPeriod(null)}>
                Custom
              </Button>
            </ButtonGroup>
            {fromTo.to && fromTo.from && (
              <DateRangePicker
                width="450px"
                disabled={loadingSku || loadingChart}
                from={fromTo.from}
                to={fromTo.to}
                onChange={(x) => {
                  onFromToChange(
                    x.selection?.startDate,
                    x.selection?.endDate,
                    fromTo.compareFrom,
                    fromTo.compareTo,
                    false,
                    compare
                  );
                }}
              />
            )}
          </Grid>
          <Grid item lg={4} xs={12} style={{margin: 'auto', textAlign: 'right', paddingBottom: '15px'}}>
            <Typography component="h2" variant="h5" color="primary" gutterBottom>
              Individual ASIN Performance
            </Typography>
            <Typography component="h2" variant="h5" gutterBottom>
              {id}
            </Typography>
            <Typography>
              <Checkbox checked={compare} onChange={(e) => toggleCompare(e.target.checked)} /> Compare to previous
            </Typography>
          </Grid>
        </Grid>
        <AsinPerformanceSummary data={summaryData} />
        <PerformanceChart data={metricData} onMetricChange={changeMetric} metric={metric} loading={loadingChart} />
        <Grid item lg={12} style={{width: '100%', paddingTop: 30}}>
          <CustomAlert id="sku-performance" />
          <DataGrid
            autoHeight={true}
            rows={skuRows.rows}
            disableSelectionOnClick={true}
            columns={columns}
            loading={loadingSku}
            checkboxSelection
            onSelectionModelChange={(x: any) => {
              onSelect(
                skuRows.rows,
                x.selectionModel,
                fromTo.from,
                fromTo.to,
                fromTo.compareFrom,
                fromTo.compareTo,
                true
              );
            }}
            selectionModel={skuRows.selected}
            rowHeight={72}
            hideFooterPagination={true}
          />
        </Grid>
      </TableContainer>
    </>
  );
}

export {AsinPerformance};
