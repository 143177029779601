import {useCallback, useEffect, useState, useContext} from 'react';
import {
  Grid,
  InputLabel,
  Select,
  Box,
  FormControl,
  MenuItem,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';
import {
  DataGrid,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridPagination,
} from '@mui/x-data-grid';
import {useLocation} from 'react-router-dom';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import DropDownButton from '../../components/DropdownButton/DropDownButton';
import DraggableList from '../../components/DraggableList/DraggableList';
import DateRangeButtons from '../../components/DateRangeButtons/DateRangeButtons';
import Button from '../../components/Button/Button';
import {BrandPerformanceSummary} from '../Brands/subpages/Advertising/components/BrandPerformanceSummary';
import {PerformanceChart} from '../Brands/subpages/Advertising/components/PerformanceChart';
import {CustomAlert} from '../Login/components/CustomAlert';
import SearchBar from '../../components/SearchBar/SearchBar';
import {metricOptionsData} from '../Brands/subpages/Advertising/components/Metrics';
// styles
import {useStyles} from './Styles';
// services
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
// utils
import {createQueryString} from '../../utils/createQueryString';
import {onFromToChange} from '../../utils/OnFromToChange';
import {updateQueryParams, getDefaultQuery} from '../../utils/urlParams';
import {advertisingGroups} from './utils/advertisingGroups';
import {rowsPerPageOptions} from '../../utils/constants';
import {Api, errorAlert} from '../../utils/api';
import {Role} from '../../utils/role';
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../utils/tableSettings';
import {marketplaces} from '../Performance/utils/marketplaces';
import {getColumns} from './utils/getColumns';
// context
import {AppContext} from '../../context/AppContext/AppContext';

type DefaultQuery = {
  from: string;
  to: string;
  compareFrom?: string;
  compareTo?: string;
  preset: string;
  metric: string[];
  marketplace?: string;
  groupBy: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  pageSize?: number;
  brandCodes?: string[];
  searchStr?: string;
  brands?: string[];
};

const hiddenMetrics = ['revenue', 'tacos'];

function AdvertisingPerformanceMain(props) {
  const classes = useStyles();
  const location = useLocation();
  const {adsType} = props;
  const [loadingChart, setLoadingChart] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [loadingGroupRows, setLoadingGroupRows] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [compare, setCompare] = useState(false);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [groupBy, setGroupBy] = useState(adsType === 'dsp' ? 'advertiser' : 'campaign');
  const [metric, setMetric] = useState([]);
  const [searchStr, setSearchStr] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('desc');
  const [orderColumns, setOrderColumns] = useState([]);
  const [visibilityModel, setVisibilityModel] = useState<any>({});
  const [tableLoading, setTableLoading] = useState(false);
  const [filter, setFilter] = useState<any>({
    brandCodes: [],
    from: null,
    to: null,
    compareFrom: null,
    compareTo: null,
    preset: null,
    marketplace_id: marketplaces.find((x) => x.id === 'ATVPDKIKX0DER').id,
    searchStr: '',
    accountManagers: [],
  });
  const [filterData, setFilterData] = useState<any>(null);
  const [columns, setColumns] = useState(getColumns(adsType, groupBy));

  const {accountManagers, brands, appLoading} = useContext(AppContext);

  useEffect(() => {
    async function getTableData() {
      setTableLoading(true);
      const _columns = getColumns(adsType, groupBy);
      setColumns(_columns);
      try {
        const data = await getUserPreferences({
          list: _columns,
          tableName: `advertising-${groupBy}`,
          visibilityModel: visibilityModel,
          defaultVisibilityModel: {},
          loading: setTableLoading,
        });
        if (data) {
          setOrderColumns(data.columns);
          setVisibilityModel(data.visibility);
        }
      } catch (e) {
      } finally {
        setTableLoading(false);
      }
    }
    getTableData();
  }, [adsType, groupBy]);

  const getCustomUrlParams = () => {
    const query = getDefaultQuery(location, {...filter, groupBy, metric, sortBy, sortDirection, page, pageSize});

    const currentMetrics = Object.keys(metricOptionsData[adsType]).filter((x) => filterData?.metric?.includes(x));

    const newParams = filterData
      ? {
          ...filterData,
          page: 0,
          groupBy: adsType === 'dsp' ? 'advertiser' : 'campaign',
          metric: currentMetrics.length > 0 ? currentMetrics : [Object.keys(metricOptionsData[adsType])[0]],
          sortBy: '',
          sortDirection: 'asc',
        }
      : {
          ...query,
          brandCodes: query.brandCodes?.split(',') || [],
          accountManagers: query.accountManagers?.split(',') || [],
          marketplace_id: marketplaces.find((x) => x.id === query.marketplace_id)?.id || filter.marketplace_id,
          groupBy: query.groupBy || groupBy,
          sortBy: query.sortBy || filter.sortBy,
          sortDirection: query.sortDirection || filter.sortDirection,
          page: +query.page || +page,
          pageSize: +query.pageSize || +pageSize,
          metric: query.metric
            ? query.metric.split(',')
            : metricOptionsData.hasOwnProperty(adsType)
            ? [Object.keys(metricOptionsData[adsType])[0]]
            : metric,
        };

    setMetric(newParams.metric);
    setGroupBy(newParams.groupBy);
    setSortBy(newParams.sortBy);
    setSortDirection(newParams.sortDirection);
    setPage(newParams.page);
    setPageSize(newParams.pageSize);

    setFilter({
      brandCodes: newParams.brandCodes,
      from: newParams.from,
      to: newParams.to,
      compareFrom: newParams.compareFrom,
      compareTo: newParams.compareTo,
      preset: newParams.preset,
      marketplace_id: newParams.marketplace_id,
      searchStr: newParams.searchStr,
      accountManagers: newParams.accountManagers,
    });
    return newParams;
  };

  async function loadGroupRowsData(params: DefaultQuery) {
    setRows([]);
    setLoadingGroupRows(true);
    const query = createQueryString(
      {
        ...params,
        displayType: 'group',
      },
      ['accountManagers']
    );
    try {
      const {data} = await Api.get(`advertising/performance/${adsType}?` + query);
      if (data) {
        setCount(data.count);
        setRows(data.rows);
      }
    } catch (e) {
      errorAlert('Unable to get data', e, {id: 'groups-alert'});
    } finally {
      setLoadingGroupRows(false);
    }
  }

  async function loadSummaryData(params: DefaultQuery) {
    setLoadingSummary(true);
    const query = createQueryString({...params, displayType: 'summary'}, ['accountManagers']);
    try {
      const {data} = await Api.get(`advertising/performance/${adsType}?` + query);
      const _summaryData = {};
      for (const periodType of Object.keys(data)) {
        const timeRangeType = periodType.replace('AdsData', '');
        for (const metricKey in data[periodType]) {
          _summaryData[metricKey] = _summaryData.hasOwnProperty(metricKey)
            ? {..._summaryData[metricKey]}
            : Object.assign({});
          _summaryData[metricKey][timeRangeType] = Number(data[periodType][metricKey]);
        }
      }
      setSummaryData(_summaryData);
    } catch (e) {
      errorAlert('Unable to get summary data', e, {id: 'summary-alert'});
    } finally {
      setLoadingSummary(false);
    }
  }

  async function loadChartData(params: DefaultQuery) {
    setChartData(null);
    setLoadingChart(true);
    const query = createQueryString({...params, displayType: 'chart'}, ['accountManagers']);
    try {
      const {data} = await Api.get(`advertising/performance/${adsType}?` + query);
      if (!data) return;
      const _chartData = [];
      for (const periodType of Object.keys(data)) {
        const timeRangeType = periodType.replace('AdsData', '');
        for (const record of data?.[periodType] || []) {
          for (const metricKey in record) {
            if (metricKey === 'reportDate') continue;
            const chartDataIdx = _chartData.findIndex(
              (item) => item.type === timeRangeType && item.metric === metricKey
            );
            if (metric?.includes(metricKey) || true) {
              if (chartDataIdx < 0) {
                _chartData.push({
                  type: timeRangeType,
                  metric: metricKey,
                  data: [
                    {
                      date: record.reportDate,
                      value: Number(record[metricKey]),
                    },
                  ],
                });
              } else {
                _chartData[chartDataIdx].data?.push({
                  date: record.reportDate,
                  value: Number(record[metricKey]),
                });
              }
            }
          }
        }
      }
      setChartData(_chartData);
    } catch (e) {
      errorAlert('Unable to get chart data', e, {id: 'chart-alert'});
    } finally {
      setLoadingChart(false);
    }
  }

  async function onDownload() {
    setDownloading(true);
    const data = {
      ...filter,
      ...filterData,
      adsType,
      displayType: 'group',
      marketplaceName: marketplaces.find((x) => x.id === filter.marketplace)?.name,
      metric: metric.join(','),
      sortBy,
      sortDirection,
      brandCodes: filter.brandCodes || [],
    };
    try {
      const {data: performanceData} = await Api.post(`advertising/performance-download`, data);
      if (performanceData.status === true) {
        const fileName = 'Advertising Performance Data.csv';
        const link = document.createElement('a');
        link.href = performanceData.message;
        link.setAttribute('download', fileName);
        link.setAttribute('rel', `noreferrer noopener`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        alertService.success('Advertising Performance Data was downloaded.');
      } else {
        alertService.error(performanceData?.message);
      }
    } catch (e) {
      errorAlert('Unable to download Advertising Performance Data', e);
    } finally {
      setDownloading(false);
    }
  }

  const getAdvertisingData = (params) => {
    if (!params.marketplace_id) return;
    updateQueryParams(location, params);
    loadGroupRowsData(params);
    loadSummaryData(params);
    loadChartData(params);
  };

  const toggleCompare = (checked) => {
    setCompare(checked);
    onFromToChange({
      from: filter.from,
      to: filter.to,
      cFrom: null,
      cTo: null,
      preset: filter.preset,
      compare: checked,
      loadData: (preset, fromValue, toValue, compareFromValue, compareToValue) => {
        const queryParams = {
          ...filter,
          from: fromValue,
          to: toValue,
          compareFrom: compareFromValue,
          compareTo: compareToValue,
        };
        setFilter(queryParams);
      },
      setFromTo: (x) => setFilter((prev) => ({...prev, ...x})),
    });
  };

  const changeMetric = useCallback(
    (x) => {
      setMetric(x);
      setFilterData((prev) => ({...prev, metric: x}));
      getAdvertisingData({...filterData, metric: x});
    },
    [metric]
  );

  const handleChangeMarketplace = (e) => {
    const _marketplace = marketplaces.find((x) => x.id === e.target.value).id;
    setFilter((prev) => ({...prev, marketplace_id: _marketplace}));
  };

  const handleChangeBrand = (brands) => {
    setFilter((prev) => ({
      ...prev,
      brandCodes: brands,
      accountManagers: brands.length === 0 ? [] : prev.accountManagers,
    }));
  };

  const handleChangeAccountManager = (accountManager) => {
    const brands = [];
    const currentAccountManagers = accountManagers.filter((x) => accountManager.includes(x.email));

    currentAccountManagers.forEach((x) => {
      x.brands.forEach((brand) => {
        if (!brands.find((b) => b.brand_code === brand.brand_code)) {
          brands.push(brand.brand_code);
        }
      });
    });

    setFilter((prev) => ({...prev, brandCodes: brands, accountManagers: accountManager}));
    handleChangeBrand(brands);
  };

  const onGroupBy = (e) => {
    setSortBy(null);
    setSortDirection('asc');
    const _params = {...filterData, groupBy: e.target.value, sortDirection: 'asc', page: 0};
    setCount(0);
    setPage(0);
    setGroupBy(e.target.value);
    setFilterData(_params);
    getAdvertisingData(_params);
    updateQueryParams(location, _params);
  };

  const handleChangePage = (params: any) => {
    setPage(params);
    const _params = {...filterData, page: params};
    setFilterData(_params);
    loadGroupRowsData(_params);
    updateQueryParams(location, _params);
  };

  const handleChangePageSize = (params: any) => {
    setPageSize(params);
    const _params = {...filterData, pageSize: params, page: 0};
    setFilterData(_params);
    loadGroupRowsData(_params);
    updateQueryParams(location, _params);
  };

  const onSearch = () => {
    const _params = {...filter, searchStr: searchStr};
    setFilter(_params);
  };

  const onSortModelChange = (x) => {
    setSortBy(x[0]?.field);
    setSortDirection(x[0]?.sort);
    const _params = {...filterData, sortBy: x[0]?.field, sortDirection: x[0]?.sort};
    setFilterData(_params);
    loadGroupRowsData(_params);
  };

  const DataGridToolbar = () => (
    <GridToolbarContainer className="toolbar-container">
      <GridToolbarColumnsButton
        className={classes.toolbarButton}
        disabled={loadingSummary || loadingChart || loadingSummary || tableLoading}
      />
      <DropDownButton buttonType="text" buttonText="Order Columns" bold loading={loadingChart || tableLoading}>
        <DraggableList
          items={[...orderColumns]}
          setItems={(v) => {
            setOrderColumns(v);
            setUserPreferences(
              {
                columnVisibilityModel: visibilityModel,
                columnsOrder: v.map((x) => x.field),
              },
              `advertising-${groupBy}`,
              setTableLoading
            );
          }}
        />
      </DropDownButton>
      <GridPagination />
    </GridToolbarContainer>
  );

  useEffect(() => {
    const defaultQuery = getCustomUrlParams();
    setFilterData(defaultQuery);
    setCompare(defaultQuery.compareFrom !== null);
    getAdvertisingData(defaultQuery);
  }, [adsType]);

  return (
    <TableContainer className="sub-page">
      <Grid container>
        <Grid container>
          <Grid item lg={9} md={12} sm={12} xs={12}>
            <DateRangeButtons
              className={`${classes.buttonGroup} new-group`}
              fromTo={{
                from: filter.from,
                to: filter.to,
                cFrom: filter.compareFrom,
                cTo: filter.compareTo,
              }}
              compare={compare}
              setFromTo={(v) => setFilter((prev) => ({...prev, ...v}))}
              period={filter.preset}
              loadData={(preset, fromValue, toValue, compareFromValue, compareToValue) => {
                const queryParams = {
                  ...filter,
                  from: fromValue,
                  to: toValue,
                  compareFrom: compareFromValue,
                  compareTo: compareToValue,
                  preset: preset,
                };
                setFilter(queryParams);
              }}
              compareOnChange={(e) => toggleCompare(e.target.checked)}
              compareComponent
            />
          </Grid>
          <Grid item lg={3} md={12} sm={12} xs={12} style={{textAlign: 'right', marginBottom: '15px'}}>
            <Box display={'flex'} flexDirection={'row-reverse'}>
              <FormControl
                variant="outlined"
                style={{width: '100%', maxWidth: '220px', textAlign: 'left'}}
                size="small"
              >
                <InputLabel id="marketplace">Marketplace</InputLabel>
                <Select
                  value={filter.marketplace_id}
                  onChange={handleChangeMarketplace}
                  label={'marketplace'}
                  labelId="marketplace"
                  variant="outlined"
                >
                  {marketplaces.map((x) => (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{margin: '10px 0 30px 0'}}>
          <Grid item lg={2.5} md={3} sm={6} xs={12}>
            <Autocomplete
              size="small"
              multiple
              options={brands?.map((x) => x.brand_code)}
              getOptionLabel={(option) => {
                const brandName = brands?.find((x) => x.brand_code === option)?.name;
                return brandName ? `${option} -  ${brandName}` : option;
              }}
              value={filter.brandCodes}
              disabled={loadingGroupRows || loadingChart || loadingSummary || appLoading}
              onChange={(event, newValue) => {
                handleChangeBrand(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Filter by brands" placeholder="Brands" />
              )}
            />
          </Grid>
          {!(
            accountService?.userValue.role === Role.StandardAgencyUser ||
            accountService.userValue.role === Role.BrandUser
          ) && (
            <Grid item lg={2.5} md={3} sm={6} xs={12}>
              <Autocomplete
                size="small"
                multiple
                options={accountManagers.map((x) => x.email)}
                getOptionLabel={(option) => option}
                value={filter.accountManagers}
                disabled={loadingGroupRows || loadingChart || loadingSummary || appLoading}
                onChange={(event, newValue) => {
                  handleChangeAccountManager(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Filter by Account Manager"
                    placeholder="Account Manager"
                  />
                )}
              />
            </Grid>
          )}

          <Grid item lg={2.5} md={3} sm={6} xs={12}>
            <SearchBar
              value={searchStr}
              placeholder="Search by"
              onChange={(value) => setSearchStr(value)}
              onSearch={() => onSearch()}
              showButton={false}
            />
          </Grid>

          <Grid item lg={2}>
            <Button
              size="small"
              disabled={loadingSummary || appLoading}
              onClick={() => {
                getAdvertisingData({...filterData, ...filter, page: 0});
                setFilterData((prev) => ({...prev, ...filter, page: 0}));
                setPage(0);
              }}
            >
              Apply Filters
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container>
            <CustomAlert id="summary-alert" />
            <BrandPerformanceSummary data={summaryData} loading={loadingSummary} hiddenMetrics={hiddenMetrics} />
            <CustomAlert id="chart-alert" />
            <PerformanceChart
              data={chartData}
              onMetricChange={changeMetric}
              metric={metric}
              loading={loadingChart}
              hiddenMetrics={hiddenMetrics}
            />
          </Grid>
          <CustomAlert id="groups-alert" />
          <Grid container marginTop={3} spacing={1}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Grid item lg={5} md={3} sm={6} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={loadingSummary || loadingChart || loadingSummary}
                >
                  <InputLabel id="demo-simple-select-outlined-label">Group by</InputLabel>
                  <Select
                    value={groupBy}
                    onChange={onGroupBy}
                    label={'Group by'}
                    labelId="demo-simple-select-outlined-label"
                    variant="outlined"
                  >
                    {Object.entries(advertisingGroups?.[adsType]).map(([key, value]: any) => (
                      <MenuItem key={key} value={key}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12} textAlign={'right'}>
              <Button
                size="small"
                type="button"
                variant="contained"
                color="primary"
                disabled={isDownloading}
                onClick={onDownload}
              >
                Export {isDownloading && <CircularProgress size={12} style={{color: 'blue'}} />}
              </Button>
            </Grid>
          </Grid>
          <Grid item lg={12} style={{width: '100%', paddingTop: 30}}>
            <DataGrid
              className={`${classes.grid} custom-table ${loadingGroupRows ? 'load-headers' : ''}`}
              components={{
                Pagination: DataGridToolbar,
                Toolbar: DataGridToolbar,
              }}
              rowHeight={70}
              autoHeight={true}
              rows={rows || []}
              disableSelectionOnClick={true}
              disableVirtualization
              loading={loadingGroupRows}
              disableColumnFilter
              pagination
              rowCount={count}
              page={page}
              pageSize={pageSize}
              paginationMode="server"
              filterMode="server"
              sortingMode="server"
              onPageChange={(params) => handleChangePage(params)}
              onPageSizeChange={(params) => handleChangePageSize(params)}
              rowsPerPageOptions={rowsPerPageOptions}
              onSortModelChange={(x: any) => {
                onSortModelChange(x);
              }}
              sortModel={
                sortBy && sortDirection
                  ? [
                      {
                        field: sortBy,
                        sort: sortDirection as GridSortDirection,
                      },
                    ]
                  : []
              }
              columns={orderColumns}
              columnVisibilityModel={visibilityModel}
              onColumnVisibilityModelChange={(newModel) => {
                let data = {};
                Object.entries(newModel).forEach(([x, v]) => {
                  if (v === false) {
                    data = {...data, [x]: v};
                  }
                });
                const newOrder = getColumnsItems({
                  list: columns,
                  columnsVisibility: data,
                  columnsOrder: orderColumns,
                  currentOrder: orderColumns,
                });
                setVisibilityModel(data);
                setOrderColumns(newOrder);
                setUserPreferences(
                  {
                    columnVisibilityModel: data,
                    columnsOrder: newOrder.map((x) => x.field),
                  },
                  `advertising-${groupBy}`,
                  setTableLoading
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </TableContainer>
  );
}
export {AdvertisingPerformanceMain};
