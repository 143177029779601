import {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {makeStyles} from '@mui/styles';
import {Link, useHistory, useParams} from 'react-router-dom';
// services
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
// components
import Button from '../../components/Button/Button';
import LoginView from '../../components/LoginView/LoginView';
import LogoComponent from '../Login/components/LogoComponent';
import {CustomAlert} from '../Login/components/CustomAlert';

const useStyles = makeStyles((theme) => ({
  logoImg: {
    width: '100%',
    maxWidth: '12em',
    margin: '3em 0',
  },
  inputField: {
    margin: '0.5em',
    width: '95%',
  },
}));

function Register({passwordReset}) {
  let history = useHistory();
  const {token} = useParams<Record<string, string | undefined>>();
  const [valid, setValid] = useState(null);

  useEffect(() => {
    if (accountService.userValue || !token) {
      history.push('/');
    }
    accountService
      .isRegisterTokenValid(token)
      .then(() => setValid(true))
      .catch((err) => {
        setValid(false);
      });
  }, []);

  const classes = useStyles();

  const initialValues = {
    password: '',
    passwordConfirmation: '',
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Password must contain at least 8 characters, one uppercase, one number and one special case character'
      ),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  function onSubmit(data, {setSubmitting}) {
    setSubmitting(true);
    alertService.clear();
    accountService
      .register(data, token)
      .then(() => {
        if (passwordReset) {
          alertService.success(
            <>
              Password changed successfully, please log in using your email address and the password you just created{' '}
              <br />
              <br />{' '}
              <Link to="/login">
                <Button variant="contained" color="secondary">
                  Login
                </Button>
              </Link>
            </>,
            {autoClose: false}
          );
        } else
          alertService.success(
            <>
              Account successfully created, please log in using your email address and the password you just created{' '}
              <br />
              <br />{' '}
              <Link to="/login">
                <Button variant="contained" color="secondary">
                  Login
                </Button>
              </Link>
            </>,
            {autoClose: false}
          );
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.response.data && error.response.data.message) {
          alertService.error(error.response.data.message);
        } else {
          alertService.error(error.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  const fields = [
    {
      label: 'Password',
      name: 'password',
      icon: 'password',
      type: 'password',
    },
    {
      label: 'Confirm password',
      name: 'passwordConfirmation',
      icon: 'password',
      type: 'password',
    },
  ];

  return (
    <>
      <LoginView
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        submitText="Register"
        fields={fields}
        logo={<LogoComponent />}
        formDescription="Please enter and confirm your new password"
        isInvalid={valid !== null && !valid}
        message="Invalid token"
      />
      <CustomAlert id="default-alert" className={classes.inputField} />
    </>
  );
}

export {Register};
