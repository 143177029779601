import {useEffect, useState} from 'react';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  Pagination,
} from '@mui/material';
import {Link} from 'react-router-dom';
// utils
import {Api, errorAlert} from '../../../utils/api';
// components
import {CustomAlert} from '../../Login/components/CustomAlert';
const pageSize = 9;

function NewBrandsWidget({classes}) {
  const [brandRows, setBrandRows] = useState([]);
  const [loadingBrands, setLoadingBrands] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(pageSize);

  async function getBrands() {
    setLoadingBrands(true);
    try {
      const {data: brandsData} = await Api.get(`brands/new/get`);
      const brands = brandsData.map((x) => {
        return {id: x.brand_code, ...x};
      });
      setBrandRows(brands);
      try {
        const {data} = await Api.get(`brand-revenue/new/revenue?brands=${brandsData.map((x) => x.brand_code)}`);
        setBrandRows(
          brands.map((x) => {
            const obj = data.find((y) => x.brand_code === y.brand_code);
            if (obj) {
              return {
                id: x.brand_code,
                revenue_1d: obj.revenue_1d,
                revenue_7d: obj.revenue_7d,
                revenue_30d: obj.revenue_30d,
                ...x,
              };
            } else {
              return {
                id: x.brand_code,
                ...x,
              };
            }
          })
        );
      } catch (e) {
        errorAlert('Unable to get revenue data', e);
      } finally {
        setLoadingBrands(false);
      }
    } catch (e) {
      setLoadingBrands(false);
      errorAlert('Unable to get brands', e);
    }
  }

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <Paper className={classes.wrapperDiv} variant={'outlined'}>
      <Typography component="h2" variant="h5" color="secondary">
        New brands
      </Typography>
      <Grid container spacing={1} justifyContent="center" style={{paddingTop: 20}}>
        <Grid item lg={12} md={12}>
          <Grid container spacing={1} justifyContent="center" style={{paddingTop: 20}}>
            {brandRows && brandRows.length > 0 ? (
              brandRows.slice(minValue, maxValue).map((val) => (
                <Grid item lg={4} md={4} sm={6} xs={6} key={val.brand_code}>
                  <Card style={{height: 220}}>
                    <CardContent>
                      <Link to={`/brands/${val.brand_code}/detail`}>
                        <div style={{margin: 'auto'}} className="brand-cards">
                          <Avatar>{val.brand_code}</Avatar>
                          <Typography component="h2" variant="subtitle1" color="textPrimary" gutterBottom>
                            {val.name}
                          </Typography>
                        </div>
                      </Link>
                    </CardContent>
                    <CardActions style={{paddingTop: 0}}>
                      <Grid item lg={4}>
                        <Typography gutterBottom variant="subtitle2" component="h5">
                          {val.revenue_1d ? `1d` : ''}
                          <br />
                          {val.revenue_1d ? (
                            <Typography
                              variant="caption"
                              className={val.revenue_1d >= 0 ? classes.green : classes.red}
                              color={'primary'}
                            >
                              {val.revenue_1d < 0 ? '' : '+'}
                              {val.revenue_1d}%
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item lg={4}>
                        <Typography gutterBottom variant="subtitle2" component="h5">
                          {val.revenue_7d ? `7d` : ''}
                          <br />
                          {val.revenue_7d ? (
                            <Typography
                              variant="caption"
                              className={val.revenue_7d >= 0 ? classes.green : classes.red}
                              color={'primary'}
                            >
                              {val.revenue_7d < 0 ? '' : '+'}
                              {val.revenue_7d}%
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item lg={4}>
                        <Typography gutterBottom variant="subtitle2" component="h5">
                          {val.revenue_30d ? `30d` : ''}
                          <br />
                          {val.revenue_30d ? (
                            <Typography
                              variant="caption"
                              className={val.revenue_30d >= 0 ? classes.green : classes.red}
                              color={'primary'}
                            >
                              {val.revenue_30d < 0 ? '' : '+'}
                              {val.revenue_30d}%
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            ) : loadingBrands ? (
              <CircularProgress />
            ) : (
              <Typography>No rows</Typography>
            )}
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <Pagination
            className={classes.pagination}
            style={{marginTop: 20, justifyContent: 'center'}}
            count={Math.ceil(brandRows.length / pageSize)}
            onChange={(event, value) => {
              if (value <= 1) {
                setMinValue(0);
                setMaxValue(pageSize);
              } else {
                setMinValue(value * pageSize - pageSize);
                setMaxValue(value * pageSize);
              }
            }}
          />
        </Grid>
        <CustomAlert id={'new-brands-alert'} />
      </Grid>
    </Paper>
  );
}

export {NewBrandsWidget};
