import TableContainer from '../TableContainer/TableContainer';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-mui';
import {Link} from 'react-router-dom';
import {Grid, Paper, useTheme, Theme, InputAdornment, Button, Typography, CircularProgress} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {AlternateEmail, LockOutlined, PersonOutlineOutlined} from '@mui/icons-material';
import {ThemeProvider, createTheme} from '@mui/material/styles';
// images
import Image from '../../assets/images/login-img.png';
// style
import './global-styles.css';

const useStyles = makeStyles((theme: Theme) => ({
  loginPaper: {
    textAlign: 'center',
    padding: '1em 2em 3em 2em',
    margin: '2em 0em',
    maxWidth: '500px',
    width: '100%',
    background: 'transparent',
    [theme.breakpoints.down(900)]: {
      padding: '1em 2em 3em 2em',
      background: '#ffffff9d',
      borderRadius: '40px',
    },
  },
  inputField: {
    width: '100%',
    margin: '10px 0',
    '& .MuiInputLabel-shrink': {
      fontWeight: '600',
    },
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 100px #fff inset!important',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.3rem',
      color: '#adadad',
    },
    [theme.breakpoints.down(900)]: {
      '& .MuiOutlinedInput-root': {
        background: '#fff',
        borderRadius: '5px',
      },
    },
  },
  loginButton: {
    margin: '20px 0 30px 0',
    width: '100%',
    padding: '13px 0',
    background: '#174582',
  },
  customView: {
    height: '100vh',
    '& > div:first-of-type': {
      [theme.breakpoints.down(900)]: {
        padding: '1em 1em 3em 1em',
        backgroundImage: `url(${Image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
    },
    '& > div:last-of-type': {
      backgroundImage: `url(${Image})`,
      backgroundPosition: 'right top',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      clipPath: 'polygon(100% 0%, 100% 50%, 100% 100%, 0 100%, 10% 51%, 0 0)',
      [theme.breakpoints.down(900)]: {
        display: 'none',
      },
    },
  },
  grid: {
    background: '#fff',
    maxWidth: '100%',
    '& > .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    [theme.breakpoints.down(1024)]: {
      padding: '0 15px',
    },
    [theme.breakpoints.down(900)]: {
      '& > .MuiGrid-item:first-of-type': {
        display: 'none',
      },
    },
  },
}));

interface LoginViewProps {
  validationSchema: any;
  onSubmit: any;
  initialValues: any;
  fields: any[];
  submitText: string;
  forgotPassword?: string;
  formDescription?: string;
  logo?: any;
  ref?: any;
  actionButton?: any;
  isInvalid?: boolean;
  message?: string;
}

export default function LoginView({
  validationSchema,
  onSubmit,
  initialValues,
  fields,
  submitText,
  forgotPassword,
  formDescription,
  logo,
  ref,
  actionButton,
  isInvalid,
  message,
}: LoginViewProps) {
  const classes = useStyles();
  const theme = useTheme();

  const icons = {
    email: <AlternateEmail />,
    password: <LockOutlined />,
    user: <PersonOutlineOutlined />,
  };

  const newTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        light: '#fff',
        main: '#174582',
        dark: '#052d61',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ffffff',
        main: '#174582',
        dark: '#1b2132',
        contrastText: '#000',
      },
      success: {
        light: '#81c784',
        main: '#4caf50',
        dark: '#388e3c',
        contrastText: '#fff',
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: '#24242488',
      },
      background: {
        default: '#fff',
        paper: '#fff',
      },
    },
    typography: {
      fontFamily: [
        'Karla',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });

  return (
    <ThemeProvider theme={newTheme}>
      <TableContainer className={classes.grid} container justifyContent="space-between" alignItems="center">
        <Grid container className={classes.customView}>
          <Grid item lg={6} md={6} sm={12} xs={12} container alignItems="center" justifyContent="center">
            <Paper className={classes.loginPaper} elevation={0}>
              {logo}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                innerRef={ref}
              >
                {({errors, touched, values, isSubmitting}) => {
                  return (
                    <Form>
                      {isInvalid ? (
                        <Typography component="h2" variant="h4" color={'error'} gutterBottom>
                          {message}
                        </Typography>
                      ) : (
                        <>
                          {formDescription && (
                            <div>
                              <Typography component="h3" variant="h6" color={theme.palette.primary.main} gutterBottom>
                                {formDescription}
                              </Typography>
                              <br />
                            </div>
                          )}
                          <div>
                            {fields.map((item, key) => {
                              return (
                                <div key={key}>
                                  <div>{item.extra}</div>
                                  <Field
                                    autocomplete="none"
                                    className={classes.inputField}
                                    component={TextField}
                                    variant="outlined"
                                    name={item.name}
                                    placeholder={item.label}
                                    type={item.type}
                                    size="large"
                                    InputLabelProps={{shrink: false}}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" style={{outline: 'none'}}>
                                          {icons[item.icon]}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                              );
                            })}
                            <div>
                              <Button
                                variant="contained"
                                size="large"
                                type="submit"
                                disabled={isSubmitting}
                                className={classes.loginButton}
                                onClick={() => actionButton && actionButton(values)}
                              >
                                {submitText}
                                {isSubmitting && <CircularProgress size={12} style={{margin: '0 10px'}} />}
                              </Button>
                            </div>
                            {forgotPassword && (
                              <div>
                                <Link to={forgotPassword}>
                                  <Button variant="outlined" color="secondary" size="large">
                                    Forgot password
                                  </Button>
                                </Link>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </Paper>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}></Grid>
        </Grid>
      </TableContainer>
    </ThemeProvider>
  );
}
