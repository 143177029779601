import {Paper, Grid, Theme} from '@mui/material';
import React, {useEffect} from 'react';
import {makeStyles} from '@mui/styles';
// components
import TableContainer from '../../../components/TableContainer/TableContainer';
import PageTitle from '../../../components/PageTitle/PageTitle';
// services
import {accountService} from '../../../services/account.service';

const useStyles = makeStyles((theme: Theme) => ({
  loginPaper: {
    textAlign: 'center',
    padding: '3em 2em 3em 2em',
    margin: '2em 0em',
  },
  inputField: {
    margin: '0.5em',
    width: '95%',
  },
  loginButton: {
    margin: '0.5em',
  },
  logoImg: {
    width: '10em',
    margin: '3em',
  },
  image: {
    width: '100%',
    maxWidth: '550px',
  },
  grid: {
    padding: '0 65px 0 0',
    minHeight: 'calc(90vh - 70px)',
    maxWidth: '100%',
    '& > .MuiGrid-item': {
      padding: ' 0 25px',
      textAlign: 'center',
    },
    [theme.breakpoints.down(1024)]: {
      padding: '0 15px',
    },
    [theme.breakpoints.down(900)]: {
      '& > .MuiGrid-item:first-of-type': {
        display: 'none',
      },
      '& > .MuiGrid-item': {
        width: '100%',
      },
    },
  },
}));

function SubscriptionCancelledPage() {
  const classes = useStyles();

  useEffect(() => {
    accountService.refreshToken();
  }, []);

  return (
    <TableContainer className={classes.grid} container justifyContent="space-between" alignItems="center">
      <Grid item lg={6} md={6} xs={12}>
        <img src={process.env.PUBLIC_URL + '/Market.svg'} alt="login-img" className={classes.image} />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <Paper className={classes.loginPaper} elevation={5}>
          <PageTitle variant="h4" component="h1" display="block" fontSize="28px" fontWeight={600}>
            Welcome back!
          </PageTitle>
        </Paper>
      </Grid>
    </TableContainer>
  );
}

export {SubscriptionCancelledPage};
