import {FormControl} from '@mui/material';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-mui';
import * as Yup from 'yup';
// utils
import {Api, errorAlert} from '../../../utils/api';
// services
import {accountService} from '../../../services/account.service';
import {alertService} from '../../../services/alert.service';
// styles
import {useStyles} from '../Styles';
// components
import Button from '../../../components/Button/Button';

interface UpdateExtraProductFormProps {
  onSuccess: () => void;
  productId?: string;
  label: string;
  coupon?: boolean;
  GAEvents?: (category, action, label?) => void;
  currentQuantity?: number;
  defaultValue?: string;
}

export default function UpdateExtraProductForm({
  onSuccess,
  productId,
  label,
  coupon,
  GAEvents,
  currentQuantity,
  defaultValue,
}: UpdateExtraProductFormProps) {
  const classes = useStyles();

  async function onUpdateExtraProduct(productId: string, values) {
    const seats = parseInt(values.quantity) + currentQuantity;
    const endpoint = coupon
      ? `/brands/${accountService.userValue.brand_code}/subscription-coupon`
      : `/brands/${accountService.userValue.brand_code}/subscription-items/${productId}`;
    const method = coupon ? 'POST' : 'PATCH';
    const body = coupon ? {coupon: values.coupon} : {quantity: seats};
    try {
      await Api.request({
        method,
        url: endpoint,
        data: body,
      });
      onSuccess();
      alertService.success(coupon ? 'The coupon has been applied successfully' : 'Product updated successfully');
    } catch (e: any) {
      errorAlert(coupon ? e?.response?.data?.message : 'Something went wrong', e);
    }
  }

  function getValidationSchema() {
    return Yup.object().shape(
      coupon
        ? {
            coupon: Yup.string().required('Coupon is required'),
          }
        : {
            quantity: Yup.number().min(1, 'Minimum quantity is 1').required('This field is required'),
          }
    );
  }

  return (
    <Formik
      initialValues={
        coupon
          ? {
              coupon: defaultValue || '',
            }
          : {
              quantity: 1,
            }
      }
      validationSchema={getValidationSchema}
      onSubmit={(values) => onUpdateExtraProduct(productId, values)}
      validateOnChange={true}
    >
      {({errors, touched, isSubmitting, values: formValues}) => (
        <Form className={classes.form}>
          {coupon ? (
            <FormControl size="small" className={classes.inputField} variant={'outlined'}>
              <Field
                label={label}
                name="coupon"
                value={formValues.coupon}
                error={touched['coupon'] && !!errors['coupon']}
                size="small"
                component={TextField}
              />
            </FormControl>
          ) : (
            <FormControl size="small" className={classes.inputField} variant={'outlined'}>
              <Field
                label={label}
                name="quantity"
                value={formValues.quantity}
                error={touched['quantity'] && !!errors['quantity']}
                type="number"
                size="small"
                component={TextField}
                onKeyDown={(e): any => {
                  ['e', 'E', '+', '-', ',', '.'].includes(e.key) && e.preventDefault();
                }}
                InputProps={{inputProps: {min: 1}}}
              />
            </FormControl>
          )}
          <Button
            size="large"
            type="submit"
            color="primary"
            loading={isSubmitting}
            onClick={() => GAEvents('Subscription', label, `user: ${accountService.userValue.userId}`)}
          >
            {coupon ? 'Send' : 'Update'}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
