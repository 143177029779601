import React from 'react';
import {Tooltip, SvgIcon} from '@mui/material';
// icons
import Flags from 'country-flag-icons/react/3x2';

interface IconsProps {
  name: string;
  className?: string;
  width?: number | string;
  height?: number;
  tooltip?: boolean;
  onClick?: () => void;
}

export default function Icons({name, className, width, height, tooltip, onClick}: IconsProps): JSX.Element {
  const iconsMap: Map<string, {component: any}> = new Map([
    ['US', {component: <Flags.US />}],
    ['DE', {component: <Flags.DE />}],
    ['TR', {component: <Flags.TR />}],
    ['CA', {component: <Flags.CA />}],
    ['MX', {component: <Flags.MX />}],
    ['BR', {component: <Flags.BR />}],
    ['SG', {component: <Flags.SG />}],
    ['AU', {component: <Flags.AU />}],
    ['JP', {component: <Flags.JP />}],
    ['ES', {component: <Flags.ES />}],
    ['UK', {component: <Flags.GB />}],
    ['GB', {component: <Flags.GB />}],
    ['FR', {component: <Flags.FR />}],
    ['NL', {component: <Flags.NL />}],
    ['IT', {component: <Flags.IT />}],
    ['SE', {component: <Flags.SE />}],
    ['PL', {component: <Flags.PL />}],
    ['AE', {component: <Flags.AE />}],
    ['IN', {component: <Flags.IN />}],
    ['EG', {component: <Flags.EG />}],
  ]);
  const icon = iconsMap.get(name);
  if (!icon) {
    return <React.Fragment />;
  }

  return (
    <Tooltip title={tooltip && name}>
      <SvgIcon>
        {React.cloneElement(icon.component, {
          className,
          width,
          height,
          onClick,
        })}
      </SvgIcon>
    </Tooltip>
  );
}
