import {useEffect, useState} from 'react';
import {Chip, Paper, Tooltip, Typography, Theme, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
// utils
import {Helper} from '../../../utils/helper';
// components
import SkeletonLoader from '../../../components/SkeletonLoader/SkeletonLoader';
import {CustomAlert} from '../../Login/components/CustomAlert';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: '10px',
  },
  green: {
    margin: '0px 10px',
    backgroundColor: '#4caf50',
  },
  red: {
    margin: '0px 10px',
    backgroundColor: '#f44336',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    gridGap: '15px',

    [theme.breakpoints.down(1320)]: {
      '& .MuiTypography-subtitle1 ': {
        fontSize: '.9rem',
      },
      '& .MuiTypography-h5': {
        fontSize: '1rem',
      },
    },
    [theme.breakpoints.down(767)]: {
      gridGap: '8px',
      '& .MuiTypography-subtitle1 ': {
        fontSize: '.9rem',
      },
      '& .MuiTypography-h5': {
        fontSize: '1rem',
      },
    },
    [theme.breakpoints.down(380)]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
      '& .MuiTypography-subtitle1 ': {
        fontSize: '.8rem',
      },
      '& .MuiTypography-h5': {
        fontSize: '.9rem',
      },
    },
  },
}));

function PerformanceSummary({data, loading}) {
  function calculatePercentage(data) {
    let buy_box_percentage = null;
    let session_percentage = null;
    let conversion_percentage = null;
    let revenue_percentage = null;
    let units_percentage = null;

    if (data?.buy_box && data?.old_buy_box) {
      buy_box_percentage = (((data.buy_box - data.old_buy_box) * 100) / data.old_buy_box).toFixed(2);
    }

    if (data?.session && data?.old_session) {
      session_percentage = (((data.session - data.old_session) * 100) / data.old_session).toFixed(2);
    }

    if (data?.conversion && data?.old_conversion) {
      conversion_percentage = (((data.conversion - data.old_conversion) * 100) / data.old_conversion).toFixed(2);
    }

    if (data?.revenue && data?.old_revenue) {
      revenue_percentage = (((data.revenue - data.old_revenue) * 100) / data.old_revenue).toFixed(2);
    }

    if (data?.units && data?.old_units) {
      units_percentage = (((data.units - data.old_units) * 100) / data.old_units).toFixed(2);
    }

    setPercentageData({
      ...percentageData,
      buy_box_percentage,
      session_percentage,
      revenue_percentage,
      units_percentage,
      conversion_percentage,
    });
  }

  const [percentageData, setPercentageData] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    calculatePercentage(data);
  }, [data]);

  return (
    <Grid container className={classes.grid}>
      <Grid item style={{width: '100%'}}>
        <Paper className={classes.box} variant={'elevation'}>
          <Tooltip
            title="The selected SKU's average Buy Box percentage for the selected period."
            style={{cursor: 'help'}}
          >
            <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
              Average Buy Box %
            </Typography>
          </Tooltip>
          <Typography component="h2" variant="h5" gutterBottom>
            {!loading ? (
              <>
                {data?.buy_box != null ? data.buy_box : 'N/A'}%
                {percentageData?.buy_box_percentage ? (
                  <Chip
                    className={percentageData.buy_box_percentage >= 0 ? classes.green : classes.red}
                    size="medium"
                    label={`${percentageData.buy_box_percentage < 0 ? '' : '+'}${percentageData.buy_box_percentage}%`}
                    color={'primary'}
                  />
                ) : (
                  ''
                )}
              </>
            ) : (
              <SkeletonLoader numberOfLines={3} xs={12} height={10.8} />
            )}
          </Typography>
          <Typography component="h2" variant="subtitle1" color="textSecondary" gutterBottom>
            {data && 'old_buy_box' in data ? (data?.old_buy_box != null ? data.old_buy_box + '%' : 'N/A') : ''}
          </Typography>
        </Paper>
      </Grid>
      <Grid item lg style={{width: '100%'}}>
        <Paper className={classes.box} variant={'elevation'}>
          <Tooltip
            title="The sum of all selected SKU's sessions. Sessions in this context are most analogous to unique visitors."
            style={{cursor: 'help'}}
          >
            <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
              Total Sessions
            </Typography>
          </Tooltip>
          <Typography component="h2" variant="h5" gutterBottom>
            {!loading ? (
              <>
                {data?.session != null ? Helper.formatAmount(data.session) : 'N/A'}
                {percentageData?.session_percentage ? (
                  <Chip
                    className={percentageData.session_percentage >= 0 ? classes.green : classes.red}
                    size="medium"
                    label={`${percentageData.session_percentage < 0 ? '' : '+'}${percentageData.session_percentage}%`}
                    color={'primary'}
                  />
                ) : (
                  ''
                )}
              </>
            ) : (
              <SkeletonLoader numberOfLines={3} xs={12} height={10.8} />
            )}
          </Typography>
          <Typography component="h2" variant="subtitle1" color="textSecondary" gutterBottom>
            {data && 'old_session' in data ? (data?.old_session != null ? data.old_session : 'N/A') : ''}
          </Typography>
        </Paper>
      </Grid>
      <Grid item lg style={{width: '100%'}}>
        <Paper className={classes.box} variant={'elevation'}>
          <Tooltip
            title="The conversion rate of selected SKU's sessions. Sessions in this context are most analogous to unique visitors."
            style={{cursor: 'help'}}
          >
            <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
              Average Conversion
            </Typography>
          </Tooltip>
          <Typography component="h2" variant="h5" gutterBottom>
            {!loading ? (
              <>
                {data?.conversion != null ? `${data.conversion}%` : 'N/A'}
                {percentageData?.conversion_percentage ? (
                  <Chip
                    className={percentageData.conversion_percentage >= 0 ? classes.green : classes.red}
                    size="medium"
                    label={`${percentageData.conversion_percentage < 0 ? '' : '+'}${
                      percentageData.conversion_percentage
                    }%`}
                    color={'primary'}
                  />
                ) : (
                  ''
                )}
              </>
            ) : (
              <SkeletonLoader numberOfLines={3} xs={12} height={10.8} />
            )}
          </Typography>
          <Typography component="h2" variant="subtitle1" color="textSecondary" gutterBottom>
            {data && 'old_conversion' in data ? (data?.old_conversion != null ? data.old_conversion : 'N/A') : ''}
          </Typography>
        </Paper>
      </Grid>
      <Grid item lg style={{width: '100%'}}>
        <Paper className={classes.box} variant={'elevation'}>
          <Tooltip
            title="The sum of all revenue recorded for the selected SKUs in the selected time period."
            style={{cursor: 'help'}}
          >
            <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
              Total Revenue
            </Typography>
          </Tooltip>
          <Typography component="h2" variant="h5" gutterBottom>
            {!loading ? (
              <>
                {data?.revenue != null ? Helper.formatAmount(Math.floor(data.revenue)) : 'N/A'}
                {percentageData?.revenue_percentage ? (
                  <Chip
                    className={percentageData.revenue_percentage >= 0 ? classes.green : classes.red}
                    size="medium"
                    label={`${percentageData.revenue_percentage < 0 ? '' : '+'}${percentageData.revenue_percentage}%`}
                    color={'primary'}
                  />
                ) : (
                  ''
                )}
              </>
            ) : (
              <SkeletonLoader numberOfLines={3} xs={12} height={10.8} />
            )}
          </Typography>
          <Typography component="h2" variant="subtitle1" color="textSecondary" gutterBottom>
            {data && 'old_revenue' in data ? (data?.old_revenue != null ? Math.floor(data.old_revenue) : 'N/A') : ''}
          </Typography>
        </Paper>
      </Grid>
      <Grid item lg style={{width: '100%'}}>
        <Paper className={classes.box} variant={'elevation'}>
          <Tooltip
            title="The sum of units sold for the selected SKUs in the selected time period."
            style={{cursor: 'help'}}
          >
            <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
              Total Units
            </Typography>
          </Tooltip>
          <Typography component="h2" variant="h5" gutterBottom>
            {!loading ? (
              <>
                {data?.units != null ? Helper.formatAmount(data.units) : 'N/A'}
                {percentageData?.units_percentage ? (
                  <Chip
                    className={percentageData.units_percentage >= 0 ? classes.green : classes.red}
                    size="medium"
                    label={`${percentageData.units_percentage < 0 ? '' : '+'}${percentageData.units_percentage}%`}
                    color={'primary'}
                  />
                ) : (
                  ''
                )}
              </>
            ) : (
              <SkeletonLoader numberOfLines={3} xs={12} height={10.8} />
            )}
          </Typography>
          <Typography component="h2" variant="subtitle1" color="textSecondary" gutterBottom>
            {data && 'old_units' in data ? (data?.old_units != null ? data.old_units : 'N/A') : ''}
          </Typography>
        </Paper>
      </Grid>
      <CustomAlert id="asin-summary" />
    </Grid>
  );
}

export {PerformanceSummary};
