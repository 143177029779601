import {useState, useEffect, memo} from 'react';
import {Grid, List, ListItem, ListItemIcon, Typography, Paper, ListItemText} from '@mui/material';
import {Square, ArrowRightAlt} from '@mui/icons-material';
import {PieChart, Pie, Cell, ResponsiveContainer, Tooltip} from 'recharts';
// utils
import getLabel from '../../../utils/getLabel';
// styles
import {useStyles} from '../../Performance/Styles';
// components
import {ChartContainer} from './components';

export const COLORS = [
  '#0088FE',
  '#00C49F',
  '#d63f7e',
  '#FFBB28',
  '#FF8042',
  '#d63f3f',
  '#d354c2',
  '#106656',
  '#145894',
  '#f390b9',
  '#c7952b',
  '#ce4f0f',
  '#f06666',
  '#911080',
];

export function getAmountDiff({
  current,
  old,
  classes,
  format,
  invertedColor,
}: {
  current: number;
  old: number;
  classes: any;
  format?: string;
  invertedColor?: boolean;
}) {
  if (current === 0 && old === 0) {
    return (
      <span className={invertedColor ? classes.red : classes.green} style={{display: 'block', lineHeight: 1}}>
        0{format ? format : '%'}
      </span>
    );
  } else if (current > 0 && old === 0) {
    return (
      <span className={invertedColor ? classes.green : classes.red} style={{display: 'block', lineHeight: 1}}>
        +100{format ? format : '%'}
      </span>
    );
  } else if (current === old) {
    return (
      <span className={invertedColor ? classes.green : classes.red} style={{display: 'block', lineHeight: 1}}>
        0{format ? format : '%'}
      </span>
    );
  } else {
    const s = current - old;
    const c = s / old;
    const diff = c * 100;
    let diffColor;
    if (diff >= 0) {
      diffColor = invertedColor ? classes.green : classes.red;
    } else {
      diffColor = invertedColor ? classes.red : classes.green;
    }
    return (
      <span className={diffColor} style={{padding: '0 5px'}}>
        {diff >= 0 && '+'}
        {`${diff.toFixed(1)}%`}
      </span>
    );
  }
}

interface PieChartProps {
  className?: string;
  listClassName?: string;
  data?: any;
  loading?: boolean;
  filters?: any;
  chartTitle?: string | JSX.Element;
  compare?: boolean;
  getItem?: any;
  subChart?: boolean;
  selectedItem?: any;
  actionButton?: any;
  doubleChart?: boolean;
  dataTitle?: string | JSX.Element;
  secondaryDataTitle?: string | JSX.Element;
  customList?: any;
  clickable?: boolean;
  showTotal?: boolean;
  pieType?: string;
  chartData?: any;
  showCount?: boolean;
  chartDescription?: string | JSX.Element;
  format?: any;
  sortData?: any;
  customValue?: boolean;
  labelIcon?: boolean;
  minHeight?: number;
}

export default memo(function PieCharts({
  className,
  listClassName,
  loading,
  filters,
  chartTitle,
  compare,
  data,
  getItem,
  subChart,
  selectedItem,
  actionButton,
  doubleChart,
  dataTitle,
  secondaryDataTitle,
  customList,
  clickable = true,
  showTotal = true,
  pieType = 'normal',
  chartData,
  showCount = true,
  chartDescription,
  format = '%',
  sortData = true,
  customValue = true,
  labelIcon = true,
  minHeight,
}: PieChartProps) {
  const classes = useStyles();

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index, value}: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return customValue ? (
      <text
        style={{
          fontSize: '12px',
        }}
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {value}%
      </text>
    ) : (
      <text
        style={{
          fontSize: '12px',
        }}
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {(parseFloat(percent) * 100).toFixed(2)}%
      </text>
    );
  };

  const getChartData = data
    ?.filter((entry) => {
      const porcent = parseFloat(entry.value_porcent);
      return porcent >= 1;
    })
    .slice(0, 20);

  const pieData = chartData ? chartData : getChartData;
  const [total, setTotal] = useState(pieData?.reduce((acc, curr) => acc + curr.value, 0));

  useEffect(() => {
    const porcent = pieData?.reduce((acc, curr) => acc + curr.value, 0);
    setTotal(porcent);
  }, [pieData]);

  const chartList = (currentData) => {
    return (
      <List className={listClassName}>
        <>
          {currentData.map((entry, index) => {
            return (
              <ListItem
                key={index}
                style={{
                  padding: '4px 0',
                }}
                className={`${selectedItem === entry.metric ? 'active' : ''}${clickable ? 'clickable' : ''}`}
                onClick={() => {
                  clickable && getItem(entry.metric);
                }}
              >
                {entry.color && labelIcon && (
                  <ListItemIcon>
                    <Square style={{borderRadius: '50px', color: entry.color}} />
                  </ListItemIcon>
                )}

                <ListItemText
                  primary={
                    <>
                      <Typography component="span" variant="overline" style={{textTransform: 'capitalize'}}>
                        {getLabel(entry.metric)}
                      </Typography>
                      <Typography component="span" variant="overline">
                        {showCount
                          ? `(${parseFloat(entry.value).toFixed(0)}) - ${
                              !isNaN(entry.value_porcent)
                                ? entry.value_porcent
                                : parseFloat(entry.value_porcent).toFixed(2)
                            }${entry.format !== null && entry.format !== undefined ? entry.format : format}`
                          : `${
                              !isNaN(entry.value_porcent)
                                ? entry.value_porcent
                                : parseFloat(entry.value_porcent).toFixed(2)
                            }${entry.format !== null && entry.format !== undefined ? entry.format : format}`}
                      </Typography>
                    </>
                  }
                  secondary={
                    compare && (
                      <>
                        <Typography component="span" variant="overline">
                          {!Number.isInteger(entry.old_value)
                            ? parseFloat(entry.old_value)?.toFixed(entry.fixed ? entry.fixed : subChart ? 0 : 2)
                            : entry.old_value}{' '}
                          {entry.format}
                          <ArrowRightAlt />
                          {!Number.isInteger(entry.value)
                            ? parseFloat(entry.value)?.toFixed(entry.fixed ? entry.fixed : subChart ? 0 : 2)
                            : entry.value}
                          {entry.format} ={' '}
                          {getAmountDiff({
                            current: parseFloat(entry.value),
                            old: parseFloat(entry?.old_value),
                            classes: classes,
                            format: entry.format,
                            invertedColor: entry.invertedColor,
                          })}
                        </Typography>
                      </>
                    )
                  }
                />
              </ListItem>
            );
          })}
        </>
      </List>
    );
  };

  return (
    <ChartContainer
      minHeight={minHeight}
      chartTitle={chartTitle}
      loading={loading}
      filters={filters}
      actionButton={actionButton}
      data={data}
    >
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{minHeight: '400px'}}
        position="relative"
      >
        <>
          {data.length > 0 ? (
            <Grid container alignItems="center" style={{padding: '20px 0 50px 0'}}>
              <Grid
                item
                lg={6}
                md={5}
                sm={12}
                xs={12}
                className={className}
                alignItems="center"
                display="flex"
                justifyContent={'center'}
                flexDirection="column"
              >
                {total > 0 && pieData.length > 0 ? (
                  <>
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          data={pieData}
                          isAnimationActive={false}
                          cy={'50%'}
                          cx={'50%'}
                          labelLine={false}
                          label={(v) => renderCustomizedLabel({...v, value: v.value_porcent})}
                          fill="#8884d8"
                          dataKey="value"
                          outerRadius={doubleChart && 120}
                        >
                          {pieData.map((entry, index) => {
                            return <Cell key={`cell-${index}`} fill={entry.color} />;
                          })}
                        </Pie>

                        <Tooltip
                          content={(props: any) => {
                            const {active, payload} = props;
                            if (active) {
                              return (
                                <Paper style={{padding: '10px'}}>
                                  <Typography
                                    component="span"
                                    variant="overline"
                                    gutterBottom
                                    style={{textTransform: 'capitalize'}}
                                  >
                                    {customValue
                                      ? `${getLabel(payload?.[0].payload.metric)}: (${payload?.[0].payload.value}) ${
                                          payload?.[0].payload.value_porcent
                                        }${
                                          payload?.[0].payload.format !== null &&
                                          payload?.[0].payload.format !== undefined
                                            ? payload?.[0].payload.format
                                            : format
                                        }`
                                      : `${getLabel(payload?.[0].payload.metric)}: (${payload?.[0].payload.value}) ${(
                                          (parseFloat(payload?.[0].payload.value_porcent) / total) *
                                          100
                                        ).toFixed(2)}${
                                          payload?.[0].payload.format !== null &&
                                          payload?.[0].payload.format !== undefined
                                            ? payload?.[0].payload.format
                                            : format
                                        }
                                        `}
                                  </Typography>
                                </Paper>
                              );
                            }
                          }}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                    <Typography variant="body1" style={{textAlign: 'center'}} color="secondary">
                      {chartDescription}
                    </Typography>
                  </>
                ) : (
                  <Typography component="h6" variant="h6" color="primary" gutterBottom>
                    No data
                  </Typography>
                )}
              </Grid>
              <Grid item lg={6} md={7} sm={12} xs={12} className={classes.dataContainer}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent={dataTitle ? 'space-between' : 'flex-end'}
                  display="flex"
                  style={{maxWidth: '500px'}}
                >
                  {dataTitle && (
                    <Typography component="span" variant="overline" color="secondary" fontSize={14}>
                      {dataTitle}
                    </Typography>
                  )}
                  {showTotal && (
                    <Typography component="span" variant="overline" color="secondary" fontSize={14}>
                      Total: {total.toFixed(0)}
                    </Typography>
                  )}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.listContainer}>
                  {chartList(sortData ? data.sort((a, b) => b.value - a.value) : data)}
                </Grid>
                {customList && (
                  <>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      justifyContent={dataTitle ? 'space-between' : 'flex-end'}
                      display="flex"
                      style={{maxWidth: '500px'}}
                    >
                      {secondaryDataTitle && (
                        <Typography component="span" variant="overline" color="secondary">
                          {secondaryDataTitle}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.listContainer}>
                      {customList}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          ) : (
            <Typography component="h2" variant="h5" color="primary" gutterBottom>
              No data available
            </Typography>
          )}
        </>
      </Grid>
    </ChartContainer>
  );
});
