import {useEffect, useState, useMemo} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Typography} from '@mui/material';
import {DataGrid, GridColDef, GridCellParams, GridSortDirection} from '@mui/x-data-grid';
import {useLocation} from 'react-router-dom';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import Button from '../../../../components/Button/Button';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// utils
import {updateQueryParams} from '../../../../utils/urlParams';
import {Api, errorAlert} from '../../../../utils/api';

type DefaultQuery = {
  filterField?: string;
  filterOperator?: string;
  filterValue?: string;
  sortBy?: string;
  sortDirection?: string;
};

function StripeBrands() {
  document.title = 'Stonehenge.ai/StripeBrands';
  const location = useLocation();
  const [rows, setRows] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [modalData, setModalData] = useState(null);

  const [filterField, setFilterField] = useState('');
  const [filterOperator, setFilterOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');

  let defaultQuery: DefaultQuery;
  function getDefaultQuery() {
    const urlParams = new URLSearchParams(location.search);
    const filterFieldQuery = urlParams.get('filterField');
    const filterOperatorQuery = urlParams.get('filterOperator');
    const filterValueQuery = urlParams.get('filterValue');
    const sortByQuery = urlParams.get('sortBy');
    const sortDirectionQuery = urlParams.get('sortDirection');

    const query: DefaultQuery = {
      filterField: filterFieldQuery,
      filterOperator: filterOperatorQuery,
      filterValue: filterValueQuery,
      sortBy: sortByQuery || 'created_at',
      sortDirection: sortDirectionQuery || 'desc',
    };

    return query;
  }

  useEffect(() => {
    defaultQuery = getDefaultQuery();
    setFilterField(defaultQuery.filterField);
    setFilterOperator(defaultQuery.filterOperator);
    setFilterValue(defaultQuery.filterValue);
    setSortBy(defaultQuery.sortBy);
    setSortDirection(defaultQuery.sortDirection);
  }, []);

  const columns: GridColDef[] = [
    {field: 'brand_code', headerName: 'Code', width: 300},
    {field: 'name', headerName: 'Name', width: 200},
    {field: 'seller_id', headerName: 'Seller ID', width: 100},
    {field: 'marketplaces', headerName: 'Marketplaces', width: 250},
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (x) => x.value?.status_name,
      valueGetter: (x) => x.value?.status_name,
    },
    {
      field: 'type',
      headerName: 'Type',
      renderCell: (x) => x.value?.type_name,
      valueGetter: (x) => x.value?.type_name,
    },
    {field: 'created_at', headerName: 'Created at', width: 250},
    {field: 'advertising_refresh_token', headerName: 'Advertising Refresh Token', width: 250},
    {
      field: 'advertising_profiles',
      headerName: 'Advertising profiles',
      width: 250,
      renderCell: (x) => {
        return x.value ? (
          <Button
            onClick={(z) => {
              setModalData(x.value);
            }}
          >
            View
          </Button>
        ) : (
          ''
        );
      },
      valueGetter: (x) => {
        try {
          return x.value ? JSON.stringify(x.value) : '';
        } catch (err) {
          return '';
        }
      },
    },
    {
      field: 'sp_api_authorized_marketplaces',
      headerName: 'SP Authorized marketplaces',
      width: 250,
      renderCell: (x) => {
        try {
          return (x.row?.sp_api_authorized_marketplaces || []).map((y) => y.marketplace_id).join(', ');
        } catch (err) {
          return '';
        }
      },
      valueGetter: (x) => {
        try {
          return (x.row?.sp_api_authorized_marketplaces || []).map((y) => y.marketplace_id).join(', ');
        } catch (err) {
          return '';
        }
      },
    },
    {
      field: 'amazon_notifications_subscriptions',
      headerName: 'Amazon Notifications Subscriptions',
      width: 250,
      renderCell: (x) => {
        return x.value && x.value.length ? (
          <Button
            onClick={(z) => {
              setModalData(x.value);
            }}
          >
            View
          </Button>
        ) : (
          ''
        );
      },
      valueGetter: (x) => {
        try {
          return x.value ? JSON.stringify(x.value) : '';
        } catch (err) {
          return '';
        }
      },
    },
    {field: 'parent_brand_code', headerName: 'Parent', width: 100},
    {field: 'stripe_subscription_status', headerName: 'Stripe subscription status', width: 100},
    {
      field: 'stripe_subscription_id',
      headerName: 'Stripe subscription id',
      width: 300,
      renderCell: (params: GridCellParams) => (
        <>
          {params.value && (
            <Button>
              <a href={`https://dashboard.stripe.com/customers/${params.row.stripe_customer_ids[0]}`} target="_blank">
                {params.value}
              </a>
            </Button>
          )}
        </>
      ),
      valueGetter: (x) => x.value,
    },
    {
      field: 'stripe_subscription_items',
      headerName: 'Stripe subscription items',
      width: 250,
      renderCell: (x) => {
        return x.value && x.value.length ? (
          <Button
            onClick={(z) => {
              setModalData(x.value);
            }}
          >
            View
          </Button>
        ) : (
          ''
        );
      },
      valueGetter: (x) => {
        try {
          return x.value ? JSON.stringify(x.value) : '';
        } catch (err) {
          return '';
        }
      },
    },
    {
      field: 'users',
      headerName: 'Users',
      width: 250,
      renderCell: (x) => {
        try {
          return (x.row?.users || []).join(', ');
        } catch (err) {
          return '';
        }
      },
      valueGetter: (x) => {
        try {
          return (x.row?.users || []).join(', ');
        } catch (err) {
          return '';
        }
      },
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const params = useMemo(() => {
    return {
      filterField,
      filterOperator,
      filterValue,
      sortBy,
      sortDirection,
    };
  }, [filterField, filterOperator, filterValue, sortBy, sortDirection]);

  useEffect(() => {
    if (!loadingData) {
      updateQueryParams(location, params);
    }
  }, [filterField, filterOperator, filterValue, sortBy, sortDirection, location, params]);

  function handleChangeFilter(filter) {
    const filterObj = filter.items[0];
    setFilterField(filterObj?.columnField || null);
    setFilterValue(filterObj?.value || null);
    setFilterOperator(filterObj?.operatorValue || null);
  }

  function handleChangeSort(dgParams) {
    const sortObj = dgParams;
    setSortBy(sortObj[0]?.field || null);
    setSortDirection(sortObj[0]?.sort || null);
  }

  async function loadData() {
    setLoadingData(true);
    try {
      const {data} = await Api.get('brands/stripe/get');
      setRows(
        data.map((x, i) => {
          return {id: i, ...x};
        })
      );
    } catch (e) {
      errorAlert('Unable to get brands', e);
    } finally {
      setLoadingData(false);
    }
  }

  return (
    <TableContainer>
      <Grid container spacing={2} style={{paddingBottom: '.3em'}}>
        <Grid item lg={12} style={{height: '850px'}}>
          {loadingData && <LinearProgress style={{position: 'absolute', width: '100%'}} />}
          <DataGrid
            pageSize={40}
            pagination
            columnBuffer={10}
            loading={loadingData}
            rows={rows}
            columns={columns}
            onFilterModelChange={(params) => handleChangeFilter(params)}
            onSortModelChange={(params) => handleChangeSort(params)}
            filterModel={{
              items: [
                {
                  columnField: filterField,
                  operatorValue: filterOperator,
                  value: filterValue,
                },
              ],
            }}
            sortModel={
              sortBy && sortDirection
                ? [
                    {
                      field: sortBy,
                      sort: sortDirection as GridSortDirection,
                    },
                  ]
                : []
            }
          />
        </Grid>
      </Grid>
      <CustomAlert id="default-alert" />
      <Dialog
        open={modalData != null}
        onClose={() => setModalData(null)}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={'lg'}
      >
        <DialogTitle id="scroll-dialog-title">Data</DialogTitle>
        <DialogContent>
          <Typography>{modalData ? JSON.stringify(modalData) : ''}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalData(null)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}

export {StripeBrands};
