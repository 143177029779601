import {useEffect} from 'react';
import * as Yup from 'yup';
import {makeStyles} from '@mui/styles';
import {useHistory, useLocation} from 'react-router-dom';
// services
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
// components
import LoginView from '../../components/LoginView/LoginView';
import LogoComponent from './components/LogoComponent';
import {CustomAlert} from './components/CustomAlert';

const useStyles = makeStyles((theme) => ({
  inputField: {
    margin: '0.5em',
    width: '95%',
  },
}));

function Login() {
  document.title = 'Stonehenge.ai/Login';
  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    if (accountService.userValue) {
      history.push('/');
    }
  }, []);

  const classes = useStyles();

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  function onSubmit({email, password}, {setSubmitting}) {
    setSubmitting(true);
    alertService.clear();
    accountService
      .login(email, password)
      .then((res) => {
        const {from} = location.state || {from: {pathname: '/'}};
        if (res.brand_type === 'ffp') {
          history.push('/amazon-listings-items');
        } else {
          history.push(from);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          alertService.error('Email or password is incorrect');
        } else {
          alertService.error(error.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  const loginFields = [
    {
      label: 'Email',
      name: 'email',
      icon: 'email',
      type: 'text',
    },
    {
      label: 'Password',
      name: 'password',
      icon: 'password',
      type: 'password',
    },
  ];

  return (
    <>
      <LoginView
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        submitText="Login"
        forgotPassword="/forgot-password"
        fields={loginFields}
        logo={<LogoComponent />}
      />
      <CustomAlert className={classes.inputField} />
    </>
  );
}

export {Login};
