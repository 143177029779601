import {useState} from 'react';
import {ListItem, ListItemIcon, ListItemText, Collapse, List} from '@mui/material';
import {ExpandLess, ExpandMore} from '@mui/icons-material/';
import {Link} from 'react-router-dom';
// services
import {accountService} from '../../services/account.service';

interface DrawerListMenuItemProps {
  icon: JSX.Element;
  label: string;
  linkTo: string;
  roles: string[];
  selected?: boolean;
  onClick?: () => void;
  subItems?: DrawerListMenuItemProps[];
  url?: string;
}

export function DrawerListMenuItem(props: DrawerListMenuItemProps): JSX.Element {
  const {label, icon, linkTo, roles} = props;
  const [collapsed, setCollapsed] = useState(true);

  const getSelectedMenu = (path: string) => props.url === path;
  const user = accountService.userValue;

  if (!user) {
    return null;
  }

  if (roles && roles.indexOf(user.role) === -1) {
    return null;
  }

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCollapsed((p) => !p);
  };

  return (
    <>
      <Link to={linkTo}>
        <ListItem
          button
          className={`${props.selected ? 'active-menu' : ''}`}
          onClick={() => {
            props.onClick();
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label} />
          {props.subItems && collapsed ? (
            <ExpandLess onClick={handleClick} />
          ) : props.subItems && !collapsed ? (
            <ExpandMore onClick={handleClick} />
          ) : null}
        </ListItem>
      </Link>
      {props.subItems?.length > 0 && (
        <Collapse
          in={collapsed}
          timeout="auto"
          unmountOnExit
          style={{width: '100%', borderBottom: '1px solid #6b6b6b'}}
        >
          <List component="div" disablePadding className="sub-menu">
            {props.subItems.map((item, i) => (
              <Link key={i} to={item.linkTo}>
                <ListItem
                  sx={{pl: 4}}
                  style={{padding: '5px 10px 5px 40px'}}
                  className={`${getSelectedMenu(item.linkTo) ? 'active-menu' : ''}`}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              </Link>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
