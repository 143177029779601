import {useEffect, useState, useContext} from 'react';
import moment from 'moment';
import {CSVLink} from 'react-csv';
import {
  Box,
  Grid,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  IconButton,
  Autocomplete,
} from '@mui/material';
import {DataGrid, GridCellParams, GridColDef} from '@mui/x-data-grid';
import {Visibility, VisibilityOff} from '@mui/icons-material';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {Role} from '../../../../utils/role';
import {rowsPerPageOptions} from '../../../../utils/constants';
// services
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';
// components
import Button from '../../../../components/Button/Button';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// context
import {AppContext} from '../../../../context/AppContext/AppContext';

interface AlertTableProps {
  alertType: string;
  classes: any;
}

export default function LateFBMShipments({alertType, classes}: AlertTableProps) {
  const [loadingData, setLoadingData] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [count, setCount] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [selectedIdxes, setSelectedIdxes] = useState([]);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);
  const [accountManager, setAccountManager] = useState(null);

  const {accountManagers, currentAM} = useContext(AppContext);

  useEffect(() => {
    if (currentAM) {
      setAccountManager(currentAM);
    }
  }, [currentAM]);

  useEffect(() => {
    loadData(page, pageSize, showAll, sort, filter, accountManager);
  }, [accountManager, page, pageSize, showAll, sort, filter]);

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 110,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        if (params.row.finished) {
          return (
            <Tooltip title={'Show Alert'}>
              <IconButton onClick={() => hideAlert(params.row)}>
                <Visibility style={{color: 'green'}} />
              </IconButton>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={'Hide Alert'}>
              <IconButton onClick={() => hideAlert(params.row)}>
                <VisibilityOff />
              </IconButton>
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'orderId',
      headerName: 'Order ID',
      flex: 0.15,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.orderId}>
          <span className="table-cell-trucate">{params.row.orderId}</span>
        </Tooltip>
      ),
    },
    {field: 'sku', headerName: 'SKU', flex: 0.4},
    {
      field: 'promiseDate',
      headerName: 'Promised Date',
      flex: 0.15,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.promiseDate}>
          <span className="table-cell-trucate">{params.row.promiseDate}</span>
        </Tooltip>
      ),
    },
    {field: 'daysPastPromise', headerName: 'Days past promise', flex: 0.15},
    {field: 'status', headerName: 'Status', flex: 0.15},
  ];

  const csvHeaders = columns
    .filter((column) => column.field !== 'actions')
    .map((column) => {
      return {key: column.field, label: column.headerName};
    });

  async function loadData(
    page,
    pageSize,
    blShowAll = showAll,
    sortModel,
    filterModel,
    amId = accountManager?.id || null
  ) {
    setLoadingData(true);
    try {
      const {data} = await Api.get(`alerts/late-fbm-shipments`, {
        params: {
          page: page,
          pageSize: pageSize,
          showAll: blShowAll,
          sortBy: sortModel ? sortModel.field : null,
          sortOrder: sortModel ? sortModel.sort : null,
          filterField: filterModel ? filterModel.columnField : null,
          filterValue: filterModel ? filterModel.value : null,
          filterOperator: filterModel ? filterModel.operatorValue : null,
          accountManagerId: amId,
        },
      });
      setCount(data.count);
      const rowsGrid = data.rows.map((x, i) => {
        x.promiseDate = moment(new Date(x.promiseDate)).format('YYYY-MM-DD hh:mm A');
        return {id: i, ...x};
      });
      setRows(rowsGrid);
      setSelectedIdxes([]);
    } catch (e) {
      errorAlert('Unable to get alerts', e);
    } finally {
      setLoadingData(false);
    }
  }

  function handleChangePage(params: any) {
    setPage(params);
    loadData(params, pageSize, showAll, sort, filter, accountManager?.id || null);
  }

  function handleChangeShowAll() {
    const tmpShowAll = !showAll;
    setShowAll(tmpShowAll);
    loadData(page, pageSize, tmpShowAll, sort, filter, accountManager?.id || null);
  }

  function handleChangeAM(newValue) {
    setAccountManager(newValue);
    const newAmId = newValue?.id || null;
    loadData(page, pageSize, showAll, sort, filter, newAmId);
  }

  function handleSortModelChange(params) {
    loadData(page, pageSize, showAll, params.sortModel[0], filter);
  }

  function handleFilterModelChange(params) {
    const filterModel = params.items[0];
    delete filterModel.id;
    if (JSON.stringify(filter) !== JSON.stringify(filterModel)) {
      loadData(page, pageSize, showAll, sort, params.items[0]);
    }
    setFilter(filterModel);
  }

  function handleChangePageSize(params: any) {
    setPage(0);
    setPageSize(params);
    loadData(0, params, showAll, sort, filter, accountManager?.id || null);
  }

  async function handleHideAlerts() {
    const selectedIds = rows.filter((row) => selectedIdxes.indexOf(row.id.toString()) > -1).map((row) => row._id);
    try {
      await Api.post('alerts/toggle-alert-finish-status/late-fbm-shipments', {alertIds: selectedIds});
      loadData(page, pageSize, showAll, sort, filter, accountManager?.id || null);
      alertService.success(`Alert status changed successfully!`);
    } catch (e) {
      errorAlert('Something went wrong hiding the alerts. Please try again later.', e);
    }
  }

  async function hideAlert(record) {
    try {
      await Api.post('alerts/toggle-alert-finish-status/late-fbm-shipments', {alertIds: [record._id]});
      loadData(page, pageSize, showAll, sort, filter, accountManager?.id || null);
      alertService.success(`Alert status changed successfully!`);
    } catch (e) {
      errorAlert('Something went wrong hiding the alert. Please try again later', e);
    }
  }

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} style={{margin: 'auto'}}>
        <Box display="flex" justifyContent="flex-end" alignItems="center" className={classes.toolbar}>
          {[Role.Admin].includes(accountService.userValue.role) && (
            <Autocomplete
              id="account-manager"
              size="small"
              options={accountManagers}
              getOptionLabel={(option) => option.name}
              value={accountManager}
              onChange={(event, newValue) => {
                handleChangeAM(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Account Manager" variant="outlined" />}
            />
          )}
          {alertType !== 'All' && (
            <>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={showAll} onChange={handleChangeShowAll} color="primary" />}
                  label="Show Finished Orders"
                />
                <Button size="small" onClick={handleHideAlerts} disabled={showAll || selectedIdxes?.length < 1}>
                  Hide all selected
                </Button>
              </FormGroup>
            </>
          )}
          <CSVLink filename={`${alertType} Alerts.csv`} data={rows} headers={csvHeaders}>
            <Button size="small">Export to CSV</Button>
          </CSVLink>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          className={classes.grid}
          autoHeight={true}
          pageSize={pageSize}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={(params) => handleChangePageSize(params)}
          onPageChange={(params) => handleChangePage(params)}
          rowCount={count}
          loading={loadingData}
          pagination
          paginationMode="server"
          sortingMode="server"
          sortModel={sort ? sort : []}
          filterMode="server"
          onFilterModelChange={handleFilterModelChange}
          onSortModelChange={handleSortModelChange}
          disableSelectionOnClick={true}
          checkboxSelection={alertType !== 'All'}
          selectionModel={selectedIdxes}
          onSelectionModelChange={(x: any) => {
            setSelectedIdxes(x.selectionModel);
          }}
          rowHeight={64}
          rows={rows}
          columns={columns}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomAlert id="default-alert-3" />
      </Grid>
    </Grid>
  );
}
