import {memo} from 'react';
import {Grid, List, ListItem, ListItemIcon, Typography, Paper, ListItemText, useTheme} from '@mui/material';
import {Square, ArrowRightAlt} from '@mui/icons-material';
import {ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList} from 'recharts';
// utils
import getLabel from '../../../utils/getLabel';
// styles
import {useStyles} from '../../Performance/Styles';
// components
import {ChartContainer} from './components';
import {getAmountDiff} from './pieChart';

export const COLORS = [
  '#0088FE',
  '#00C49F',
  '#d63f7e',
  '#FFBB28',
  '#FF8042',
  '#d63f3f',
  '#d354c2',
  '#106656',
  '#145894',
  '#f390b9',
  '#c7952b',
  '#ce4f0f',
  '#f06666',
  '#911080',
];
interface PieChartProps {
  className?: string;
  listClassName?: string;
  data?: any;
  loading?: boolean;
  filters?: any;
  chartTitle?: string | JSX.Element;
  compare?: boolean;
  getItem?: any;
  subChart?: boolean;
  selectedItem?: any;
  actionButton?: any;
  doubleChart?: boolean;
  dataTitle?: string | JSX.Element;
  secondaryDataTitle?: string | JSX.Element;
  customList?: any;
  clickable?: boolean;
  showTotal?: boolean;
  pieType?: string;
  chartData?: any;
  showCount?: boolean;
  chartDescription?: string | JSX.Element;
  customListHeight?: number;
  format?: any;
}

export default memo(function PieCharts({
  className,
  listClassName,
  loading,
  filters,
  chartTitle,
  compare,
  data,
  getItem,
  subChart,
  selectedItem,
  actionButton,
  doubleChart,
  dataTitle,
  secondaryDataTitle,
  customList,
  clickable = true,
  showTotal = true,
  pieType = 'normal',
  chartData,
  showCount = true,
  chartDescription,
  customListHeight,
  format = '%',
}: PieChartProps) {
  const classes = useStyles();
  const theme = useTheme();
  const pieData = chartData ? chartData : data;

  const chartList = (currentData) => {
    return (
      <List className={listClassName}>
        <>
          {currentData.map((entry, index) => {
            return (
              <ListItem
                key={index}
                style={{
                  padding: '4px 0',
                }}
                className={`${selectedItem === entry.metric ? 'active' : ''}${clickable ? 'clickable' : ''}`}
                onClick={() => {
                  clickable && getItem(entry.metric);
                }}
              >
                {entry.color && (
                  <ListItemIcon>
                    <Square style={{borderRadius: '50px', color: entry.color}} />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <>
                      <Typography component="span" variant="overline" style={{textTransform: 'capitalize'}}>
                        {getLabel(entry.metric)}
                      </Typography>
                      <Typography component="span" variant="overline">
                        {entry.value}
                        {format}
                      </Typography>
                    </>
                  }
                  secondary={
                    compare &&
                    entry.old_value && (
                      <>
                        <Typography component="span" variant="overline">
                          {entry.old_value?.toFixed(subChart ? 0 : 1)}
                          {format} <ArrowRightAlt />
                          {entry.value?.toFixed(subChart ? 0 : 1)}
                          {format} ={''}
                          {getAmountDiff({
                            current: entry.value,
                            old: entry?.old_value,
                            classes: classes,
                            invertedColor: entry.invertedColor,
                          })}
                        </Typography>
                      </>
                    )
                  }
                />
              </ListItem>
            );
          })}
        </>
      </List>
    );
  };

  return (
    <ChartContainer chartTitle={chartTitle} loading={loading} filters={filters} actionButton={actionButton} data={data}>
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{minHeight: '400px'}}
        position="relative"
      >
        <>
          {data.length > 0 ? (
            <Grid container alignItems="center" style={{padding: '20px 0 50px 0'}}>
              <Grid
                item
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={className}
                alignItems="center"
                display="flex"
                justifyContent={'center'}
                flexDirection="column"
                style={{padding: '0 15px 0 0'}}
              >
                {pieData.length > 0 ? (
                  <>
                    <ResponsiveContainer
                      height={pieData.length > 12 ? pieData.length * 32 + 10 : 400}
                      className="customContainer"
                    >
                      <ComposedChart layout="vertical" data={pieData} stackOffset="expand">
                        <CartesianGrid stroke={theme.palette.mode === 'dark' ? '#a1a1a1' : '#8888'} />
                        <XAxis unit={format} orientation="top" type="number" />
                        <YAxis
                          width={5}
                          dataKey="metric"
                          type="category"
                          scale="band"
                          interval={0}
                          allowDataOverflow
                          tick={() => {
                            return null;
                          }}
                        />
                        <Tooltip
                          content={(props: any) => {
                            const {active, payload} = props;
                            if (active) {
                              return (
                                <Paper style={{padding: '10px'}}>
                                  <Typography
                                    component="span"
                                    variant="overline"
                                    gutterBottom
                                    style={{textTransform: 'capitalize'}}
                                  >
                                    {compare ? (
                                      <Typography
                                        component="span"
                                        variant="overline"
                                        className={classes.composedTooltip}
                                      >
                                        <Typography component="span" variant="overline">
                                          {payload?.[0].payload.label || getLabel(payload?.[0].payload.metric)}
                                        </Typography>
                                        <Typography component="span" variant="overline">
                                          {payload?.[0].payload.old_value?.toFixed(subChart ? 0 : 1)}
                                          {format} <ArrowRightAlt />
                                          {payload?.[0].payload.value?.toFixed(subChart ? 0 : 1)}
                                          {format} ={' '}
                                          {getAmountDiff({
                                            current: payload?.[0].payload.value,
                                            old: payload?.[0].payload.old_value,
                                            classes: classes,
                                            invertedColor: payload?.[0].payload.invertedColor,
                                          })}
                                        </Typography>
                                      </Typography>
                                    ) : (
                                      `${payload?.[0].payload.label || getLabel(payload?.[0].payload.metric)}: ${
                                        payload?.[0].payload.value
                                      }${format}`
                                    )}
                                  </Typography>
                                </Paper>
                              );
                            }
                          }}
                        />
                        <Bar dataKey="value" barSize={30} fill="#3c8dc7">
                          <LabelList
                            position="insideRight"
                            valueAccessor={(item) => {
                              return {value: item.value, metric: item.metric, label: item.label};
                            }}
                            content={(props: any) => {
                              const {x, y, value} = props;
                              return (
                                <text className="custom-bar" x={x + 10} y={y + 18}>{`${value.label || value.metric} - ${
                                  value.value
                                }${format}`}</text>
                              );
                            }}
                          />
                        </Bar>
                      </ComposedChart>
                    </ResponsiveContainer>
                    <Typography variant="body1" style={{textAlign: 'center', paddingTop: '15px'}} color="secondary">
                      {chartDescription}
                    </Typography>
                  </>
                ) : (
                  <Typography component="h2" variant="h5" color="primary" gutterBottom>
                    No data available
                  </Typography>
                )}
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12} className={classes.dataContainer}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent={dataTitle ? 'space-between' : 'flex-end'}
                  display="flex"
                  style={{maxWidth: '500px'}}
                >
                  {dataTitle && (
                    <Typography component="span" variant="overline" color="secondary" fontSize={14}>
                      {dataTitle}
                    </Typography>
                  )}
                </Grid>

                {data && (
                  <Grid item lg={12} md={12} sm={12} xs={12} className={classes.listContainer}>
                    {chartList(data.sort((a, b) => b.value - a.value))}
                  </Grid>
                )}
                {customList && (
                  <>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      justifyContent={dataTitle ? 'space-between' : 'flex-end'}
                      display="flex"
                      style={{maxWidth: '500px'}}
                    >
                      {secondaryDataTitle && (
                        <Typography component="span" variant="overline" color="secondary">
                          {secondaryDataTitle}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.listContainer}
                      style={{maxHeight: customListHeight ? customListHeight : '400px'}}
                    >
                      {customList}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          ) : (
            <Typography component="h2" variant="h5" color="primary" gutterBottom>
              No data available
            </Typography>
          )}
        </>
      </Grid>
    </ChartContainer>
  );
});
