import {useEffect, useState} from 'react';
import {IconButton, Grid} from '@mui/material';
import {Link, useHistory, useParams} from 'react-router-dom';
import {DataGrid, getGridStringOperators, GridCellParams, GridColDef, GridColTypeDef} from '@mui/x-data-grid';
import {Edit, Visibility} from '@mui/icons-material';
import moment from 'moment';
import {htmlToText} from 'html-to-text';
// utils
import {Helper} from '../../../../utils/helper';
import {Api, errorAlert} from '../../../../utils/api';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {BuildMenu} from '../../components/menu';
import {MenuStore} from '../../../../App';
import {CustomAlert} from '../../../Login/components/CustomAlert';

const stringColumnType: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

const columns: GridColDef[] = [
  {
    field: 'month',
    headerName: 'Month',
    width: 120,
    type: 'filterString',
  },
  {
    field: 'sales_note',
    headerName: 'Sales Note',
    width: 200,
    type: 'filterString',
  },
  {
    field: 'ad_note',
    headerName: 'Advertising Note',
    flex: 1,
    width: 200,
    type: 'filterString',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 130,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Link to={`/brandreports/view/${params.row.brand_code}/${params.row.month}`}>
          <IconButton>
            <Visibility />
          </IconButton>
        </Link>
        <Link to={`/brandreports/update/${params.row.brand_code}/${params.row.month}`}>
          <IconButton>
            <Edit />
          </IconButton>
        </Link>
      </>
    ),
  },
];

function BrandReports(props) {
  const firstMonth = '2021-04-01';
  let history = useHistory();

  const {id} = useParams<Record<string, string | undefined>>();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState({field: 'month', sort: 'DESC'});
  const [filter, setFilter] = useState(null);
  const menuItems = BuildMenu('monthly_reports', id, history);

  var dateStart = moment(new Date(firstMonth));
  var dateEnd = moment(new Date());
  var timeValues = [];

  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    timeValues.push(dateStart.format('YYYY-MM'));
    dateStart.add(1, 'month');
  }

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = menuItems;
    });
    loadData(page, pageSize, sort, {});
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, []);

  function onPageChange(params) {
    loadData(params.page, pageSize, sort, filter);
    setPage(params.page);
  }

  async function loadData(page, pageSize, sortModel, filterModel) {
    const months = Helper.getMonthsBetweenDates(firstMonth);
    try {
      const {data} = await Api.get(`brandreports/${id}`, {
        params: {
          page: page,
          pageSize: pageSize,
          sortField: sortModel ? sortModel.field : null,
          sortOrder: sortModel ? sortModel.sort : null,
          filterField: filterModel ? filterModel.columnField : null,
          filterValue: filterModel ? filterModel.value : null,
        },
      });
      setCount(months.length);
      setRows(
        months.map((month, i) => {
          const item = data.rows.find((item) => item.month === month);
          const ret = item
            ? {id: month, ...item}
            : {
                id: month,
                month: month,
                brand_code: id,
                sales_note: 'N/A',
                ad_note: 'N/A',
              };

          ret.sales_note = htmlToText(ret.sales_note);
          ret.ad_note = htmlToText(ret.ad_note);
          return ret;
        })
      );
    } catch (e) {
      errorAlert('Unable to get brands', e);
    }
  }

  function handleSortModelChange(params) {
    loadData(page, pageSize, params.sortModel[0], filter);
    setSort(params.sortModel[0]);
  }

  function handleFilterModelChange(params) {
    loadData(page, pageSize, sort, params.filterModel.items[0]);
    setFilter(params.filterModel.items[0]);
  }

  return (
    <TableContainer className="sub-page">
      <Grid item xs={12} style={{paddingBottom: 30}}>
        <DataGrid
          autoHeight={true}
          pageSize={pageSize}
          page={page}
          rowCount={count}
          columnTypes={{filterString: stringColumnType}}
          onPageChange={onPageChange}
          pagination
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          onFilterModelChange={handleFilterModelChange}
          onSortModelChange={handleSortModelChange}
          disableSelectionOnClick={true}
          rows={rows}
          columns={columns}
        />
        <CustomAlert id="default-alert" />
      </Grid>
    </TableContainer>
  );
}

export {BrandReports};
