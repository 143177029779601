import {useEffect, useState} from 'react';
import {Typography, TextField, Autocomplete, Theme, IconButton, Grid} from '@mui/material';
import {DataGrid, GridColDef, GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from '@mui/styles';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {CheckBoxOutlineBlank, CheckBox} from '@mui/icons-material';
import {useParams, useLocation} from 'react-router-dom';
// utils
import {rowsPerPageOptions} from '../../../../utils/constants';
import {Api, errorAlert} from '../../../../utils/api';
import getLabel from '../../../../utils/getLabel';
// components
import SearchBar from '../../../../components/SearchBar/SearchBar';
import Button from '../../../../components/Button/Button';
import Spacer from '../../../../components/Spacer/Spacer';
import TableContainer from '../../../../components/TableContainer/TableContainer';
// services
import {alertService} from '../../../../services/alert.service';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'flex',
    flexDirection: 'column-reverse',
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgb(23 69 130 / 8%)',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '&.load-headers': {
      opacity: 0.5,
    },
  },
  placeholder: {
    width: '50%',
    margin: 'auto',
    display: 'block',
  },
  button: {
    marginBottom: '1em',
  },
  toolbarButton: {
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '.5rem 1rem .4rem 1rem',
    [theme.breakpoints.down(820)]: {
      fontSize: '12px',
    },
  },
  autocomplete: {
    '& .MuiOutlinedInput-input': {
      textTransform: 'capitalize',
    },
  },
  toggleButtons: {
    margin: '10px 0',
    display: 'flex',
    [theme.breakpoints.down(400)]: {
      display: 'block',
      '& button': {
        margin: '5px 0',
        width: '100%',
        display: 'block',
      },
    },
  },
}));

function UserPermission() {
  const {id: userId} = useParams<Record<string, string | undefined>>();
  const classes = useStyles();
  const location = useLocation();
  const [loadingData, setLoadingData] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [count, setCount] = useState(0);
  const [selectedPropertyGroup, setSelectedPropertyGroup] = useState(null);
  const [propertyGroups, setPropertyGroups] = useState([]);
  const [propertyNameFilter, setPropertyNameFilter] = useState('');
  const [params, setParams] = useState({
    page: 0,
    pageSize: pageSize,
    userId,
    filterField: null,
    filterValue: null,
    filterOperator: null,
    propertyGroup: selectedPropertyGroup,
    propertyName: propertyNameFilter !== '' ? propertyNameFilter : null,
  });

  const columns: GridColDef[] = [
    {
      field: 'group_name',
      headerName: 'Group',
      minWidth: 300,
    },
    {
      field: 'property_name',
      headerName: 'Property',
      minWidth: 350,
    },
    {
      field: 'editable',
      headerName: 'Editable',
      sortable: false,
      filterable: false,
      align: 'right',
      headerAlign: 'right',
      width: 150,
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <>
          {params.row?.is_editable ? (
            <IconButton onClick={() => onToggleEditable({propertyId: params.row.id, isEditable: false})}>
              <CheckBox />
            </IconButton>
          ) : (
            <IconButton onClick={() => onToggleEditable({propertyId: params.row.id, isEditable: true})}>
              <CheckBoxOutlineBlank />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  async function getPropertyGroups() {
    try {
      const {data} = await Api.get(`amazon-listings/amazon-property-groups`);
      setPropertyGroups(data);
    } catch (e) {
      errorAlert('Unable to get Amazon Properties', e);
    }
  }

  function updateQueryParams() {
    let search = new URLSearchParams(location.search);
    if (selectedPropertyGroup) {
      search.set('propertyGroup', selectedPropertyGroup);
    } else {
      search.delete('propertyGroup');
    }
    if (propertyNameFilter) {
      search.set('propertyName', propertyNameFilter);
    } else {
      search.delete('propertyName');
    }
    if (page) {
      search.set('page', page.toString());
    } else {
      search.delete('page');
    }
    if (pageSize) {
      search.set('pageSize', pageSize.toString());
    } else {
      search.delete('pageSize');
    }
    const newurl =
      window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + search.toString();
    window.history.pushState({path: newurl}, '', newurl);
  }

  function getDefaultQuery() {
    const urlParams = new URLSearchParams(location.search);
    const propertyGroup = urlParams.get('propertyGroup');
    const propertyName = urlParams.get('propertyName');
    const page = urlParams.get('page');
    const pageSize = urlParams.get('pageSize');
    const query: any = {
      propertyGroup,
      page,
      pageSize,
      propertyName,
    };
    query.propertyGroup = propertyGroup;
    query.page = page;
    query.pageSize = pageSize;
    query.propertyName = propertyName;

    return query;
  }

  useEffect(() => {
    const defaultQuery = getDefaultQuery();
    setSelectedPropertyGroup(defaultQuery.propertyGroup);
    setPage(defaultQuery.page ? parseInt(defaultQuery.page) : 0);
    setPageSize(defaultQuery.pageSize ? parseInt(defaultQuery.pageSize) : 40);
    setPropertyNameFilter(defaultQuery.propertyName ? defaultQuery.propertyName : '');
    const _params = {
      ...params,
      page: defaultQuery.page ? parseInt(defaultQuery.page) : 0,
      pageSize: defaultQuery.pageSize ? parseInt(defaultQuery.pageSize) : 40,
      propertyGroup: defaultQuery.propertyGroup,
      propertyName: defaultQuery.propertyName ? defaultQuery.propertyName : '',
    };

    setParams(_params);
    loadData(_params);
    getPropertyGroups();
  }, []);

  useEffect(() => {
    updateQueryParams();
  }, [selectedPropertyGroup, page, pageSize, propertyNameFilter]);

  async function loadData(_params?: any) {
    setLoadingData(true);
    try {
      const {data} = await Api.get(`amazon-listings/amazon-properties`, {
        params: _params,
      });
      setRows(data.rows);
      setCount(data.count);
    } catch (e) {
      errorAlert('Unable to get amazon properties', e);
    } finally {
      setLoadingData(false);
    }
  }

  async function onToggleEditable({
    propertyId,
    isEditable,
    propertyGroup,
  }: {
    propertyId?: number | string;
    isEditable: boolean;
    propertyGroup?: string;
  }) {
    const body = propertyGroup
      ? {
          property_group: propertyGroup,
          is_editable: isEditable,
        }
      : {
          is_editable: isEditable,
          property_ids: [propertyId],
        };

    try {
      const {data} = await Api.post(`amazon-listings/property-permission`, {
        ...body,
        user_id: userId,
      });
      if (propertyGroup) {
        loadData(params);
      } else {
        let _rows = [...rows];
        data?.forEach((x) => {
          const idx = _rows.findIndex((_row) => _row.id.toString() === x.property_id.toString());
          _rows[idx].is_editable = x.is_editable;
        });
        setRows(_rows);
      }
      alertService.success(`Permission was changed successfully.`);
    } catch (e: any) {
      errorAlert('Unable to change permission.', e);
    }
  }

  async function handleChangePage(value: number) {
    setPage(value);
    const _params = {...params, page: value};
    setParams(_params);
    loadData(_params);
  }

  async function handleChangePageSize(value: number) {
    setPageSize(value);
    const _params = {...params, pageSize: value};
    setParams(_params);
    loadData(_params);
  }

  function onChangePropertyGroup(properytGroup) {
    setPage(0);
    setSelectedPropertyGroup(properytGroup);
    const _params = {...params, propertyGroup: properytGroup, page: 0};
    setParams(_params);
    loadData(_params);
  }

  function onSearch() {
    setPage(0);
    const _params = {...params, propertyName: propertyNameFilter, page: 0};
    setParams(_params);
    loadData(_params);
  }

  return (
    <TableContainer container spacing={1} justifyContent="center" style={{paddingTop: 60}}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h4" color="primary" gutterBottom>
          User Permission on Amazon Listings Item
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={{lg: 1.5, md: 1.5, sm: 1, xs: 1}}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Autocomplete
              size="small"
              options={propertyGroups}
              className={classes.autocomplete}
              getOptionLabel={(option) => getLabel(option)}
              value={selectedPropertyGroup}
              onChange={(event, newValue) => {
                onChangePropertyGroup(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Filter by property group"
                  placeholder="Property Group"
                />
              )}
            />
          </Grid>
          <Grid item lg={9} md={9} sm={6} xs={12}>
            <SearchBar
              placeholder="Search By Property Name"
              value={propertyNameFilter}
              onChange={(newValue) => setPropertyNameFilter(newValue)}
              onSearch={() => onSearch()}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.toggleButtons}>
        <Button
          size="small"
          disabled={!selectedPropertyGroup}
          onClick={() => onToggleEditable({isEditable: true, propertyGroup: selectedPropertyGroup})}
        >
          Make Group Editable
        </Button>
        <Spacer width={10} />
        <Button
          size="small"
          disabled={!selectedPropertyGroup}
          onClick={() => onToggleEditable({isEditable: false, propertyGroup: selectedPropertyGroup})}
        >
          Make Group Non-Editable
        </Button>
      </Grid>

      <Grid item xs={12} style={{paddingTop: 15}}>
        <DataGrid
          style={{width: '100%'}}
          className={`custom-table ${loadingData ? 'load-headers' : ''}`}
          autoHeight={true}
          pageSize={pageSize}
          rowCount={count}
          loading={loadingData}
          page={page}
          pagination
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={(params) => handleChangePageSize(params)}
          paginationMode="server"
          onPageChange={(params) => handleChangePage(params)}
          disableSelectionOnClick={true}
          rows={rows}
          columns={columns}
        />
      </Grid>
      <Grid item lg={12}>
        <CustomAlert id="default-alert" />
      </Grid>
    </TableContainer>
  );
}

export {UserPermission};
