import {Typography, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  pageTitle: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: '5px',
      fontSize: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px!important',
      '& svg': {
        fontSize: '22px',
      },
    },
  },
}));

interface PageTitleProps {
  children: JSX.Element | string;
  icon?: JSX.Element;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  color?: string;
  component?: string;
  display?: string;
  gutterBottom?: boolean;
  fontSize?: string;
  fontWeight?: number;
  margin?: string;
}

export default function PageTitle({
  children,
  icon,
  variant = 'h4',
  color = 'primary',
  display = 'block',
  gutterBottom,
  fontSize = '24px',
  fontWeight = 400,
  margin,
}: PageTitleProps) {
  const classes = useStyles();
  return (
    <Typography
      variant={variant}
      color={color}
      className={classes.pageTitle}
      gutterBottom={gutterBottom}
      style={{fontSize: fontSize, display: display, fontWeight: fontWeight, margin: margin}}
    >
      {children} {icon}
    </Typography>
  );
}
