import mixpanel from 'mixpanel-browser';
// services
import {accountService} from './account.service';

export default function mixPanel({
  eventName,
  eventProperties = {},
  registrationId,
}: {
  eventName?: string;
  eventProperties?: any;
  registrationId?: string;
}) {
  const user = accountService.userValue;
  const properties = {
    user_id: user?.userId,
    email: user?.email,
    brand_code: user?.brand_code,
    name: user?.name,
    role: user?.role,
    sub_brands: user?.sub_brand_codes,
    active_subscription: user?.active_brand_subscription,
    brand_name: user?.brand_name,
    brand_type: user?.brand_type,
    brand_admin: user?.brand_admin,
  };

  const isProduction = process.env.NODE_ENV === 'production';

  if (isProduction) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {ignore_dnt: true, cross_subdomain_cookie: true});
    mixpanel.identify(registrationId ? registrationId : user?.email);

    if (eventProperties) {
      mixpanel.people.set({...properties, ...eventProperties});
    }

    if (eventName) {
      mixpanel.track(eventName, {...properties, ...eventProperties});
    }
  }
}
