import ReactGA from 'react-ga4';

export function withTracker(category, action, label?) {
  const isProduction = process.env.NODE_ENV === 'production';
  if (isProduction) {
    ReactGA.initialize(process.env.REACT_APP_GA_KEY, {
      testMode: true,
    });

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
    });

    ReactGA.event({
      category: category,
      action: action,
      label: label ? label : '',
    });
  }
}
