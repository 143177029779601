import {useEffect, useState, useContext} from 'react';
import moment from 'moment';
import {CSVLink} from 'react-csv';
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import {DataGrid, GridCellParams, GridColDef} from '@mui/x-data-grid';
import {VisibilityOff, Visibility} from '@mui/icons-material';
// services
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {Role} from '../../../../utils/role';
import {rowsPerPageOptions} from '../../../../utils/constants';
// components
import DateRangePicker from '../../../../components/DateRangePicker/DateRangePicker';
import Button from '../../../../components/Button/Button';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// context
import {AppContext} from '../../../../context/AppContext/AppContext';

interface AlertTableProps {
  alertType: string;
  classes: any;
}

export default function DeletedSkus({alertType, classes}: AlertTableProps) {
  const [loadingData, setLoadingData] = useState(false);
  const [fromTo, setFromTo] = useState({
    from: moment(new Date().setDate(new Date().getDate())).startOf('day').valueOf(),
    to: moment(new Date()).valueOf(),
  });
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [count, setCount] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [selectedIdxes, setSelectedIdxes] = useState([]);
  const [filterModel, setFilterModel] = useState(null);
  const [sortModel, setSortModel] = useState(null);
  const [accountManager, setAccountManager] = useState(null);
  const [csvRows, setCsvRows] = useState([]);

  const {accountManagers, currentAM} = useContext(AppContext);

  useEffect(() => {
    if (currentAM) {
      setAccountManager(currentAM);
    }
  }, [currentAM]);

  useEffect(() => {
    loadData();
  }, [accountManager, fromTo, page, pageSize, showAll, sortModel, filterModel]);

  async function loadData() {
    setCount(0);
    setRows([]);
    setLoadingData(true);
    try {
      const {data} = await Api.get(`alerts/deleted-skus`, {
        params: {
          from: fromTo.from,
          to: fromTo.to,
          page,
          pageSize,
          showAll,
          sortBy: sortModel?.field || null,
          sortOrder: sortModel?.sort || null,
          filterField: filterModel?.columnField || null,
          filterValue: filterModel?.value || null,
          filterOperator: filterModel?.operatorValue || null,
          accountManagerId: accountManager?.id || null,
        },
      });
      setCount(data.count);
      setRows(
        data.rows.map((x, i) => {
          x.date = moment(new Date(x.date)).format('YYYY-MM-DD');
          return {id: i, ...x};
        })
      );
      setSelectedIdxes([]);
      setLoadingData(false);
    } catch (e) {
      errorAlert('Error loading data', e);
    } finally {
      setLoadingData(false);
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 110,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        if (params.row.finished) {
          return (
            <Tooltip title={'Show Alert'}>
              <IconButton onClick={() => hideAlert(params.row)}>
                <Visibility style={{color: 'green'}} />
              </IconButton>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={'Hide Alert'}>
              <IconButton onClick={() => hideAlert(params.row)}>
                <VisibilityOff />
              </IconButton>
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'sku',
      headerName: 'SKU',
      flex: 0.3,
      renderCell: (params: GridCellParams) => {
        return (
          <Tooltip title={params.row.sku}>
            <span className="table-cell-trucate">{params.row.sku}</span>
          </Tooltip>
        );
      },
    },
    {field: 'asin', headerName: 'ASIN', flex: 0.15},
    {field: 'brandName', headerName: 'Brand', flex: 0.2},
    {field: 'date', headerName: 'Date', flex: 0.1},
    {field: 'status', headerName: 'Status', flex: 0.1},
  ];

  const csvHeaders = columns
    .filter((column) => column.field !== 'actions')
    .map((column) => {
      return {key: column.field, label: column.headerName};
    });

  function onFromToChange(from, to) {
    const fromValue = moment(from).startOf('day').valueOf();
    const toValue = moment(to).endOf('day').valueOf();
    setFromTo({from: fromValue, to: toValue});
  }

  async function hideAlert(record) {
    try {
      await Api.post('alerts/deleted-skus/hide-alert', {alertIds: [record._id]});
      loadData();
      alertService.success(`Successfully hide!`);
    } catch (e) {
      errorAlert('Something went wrong hiding the alert. Please try again later', e);
    }
  }

  async function handleHideAlerts() {
    const selectedIds = rows.filter((row) => selectedIdxes.indexOf(row.id.toString()) > -1).map((row) => row._id);
    try {
      await Api.post('alerts/deleted-skus/hide-alert', {alertIds: selectedIds});
      loadData();
      alertService.success(`Successfully hide!`);
    } catch (e) {
      errorAlert('Something went wrong hiding the alerts. Please try again later.', e);
    }
  }

  async function handleExportCSV(done) {
    try {
      const {data} = await Api.get(`alerts/deleted-skus`, {
        params: {
          from: fromTo.from,
          to: fromTo.to,
          pageSize: count,
          showAll,
          sortBy: sortModel?.field || null,
          sortOrder: sortModel?.sort || null,
          filterField: filterModel?.columnField || null,
          filterValue: filterModel?.value || null,
          filterOperator: filterModel?.operatorValue || null,
          accountManagerId: accountManager?.id || null,
        },
      });
      setCsvRows(data.rows);
      done();
    } catch (e) {
      errorAlert('Error exporting data', e);
    }
  }

  function handleChangePageSize(params: any) {
    setPageSize(params);
    loadData();
  }

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item lg={4} xs={12} style={{margin: 'auto'}}>
        {fromTo.to && fromTo.from && (
          <DateRangePicker
            from={fromTo.from}
            to={fromTo.to}
            onChange={(x) => {
              onFromToChange(x.selection?.startDate, x.selection?.endDate);
            }}
          />
        )}
      </Grid>
      <Grid item lg={8} xs={12} style={{margin: 'auto'}}>
        <Box display="flex" justifyContent="flex-end" alignItems="center" className={classes.toolbar}>
          {[Role.Admin].includes(accountService.userValue.role) && (
            <Autocomplete
              id="account-manager"
              size="small"
              options={accountManagers}
              getOptionLabel={(option) => option.name}
              value={accountManager}
              onChange={(event, newValue) => {
                setAccountManager(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Account Manager" variant="outlined" />}
            />
          )}
          {alertType !== 'All' && (
            <FormGroup row>
              <FormControlLabel
                control={<Switch checked={showAll} onChange={() => setShowAll(!showAll)} />}
                label="Show All"
              />
              <Button size="small" onClick={handleHideAlerts} disabled={showAll || selectedIdxes?.length < 1}>
                Hide all selected
              </Button>
            </FormGroup>
          )}
          <CSVLink
            filename={`${alertType} Alerts.csv`}
            data={csvRows}
            headers={csvHeaders}
            asyncOnClick={true}
            onClick={(event, done) => {
              handleExportCSV(done);
            }}
          >
            <Button size="small">Export to CSV</Button>
          </CSVLink>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          className={classes.grid}
          rows={rows}
          columns={columns}
          autoHeight={true}
          pageSize={pageSize}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={(params) => handleChangePageSize(params)}
          rowCount={count}
          loading={loadingData}
          pagination
          paginationMode="server"
          onPageChange={(params: any) => setPage(params)}
          sortingMode="server"
          onSortModelChange={(params: any) => setSortModel(params[0])}
          filterMode="server"
          onFilterModelChange={(params: any) => setFilterModel(params.items[0])}
          disableSelectionOnClick={true}
          checkboxSelection={alertType !== 'All'}
          selectionModel={selectedIdxes}
          onSelectionModelChange={(x: any) => {
            setSelectedIdxes(x.selectionModel);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomAlert id="default-alert-2" />
      </Grid>
    </Grid>
  );
}
