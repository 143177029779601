import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: '10px',
  },
  grid: {
    display: 'flex',
    flexDirection: 'column-reverse',
    boxShadow: '0px 0px 6px 0px #94949433',
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgb(23 69 130 / 8%)',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '&.load-headers': {
      opacity: 0.5,
    },

    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '65vh!important',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      background: '#999',
      borderRadius: '3px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
      background: '#777',
    },
  },
  green: {
    color: '#4caf50',
  },
  red: {
    color: '#f44336',
  },
  formGroup: {
    '&.MuiGrid-root': {
      width: '100%',
      paddingTop: '30px',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& > div:first-of-type': {
        padding: '20px 0',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr));',
        width: '100%',
        gap: '10px',
      },
      '& > div:last-of-type': {
        width: '100%',
        maxWidth: '300px',
      },
      [theme.breakpoints.down(767)]: {
        paddingTop: 0,
        '& > div:first-of-type': {
          justifyContent: 'space-between',
          width: '100%',
          paddingRight: 0,
          '& > div:last-of-type': {
            paddingRight: 0,
          },
        },
        '& > .MuiAutocomplete-root': {
          maxWidth: '100%',
          paddingRight: '0',
        },
      },
      [theme.breakpoints.down(520)]: {
        '& > div:first-of-type': {
          padding: '10px 0',
          width: '100%',
          flexDirection: 'column',
          '& > .MuiAutocomplete-root': {
            width: '100%',
            maxWidth: '100%',
            '&:not(:last-of-type)': {
              padding: '0 0 10px 0',
            },
          },
        },
        '& > div:last-of-type': {
          maxWidth: '100%',
        },
      },
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '900px',

    '& .MuiButton-outlined:not(:focus, :active)': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#f9f9f9',
    },
    '&.MuiButtonGroup-root .MuiButton-root': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down(1240)]: {
      '&.new-group': {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
          borderRadius: '0',
          borderTopLeftRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(-n+4)': {
          borderBottomColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(4)': {
          borderRadius: '0',
          borderTopRightRadius: '3px!important',
          borderRightColor: 'rgba(60, 141, 199, 0.5)',
        },
        '& .MuiButton-root:nth-of-type(4n)': {
          borderRadius: '0',
        },
        '& .MuiButton-root:nth-of-type(8)': {
          borderBottomRightRadius: '3px',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(.new-group)': {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
          borderRadius: '0',
          borderTopLeftRadius: '3px',
        },
        '&.MuiButtonGroup-root .MuiButton-root': {
          border: theme.palette.mode === 'dark' ? '1px solid #3887be' : '1px solid #174580',
          borderBottomColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(3n)': {
          borderLeftColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(3)': {
          borderTopRightRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(4n)': {
          borderRightColor: 'transparent',
          '&:hover': {
            borderRightColor: 'transparent',
          },
        },
        '& .MuiButton-root:last-of-type': {
          gridArea: '4 / 1 / 4/ 4',
          borderBottomColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
          borderRadius: '0',
          borderBottomRightRadius: '3px',
          borderBottomLeftRadius: '3px',
        },
        '& .MuiButton-outlined': {
          border: 'none',
          color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
          '&:last-of-type': {
            backgroundColor: '#e9e9e9',
          },
        },
        '& .MuiButton-containedPrimary': {
          border: 'none',
          '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
            outline: 'none',
          },
        },
      },
      '&.new-group': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',

        '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
          borderRadius: '0',
          borderTopLeftRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(-n+6)': {
          borderBottomColor: 'transparent',
        },
        '& .MuiButton-root:nth-of-type(2)': {
          borderRadius: '0',
          borderTopRightRadius: '3px!important',
          borderRightColor: 'rgba(60, 141, 199, 0.5)',
        },
        '& .MuiButton-root:nth-of-type(6), ': {
          borderRightColor: 'rgba(60, 141, 199, 0.5)',
        },
        '& .MuiButton-root:nth-of-type(4n)': {
          borderRadius: '0',
        },
        '& .MuiButton-root:nth-of-type(7)': {
          borderBottomLeftRadius: '3px',
        },
        '& .MuiButton-root:nth-of-type(8)': {
          borderBottomRightRadius: '3px',
        },
      },
    },
  },
  toolbarButton: {
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '.5rem 1rem .4rem 1rem',
    [theme.breakpoints.down(820)]: {
      fontSize: '12px',
    },
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
    [theme.breakpoints.down(820)]: {
      margin: '40px auto 20px auto',
    },
  },
  chart: {
    width: '100%',
    height: '500px',
    '&.composed-chart': {
      height: '600px',
      width: '300px',
    },
    '&.subscription': {
      height: '700px',
    },
    '& .customContainer': {
      '& .custom-bar': {
        fill: theme.palette.mode === 'dark' ? '#fff' : '#000000',
        fontSize: '11px',
        fontWeight: theme.palette.mode === 'dark' ? 500 : 'bold',
      },
      overflow: 'auto',
      '& .custom-y-axis': {},
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#999',
        borderRadius: '3px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#777',
      },
    },
    '& .recharts-cartesian-axis-tick-value': {
      fontSize: '12px',
      fill: theme.palette.text.secondary,
      fontWeight: '500',
      '& tspan': {
        width: '50px',
      },
    },
    '& .recharts-wrapper': {
      width: '100%!important',
    },
    '& .recharts-surface': {
      width: '100%',
      margin: 'auto',
    },
    '& svg > g': {
      margin: 'auto',
    },
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down(500)]: {
      height: '300px',
    },
  },
  doubleChart: {
    width: '100%',
    height: '600px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(900)]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down(500)]: {
      height: '300px',
    },
  },
  chartList: {
    width: '100%',
    maxWidth: '500px',
    margin: 'auto',
    '& > li': {
      padding: '0 8px!important',
      '&.clickable': {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(82, 164, 223, 0.1)',
          '& > .MuiListItemText-root > span:first-of-type > span:first-of-type': {
            textDecoration: 'underline',
          },
        },
      },
    },
    '& li.active': {
      backgroundColor: 'rgba(82, 164, 223, 0.1)',
    },
    '& li div': {
      minWidth: '40px',
    },
    '&:not(.custom-list) li div > span': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > span': {
        lineHeight: '1.5',
      },
    },
    '& li div p > span': {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1.5',
      '& svg': {
        fontSize: '18px',
      },
    },
    '&.custom-list li .MuiListItemText-root p > div > span': {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1.5',
      '& svg': {
        fontSize: '18px',
      },
    },
    '&.custom-list': {
      '& > li': {
        padding: '0 8px!important',
        '&.clickable': {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(82, 164, 223, 0.1)',
            '& > .MuiListItemText-root > span:first-of-type': {
              textDecoration: 'underline',
            },
          },
        },
      },
    },
    '&.custom-list .custom-list': {
      '&, & li, & li div': {
        padding: '0!important',
        margin: '0!important',
      },
      '& li div > span': {
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '1.5',
        color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
        '& svg': {
          fontSize: '15px',
        },
        '& > span': {
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.mode === 'dark' ? '#cacaca' : '#464646',
        },
      },
    },
  },
  listContainer: {
    maxHeight: '400px',
    maxWidth: '500px',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingRight: '10px!important',
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#999',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#777',
    },
  },
  chartContainer: {
    height: '100%',
    width: '100%',
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    boxShadow: '0px 0px 6px 0px #94949433',
    padding: '10px 20px 20px 20px',
    position: 'relative',
    '&.custom-container': {
      boxShadow: 'none',
      border: 'none',
    },
    '& .chart-header': {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',

      '& button': {
        fontSize: '12px',
      },
    },
    '&.reports': {
      minHeight: '250px',
      '& > div:last-of-type': {
        padding: '15px 0',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
      },
      '& .default-icon': {
        fontSize: '55px',
        color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.secondary,
        '& .download': {
          fontSize: '25px',
        },
      },
    },
    [theme.breakpoints.down(600)]: {
      '& .chart-header': {
        '& h6, & h5, & svg': {
          fontSize: '16px',
          lineHeight: '1.2',
        },
        '& button': {
          width: '100%',
          fontSize: '10x',
          float: 'right',
          padding: '5px',
          margin: '5px 0',
        },
      },
    },
  },
  chartModal: {
    '& .MuiDialogContent-root': {
      padding: '0!important',
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
    },
    '& .MuiDialogTitle-root': {
      '& > div > a, & > div > a button': {
        '& > div': {
          margin: '0',
        },
        [theme.breakpoints.down(600)]: {
          width: '100%',
          margin: 0,
          fontSize: '12px',
        },
      },
    },
  },
  breadcrumbs: {
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
    textAlign: 'left',
    zIndex: '1',
    '& h6, & h5': {
      fontSize: '1.2rem',
    },
    '& .selected': {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& h5': {
      textTransform: 'lowercase',
    },
    '& h5:first-letter': {
      textTransform: 'uppercase',
    },
  },
  pageHeader: {
    display: 'flex',
    [theme.breakpoints.down(1200)]: {
      flexDirection: 'column-reverse',
    },
  },
  salesCard: {
    position: 'relative',
    width: '100%',
    '& .selected-item': {
      cursor: 'pointer',
      '&:hover': {
        background: 'red',
        backgroundColor: 'rgba(82, 164, 223, 0.1)',
      },
    },
    '& .MuiCardHeader-root': {
      padding: '12px!important',
    },
    '& .MuiCardHeader-content': {
      '& span:first-of-type, .MuiBreadcrumbs-li h6, .MuiBreadcrumbs-li h5, .MuiBreadcrumbs-li svg': {
        fontSize: '18px',
        color: theme.palette.secondary.main,
        lineHeight: '1.3',
      },
      '& span:last-of-type': {
        fontSize: '14px',
      },
    },
    '& > .container': {
      height: '215px',
      '&.compare': {
        height: '300px',
      },
      overflowX: 'hidden',
      overflowY: 'auto',

      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#999',
        borderRadius: '3px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#777',
      },
      [theme.breakpoints.only('md')]: {
        height: '200px',
        '&.compare': {
          height: '285px',
        },
      },
      [theme.breakpoints.down(600)]: {
        height: '200px',
        '&.compare': {
          height: '270px',
        },
      },
      [theme.breakpoints.down(500)]: {
        height: '350px',
        '&.compare': {
          height: '480px',
        },
        '& > div > .MuiGrid-container': {
          flexDirection: 'column',
          '& > div': {
            maxWidth: '100%',
          },
        },
      },
    },
    '& .card-list-1': {
      fontSize: '12px',
      '& li:first-of-type': {
        '& .brand-code': {
          color: theme.palette.secondary.main,
        },
        '& .brand-code.sku a': {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
      '& li': {
        '& .MuiListItemText-root': {
          margin: '2px 0px!important',
        },
        padding: '0 10px!important',
        '& .MuiTypography-body1': {
          fontSize: '16px',
          fontWeight: 'bold',
        },
        '& .MuiTypography-body2': {
          fontSize: '12px',
          textTransform: 'capitalize',
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
          whiteSpace: 'break-spaces',
          '& > div > span': {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            '& svg': {
              fontSize: '13px',
            },
          },
        },
        [theme.breakpoints.only('md')]: {
          '& .MuiTypography-body1': {
            fontSize: '14px',
          },
          '& .MuiTypography-body2': {
            fontSize: '10px',
          },
        },
        [theme.breakpoints.down(600)]: {
          '& .MuiTypography-body1': {
            fontSize: '14px',
          },
          '& .MuiTypography-body2': {
            fontSize: '10px',
          },
        },
      },
    },
    '& .card-list-2': {
      padding: '10px',
      '& li': {
        padding: '4px!important',
        '& .MuiListItemText-root': {
          margin: '0!important',
        },

        '& .MuiTypography-body2': {
          fontSize: '11px',
          textTransform: 'capitalize',
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
          whiteSpace: 'break-spaces',
          '& > div > span': {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            '& svg': {
              fontSize: '13px',
            },
          },
        },
        '& .MuiListItemText-root > span': {
          margin: '0!important',
          display: 'flex',
          justifyContent: 'space-between',
          '& .MuiTypography-body1': {
            fontSize: '13px',
            textTransform: 'capitalize',
          },
          [theme.breakpoints.only('md')]: {
            '& .MuiTypography-body1': {
              fontSize: '10px',
              textTransform: 'capitalize',
            },
          },
          [theme.breakpoints.down(600)]: {
            '& .MuiTypography-body1': {
              fontSize: '10px',
              textTransform: 'capitalize',
            },
          },
        },
      },
    },
  },
  geomap: {
    overflow: 'hidden',
    padding: '25px 0!important',
    width: '100%',
    position: 'relative',
    '& > h6': {
      color: theme.palette.secondary.main,
      fontSize: '1.2rem',
      marginBottom: '10px',
    },

    '& .loading-component': {
      width: '100%',
      height: '600px',
      background: '#000000bc',
      position: 'absolute',
      zIndex: 2,
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff!important',
    },
    '& #controls': {
      margin: '10px 0 0 0',
      textAlign: 'center',
    },
    '& .data-bar': {
      display: 'flex',
      paddingBottom: '15px',
      '& .color-key': {
        background: '#fff',
        height: '22px',
        position: 'relative',

        '& .color-bar': {
          height: '100%',
          width: '100%',
          background: ' linear-gradient(to right,rgb(0, 136, 254, .3), rgb(0, 136, 254, 1) )',
        },
        flex: '1',
        textAlign: 'left',
        fontSize: '1.5em',
        lineHeight: '1em',
        '& #data-caret': {
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          color: '#fff',
        },
        '& #data-caret-text': {
          position: 'relative',
          fontSize: '13px',
          margin: 0,
          color: theme.palette.text.primary,
        },
      },
    },
  },
  mapBreadcrumbs: {
    position: 'absolute',
    top: '8px',
    left: '10px',
    zIndex: 2,
    background: theme.palette.background.paper,
    boxShadow: '0 4px 6px -4px #333',
    borderRadius: '2px',
    padding: '2px 10px!important',
    '& h6': {
      cursor: 'auto!important',
      '&:hover': {
        textDecoration: 'none!important',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 700,
      textTransform: 'none!important',
      textDecoration: 'none!important',
      '&:hover': {
        textDecoration: 'none!important',
      },
      '& svg': {
        cursor: 'pointer',
        fontSize: '18px',
        margin: '0 0 0 5px',
        '&:hover': {
          color: theme.palette.mode === 'dark' ? '#f9f9f9' : '#515151',
        },
      },
    },
  },
  mapDataContainer: {
    paddingLeft: '20px!important',
    '& > div': {
      width: '100%',
      display: 'flex',
      margin: 'auto',
      justifyContent: 'center',
      '& > nav': {
        width: '100%',
        padding: '0 8px',
      },
    },
    [theme.breakpoints.down(1200)]: {
      paddingLeft: '0!important',
      '& > div': {
        margin: '40px auto 20px auto',
      },
    },
  },
  mapTooltip: {
    position: 'absolute',
    bottom: '85px',
    opacity: 0.8,
    left: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'center',
    fontSize: '13px',
    zIndex: 5,
    boxShadow: '0 4px 6px -4px #333',
    padding: '15px',
    '& p': {
      display: 'flex',
      width: '100%',
      fontSize: '14px',
      '& .compare': {
        paddingLeft: '4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '& svg': {
          fontSize: '12px',
        },
      },
    },
  },
  heatmap: {
    minHeight: '400px',
    display: 'flex',
    padding: '55px 0 35px 0!important',
    fontSize: '12px',
    '& .header': {
      position: 'absolute',
      top: '55px',
    },
  },
  heatmapTooltip: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '160px',
    padding: '5px',
    '& > p': {
      textTransform: 'capitalize',
      color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : '#fff',
    },
    '& span': {
      margin: 0,
      padding: '5px 0',
      fontSize: '13px',
      lineHeight: '13px',
    },
    '& .compare': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      fontSize: '11px!important',
      lineHeight: '10px',
    },
  },
  heatmapContainer: {
    overflowX: 'auto',
    paddintTop: '10px',
    '& .no-data': {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#999',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#777',
    },
    '& > div:not(.no-data)': {
      minWidth: '1100px',
    },
    '& > div > div > div > div > div:first-of-type > div': {
      background: theme.palette.background.paper,
      height: '62px!important',
      zIndex: 1,
    },
    '& > div > div > div:last-of-type > div:not(:first-of-type)': {
      minWidth: '71px',
    },
    '& > div > div > div > div > div:not(:first-of-type):not(last-of-type) > div': {
      padding: '0!important',
    },
  },
  composedTooltip: {
    display: 'flex',
    flexDirection: 'column',
    '& span:last-of-type': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export {useStyles};
