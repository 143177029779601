import moment from 'moment';

function updateQueryParams(location, values, ignoreAttr?) {
  let search = new URLSearchParams(location.search);

  Object.entries(values).forEach(([key, value]: any) => {
    if (value === undefined || value === null || value.length === 0 || value === '' || ignoreAttr?.includes(key)) {
      return search.delete(key);
    } else {
      return search.set(key, value);
    }
  });
  let newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;

  if (search?.toString() !== '') newUrl = newUrl + '?' + search.toString();
  window.history.pushState({path: newUrl}, '', newUrl);
}

function getDefaultQuery(location, values, customPreset?) {
  const urlParams = new URLSearchParams(location.search);
  let query: any = {};
  Object.entries(values).forEach(([key, value]: any) => {
    query[key] = urlParams.get(key);
  });
  if (
    !moment(query.from).isValid() ||
    !moment(query.to).isValid() ||
    !moment(query.compareFrom).isValid() ||
    !moment(query.compareTo).isValid()
  ) {
    query = {
      ...query,
      from: null,
      to: null,
      compareFrom: null,
      compareTo: null,
    };
  }

  if (!query.from || !query.to) {
    if (customPreset) {
      query.to = customPreset.to;
      query.from = customPreset.from;
      query.preset = customPreset.preset;
    } else {
      query.to = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
      query.from = moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD');
      query.preset = '7d';
    }
  } else if (!query.compareFrom || !query.compareTo) {
    query.from = moment(query.from).format('YYYY-MM-DD');
    query.to = moment(query.to).format('YYYY-MM-DD');
  } else {
    query.from = moment(query.from).format('YYYY-MM-DD');
    query.to = moment(query.to).format('YYYY-MM-DD');
    query.compareFrom = moment(query.compareFrom).format('YYYY-MM-DD');
    query.compareTo = moment(query.compareTo).format('YYYY-MM-DD');
  }

  return query;
}

export {updateQueryParams, getDefaultQuery};
