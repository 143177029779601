import {useEffect, useState} from 'react';
import _ from 'lodash';
import {Grid, Typography} from '@mui/material';
import {DataGrid, GridColDef, GridToolbarContainer, GridToolbarColumnsButton} from '@mui/x-data-grid';
// components
import {CustomAlert} from '../../../Login/components/CustomAlert';
import TableContainer from '../../../../components/TableContainer/TableContainer';
import DropDownButton from '../../../../components/DropdownButton/DropDownButton';
import DraggableList from '../../../../components/DraggableList/DraggableList';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../../../utils/tableSettings';
// styles
import {useStyles} from '../../Styles';

export default function BrandsOverview() {
  document.title = 'Stonehenge.ai/Brands Overview';
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderColumns, setOrderColumns] = useState([]);
  const [visibilityModel, setVisibilityModel] = useState<any>({});
  const [tableLoading, setTableLoading] = useState(false);

  const columns: GridColDef[] = [
    {field: 'group', headerName: 'By', minWidth: 280, flex: 1},
    {field: 'displayName', headerName: 'Tag', minWidth: 250, flex: 1},
    {
      field: 'total',
      headerName: 'Active',
      minWidth: 140,
      flex: 1,
      renderCell: (params) => <span>{params.row.count || params.row.total || 0}</span>,
    },
    {
      field: 'percentage',
      headerName: 'Percentage %',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <span>{params.row.percentage || 0}%</span>,
    },
  ];

  useEffect(() => {
    async function getTableData() {
      const data = await getUserPreferences({
        list: columns,
        tableName: 'brands-overview',
        visibilityModel: visibilityModel,
        defaultVisibilityModel: {},
        loading: setTableLoading,
      });
      if (data) {
        setOrderColumns(data.columns);
        setVisibilityModel(data.visibility);
      }
    }
    getTableData();
  }, [loading]);

  async function loadData() {
    setLoading(true);
    try {
      const {data} = await Api.get('brands/overview-data');
      let newData = [];
      let response = {};
      Object.entries(data).forEach(([key, value]: any, index) => {
        response = {
          ...response,
          [key]: {key: key, id: index, ...value},
        };
      });
      _.chain(response)
        .groupBy('group')
        .forEach((value, key) => {
          newData.push(...value);
        })
        .value();
      setRows(newData);
    } catch (e) {
      errorAlert('Unable to get brands overview data', e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const DataGridToolbar = () => (
    <GridToolbarContainer className="toolbar-container">
      <GridToolbarColumnsButton className={classes.toolbarButton} disabled={loading || tableLoading} />
      <DropDownButton buttonType="text" buttonText="Order Columns" bold loading={loading || tableLoading}>
        <DraggableList
          items={[...orderColumns]}
          ignoreItems={['actions']}
          setItems={(v) => {
            setOrderColumns(v);
            setUserPreferences(
              {
                columnVisibilityModel: visibilityModel,
                columnsOrder: v.map((x) => x.field),
              },
              'brands-overview',
              setTableLoading
            );
          }}
        />
      </DropDownButton>
    </GridToolbarContainer>
  );

  return (
    <TableContainer>
      <Grid item lg={12} style={{width: '100%'}}>
        <Typography variant="h5" className={classes.title}>
          Brands Overview
        </Typography>
        <Grid container position="relative">
          <DataGrid
            components={{
              Pagination: DataGridToolbar,
              Toolbar: DataGridToolbar,
            }}
            className={`${classes.overviewGrid} custom-table ${tableLoading && !loading ? 'load-headers' : ''}`}
            style={{width: '100%'}}
            autoHeight={true}
            loading={loading}
            disableSelectionOnClick={true}
            rows={rows}
            getRowClassName={(params) => `column-${params.row.group.replace('&', '').replaceAll(' ', '-')}`}
            columns={[...orderColumns]}
            columnVisibilityModel={visibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              let data = {};
              Object.entries(newModel).forEach(([x, v]) => {
                if (v === false) {
                  data = {...data, [x]: v};
                }
              });
              const newOrder = getColumnsItems({
                list: columns,
                columnsVisibility: data,
                columnsOrder: orderColumns,
                currentOrder: orderColumns,
              });
              setVisibilityModel(data);
              setOrderColumns(newOrder);
              setUserPreferences(
                {
                  columnVisibilityModel: data,
                  columnsOrder: newOrder.map((x) => x.field),
                },
                'brands-overview',
                setTableLoading
              );
            }}
          />
        </Grid>
        <CustomAlert id="default-alert" />
      </Grid>
    </TableContainer>
  );
}
