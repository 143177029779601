export const createQueryString = (data: any, ignore?) => {
  Object.entries(data).forEach(([key, value]: any) => {
    if (value === null || value === undefined || value?.length === 0 || ignore?.includes(key)) {
      delete data[key];
    }
  });

  const newQuery = Object.keys(data)
    .map((key) => `${key}=${data[key]}`)
    .join('&');

  return newQuery;
};
