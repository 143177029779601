import {useEffect, useState} from 'react';
import {Paper, Typography} from '@mui/material';
import {CustomAlert} from '../../Login/components/CustomAlert';
import {Api, errorAlert} from '../../../utils/api';
import {Link} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {DataGrid, GridCellParams, GridColDef} from '@mui/x-data-grid';
import moment from 'moment';

function TopAsinsWidget({classes}) {
  const [rowsGainers1d, setRowsGainers1d] = useState([]);
  const [loadingGainers1d, setLoadingGainers1d] = useState(false);
  const [rowsGainers7d, setRowsGainers7d] = useState([]);
  const [loadingGainers7d, setLoadingGainers7d] = useState(false);
  const [rowsGainers30d, setRowsGainers30d] = useState([]);
  const [loadingGainers30d, setLoadingGainers30d] = useState(false);

  const [rowsLosers1d, setRowsLosers1d] = useState([]);
  const [loadingLosers1d, setLoadingLosers1d] = useState(false);
  const [rowsLosers7d, setRowsLosers7d] = useState([]);
  const [loadingLosers7d, setLoadingLosers7d] = useState(false);
  const [rowsLosers30d, setRowsLosers30d] = useState([]);
  const [loadingLosers30d, setLoadingLosers30d] = useState(false);

  function renderRevenue(x) {
    return x?.value ? (
      <Typography className={x.value >= 0 ? classes.green : classes.red} color={'primary'}>
        {x.value < 0 ? '' : '+'}
        {x.value}$
      </Typography>
    ) : (
      <></>
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'asin',
      headerName: 'ASIN',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <>
          <Link to={`/asins/performance/${params.value}`}>
            <Typography>{params.value}</Typography>
          </Link>
        </>
      ),
    },
    {
      field: 'revenue_amount',
      headerName: 'Change',
      flex: 1.2,
      sortable: false,
      filterable: false,
      renderCell: renderRevenue,
    },
  ];

  async function getGainer1d() {
    setLoadingGainers1d(true);
    try {
      const {data: gainerData} = await Api.get(`asins/gainers/1d`);
      const data = gainerData.map((x) => {
        return {id: x.asin, ...x};
      });
      setRowsGainers1d(data);
      localStorage.setItem('asinsGainers1d', JSON.stringify(data));
      localStorage.setItem('asinsGainers1d_timestamp', moment.utc().toISOString());
    } catch (e) {
      errorAlert('Unable to load gainer data', e);
    } finally {
      setLoadingGainers1d(false);
    }
  }

  async function getGainer7d() {
    setLoadingGainers7d(true);
    try {
      const {data: gainerData} = await Api.get(`asins/gainers/7d`);
      const data = gainerData.map((x) => {
        return {id: x.asin, ...x};
      });
      setRowsGainers7d(data);
      localStorage.setItem('asinsGainers7d', JSON.stringify(data));
      localStorage.setItem('asinsGainers7d_timestamp', moment.utc().toISOString());
      setLoadingGainers7d(false);
    } catch (e) {
      errorAlert('Unable to load gainer data', e);
    } finally {
      setLoadingGainers7d(false);
    }
  }

  async function getGainer30d() {
    setLoadingGainers30d(true);
    try {
      const {data: gainerData} = await Api.get(`asins/gainers/30d`);
      const data = gainerData.map((x) => {
        return {id: x.asin, ...x};
      });
      setRowsGainers30d(data);
      localStorage.setItem('asinsGainers30d', JSON.stringify(data));
      localStorage.setItem('asinsGainers30d_timestamp', moment.utc().toISOString());
    } catch (e) {
      errorAlert('Unable to load gainer data', e);
    } finally {
      setLoadingGainers30d(false);
    }
  }

  async function getLoser1d() {
    setLoadingLosers1d(true);
    try {
      const {data: loserData} = await Api.get(`asins/losers/1d`);
      const data = loserData.map((x) => {
        return {id: x.asin, ...x};
      });
      setRowsLosers1d(data);
      localStorage.setItem('asinsLosers1d', JSON.stringify(data));
      localStorage.setItem('asinsLosers1d_timestamp', moment.utc().toISOString());
    } catch (e) {
      errorAlert('Unable to load loser data', e);
    } finally {
      setLoadingLosers1d(false);
    }
  }

  async function getLoser7d() {
    setLoadingLosers7d(true);
    try {
      const {data: loserData} = await Api.get(`asins/losers/7d`);
      const data = loserData.map((x) => {
        return {id: x.asin, ...x};
      });
      setRowsLosers7d(data);
      localStorage.setItem('asinsLosers7d', JSON.stringify(data));
      localStorage.setItem('asinsLosers7d_timestamp', moment.utc().toISOString());
    } catch (e) {
      errorAlert('Unable to load loser data', e);
    } finally {
      setLoadingLosers7d(false);
    }
  }

  async function getLoser30d() {
    setLoadingLosers30d(true);
    try {
      const {data: loserData} = await Api.get(`asins/losers/30d`);
      const data = loserData.map((x) => {
        return {id: x.asin, ...x};
      });
      setRowsLosers30d(data);
      localStorage.setItem('asinsLosers30d', JSON.stringify(data));
      localStorage.setItem('asinsLosers30d_timestamp', moment.utc().toISOString());
    } catch (e) {
      errorAlert('Unable to load loser data', e);
    } finally {
      setLoadingLosers30d(false);
    }
  }

  useEffect(() => {
    const gainers1d = JSON.parse(localStorage.getItem('asinsGainers1d'));
    const gainers7d = JSON.parse(localStorage.getItem('asinsGainers7d'));
    const gainers30d = JSON.parse(localStorage.getItem('asinsGainers30d'));
    const losers1d = JSON.parse(localStorage.getItem('asinsLosers1d'));
    const losers7d = JSON.parse(localStorage.getItem('asinsLosers7d'));
    const losers30d = JSON.parse(localStorage.getItem('asinsLosers30d'));

    const gainers1d_timestamp = localStorage.getItem('asinsGainers1d_timestamp');
    const gainers7d_timestamp = localStorage.getItem('asinsGainers7d_timestamp');
    const gainers30d_timestamp = localStorage.getItem('asinsGainers30d_timestamp');
    const losers1d_timestamp = localStorage.getItem('asinsLosers1d_timestamp');
    const losers7d_timestamp = localStorage.getItem('asinsLosers7d_timestamp');
    const losers30d_timestamp = localStorage.getItem('asinsLosers30d_timestamp');

    if (
      !(gainers1d && gainers1d_timestamp && moment.utc().dayOfYear() <= moment.utc(gainers1d_timestamp).dayOfYear())
    ) {
      getGainer1d();
    } else {
      setRowsGainers1d(gainers1d);
    }

    if (
      !(gainers7d && gainers7d_timestamp && moment.utc().dayOfYear() <= moment.utc(gainers7d_timestamp).dayOfYear())
    ) {
      getGainer7d();
    } else {
      setRowsGainers7d(gainers7d);
    }

    if (
      !(gainers30d && gainers30d_timestamp && moment.utc().dayOfYear() <= moment.utc(gainers30d_timestamp).dayOfYear())
    ) {
      getGainer30d();
    } else {
      setRowsGainers30d(gainers30d);
    }

    if (!(losers1d && losers1d_timestamp && moment.utc().dayOfYear() <= moment.utc(losers1d_timestamp).dayOfYear())) {
      getLoser1d();
    } else {
      setRowsLosers1d(losers1d);
    }

    if (!(losers7d && losers7d_timestamp && moment.utc().dayOfYear() <= moment.utc(losers7d_timestamp).dayOfYear())) {
      getLoser7d();
    } else {
      setRowsLosers7d(losers7d);
    }

    if (
      !(losers30d && losers30d_timestamp && moment.utc().dayOfYear() <= moment.utc(losers30d_timestamp).dayOfYear())
    ) {
      getLoser30d();
    } else {
      setRowsLosers30d(losers30d);
    }
  }, []);

  return (
    <Paper className={classes.wrapperDiv} variant={'outlined'}>
      <Typography component="h2" variant="h5" color="secondary">
        ASIN Movers
      </Typography>
      <Grid container spacing={0} justifyContent="center" style={{paddingTop: 20}}>
        <Grid item lg={6}>
          <Typography component="h2" variant="h6" color="secondary">
            Gainers
          </Typography>
          <Typography component="h2" variant="subtitle1" color="secondary">
            1 day
          </Typography>
          <DataGrid
            autoHeight={true}
            rowHeight={40}
            density={'compact'}
            pageSize={4}
            rowsPerPageOptions={[4]}
            pagination
            rows={rowsGainers1d}
            disableSelectionOnClick={true}
            columns={columns}
            loading={loadingGainers1d}
          />
          <CustomAlert id={'asin-gainers-1d'} />
          <Typography component="h2" variant="subtitle1" color="secondary">
            7 days
          </Typography>
          <DataGrid
            autoHeight={true}
            rowHeight={40}
            density={'compact'}
            pageSize={4}
            rowsPerPageOptions={[4]}
            pagination
            rows={rowsGainers7d}
            disableSelectionOnClick={true}
            columns={columns}
            loading={loadingGainers7d}
          />
          <CustomAlert id={'asin-gainers-7d'} />
          <Typography component="h2" variant="subtitle1" color="secondary">
            30 days
          </Typography>
          <DataGrid
            autoHeight={true}
            rowHeight={40}
            density={'compact'}
            pageSize={4}
            rowsPerPageOptions={[4]}
            pagination
            rows={rowsGainers30d}
            disableSelectionOnClick={true}
            columns={columns}
            loading={loadingGainers30d}
          />
          <CustomAlert id={'asin-gainers-30d'} />
        </Grid>
        <Grid item lg={6}>
          <Typography component="h2" variant="h6" color="secondary">
            Losers
          </Typography>
          <Typography component="h2" variant="subtitle1" color="secondary">
            1 day
          </Typography>
          <DataGrid
            autoHeight={true}
            rowHeight={40}
            density={'compact'}
            pageSize={4}
            rowsPerPageOptions={[4]}
            pagination
            rows={rowsLosers1d}
            disableSelectionOnClick={true}
            columns={columns}
            loading={loadingLosers1d}
          />
          <CustomAlert id={'asin-losers-1d'} />
          <Typography component="h2" variant="subtitle1" color="secondary">
            7 days
          </Typography>
          <DataGrid
            autoHeight={true}
            rowHeight={40}
            density={'compact'}
            pageSize={4}
            rowsPerPageOptions={[4]}
            pagination
            rows={rowsLosers7d}
            disableSelectionOnClick={true}
            columns={columns}
            loading={loadingLosers7d}
          />
          <CustomAlert id={'asin-losers-7d'} />
          <Typography component="h2" variant="subtitle1" color="secondary">
            30 days
          </Typography>
          <DataGrid
            autoHeight={true}
            rowHeight={40}
            density={'compact'}
            pageSize={4}
            rowsPerPageOptions={[4]}
            pagination
            rows={rowsLosers30d}
            disableSelectionOnClick={true}
            columns={columns}
            loading={loadingLosers30d}
          />
          <CustomAlert id={'asin-losers-30d'} />
        </Grid>
      </Grid>
    </Paper>
  );
}

export {TopAsinsWidget};
