import {useEffect, useState} from 'react';
import {ButtonGroup, Button, Grid, Checkbox, Typography} from '@mui/material';
// components
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import PageTitle from '../PageTitle/PageTitle';
// utils
import {onFromToChange} from '../../utils/OnFromToChange';
import {rangeData} from './rangeData';
// styles
import {useStyles} from './Styles';

interface DateRangeButtonsProps {
  className?: string;
  loading?: boolean;
  compare?: boolean;
  loadData?: any;
  fromTo: any;
  setFromTo: any;
  period?: string;
  setPeriod?: any;
  showButton?: boolean;
  buttonOnClick?: any;
  compareComponent?: boolean;
  compareOnChange?: any;
  pageTitle?: string;
  pageTitleComponent?: any;
}

export default function DateRangeButtons({
  className,
  loading,
  compare,
  loadData,
  fromTo,
  setFromTo,
  period,
  setPeriod,
  showButton,
  buttonOnClick,
  compareComponent,
  compareOnChange,
  pageTitle,
  pageTitleComponent,
}: DateRangeButtonsProps) {
  const classes = useStyles();
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const currentDate = Object.keys(rangeData).find((key) => {
    const from = rangeData[key]?.from?.format('YYYY-MM-DD');
    const to = rangeData[key]?.to?.format('YYYY-MM-DD');
    return from === fromTo?.from && to === fromTo?.to;
  });

  useEffect(() => {
    if (currentDate) {
      setPeriod && setPeriod(rangeData[currentDate].value);
    } else {
      setPeriod && setPeriod('custom');
    }
  }, [currentDate, setPeriod, fromTo]);

  return (
    <Grid container spacing={2} className={classes.pageHeader}>
      <Grid item lg={pageTitle || pageTitleComponent ? 9 : 12} xs={12}>
        <ButtonGroup
          size={'small'}
          variant={'outlined'}
          color={'primary'}
          disabled={loading}
          fullWidth
          style={{marginBottom: '20px'}}
          className={`${className} ${classes.buttonGroup} new-group`}
        >
          {Object.entries(rangeData).map(([index, item]: any) => {
            return (
              <Button
                key={index}
                variant={period === item.value ? 'contained' : 'outlined'}
                color={'primary'}
                onClick={() => {
                  if (item.value !== 'custom') {
                    onFromToChange({
                      from: item.from,
                      to: item.to,
                      cFrom: item.compareFrom,
                      cTo: item.compareTo,
                      preset: item.value,
                      compare: compare,
                      loadData: loadData && loadData,
                      setFromTo: setFromTo && setFromTo,
                    });
                  } else {
                    setShowDateRangePicker((prev) => !prev);
                  }
                  setPeriod && setPeriod(item.value);
                }}
              >
                {item.label}
              </Button>
            );
          })}
        </ButtonGroup>

        {fromTo.to && fromTo.from && (
          <Grid container style={{display: 'flex', alignItems: 'center'}}>
            {compareComponent && (
              <Grid item xs={12} lg={12} style={{fontSize: '13px', marginBottom: '10px'}}>
                <Typography variant="overline" display="block" color="secondary">
                  <Checkbox size="small" checked={compare} onChange={(e) => compareOnChange?.(e)} /> Compare to previous
                </Typography>
              </Grid>
            )}
            <DateRangePicker
              width="450px"
              disabled={loading}
              from={fromTo.from}
              open={showDateRangePicker}
              setOpen={setShowDateRangePicker}
              to={fromTo.to}
              onChange={(x) => {
                setPeriod && setPeriod('custom');
                onFromToChange({
                  from: x.selection?.startDate,
                  to: x.selection?.endDate,
                  cFrom: fromTo.compareFrom,
                  cTo: fromTo.compareTo,
                  preset: 'custom',
                  compare: compare,
                  loadData: loadData && loadData,
                  setFromTo: setFromTo && setFromTo,
                });
              }}
            />
            {showButton && (
              <Grid item xs={12} lg={3}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{margin: '0 10px'}}
                  onClick={buttonOnClick}
                >
                  Apply Filters
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {pageTitle && (
        <Grid item lg={3} xs={12} className="page-title">
          <PageTitle variant="h2" component="h5" gutterBottom fontSize="24px">
            {pageTitle}
          </PageTitle>
        </Grid>
      )}
      {pageTitleComponent && (
        <Grid item lg={3} xs={12} className="page-title">
          {pageTitleComponent}
        </Grid>
      )}
    </Grid>
  );
}
