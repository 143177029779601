export const marketplaces = [
  {
    id: 'A2Q3Y263D00KWC',
    name: 'Brazil',
  },
  {
    id: 'A2EUQ1WTGCTBG2',
    name: 'Canada',
  },
  {
    id: 'A1AM78C64UM0Y8',
    name: 'Mexico',
  },
  {
    id: 'ATVPDKIKX0DER',
    name: 'United States',
  },
  {
    id: 'A2VIGQ35RCS4UG',
    name: 'United Arab Emirates (U.A.E.)\t',
  },
  {
    id: 'A1PA6795UKMFR9',
    name: 'Germany',
  },
  {
    id: 'ARBP9OOSHTCHU',
    name: 'Egypt',
  },
  {
    id: 'A1RKKUPIHCS9HS',
    name: 'Spain',
  },
  {
    id: 'A13V1IB3VIYZZH',
    name: 'France',
  },
  {
    id: 'A1F83G8C2ARO7P',
    name: 'United Kingdom',
  },
  {
    id: 'A21TJRUUN4KGV',
    name: 'India',
  },
  {
    id: 'APJ6JRA9NG5V4',
    name: 'Italy',
  },
  {
    id: 'A1805IZSGTT6HS',
    name: 'Netherlands',
  },
  {
    id: 'A1C3SOZRARQ6R3',
    name: 'Poland',
  },
  {
    id: 'A17E79C6D8DWNP',
    name: 'Saudi Arabia',
  },
  {
    id: 'A2NODRKZP88ZB9',
    name: 'Sweden',
  },
  {
    id: 'A33AVAJ2PDY3EV',
    name: 'Turkey',
  },
  {
    id: 'A19VAU5U5O7RUS',
    name: 'Singapore',
  },
  {
    id: 'A39IBJ37TRP1C6',
    name: 'Australia',
  },
  {
    id: 'A1VC38T7YXB528',
    name: 'Japan',
  },
];
