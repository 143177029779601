import {useEffect, useState, useContext} from 'react';
import moment from 'moment';
import {CSVLink} from 'react-csv';
import {
  Grid,
  IconButton,
  Tooltip,
  FormGroup,
  FormControlLabel,
  TextField,
  Typography,
  Link,
  FormControl,
  RadioGroup,
  Radio,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  CircularProgress,
  FormHelperText,
  Checkbox,
  Autocomplete,
  LinearProgress,
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridPagination,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';
import {Autocomplete as AutocompleteFM} from 'formik-mui';
import {Field, Form, Formik} from 'formik';
import {useLocation} from 'react-router-dom';
import {Close} from '@mui/icons-material';
// utils
import {Api, errorAlert} from '../../utils/api';
import {Role} from '../../utils/role';
import {rowsPerPageOptions} from '../../utils/constants';
import {marketplaces} from '../Performance/utils/marketplaces';
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../utils/tableSettings';
// services
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
// components
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import Button from '../../components/Button/Button';
import DropDownButton from '../../components/DropdownButton/DropDownButton';
import DraggableList from '../../components/DraggableList/DraggableList';
import Icons from '../../components/Icons/Icons';
import {CustomAlert} from '../Login/components/CustomAlert';
// context
import {AppContext} from '../../context/AppContext/AppContext';
import {getSubBrands} from '../../utils/getSubBrands';

interface AlertTableProps {
  alertType: string;
  classes: any;
}

interface selectedAlertItem {
  id: string;
  collectionName: string;
}

const classificationAll = {
  department: 'All',
};

const visibleTypeLabels = ['Visible', 'Hidden'];

const urlArrayValues = ['alertVisibleTypes', 'brands', 'alertTypes', 'alertSubTypes', 'subBrands'];
const parentLabelForAdditionAMAlert = 'Additional AM AlertType';
const additionalAlertSubTypesForAM = [
  'Lost Best Seller Badge',
  'No Cart Button',
  'Product Listing Suspension',
  'marketplace Suspension',
  'Adult-Flag',
  'Out of Stock (Alert)',
  'Low on Stock (Alert)',
  'Search Result Suppression',
  'Star Rating Dropped',
  'Review Count Drop',
  'Lost Amazon Choice Badge',
  'Lost Prime Badge',
  'Prime Badge Recovered',
  'Search Result Suppression Lifted',
];

export default function AlertsTable({alertType, classes}: AlertTableProps) {
  const location = useLocation();
  const [loadingData, setLoadingData] = useState(false);
  const [fromTo, setFromTo] = useState({
    from: moment().subtract(1, 'days').startOf('day').valueOf(),
    to: moment().valueOf(),
  });
  const [classifications, setClassifications] = useState([]);
  const [classificationValue, setClassificationValue] = useState(classificationAll.department);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [count, setCount] = useState(0);
  const [updatingVisible, setUpdatingVisible] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [selectedAlerts, setSelectedAlerts] = useState({visible: [], hidden: []});
  const [togglingRowId, setTogglingRowId] = useState(null);
  const [filterModel, setFilterModel] = useState(null);
  const [sortModel, setSortModel] = useState([{field: 'dateTime', sort: 'desc'}]);
  const [accountManager, setAccountManager] = useState({id: -1, name: null, email: null});
  const [selectedBrands, setSelectedBrands] = useState(null);
  const [selectedMP, setSelectedMP] = useState([]);
  const [selectedAlertTypes, setSelectedAlertTypes] = useState([]);
  const [alertTypes, setAlertTypes] = useState([]);
  const [filteredAlertTypes, setFilteredAlertTypes] = useState([]);
  const [selectedAlertSubTypes, setSelectedAlertSubTypes] = useState([]);
  const [alertSubTypes, setAlertSubTypes] = useState([]);
  const [filteredAlertSubTypes, setFilteredAlertSubTypes] = useState([]);
  const [alertVisibleTypes, setAlertVisibleTypes] = useState(visibleTypeLabels.filter((item) => item !== 'Hidden'));
  const [values, setValues] = useState({
    cu_space_id: null,
    cu_folder_id: null,
    cu_list_id: null,
    cu_user_id: null,
  });

  const [params, setParams] = useState({
    am_email: null,
    from: fromTo.from,
    to: fromTo.to,
    page: 0,
    pageSize: pageSize,
    classification: null,
    alertVisibleTypes,
    sortBy: sortModel[0]?.sort || null,
    sortOrder: sortModel[0]?.field || null,
    filterField: null,
    filterValue: null,
    filterOperator: null,
    brands: [],
    alertTypes: [],
    alertSubTypes: [],
    subBrands: [],
    marketplaces: [],
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [alertDetailModalOpen, setAlertDetailModalOpen] = useState(false);
  const [alertDetail, setAlertDetail] = useState(null);
  const [cuSpaces, setCuSpaces] = useState([]);
  const [folderLoadingData, setFolderLoadingData] = useState(false);
  const [cuFolders, setCuFolders] = useState([]);
  const [cuFolderLessLists, setCuFolderLessLists] = useState([]);
  const [cuLists, setCuLists] = useState([]);
  const [cuUsers, setCuUsers] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedList, setSelectedList] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalActiveRow, setModalActiveRow] = useState(null);
  const [creatingTask, setCreatingTask] = useState(false);
  const [isPostToUrgent, setIsPostToUrgent] = useState(false);
  const [subBrands, setSubBrands] = useState([]);
  const [selectedSubBrands, setSelectedSubBrands] = useState(null);
  const [orderColumns, setOrderColumns] = useState([]);
  const [visibilityModel, setVisibilityModel] = useState<any>({});
  const [tableLoading, setTableLoading] = useState(false);

  const {accountManagers, brands} = useContext(AppContext);

  let defaultQuery;

  function getDefaultQuery() {
    const urlParams = new URLSearchParams(location.search);
    let query = Object({});
    Object.keys(params).forEach((key) => {
      switch (key) {
        case 'from':
          query[key] = urlParams.get(key) ? urlParams.get(key) : moment().subtract(1, 'days').startOf('day').valueOf();
          break;
        case 'to':
          query[key] = urlParams.get(key) ? urlParams.get(key) : moment().valueOf();
          break;
        case 'am_email':
          if (urlParams.get(key)) {
            query[key] = urlParams.get(key);
          } else {
            query[key] = accountService.userValue.email;
          }
          break;
        case 'page':
        case 'pageSize':
          if (key === 'pageSize' && !urlParams.get(key)) {
            query[key] = pageSize;
          } else query[key] = Number(urlParams.get(key));
          break;
        case 'alertVisibleTypes':
          if (urlParams.get(key)) {
            query[key] = urlParams.get(key).split(',');
            setAlertVisibleTypes(query[key]);
          } else {
            query[key] = alertVisibleTypes;
          }
          break;
        case 'brands':
        case 'subBrands':
        case 'alertTypes':
        case 'alertSubTypes':
          query[key] = urlParams.get(key) && urlParams.get(key) !== '' ? urlParams.get(key).split(',') : [];
          break;
        case 'classification':
          query[key] = urlParams.get(key) ? urlParams.get(key) : classificationAll.department;
          break;
        case 'marketplaces':
          query[key] = urlParams.get(key) ? urlParams.get(key)?.split(',') : [];
          break;
        default:
          query[key] = urlParams.get(key);
          break;
      }
    });
    setPage(query['page']);

    if ('marketplaces' in query) {
      const activeMP = marketplaces.filter((item) => query.marketplaces?.includes(item.id));
      setSelectedMP(activeMP);
    }
    if ('brands' in query) {
      pullSubBrands(query.brands);
    }
    return query;
  }

  function updateQueryParams() {
    let search = new URLSearchParams(location.search);
    Object.keys(params).forEach((key) => {
      if (key === 'sortBy' || key === 'sortOrder' || key === 'page' || key === 'pageSize') {
        search.delete(key);
      } else if (urlArrayValues.includes(key)) {
        if (!params[key] || params[key].length === 0) {
          search.delete(key);
        } else {
          search.set(key, String(params[key]));
        }
      } else {
        if (params[key] !== null) {
          search.set(key, String(params[key]));
        } else {
          if (key === 'am_email') search.set(key, String(params[key]));
          else search.delete(key);
        }
      }
    });

    if (filterModel) {
      search.set('filterField', filterModel?.columnField);
      search.set('filterValue', filterModel?.value);
    }
    const newUrl =
      window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + search.toString();
    window.history.pushState({path: newUrl}, '', newUrl);
  }

  useEffect(() => {
    // getCuSpaces();
    // getCuUsers();
    defaultQuery = getDefaultQuery();
    getClassifications(defaultQuery);
    getAlertTypes(defaultQuery);
    setParams(defaultQuery);
    setFromTo({from: Number(defaultQuery.from), to: Number(defaultQuery.to)});
    loadData(defaultQuery);
  }, []);

  useEffect(() => {
    defaultQuery = getDefaultQuery();
    if (accountManagers.length > 0) {
      getAccountManagers(defaultQuery);
    }
  }, [accountManagers]);

  useEffect(() => {
    updateQueryParams();
  }, [
    fromTo,
    classificationValue,
    accountManager,
    selectedBrands,
    selectedSubBrands,
    selectedAlertTypes,
    selectedAlertSubTypes,
    alertVisibleTypes,
    filterModel,
    selectedMP,
  ]);

  async function getCuSpaces() {
    try {
      const {data} = await Api.get(`clickup/spaces`);
      setCuSpaces(data);
    } catch (e) {
      errorAlert('Unable to get clickup spaces', e);
    }
  }

  async function getCuFolders(spaceId) {
    setFolderLoadingData(true);
    try {
      const {data} = await Api.get(`clickup/folders/${spaceId}`);
      setCuFolders(data.folders);
      if (data.folderLessLists.length > 0) {
        setCuFolderLessLists(data.folderLessLists);
        setCuLists(data.folderLessLists);
      }
    } catch (e) {
      errorAlert('Unable to get clickup folders', e);
    } finally {
      setFolderLoadingData(false);
    }
  }

  async function getCuUsers() {
    try {
      const {data} = await Api.get(`clickup/users`);
      setCuUsers(data.filter((user) => user.username));
    } catch (e) {
      errorAlert('Unable to get clickup users', e);
    }
  }

  async function getClassifications(query) {
    try {
      const {data} = await Api.get(`classifications`);
      data.unshift(classificationAll);
      setClassifications(data);
      setClassificationValue(query.classification);
    } catch (e) {
      errorAlert('Unable to get classifications', e);
    }
  }

  async function getAlertTypes(query) {
    try {
      const {data} = await Api.get(`alerts/alert-types`);
      const resAlertSubTypes = [];
      const resAlertTypes = data.map((item) => {
        for (const record of item.subTypes) {
          if (record?.value && !resAlertSubTypes.find((item) => item.name === record.value)) {
            resAlertSubTypes.push({
              name: record.value,
              parent: item._id,
            });
          }
        }
        return item._id;
      });
      setAlertTypes(resAlertTypes);
      setFilteredAlertTypes(resAlertTypes);
      setFilteredAlertSubTypes(resAlertSubTypes);
      for (const subType of additionalAlertSubTypesForAM) {
        resAlertSubTypes.push({
          name: subType,
          parent: parentLabelForAdditionAMAlert,
        });
      }
      setAlertSubTypes(resAlertSubTypes);
    } catch (e) {
      errorAlert('Unable to get alert types', e);
    }
  }

  function getAccountManagers(query) {
    if ([Role.Admin].includes(accountService.userValue.role)) {
      let currentUser = accountManager;
      if (query.am_email) {
        currentUser = accountManagers.find((manager) => manager.email === query.am_email);
        setAccountManager(currentUser);
      }
    }
  }

  function isHide(row) {
    if (classificationValue === 'All') {
      return row.hiddenFromTabs && row.hiddenFromTabs.length > 0 && row.hiddenFromTabs.length === classifications.filter((x) => x.department !== 'All').length;
    } else {
      return (
        row.hiddenFromTabs &&
        (row.hiddenFromTabs.indexOf(classificationValue) > -1 ||
          row.hiddenFromTabs.indexOf(classificationAll.department) > -1)
      );
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'alertType',
      headerName: 'Alert Type',
      width: 130,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.alertType}>
          <span className="table-cell-trucate">{params.row.alertType}</span>
        </Tooltip>
      ),
    },
    {
      field: 'alertSubType',
      headerName: 'Alert Sub Type',
      width: 150,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.alertSubType}>
          <span className="table-cell-trucate">{params.row.alertSubType}</span>
        </Tooltip>
      ),
    },
    {
      field: 'productImageURL',
      headerName: 'Photo',
      width: 130,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <img
          src={params.row.productImageURL}
          style={{maxWidth: '35px', maxHeight: '35px'}}
          alt={params.row?.productName || ''}
        />
      ),
    },
    {
      field: 'productName',
      headerName: 'Title',
      minWidth: 320,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row?.productName || ''} hidden={!params.row.productName}>
          <span className="table-cell-trucate">{params.row?.productName}</span>
        </Tooltip>
      ),
    },
    {
      field: 'message',
      headerName: 'Message',
      minWidth: 600,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row?.message || ''} hidden={!params.row.message}>
          <span className="message-cell">{params.row?.message}</span>
        </Tooltip>
      ),
    },
    {
      field: 'asin',
      headerName: 'ASIN',
      width: 150,
      renderCell: (params: GridCellParams) => {
        if (params.row.asin) {
          const href = `https://www.amazon.com/${params.row.sku ? params.row.sku + '/' : ''}dp/${params.row.asin}`;
          return (
            <Typography>
              <Link href={href} target="_blank" rel="noreferrer noopener">
                {params.row.asin}
              </Link>
            </Typography>
          );
        } else return <></>;
      },
    },
    {field: 'brand', headerName: 'Brand', width: 150, filterable: false},
    {
      field: 'marketplace',
      headerName: 'MarketPlace',
      width: 120,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridCellParams) => {
        return (
          <Typography className={params.row.marketplace && classes.iconFlag}>
            {params.row.marketplace ? <Icons name={params.row.marketplace.toUpperCase()} tooltip /> : 'N/A'}
          </Typography>
        );
      },
    },

    {
      field: 'dateTime',
      headerName: 'Date',
      width: 120,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.dateTime}>
          <span className="table-cell-truncate">{moment(params.row.dateTime).format('YYYY-MM-DD')}</span>
        </Tooltip>
      ),
    },
  ];

  if (accountService.userValue.role !== Role.BrandUser) {
    columns.unshift({
      headerName: 'Action',
      field: 'button',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridCellParams) => (
        <Box display="flex" alignItems="center" flexDirection="column" fontSize={12}>
          {/* {params.row.cuTaskUrl ? (
            <Typography variant="caption">
              <Link href={params.row.cuTaskUrl} target="_blank" rel="noopener noreferrer">
                <Button className={'textButton'} color="primary" size="small" variant="text">
                  View CU Task
                </Button>
              </Link>
            </Typography>
          ): (
            <Button variant="text" onClick={() => onCreateCUTask(params.row)} className={'textButton'} size="small">
              Create CU task
            </Button>
          )} */}
          <Typography variant="caption">
            {params.row.sentryKitURL && (
              <Link href={params.row.sentryKitURL} target="_blank" rel="noopener noreferrer">
                <Button className={'textButton'} color="primary" size="small" variant="text">
                  View Sentry Kit
                </Button>
              </Link>
            )}
          </Typography>
          <Typography variant="caption">
            <Button
              onClick={() => onViewAlert(params.row)}
              className={'textButton'}
              color="primary"
              size="small"
              variant="text"
            >
              View Alert
            </Button>
          </Typography>
          <Typography variant="caption">
            <Button
              onClick={() => hideAlert(params.row)}
              className={'textButton'}
              color={isHide(params.row) ? 'primary' : 'inherit'}
              size="small"
              disabled={loadingData || updatingVisible}
              variant="text"
            >
              {isHide(params.row) ? 'Show Alert' : 'Hide Alert'}
              {updatingVisible && params.row._id === togglingRowId && (
                <CircularProgress size={12} style={{color: 'blue'}} />
              )}
            </Button>
          </Typography>
        </Box>
      ),
    });
  }

  const csvHeaders = columns
    .filter((column) => column.field !== 'actions')
    .map((column) => {
      return {
        key: column.field,
        label: column.headerName,
      };
    });

  async function loadData(queryParams = params) {
    if (queryParams.am_email === 'null') delete queryParams.am_email;
    setLoadingData(true);
    let active = true;

    try {
      const {data} = await Api.get(`alerts`, {
        params: queryParams,
      });
      if (!active) return;
      setCount(data.count);
      const gridRows = data.rows.map((x, i) => {
        x.dateTime = moment(new Date(x.dateTime)).format('YYYY-MM-DD hh:mm A');
        return {id: i, ...x};
      });
      setRows(gridRows);
      if (alertVisibleTypes.length === 1) {
        const _selectedIndexes = gridRows
          .filter(
            (row) => selectedAlerts[alertVisibleTypes[0].toLowerCase()].findIndex((item) => item.id === row._id) > -1
          )
          .map((row) => row.id);
        setSelectedIndexes(_selectedIndexes);
      } else setSelectedIndexes([]);
    } catch (e) {
      errorAlert('Unable to get alerts', e, {id: 'data-grid-alert'});
    } finally {
      setLoadingData(false);
    }
  }

  function onFromToChange(from, to) {
    const fromValue = moment(from).startOf('day').valueOf();
    const toValue = moment(to).endOf('day').valueOf();
    setFromTo({from: fromValue, to: toValue});
    let queryParams = getUpdatedParamsState('from', fromValue);
    queryParams = getUpdatedParamsState('to', toValue);
    loadData(queryParams);
  }

  async function hideAlert(record) {
    setTogglingRowId(record._id);
    setUpdatingVisible(true);
    try {
      await Api.post('alerts/hide-alert', {
        alertId: record._id,
        alertCollection: record.collection,
        tabName: classificationValue,
      });
      alertService.success(`Alert hidden from tab ${classificationValue}`, {id: 'data-grid-alert'});
      setRows(rows.filter((row) => record._id !== row._id));
      setCount((prev) => prev - 1);
    } catch (e) {
      errorAlert('Something went wrong hiding the alert. Please try again later', e, {id: 'data-grid-alert'});
    } finally {
      setTogglingRowId(null);
      setUpdatingVisible(false);
    }
  }

  function handleChangePage(params: any) {
    setSelectedIndexes([]);
    setPage(params);
    loadData(getUpdatedParamsState('page', params));
  }

  function handleChangePageSize(params: any) {
    setPageSize(params);
    loadData(getUpdatedParamsState('pageSize', params));
  }

  async function handleHideAlerts() {
    if (alertVisibleTypes.length !== 1) {
      alertService.error('Hide/Show Action is available on one option of Visible or Hidden.', {id: 'data-grid-alert'});
    }
    setUpdatingVisible(true);
    try {
      const {data} = await Api.post('alerts/hide-alerts', {
        alerts: selectedAlerts[alertVisibleTypes[0].toLowerCase()],
        tabName: classificationValue,
      });
      const _selectedAlerts = selectedAlerts;
      _selectedAlerts[alertVisibleTypes[0].toLowerCase()] = _selectedAlerts[alertVisibleTypes[0].toLowerCase()].filter(
        (alertItem: selectedAlertItem) => !data.includes(alertItem.id)
      );
      setSelectedAlerts(_selectedAlerts);
      setRows(rows.filter((row) => !data.includes(row._id)));
      setCount(count - data.length);
      loadData(params);
      alertService.success(
        `Set ${data.length} Alerts as [${visibleTypeLabels.find(
          (item) => item !== alertVisibleTypes[0]
        )}] on tab ${classificationValue}`,
        {id: 'data-grid-alert'}
      );
    } catch (e) {
      errorAlert('Something went wrong hiding the alert. Please try again later', e, {id: 'data-grid-alert'});
    } finally {
      setUpdatingVisible(false);
    }
  }

  function handleChangeAccountManager(accountManagerObj: any) {
    setAccountManager(accountManagerObj);
    loadData(getUpdatedParamsState('am_email', accountManagerObj?.email || null));
  }

  function handleChangeClassificationValue(event) {
    const departmentStr = event.target.value;
    setClassificationValue(departmentStr);
    let queryParams = getUpdatedParamsState('classification', departmentStr);
    queryParams = getUpdatedParamsState('alertType', null);
    queryParams = getUpdatedParamsState('alertSubType', null);
    updateTypesByClassification(departmentStr);
    loadData(queryParams);
  }

  function updateTypesByClassification(departmentStr) {
    if (departmentStr === classificationAll.department) {
      setFilteredAlertTypes(alertTypes);
      setSelectedAlertTypes(null);
      setFilteredAlertSubTypes(alertSubTypes);
      setSelectedAlertSubTypes([]);
    } else {
      const cAlerts = classifications.find((item) => item.department === departmentStr)?.alerts;
      const CFilteredAlertTypes = [];
      const cFilteredAlertSubTypes = alertSubTypes.filter(
        (item) =>
          cAlerts.indexOf(item.name) !== -1 || (departmentStr === 'AM' && item.parent === parentLabelForAdditionAMAlert)
      );
      cFilteredAlertSubTypes.forEach((item) => {
        if (CFilteredAlertTypes.indexOf(item.parent) === -1) CFilteredAlertTypes.push(item.parent);
      });
      setFilteredAlertTypes(CFilteredAlertTypes);
      setSelectedAlertTypes(null);
      setFilteredAlertSubTypes(cFilteredAlertSubTypes);
      setSelectedAlertSubTypes([]);
    }
  }

  async function pullSubBrands(brandCodes: string[]) {
    const data = await getSubBrands(brandCodes);
    if (data) {
      setSubBrands(data);
    }
  }

  function handleChangeBrand(brands) {
    if (brands) {
      pullSubBrands(brands);
    }
    setSelectedBrands(brands);
    const queryParams = getUpdatedParamsState('brands', brands || []);
    loadData(queryParams);
  }

  function handleChangeSubBrand(subBrands) {
    setSelectedSubBrands(brands);
    const queryParams = getUpdatedParamsState('subBrands', subBrands || []);
    loadData(queryParams);
  }

  function handleChangeAlertTypes(selectedAlertTypes: string[]) {
    if (selectedAlertTypes && selectedAlertTypes.length > 0) {
      const filteredSubTypes = alertSubTypes.filter(
        (item) =>
          selectedAlertTypes.includes(item.parent) ||
          (classificationValue === 'AM' && item.parent === parentLabelForAdditionAMAlert)
      );
      setFilteredAlertSubTypes(filteredSubTypes);
      setSelectedAlertTypes(selectedAlertTypes);
      setSelectedAlertSubTypes([]);
    } else {
      updateTypesByClassification(classificationValue);
    }
    let queryParams = getUpdatedParamsState('alertTypes', selectedAlertTypes);
    queryParams = getUpdatedParamsState('alertSubTypes', null);
    loadData(queryParams);
  }

  function handleChangeAlertSubTypes(alertSubTypes: string[]) {
    setSelectedAlertSubTypes(alertSubTypes);
    loadData(getUpdatedParamsState('alertSubTypes', alertSubTypes || []));
  }

  function handleChangeMarketPlace(mpObj: any) {
    setSelectedMP(mpObj);
    const selectedMp = mpObj.map((i) => i.id);
    loadData(getUpdatedParamsState('marketplaces', selectedMp || null));
  }

  function handleVisibleTypeChange(label, value) {
    const _alertVisibleTypes = value ? [...alertVisibleTypes, label] : alertVisibleTypes.filter((x) => x !== label);
    const queryParams = getUpdatedParamsState('alertVisibleTypes', _alertVisibleTypes);
    setAlertVisibleTypes(_alertVisibleTypes);
    loadData(queryParams);
  }

  function handleChangeFilter(filter) {
    const filterObj = filter.items[0];
    setFilterModel(filterObj);
    let queryParams = getUpdatedParamsState('filterField', filterObj?.columnField || null);
    queryParams = getUpdatedParamsState('filterValue', filterObj?.value || null);
    queryParams = getUpdatedParamsState('filterOperator', filterObj?.operatorValue || null);
    loadData(queryParams);
  }

  function handleChangeSort(dgParams) {
    const sortObj = dgParams;
    let queryParams = getUpdatedParamsState('sortBy', sortObj[0]?.field || null);
    queryParams = getUpdatedParamsState('sortOrder', sortObj[0]?.sort || null);
    loadData(queryParams);
  }

  function handleSelectAlerts(rowIdxes: number[]) {
    const gridSelectedAlerts = rows
      .filter((row) => rowIdxes.indexOf(row.id) > -1)
      .map((row) => ({id: row._id, collectionName: row.collection}));

    const _selectedAlerts = selectedAlerts;
    const alertVisibleType = alertVisibleTypes[0].toLowerCase();
    const selectedAlertsFromOtherPage = _selectedAlerts[alertVisibleType].filter(
      (alertItem: any) => rows.findIndex((row) => row._id === alertItem.id) === -1
    );
    _selectedAlerts[alertVisibleType] = selectedAlertsFromOtherPage.concat(gridSelectedAlerts);
    setSelectedAlerts(_selectedAlerts);
    setSelectedIndexes(rowIdxes);
  }

  function getUpdatedParamsState(key, value) {
    const queryParams = params;
    if (key !== 'page') {
      queryParams['page'] = 0;
      setPage(0);
    }
    queryParams[key] = value;
    setParams(queryParams);
    return queryParams;
  }

  function handleChangeSpace(spaceObj) {
    setSelectedSpace(spaceObj);
    setSelectedFolder(null);
    setSelectedList(null);

    if (spaceObj) {
      getCuFolders(spaceObj?.id || null);
      setValues({
        cu_space_id: spaceObj?.id,
        cu_folder_id: selectedFolder?.id,
        cu_list_id: selectedList?.id,
        cu_user_id: selectedUser?.id,
      });
    } else {
      setCuFolders([]);
      setCuFolderLessLists([]);
      setCuLists([]);
    }
  }

  function handleChangeFolder(folderObj) {
    setSelectedFolder(folderObj);
    setSelectedList(null);

    if (folderObj) {
      setCuLists(folderObj?.lists || []);
      setValues({
        cu_space_id: selectedSpace?.id,
        cu_folder_id: folderObj?.id,
        cu_list_id: selectedList?.id,
        cu_user_id: selectedUser?.id,
      });
    } else {
      setCuLists(cuFolderLessLists);
    }
  }

  function handleChangeList(listObj) {
    setSelectedList(listObj);
    setValues({
      cu_space_id: selectedSpace?.id,
      cu_folder_id: selectedFolder?.id,
      cu_list_id: listObj?.id,
      cu_user_id: selectedUser?.id,
    });
  }

  function handleChangeUser(userObj) {
    setSelectedUser(userObj);
    setValues({
      cu_space_id: selectedSpace?.id,
      cu_folder_id: selectedFolder?.id,
      cu_list_id: selectedList?.id,
      cu_user_id: userObj?.id,
    });
  }

  function onCreateCUTask(row) {
    row.dateTime = moment(row.dateTime).valueOf();
    setModalActiveRow(row);
    setModalOpen(true);
  }

  function onClose() {
    setCuFolders([]);
    setCuLists([]);
    setCuFolderLessLists([]);
    setSelectedSpace(null);
    setSelectedFolder(null);
    setSelectedList(null);
    setSelectedUser(null);
    setModalActiveRow(null);
    setModalOpen(false);
    setIsPostToUrgent(false);
  }

  function onViewAlert(row) {
    setAlertDetail(row);
    setAlertDetailModalOpen(true);
  }

  function onAlertDetailModalClose() {
    setAlertDetail(null);
    setAlertDetailModalOpen(false);
  }

  async function onSubmit() {
    setCreatingTask(true);
    try {
      await Api.post(`clickup/create_task`, {
        alert: modalActiveRow,
        list_id: selectedList?.id,
        assignee_id: selectedUser?.id,
        isPostToUrgent,
      });
      loadData();
      alertService.success('Clickup Task created successfully');
    } catch (e) {
      errorAlert('Error creating Clickup Task', e);
    } finally {
      setCreatingTask(false);
      onClose();
    }
  }

  useEffect(() => {
    async function getTableData() {
      const data = await getUserPreferences({
        list: columns,
        tableName: 'alerts',
        visibilityModel: visibilityModel,
        defaultVisibilityModel: {},
        loading: setTableLoading,
      });
      if (data) {
        setOrderColumns(data.columns);
        setVisibilityModel(data.visibility);
      }
    }
    getTableData();
  }, []);

  const DataGridToolbar = () => (
    <GridToolbarContainer className="toolbar-container">
      <GridToolbarColumnsButton className={classes.toolbarButton} disabled={loadingData || tableLoading} />
      <DropDownButton buttonType="text" buttonText="Order Columns" bold loading={loadingData || tableLoading}>
        <DraggableList
          items={orderColumns}
          setItems={(v) => {
            setOrderColumns(v);
            setUserPreferences(
              {
                columnVisibilityModel: visibilityModel,
                columnsOrder: v.map((x) => x.field),
              },
              'alerts',
              setTableLoading
            );
          }}
        />
      </DropDownButton>
      <GridPagination />
    </GridToolbarContainer>
  );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', width: '100%'}}>
              {fromTo.to && fromTo.from && (
                <DateRangePicker
                  width="500px"
                  from={fromTo.from}
                  to={fromTo.to}
                  onChange={(x) => {
                    onFromToChange(x.selection?.startDate, x.selection?.endDate);
                  }}
                />
              )}
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign: 'center'}}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="classification"
                value={classificationValue}
                onChange={handleChangeClassificationValue}
                className={classes.radioGroup}
              >
                {classifications.map((item) => (
                  <FormControlLabel
                    key={item.department}
                    value={item.department}
                    control={<Radio size="small" />}
                    label={item.department}
                    disabled={loadingData}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={{lg: 1.5, md: 1.5, sm: 1, xs: 1}} columnSpacing={1}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Autocomplete
              size="small"
              multiple
              options={brands.map((x) => x.brand_code)}
              getOptionLabel={(option) => {
                const brandName = brands.find((x) => x.brand_code === option)?.name;
                return brandName ? `${option} -  ${brandName}` : option;
              }}
              value={params.brands ? params.brands : []}
              disabled={loadingData}
              onChange={(event, newValue) => {
                handleChangeBrand(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Filter by brands" placeholder="Brands" />
              )}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Autocomplete
              size="small"
              multiple
              options={subBrands.map((x) => x.brand_code)}
              getOptionLabel={(option) => {
                const brandName = subBrands.find((x) => x.brand_code === option)?.name;
                return brandName ? `${option} -  ${brandName}` : option;
              }}
              value={params.subBrands ? params.subBrands : []}
              disabled={loadingData}
              onChange={(event, newValue) => {
                handleChangeSubBrand(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Filter by sub brands" placeholder="Sub brands" />
              )}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Autocomplete
              id="alerttype-list"
              size="small"
              multiple
              options={filteredAlertTypes}
              getOptionLabel={(option) => option}
              value={params.alertTypes ? params.alertTypes : []}
              disabled={loadingData}
              onChange={(event, newValue) => {
                handleChangeAlertTypes(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Alert Type" variant="outlined" />}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Autocomplete
              id="alertsubtype-list"
              size="small"
              options={
                params.alertTypes.length > 0
                  ? alertSubTypes.filter((item) => params.alertTypes.includes(item.parent)).map((item) => item.name)
                  : filteredAlertSubTypes.map((item) => item.name)
              }
              getOptionLabel={(option) => option}
              value={params.alertSubTypes ? params.alertSubTypes : []}
              disabled={loadingData}
              multiple
              onChange={(event, newValue) => {
                handleChangeAlertSubTypes(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Alert Sub Type" variant="outlined" />}
            />
          </Grid>

          {[Role.Admin].includes(accountService.userValue.role) && accountManagers.length > 0 && (
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Autocomplete
                size="small"
                options={accountManagers}
                getOptionLabel={(option) => option?.name || option?.email}
                value={accountManager}
                defaultValue={accountManager}
                disabled={loadingData}
                onChange={(event, newValue) => {
                  handleChangeAccountManager(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Account Manager" variant="outlined" />}
              />
            </Grid>
          )}

          <Grid item lg={3} md={3} sm={6} xs={12}>
            {marketplaces.length > 0 && (
              <Autocomplete
                id="marketplace-list"
                multiple
                size="small"
                options={marketplaces}
                getOptionLabel={(option) => option.name}
                value={selectedMP}
                disabled={loadingData}
                onChange={(event, newValue) => {
                  handleChangeMarketPlace(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="MarketPlaces" variant="outlined" />}
              />
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.alertsToolbar}>
              <FormGroup row={true}>
                {accountService.userValue.role !== Role.BrandUser &&
                  visibleTypeLabels.map((x) => (
                    <FormControlLabel
                      key={x}
                      control={
                        <Checkbox
                          checked={alertVisibleTypes.includes(x)}
                          onChange={(e) => handleVisibleTypeChange(x, e.target.checked)}
                          disabled={loadingData || (alertVisibleTypes.includes(x) && alertVisibleTypes.length === 1)}
                        />
                      }
                      label={x}
                    ></FormControlLabel>
                  ))}
              </FormGroup>
              <Grid>
                {accountService.userValue.role !== Role.BrandUser && alertVisibleTypes.length === 1 && (
                  <Button
                    onClick={handleHideAlerts}
                    size="small"
                    disabled={
                      loadingData || selectedAlerts[alertVisibleTypes[0].toLowerCase()].length < 1 || updatingVisible
                    }
                  >
                    {alertVisibleTypes[0] === 'Visible' ? 'Hide' : 'Show'} selected
                    {updatingVisible && <CircularProgress size={12} style={{color: 'blue'}} />}
                  </Button>
                )}
                <CSVLink filename={`${alertType} Alerts.csv`} data={rows} headers={csvHeaders}>
                  <Box ml={2}>
                    <Button size="small" disabled={loadingData}>
                      Export to CSV
                    </Button>
                  </Box>
                </CSVLink>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomAlert id="data-grid-alert" />
      </Grid>
      <Grid item xs={12}>
        {accountService.userValue.role !== Role.BrandUser && alertVisibleTypes.length === 1 && (
          <p>
            {selectedAlerts[alertVisibleTypes[0].toLowerCase()]?.length} {alertVisibleTypes[0]} alerts selected
          </p>
        )}
      </Grid>
      <Grid item xs={12} lg={12} position="relative">
        {(loadingData || tableLoading) && <LinearProgress style={{position: 'absolute', width: '100%'}} />}
        <DataGrid
          components={{
            Pagination: DataGridToolbar,
            Toolbar: DataGridToolbar,
          }}
          className={`${classes.grid} custom-table ${tableLoading ? 'load-headers' : ''}`}
          disableColumnMenu={loadingData}
          autoHeight={true}
          pageSize={pageSize}
          rowCount={count}
          loading={loadingData}
          page={page}
          pagination
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={(params) => handleChangePageSize(params)}
          paginationMode="server"
          onPageChange={(params) => handleChangePage(params)}
          filterMode="server"
          onFilterModelChange={(params) => handleChangeFilter(params)}
          sortingMode="server"
          onSortModelChange={(params) => handleChangeSort(params)}
          disableSelectionOnClick={true}
          checkboxSelection={alertVisibleTypes?.length === 1}
          selectionModel={selectedIndexes}
          onSelectionModelChange={(x: any) => {
            handleSelectAlerts(x);
          }}
          rowHeight={108}
          rows={loadingData ? [] : rows}
          columns={orderColumns}
          columnVisibilityModel={visibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            let data = {};
            Object.entries(newModel).forEach(([x, v]) => {
              if (v === false) {
                data = {...data, [x]: v};
              }
            });
            const newOrder = getColumnsItems({
              list: columns,
              columnsVisibility: data,
              columnsOrder: orderColumns,
              currentOrder: orderColumns,
            });
            setVisibilityModel(data);
            setOrderColumns(newOrder);
            setUserPreferences(
              {
                columnVisibilityModel: data,
                columnsOrder: newOrder.map((x) => x.field),
              },
              'alerts',
              setTableLoading
            );
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Dialog
          open={modalOpen}
          onClose={onClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth="sm"
        >
          <Formik initialValues={values} onSubmit={onSubmit} validateOnChange={true}>
            {({errors, touched, isSubmitting}) => (
              <Form>
                <DialogTitle id="scroll-dialog-title">
                  <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Create ClickUp Task</Box>
                    <Box>
                      <IconButton onClick={onClose} disabled={creatingTask}>
                        <Close />
                      </IconButton>
                    </Box>
                  </Box>
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={1} justifyContent="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Field
                        className={classes.inputField}
                        name="cu_space_id"
                        component={AutocompleteFM}
                        value={selectedSpace}
                        required={true}
                        onChange={(event, newValue) => {
                          handleChangeSpace(newValue);
                        }}
                        size="medium"
                        options={cuSpaces}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="ClickUp Spaces" variant={'outlined'} />}
                      />
                      <FormHelperText error={true}>
                        {touched['cu_space_id'] && !selectedSpace?.id ? 'Space is required.' : ''}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        className={classes.inputField}
                        name="cu_folder_id"
                        component={AutocompleteFM}
                        size="medium"
                        value={selectedFolder}
                        onChange={(event, newValue) => {
                          handleChangeFolder(newValue);
                        }}
                        options={cuFolders}
                        getOptionLabel={(option) => option.name}
                        disabled={cuFolders.length === 0}
                        renderInput={(params) => (
                          <>
                            {folderLoadingData && <CircularProgress size={14} />}
                            {!folderLoadingData && (
                              <TextField
                                {...params}
                                error={touched['cu_folder_id'] && !!errors['cu_folder_id']}
                                helperText={touched['cu_folder_id'] && errors['cu_folder_id']}
                                label="ClickUp Folders"
                                variant={'outlined'}
                                disabled={cuFolders.length === 0}
                              />
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        className={classes.inputField}
                        name="cu_list_id"
                        component={AutocompleteFM}
                        onChange={(event, newValue) => {
                          handleChangeList(newValue);
                        }}
                        value={selectedList}
                        required={true}
                        size="medium"
                        options={cuLists}
                        getOptionLabel={(option) => option.name}
                        disabled={cuLists.length === 0}
                        renderInput={(params) => (
                          <>
                            {folderLoadingData && <CircularProgress size={14} />}
                            {!folderLoadingData && (
                              <TextField
                                {...params}
                                label="ClickUp Lists"
                                variant={'outlined'}
                                disabled={cuLists.length === 0}
                              />
                            )}
                          </>
                        )}
                      />
                      <FormHelperText error={true}>
                        {touched['cu_list_id'] && !selectedList?.id ? 'List is required.' : ''}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        className={classes.inputField}
                        name="cu_user_id"
                        component={AutocompleteFM}
                        onChange={(event, newValue) => {
                          handleChangeUser(newValue);
                        }}
                        value={selectedUser}
                        size="medium"
                        options={cuUsers}
                        getOptionLabel={(option) => option.username}
                        disabled={cuUsers.length === 0}
                        required={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="ClickUp Users"
                            variant={'outlined'}
                            disabled={cuUsers.length === 0}
                          />
                        )}
                      />
                      <FormHelperText error={true}>
                        {touched['cu_user_id'] && !selectedUser?.id ? 'User is required.' : ''}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{color: 'red'}}
                            checked={isPostToUrgent}
                            onChange={() => setIsPostToUrgent(!isPostToUrgent)}
                          />
                        }
                        label={'Also post in #urgent channel?'}
                        name="is_post_to_urgent"
                      ></FormControlLabel>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={creatingTask || !selectedSpace?.id || !selectedUser?.id || !selectedList?.id}
                  >
                    {creatingTask && <CircularProgress size={14} />}
                    {!creatingTask && 'Create'}
                  </Button>
                  <Button onClick={onClose} color="primary" disabled={creatingTask}>
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
          <CustomAlert id="dialog-alert" />
        </Dialog>
        <Dialog
          open={alertDetailModalOpen}
          onClose={onAlertDetailModalClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="alert-detail-dialog-title">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>Alert Detail</Box>
              <Box>
                <IconButton onClick={onAlertDetailModalClose}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1} justifyContent="center">
              {alertDetail &&
                Object.keys(alertDetail).map((key) => {
                  if (
                    key.includes('_id') ||
                    key.includes('id') ||
                    key.includes('createdAt') ||
                    key.includes('updatedAt') ||
                    key.includes('__v')
                  )
                    return null;
                  else if (key.includes('fullPayload'))
                    return (
                      <Grid item xs={12}>
                        <Box fontWeight="fontWeightBold" mb={1}>
                          {key}
                        </Box>
                        <Box>{JSON.stringify(alertDetail[key])}</Box>
                      </Grid>
                    );
                  else
                    return (
                      <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                        <label style={{fontWeight: 'bold'}}>{key}:&nbsp;&nbsp;</label>
                        {key === 'productImageURL' && <img src={alertDetail.productImageURL} alt="No ProductImage" width={256}/>}
                        {key !== 'productImageURL' && <span>{String(alertDetail[key])}</span>}
                      </Grid>
                    );
                })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onAlertDetailModalClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}
