export const brandReportTypes = [
  {
    reportType: 'GET_V1_SELLER_PERFORMANCE_REPORT',
    name: 'Seller Performance Report',
  },
  {
    reportType: 'GET_SALES_AND_TRAFFIC_REPORT_BY_DATE',
    name: 'Sales and Traffic by Date',
  },
  {
    reportType: 'GET_SALES_AND_TRAFFIC_REPORT_BY_ASIN',
    name: 'Sales and Traffic by Asin',
  },
  {
    reportType: 'GET_FBA_FULFILLMENT_CURRENT_INVENTORY_DATA',
    name: 'FBA Fulfillment Current Inventory Data',
  },
  {
    reportType: 'GET_FBA_FULFILLMENT_MONTHLY_INVENTORY_DATA',
    name: 'FBA Fulfillment Monthly Inventory Data',
  },
  {
    reportType: 'GET_FLAT_FILE_ALL_ORDERS_DATA_BY_LAST_UPDATE_GENERAL',
    name: 'All Orders Data By Last Update General',
  },
  {
    reportType: 'GET_FBA_FULFILLMENT_CUSTOMER_RETURNS_DATA',
    name: 'FBA Fulfillment Customer Returns Data',
  },
  {
    reportType: 'GET_AMAZON_FULFILLED_SHIPMENTS_DATA_GENERAL',
    name: 'Amazon Fulfilled Shipments Data General',
  },
  {
    reportType: 'GET_FBA_ESTIMATED_FBA_FEES_TXT_DATA',
    name: 'FBA Estimated Fees Text Data',
  },
  {
    reportType: 'GET_FBA_REIMBURSEMENTS_DATA',
    name: 'FBA Reimbursements Data',
  },
  {
    reportType: 'GET_RESTOCK_INVENTORY_RECOMMENDATIONS_REPORT',
    name: 'Restock Inventory Recommendations',
  },
  {
    reportType: 'GET_STRANDED_INVENTORY_UI_DATA',
    name: 'Stranded Inventory UI Data',
  },
  {
    reportType: 'GET_FBA_INVENTORY_AGED_DATA',
    name: 'FBA Inventory Aged Data',
  },
  {
    reportType: 'GET_FBA_RECOMMENDED_REMOVAL_DATA',
    name: 'FBA Recommended Removal Data',
  },
  {
    reportType: 'GET_FBA_FULFILLMENT_REMOVAL_ORDER_DETAIL_DATA',
    name: 'FBA Fulfillment Removal Order Detail Data',
  },
  {
    reportType: 'GET_FBA_FULFILLMENT_REMOVAL_SHIPMENT_DETAIL_DATA',
    name: 'FBA Fulfillment Removal Shipment Detail Data',
  },
  {
    reportType: 'GET_COUPON_PERFORMANCE_REPORT',
    name: 'Coupon Performance',
  },
  {
    reportType: 'GET_PROMOTION_PERFORMANCE_REPORT',
    name: 'Promotion Performance',
  },
  {
    reportType: 'GET_BRAND_ANALYTICS_MARKET_BASKET_REPORT',
    name: 'Brand Analytics Market Basket',
  },
  {
    reportType: 'GET_BRAND_ANALYTICS_REPEAT_PURCHASE_REPORT',
    name: 'Brand Analytics Repeat Purchase',
  },
  {
    reportType: 'GET_BRAND_ANALYTICS_ALTERNATE_PURCHASE_REPORT',
    name: 'Brand Analytics Alternate Purchase',
  },
  {
    reportType:'GET_LEDGER_SUMMARY_VIEW_DATA',
    name :'Inventory Ledger Summary View Data'
  },
  {
    reportType:'GET_LEDGER_DETAIL_VIEW_DATA',
    name:'Inventory Ledger Detail View Data'
  },
  {
    reportType:'GET_V2_SETTLEMENT_REPORT_DATA_FLAT_FILE_V2',
    name:'Settlement Report Data Flat File V2'
  },
  {
    reportType: 'WOW_BRAND_ADVERTISING_PERFORMANCE',
    name: 'WoW Brand Advertising Performance',
  },
  {
    reportType: 'ADVERTISING_SPONSORED_PRODUCTS',
    name: 'Advertising Sponsored Products',
  },
  {
    reportType: 'ADVERTISING_SPONSORED_BRANDS',
    name: 'Advertising Sponsored Brands',
  },
  {
    reportType: 'ADVERTISING_SPONSORED_DISPLAY',
    name: 'Advertising Sponsored Display',
  },
  {
    reportType: 'ADVERTISING_ATTRIBUTION',
    name: 'Advertising Attribution',
  },
  {
    reportType: 'ADVERTISING_DSP',
    name: 'Advertising DSP',
  },
  {
    reportType: 'GET_DAILY_ALERTS_SUMMARY',
    name: 'Daily Alerts Summary',
  },
  {
    reportType: 'ADVERTISING_INVOICES',
    name: 'Advertising Invoices',
  },
];
