import {useState, useEffect, memo} from 'react';
import {styled} from '@mui/system';
import {Search as SearchIcon, Clear} from '@mui/icons-material';
import {useDebounce} from 'use-lodash-debounce';
import {IconButton, TextField} from '@mui/material';
import {} from 'formik-mui';

const SearchContainer = styled('div')(({theme}) => ({
  position: 'relative',
  display: 'flex',
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  '&.hide-btn': {
    marginRight: '10px',
    '& > .MuiSvgIcon-root': {
      right: '10px',
    },
  },
  '& > .MuiSvgIcon-root': {
    position: 'absolute',
    fontSize: '18px',
    top: '11px',
    right: '50px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  '& > div': {
    width: '100%',
    '& input': {
      paddingRight: '50px',
    },
  },
  '& > button': {
    position: 'absolute',
    right: '6px',
  },
}));

interface SearchBarProps {
  onSearch?: () => void;
  onChange: (event: string) => void;
  value: string;
  placeholder?: string;
  showButton?: boolean;
  disabled?: boolean;
}

export default memo(function SearchBar({
  onSearch,
  value,
  onChange,
  placeholder,
  showButton = true,
  disabled,
}: SearchBarProps) {
  const [currentValue, setCurrentValue] = useState(value ? value : null);
  const onHandleChange = useDebounce(currentValue, 400);

  useEffect(() => {
    currentValue !== null && onSearch?.();
  }, [onHandleChange]);

  return (
    <SearchContainer className={!showButton ? 'hide-btn' : ''}>
      <TextField
        disabled={disabled}
        value={value}
        size="small"
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.value.length > 0 ? e.target.value : '');
          setCurrentValue(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      />
      {value.length > 0 && (
        <Clear
          onClick={() => {
            onChange('');
            setCurrentValue('');
          }}
        />
      )}
      {showButton && (
        <IconButton onClick={() => onSearch?.()}>
          <SearchIcon />
        </IconButton>
      )}
    </SearchContainer>
  );
});
