import {createContext, useState, useEffect} from 'react';
// services
import {accountService} from '../../services/account.service';
// utils
import {Api, errorAlert} from '../../utils/api';
import {Role} from '../../utils/role';

interface ProviderProps {
  children?: any;
}

interface AppContextState {
  brands: any[];
  setBrands: (brands: any[]) => void;
  accountManagers: any[];
  setAccountManagers: (accountManagers: any[]) => void;
  appLoading: boolean;
  currentAM: any;
  getBrandUtils: () => void;
  brandUtilsLoading: boolean;
  setBrandUtilsLoading: (brandUtilsLoading: boolean) => void;
  brandUtils: any;
  setBrandUtils: (brandUtils: any) => void;
  getBrandRoles: () => void;
}

const initialState: AppContextState = {
  accountManagers: [],
  setAccountManagers: () => {},
  brands: [],
  setBrands: () => {},
  appLoading: true,
  currentAM: null,
  getBrandUtils: () => {},
  brandUtilsLoading: true,
  setBrandUtilsLoading: () => {},
  brandUtils: {},
  setBrandUtils: () => {},
  getBrandRoles: () => {},
};

export const AppContext = createContext(initialState);

function AppContextProvider(props: ProviderProps) {
  const [accountManagers, setAccountManagers] = useState<any[]>(initialState.accountManagers);
  const [brands, setBrands] = useState<any[]>(initialState.brands);
  const [currentAM, setCurrentAM] = useState<any>(initialState.currentAM);
  const [appLoading, setAppLoading] = useState<boolean>(true);
  const [brandUtilsLoading, setBrandUtilsLoading] = useState<boolean>(initialState.brandUtilsLoading);
  const [brandUtils, setBrandUtils] = useState<any>(initialState.brandUtils);

  async function getAccountManagers() {
    const {data} = await Api.get('users/account-managers');
    setAccountManagers(data);
    const currentUser = data.find((manager) => manager.id === accountService.userValue.userId);
    setCurrentAM(currentUser);
  }

  async function getBrands() {
    const {data} = await Api.get('brands/ids');
    setBrands(data);
  }

  async function getBrandsStatuses() {
    if (brandUtils.brandStatuses) return;
    const {data} = await Api.get('brand-statuses');
    setBrandUtils((prev) => ({...prev, brandStatuses: data}));
  }

  async function getBrandRoles() {
    if (brandUtils.brandRoles) return;
    const {data} = await Api.get('users/brand-role-assignees');
    setBrandUtils((prev) => ({...prev, brandRoles: data}));
  }

  async function getBrandList() {
    if (brandUtils.brandList) return;
    const {data} = await Api.get('brands/all');
    setBrandUtils((prev) => ({...prev, brandList: data}));
  }

  async function getBrandTypes() {
    if (brandUtils.brandTypes) return;
    const {data} = await Api.get('brands/types');
    setBrandUtils((prev) => ({...prev, brandTypes: data}));
  }

  async function getOrderMethods() {
    if (brandUtils.orderMethods) return;
    const {data} = await Api.get('orders/methods');
    setBrandUtils((prev) => ({...prev, orderMethods: data}));
  }

  async function getAgreementTypes() {
    if (brandUtils.agreementTypes) return;
    const {data} = await Api.get('agreement-types');
    setBrandUtils((prev) => ({...prev, agreementTypes: data}));
  }

  async function getBrandMaxControl() {
    if (brandUtils.brandMaxControl) return;
    const {data} = await Api.get('brands/max-control');
    setBrandUtils((prev) => ({...prev, brandMaxControl: data}));
  }

  async function getInitialData() {
    setAppLoading(true);
    try {
      await getBrands();
      if (
        accountService?.userValue?.role !== Role.BrandUser &&
        ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) === false
      ) {
        await getAccountManagers();
      }
    } catch (e) {
      errorAlert('Unable to get data!', e);
    } finally {
      setAppLoading(false);
    }
  }

  async function getBrandUtils() {
    setBrandUtilsLoading(true);
    try {
      Promise.all([
        getBrandsStatuses(),
        getBrandRoles(),
        getBrandList(),
        getBrandTypes(),
        getOrderMethods(),
        getAgreementTypes(),
        getBrandMaxControl(),
      ]);
    } catch (e) {
      errorAlert('Unable to get brand utils data!', e);
    } finally {
      setBrandUtilsLoading(false);
    }
  }

  useEffect(() => {
    if (accountService?.userValue?.accessToken) {
      getInitialData();
    }
  }, [accountService?.userValue?.accessToken]);

  return (
    <AppContext.Provider
      value={{
        brands,
        setBrands,
        accountManagers,
        setAccountManagers,
        appLoading,
        currentAM,
        getBrandUtils,
        brandUtilsLoading,
        setBrandUtilsLoading,
        brandUtils,
        setBrandUtils,
        getBrandRoles,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
