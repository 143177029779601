const groups = {
  campaign: {
    key: 'campaign',
    name: 'Campaign',
  },
  adGroup: {
    key: 'adGroup',
    name: 'AdGroup',
  },
  target: {
    key: 'target',
    name: 'Target Expression',
  },
  keyword: {
    key: 'keyword',
    name: 'Keyword',
  },
  brand: {
    key: 'brand',
    name: 'Brand',
  },
  advertiser: {
    key: 'advertiser',
    name: 'Advertiser',
  },
};

export const advertisingGroups = {
  sp: {
    campaign: groups['campaign'],
    adGroup: groups['adGroup'],
    keyword: groups['keyword'],
    target: groups['target'],
    brand: groups['brand'],
  },
  sb: {
    campaign: groups['campaign'],
    adGroup: groups['adGroup'],
    keyword: groups['keyword'],
    brand: groups['brand'],
  },
  dsp: {
    advertiser: groups['advertiser'],
    brand: groups['brand'],
  },
  attribution: {
    campaign: groups['campaign'],
    adGroup: groups['adGroup'],
    brand: groups['brand'],
  },
};
