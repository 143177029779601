import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

const globalStyles = makeStyles((theme: Theme) => ({
  // tables
  tableSelect: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      '&:hover': {
        outline: 'none',
        borderColor: 'transparent',
      },
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:before, & .Mui-focused.MuiInput-underline:after': {
      borderBottom: 'none!important',
    },
    '& .MuiInput-underline': {
      height: '100%',
    },
    '&.MuiFormControl-root': {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiSelect-select': {
      paddingLeft: '10px',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
}));

export {globalStyles};
