import {useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import {Select} from 'formik-mui';
import * as Yup from 'yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Typography,
  Link,
  Stack,
  Grid,
  Button,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useHistory} from 'react-router-dom';
import {Alert} from '@mui/lab';
//components
import Spacer from '../../../components/Spacer/Spacer';
// utils
import {Api, errorAlert} from '../../../utils/api';
// services
import {alertService} from '../../../services/alert.service';

const useStyles = makeStyles({
  loginPaper: {
    textAlign: 'center',
    padding: '1em 2em 3em 2em',
    margin: '2em 0em',
  },
  inputField: {
    margin: '0.5em',
    width: '95%',
  },
  loginButton: {
    margin: '0.3em',
  },
  logoImg: {
    width: '10em',
    margin: '3em',
  },
  grid: {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/Market.svg'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '30em',
    backgroundPositionY: '5em',
  },
});

const marketplaceValidationSchema = Yup.object().shape({
  sp_auth_marketplace: Yup.string().required('The marketplace is required'),
});

function AmazonAuditAuthorize() {
  let history = useHistory();
  const classes = useStyles();
  const [brandInfo, setBrandInfo] = useState(null);
  const [displayConfirmDisconnectSpApi, setDisplayConfirmDisconnectSpApi] = useState(false);
  const [displaySelectMarketplaceForSPAPI, setDisplaySelectMarketplaceForSPAPI] = useState(false);
  const [amazonMarketplaces, setAmazonMarketplaces] = useState([]);
  const [marketplaceToDelete, setMarketplaceToDelete] = useState('');

  async function getUserBrand() {
    try {
      const {data} = await Api.get(`brands/brand-user/brand`);
      setBrandInfo(data);
      if (data.selling_partner_api_access) {
        history.push('/');
      }
    } catch (e) {
      errorAlert('Unable to get brand info', e);
    }
  }

  async function getSpMarketplaces() {
    try {
      const {data} = await Api.get('sp-api/marketplaces');
      setAmazonMarketplaces(data);
    } catch (e) {
      errorAlert('Unable to get Amazon Marketplaces', e);
    }
  }

  async function grantSellingPartnerAccess(data, {setSubmitting}) {
    try {
      setSubmitting?.(true);
      const response = await Api.get(`sp-api/authorization-link/brand-user/${data.sp_auth_marketplace}`);
      window.location.href = response.data;
    } catch (e: any) {
      errorAlert('Unable to get authorization link', e);
    }
  }

  async function confirmDisconnectSellingPartnerAccess() {
    setDisplayConfirmDisconnectSpApi(false);
    try {
      const response = await Api.post(
        `${process.env.REACT_APP_API_URL}/sp-api/deauthorize/brand-user/${marketplaceToDelete}`,
        {}
      );
      if (response.data.ok === true) {
        alertService.success('The Selling Partner API access was revoked.');
      }
    } catch (e: any) {
      errorAlert('Unable to revoke Selling Partner API access', e);
    }
  }

  useEffect(() => {
    Promise.all([getUserBrand(), getSpMarketplaces()]);
  }, []);

  return (
    <>
      <Grid container spacing={1} justifyContent="center" style={{paddingTop: 30, textAlign: 'center'}}>
        <Grid item lg={6}>
          <div>
            <Typography component="h2" variant="h4" color="primary" gutterBottom>
              {' '}
              Selling Partner API{' '}
            </Typography>
            {amazonMarketplaces.map((marketplace) => (
              <Stack flexDirection={'row'} justifyContent="space-between" key={`mp_${marketplace.marketplaceId}`}>
                <Typography>{marketplace.name}</Typography>
                {brandInfo.sp_api_authorized_marketplaces.find(
                  (x) => x.marketplace_id === marketplace.marketplaceId
                ) ? (
                  <Link
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      setMarketplaceToDelete(marketplace.marketplaceId);
                      setDisplayConfirmDisconnectSpApi(true);
                    }}
                  >
                    Disconnect
                  </Link>
                ) : (
                  <Link
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      grantSellingPartnerAccess(
                        {sp_auth_marketplace: marketplace.marketplaceId},
                        {setSubmitting: null}
                      );
                    }}
                  >
                    Connect
                  </Link>
                )}
              </Stack>
            ))}
            <Spacer height={10} />
          </div>
          <Grid />
        </Grid>
      </Grid>
      <Dialog
        open={displaySelectMarketplaceForSPAPI}
        onClose={() => setDisplaySelectMarketplaceForSPAPI(false)}
        aria-labelledby="alert-dialog-title2"
        aria-describedby="alert-dialog-description2"
      >
        <DialogTitle id="alert-dialog-title-2">Please select the marketplace that you want to connect</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              sp_auth_marketplace: 'ATVPDKIKX0DER',
            }}
            validationSchema={marketplaceValidationSchema}
            onSubmit={grantSellingPartnerAccess}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {({errors, touched, isSubmitting, values: formValues}) => (
              <Form>
                {brandInfo.sp_api_authorized_marketplaces.find(
                  (marketplace) => marketplace.marketplace_id === formValues.sp_auth_marketplace
                ) && (
                  <Alert color="warning">
                    <Typography>
                      This brand has already granted us Selling Partner API authorization for this marketplace.
                      Authorizing this marketplace again would overwrite the information from the previous
                      authorization.
                    </Typography>
                  </Alert>
                )}
                <Spacer height={15} />
                <FormControl
                  size="small"
                  error={touched['sp_auth_marketplace'] && !!errors['sp_auth_marketplace']}
                  className={classes.inputField}
                  variant={'outlined'}
                >
                  <InputLabel id="type-label">Marketplace</InputLabel>
                  <Field
                    component={Select}
                    name="sp_auth_marketplace"
                    label="Marketplace"
                    labelId="sp-marketplace-label"
                  >
                    {amazonMarketplaces.map((marketplace) => (
                      <MenuItem key={`marketplace_${marketplace.marketplaceId}`} value={marketplace.marketplaceId}>
                        {marketplace.name} ({marketplace.countryCode})
                      </MenuItem>
                    ))}
                  </Field>
                  <FormHelperText>
                    {touched['sp_auth_marketplace'] && !!errors['sp_auth_marketplace']
                      ? errors['sp_auth_marketplace']
                      : ''}
                  </FormHelperText>
                </FormControl>
                <Button size="large" type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  Authorize
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDisplaySelectMarketplaceForSPAPI(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={displayConfirmDisconnectSpApi}
        onClose={() => setDisplayConfirmDisconnectSpApi(false)}
        aria-labelledby="alert-dialog-title3"
        aria-describedby="alert-dialog-description3"
      >
        <DialogTitle id="alert-dialog-title3">
          Are you sure you want to disconnect the Selling Partner API access to this marketplace?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description3">
            This cannot be undone. After the Selling Partner API access is disconnected, Stonehenge will no longer pull
            the brand's information from this marketplace until the access is granted again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDisplayConfirmDisconnectSpApi(false)} color="primary">
            Disagree
          </Button>
          <Button onClick={confirmDisconnectSellingPartnerAccess} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export {AmazonAuditAuthorize};
