import {useEffect, useState, useRef, useContext} from 'react';
import {useLocation} from 'react-router-dom';
import {
  Autocomplete,
  Grid,
  TextField,
  Typography,
  LinearProgress,
  Tooltip,
  MenuItem,
  List,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Paper,
  Divider,
  ListItemIcon,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridPagination,
  GridToolbarColumnsButton,
  useGridApiContext,
} from '@mui/x-data-grid';
import {Edit, AccessTime, Visibility, Delete, ErrorOutline, WarningAmber} from '@mui/icons-material';
// services
import mixPanel from '../../services/mixpanel';
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import SearchBar from '../../components/SearchBar/SearchBar';
import DropDownButton from '../../components/DropdownButton/DropDownButton';
import DraggableList from '../../components/DraggableList/DraggableList';
import CustomDataGrid from '../../components/CustomDataGrid/CustomDataGrid';
import TableSelectInput from '../../components/TableSelectInput/TableSelectInput';
import CollapsibleDataGrid from '../../components/CollapsibleDataGrid/CollapsibleDataGrid';
import ModalButton from '../../components/ModalButton/ModalButton';
import {CustomAlert} from '../Login/components/CustomAlert';
import SkeletonLoader from '../../components/SkeletonLoader/SkeletonLoader';
import Button from '../../components/Button/Button';
import {selectedColumns, defaultColumns} from './components/customColumns';
// styles
import {useStyles} from './Styles';
// utils
import {getSubBrands} from '../../utils/getSubBrands';
import {Role} from '../../utils/role';
import {ListingsItemStatuses} from './utils/constant';
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../utils/tableSettings';
import {getArticle} from '../../utils/FFPBeacon';
import {CustomColumn, makeColumnFreeze, fixedPlaceHolder} from '../../utils/makeColumnFreeze';
import {rowsPerPageOptions} from '../../utils/constants';
import {Api, errorAlert} from '../../utils/api';
import {getDefaultQuery, updateQueryParams} from '../../utils/urlParams';
import {BrandTypeUtils} from '../../utils/brand-types';
// context
import {AppContext} from '../../context/AppContext/AppContext';

const groups = ['parent_asin'];
const optionsForManagedByAgency = ['TRUE', 'FALSE'];
interface AmazonListingItemsProps {
  GAEvents?: (category, action, label?) => void;
}

export default function AmazonListings({GAEvents}: AmazonListingItemsProps) {
  const location = useLocation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [listingRows, setListingRows] = useState([]);
  const [statusCount, setStatusCount] = useState([]);
  const [codeCount, setCodeCount] = useState([]);
  const [filter, setFilter] = useState<any>({
    brands: null,
    subBrands: null,
    statuses: null,
    issue: null,
    code: null,
    groupBy: null,
    asin: null,
    isManagedByAgency: null,
  });
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [subBrands, setSubBrands] = useState([]);
  const [orderColumns, setOrderColumns] = useState([]);
  const [groupByColumns, setGroupByColumns] = useState([]);
  const [groupByVisibility, setGroupByVisibility] = useState<any>({});
  const [visibilityModel, setVisibilityModel] = useState<any>({});
  const [tableLoading, setTableLoading] = useState(false);
  const [groupByValue, setGroupByValue] = useState(null);
  const [selectedRow, setSelectedRow] = useState(0);
  const [switchTable, setSwitchTable] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [tableFields, setTableFields] = useState([]);
  const [countLoading, setCountLoading] = useState(false);
  const [subBrandLoading, setSubBrandLoading] = useState(false);
  const [selectedCustomAttrData, setSelectedCustomAttrData] = useState({field: null, data: []});
  const [utilsLoading, setUtilsLoading] = useState(true);
  const [customButtons, setCustomButtons] = useState<any>([
    {
      icon: (v) => (v.isSupportedProductType ? <Edit /> : <Visibility />),
      tooltip: (v) => (v.isSupportedProductType ? 'Edit' : 'View'),
      url: (v) => `/amazon-listings-item/${v.marketplace_id}/${encodeURIComponent(v.seller_sku)}/edit`,
    },
    {
      icon: <AccessTime />,
      tooltip: 'View Versions',
      url: (v) => `/amazon-listings-item/${v.marketplace_id}/${encodeURIComponent(v.seller_sku)}/versions`,
    },
  ]);

  const {brands, appLoading, getBrandRoles, brandUtils} = useContext(AppContext);
  const isFfpBrandType = BrandTypeUtils.isFFP(accountService.userValue.brand_type);
  const isEcom3kBrandType = BrandTypeUtils.isEcom3k(accountService.userValue.brand_type);

  const thisRef = useRef<any>(null);

  let selectedCustomAttr: any = {field: null, data: []};
  let skusByPage = [];

  const setSelectedCheckboxes = (id, all, value, skus?, setProperty?) => {
    const input = document.getElementById(id) as HTMLInputElement | null;
    if (input) {
      input.checked = value;
      if (setProperty) {
        input.setAttribute('clear', 'true');
      } else {
        input.removeAttribute('clear');
      }
    }
    if (all) {
      const selectedSKUS = skus ? skus : selectedCustomAttr.data;

      selectedSKUS.forEach((i) => {
        const input = document.getElementById(
          `${id}-${i.seller_sku}-${i.marketplace}-${i.id}`
        ) as HTMLInputElement | null;

        if (input) {
          input.checked = value;
          if (setProperty) {
            input.setAttribute('clear', 'true');
          } else {
            input.removeAttribute('clear');
          }
        }
      });
    }
  };

  const resetCheckboxes = () => {
    setSelectedCheckboxes(selectedCustomAttrData.field, true, false, selectedCustomAttrData.data, true);
  };

  const getFieldOptions = (field: string) => {
    const optionsArray = field === 'role' ? brandUtils?.brandRoles : [];
    return optionsArray;
  };

  const checkboxHandleChange = (v, field, selectedAll, skusByPage, sellerSKU?) => {
    const currentValue = v.target.value;

    if (selectedCustomAttr.field !== field) {
      setSelectedCheckboxes(selectedCustomAttr.field, true, false);
      selectedCustomAttr.data = [];
      selectedCustomAttr.field = null;
    }

    if (selectedAll) {
      if (v.target.checked) {
        selectedCustomAttr.data = [...selectedCustomAttr.data, ...skusByPage];
        selectedCustomAttr.data = selectedCustomAttr.data.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.seller_sku === value.seller_sku && t.marketplace === value.marketplace)
        );
        selectedCustomAttr.field = currentValue;
        setSelectedCheckboxes(currentValue, true, true, skusByPage);
      } else {
        selectedCustomAttr.data = [];
        selectedCustomAttr.data.filter(
          (i) => !skusByPage.find((t) => t.seller_sku === i.seller_sku && t.marketplace === i.marketplace)
        );
        selectedCustomAttr.field = null;
        setSelectedCheckboxes(currentValue, true, false, skusByPage);
      }
    } else {
      if (v.target.checked) {
        if (selectedCustomAttr.field === field) {
          selectedCustomAttr.data.push(sellerSKU);
        } else {
          selectedCustomAttr.field = field;
          selectedCustomAttr.data = [sellerSKU];
        }
      } else {
        const index = selectedCustomAttr.data.findIndex(
          (x) =>
            x.id === sellerSKU.id && x.seller_sku === sellerSKU.seller_sku && x.marketplace === sellerSKU.marketplace
        );
        if (index > -1) {
          selectedCustomAttr.data.splice(index, 1);
        }
      }
    }

    setSelectedCustomAttrData({...selectedCustomAttr});
  };

  const BulkCustomAttributes = ({value, selectAll, data}: any) => {
    let isSelected = false;
    const checkboxes = document.querySelectorAll("input[clear='true']:checked");

    if (!selectAll && value.skus?.length > 0) {
      skusByPage = value.skus;
    }

    if (checkboxes.length > 0) {
      selectedCustomAttr.data = [];
      selectedCustomAttr.field = null;
      checkboxes.forEach((i) => i.removeAttribute('clear'));
    }

    if (!selectAll) {
      if (
        selectedCustomAttr.field === value.label &&
        selectedCustomAttr.data.find((i) => i.seller_sku === value.seller_sku && i.marketplace === value.marketplace)
      ) {
        isSelected = true;
      }
    }

    if (
      selectAll &&
      selectedCustomAttr.field === value.label &&
      skusByPage.every(
        (i) =>
          selectedCustomAttr.data.find((t) => t.seller_sku === i.seller_sku && t.marketplace === i.marketplace)?.id ===
          i.id
      )
    ) {
      isSelected = true;
    }

    return (
      <input
        className="column-checkbox"
        type="checkbox"
        id={selectAll ? value.label : `${value.label}-${value.seller_sku}-${value.marketplace}-${value.id}`}
        value={selectAll ? value.label : `${value.label}-${value.seller_sku}-${value.marketplace}-${value.id}`}
        checked={isSelected}
        onChange={(v) => {
          checkboxHandleChange(
            v,
            value.label,
            selectAll,
            skusByPage,
            value.seller_sku && {seller_sku: value.seller_sku, marketplace: value.marketplace, id: value.id}
          );
          if (v.target.checked) {
            isSelected = true;
          } else {
            isSelected = false;
          }
        }}
      />
    );
  };

  const CustomEditComponent = ({props}: any) => {
    const {id, value, field} = props;
    const apiRef = useGridApiContext();
    const handleValueChange = (event) => {
      const v = event.target.value;
      apiRef.current.setEditCellValue({id, field, value: v});
    };
    const handleRef = (element) => {
      if (element) {
        const input = element.querySelector(`input[value="${value}"]`);
        input?.focus();
      }
    };

    return (
      <TextField
        className="custom-textfield"
        ref={handleRef}
        onChange={handleValueChange}
        onBlurCapture={() => {
          selectedCustomAttr = {data: [], field: null};
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            selectedCustomAttr = {data: [], field: null};
          }
        }}
        value={value}
        variant="outlined"
      />
    );
  };

  const SelectInputCell = ({props, optionsArray, currentItem, required}: any) => {
    const {id, value, field} = props;
    const apiRef = useGridApiContext();

    const handleChange = (event) => {
      apiRef.current.setEditCellValue({id, field, value: event});
    };

    const handleRef = (element) => {
      if (element) {
        const input = element.querySelector(`input[value="${value}"]`);
        input?.focus();
      }
    };

    return (
      <TableSelectInput
        ref={handleRef}
        required={required}
        onBlurCapture={() => {
          selectedCustomAttr = {data: [], field: null};
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            selectedCustomAttr = {data: [], field: null};
          }
        }}
        value={currentItem?.id ? currentItem.id : currentItem?.brand_code}
        renderValue={
          currentItem?.name ? currentItem.name : currentItem?.email ? currentItem.email : currentItem?.brand_code
        }
        onChange={handleChange}
        options={optionsArray?.map((item) => (
          <MenuItem key={item.id ? item.id : item?.brand_code} value={item.id ? item.id : item?.brand_code}>
            {item.name ? item.name : item.email ? item.email : item?.brand_code}
          </MenuItem>
        ))}
      />
    );
  };

  const newColumns: GridColDef[] = Object.entries(tableFields)
    .map(([key, value]: any) => {
      let component = null;
      if (value.type === 'select') {
        component = {
          field: key,
          headerName: value.label,
          flex: 1,
          minWidth: 280,
          filterable: false,
          editable: true,
          renderHeader: (params) => {
            return (
              <div style={{display: 'flex', alignItems: 'center'}}>
                {value.custom_attribute && <BulkCustomAttributes value={{label: params.colDef.headerName}} selectAll />}
                {value.label}
              </div>
            );
          },
          sortable: !value.custom_attribute,
          renderCell: (params) => {
            const optionsArray = getFieldOptions(value.options);
            const values = {
              ...value,
              seller_sku: params.row.seller_sku,
              marketplace: params.row.marketplace_id,
              id: params.row.id,
              skus: params.row.skus,
            };
            const currentItem = optionsArray?.find((item) => {
              if (value.options === 'brands') {
                return item.brand_code === params.row[key];
              } else {
                return item.email === params.row[key] || item.id === params.row[key] || item.name === params.row[key];
              }
            });

            return (
              <>
                {value.custom_attribute && <BulkCustomAttributes value={values} />}
                <TableSelectInput
                  readOnly
                  value={currentItem?.id ? currentItem.id : currentItem?.brand_code}
                  loading={utilsLoading}
                  renderValue={
                    currentItem?.name
                      ? currentItem.name
                      : currentItem?.email
                      ? currentItem.email
                      : currentItem?.brand_code
                  }
                />
              </>
            );
          },
          renderEditCell: (params: any) => {
            const optionsArray = getFieldOptions(value.options);
            const currentItem = optionsArray?.find((item) => {
              if (value.options === 'brands') {
                return item.brand_code === params.row[key];
              } else {
                return item.email === params.row[key] || item.id === params.row[key] || item.name === params.row[key];
              }
            });

            return (
              <SelectInputCell
                props={params}
                optionsArray={optionsArray}
                currentItem={currentItem}
                required={value.required}
              />
            );
          },
        };
      } else if (value.type === 'text' || value.type === 'number') {
        component = {
          field: key,
          headerName: value.label,
          flex: 1,
          minWidth: 250,
          filterable: false,
          renderHeader: (params) => {
            return (
              <div style={{display: 'flex', alignItems: 'center'}}>
                {value.custom_attribute && <BulkCustomAttributes value={{label: params.colDef.headerName}} selectAll />}
                {value.label}
              </div>
            );
          },
          sortable: !value.custom_attribute,
          editable: !value.noEditable,
          renderCell: (params) => {
            const values = {
              ...value,
              seller_sku: params.row.seller_sku,
              marketplace: params.row.marketplace_id,
              id: params.row.id,
              skus: params.row.skus,
            };
            const newValue = params.row[key]?.toString().includes('https://') ? (
              <a target="_blank" rel="noopener noreferrer" style={{color: '#3c8dc7'}} href={params.row[key]}>
                {params.row[key]}
              </a>
            ) : (
              params.row[key]
            );
            return !value.noEditable ? (
              <Tooltip title="Double Click to Edit" PopperProps={{disablePortal: true}}>
                <span style={{width: '100%', height: '100%'}}>
                  {value.custom_attribute && <BulkCustomAttributes value={values} />}
                  {params.row[key] ? newValue : 'N/A'}
                </span>
              </Tooltip>
            ) : params.row[key] ? (
              newValue
            ) : (
              'N/A'
            );
          },
          renderEditCell: (params: any) => {
            return <CustomEditComponent props={params} />;
          },
        };
      }
      return component;
    })
    .filter((item) => item !== null);

  useEffect(() => {
    async function getTableData() {
      const defaultColumnsData = defaultColumns(filter.groupBy, newColumns, classes);
      const data = await getUserPreferences({
        list: filter.groupBy ? selectedColumns : defaultColumnsData,
        tableName: filter.groupBy ? `listings-group-by-${filter.groupBy}` : 'listings',
        visibilityModel: visibilityModel,
        defaultVisibilityModel: {},
        loading: setTableLoading,
      });
      if (data) {
        if (filter.groupBy) {
          setGroupByColumns(data.columns);
          setGroupByVisibility(data.visibility);
        } else {
          setOrderColumns(data.columns);
          setVisibilityModel(data.visibility);
        }
      }
    }
    getTableData();
  }, [filter.groupBy, utilsLoading]);

  async function pullSubBrands(brandCodes: string[]) {
    let newSubBrands = [];

    const data = await getSubBrands(brandCodes, setSubBrandLoading);

    if (data) {
      newSubBrands = data;
    }

    const userSubBrandCodes = accountService.userValue.sub_brand_codes;

    const newFilter: any = {};
    if (![Role.Admin].includes(accountService.userValue.role)) {
      if (userSubBrandCodes && userSubBrandCodes.length > 0) {
        newSubBrands = newSubBrands.filter((subBrand) => userSubBrandCodes.includes(subBrand.brand_code));
        // This is a hack to make sure the user can only see their own sub brands
        newFilter.subBrands = newSubBrands
          .filter((subBrand) => accountService.userValue.sub_brand_codes.includes(subBrand.brand_code))
          .map((brand) => brand.brand_code);
      } else {
        newSubBrands = [];
      }
    }

    setSubBrands(newSubBrands);
    setFilter((prev) => ({...prev, ...newFilter}));
  }

  async function fetchData() {
    const defaultQuery = getDefaultQuery(location, {...filter, page, pageSize});
    try {
      if ((defaultQuery.brands && defaultQuery.brands.length > 0) || accountService.userValue.role === Role.BrandUser) {
        if (accountService.userValue.role === Role.BrandUser) {
          pullSubBrands([accountService.userValue.brand_code]);
        } else {
          pullSubBrands(defaultQuery.brands?.split(','));
        }
      }

      const filterData = {
        brands:
          accountService.userValue.role === Role.BrandUser
            ? [accountService.userValue.brand_code]
            : defaultQuery.brands?.split(',') || null,
        subBrands: defaultQuery.subBrands?.split(',') || null,
        statuses: defaultQuery?.statuses || null,
        issue: defaultQuery?.issue || null,
        code: defaultQuery?.code || null,
        groupBy: defaultQuery?.groupBy || null,
        isManagedByAgency: defaultQuery?.isManagedByAgency || null,
      };

      const tableData = {
        page: +defaultQuery.page || 0,
        pageSize: +defaultQuery.pageSize || 40,
      };

      setFilter(filterData);
      setPage(tableData.page);
      setPageSize(tableData.pageSize);
      setGroupByValue(filterData.groupBy);

      updateQueryParams(location, {...filterData, ...tableData});

      await loadData({
        filter: filterData,
        isManagedByAgency: filterData.isManagedByAgency,
        groupBy: filterData.groupBy,
        page: tableData.page,
        pageSize: tableData.pageSize,
      });
    } catch (e) {
      errorAlert('Unable to fetch listings', e);
    }
  }

  async function getSummaryData(params) {
    setCountLoading(true);
    try {
      const {data} = await Api.get(`amazon-listings/items/summary`, {
        params: {
          countBy: 'status',
          ...params,
        },
      });
      setStatusCount(data);
      try {
        const {data} = await Api.get(`amazon-listings/items/summary`, {
          params: {
            countBy: 'code',
            ...params,
          },
        });
        setCodeCount(data);
      } catch (e) {
        errorAlert('Unable to load summary data by code', e);
      } finally {
        setCountLoading(false);
      }
    } catch (e) {
      setCountLoading(false);
      errorAlert('Unable to load summary data by status', e);
    }
  }

  async function loadData(params, updateCustomAttr?) {
    updateQueryParams(location, {...params, ...params.filter}, 'filter');
    if (params?.filter) {
      Object.keys(params?.filter).forEach((key) => {
        if (!params.filter[key]) delete params.filter[key];
      });
    }
    updateCustomAttr && setUtilsLoading(true);
    setLoadingData(true);
    if (params.groupBy) {
      if (loadingData) {
        setSwitchTable(true);
      }
    }

    const getAttributes = (data) => {
      let customAttributes = {};
      Object.entries(data).forEach(([key, row]: any, index) => {
        customAttributes = {
          ...customAttributes,
          [key.replaceAll(' ', '_')]: row.value,
        };
      });
      return customAttributes;
    };

    getSummaryData(params);

    try {
      const {data} = await Api.get(`amazon-listings/items`, {
        params,
      });
      setCount(data.count);
      if (!params.groupBy) {
        const skus = data.rows.map((i, x) => {
          return {seller_sku: i.seller_sku, marketplace: i.marketplace_id, id: x};
        });

        setListingRows(
          data.rows.map((x, i) => {
            const attr = getAttributes(x?.custom_attributes);
            return {id: i, ...x, ...attr, skus: skus};
          })
        );
      } else {
        setListingRows(
          data.rows.map((x, i) => {
            return {id: i, ...x};
          })
        );
      }

      let customAttributes = [];
      const attributeType = {
        TEXT: 'text',
        LINK: 'text',
        NUMBER: 'number',
        USER: 'select',
      };
      if (!params.groupBy) {
        data.rows.length > 0 &&
          Object.entries(data.rows[0]?.custom_attributes).forEach(([key, row]: any, index) => {
            customAttributes = {
              ...customAttributes,
              [key.replaceAll(' ', '_')]: {
                type: attributeType[row.type],
                value: null,
                label: key,
                custom_attribute: true,
                options: attributeType[row.type] === 'select' ? 'role' : null,
              },
            };
          });

        setTableFields({...customAttributes});
      }
    } catch (e) {
      errorAlert('Unable to load listings data', e);
    } finally {
      setLoadingData(false);
      setSwitchTable(false);
      setUtilsLoading(false);
    }
  }

  const handleChangeBrandFilter = (value) => {
    if (value.brands?.length === 0) {
      value.subBrands = [];
    }

    if (value?.subBrands?.length === 0) delete value.subBrands;

    if (value?.brands?.length > 0) {
      pullSubBrands(value.brands);
      setFilter((prev) => ({...prev, ...value}));
    } else {
      setFilter((prev) => ({
        ...prev,
        ...value,
        subBrands: [],
      }));
      value.subBrands = [];
    }
  };

  const handleChangePage = (params) => {
    setPage(params);
    loadData({filter, page: params, pageSize, groupBy: filter.groupBy});
  };

  async function handleChangePageSize(params: any) {
    setPageSize(params);
    setPage(0);
    loadData({filter, page: 0, pageSize: params, groupBy: filter.groupBy}, true);
    resetCheckboxes();
  }

  async function onDeleteItem(item, closeModal) {
    resetCheckboxes();
    try {
      await Api.delete(`amazon-listings/item`, {
        params: {
          marketplace_id: item.marketplace_id,
          brand_code: item.brand_code,
          seller_id: item.seller_id,
          seller_sku: item.seller_sku,
        },
      });
      setDeleteItem(null);
      alertService.success('Item deleted successfully');
      loadData({filter, page: 0, pageSize, groupBy: filter.groupBy});
      closeModal();
    } catch (e) {
      setDeleteItem(null);
      errorAlert('Unable to delete item', e);
    }
  }

  async function updateCustomAttr(selectedAttributes, value, field) {
    const seller_skus = selectedAttributes.data.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );

    const attributes = seller_skus.map((i) => {
      return {
        seller_sku: i.seller_sku,
        marketplace_id: i.marketplace,
        name: selectedAttributes.field,
        value: value,
      };
    });

    setLoadingData(true);

    try {
      await Api.put(`custom-attribute/bulk`, attributes);
      alertService.success('Brand updated successfully');
      selectedAttributes.data.forEach((i) => {
        const foundIndex = listingRows.findIndex((x) => x.id === i.id);
        listingRows[foundIndex][field] = value;
      });
    } catch (e) {
      errorAlert('Unable to update brand', e);
    } finally {
      setLoadingData(false);
      setSelectedCheckboxes(selectedAttributes.field, true, false);
      setSelectedCustomAttrData({field: null, data: []});
    }
  }

  const DataGridToolbar = () => (
    <GridToolbarContainer className="toolbar-container">
      <GridToolbarColumnsButton className={classes.toolbarButton} disabled={loadingData || tableLoading} />
      <DropDownButton buttonType="text" buttonText="Order Columns" bold loading={loadingData || tableLoading}>
        <DraggableList
          items={filter.groupBy ? groupByColumns : orderColumns}
          ignoreItems={['actions']}
          setItems={(v) => {
            filter.groupBy ? setGroupByColumns(v) : setOrderColumns(v);
            setUserPreferences(
              {
                columnVisibilityModel: filter.groupBy ? groupByVisibility : visibilityModel,
                columnsOrder: v.map((x) => x.field),
              },
              filter.groupBy ? `listings-group-by-${filter.groupBy}` : 'listings',
              setTableLoading
            );
            resetCheckboxes();
          }}
        />
      </DropDownButton>
      <GridPagination />
    </GridToolbarContainer>
  );

  useEffect(() => {
    getBrandRoles();
    fetchData();
    if (isFfpBrandType || isEcom3kBrandType) {
      mixPanel({
        eventName: 'Listings',
      });
    }
  }, [isEcom3kBrandType, isFfpBrandType]);

  useEffect(() => {
    getArticle({});
  }, [loadingData]);

  useEffect(() => {
    if (
      [Role.Admin, Role.Manager].includes(accountService.userValue.role) ||
      accountService.userValue?.delete_listings === true
    ) {
      setCustomButtons((prev) => [
        ...prev,
        {
          icon: <Delete />,
          tooltip: 'Delete item',
          onClick: (v) => {
            setDeleteItem(v);
            if (isFfpBrandType) {
              GAEvents('Delete item', 'Click on Delete Button');
            }
          },
        },
      ]);
    }
  }, [GAEvents, isFfpBrandType]);

  useEffect(() => {
    !filter.groupBy && makeColumnFreeze(thisRef, visibilityModel, listingRows);
  }, [visibilityModel, listingRows, filter.groupBy, switchTable]);

  const applyFilters = () => {
    setFilter((prev) => ({
      ...prev,
      groupBy: groupByValue,
    }));
    if (groupByValue !== filter.groupBy) {
      setListingRows([]);
      setCount(0);
      setSelectedRow(null);
    }
    updateQueryParams(location, {
      ...filter,
      groupBy: groupByValue,
      page: 0,
      pageSize,
      isManagedByAgency: filter.isManagedByAgency,
    });
    loadData({filter, page: 0, pageSize, groupBy: groupByValue, isManagedByAgency: filter.isManagedByAgency}, true);
    resetCheckboxes();
  };

  const getBrandDropdownOptionLabel = (option: string) => {
    const brandName = brands.find((x) => x.brand_code === option)?.name;
    return brandName ? (isFfpBrandType ? brandName : `${option} - ${brandName}`) : option;
  };

  return (
    <TableContainer>
      <div style={{display: 'none'}}>
        <CustomColumn thisRef={thisRef} rows={listingRows} buttons={customButtons} height="64px" width="130px" />
      </div>
      <Grid container spacing={1.1} className={classes.listingCard}>
        <Grid item xs={12} sm={6} md={3}>
          <ListSubheader component="div" id="nested-list-subheader">
            Status count
          </ListSubheader>
          <Paper>
            {countLoading ? (
              <SkeletonLoader numberOfLines={10} xs={12} height={14.2} padding={'10px 5px 0 5px'} />
            ) : (
              statusCount.length > 0 && (
                <List>
                  {statusCount.map((x, i) => {
                    return (
                      <Grid container key={i}>
                        <ListItemButton
                          key={i}
                          onClick={() => {
                            loadData({
                              filter: {...filter, statuses: x.status},
                              page: 0,
                              pageSize,
                              groupBy: filter.groupBy,
                            });
                            setFilter((prevState) => ({...prevState, statuses: x.status}));
                          }}
                        >
                          <ListItemText primary={`${x.status}`} secondary={x.count} color={'primary'} />
                        </ListItemButton>
                        {i !== statusCount.length - 1 && <Divider />}
                      </Grid>
                    );
                  })}
                </List>
              )
            )}

            {!countLoading && statusCount.length === 0 && (
              <Grid container className="not-found">
                <Typography variant="body2" color="textSecondary" align="center">
                  No data found
                </Typography>
              </Grid>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ListSubheader component="div" id="nested-list-subheader">
            Issue code count
          </ListSubheader>
          <Paper>
            {countLoading ? (
              <SkeletonLoader numberOfLines={10} xs={12} height={14.2} padding={'10px 5px 0 5px'} />
            ) : (
              codeCount.length > 0 && (
                <List>
                  {codeCount.map((x, i) => {
                    return (
                      <Grid container key={i}>
                        <ListItemButton
                          key={i}
                          onClick={() => {
                            loadData({
                              filter: {...filter, code: x.code},
                              page: 0,
                              pageSize,
                              groupBy: filter.groupBy,
                            });
                            setFilter((prevState) => ({...prevState, code: x.code}));
                          }}
                        >
                          <ListItemIcon>
                            {x.severity === 'ERROR' ? (
                              <ErrorOutline color={'error'} />
                            ) : (
                              <WarningAmber color={'warning'} />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={`Code - ${x.code}`} secondary={x.count} />
                        </ListItemButton>
                        {i !== codeCount.length - 1 && <Divider />}
                      </Grid>
                    );
                  })}{' '}
                </List>
              )
            )}
            {!countLoading && codeCount.length === 0 && (
              <Grid container className="not-found">
                <Typography variant="body2" color="textSecondary" align="center">
                  No data found
                </Typography>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{paddingTop: 30}}>
        <Grid container spacing={1.1} style={{paddingBottom: '20px'}}>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Autocomplete
              multiple
              options={Array.from(
                new Set(
                  brands
                    .map((x) => x.brand_code)
                    .filter((x) => x != null)
                    .sort()
                )
              )}
              size="small"
              getOptionLabel={(option) => getBrandDropdownOptionLabel(option)}
              value={
                accountService.userValue.role === Role.BrandUser
                  ? [accountService.userValue.brand_code]
                  : filter?.brands
                  ? filter.brands
                  : []
              }
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Filter by Accounts." />}
              onChange={(event: object, value: any) => {
                handleChangeBrandFilter({brands: value});
              }}
              disabled={loadingData || brands.length === 0 || isFfpBrandType || appLoading}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Autocomplete
              multiple
              size="small"
              options={subBrands.map((x) => x.brand_code)}
              getOptionLabel={(option) => getBrandDropdownOptionLabel(option)}
              isOptionEqualToValue={(option, value) => option === value}
              value={filter?.subBrands ? filter.subBrands : []}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Filter by Sub Accounts" />}
              onChange={(event: object, value: any) => {
                setFilter((prev) => ({...prev, subBrands: value}));
              }}
              disabled={loadingData || brands.length === 0 || appLoading || subBrandLoading}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Autocomplete
              size="small"
              options={ListingsItemStatuses}
              getOptionLabel={(option) => `${option}`}
              value={filter?.statuses ? filter.statuses : null}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Filter by status" />}
              onChange={(event: object, value: any) => {
                setFilter((prev) => ({...prev, statuses: value}));
              }}
              isOptionEqualToValue={(option, value) => option === value}
              disabled={loadingData || appLoading}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Autocomplete
              multiple={false}
              size="small"
              options={groups}
              getOptionLabel={(option) => `${option.replace('_', ' ').toUpperCase()}`}
              value={groupByValue || null}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Group by" />}
              onChange={(event: object, value: any) => {
                setGroupByValue(value);
              }}
              disabled={loadingData || appLoading}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <SearchBar
              value={filter?.sku || ''}
              placeholder="Filter by SKU"
              onChange={(value) => setFilter((prevState) => ({...prevState, sku: value}))}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <SearchBar
              value={filter.asin || ''}
              placeholder="Filter by ASIN"
              onChange={(value) => {
                setFilter((prevState) => ({...prevState, asin: value}));
              }}
              disabled={loadingData || appLoading}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <SearchBar
              value={filter?.itemTitle || ''}
              placeholder="Filter by Title"
              onChange={(value) => setFilter((prevState) => ({...prevState, itemTitle: value}))}
              disabled={loadingData || appLoading}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <SearchBar
              value={filter?.issue || ''}
              placeholder="Search By Issue Message"
              onChange={(value) => setFilter((prevState) => ({...prevState, issue: value}))}
              disabled={loadingData || appLoading}
            />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <SearchBar
              value={filter?.code || ''}
              placeholder="Search By Issue Code"
              onChange={(value) => setFilter((prevState) => ({...prevState, code: value}))}
              disabled={loadingData || appLoading}
            />
          </Grid>
          {!isFfpBrandType && (
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Autocomplete
                multiple={false}
                size="small"
                options={optionsForManagedByAgency}
                getOptionLabel={(option) => option}
                value={filter.isManagedByAgency || null}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Managed By Agency" />}
                onChange={(event: object, value: any) => {
                  setFilter((prevState) => ({...prevState, isManagedByAgency: value}));
                }}
                isOptionEqualToValue={(option, value) => option === value}
                disabled={loadingData || appLoading}
              />
            </Grid>
          )}
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Button
              size="small"
              disabled={loadingData || appLoading}
              onClick={() => {
                applyFilters();
              }}
            >
              Apply Filters
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={12} position="relative">
        {(loadingData || tableLoading) && <LinearProgress style={{position: 'absolute', width: '100%'}} />}
        {filter?.groupBy ? (
          <CollapsibleDataGrid
            components={{
              Pagination: DataGridToolbar,
              Toolbar: DataGridToolbar,
            }}
            className={`${classes.grid} ${tableLoading && !loadingData ? 'load-headers' : ''} custom-table collapsible`}
            pageSize={pageSize}
            rowCount={+count}
            loading={loadingData}
            page={page}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageSizeChange={(v) => handleChangePageSize(v)}
            disableSelectionOnClick={true}
            onPageChange={(params) => handleChangePage(params)}
            rows={listingRows}
            columns={groupByColumns}
            columnVisibilityModel={groupByVisibility}
            onColumnVisibilityModelChange={(newModel) => {
              let data = {};
              Object.entries(newModel).forEach(([x, v]) => {
                if (v === false) {
                  data = {...data, [x]: v};
                }
              });
              const newOrder = getColumnsItems({
                list: selectedColumns,
                columnsVisibility: data,
                columnsOrder: groupByColumns,
                currentOrder: groupByColumns,
              });
              setGroupByColumns(newOrder);
              setGroupByVisibility(data);
              setUserPreferences(
                {
                  columnVisibilityModel: data,
                  columnsOrder: newOrder.map((x) => x.field),
                },
                `listings-group-by-${filter.groupBy}`,
                setTableLoading
              );
            }}
            accordionContent={
              <CustomDataGrid
                rows={
                  listingRows?.[selectedRow]?.child_asins?.map((x, i) => {
                    return {id: i, ...x};
                  }) || []
                }
                columns={[
                  {
                    field: 'asin',
                    headerName: 'ASIN',
                    minWidth: 120,
                    align: 'left',
                    filterable: false,
                    sortable: false,
                    disableColumnMenu: true,
                  },
                  ...defaultColumns(filter, newColumns, classes).filter((x) => x.field !== 'brand_name'),
                  fixedPlaceHolder({
                    width: 120,
                    buttons: customButtons,
                  }),
                ]}
              />
            }
            selectedRow={selectedRow}
            setSelectedRow={(v) => setSelectedRow(v)}
            clickable={(params) => params.field !== 'actions'}
            rowClassName={(params) => params.field !== 'actions' && 'hover-row'}
          />
        ) : (
          <DataGrid
            components={{
              Pagination: DataGridToolbar,
              Toolbar: DataGridToolbar,
            }}
            onCellEditCommit={(params) => {
              const field = listingRows.find((item) => item.id === params.id);
              const currentValue = field[params.field];
              const columnField = tableFields[params.field];
              const customAttr = columnField.custom_attribute;
              const setNewValue = currentValue !== params.value;
              if (setNewValue) {
                if (customAttr) {
                  let value;
                  if (selectedCustomAttrData.field) {
                    value = !selectedCustomAttr.data.find(
                      (i) =>
                        i.id === value.id && i.seller_sku === value.seller_sku && i.marketplace === value.marketplace
                    )
                      ? {
                          ...selectedCustomAttrData,
                          data: [
                            ...selectedCustomAttrData.data,
                            {seller_sku: field.seller_sku, marketplace: field.marketplace_id, id: field.id},
                          ],
                        }
                      : selectedCustomAttrData;
                  } else {
                    value = {
                      field: columnField.label,
                      data: [{seller_sku: field.seller_sku, marketplace: field.marketplace_id}],
                    };
                  }
                  updateCustomAttr(value, params.value, params.field);
                }
              }
            }}
            className={`${classes.grid} custom-table ${tableLoading && !loadingData ? 'load-headers' : ''}`}
            columnBuffer={1}
            columnThreshold={0}
            rowsPerPageOptions={rowsPerPageOptions}
            autoHeight={true}
            pageSize={pageSize}
            rowCount={+count}
            loading={loadingData}
            page={page}
            pagination
            disableVirtualization
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            disableSelectionOnClick={true}
            onPageChange={(params) => handleChangePage(params)}
            onPageSizeChange={handleChangePageSize}
            rows={listingRows}
            columns={[...orderColumns, fixedPlaceHolder({})]}
            columnVisibilityModel={visibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              let data = {};
              const defaultDataColumns = defaultColumns(filter, newColumns, classes);
              Object.entries(newModel).forEach(([x, v]) => {
                if (v === false) {
                  data = {...data, [x]: v};
                }
              });
              const newOrder = getColumnsItems({
                list: defaultDataColumns,
                columnsVisibility: data,
                columnsOrder: orderColumns,
                currentOrder: orderColumns,
              });
              setVisibilityModel(data);
              setOrderColumns(newOrder);
              setUserPreferences(
                {
                  columnVisibilityModel: data,
                  columnsOrder: newOrder.map((x) => x.field),
                },
                `listings`,
                setTableLoading
              );
              resetCheckboxes();
            }}
            rowHeight={64}
          />
        )}
      </Grid>

      <ModalButton
        openModal={deleteItem !== null}
        modalTitle="Delete Amazon Listings Item"
        hideButton
        closable
        onCloseText="No"
        onCloseAction={() => setDeleteItem(null)}
        actions={(closeModal): JSX.Element => (
          <Button
            onClick={() => {
              if (deleteItem) {
                onDeleteItem(deleteItem, closeModal);
                if (isFfpBrandType) {
                  GAEvents(
                    'Amazon Listings Item',
                    'Delete item',
                    `Listings item deleted: ${deleteItem.seller_sku} on ${deleteItem.marketplace_id}`
                  );
                }
              }
            }}
          >
            Yes
          </Button>
        )}
      >
        <span>
          Are you sure you want to delete item with sku: <b>{deleteItem?.seller_sku}</b>
        </span>
      </ModalButton>

      <Grid item lg={12} md={12}>
        <CustomAlert id="default-alert" />
      </Grid>
    </TableContainer>
  );
}
