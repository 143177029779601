import {useEffect, useState, useRef} from 'react';
import {Edit, Visibility} from '@mui/icons-material';
import {Grid} from '@mui/material';
import {DataGrid, getGridStringOperators, GridColDef, GridColTypeDef} from '@mui/x-data-grid';
// services
import {accountService} from '../../services/account.service';
// utils
import {Api, errorAlert} from '../../utils/api';
import {Role} from '../../utils/role';
import {CustomColumn, makeColumnFreeze, fixedPlaceHolder} from '../../utils/makeColumnFreeze';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import {CustomAlert} from '../Login/components/CustomAlert';

const columns: GridColDef[] = [
  {field: 'asin', headerName: 'Asin', width: 140, type: 'filterString'},
  {field: 'brand', headerName: 'Brand', width: 300, type: 'filterString'},
  //   {field: 'think_tank', headerName: 'Think tank', flex: 1, type: 'filterString'},
  {field: 'account_manager', headerName: 'Account manager', flex: 1, type: 'filterString'},
];

const brandUserColumns: GridColDef[] = [
  {field: 'asin', headerName: 'Asin', flex: 0.5, type: 'filterString'},
  {field: 'brand', headerName: 'Brand', flex: 1.5, sortable: false, filterable: false},
  {field: 'account_manager', headerName: 'Account manager', flex: 1, sortable: false, filterable: false},
];

const stringColumnType: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

function Asins() {
  document.title = 'Stonehenge.ai/Asins';
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData(page, pageSize, {}, {});
  }, []);

  function onPageChange(params) {
    loadData(params.page, pageSize, sort, filter);
    setPage(params.page);
  }

  async function loadData(page, pageSize, sortModel, filterModel) {
    setLoading(true);
    try {
      const {data} = await Api.get('asins', {
        params: {
          page: page,
          pageSize: pageSize,
          sortField: sortModel ? sortModel.field : null,
          sortOrder: sortModel ? sortModel.sort : null,
          filterField: filterModel ? filterModel.columnField : null,
          filterValue: filterModel ? filterModel.value : null,
        },
      });
      setCount(data.count);
      setRows(
        data.rows.map((x, i) => {
          return {id: i, ...x};
        })
      );
    } catch (e) {
      errorAlert('Unable to get Asins', e);
    } finally {
      setLoading(false);
    }
  }

  function handleSortModelChange(params) {
    loadData(page, pageSize, params[0], filter);
    setSort(params[0]);
  }

  function handleFilterModelChange(params) {
    loadData(0, pageSize, sort, params.items[0]);
    setFilter(params.items[0]);
    setPage(0);
  }

  const thisRef = useRef<any>(null);

  const customButtons =
    Role.BrandUser === accountService.userValue.role
      ? [
          {
            icon: <Visibility />,
            url: (v) => `/asins/view/${v.asin}`,
            tooltip: 'View',
          },
        ]
      : [
          {
            icon: <Visibility />,
            url: (v) => `/asins/view/${v.asin}`,
            tooltip: 'View',
          },
          {
            icon: <Edit />,
            url: (v) => `/asins/update/${v.asin}`,
            tooltip: 'Edit',
          },
        ];

  useEffect(() => {
    makeColumnFreeze(thisRef, {}, rows);
  }, [rows]);

  return (
    <TableContainer style={{paddingTop: 50}}>
      <div style={{display: 'none'}}>
        <CustomColumn thisRef={thisRef} rows={rows} buttons={customButtons} />
      </div>
      <Grid item lg={12} style={{width: '100%'}}>
        <DataGrid
          style={{width: '100%'}}
          autoHeight={true}
          pageSize={pageSize}
          page={page}
          rowCount={count}
          columnTypes={{filterString: stringColumnType}}
          onPageChange={onPageChange}
          loading={loading}
          pagination
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          onFilterModelChange={handleFilterModelChange}
          onSortModelChange={handleSortModelChange}
          rows={rows}
          disableSelectionOnClick={true}
          columns={
            Role.BrandUser === accountService.userValue.role
              ? ([...brandUserColumns, fixedPlaceHolder({})] as GridColDef[])
              : ([...columns, fixedPlaceHolder({})] as GridColDef[])
          }
        />
        <CustomAlert id="default-alert" />
      </Grid>
    </TableContainer>
  );
}

export {Asins};
