import {styled, Theme, CSSObject} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = 200;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: 'all 0.2s ease-out',
  overflowX: 'hidden',
  width: 0,
  [theme.breakpoints.up(1024)]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '&.MuiDrawer-root > div': {
    top: '64px',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '45px',
    '& svg': {
      fontSize: '22px',
    },
  },
  '&.MuiDrawer-root > div > div:last-child': {
    position: 'absolute',
    bottom: '75px',
    width: '100%',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiButtonBase-root ': {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  '& .MuiList-root ': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > a': {
      width: '100%',
    },
    '& > a .MuiListItem-root': {
      padding: '5px 10px 5px 22px',
    },

    '& > a:not(:last-child) .MuiListItem-root': {
      borderBottom: theme.palette.mode === 'dark' ? '1px solid #6b6b6b' : '1px solid #ececec',
    },
    '& .MuiListItemIcon-root > svg ': {
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main,
    },
    '& > a .MuiListItem-root:focus, & > a .MuiListItem-root:active': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.dark : theme.palette.primary.main,
      color: '#fff!important',
      '& svg': {
        color: '#fff',
      },
    },
    ' & > a .active-menu': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.dark : theme.palette.primary.main,
      color: '#fff!important',
      '& svg': {
        color: '#fff',
      },
    },
    '& .has-sub-items': {
      borderBottom: 'none',
    },
  },
  '& .sub-menu': {
    '& li': {
      padding: '5px 10px 5px 30px!important',
    },
    '& svg': {
      fontSize: '20px',
    },
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),

    [theme.breakpoints.up(1024)]: {
      '& .MuiListItemText-root': {
        opacity: 1,
        transition: 'visibility 0s, opacity 0.5s linear',
        display: 'block',
      },
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
    [theme.breakpoints.up(1024)]: {
      '& .MuiListItemText-root': {
        opacity: 0,
        transition: 'visibility 0s, opacity 0.5s linear',
        display: 'none',
      },
      '& .MuiList-root > a .MuiListItem-root': {
        padding: '9px 20px',
      },
      '& .MuiListItemIcon-root ': {
        minWidth: 'auto',
      },
    },
  }),
}));

export {DrawerHeader, Drawer};
