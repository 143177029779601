const COLORS = [
  '#0088FE',
  '#00C49F',
  '#d63f7e',
  '#FFBB28',
  '#FF8042',
  '#d63f3f',
  '#d354c2',
  '#106656',
  '#145894',
  '#f390b9',
  '#c7952b',
  '#ce4f0f',
  '#f06666',
  '#911080',
];

export {COLORS};
