import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Grid, Typography, InputLabel, LinearProgress, Select, Box, FormControl, MenuItem} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {Api, errorAlert} from '../../../../utils/api';
import {alertService} from '../../../../services/alert.service';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import DateRangeButtons from '../../../../components/DateRangeButtons/DateRangeButtons';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import {BrandPerformanceSummary} from './components/BrandPerformanceSummary';
import {PerformanceChart} from './components/PerformanceChart';
import {metricOptionsData} from './components/Metrics';
import {BuildMenu} from '../../components/menu';
import {MenuStore} from '../../../../App';
// styless
import {useStyles} from '../../Styles';
// utils
import {onFromToChange} from '../../../../utils/OnFromToChange';
import {updateQueryParams, getDefaultQuery} from '../../../../utils/urlParams';
import {AdvertisingProfile, Brand} from '../../utils/interface';

function BrandAdvertising(props) {
  const classes = useStyles();
  const location = useLocation();
  const {adsType} = props;
  const {id} = useParams<Record<string, string | undefined>>();
  const [brand, setBrand] = useState<Brand>(null);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [adsProfile, setAdsProfile] = useState<AdvertisingProfile>(null);
  const [fromTo, setFromTo] = useState({from: null, to: null, compareFrom: null, compareTo: null});
  const [metric, setMetric] = useState([]);
  const [period, setPeriod] = useState(null);
  const [metricData, setMetricData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [loadingChart, setLoadingChart] = useState(false);
  const [compare, setCompare] = useState(false);

  let history = useHistory();

  const params = {
    from: fromTo.from,
    to: fromTo.to,
    compareFrom: fromTo.compareFrom,
    compareTo: fromTo.compareTo,
    metric: metric?.toString(),
    marketplace_id: adsProfile?.accountInfo?.marketplaceStringId || 'ATVPDKIKX0DER',
  };

  const menuItems = BuildMenu('ads_performance', id, history);

  const getQueryValues = (location, params) => {
    const query = getDefaultQuery(location, params);
    query.metric =
      query.metric && query.metric !== ''
        ? query.metric.split(',')
        : metricOptionsData.hasOwnProperty(adsType)
        ? [Object.keys(metricOptionsData[adsType])[0]]
        : [];
    query.marketplace_id = query.marketplace_id || adsProfile?.accountInfo.marketplaceStringId || 'ATVPDKIKX0DER';
    return query;
  };

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = menuItems;
    });
    loadBrand();
    const defaultQuery = getQueryValues(location, params);

    setFromTo({
      from: defaultQuery.from,
      to: defaultQuery.to,
      compareFrom: defaultQuery.compareFrom,
      compareTo: defaultQuery.compareTo,
    });
    setPeriod(defaultQuery.preset);
    setMetric(defaultQuery.metric);
    setCompare(defaultQuery.compareFrom !== null);
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, []);

  useEffect(() => {
    updateQueryParams(location, params);
  }, [JSON.stringify(params)]);

  async function loadData(query) {
    if (!query.marketplace_id) return;
    setLoadingChart(true);
    const qs = Object.keys(query)
      .map((key) => (query[key] ? `${key}=${query[key]}` : null))
      .filter((item) => item !== null)
      .join('&');
    try {
      const {data} = await Api.get(`advertising/brand-performance/${id}/${adsType}?` + qs);
      if (data) {
        const _summaryData = Object.assign({});
        const _metricData = [];
        for (const property in data) {
          const dataType = property.replace('AdsData', '');
          for (const record of data[property] || []) {
            for (const metricKey in record) {
              if (metricKey === 'reportDate') continue;
              if (!_summaryData.hasOwnProperty(metricKey)) {
                _summaryData[metricKey] = Object.assign({});
              } else {
                if (_summaryData[metricKey]?.hasOwnProperty(dataType)) {
                  _summaryData[metricKey][dataType] =
                    Math.round((_summaryData[metricKey][dataType] + Number(record[metricKey])) * 100) / 100;
                } else {
                  _summaryData[metricKey][dataType] = 0;
                }
              }
              const metricDataIdx = _metricData.findIndex(
                (item) => item.type === dataType && item.metric === metricKey
              );
              if (metric.includes(metricKey)) {
                if (metricDataIdx < 0) {
                  _metricData.push({
                    type: dataType,
                    metric: metricKey,
                    data: [{date: record.reportDate, value: Number(record[metricKey])}],
                  });
                } else {
                  _metricData[metricDataIdx].data?.push({date: record.reportDate, value: Number(record[metricKey])});
                }
              }
            }
          }
        }
        setSummaryData(_summaryData);
        setMetricData(_metricData);
      }
    } catch (e) {
      errorAlert('Unable to get data', e, {id: 'brand-summary'});
    } finally {
      setLoadingChart(false);
    }
  }

  useEffect(() => {
    loadData(getQueryValues(location, params));
  }, [adsType]);

  function toggleCompare(checked) {
    setCompare(checked);
    onFromToChange({
      from: fromTo.from,
      to: fromTo.to,
      cFrom: null,
      cTo: null,
      preset: period,
      compare: checked,
      setFromTo,
    });
  }

  function updateMetric(_metric) {
    setMetric(_metric);
    const newParams = {...params, metric: _metric};
    loadData(newParams);
  }

  async function loadBrand() {
    setLoadingBrand(true);
    try {
      const {data} = await Api.get(`brands/${id}`);
      setBrand(data);
      const adsProfiles = data?.advertising_profiles || [];
      let defaultAdsProfile = adsProfiles.find((adsProfile) => adsProfile.countryCode === 'US');
      if (!defaultAdsProfile) defaultAdsProfile = adsProfiles[0];
      setAdsProfile(defaultAdsProfile);
      const currentParams = {
        ...getQueryValues(location, params),
        marketplace_id: defaultAdsProfile.accountInfo.marketplaceStringId,
      };
      loadData(currentParams);
    } catch (e) {
      errorAlert('Unable to get brands', e, {id: 'ads-performance-tab-alert'});
    } finally {
      setLoadingBrand(false);
    }
  }

  function handleChangeMarketplace(e) {
    const selectedAdsProfile = brand?.advertising_profiles?.find(
      (profile) => profile.accountInfo.marketplaceStringId === e.target.value
    );
    setAdsProfile(selectedAdsProfile);
    const currentParams = {
      ...params,
      marketplace_id: selectedAdsProfile?.accountInfo?.marketplaceStringId,
    };
    loadData(currentParams);
  }

  return loadingBrand ? (
    <TableContainer className="sub-page">
      <Typography>Loading Brand Information ...</Typography>
      <LinearProgress />
    </TableContainer>
  ) : (
    <TableContainer className="sub-page">
      <DateRangeButtons
        className={`${classes.buttonGroup} new-group`}
        fromTo={fromTo}
        compare={compare}
        period={period}
        setPeriod={(v) => setPeriod(v)}
        setFromTo={(v) => setFromTo(v)}
        compareOnChange={(e) => toggleCompare(e.target.checked)}
        compareComponent
        showButton
        buttonOnClick={() => {
          loadData(params);
        }}
        pageTitleComponent={
          <Grid item lg={12} xs={12} style={{textAlign: 'right'}}>
            <PageTitle variant="h2" component="h5" gutterBottom fontSize="24px">
              Individual Brand Performance
            </PageTitle>
            <Box display={'flex'} flexDirection={'row-reverse'}>
              <Typography component="h2" variant="h5" gutterBottom>
                {id}
              </Typography>
              {adsProfile && (
                <FormControl
                  variant="outlined"
                  style={{width: '150px', textAlign: 'left', marginRight: '16px'}}
                  size="small"
                >
                  <InputLabel id="marketplace">Marketplace</InputLabel>
                  <Select
                    value={adsProfile.accountInfo.marketplaceStringId}
                    onChange={handleChangeMarketplace}
                    label={'marketplace'}
                    labelId="marketplace"
                    variant="outlined"
                  >
                    {brand?.advertising_profiles.map((profile) => (
                      <MenuItem
                        key={profile.accountInfo.marketplaceStringId}
                        value={profile.accountInfo.marketplaceStringId}
                      >
                        {profile.countryCode}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          </Grid>
        }
      />
      <Grid item lg={12} xs={12}>
        <BrandPerformanceSummary data={summaryData} />
        <PerformanceChart data={metricData} onMetricChange={updateMetric} metric={metric} loading={loadingChart} />
      </Grid>
    </TableContainer>
  );
}

export {BrandAdvertising};
