import axios, {AxiosInstance, AxiosResponse} from 'axios';
// services
import {accountService} from '../services/account.service';
import {alertService} from '../services/alert.service';

export const Api = (function (): AxiosInstance {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
})();

Api.interceptors.request.use((config) => {
  const access = accountService.userValue?.accessToken;
  if (access) {
    config.headers['Authorization'] = `Bearer ${access}`;
  }
  return config;
});

Api.interceptors.response.use(
  (response): AxiosResponse => {
    return response;
  },
  (error): any => {
    if (error.response && error.response.status === 401) {
      accountService.logout();
      alertService.warn('Session expired, please login again');
    }
    return Promise.reject(error);
  }
);

export const ApiWithCustomToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const errorAlert = (errorMessage, error, id?) => {
  let errorText = errorMessage;
  if (error?.response?.status !== 401) {
    if(error?.response?.data?.message) 
     errorText = errorText + ` ${error.response.data.message}`;
    alertService.error(errorText, id);
  }
};
