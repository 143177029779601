import {AppBar} from '@mui/material';
import {styled} from '@mui/system';

const CustomTabs = styled(AppBar)(({theme}) => ({
  '& .MuiTabs-flexContainer ': {
    justifyContent: 'center',
  },
  '& .MuiTabs-flexContainer > button': {
    padding: '0 1.5em',
  },
  '& .Mui-selected ': {
    fontWeight: 'bold',
  },
  [theme.breakpoints.down(1024)]: {
    '&.asin-tab': {
      position: 'absolute',
      zIndex: 9999,
      top: '-60px',
    },
  },
  [theme.breakpoints.down(767)]: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .Mui-selected': {
      borderBottom: theme.palette.mode === 'dark' ? '2px solid #3887be' : '2px solid #174580',
    },
  },
  [theme.breakpoints.down(620)]: {
    '& .MuiTabs-root': {
      minHeight: 'auto',
    },
    '& .MuiTabs-flexContainer': {
      flexWrap: 'wrap',
    },
    '& .MuiTabs-flexContainer > button': {
      minHeight: 'auto',
      fontSize: '12px',
      padding: '1em',
    },
  },
}));

export default CustomTabs;
