import {useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import {Select} from 'formik-mui';
import * as Yup from 'yup';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Typography,
  Link,
  useTheme,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Stack,
} from '@mui/material';
import {Edit, Close} from '@mui/icons-material';
import {Alert} from '@mui/lab';
// utils
import {Api, errorAlert} from '../../../../../utils/api';
// services
import {accountService} from '../../../../../services/account.service';
import {alertService} from '../../../../../services/alert.service';
// components
import ModalButton from '../../../../../components/ModalButton/ModalButton';
import Button from '../../../../../components/Button/Button';
// styles
import {useStyles} from '../../../../Account/Styles';

const marketplaceValidationSchema = Yup.object().shape({
  sp_auth_marketplace: Yup.string().required('The marketplace is required'),
});

function AuthorizeSpApiForFFPBrand({
  brandCode,
  buttonOnly,
  buttonSize,
  hideButton,
  brandInfo,
  refreshBrand,
  GAEvents,
  subBrand,
  advertisingAPI,
  editable,
}: {
  brandCode: string;
  buttonOnly?: boolean;
  buttonSize?: string;
  hideButton?: boolean;
  brandInfo: any;
  refreshBrand?: () => void;
  GAEvents?: (category, action, label?) => void;
  subBrand?: any;
  advertisingAPI?: JSX.Element;
  editable?: boolean;
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [displayConfirmDisconnectSpApi, setDisplayConfirmDisconnectSpApi] = useState(false);
  const [amazonMarketplaces, setAmazonMarketplaces] = useState([]);
  const [marketplaceToDelete, setMarketplaceToDelete] = useState('');
  const [editBrand, setEditBrand] = useState(false);
  const [newBrandName, setNewBrandName] = useState('');

  async function getMarketplaces() {
    try {
      const {data} = await Api.get('sp-api/marketplaces');
      setAmazonMarketplaces(data);
    } catch (e) {
      errorAlert('Unable to get Amazon Marketplaces', e);
    }
  }

  async function grantSellingPartnerAccess(data, closeModal?: any) {
    try {
      const response = await Api.get(`sp-api/authorization-link/${brandCode}/${data.sp_auth_marketplace}`);
      window.location.href = response.data;
      closeModal?.();
    } catch (e: any) {
      errorAlert('Unable to get authorization link', e);
    }
  }

  async function confirmDisconnectSellingPartnerAccess(closeModal) {
    setDisplayConfirmDisconnectSpApi(false);
    const endpoint = subBrand
      ? `/sp-api/deauthorize/${brandCode}/${marketplaceToDelete}`
      : `/sp-api/deauthorize/brand-user/${marketplaceToDelete}`;
    try {
      const response = await Api.post(endpoint, {});
      if (response.data.ok === true) {
        alertService.success('The Selling Partner API access was revoked.');
      }
      accountService.refreshToken();
      refreshBrand && refreshBrand();
      closeModal();
    } catch (e: any) {
      errorAlert('Unable to revoke Selling Partner API access', e);
    }
  }

  async function updateParentBrand(id, field, value) {
    if (value === '') {
      return;
    }
    try {
      await Api.patch(`brands/${id}`, {[field]: value});
      alertService.success('Brand updated successfully');
      setEditBrand(false);
      setNewBrandName('');
      refreshBrand && refreshBrand();
    } catch (e: any) {
      errorAlert('Unable to update brand', e);
    }
  }

  useEffect(() => {
    getMarketplaces();
  }, []);

  const authorizedMarketplacesModal = (
    <ModalButton
      modalTitle="Please select the marketplace that you want to connect"
      buttonText="Grant New Marketplace for Selling Partner API access"
      buttonSize={buttonSize}
      onClick={() => GAEvents && GAEvents('Click', 'Selling Partner API')}
    >
      {(closeModal) => {
        return (
          <Formik
            initialValues={{
              sp_auth_marketplace: 'ATVPDKIKX0DER',
            }}
            validationSchema={marketplaceValidationSchema}
            onSubmit={(values) => grantSellingPartnerAccess(values, closeModal)}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {({errors, touched, isSubmitting, values: formValues}) => (
              <Form className={classes.form}>
                {brandInfo.sp_api_authorized_marketplaces.find(
                  (marketplace) => marketplace.marketplace_id === formValues.sp_auth_marketplace
                ) && (
                  <Alert color="warning">
                    <Typography>
                      This brand has already granted us Selling Partner API authorization for this marketplace.
                      Authorizing this marketplace again would overwrite the information from the previous
                      authorization.
                    </Typography>
                  </Alert>
                )}
                <FormControl
                  size="small"
                  error={touched['sp_auth_marketplace'] && !!errors['sp_auth_marketplace']}
                  className={classes.inputField}
                  variant={'outlined'}
                >
                  <Field
                    size="small"
                    component={Select}
                    name="sp_auth_marketplace"
                    label="Marketplace"
                    labelId="sp-marketplace-label"
                  >
                    {amazonMarketplaces.map((marketplace) => (
                      <MenuItem key={`marketplace_${marketplace.marketplaceId}`} value={marketplace.marketplaceId}>
                        {marketplace.name} ({marketplace.countryCode})
                      </MenuItem>
                    ))}
                  </Field>
                  <FormHelperText>
                    {touched['sp_auth_marketplace'] && !!errors['sp_auth_marketplace']
                      ? errors['sp_auth_marketplace']
                      : ''}
                  </FormHelperText>
                </FormControl>
                <Button
                  size="large"
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => {
                    GAEvents && GAEvents('Selling Partner API', 'Connect', 'Connect');
                  }}
                >
                  Authorize
                </Button>
              </Form>
            )}
          </Formik>
        );
      }}
    </ModalButton>
  );

  return (
    <>
      {!buttonOnly && (
        <Card variant="outlined" className={classes.connectorCard}>
          <CardHeader title="Amazon Account Connectors" />
          <CardContent>
            {editable ? (
              <div className={classes.brandInput}>
                {!editBrand ? (
                  <div>
                    <Typography variant="subtitle1">
                      <span>Parent Account Name: </span> {brandInfo?.name}
                    </Typography>
                    <Edit onClick={() => setEditBrand((p) => !p)} />
                  </div>
                ) : (
                  <div>
                    <TextField
                      variant="outlined"
                      name="name"
                      label="Account name"
                      type="text"
                      size="small"
                      defaultValue={brandInfo?.name}
                      onChange={(e) => setNewBrandName(e.target.value)}
                    />
                    <Button onClick={() => updateParentBrand(brandInfo?.brand_code, 'name', newBrandName)}>Save</Button>
                    <Close onClick={() => setEditBrand((p) => !p)} />
                  </div>
                )}
              </div>
            ) : (
              <Typography variant="subtitle1">
                <span>Parent Account Name: </span> {brandInfo?.name}
              </Typography>
            )}
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Divider />
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography
                  component="h6"
                  color={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
                  gutterBottom
                >
                  Marketplaces:
                </Typography>

                {amazonMarketplaces.map((marketplace) => (
                  <Stack flexDirection={'row'} justifyContent="space-between" key={`mp_${marketplace.marketplaceId}`}>
                    <Typography>{marketplace.name}</Typography>
                    {brandInfo?.sp_api_authorized_marketplaces.find(
                      (x) => x.marketplace_id === marketplace.marketplaceId
                    ) ? (
                      <Link
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          setMarketplaceToDelete(marketplace.marketplaceId);
                          setDisplayConfirmDisconnectSpApi(true);
                        }}
                      >
                        Disconnect
                      </Link>
                    ) : (
                      <Link
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          grantSellingPartnerAccess({sp_auth_marketplace: marketplace.marketplaceId});
                        }}
                      >
                        Connect
                      </Link>
                    )}
                  </Stack>
                ))}
              </div>
            </div>
            {advertisingAPI && (
              <>
                <Divider />
                {advertisingAPI}
              </>
            )}
          </CardContent>
        </Card>
      )}
      {buttonOnly && authorizedMarketplacesModal}
      <ModalButton
        openModal={displayConfirmDisconnectSpApi}
        hideButton
        closable
        onCloseText="Disagree"
        onCloseAction={() => setDisplayConfirmDisconnectSpApi(false)}
        modalTitle="Are you sure you want to disconnect the Selling Partner API access to this marketplace?"
        actions={(closeModal): JSX.Element => (
          <Button
            onClick={() => {
              confirmDisconnectSellingPartnerAccess(closeModal);
              GAEvents && GAEvents('Selling Partner API', 'Revoke Marketplace', marketplaceToDelete);
            }}
          >
            Agree
          </Button>
        )}
        buttonText="Disconnect"
      >
        {(closeModal) => {
          return (
            <>
              This cannot be undone. After the Selling Partner API access is disconnected, Stonehenge will no longer
              pull the brand's information from this marketplace until the access is granted again.
            </>
          );
        }}
      </ModalButton>
    </>
  );
}

export {AuthorizeSpApiForFFPBrand};
