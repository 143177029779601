import {Skeleton, Grid} from '@mui/material';

interface SkeletonLoaderProps {
  height?: number;
  animation?: 'wave' | 'pulse';
  numberOfLines?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  padding?: number | string;
}

export default function SkeletonLoader({
  height,
  animation = 'wave',
  numberOfLines,
  xs,
  sm,
  md,
  lg,
  padding = 0,
}: SkeletonLoaderProps) {
  return (
    <Grid xs={xs} sm={sm} md={md} lg={lg} item style={{padding: padding, width: '100%'}}>
      {Array.from(Array(numberOfLines).keys()).map((i) => (
        <Skeleton key={i} height={height} animation={animation} />
      ))}
    </Grid>
  );
}
