import {createContext, useState, useMemo} from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {CssBaseline} from '@mui/material';
interface ProviderProps {
  children?: any;
}

interface ColorModeState {
  toggleColorMode: any;
  colorMode: boolean;
  setColorMode: (boolean) => void | null;
}

const initialState: ColorModeState = {
  toggleColorMode: null,
  colorMode: window.localStorage.getItem('colorMode') === 'true' ? true : false,
  setColorMode: null,
};

export const ColorModeContext = createContext(initialState);

function ColorModeProvider(props: ProviderProps) {
  const [colorMode, setColorMode] = useState(initialState.colorMode);

  const toggleColorMode = () => {
    setColorMode((prevMode) => (prevMode === true ? false : true));
    window.localStorage.setItem('colorMode', colorMode ? 'false' : 'true');
  };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colorMode ? 'dark' : 'light',
          primary: {
            light: '#fff',
            main: colorMode ? '#3c8dc7' : '#174582',
            dark: colorMode ? '#307aaf' : '#052d61',
            contrastText: '#fff',
          },
          secondary: {
            light: '#ffffff',
            main: colorMode ? '#64aada' : '#174582',
            dark: colorMode ? '#5596c5ac' : '#1b2132',
            contrastText: '#000',
          },
          success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: '#fff',
          },
          text: {
            primary: colorMode ? '#f9f9f9' : 'rgba(0, 0, 0, 0.87)',
            secondary: colorMode ? '#d8d8d8' : '#24242488',
          },
          background: {
            default: colorMode ? '#353535' : '#fff',
            paper: colorMode ? '#444444' : '#fff',
          },
        },
        typography: {
          fontFamily: [
            'Karla',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        },
        components: {
          MuiTab: {
            styleOverrides: {
              root: {
                maxWidth: 'none',
              },
            },
          },
        },
      }),
    [colorMode]
  );

  return (
    <ColorModeContext.Provider value={{colorMode, setColorMode, toggleColorMode}}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default ColorModeProvider;
