import React, {memo} from 'react';
import {isArray} from 'lodash';
import {Typography, Divider, Tooltip, Chip} from '@mui/material';
import {Help} from '@mui/icons-material';
import _ from 'lodash';
import {FieldArray} from 'formik';
import moment from 'moment';
// utils
import getLabel from '../../../utils/getLabel';
//styles
import {useStyles} from '../Styles';
// components
import ListingsInputFields from './ListingsInputFields';
import ListingsAttrContainer from './ListingsAttrContainer';

export const getOptions = ['standardized', '_enums', 'marketplace_id', 'language_tag'];

export const getError = (data) => {
  const errors = [];
  const {
    key,
    label,
    currentValue,
    maxLength,
    min,
    max,
    minLength,
    UTFSize,
    maxUniqueItems,
    isRequired,
    messages,
    duplicated,
    itemRequiredFields,
  } = data;
  const errorData = {
    id: key.join('.'),
    label: label.join('.'),
    type: itemRequiredFields.some((item) => key.includes(item)) ? 'error' : 'warning',
  };

  if (isRequired && (currentValue === null || currentValue === '' || currentValue === undefined)) {
    errors.push({
      ...errorData,
      message: `${errorData.type === 'error' ? '' : 'Filling'} ${messages.required} ${
        errorData.type === 'error' ? 'is required' : 'is recommended'
      }`,
    });
  }

  if (UTFSize) {
    if (new Blob([currentValue]).size > UTFSize) {
      errors.push({...errorData, message: messages.UTFSize});
    }
  }

  if (maxLength) {
    if (currentValue?.length > maxLength) {
      errors.push({...errorData, message: messages.maxLength});
    }
  }

  if (minLength) {
    if (currentValue?.length < minLength) {
      errors.push({...errorData, message: messages.minLength});
    }
  }

  if (min) {
    if (currentValue < min) {
      errors.push({...errorData, message: messages.min});
    }
  }

  if (currentValue > max) {
    errors.push({...errorData, message: messages.max});
  }

  if (currentValue > maxUniqueItems) {
    errors.push({...errorData, message: messages.maxUniqueItems});
  }

  if (duplicated) {
    errors.push({...errorData, message: messages.minUniqueItems});
  }

  return errors;
};

export const createNewField = (verification, defaultValues?) => {
  if (verification.type && verification.type === 'array') {
    return createNewField(verification.items, defaultValues);
  } else if (verification.type && verification.type === 'object') {
    return createNewField(verification.properties, defaultValues);
  } else {
    let fields = {};
    Object.entries(verification).forEach(([key, value]: any) => {
      if (value.type && value.type === 'array') {
        fields = {...fields, [key]: [createNewField(value.items, defaultValues)]};
      } else if (value.type && value.type === 'object') {
        fields = {...fields, [key]: createNewField(value.properties, defaultValues)};
      } else {
        let newValue = null;
        if (value.type === 'string' && !value.default) {
          newValue = '';
        } else if (value.default) {
          newValue = value.default;
        } else if (getOptions.includes(key)) {
          newValue = defaultValues[key].default;
        }
        fields = {...fields, [key]: newValue};
      }
    });
    return fields;
  }
};

export default memo(function AttributeList({
  attributes,
  defaultKey,
  label,
  validations,
  setFieldValue,
  parentRequiredFields,
  defaultValues,
  disabledForm,
  itemRequiredFields,
}: any) {
  const requiredFields = [...parentRequiredFields];
  const classes = useStyles();

  function inputNumber(e, integer?) {
    e = e ? e : window.event;
    let charCode = e.which ? e.which : e.keyCode;
    if (integer && charCode === 46) {
      e.preventDefault();
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode !== 46 || charCode === 8)) {
      e.preventDefault();
    } else {
      let index = e.target.value.indexOf('.');
      if (index > 0 && charCode === 46) {
        e.preventDefault();
      }
    }
  }

  function newFields(value, key, label) {
    const validationKey = label?.join('.');
    const currentValue = value === null || value === undefined ? '' : value;
    const validation = validations?.[validationKey];
    let options;
    let type = 'string';
    let UTFSize = validation?.maxUtf8ByteLength;
    let minLength = validation?.minLength;
    let maxLength = validation?.maxLength;
    let min = validation?.minimum;
    let max = validation?.maximum;
    let title = validation?.title;
    let maxUniqueItems = validation?.maxUniqueItems || validation?.maxItems;
    let minUniqueItems = validation?.minUniqueItems;
    let hidden = validation?.hidden;
    let editable = validation?.editable;
    let defaultVal = validation?.default;
    let minItems = validation?.minItems;
    let maxItems = validation?.maxItems;
    let required = validation?.items?.required || validation?.required;
    let description = validation?.description;
    let multiple = false;
    let disabled;

    switch (title) {
      case 'Variation Theme Name':
        title = 'Parentage variation theme';
    }

    if (required && required.length > 0) {
      const filteredRequired = required
        .filter((i) => {
          return !getOptions.includes(i);
        })
        .map((i, x) => {
          return `attributes.${label.join('.')}.${i}`;
        });
      requiredFields.push(...filteredRequired);
    }

    if (validation?.enum) {
      const option = _.zipObject(validation?.enumNames, validation?.enum);
      options = Object.entries(option).map(([key, value]) => {
        return {label: key, value};
      });
    }

    if (validation?.anyOf) {
      const option = _.zipObject(validation?.anyOf[1].enumNames, validation?.anyOf[1].enum);
      options = Object.entries(option).map(([key, value]) => {
        return {label: key, value};
      });
    }

    if (validation?.oneOf) {
      type = validation?.oneOf[0]?.format;
    } else if (validation?.type) {
      type = validation?.type;
    }

    const isRequired = requiredFields?.includes(`attributes.${label.join('.')}`);

    const messages = {
      required: `${title ? title : label?.[label?.length - 1]}`,
      min: `Minimum value for ${title ? title : label?.[label?.length - 1]} is ${min}`,
      max: `Maximum value for ${title ? title : label?.[label?.length - 1]} is ${max}`,
      minLength: `Value must be at least ${minLength} characters`,
      maxLength: `Maximum value length is ${maxLength} characters`,
      minUniqueItems: `Duplicate values are not allowed`,
      UTFSize: `Maximun UTF-8 size is ${UTFSize}`,
    };

    const errors = getError({
      key,
      label,
      currentValue,
      maxLength,
      min,
      max,
      minLength,
      UTFSize,
      maxUniqueItems,
      minUniqueItems,
      isRequired,
      editable,
      messages,
      itemRequiredFields,
    });

    if (editable === false && key.includes('brand') && errors.length === 0) {
      disabled = true;
    }

    if (options && currentValue && typeof currentValue !== 'object' && !isArray(currentValue)) {
      if (!options.find((i) => i.value === currentValue)) {
        options.push({label: currentValue, value: currentValue});
      }
    }

    if (value && isArray(value) && value.length > 0) {
      return (
        <ListingsAttrContainer
          key={key.join('.')}
          rowSpacing={2}
          columnSpacing={{xs: 1, sm: 1, md: 1}}
          className={maxUniqueItems !== 1 && maxItems !== 1 ? classes.subFields : classes.singleSubFields}
        >
          <FieldArray name={key.join('.')} validateOnChange={false}>
            {({push, remove}) => {
              const duplicated =
                currentValue
                  .map((item) => JSON.stringify(item))
                  .filter((item, index, self) => self.indexOf(item) !== index).length >= minUniqueItems;
              return (
                <React.Fragment key={key.join('.')}>
                  {currentValue.length > 0 ? (
                    currentValue.map((item, index) => {
                      return (
                        <ListingsAttrContainer
                          className={classes.arrayItem}
                          rowSpacing={2}
                          columnSpacing={{xs: 1, sm: 1, md: 1}}
                          key={`${key.join('.')}.${index}`}
                        >
                          <div className={classes.customDivider}>
                            <Typography className={classes.fieldHeader}>
                              {title ? title : getLabel(label?.[label?.length - 1])} {value.length > 1 && index + 1}
                              <Tooltip title={`${description}`}>
                                <Help />
                              </Tooltip>
                            </Typography>

                            <Divider />
                            {value.length > minItems && (
                              <Chip
                                label="- Delete"
                                onClick={() => {
                                  remove(index);
                                }}
                              />
                            )}
                          </div>
                          {getField(item, [...key, index], [...label])}
                        </ListingsAttrContainer>
                      );
                    })
                  ) : (
                    <ListingsAttrContainer
                      className={classes.arrayItem}
                      rowSpacing={2}
                      columnSpacing={{xs: 1, sm: 1, md: 1}}
                      key={`${key.join('.')}`}
                    >
                      <div className={classes.customDivider}>
                        <Typography className={classes.fieldHeader} component="h6">
                          {getLabel(label?.[label?.length - 1])}
                        </Typography>
                        <Divider />
                      </div>
                    </ListingsAttrContainer>
                  )}
                  {duplicated && (
                    <Typography color="error" className={`${classes.errorMessage} get-error`}>
                      {messages.minUniqueItems}
                    </Typography>
                  )}
                  {!disabledForm && (
                    <>
                      {maxItems || maxUniqueItems ? (
                        (value.length < maxItems || value.length < maxUniqueItems) && (
                          <div className={`${classes.customDivider} add-btn`}>
                            <Chip
                              label="+ Add"
                              onClick={() => {
                                push(createNewField(validation, defaultValues));
                              }}
                            />
                            <Divider />
                          </div>
                        )
                      ) : (
                        <div className={`${classes.customDivider} add-btn`}>
                          <Chip
                            label="+ Add"
                            onClick={() => {
                              push(createNewField(validation, defaultValues));
                            }}
                          />
                          <Divider />
                        </div>
                      )}
                    </>
                  )}
                </React.Fragment>
              );
            }}
          </FieldArray>
        </ListingsAttrContainer>
      );
    } else if (currentValue && typeof currentValue === 'object' && !isArray(currentValue)) {
      return (
        <ListingsAttrContainer
          key={key.join('.')}
          rowSpacing={2}
          columnSpacing={{xs: 1, sm: 1, md: 1}}
          label={disabledForm && getLabel(label?.[label?.length - 1])}
          subLabel={disabledForm}
        >
          {getField(value, [...key], [...label])}
        </ListingsAttrContainer>
      );
    } else {
      return !hidden ? (
        <ListingsInputFields
          multiple={multiple}
          key={key.join('.')}
          name={key.join('.')}
          value={defaultVal ? defaultVal : type === 'date' ? value : currentValue}
          label={title ? title : label?.[label?.length - 1]}
          type={options ? 'autocomplete' : type}
          options={options && options.map((option) => option.value)}
          optionsLabel={(option: string) => options?.find((o) => o.value === option)?.label || option || ''}
          className={type === 'date' && classes.dateField}
          disabled={disabledForm ? disabledForm : disabled}
          fullWidth={type === 'string' && maxLength && !options && maxLength > 50}
          onChange={(e) => {
            if (type === 'date') {
              const date = moment(e).format().toString();
              setFieldValue(key.join('.'), date);
            } else {
              setFieldValue(key.join('.'), e);
            }
          }}
          required={isRequired}
          onKeyPress={(e) => {
            if (type === 'number') {
              inputNumber(e);
            }
            if (type === 'integer') {
              inputNumber(e, true);
            }
            if (UTFSize) {
              if (new Blob([currentValue]).size > UTFSize) {
                e.preventDefault();
              }
            }
            if (maxLength) {
              if (currentValue?.length > maxLength) {
                e.preventDefault();
              }
            }
            if (currentValue > max) {
              e.preventDefault();
            }
          }}
          showError={
            errors.length > 0 &&
            errors.map((error, index) => (
              <Typography
                component="span"
                color={error.type === 'error' ? 'red' : '#f3be1f'}
                className={`${classes.errorMessage} field`}
                key={index}
              >
                <React.Fragment key={`${error.id}-${index}`}>
                  <span>{error.message}</span>
                  {index !== errors.length - 1 && ', '}
                </React.Fragment>
              </Typography>
            ))
          }
        />
      ) : null;
    }
  }

  function getField(selectedAttributes, defaultKey, label) {
    let keys = [...defaultKey];
    let labels = label ? [...label] : [];
    if (selectedAttributes) {
      if (typeof selectedAttributes === 'object' && !isArray(selectedAttributes)) {
        return Object.entries(selectedAttributes).map(([key, value], index) => {
          if (!getOptions.some((c) => key?.includes(c))) {
            return (
              <React.Fragment key={[...keys, key].join('.')}>
                {newFields(value, [...keys, key], [...labels, key])}
              </React.Fragment>
            );
          } else {
            return null;
          }
        });
      } else if (isArray(selectedAttributes)) {
        return selectedAttributes?.map((value, index) => {
          return (
            <React.Fragment key={[...keys, index].join('.')}>
              {newFields(value, [...keys, index], [...labels])}
            </React.Fragment>
          );
        });
      }
    }
  }

  return <React.Fragment key={defaultKey.join('.')}>{getField(attributes, defaultKey, [label])}</React.Fragment>;
});
