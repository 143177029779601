import {useCallback, useLayoutEffect, useRef} from 'react';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
// styles
import {useStyles} from '../../pages/Listings/Styles';

interface CollapsibleDataGridProps {
  className?: string;
  pageSize?: number;
  rowCount?: number;
  rows?: any[];
  columns?: GridColDef[];
  page?: number;
  rowsPerPageOptions?: number[];
  disableSelectionOnClick?: boolean;
  onPageChange?: (page: number) => void;
  loading: boolean;
  selectedRow: any;
  setSelectedRow: (row: any) => void;
  accordionContent: JSX.Element | any;
  onPageSizeChange?: (rowsPerPage: number) => void;
  clickable?: ((params: any) => boolean) | boolean;
  rowClassName?: ((params: any) => string) | string;
  multiple?: boolean;
  gridName?: string;
  components?: any;
  columnVisibilityModel?: any;
  onColumnVisibilityModelChange?: (model: any) => void;
}

export default function CollapsibleDataGrid({
  className,
  pageSize,
  rowCount,
  rows,
  columns,
  page,
  rowsPerPageOptions,
  disableSelectionOnClick,
  onPageChange,
  loading,
  selectedRow,
  setSelectedRow,
  accordionContent,
  onPageSizeChange,
  clickable,
  rowClassName,
  multiple,
  gridName,
  components,
  columnVisibilityModel,
  onColumnVisibilityModelChange,
}: CollapsibleDataGridProps) {
  const classes = useStyles();
  const info = multiple && accordionContent();
  const thisRef = useRef<any>(null);

  const setAccordion = (row) => {
    if (multiple) {
      if (document.getElementById(`ref-${row}-clone`)) {
        setSelectedRow(null);
        document.getElementById(`ref-${row}-clone`)?.remove();
      } else {
        setSelectedRow(row);
      }
    } else {
      setSelectedRow((p) => (p === row ? null : row));
      document.getElementById(`accordion-${selectedRow}-clone`)?.remove();
    }
  };
  const showAccordion = useCallback(
    (item) => {
      const id = multiple ? item?.id : selectedRow;

      const row = document.querySelector(`div[data-id="${id}"]`);
      if (multiple) {
        const comp = document.getElementById(`ref-${id}`)?.cloneNode(true) as HTMLElement;
        if (comp) {
          comp.id = `ref-${id}-clone`;
        }
        if (comp && row && !document.getElementById(`ref-${id}-clone`)) {
          row?.parentNode?.insertBefore(comp, row.nextSibling);
        }
      }
      if (!multiple && !document.getElementById(`accordion-${id}-clone`) && row) {
        const comp = thisRef.current;
        if (comp) {
          comp.id = `accordion-${id}-clone`;
        }
        row?.parentNode?.insertBefore(comp, row.nextSibling);
      }
    },
    [multiple, selectedRow]
  );

  const updateAccordion = useCallback(() => {
    if (loading) {
      const getAll = document.querySelectorAll('.custom-accordion');
      getAll.forEach((item) => {
        item.remove();
      });
    }
    if (!multiple) {
      if (selectedRow !== null && !loading && rows.length > 0) {
        showAccordion(selectedRow);
      } else {
        document.getElementById(`accordion-${selectedRow}`)?.remove();
        document.getElementById('accordion-null')?.remove();
      }
    } else {
      if (!loading && rows.length > 1 && selectedRow === 'all') {
        info.forEach((item) => {
          showAccordion(item);
        });
      }
      if (!loading && rows.length > 1 && selectedRow !== 'all') {
        showAccordion(info[selectedRow]);
      }
    }
  }, [selectedRow, loading, info, multiple, rows, showAccordion]);

  useLayoutEffect(() => {
    updateAccordion();
  }, [selectedRow, loading, updateAccordion]);

  return (
    <>
      {!loading && (
        <div style={{display: 'none'}}>
          {multiple ? (
            info.map((i, x) => (
              <div key={x} ref={thisRef} id={`ref-${x}`} className="custom-accordion">
                {i.content}
              </div>
            ))
          ) : (
            <div ref={thisRef} className="custom-accordion">
              {accordionContent && accordionContent}
            </div>
          )}
        </div>
      )}
      <DataGrid
        components={components}
        rowHeight={64}
        disableVirtualization
        className={`${classes.customGrid} ${className} collapsible`}
        autoHeight={true}
        pageSize={pageSize}
        rowCount={rowCount}
        loading={loading}
        page={page}
        pagination
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        rowsPerPageOptions={rowsPerPageOptions}
        onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
        disableSelectionOnClick={disableSelectionOnClick}
        onPageChange={(params) => onPageChange(params)}
        rows={rows}
        columns={columns}
        getRowClassName={(params) => {
          if (typeof rowClassName === 'function') {
            return rowClassName(params);
          } else {
            return rowClassName;
          }
        }}
        onCellClick={(params) => {
          if (typeof clickable === 'function') {
            if (clickable(params)) {
              setAccordion(params.id);
            }
          } else {
            if (clickable) {
              setAccordion(params.id);
            }
          }
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(model) => onColumnVisibilityModelChange && onColumnVisibilityModelChange(model)}
      />
    </>
  );
}
