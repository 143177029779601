import {useEffect, useState} from 'react';
import {Alert} from '@mui/lab';
import {Grid} from '@mui/material';
import {DataGrid, getGridStringOperators, GridColDef, GridColTypeDef} from '@mui/x-data-grid';
// utils
import {Api, errorAlert} from '../../utils/api';
// components
import {CustomAlert} from '../Login/components/CustomAlert';

const columns: GridColDef[] = [
  {field: 'date', headerName: 'Date', width: 220, type: 'dateTime'},
  {
    field: 'seller_sku',
    headerName: 'SKU',
    width: 300,
    type: 'filterString',
  },
  {field: 'asin', headerName: 'ASIN', width: 150, type: 'filterString'},
  {
    field: 'marketplace_id',
    headerName: 'Marketplace',
    width: 250,
    type: 'filterString',
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 400,
    type: 'filterString',
  },
];

const stringColumnType: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

function ProductsErrors() {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    loadData(page, pageSize, {}, {});
  }, []);

  function onPageChange(params) {
    loadData(params.page, pageSize, sort, filter);
    setPage(params.page);
  }

  async function loadData(page, pageSize, sortModel, filterModel) {
    try {
      const {data} = await Api.get('products-errors', {
        params: {
          page: page,
          pageSize: pageSize,
          sortField: sortModel ? sortModel.field : null,
          sortOrder: sortModel ? sortModel.sort : null,
          filterField: filterModel ? filterModel.columnField : null,
          filterValue: filterModel ? filterModel.value : null,
        },
      });
      setCount(data.count);
      setRows(
        data.rows.map((x, i) => {
          return {id: i, ...x};
        })
      );
    } catch (e) {
      errorAlert('Unable to get brands', e);
    }
  }

  function handleSortModelChange(params) {
    loadData(page, pageSize, params.sortModel[0], filter);
    setSort(params.sortModel[0]);
  }

  function handleFilterModelChange(params) {
    loadData(page, pageSize, sort, params.filterModel.items[0]);
    setFilter(params.filterModel.items[0]);
  }

  return (
    <div>
      <div style={{height: 20}} />
      <Alert color="info">
        For errors of type FBA_INB_XXXX, refer to&nbsp;
        <a
          style={{color: 'blue'}}
          target="_blank"
          rel="noreferrer"
          href="https://github.com/amzn/selling-partner-api-docs/blob/bcc9de8bde8b9584006c84c3656215c351edfdc0/references/fba-inbound-eligibility-api/fbaInbound.md#ineligibilityreasonlist"
        >
          this page
        </a>
        .
      </Alert>

      <Grid container spacing={1} style={{paddingTop: 30}}>
        <Grid item lg={12} style={{width: '100%'}}>
          <DataGrid
            autoHeight={true}
            pageSize={pageSize}
            page={page}
            rowCount={count}
            columnTypes={{filterString: stringColumnType}}
            onPageChange={onPageChange}
            pagination
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            onSortModelChange={handleSortModelChange}
            disableSelectionOnClick={true}
            rows={rows}
            columns={columns}
          />
          <CustomAlert id="default-alert" />
        </Grid>
      </Grid>
    </div>
  );
}

export {ProductsErrors};
