import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import {Alert} from '@mui/material';
import Fade from '@mui/material/Fade';
// services
import {alertService} from '../../../services/alert.service';

const useStyles = makeStyles((theme) => ({
  alert: {
    position: 'fixed',
    top: '20px',
    maxWidth: '500px',
    width: '100%',
    zIndex: 1301,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

function CustomAlert({id, ...props}) {
  const classes = useStyles();
  const [alerts, setAlerts] = useState([]);
  let history = useHistory();

  useEffect(() => {
    // subscribe to new alert notifications
    const subscription = alertService.onAlert(id).subscribe((alert) => {
      // clear alerts when an empty alert is received
      if (!alert.message) {
        setAlerts((alerts) => {
          // filter out alerts without 'keepAfterRouteChange' flag
          const filteredAlerts = alerts.filter((x) => x.keepAfterRouteChange);

          // remove 'keepAfterRouteChange' flag on the rest
          filteredAlerts.forEach((x) => delete x.keepAfterRouteChange);
          return filteredAlerts;
        });
      } else {
        // add alert to array
        const alertWithFade = {...alert, fade: true};

        if (props.unique) {
          setAlerts([alertWithFade]);
        } else {
          setAlerts((alerts) => [...alerts, alertWithFade]);
        }

        // auto close alert if required
        if (alertWithFade.autoClose) {
          setTimeout(() => removeAlert(alertWithFade), 5000);
        }
      }
    });

    //clear alerts on location change
    const historyUnlisten = history.listen(({pathname}) => {
      // don't clear if pathname has trailing slash because this will be auto redirected again
      if (pathname && pathname.endsWith('/')) return;

      alertService.clear(id);
    });

    // clean up function that runs when the component unmounts
    return () => {
      // unsubscribe & unlisten to avoid memory leaks
      subscription.unsubscribe();
      historyUnlisten();
    };
  }, []);

  function removeAlert(alert) {
    // fade out alert
    const alertWithFade = {...alert, fade: false};
    setAlerts((alerts) => alerts.map((x) => (x === alert ? alertWithFade : x)));

    // remove alert after faded out
    setTimeout(() => {
      setAlerts((alerts) => alerts.filter((x) => x !== alertWithFade));
    }, 500);
  }

  if (!alerts.length) return null;

  return (
    <div className={`${classes.alert} ${props.className ? props.className : ''}`}>
      {alerts.map((alert, index) => (
        <Fade key={alert.id} in={alert.fade} timeout={400}>
          <Alert
            onClose={() => {
              removeAlert(alert);
            }}
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Fade>
      ))}
    </div>
  );
}

// CustomAlert.propTypes = propTypes;
// CustomAlert.defaultProps = defaultProps;
export {CustomAlert};
