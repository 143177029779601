import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    '&:first-of-type': {
      alignItems: 'flex-end',
      padding: theme.spacing(0, 3),
    },
    '&:last-of-type': {
      alignItems: 'flex-start',
      '& button': {
        marginBottom: theme.spacing(1),
      },
    },
    [theme.breakpoints.down(900)]: {
      '&:first-of-type, &:last-of-type': {
        alignItems: 'center!important',
      },
      '&:first-of-type': {
        padding: theme.spacing(3, 0),
      },
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: theme.spacing(0, 1),
    '& .MuiInputLabel-shrink': {
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main,
    },
  },
  brandInput: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      '& h6 > span': {
        color: theme.palette.secondary.main,
        fontSize: '18px',
      },
      '& > svg': {
        margin: '0 5px',
        cursor: 'pointer',
        fontSize: '18px',
        '&:hover': {
          color: theme.palette.secondary.main,
        },
      },
      '& > button': {
        margin: '0 5px',
      },
    },
  },
  inputField: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0',
  },
  card: {
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0px 0px 6px 0px #94949433',
    '& h4': {
      fontWeight: 'bold',
      margin: '0 10px',
      fontSize: '22px',
    },
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    padding: '18px 24px',
    textAlign: 'center',
    color: '#fff',
    [theme.breakpoints.down(700)]: {
      '& h4': {
        fontSize: '20px',
      },
    },
  },
  cardContent: {
    padding: theme.spacing(3),
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main,

    '& ul': {
      marginTop: theme.spacing(2),
    },
    '& li span': {
      fontWeight: 'bold',
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#858585',
    },
    '& svg': {
      color: '#e4cd4c',
    },
  },
  cardFooter: {
    padding: theme.spacing(0, 3, 4, 3),
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        fontSize: '16px!important',
      },
    },
  },
  connectorCard: {
    background: theme.palette.background.default,
    boxShadow: '0 0 10px 0 rgba(143, 143, 143, 0.1)',
    '& hr': {
      margin: '10px 0',
    },
    '& button': {
      width: 'fit-content',
    },
    '& .MuiCardHeader-content span': {
      fontSize: '1.3rem',
      textAlign: 'center',
      color: '#fff',
    },
    '& .MuiCardContent-root h6': {
      fontSize: '1.1rem',
      lineHeight: '1.4',
    },
    '& .MuiCardContent-root': {
      justifyContent: 'center',
    },
    '& .MuiCardHeader-root': {
      background: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.primary.main,
    },
    '& .MuiCardActions-root': {
      justifyContent: 'center',
    },
    [theme.breakpoints.down(767)]: {
      '& .MuiCardHeader-content span': {
        fontSize: '1rem',
      },
      '& .MuiCardContent-root h6, & .MuiCardContent-root span, & .MuiCardContent-root p': {
        fontSize: '.9rem',
      },
    },
  },
}));

export {useStyles};
