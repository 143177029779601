export const FFPArticles = {
  '/account/billing': {
    id: '6231040ec1e53608cf9e8db4',
    name: 'Payments and Billings',
  },
  '/account/connectors': [
    {
      id: '623102512ce7ed0fb0915be1',
      name: 'Post Registration',
      type: 'modal',
    },
    {
      id: '62310435af6f8700f6daf893',
      name: 'Connectors',
      type: 'sidebar',
    },
  ],
  '/account/users': {
    id: '62310420af6f8700f6daf892',
    name: 'Users and Permissions',
  },
  '/amazon-listings-items': {
    id: '623102c4c1e53608cf9e8dad',
    name: 'Listings Page',
  },
  '/edit': {
    id: '62310308c1e53608cf9e8db0',
    name: 'Listing Edit',
  },
  '/versions': {
    id: '62310368ab585b230a8a0ff6',
    name: 'Listing Version',
  },
};

export const ecom3kArticles = {
  '/account/billing': {
    id: '62a8eb0506aa9755784623bf',
    name: 'Payments and Billings',
  },
  '/account/connectors': [
    {
      id: '62a8eb24cb3c130081c14229',
      name: 'Post Registration',
      type: 'modal',
    },
    {
      id: '62a8ead5cb3c130081c14224',
      name: 'Connectors',
      type: 'sidebar',
    },
  ],
  '/account/users': {
    id: '62a8eaf3cb3c130081c14226',
    name: 'Users and Permissions',
  },
  '/update-sub-brand': {
    id: '62a8eb371f353616cdf01e82',
    name: 'Brand Report Settings',
  },
};
