export const brandFields = {
  name: {type: 'text', priority: true, value: null, label: 'Name'},
  account_location: {type: 'text', value: null, label: 'Account Location'},
  account_manager_assistant_id: {type: 'select', options: 'role', value: null, label: 'Account Manager Assistant'},
  account_manager_id: {type: 'select', value: null, label: 'Account Manager'},
  advertising_id: {type: 'select', options: 'role', value: null, label: 'Advertising'},
  case_manager_id: {
    type: 'select',
    options: 'role',
    br_onboarding_cm_id: {type: 'select', options: 'role', value: null, label: 'Brand Onboarding CM'},
    value: null,
    label: 'Case Manager',
  },
  advertising_profiles: {type: 'text', value: null, label: 'Advertising Profiles', createMode: true},
  cs_manager_id: {type: 'select', options: 'role', value: null, label: 'CS Manager'},
  customer_service_manager_id: {type: 'select', options: 'role', value: null, label: 'Customer Service Manager'},
  inactive_seniority: {type: 'select', options: 'role', value: null, label: 'Inactive Seniority'},
  inventory_lead_id: {type: 'select', options: 'role', value: null, label: 'Inventory Lead'},
  inventory_manager_id: {type: 'select', options: 'role', value: null, label: 'Inventory Manager'},
  inventory_onboarding_supervisor_id: {
    type: 'select',
    options: 'role',
    value: null,
    label: 'Inventory Onboarding Supervisor',
  },
  inventory_reporting_id: {type: 'select', options: 'role', value: null, label: 'Inventory Reporting'},
  inventory_supervisor_id: {type: 'select', options: 'role', value: null, label: 'Inventory Supervisor'},
  onboarding_copy_id: {type: 'select', options: 'role', value: null, label: 'Onboarding Copy'},
  onboarding_design_id: {type: 'select', options: 'role', value: null, label: 'Onboarding Design'},
  onboarding_upload_id: {type: 'select', options: 'role', value: null, label: 'Onboarding Upload'},
  // original_account_manager_id: {type: 'select', options: 'role', value: null, label: 'Original Account Manager'},
  parent_brand_code: {type: 'select', value: null, label: 'Parent Brand', options: 'brands'},
  lead_inventory_onboarding_supervisor_id: {
    type: 'select',
    options: 'role',
    value: null,
    label: 'Lead Inventory Onboarding Supervisor',
  },
  catalog_id: {type: 'select', value: null, label: 'Catalog ID', options: 'role'},
  content_id: {type: 'select', value: null, label: 'Content ID', options: 'role'},
  listing_qa_id: {type: 'select', value: null, label: 'Listing QA', options: 'role'},
  marketing_team_member_id: {type: 'select', options: 'role', value: null, label: 'Marketing Team Member'},
  status: {type: 'select', value: null, label: 'Status'},
  type_id: {type: 'select', value: null, label: 'Type'},
  ordering_method_id: {type: 'select', value: null, label: 'Ordering Method', options: 'ordering'},
  agreement_type_id: {type: 'select', value: null, label: 'Agreement Type', options: 'agreement'},
  brand_registered_name: {type: 'text', value: null, label: 'Brand Registered Name'},
  brand_tier: {type: 'text', value: null, label: 'Brand Tier'},
  bu_date: {type: 'date', value: null, label: 'BU Date', createMode: true},
  contract_date: {type: 'date', value: null, label: 'Contract Date'},
  ft_converstion_date: {type: 'date', value: null, label: 'FT Conversation Date'},
  mc_phase_2_date: {type: 'date', value: null, label: 'MC Phase 2 Date'},
  onboarding_begin: {type: 'date', value: null, label: 'Onboarding Begin'},
  category: {type: 'text', value: null, label: 'Category'},
  contact_name: {type: 'text', value: null, label: 'Contact Name'},
  email: {type: 'text', variant: 'email', value: null, label: 'Email'},
  fba: {type: 'boolean', value: false, label: 'FBA'},
  fbm: {type: 'boolean', value: false, label: 'FBM'},
  ft: {type: 'boolean', value: false, label: 'FT'},
  ppe: {type: 'boolean', value: false, label: 'PPE'},
  sell_out: {type: 'boolean', value: false, label: 'Sell Out'},
  on_hold: {type: 'boolean', value: false, label: 'On Hold'},
  requires_verification_photos: {type: 'boolean', value: false, label: 'Requires Verification Photos'},
  item_label_url: {type: 'text', value: null, label: 'Item Label URL'},
  logo: {type: 'image', value: null, label: 'Logo'},
  monthly_ad_budget: {type: 'text', value: null, label: 'Monthly Ad Budget'},
  mws_auth_token: {type: 'text', value: null, label: 'MWS Auth Token', createMode: true},
  phone: {type: 'text', variant: 'tel', value: null, label: 'Phone'},
  product_image: {type: 'image', value: null, label: 'Product Image'},
  relative_seller_id: {type: 'text', value: null, label: 'Relative Seller ID', createMode: true},
  revenue_rate_1d: {type: 'number', value: null, label: 'Revenue Rate 1D'},
  revenue_rate_7d: {type: 'number', value: null, label: 'Revenue Rate 7D'},
  revenue_rate_30d: {type: 'number', value: null, label: 'Revenue Rate 30D'},
  seniority: {type: 'number', value: null, label: 'Seniority'},
  seller_central_account_location: {
    type: 'text',
    value: null,
    priority: true,
    label: 'Seller Central Account Location',
  },
  seller_central_credentials: {type: 'text', value: null, label: 'Seller Central Credentials', createMode: true},
  website: {type: 'text', value: null, label: 'Website'},
};

export const tableFields = {
  ...brandFields,
  account_manager: {
    type: 'select',
    value: null,
    label: 'Account Manager',
    options: 'manager',
    as: 'account_manager_id',
  },
  brand_status: {type: 'select', value: null, label: 'Status', options: 'status', required: true, as: 'status_id'},
  brand_type: {type: 'select', value: null, label: 'Brand Type', options: 'type', required: true, as: 'type_id'},
  seller_id: {type: 'text', priority: true, value: null, label: 'Seller ID'},
  brand_code: {type: 'text', priority: true, value: null, label: 'Brand Code', noEditable: true},
  amazon_listings: {
    type: 'text',
    noEditable: true,
    value: null,
    label: 'Amazon Listings',
    customURL: 'amazonListings',
  },
  alerts: {type: 'text', noEditable: true, value: null, label: 'Alerts', customURL: 'alerts'},
  performance_checklist: {
    type: 'text',
    noEditable: true,
    value: null,
    label: 'Performance Checklist',
    modal: true,
    buttonText: 'View Checklist',
    customComponent: 'PerformanceChecklist',
  },
};

delete tableFields.account_manager_id;
delete tableFields.status;
delete tableFields.type_id;
delete tableFields.advertising_profiles;
delete tableFields.relative_seller_id;
delete tableFields.revenue_rate_1d;
delete tableFields.revenue_rate_7d;
delete tableFields.revenue_rate_30d;
delete tableFields.seller_central_credentials;
