import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Theme, Paper, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {LineChart, Line, XAxis, YAxis, ResponsiveContainer, BarChart, Bar, LabelList} from 'recharts';
// utils
import {Api, errorAlert} from '../../../../../../utils/api';
import {Helper} from '../../../../../../utils/helper';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: '1em 0',
  },
  '#report_data': {
    minWidth: '1024px',
  },
  page: {
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',
    backgroundSize: 'cover',
    marginBottom: 5,
    '&:first-of-type': {
      backgroundImage: `url(${process.env.PUBLIC_URL + '/reports/Monthly-Presentation.png'})`,
    },
    '&.dark-blue-bg': {
      backgroundImage: `linear-gradient(to bottom left, #245fac, #0b2845)`,
    },
    '&.gray-bg': {
      backgroundImage: `linear-gradient(to bottom left, #ebebeb, #b5b5b5)`,
    },
  },
  dark_blue_bg: {
    backgroundImage: `linear-gradient(to bottom left, #245fac, #0b2845)`,
  },
  paper: {
    padding: '4.7%',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    '&.page_sales_note': {
      padding: 0,
      '& .MuiGrid-grid-xs-4': {
        padding: '4.7%',
        paddingRight: '1%',
        paddingBottom: 0,
        backgroundImage: `linear-gradient(to bottom left, #ebebeb, #b5b5b5)`,
      },
      '& .MuiGrid-grid-xs-8': {
        padding: '4.7%',
        paddingBottom: 0,
        backgroundImage: `linear-gradient(to bottom left, #245fac, #0b2845)`,
      },
      '& .MuiGrid-grid-xs-12': {
        padding: '4.7%',
        paddingBottom: 0,
        backgroundImage: `linear-gradient(to bottom left, #245fac, #0b2845)`,
      },
    },
    '&#page_case': {
      padding: 0,
      '& .MuiGrid-grid-xs-4': {
        padding: '4.7%',
        backgroundImage: `linear-gradient(to bottom left, #ebebeb, #b5b5b5)`,
      },
      '& .MuiGrid-grid-xs-8': {
        padding: '4.7%',
        backgroundImage: `linear-gradient(to bottom left, #245fac, #0b2845)`,
      },
    },
    '&>.MuiGrid-container': {
      height: '100%',
    },
    '&#page_cover': {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'right',
    },
  },
  '#page_cover': {
    p: {
      margin: 0,
      'font-size': '5.5rem',
      width: '35rem',
      'font-weight': 'normal',
      'line-height': '0.8',
      '&>span': {
        fontSize: '1.5rem',
        color: '#95CFD2',
        'font-weight': 'bold',
      },
    },
  },
  table_case: {
    width: '100%',
    textAlign: 'left',
    borderCollapse: 'collapse',
    '& tr': {
      '&>th:first-child': {
        width: 120,
      },
      '&>th:last-child': {
        width: 120,
      },
      '&>*': {
        'border-bottom': '2px dashed #3D85C6',
      },
      '&>td': {
        'font-weight': 'normal',
        maxWidth: 100,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    '&.left': {},
  },
  tr_aggr: {
    '&>td': {
      color: 'yellow',
      border: 'none !important',
    },
  },
  table_case_left: {
    '&>tbody': {
      '&>tr': {
        '&.tr-total': {
          '&>td': {
            textAlign: 'left',
            fontSize: '1.5rem',
            '&:first-child': {
              fontSize: '5rem',
              textAlign: 'center',
            },
          },
        },
        '&>td': {
          fontWeight: 'normal',
          padding: '0.1rem 0.375rem',
          '&:first-child': {
            textAlign: 'center',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  advertising_note: {
    'font-weight': 'normal',
    paddingBottom: 0,
    color: '#1B4580 !important',
    '& .title': {
      'font-weight': 'bold',
    },
  },
  chart_container: {
    margin: 'auto',
  },
  chart_container_tacos: {
    transform: 'translateX(1.5rem)',
  },
  market_place_title: {
    direction: 'rtl',
    color: 'gold',
    fontWeight: 400,
  },
  custom_legend: {
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    fontWeight: 'normal',
    color: 'white',
    '&>li': {
      listStyle: 'none',
      display: 'flex',
      margin: '0 1rem',
      alignItems: 'center',
      '&>div': {
        width: '15px',
        height: '15px',
        margin: '0.5rem',
        color: '#dfb83a',
      },
      '&:nth-child(2)': {
        '&>div': {
          backgroundColor: 'rgb(149, 207, 210)',
        },
      },
      '&:nth-child(3)': {
        '&>div': {
          backgroundColor: 'rgb(77, 131, 195)',
        },
      },
    },
  },
  reportContainer: {
    width: '60%',
    [theme.breakpoints.down(767)]: {
      width: '100%',
    },
  },
}));

function ViewBrandReport() {
  const {id} = useParams<Record<string, string | undefined>>();
  const {month} = useParams<Record<string, string | undefined>>();
  const classes = useStyles();
  const [brandInfo, setBrandInfo] = useState(null);
  const [notes, setNotes] = useState(null);
  const [suppressedOnMonth, setSuppressedOnMonth] = useState([]);
  const [recovereds, setRecovereds] = useState([]);
  const [lifteds, setLifteds] = useState([]);
  const [stillDowns, setStillDowns] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [suppressions, setSuppressions] = useState([]);

  const startDateOfMonth = moment(month, 'YYYY-MM').clone().startOf('month').valueOf();
  const endDateOfMonth = moment(month, 'YYYY-MM').clone().endOf('month').valueOf();

  async function getReport() {
    let arrMonths = [];
    for (let i = 0; i < 6; i++) {
      const pastMonth = moment(month, 'YYYY-MM').clone().subtract(i, 'month').format('YYYY-MM');
      arrMonths.push(pastMonth);
    }
    arrMonths.reverse();

    try {
      const {data} = await Api.get(`brandreports/${id}/${month}`);
      setBrandInfo(data.brandInfo);
      setNotes(data.notes);
      setSuppressions(data.suppressions);
      setRecovereds(data.suppressions.filter((record) => record.alertLiftedId && String(record.alertLiftedId) !== ''));
      setStillDowns(data.suppressions.filter((record) => !record.alertLiftedId && String(record.alertLiftedId) === ''));
      setLifteds(
        data.suppressions.filter((record) => {
          return record.alertLiftedId && record.liftedAt >= startDateOfMonth && record.liftedAt <= endDateOfMonth;
        })
      );
      setSuppressedOnMonth(
        data.suppressions.filter((record) => {
          return (
            record.alertLiftedId &&
            record.liftedAt >= startDateOfMonth &&
            record.liftedAt <= endDateOfMonth &&
            moment(new Date(record.createdAt)).valueOf() < startDateOfMonth
          );
        })
      );

      const salesStateData = [];
      const salesDataAllMonths = [];

      for (const month of arrMonths) {
        const totalSalesDataPerMonth = {
          report_month: month,
          countryCode: 'All',
          marketplace_id: 'All',
          sales_total: 0,
          ad_spend: 0,
          sales_ppc: 0,
          sales_organic: 0,
          acos: 0,
          tacos: 0,
        };
        const salesPerReportMonth: any[] = data.sales.filter((item) => item.report_month === month);
        salesPerReportMonth.forEach((item) => {
          totalSalesDataPerMonth.sales_total = totalSalesDataPerMonth.sales_total + Number(item.sales_total);
          totalSalesDataPerMonth.ad_spend = totalSalesDataPerMonth.ad_spend + Number(item.ad_spend);
          totalSalesDataPerMonth.sales_ppc = totalSalesDataPerMonth.sales_ppc + Number(item.sales_ppc);
          totalSalesDataPerMonth.sales_organic = totalSalesDataPerMonth.sales_organic + item.sales_organic;
        });
        totalSalesDataPerMonth.acos =
          totalSalesDataPerMonth.sales_ppc === 0
            ? 0
            : Math.round((totalSalesDataPerMonth.ad_spend / totalSalesDataPerMonth.sales_ppc) * 10000) / 100;
        totalSalesDataPerMonth.tacos =
          totalSalesDataPerMonth.sales_total === 0
            ? 0
            : Math.round((totalSalesDataPerMonth.ad_spend / totalSalesDataPerMonth.sales_total) * 10000) / 100;
        salesDataAllMonths.push(totalSalesDataPerMonth);
      }

      let salesByMarket = {All: salesDataAllMonths};
      salesByMarket = Object.assign(salesByMarket, Helper.arrayGroupBy(data.sales, 'marketplace_id'));

      for (const marketplaceId in salesByMarket) {
        const salesPerMarket: any[] = salesByMarket[marketplaceId];
        const tacos = [];
        const acos = [];
        const sales = [];
        let countryCode = salesPerMarket[0]?.countrycode || '';
        if (marketplaceId === 'All') countryCode = 'All';

        for (const month of arrMonths) {
          const item = salesPerMarket?.find((record) => record.report_month === month) || {report_month: month};
          item.report_month = moment(item.report_month, 'YYYY-MM').format('MMM -YYYY');

          tacos.push({
            month: item.report_month,
            value: item.tacos ? Number(item.tacos).toFixed(1) : 0,
          });
          acos.push({
            month: item.report_month,
            value: item.acos ? Number(item.acos).toFixed(1) : 0,
          });
          sales.push({
            month: item.report_month,
            ppc: item.sales_ppc ? Number(item.sales_ppc).toFixed(2) : 0,
            organic: item.sales_organic ? Number(item.sales_organic).toFixed(2) : 0,
            total:
              item.total && item.total > 0
                ? Number(item.sales_total).toFixed(2)
                : (Number(item.sales_ppc) + Number(item.sales_organic)).toFixed(2),
          });
        }

        salesStateData.push({
          marketplaceId,
          countryCode: countryCode,
          tacos: tacos.reverse().slice(0, 3).reverse(),
          acos,
          sales,
          existChartData: tacos.length > 0 || acos.length > 0 || sales.length > 0,
        });
      }

      setSalesData(salesStateData);
    } catch (e) {
      errorAlert('Unable to get report', e);
    }
  }

  useEffect(() => {
    getReport();
  }, []);

  const valueAccessor = (entry) => {
    return entry ? (Number(entry.ppc) + Number(entry.organic)).toFixed(2) : 0;
  };

  const printDocument = () => {
    const fileName = `${brandInfo.name}_${month}.pdf`;
    const input = document.getElementById('report_data');
    html2canvas(input, {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      let imgWidth = document.getElementById('page_cover').offsetWidth;
      const pageHeight = document.getElementById('page_cover').offsetHeight;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF('l', 'px', [imgWidth, pageHeight]);
      let position = 0;
      doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      let i = 1;
      while (heightLeft > pageHeight) {
        position = heightLeft - imgHeight;
        const yValue = heightLeft === 0 ? 0 - imgHeight : position;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, yValue - 5 * i, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        i++;
      }
      doc.save(fileName);
    });
  };

  const numberWithCommas = (x: any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const customLabel = ({x, y, value}) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={-16}
          fontSize={12}
          fontWeight={400}
          textAnchor="middle"
          fill="#dfb83a"
          transform="rotate(0, -140, -160)"
        >
          {value}%
        </text>
      </g>
    );
  };

  const customBarLabel = (key, data) => {
    const {x, y, width, height, value} = data;
    let dd = {
      dx: width / 2,
      dy: 0,
      fill: 'white',
      styleValue: null,
    };
    if (value === 0) return;
    if (key === 'organic') {
      dd.dy = height / 2;
    } else if (key === 'total') {
      dd.dy = -10;
    } else if (key === 'ppc') {
      dd.dy = height / 2;
      dd.fill = 'rgb(77, 131, 195)';
      dd.styleValue = {
        textShadow: '-1px -1px 0 white,1px -1px 0 white,	-1px 1px 0 white,	1px 1px 0 white',
      };
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          dx={dd.dx}
          dy={dd.dy}
          fontSize={14}
          fontWeight={500}
          textAnchor="middle"
          fill={dd.fill}
          style={dd.styleValue}
        >
          ${numberWithCommas(value)}
        </text>
      </g>
    );
  };

  const AxisLabel = ({x, y, width, height, payload}) => {
    const px = x - 40;
    const py = y + 5;
    return (
      <text x={px} y={py} width={width} fill="white" height={height} textAnchor="middle" fontWeight={400}>
        <tspan>${payload.value}</tspan>
      </text>
    );
  };

  return (
    <div className={classes.reportContainer}>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'right',
          flexDirection: 'row-reverse',
        }}
      >
        <Button size="large" variant="contained" color="primary" className={classes.button} onClick={printDocument}>
          Print
        </Button>
      </Grid>

      <Grid container id="report_data">
        <Grid item xs={12} className={classes.page}>
          <Paper
            elevation={0}
            className={classes.paper}
            id="page_cover"
            style={{backgroundImage: `url(${brandInfo?.product_image})`}}
          >
            <Grid container>
              <Grid item xs={12}>
                <div style={{width: '50%', color: 'white', paddingTop: '28%'}}>
                  <img src={brandInfo?.logo || ''} alt="" style={{width: '25%'}} />
                  <p style={{margin: 0, fontSize: '5.5rem', width: '35rem', fontWeight: 'normal', lineHeight: '0.8'}}>
                    <strong>Month End</strong> Report{' '}
                    <span
                      style={{
                        fontSize: '1.5rem',
                        color: '#95CFD2',
                        fontWeight: 'bold',
                      }}
                    >
                      &nbsp;&#8212;&nbsp;
                      {moment(month, 'YYYY-MM').format('MMMM YYYY')}
                    </span>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {salesData.map((salesByMarket, index) => (
          <>
            <Grid item xs={12} className={classes.page}>
              <Paper elevation={0} className={`${classes.paper} page_sales_note`}>
                <Grid container>
                  {index < 1 && (
                    <Grid item xs={4}>
                      <img
                        src={process.env.PUBLIC_URL + '/reports/sales_note_logo.svg'}
                        alt=""
                        style={{width: '70%'}}
                      />
                      {index < 1 && (
                        <div style={{color: '#1B4580'}}>
                          <p
                            style={{color: '#1B4580'}}
                            dangerouslySetInnerHTML={{
                              __html: notes?.sales_note || '<i style="color:#ff4500;">There is no Sales Notes.</i>',
                            }}
                          />
                        </div>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={index < 1 ? 8 : 12} className={classes.dark_blue_bg}>
                    <div>
                      <div className={classes.market_place_title}>
                        {salesByMarket.countryCode} Marketplace{index < 1 ? 's' : ''}
                      </div>
                      <ul className={classes.custom_legend}>
                        <li>
                          <div>&#8212;</div>TACos sales
                        </li>
                        <li>
                          <div></div>PPC sales
                        </li>
                        <li>
                          <div></div>Organic sales
                        </li>
                      </ul>
                    </div>
                    {salesByMarket.existChartData && (
                      <>
                        <ResponsiveContainer
                          width="50%"
                          height="20%"
                          className={`${classes.chart_container} ${classes.chart_container_tacos}`}
                        >
                          <LineChart
                            width={100}
                            height={50}
                            data={salesByMarket.tacos}
                            syncId={`sales_notes_chart_${index}`}
                            margin={{top: 5, right: 30, left: 20, bottom: 5}}
                          >
                            <XAxis dataKey="month" hide={true} />
                            <YAxis hide={true} domain={[0, 100]} />
                            <Line
                              type="monotone"
                              dataKey="value"
                              stroke="#dfb83a"
                              name="TACoS"
                              activeDot={{r: 8}}
                              label={customLabel}
                              isAnimationActive={false}
                            ></Line>
                          </LineChart>
                        </ResponsiveContainer>
                        <ResponsiveContainer width="70%" height="60%" className={classes.chart_container}>
                          <BarChart
                            width={100}
                            height={50}
                            data={salesByMarket.sales.slice(-3)}
                            syncId="sales_notes_chart"
                            margin={{top: 20, right: 30, left: 20, bottom: 5}}
                          >
                            <XAxis dataKey="month" stroke="rgb(149, 207, 210)" />
                            <YAxis stroke="white" tick={AxisLabel} />
                            <Bar
                              dataKey="organic"
                              name="Organic Sales"
                              stackId="a"
                              fill="#4d83c3"
                              label={(data) => customBarLabel('organic', data)}
                            ></Bar>
                            <Bar dataKey="ppc" name="PPC Sales" stackId="a" fill="#95cfd2">
                              <LabelList
                                dataKey="ppc"
                                position="center"
                                content={(data) => customBarLabel('ppc', data)}
                              />
                              <LabelList
                                position="top"
                                content={(data) => customBarLabel('total', data)}
                                valueAccessor={valueAccessor}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} className={`${classes.page} dark-blue-bg`}>
              <Paper elevation={0} className={classes.paper} id="page_advertising">
                <Grid container>
                  <Grid item xs={12} style={{display: 'flex', flexDirection: 'column'}}>
                    <img style={{width: '25%'}} src={process.env.PUBLIC_URL + '/reports/advertising_logo.svg'} alt="" />
                    <div style={{flexGrow: 1}}>
                      <div>
                        <div className={classes.market_place_title}>{salesByMarket.countryCode} Marketplace</div>
                        <ul className={`${classes.custom_legend}`} id={'advertising_lengend'}>
                          <li>
                            <div style={{color: 'rgb(149, 207, 210)'}}>&#8212;</div>ACos sales
                          </li>
                          <li>
                            <div style={{backgroundColor: 'rgb(77, 131, 195)'}}></div>PPC sales
                          </li>
                        </ul>
                      </div>
                      {salesByMarket.existChartData && (
                        <>
                          <ResponsiveContainer
                            width="80%"
                            height="30%"
                            className={`${classes.chart_container} ${classes.chart_container_tacos}`}
                          >
                            <LineChart
                              width={100}
                              height={50}
                              data={salesByMarket.acos}
                              syncId="sales_notes_chart"
                              margin={{top: 5, right: 30, left: 20, bottom: 5}}
                            >
                              <XAxis dataKey="month" hide={true} />
                              <YAxis hide={true} domain={[0, 100]} />
                              <Line
                                type="monotone"
                                dataKey="value"
                                stroke="#95cfd2"
                                name="ACoS"
                                activeDot={{r: 8}}
                                label={customLabel}
                                isAnimationActive={false}
                              ></Line>
                            </LineChart>
                          </ResponsiveContainer>
                          <ResponsiveContainer width="100%" height="50%" className={classes.chart_container}>
                            <BarChart
                              width={100}
                              height={50}
                              data={salesByMarket.sales}
                              syncId="sales_notes_chart"
                              margin={{top: 20, right: 30, left: 20, bottom: 5}}
                            >
                              <XAxis dataKey="month" stroke="rgb(149, 207, 210)" />
                              <YAxis stroke="white" tick={AxisLabel} />
                              <Bar dataKey="ppc" name="PPC Sales" stackId="a" fill="#4d83c3">
                                <LabelList
                                  dataKey="ppc"
                                  position="top"
                                  content={(data) => customBarLabel('total', data)}
                                />
                              </Bar>
                            </BarChart>
                          </ResponsiveContainer>
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {salesByMarket.marketplaceId === 'All' && (
              <Grid item xs={12} className={`${classes.page} gray-bg`}>
                <Paper elevation={0} className={classes.paper} id="page_ad_notes">
                  <Grid container>
                    <Grid item xs={12} className={classes.advertising_note}>
                      <div style={{columnCount: 2, lineHeight: 1.25, columnFill: 'auto', height: '100%'}}>
                        <img
                          src={process.env.PUBLIC_URL + '/reports/advertising_note_logo.svg'}
                          alt=""
                          style={{width: '15rem'}}
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: notes?.ad_note || '<i style="color:#ff4500;">There is no Advertising Notes.</i>',
                          }}
                        ></div>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </>
        ))}

        <Grid item xs={12} className={classes.page}>
          <Paper elevation={0} className={classes.paper} id="page_case">
            <Grid container>
              <Grid item xs={4}>
                <img style={{width: '70%'}} src={process.env.PUBLIC_URL + '/reports/case_logo_1.svg'} alt="" />
                <div style={{color: '#1B4580'}}>
                  <table className={classes.table_case_left}>
                    <tbody>
                      <tr className={'tr-total'}>
                        <td>{suppressions.length}</td>
                        <td>
                          Total
                          <br />
                          Suppressions
                        </td>
                      </tr>
                      <tr>
                        <td>{stillDowns.length}</td>
                        <td>Suppressed, lifted, but went down again (still down)</td>
                      </tr>
                      <tr className={'tr-total'}>
                        <td>{lifteds.length}</td>
                        <td>
                          Total
                          <br />
                          Suppressions Lifted
                        </td>
                      </tr>
                      <tr>
                        <td>{suppressedOnMonth.length}</td>
                        <td>Previously Suppressed but Lifted This month</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Grid>
              <Grid item xs={8}>
                <img style={{width: '45%'}} src={process.env.PUBLIC_URL + '/reports/case_logo_2.svg'} alt="" />
                <div style={{color: 'white', paddingTop: '2rem'}}>
                  <table className={classes.table_case}>
                    <thead>
                      <tr>
                        <th>Listings Recovered</th>
                        <th>Item</th>
                        <th style={{width: 100, textAlign: 'center'}}>MarketPlace</th>
                        <th style={{textAlign: 'center'}}>Suppression lifted in…</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recovereds.map(
                        (record, i) =>
                          i < 10 && (
                            <tr key={record._id}>
                              <td>{record.asin}</td>
                              <td>{record.productName}</td>
                              <td width="100" style={{textAlign: 'center'}}>
                                {record.marketplace}
                              </td>
                              <td style={{textAlign: 'right'}}>{record.diffHours}H</td>
                            </tr>
                          )
                      )}
                      {recovereds.length > 10 && (
                        <tr className={classes.tr_aggr}>
                          <td colSpan={3}>{lifteds.length - 10} Rows more ...</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <table className={classes.table_case} style={{marginTop: '3rem'}}>
                    <thead>
                      <tr>
                        <th>Still Down</th>
                        <th></th>
                        <th style={{width: 100, textAlign: 'center'}}></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {stillDowns.map(
                        (record, i) =>
                          i < 5 && (
                            <tr key={record._id}>
                              <td>{record.asin}</td>
                              <td>{record.productName}</td>
                              <td width="100" style={{textAlign: 'center'}}>
                                {record.marketplace}
                              </td>
                              <td style={{textAlign: 'right'}}>
                                {moment(new Date()).diff(moment(record.createdAt), 'hours')}H
                              </td>
                            </tr>
                          )
                      )}
                      {stillDowns.length > 5 && (
                        <tr className={classes.tr_aggr}>
                          <td colSpan={3}>{stillDowns.length - 5} Rows more ...</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export {ViewBrandReport};
