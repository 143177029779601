import {useEffect, useState, useContext} from 'react';
import {TextField as TextFieldMaterial, Typography, AutocompleteRenderInputParams, Grid} from '@mui/material';
import {Field, Form, Formik} from 'formik';
import {TextField, Autocomplete} from 'formik-mui';
import * as Yup from 'yup';
import {useHistory, useParams} from 'react-router-dom';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import Button from '../../../../components/Button/Button';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {MenuStore} from '../../../../App';
import {buildMenu} from '../../components/menu';
// styles
import {useStyles} from '../../../Brands/Styles';
// context
import {AppContext} from '../../../../context/AppContext/AppContext';

const validationSchema = Yup.object().shape({
  asin: Yup.string().required('ASIN is required'),
  parent_asin: Yup.string(),
  brand_code: Yup.object({
    name: Yup.string().typeError('Brand is required').required('Brand is required'),
    brand_code: Yup.string().typeError('Brand is required').required('Brand is required'),
  })
    .typeError('Brand is required')
    .required('Brand is required'),
});

function UpdateAsin() {
  let history = useHistory();
  const classes = useStyles();
  const [values, setValues] = useState(null);
  const {id} = useParams<Record<string, string | undefined>>();
  const menuItems = buildMenu('edit', id, history);

  const {brands} = useContext(AppContext);

  async function getAsin() {
    try {
      const {data} = await Api.get(`/asins/${id}`);
      const tempBrands = brands;
      setValues({
        asin: data.asin,
        parent: data.parent,
        brand_code: data.brand_code ? tempBrands.find((x) => x.brand_code === data.brand_code) : null,
      });
    } catch (e) {
      errorAlert('Unable to get Asins', e);
    }
  }

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = menuItems;
    });
    brands.length > 0 && getAsin();
    return () => {
      MenuStore.update((s) => {
        s.menuItems = [];
      });
    };
  }, [brands]);

  function onSubmit(data, {setSubmitting}) {
    //TODO
    // alertService.clear();
    // if (data.brand_code) {
    //     data.brand_code = data.brand_code.brand_code;
    // }
    // Api.put(`asins/${id}`, data, {
    //     headers: {
    //         'Authorization': `Bearer ${accountService.userValue.accessToken}`
    //     }
    // }).then(res => {
    //     alertService.success("Brand updated successfully")
    // }).catch(error => {
    //     setSubmitting(false);
    //     if (error.response.status === 400 && error.response.data && error.response.data.message) {
    //         alertService.error(error.response.data.message);
    //     }else if (error.response.status === 403 && error.response.data && error.response.data.message) {
    //         alertService.error(error.response.data.message);
    //     } else {
    //         alertService.error(error.message);
    //     }
    // });
  }

  return (
    <TableContainer className="sub-page" container spacing={1} justifyContent="center" style={{paddingTop: 60}}>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Typography component="h2" variant="h4" color="primary" gutterBottom>
          Update ASIN
        </Typography>
        {values ? (
          <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnChange={true}
          >
            {({errors, touched, isSubmitting}) => (
              <Form>
                <div>
                  <div>
                    <Field
                      className={classes.inputField}
                      component={TextField}
                      variant="outlined"
                      name="asin"
                      label="ASIN number"
                      type="text"
                      size="small"
                      disabled={true}
                    />
                  </div>
                  <div>
                    <Field
                      className={classes.inputField}
                      component={TextField}
                      variant="outlined"
                      name="parent_asin"
                      label="Parent"
                      type="text"
                      size="small"
                      disabled={true}
                    />
                  </div>
                  <Field
                    className={classes.inputField}
                    name="brand_code"
                    component={Autocomplete}
                    options={brands}
                    size="small"
                    disabled={true}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextFieldMaterial
                        {...params}
                        error={touched['brand_code'] && !!errors['brand_code']}
                        helperText={touched['brand_code'] && errors['brand_code']}
                        label="Brands"
                        variant={'outlined'}
                      />
                    )}
                  />
                  <div>
                    <Button size="large" type="submit" disabled={isSubmitting}>
                      Save
                    </Button>
                    <CustomAlert className={classes.inputField} id="default-alert" />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          ''
        )}
      </Grid>
    </TableContainer>
  );
}

export {UpdateAsin};
