import {memo, useEffect, useState} from 'react';
import {Typography, Grid, List, ListItem, ListItemText, useTheme, Tooltip} from '@mui/material';
import {ArrowRightAlt} from '@mui/icons-material';
import HeatMap from 'react-heatmap-grid';
// components
import {getAmountDiff} from './pieChart';
import {BreadcrumbsComponent} from './components';
import {ChartContainer} from './components';
// styles
import {useStyles} from '../../Performance/Styles';

interface HeatmapProps {
  data: any;
  brandsData: any;
  loading: boolean;
  heatmapBrand: any;
  setHeatmapBrand: (e) => void;
  heatmapSku: string;
  setHeatmapSku: (e) => void;
  compare: boolean;
  filters: any;
  getSkuData: any;
  skuData: any;
  setSkuData: any;
  skuLoading: boolean;
  actionButton?: any;
}

export default memo(function Heatmap({
  data,
  loading,
  brandsData,
  heatmapBrand,
  setHeatmapBrand,
  heatmapSku,
  setHeatmapSku,
  compare,
  filters,
  getSkuData,
  skuData,
  setSkuData,
  skuLoading,
  actionButton,
}: HeatmapProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [listData, setListData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [yLabels, setYLabels] = useState([]);
  const xLabels = new Array(24).fill(0).map((_, i) => `${i}h`);

  const loader = (
    <Grid container className="container" justifyContent="center" alignItems="center" style={{height: '100%'}}>
      <Typography component="h2" variant="h5" color="primary" gutterBottom>
        Loading...
      </Typography>
    </Grid>
  );

  useEffect(() => {
    if (!loading) {
      setMapData(data.data);
      setYLabels(data.labels);
      setListData(brandsData);
    }
  }, [data, loading]);

  useEffect(() => {
    if (heatmapBrand) {
      const brand = brandsData.find((i) => i.label === heatmapBrand.value);
      const sku = skuData.find((i) => i.label === heatmapSku);
      setListData(skuData);
      if (sku) {
        setMapData(sku.data);
        setYLabels(sku.labels);
      } else if (brand) {
        setMapData(brand.data);
        setYLabels(brand.labels);
      }
    }
  }, [compare, loading]);

  useEffect(() => {
    if (heatmapBrand) {
      setListData(skuData);
    }
  }, [skuData, heatmapBrand]);

  const chartList = (currentData) => {
    return (
      <List className={`${classes.chartList} custom-list`}>
        {currentData.length > 0 ? (
          <>
            {currentData.map((entry, index) => {
              return (
                <ListItem
                  key={index}
                  style={{
                    padding: '4px 0',
                  }}
                  className={`${heatmapSku === entry.label ? 'active' : ''} clickable`}
                  onClick={() => {
                    if (entry.type === 'brand') {
                      setHeatmapBrand(entry);
                      setMapData(entry.data);
                      setYLabels(entry.labels);
                      getSkuData(entry.label);
                    }
                    if (entry.type === 'sku') {
                      setHeatmapSku(entry.label);
                      setMapData(entry.data);
                      setYLabels(entry.labels);
                    }
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        component="span"
                        variant="overline"
                        color="secondary"
                        style={{textTransform: 'capitalize'}}
                      >
                        {entry.name || entry.label}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </>
        ) : (
          <Typography gutterBottom>No data found</Typography>
        )}
      </List>
    );
  };

  const tooltipComponent = (entry) => {
    return (
      <Grid className={classes.heatmapTooltip}>
        <Typography color="secondary" style={{}} gutterBottom>
          {entry.day} - {entry.hour}
        </Typography>
        {entry.data.map((entry, index) => {
          return (
            <Typography key={index} component="span" variant="overline" style={{textTransform: 'capitalize'}}>
              {entry.metric}: {entry.value} {compare && <br />}
              {compare && (
                <span className="compare">
                  {entry.old_value} <ArrowRightAlt /> {entry.value} ={' '}
                  {getAmountDiff({
                    current: parseFloat(entry.value),
                    old: parseFloat(entry.old_value),
                    classes: classes,
                    invertedColor: true,
                  })}
                </span>
              )}
            </Typography>
          );
        })}
      </Grid>
    );
  };

  return (
    <ChartContainer
      minHeight={600}
      chartTitle={
        <Typography variant="h6" component="h6" color="secondary">
          Time Of Day Heatmap
        </Typography>
      }
      actionButton={actionButton}
      loading={loading}
      filters={filters}
      data={data.data}
    >
      <Grid container className={classes.heatmap}>
        <Grid container className="header">
          <Typography component="span" variant="overline" color="secondary" style={{fontSize: '14px'}}>
            {heatmapSku ? `Selected SKU: ${heatmapSku}` : heatmapBrand ? `Selected Brand: ${heatmapBrand.name}` : ''}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={9} display="flex" alignItems="center">
          <Grid container className={classes.heatmapContainer}>
            {!loading && mapData?.length > 0 ? (
              <div>
                <HeatMap
                  xLabels={xLabels}
                  yLabels={yLabels}
                  xLabelsLocation={'bottom'}
                  xLabelWidth={80}
                  data={mapData}
                  height={62}
                  cellStyle={(background, value, mn, mx, data, x, y) => {
                    let values = [];
                    data.forEach((i) => {
                      const newArr = i.map((x) => x.revenue);
                      values = [...values, ...newArr];
                    });
                    const min = Math.min.apply(Math, values);
                    const max = Math.max.apply(Math, values);
                    return {
                      background: value?.revenue
                        ? `rgb(0, 136, 254, ${1 - (max - value?.revenue) / (max - min)})`
                        : 'none',
                      cursor: value?.revenue ? 'help' : 'auto',
                      fontSize: '11.5px',
                      color: theme.palette.mode === 'dark' ? '#fff' : `#808080`,
                      flex: 1,
                      flexGrow: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minWidth: '70px',
                      width: '100%',
                    };
                  }}
                  title={() => ''}
                  cellRender={(value, hour, day) => {
                    const cell = value ? (
                      <Tooltip
                        title={tooltipComponent({
                          ...value,
                          hour: hour,
                          day: day,
                          data: [
                            {metric: 'Units', value: value.units, old_value: value.old_units},
                            {metric: 'Revenue', value: value.revenue, old_value: value.old_revenue},
                          ],
                        })}
                      >
                        <span
                          style={{
                            mixBlendMode: theme.palette.mode === 'light' ? 'difference' : 'normal',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <span>U: {value.units}</span>
                          <span>R: {value.revenue}</span>
                        </span>
                      </Tooltip>
                    ) : (
                      'N / A'
                    );
                    return cell;
                  }}
                />
              </div>
            ) : (
              <Grid container className="no-data">
                <Typography component="h2" variant="h5" color="primary" gutterBottom textAlign="center">
                  No data available
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} className={classes.mapDataContainer}>
          {!loading ? (
            <>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{maxWidth: '500px'}}>
                <BreadcrumbsComponent
                  parent={'BRANDS'}
                  child={heatmapBrand && heatmapBrand.name}
                  onChange={() => {
                    setHeatmapBrand(null);
                    setMapData(data.data);
                    setYLabels(data.labels);
                    setListData(brandsData);
                    setHeatmapSku(null);
                    setSkuData();
                  }}
                  textTransform="uppercase"
                  fontSize={14}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.listContainer}>
                {!skuLoading ? chartList(listData) : <Grid style={{height: '400px'}}> {loader} </Grid>}
              </Grid>
            </>
          ) : (
            loader
          )}
        </Grid>
      </Grid>
    </ChartContainer>
  );
});
