import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CardActions,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Breadcrumbs,
  Link as MuiLink,
  Divider,
  TableCell,
} from '@mui/material';

import {
  CheckCircle,
  Facebook,
  Instagram,
  Twitter,
  MailOutline,
  YouTube,
  LinkedIn,
  ExpandMore,
} from '@mui/icons-material';
// services
import {accountService} from '../../services/account.service';
// styles
import {useStyles} from './Styles';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import Button from '../../components/Button/Button';

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function InfoPage() {
  const classes = useStyles();
  let history = useHistory();
  const [selectedCollapse, setSelectedCollapse] = useState(null);

  useEffect(() => {
    if (accountService.userValue) {
      history.push('/');
    }
  }, []);

  function createData(name: string, ecom3k: string, manual_report: string, outsourced_developer: string) {
    return {name, ecom3k, manual_report, outsourced_developer};
  }

  const pricing = [
    {
      title: 'Operations Reports',
      price: 19,
      features: [
        'All Orders Reports',
        'All Inventory Reports',
        'All Payments Reports',
        'Unlimited ASINs Supported',
        'Unlimited Orders Supported',
        'Unlimited Revenue Supported',
        'Pause Anytime',
      ],
    },
    {
      title: 'Ops+Ads (Most Popular)',
      price: 39,
      features: [
        'Everything in Ops Reports PLUS',
        'Advertising Reports',
        'Unlimited ASINs Supported',
        'Unlimited Orders Supported',
        'Unlimited Revenue Supported',
        'Pause Anytime',
      ],
    },
    {
      title: 'Ops+Ads+Dashboarding',
      price: 995,
      features: [
        'Everything in Ops+Ads PLUS',
        'Implementation and Maintenance of Business Intelligence tool',
        '(Power BI,Google Display Studio, Tableu, or other specified)',
        'Unlimited ASINs Supported',
        'Unlimited Orders Supported',
        'Unlimited Revenue Supported',
        'Pause Anytime',
      ],
    },
  ];

  const tableContent = [
    createData('Value', '10x cheaper than other software', 'Often low-cost VA', '1 high-risk hire'),
    createData(
      'Cost',
      'One low monthly fee',
      'More expensive than Ecom3k',
      'Expensive with report or order escalations'
    ),
    createData(
      'Time until results',
      '90 Seconds to connect accounts and see test data',
      'Whenever they get around to it',
      'On-boarding calls and developer meetings (not fun)'
    ),
    createData(
      'Risk',
      'No-Risk! No Contracts! Test us with no risk',
      'High! Human error can have big impact',
      'High! Larger companies demand 6+ months contract'
    ),
    createData(
      'Time Commitment',
      'So Simple. We designed this tool to be so simple our moms can use it',
      'Hiring, on-boarding, training is a painful and error-prone expensive process',
      'Developer meetings and multiple calls is a waste of time'
    ),
  ];

  const collapseContent = [
    {
      title: 'Can I Really Get All Of My Amazon Data Without Manually Downloading It Or Hiring A Developer?',
      content: (
        <Typography>
          <b>Yes!</b> Within 90 seconds of signing up you’ll be able to select what reports you want to sync over and
          Ecom3k will go to work bringing your data over.
        </Typography>
      ),
    },
    {
      title: 'Do I Have To Sign A Contract?',
      content: (
        <Typography>
          <b>No!</b> You’ll be billed monthly until you pause. There is a option of an annual plan to{' '}
          <b>unlock 20% savings</b>.
        </Typography>
      ),
    },
    {
      title: 'Is There A Limit To How Many ASINs Or Orders Like Other Software?',
      content: <Typography>No limits or restrictions. You can request as many report pulls as you wish.</Typography>,
    },
    {
      title: 'When Will My Reports Be Delivered To My Google Drive Folders?',
      content: (
        <Typography>
          <ul>
            <li>FBA Fulfillment Current Inventory Data, Daily - 2AM.</li>
            <li>FBA Fulfillment Monthly Inventory Data, "Monthly, first day of month for the previous month".</li>
            <li>All Orders Data By Last Update General, Every 6 hours starting from midnight Daily.</li>
            <li>FBA Fulfillment Customer Returns Data, Every 6 hours starting from midnight Daily.</li>
            <li>Amazon Fulfilled Shipments Data General, Every 6 hours starting from midnight Daily.</li>
            <li>FBA Estimated Fees Data, Daily - 2AM.</li>
            <li>FBA Reimbursements Data, Every 12 hours starting from midnight Daily.</li>
            <li>Restock Inventory Recommendations, Daily - 1AM.</li>
            <li>Stranded Inventory UI Data, Daily - 1AM.</li>
            <li>FBA Inventory Aged Data, Daily - 1AM.</li>
            <li>FBA Recommended Removal Data, Daily - 1AM.</li>
            <li>FBA Fulfillment Removal Order Detail Data, Weekly - Monday at Midnight.</li>
            <li>FBA Fulfillment Removal Shipment Detail Data, Weekly - Monday at Midnight.</li>
            <li>Coupon Performance, Daily - Midnight.</li>
            <li>Promotion Performance, Daily - Midnight.</li>
            <li>Brand Analytics Market Basket, Daily - Midnight.</li>
            <li>Brand Analytics Repeat Purchase, Daily - Midnight.</li>
            <li>Brand Analytics Alternate Purchase, Daily - Midnight.</li>
            <li>Sales and Traffic, Daily - Midnight.</li>
            <li>Seller Performance Report, Daily - Midnight.</li>
          </ul>
        </Typography>
      ),
    },
    {
      title: 'Which Amazon Reports Are Available?',
      content: (
        <Typography>
          <ul>
            <li>FBA Fulfillment Current Inventory Data</li>
            <li>FBA Fulfillment Monthly Inventory Data</li>
            <li>All Orders Data By Last Update General</li>
            <li>FBA Fulfillment Customer Returns Data</li>
            <li>Amazon Fulfilled Shipments Data General</li>
            <li>FBA Estimated Fees Data</li>
            <li>FBA Reimbursements Data</li>
            <li>Restock Inventory Recommendations</li>
            <li>Stranded Inventory UI Data</li>
            <li>FBA Inventory Aged Data</li>
            <li>FBA Recommended Removal Data</li>
            <li>FBA Fulfillment Removal Order Detail Data</li>
            <li>FBA Fulfillment Removal Shipment Detail Data</li>
            <li>Coupon Performance</li>
            <li>Promotion Performance</li>
            <li>Brand Analytics Market Basket</li>
            <li>Brand Analytics Repeat Purchase</li>
            <li>Brand Analytics Alternate Purchase</li>
            <li>Sales and Traffic</li>
            <li>Seller Performance Report</li>
          </ul>
        </Typography>
      ),
    },
  ];

  return (
    <TableContainer className={classes.homePage}>
      <Grid className="header">
        <Typography variant="h2">
          <span>Your Amazon Data</span>
          <span>In Google Drive</span>
        </Typography>
        <Typography>90 seconds to automate the transfer of all of your Amazon reports</Typography>
        <a target="_blank" rel="noreferrer" href="/ecom3kRegistration">
          <Button size="large">START FREE TRIAL</Button>
        </a>
        <span>no CC required</span>
        <Grid container spacing={2} className="cards">
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <img
              alt="saveYourTime"
              src="https://ecom3k.com/wp-content/uploads/2022/06/Depositphotos_103831110_S-400x400-1.jpg"
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <img
              alt="saveMoney"
              src="https://ecom3k.com/wp-content/uploads/2022/06/Depositphotos_68725257_S-400x400-1.jpg"
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <img
              alt="MakeBetterDecisions"
              src="https://ecom3k.com/wp-content/uploads/2022/06/Add-a-subheading-400x400-1.png"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="section-1">
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <Typography variant="h5">IN 90 SECS</Typography>
          <Typography variant="h6">You Get Actionable Amazon Data Into Your Google Drive</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Typography variant="h5">10x</Typography>
          <Typography variant="h6">Cheaper</Typography>
          <Typography variant="h5">10x</Typography>
          <Typography variant="h6">Faster</Typography>
          <span>vx manual upload or other products</span>
        </Grid>
      </Grid>

      <Grid container spacing={1} className="section-2">
        <Grid item lg={2.3}>
          <img src="https://ecom3k.com/wp-content/uploads/2022/06/Client-logo5.png" alt="ekster" />
        </Grid>
        <Grid item lg={2.3}>
          <img src="https://ecom3k.com/wp-content/uploads/2022/06/Pela-PNG-Logo.png" alt="pela" />
        </Grid>
        <Grid item lg={2.3}>
          <img src="https://ecom3k.com/wp-content/uploads/2022/06/Client-logo3.png" alt="epic" />
        </Grid>
        <Grid item lg={2.3}>
          <img src="https://ecom3k.com/wp-content/uploads/2022/06/Organifi-PNG-Logo.png" alt="organifi" />
        </Grid>
        <Grid item lg={2.3}>
          <img src="https://ecom3k.com/wp-content/uploads/2022/06/Client-logo2.png" alt="rw-garcia" />
        </Grid>
      </Grid>
      <Grid container className="section-3">
        <Grid container>
          <Typography variant="h5">Takes less than 90 seconds to get started</Typography>
          <Typography variant="h4">HOW IT WORKS</Typography>
          <Typography variant="h6">
            Selling on Amazon is hard enough. You Shouldn’t need a programmer or data scientist to make sense of your
            own reports. That’s why we exist.
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={5} xs={12}>
            <img
              src="https://ecom3k.com/wp-content/uploads/2022/06/Depositphotos_379415036_S-e1655580385719-300x300.jpg"
              alt="sync"
            />
            <Typography variant="body1">
              <b>SYNC:</b> AMAZON AND GOOGLE ACCOUNTS
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={5} xs={12}>
            <img
              src="https://ecom3k.com/wp-content/uploads/2022/06/Depositphotos_194013622_S-e1655580425333.jpg"
              alt="pull"
            />
            <Typography variant="body1">
              <b>PULL:</b> GET REPORTS INTO GOOGLE DRIVE
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={5} xs={12}>
            <img
              src="https://ecom3k.com/wp-content/uploads/2022/06/Depositphotos_467366440_S-e1655580468120.jpg"
              alt="act"
            />
            <Typography variant="body1">
              <b>ACT:</b> MAKE THE RIGHT DECISIONS FOR YOUR BIZ!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} className="section-4">
        <img src="https://ecom3k.com/wp-content/uploads/2022/06/unnamed.jpeg" alt="" />
        <Typography variant="h5">“Wow. That’s amazing”</Typography>
        <Typography>
          <b>Matthew Turner,</b> Founder, and 7-figure seller
        </Typography>
      </Grid>
      <Grid container spacing={1} className="section-5">
        <Grid container>
          <Typography variant="h5">Takes less than 90 seconds to get started</Typography>
          <Typography variant="h4">What can you do with your own Amazon Data?</Typography>
          <Typography variant="h6">
            Selling on Amazon is hard enough. You Shouldn’t need a programmer or data scientist to make sense of your
            own reports. That’s why we exist.
          </Typography>
        </Grid>
        <Grid container className="cards" spacing={2}>
          <Grid item lg={4} md={4} sm={5} xs={12}>
            <img
              src="https://ecom3k.com/wp-content/uploads/2022/06/Depositphotos_4983220_S-e1655593637515-300x300.jpg"
              alt="product"
            />
            <Typography variant="h5">PRODUCT OPTIMIZATION</Typography>
            <Typography variant="h6">
              TRACKING CONVERSION RATES AND TRAFFIC OVER TIME IS SO CRUCIAL TO SUCCESS
            </Typography>
          </Grid>

          <Grid item lg={4} md={4} sm={5} xs={12}>
            <img
              src="https://ecom3k.com/wp-content/uploads/2022/06/Depositphotos_81298754_S-e1655593675584-300x300.jpg"
              alt="inventory"
            />
            <Typography variant="h5">INVENTORY OPTIMIZATION</Typography>
            <Typography variant="h6">KEEPING JUST THE RIGHT AMOUNT INVENTORY ON HAND</Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={5} xs={12}>
            <img
              src="https://ecom3k.com/wp-content/uploads/2022/06/Depositphotos_186334254_S-e1655593707666-300x300.jpg"
              alt="adverticing"
            />
            <Typography variant="h5">ADVERTISING OPTIMIZATION</Typography>
            <Typography variant="h6">
              HARNESS THE HIDDEN METRICS SAVVY SELLERS LOOK AT WITHIN THE ADS REPORTS
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Typography variant="h5">SIMPLE AND FLEXIBLE</Typography>
          <Typography variant="h6">PRICING STARTING AT $19/MO</Typography>
          <Typography>Make better business decisions in 90 seconds with your own Amazon data</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="section-6">
        {pricing.map((item, index) => {
          return (
            <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
              <Card className="card">
                <CardHeader title={item.title}></CardHeader>
                <Divider />
                <CardContent>
                  <Typography variant="h4">{item.price}</Typography>
                  <Typography>Monthly</Typography>
                  <List>
                    {item.features.map((v, i) => {
                      return (
                        <ListItem key={i}>
                          <ListItemIcon>
                            <CheckCircle />
                          </ListItemIcon>
                          <ListItemText>{v}</ListItemText>
                        </ListItem>
                      );
                    })}
                  </List>
                </CardContent>
                <CardActions>
                  <a target="_blank" rel="noreferrer" href="/ecom3kRegistration">
                    <Button>GET STARTED NOW</Button>
                  </a>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={1} className="section-7">
        <Typography variant="h5">WHY WE ARE THE BEST</Typography>
        <Typography variant="h6">AT WHAT WE DO</Typography>
        <Grid container>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left">ECOM3K</TableCell>
                <TableCell align="left">MANUAL REPORT PULLING</TableCell>
                <TableCell align="left"> OUTSOURCED DEVELOPER/EXISTING SOLUTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableContent.map((row) => (
                <StyledTableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.ecom3k}</TableCell>
                  <TableCell align="left">{row.manual_report}</TableCell>
                  <TableCell align="left">{row.outsourced_developer}</TableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell colSpan={6} component="th" scope="row" style={{padding: '0 0 0 15px'}}>
                  <Grid className="custom-cell">
                    <Typography>Winner by a Landslide!</Typography>
                    <a target="_blank" rel="noreferrer" href="/ecom3kRegistration">
                      <Button>
                        START YOUR FREE TRIAL NOW <span>no Credit Card required</span>
                      </Button>
                    </a>
                  </Grid>
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid container spacing={1} className="section-8">
        <Typography variant="h4">QUESTIONS?</Typography>
        <Typography variant="h6">WE GOT ANSWERS</Typography>
        <Grid className="collapse">
          {collapseContent.map((item, index) => {
            return (
              <Accordion
                expanded={selectedCollapse === index}
                onChange={() => setSelectedCollapse((p) => (index === p ? null : index))}
              >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />}>
                  <Typography>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>{item.content}</AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </Grid>
      <Grid container spacing={3} className="section-9">
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <Typography variant="h5">Setup your first Amazon to Google Drive transfer with</Typography>
          <Typography variant="h3">ZERO RISK!</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Typography variant="h6">Aren’t happy?</Typography>
          <Typography variant="h5">100% money-back within your first 7-days</Typography>
          <Typography variant="h4">GET STARTED TODAY</Typography>
          <a target="_blank" rel="noreferrer" href="/ecom3kRegistration">
            <Button>START YOUR FREE TRIAL NOW!</Button>
          </a>

          <span>no CC required</span>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="section-10">
        <Grid lg={4} md={6} sm={6} xs={12}>
          <Typography>Copyright © 2022 Sunken Stone. All Rights Reserved</Typography>
        </Grid>
        <Grid lg={5} md={6} sm={6} xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <a href="/privacy-policy" target="_blank" rel="noreferrer">
              <MuiLink underline="hover" color="inherit">
                View Our Privacy Policy
              </MuiLink>
            </a>
            <a href="/terms-of-service" target="_blank" rel="noreferrer">
              <MuiLink underline="hover" color="inherit">
                Terms of Service
              </MuiLink>
            </a>
            <a href="/cookie-policy" target="_blank" rel="noreferrer">
              <MuiLink underline="hover" color="inherit">
                Cookie Policy
              </MuiLink>
            </a>
          </Breadcrumbs>
        </Grid>

        <Grid lg={3} md={6} sm={6} xs={12}>
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/SunkenStonecom-1145250828861663/">
            <Facebook />
          </a>
          <a target="_blank" rel="noreferrer" href="http://sales@sunkenstone.com/">
            <MailOutline />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCNo6TAyu1LVLHvTl2-XgUkA">
            <YouTube />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/sunken-stone/">
            <LinkedIn />
          </a>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/sunkenstone_">
            <Twitter />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/sunkenstone_/">
            <Instagram />
          </a>
        </Grid>
      </Grid>
    </TableContainer>
  );
}
