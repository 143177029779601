import {useEffect, useState, useRef} from 'react';
import {Theme, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';
import {
  DataGrid,
  getGridStringOperators,
  GridColDef,
  GridColTypeDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridPagination,
} from '@mui/x-data-grid';
import {Delete, Edit, Security} from '@mui/icons-material';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import Button from '../../components/Button/Button';
import ModalButton from '../../components/ModalButton/ModalButton';
import DropDownButton from '../../components/DropdownButton/DropDownButton';
import DraggableList from '../../components/DraggableList/DraggableList';
import {CustomAlert} from '../Login/components/CustomAlert';
// utils
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../utils/tableSettings';
import {getArticle} from '../../utils/FFPBeacon';
import {CustomColumn, makeColumnFreeze, fixedPlaceHolder} from '../../utils/makeColumnFreeze';
import {Role} from '../../utils/role';
import {Api, errorAlert} from '../../utils/api';
// services
import mixPanel from '../../services/mixpanel';
import {alertService} from '../../services/alert.service';
import {accountService} from '../../services/account.service';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'flex',
    flexDirection: 'column-reverse',
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgb(23 69 130 / 8%)',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '&.load-headers': {
      opacity: 0.5,
    },
  },
  placeholder: {
    width: '50%',
    margin: 'auto',
    display: 'block',
  },
  button: {
    marginBottom: '1em',
  },
  toolbarButton: {
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '.5rem 1rem .4rem 1rem',
    [theme.breakpoints.down(820)]: {
      fontSize: '12px',
    },
  },
}));

const stringColumnType: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

interface UsersProps {
  GAEvents?: (category, action, label?) => void;
}

const columns: GridColDef[] = [
  {field: 'email', headerName: 'Email', minWidth: 280, type: 'filterString'},
  {field: 'name', headerName: 'Name', minWidth: 250, type: 'filterString'},
  {field: 'role', headerName: 'Role', minWidth: 140, type: 'filterString'},
  {field: 'department', headerName: 'Department', minWidth: 200, type: 'filterString'},
  {field: 'brand', headerName: 'Brand', minWidth: 200, type: 'filterString'},
  {field: 'inactive', headerName: 'Inactive', minWidth: 140},
];

const brandManagerColumns: GridColDef[] = [
  {field: 'email', headerName: 'Email', flex: 1, minWidth: 280, type: 'filterString'},
  {field: 'name', headerName: 'Name', width: 250, type: 'filterString'},
  {field: 'brand', headerName: 'Brand', width: 200, type: 'filterString'},
  {field: 'inactive', headerName: 'Inactive', width: 100},
];

function Users({GAEvents}: UsersProps) {
  document.title = 'Stonehenge.ai/Users';
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(40);
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);
  const [deleteUser, setDeleteUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orderColumns, setOrderColumns] = useState([]);
  const [visibilityModel, setVisibilityModel] = useState<any>({});
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    async function getTableData() {
      const data = await getUserPreferences({
        list: [Role.Admin, Role.Manager].includes(accountService.userValue.role)
          ? columns
          : [Role.BrandUser].includes(accountService.userValue.role)
          ? brandManagerColumns
          : [],
        tableName: `users-${accountService.userValue.role}`,
        visibilityModel: visibilityModel,
        defaultVisibilityModel: {},
        loading: setTableLoading,
      });
      if (data) {
        setOrderColumns(data.columns);
        setVisibilityModel(data.visibility);
      }
    }
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  useEffect(() => {
    if ([Role.Admin, Role.Manager].includes(accountService.userValue.role)) loadData(page, pageSize, {}, {});

    if ([Role.BrandUser].includes(accountService.userValue.role) && accountService.userValue.create_users === true)
      loadData(page, pageSize, {}, {});
  }, [page, pageSize]);

  function onPageChange(params) {
    loadData(params, pageSize, sort, filter);
    setPage(params);
  }

  async function loadData(page, pageSize, sortModel, filterModel) {
    setLoading(true);
    try {
      const {data} = await Api.get('users', {
        params: {
          page: page,
          pageSize: pageSize,
          sortField: sortModel ? sortModel.field : null,
          sortOrder: sortModel ? sortModel.sort : null,
          filterField: filterModel ? filterModel.columnField : null,
          filterValue: filterModel ? filterModel.value : null,
        },
      });
      setCount(data.count);
      setRows(data.rows);
    } catch (e) {
      errorAlert('Unable to get users', e);
    } finally {
      setLoading(false);
    }
  }

  function handleSortModelChange(params) {
    loadData(page, pageSize, params[0], filter);
    setSort(params[0]);
  }

  function handleFilterModelChange(params) {
    loadData(0, pageSize, sort, params.items[0]);
    setFilter(params.items[0]);
    setPage(0);
  }

  async function deleteUserById(id, closeModal) {
    try {
      await Api.delete(`users/${id}`);
      setDeleteUser(null);
      alertService.success('User deleted successfully');
      loadData(page, pageSize, sort, filter);
      closeModal();
    } catch (e) {
      errorAlert('Unable to delete user', e);
    }
  }

  useEffect(() => {
    ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) && getArticle({});
  }, [loading]);

  useEffect(() => {
    rows.length > 0 &&
      ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
      mixPanel({
        eventName: 'Account Page - Users And Permissions',
        eventProperties: {users_added: rows},
      });
  }, [rows]);

  const DataGridToolbar = () => (
    <GridToolbarContainer className="toolbar-container">
      <GridToolbarColumnsButton className={classes.toolbarButton} disabled={loading || tableLoading} />
      <DropDownButton buttonType="text" buttonText="Order Columns" bold loading={loading || tableLoading}>
        <DraggableList
          items={[...orderColumns]}
          ignoreItems={['actions']}
          setItems={(v) => {
            setOrderColumns(v);
            setUserPreferences(
              {
                columnVisibilityModel: visibilityModel,
                columnsOrder: v.map((x) => x.field),
              },
              `users-${accountService.userValue.role}`,
              setTableLoading
            );
          }}
        />
      </DropDownButton>
      <GridPagination />
    </GridToolbarContainer>
  );

  const thisRef = useRef<any>(null);

  const customButtons = [Role.Admin, Role.Manager].includes(accountService.userValue.role)
    ? [
        {
          icon: <Security />,
          url: (v) => `/users/${v.id}/permission`,
          tooltip: 'Permissions',
        },
        {
          icon: <Edit />,
          url: (v) => `/users/update/${v.id}`,
          tooltip: 'Edit',
        },
        {
          icon: <Delete />,
          onClick: (v) => setDeleteUser(v),
          tooltip: 'Delete',
        },
      ]
    : [Role.BrandUser].includes(accountService.userValue.role) && accountService.userValue.brand_type === 'ffp'
    ? [
        {
          icon: (v) => (v.brand_admin ? <Security /> : null),
          url: (v) => `/users/${v.id}/permission`,
          tooltip: 'Permissions',
        },
        {
          icon: <Edit />,
          url: (v) => `/users/update/${v.id}`,
          onClick: (v) =>
            ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
            GAEvents('Edit user', 'Click on Edit Button'),
          tooltip: 'Edit',
        },
        {
          icon: (v) => (!v.brand_admin ? <Delete /> : null),
          onClick: (v) => {
            setDeleteUser(v);
            ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
              GAEvents('Delete user', 'Click on Delete Button');
          },
          tooltip: 'Delete',
        },
      ]
    : [Role.BrandUser].includes(accountService.userValue.role) && accountService.userValue.brand_type === 'ecom3k'
    ? [
        {
          icon: <Edit />,
          url: (v) => `/users/update/${v.id}`,
          onClick: (v) =>
            ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
            GAEvents('Edit user', 'Click on Edit Button'),
          tooltip: 'Edit',
        },
        {
          icon: (v) => (!v.brand_admin ? <Delete /> : null),
          onClick: (v) => {
            setDeleteUser(v);
            ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
              GAEvents('Delete user', 'Click on Delete Button');
          },
          tooltip: 'Delete',
        },
      ]
    : [];

  useEffect(() => {
    makeColumnFreeze(thisRef, visibilityModel, rows);
  }, [visibilityModel, rows]);

  return (
    <TableContainer>
      <div style={{display: 'none'}}>
        <CustomColumn thisRef={thisRef} rows={rows} buttons={customButtons} width="130px" />
      </div>
      <Grid item lg={12} style={{width: '100%'}}>
        {[Role.Admin, Role.Manager, Role.BrandUser].includes(accountService.userValue.role) && (
          <Link to="/users/create">
            <Button
              className={classes.button}
              onClick={() =>
                ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
                GAEvents('Create user', 'Click on Create Button')
              }
            >
              Create user
            </Button>
          </Link>
        )}
        <Grid item lg={12} position="relative">
          {[Role.Admin, Role.Manager].includes(accountService.userValue.role) ? (
            <DataGrid
              components={{
                Pagination: DataGridToolbar,
                Toolbar: DataGridToolbar,
              }}
              className={`${classes.grid} custom-table ${tableLoading && !loading ? 'load-headers' : ''}`}
              style={{width: '100%'}}
              autoHeight={true}
              pageSize={pageSize}
              page={page}
              loading={loading}
              rowCount={count}
              columnTypes={{filterString: stringColumnType}}
              onPageChange={onPageChange}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              onSortModelChange={handleSortModelChange}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[40]}
              rows={rows}
              columns={[...orderColumns, fixedPlaceHolder({width: 130})]}
              columnVisibilityModel={visibilityModel}
              onColumnVisibilityModelChange={(newModel) => {
                let data = {};
                Object.entries(newModel).forEach(([x, v]) => {
                  if (v === false) {
                    data = {...data, [x]: v};
                  }
                });
                const newOrder = getColumnsItems({
                  list: columns,
                  columnsVisibility: data,
                  columnsOrder: orderColumns,
                  currentOrder: orderColumns,
                });
                setVisibilityModel(data);
                setOrderColumns(newOrder);
                setUserPreferences(
                  {
                    columnVisibilityModel: data,
                    columnsOrder: newOrder.map((x) => x.field),
                  },
                  `users-${accountService.userValue.role}`,
                  setTableLoading
                );
              }}
            />
          ) : (
            ''
          )}
          {[Role.BrandUser].includes(accountService.userValue.role) ? (
            <DataGrid
              components={{
                Pagination: DataGridToolbar,
                Toolbar: DataGridToolbar,
              }}
              className={`${classes.grid} custom-table ${tableLoading && !loading ? 'load-headers' : ''}`}
              autoHeight={true}
              pageSize={pageSize}
              page={page}
              rowCount={count}
              columnTypes={{filterString: stringColumnType}}
              onPageChange={onPageChange}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              onSortModelChange={handleSortModelChange}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[40]}
              rows={rows}
              columns={[...orderColumns, fixedPlaceHolder({width: 130})]}
              columnVisibilityModel={visibilityModel}
              onColumnVisibilityModelChange={(newModel) => {
                let data = {};
                Object.entries(newModel).forEach(([x, v]) => {
                  if (v === false) {
                    data = {...data, [x]: v};
                  }
                });
                const newOrder = getColumnsItems({
                  list: columns,
                  columnsVisibility: data,
                  columnsOrder: orderColumns,
                  currentOrder: orderColumns,
                });
                setVisibilityModel(data);
                setOrderColumns(newOrder);
                setUserPreferences(
                  {
                    columnVisibilityModel: data,
                    columnsOrder: newOrder.map((x) => x.field),
                  },
                  `users-${accountService.userValue.role}`,
                  setTableLoading
                );
              }}
              loading={loading}
            />
          ) : (
            ''
          )}
        </Grid>
        <CustomAlert id="default-alert" />
      </Grid>
      <ModalButton
        openModal={deleteUser !== null}
        modalTitle="Delete user"
        hideButton
        closable
        onCloseText="No"
        onCloseAction={() => setDeleteUser(null)}
        actions={(closeModal): JSX.Element => (
          <Button
            onClick={() => {
              deleteUserById(deleteUser.id, closeModal);
              ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
                GAEvents('Users', 'Delete user', `User deleted: ${deleteUser.id}`);
            }}
          >
            Yes
          </Button>
        )}
      >
        {(closeModal): JSX.Element => {
          return (
            <span>
              Are you sure you want to delete user with email: <b>{deleteUser?.email}</b>
            </span>
          );
        }}
      </ModalButton>
    </TableContainer>
  );
}

export {Users};
