import {useEffect} from 'react';
import {Typography} from '@mui/material';
// services
import {accountService} from '../../../services/account.service';
import {alertService} from '../../../services/alert.service';
// utils
import {Api, errorAlert} from '../../../utils/api';
import {BrandTypesAlias} from '../../../utils/brand-types';

export default function AuthorizeAdsForBrand() {
  async function handleParams() {
    const url = new URL(window.location.href);
    const code = url.searchParams.get('code');
    const state = url.searchParams.get('state');
    const scope = url.searchParams.get('scope');

    try {
      await Api.post(`/advertising/authorize/${state}`, {
        code,
        scope,
      });
      alertService.success('Advertising authorization granted successfully');
      if (accountService.userValue.brand_type === BrandTypesAlias.ecom3k) window.location.replace(`account/connectors`);
      else window.location.replace(`brands/update/${state}`);
    } catch (e) {
      errorAlert('Something went wrong obtaining the advertising authorization. Please try again.', e);
    }
  }

  useEffect(() => {
    handleParams();
  }, []);

  return (
    <div style={{padding: 20}}>
      <Typography>Authorizing...</Typography>
    </div>
  );
}
