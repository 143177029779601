import {Tab, Tabs} from '@mui/material';
import {BarChart, Edit, InfoOutlined, ListAlt, MultilineChart, Subject} from '@mui/icons-material';
// services
import {accountService} from '../../../services/account.service';
// utils
import {Role} from '../../../utils/role';

const BuildMenu = (currentValue: string, id: string, history: any) => {
  return (
    <Tabs value={currentValue} orientation={'vertical'} centered>
      <Tab icon={<Edit />} label="Edit" value="update" onClick={() => history.push(`/brands/update/${id}/edit`)} />
      <Tab icon={<InfoOutlined />} label="Detail" value="detail" onClick={() => history.push(`/brands/${id}/detail`)} />
      {!(accountService?.userValue.role === Role.StandardAgencyUser) && (
        <Tab
          icon={<MultilineChart />}
          label="Ads Performance"
          value="ads_performance"
          onClick={() => history.push(`/brands/${id}/ads-performance/sp`)}
        />
      )}
      {!(accountService?.userValue.role === Role.StandardAgencyUser) && (
        <Tab
          icon={<Subject />}
          label="Monthly Reports"
          value="monthly_reports"
          onClick={() => history.push(`/brandreports/${id}`)}
        />
      )}
      <Tab
        icon={<BarChart />}
        label="Amazon Health"
        value="performance"
        onClick={() => history.push(`/brand-health/${id}`)}
      />
      <Tab
        icon={<ListAlt />}
        label="Inventory"
        value="inventory"
        onClick={() => history.push(`/brand-inventory/${id}`)}
      />
    </Tabs>
  );
};

export {BuildMenu};
