export const checkboxes: Array<{
    id: string;
    name: string;
    value: boolean;
}> =  [
    {
        id: 'product1',
        name: 'Product1',
        value: false,
    },
    {
        id: 'product2',
        name: 'Product2',
        value: false,
    },
    {
        id: 'product3',
        name: 'Product3',
        value: false,
    }
];

export const imageMaxFileSize = 5;
export const frontendMajorVersion = 0.1;
export const rowsPerPageOptions = [40, 60, 80, 100];
