export enum BrandTypesAlias {
  ffp = 'ffp',
  mr = 'mr',
  mc = 'mc',
  ecom3k = 'ecom3k',
}

const isFFP = (brandType: string) => brandType === BrandTypesAlias.ffp;
const isEcom3k = (brandType: string) => brandType === BrandTypesAlias.ecom3k;

export const BrandTypeUtils = {
  isFFP,
  isEcom3k
}