const metricOptionsData = {
  sp: {
    impressions: {
      color: '#FDD276',
      label: 'Impressions',
    },
    clicks: {
      color: '#F9BBB1',
      label: 'Clicks',
    },
    revenue: {
      color: '#929292',
      label: 'Revenue',
    },
    advertising_revenue: {
      color: '#4dc2c2',
      label: 'Advertising Sales',
      tooltip: 'Revenue generated from advertising for the selected time period.'
    },
    advertising_cost: {
      color: '#cf2727',
      label: 'Advertising Spend',
      tooltip: 'Cost of advertising for the selected time period.'
    },
    acos: {
      color: '#19c45d',
      label: 'ACos(%)',
      tooltip: 'The ACoS for the selected time period.'
    },
    tacos: {
      color: '#e4ff00',
      label: 'TACos(%)',
    }, 
    orders: {
      color: '#a4ff00',
      label: 'Orders',
    },
  },
  sb: {
    impressions: {
      color: '#FDD276',
      label: 'Impressions',
    },
    clicks: {
      color: '#F9BBB1',
      label: 'Clicks',
    },
    revenue: {
      color: '#929292',
      label: 'Revenue',
    },
    advertising_revenue: {
      color: '#4dc2c2',
      label: 'Advertising Revenue',
      tooltip: 'The sum of Advertising Cost for the selected time period.'
    },
    advertising_cost: {
      color: '#cf2727',
      label: 'Advertising Cost',
    },
    acos: {
      color: '#19c45d',
      label: 'ACos(%)',
      tooltip: 'The ACoS for the selected time period.'
    },
    tacos: {
      color: '#e4ff00',
      label: 'TACos(%)',
    },
    orders: {
      color: '#a4ff00',
      label: 'Orders',
    },
  },
  attribution: {
    click_throughs: {
      color: '#FDD276',
      label: 'Click-Throughs',
    },
    atc: {
      color: '#F9BBB1',
      label: 'ATC',
    },
    dpv: {
      color: '#929292',
      label: 'DPV',
    },
    purchases: {
      color: '#4dc2c2',
      label: 'Purchases',
    },
    total_purchases: {
      color: '#cf2727',
      label: 'Total Purchases',
    },
    unit_sold: {
      color: '#19c45d',
      label: 'Unit Sold',
    },
    total_unit_sold: {
      color: '#e4ff00',
      label: 'Total Unit Sold',
    },
    total_product_sales: {
      color: '#003ea9',
      label: 'Total Product Sales',
    },
  },
  dsp: {
    impressions: {
      color: '#cf2727',
      label: 'Impressions',
    },
    total_cost: {
      color: '#FDA276',
      label: 'Total Cost',
    },
    click_throughs: {
      color: '#FDD276',
      label: 'Click-Throughs',
    },
    total_product_sales: {
      color: '#929292',
      label: 'Total Product Sales',
    },
    atc: {
      color: '#F9BBB1',
      label: 'ATC',
    },
    purchases: {
      color: '#4dc2c2',
      label: 'Purchases',
    },
    dpv: {
      color: '#19c45d',
      label: 'DPV',
    },
  }
};

export { metricOptionsData }
