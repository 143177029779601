import {Redirect, Route} from 'react-router-dom';
// services
import {accountService} from '../../../services/account.service';
// utils
import {Role} from '../../../utils/role';
import {BrandTypesAlias} from '../../../utils/brand-types';

interface PrivateRouteProps {
  [key: string]: any;
  component: any;
  roles: string[];
  path: string;
  exact?: boolean;
  brandTypes?: BrandTypesAlias[];
  requireFfpActiveSubscription?: boolean;
  requiresBrandAdminPermissions?: boolean;
  hideForFFPAdmin?: boolean;
}

function PrivateRoute({
  component: Component,
  roles,
  brandTypes,
  requiresBrandAdminPermissions,
  requireFfpActiveSubscription,
  hideForFFPAdmin,
  ...rest
}: PrivateRouteProps) {
  const user = accountService.userValue;
  let restricted = false;
  let redirectRoute = '/';

  if (user) {
    restricted = roles && roles.indexOf(user.role) === -1;

    if (roles.includes(Role.BrandUser) && user.role === Role.BrandUser && brandTypes && brandTypes.length > 0) {
      restricted = brandTypes.indexOf(user.brand_type) === -1;

      if (['ffp', 'ecom3k'].includes(user.brand_type) && user.brand_admin && user.active_brand_subscription) {
        redirectRoute = 'account';
      } else if (user.brand_type === BrandTypesAlias.ffp && !user.brand_admin && user.active_brand_subscription) {
        redirectRoute = 'amazon-listings-items';
      } else {
        redirectRoute = '/';
      }
    }

    if (user.brand_type === BrandTypesAlias.ffp && hideForFFPAdmin) {
      if (user.brand_admin) {
        restricted = true;
        redirectRoute = '/amazon-listings-items';
      }
      if (!user.brand_admin && user.active_brand_subscription) {
        restricted = true;
        redirectRoute = '/amazon-listings-items';
      }
    }

    if (user.brand_type === BrandTypesAlias.ecom3k && hideForFFPAdmin) {
      if (user.brand_admin) {
        restricted = true;
        redirectRoute = '/account/billing';
      }
      if (!user.brand_admin && user.active_brand_subscription) {
        restricted = true;
        redirectRoute = '/account/billing';
      }
    }

    if (
      requireFfpActiveSubscription &&
      ['ffp', 'ecom3k'].includes(user.brand_type) &&
      !user.active_brand_subscription
    ) {
      restricted = true;
      if (user.brand_admin) {
        redirectRoute = '/account/billing';
      } else {
        redirectRoute = '/';
      }
    }

    if (user.role === Role.BrandUser && requiresBrandAdminPermissions && !user.brand_admin) {
      restricted = true;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{pathname: '/login', state: {from: props.location}}} />;
        }
        // check if route is restricted by role
        if (restricted) {
          // role not authorized so redirect to home page
          return <Redirect to={{pathname: redirectRoute}} />;
        }

        // authorized so return component
        return <Component {...props} {...rest.componentProps} />;
      }}
    />
  );
}

export {PrivateRoute};
