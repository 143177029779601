import {useEffect, useState} from 'react';
import {
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Theme,
} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import {Edit, Delete, ArrowBack} from '@mui/icons-material';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// services
import {alertService} from '../../../../services/alert.service';
// components
import Button from '../../../../components/Button/Button';
import SkeletonLoader from '../../../../components/SkeletonLoader/SkeletonLoader';
import {CustomAlert} from '../../../Login/components/CustomAlert';

const useStyles = makeStyles((theme: Theme) => ({
  inputField: {
    margin: '0.75em 0',
    width: '95%',
  },
  buttons: {
    margin: '0.75em 0',
    '& > button:first-of-type': {
      marginRight: '0.5em',
    },
  },
  customField: {
    position: 'relative',
    '& > .MuiOutlinedInput-root:last-of-type': {
      backgroundColor: theme.palette.primary.main,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
    },
    '& > .MuiFormControl-root > .MuiOutlinedInput-root:first-of-type ': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
    '& > .MuiOutlinedInput-root:last-of-type > fieldset': {
      borderColor: 'transparent',
    },
  },
}));

export default function CustomAttributesConfig() {
  const classes = useStyles();
  const [editAttributes, setEditAttributes] = useState(new Set());
  const [isSubmitting, setIsSubmitting] = useState(new Set());
  const [editValues, setEditValues] = useState({});
  const [attributes, setAttributes] = useState([]);
  const [newName, setNewName] = useState('');
  const [newType, setNewType] = useState('TEXT');
  const [toDelete, setToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const brandCode = search.get('brand');
  const marketplace = search.get('marketplace');
  const sku = search.get('sku');

  const brand = window.location.pathname.includes('brands-custom-attribute');

  async function fetchProduct(brand?) {
    setLoading(true);
    const endpoint = brand ? 'brand-custom-attribute/all' : '/custom-attribute/all';
    try {
      const {data} = await Api.get(endpoint);
      setAttributes(data);
    } catch (e) {
      errorAlert('Unable to get custom attributes', e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProduct(brand && brand);
  }, []);

  async function addAttribute(name, type) {
    const endpoint = brand ? '/brand-custom-attribute/create-attribute' : '/custom-attribute/config';
    try {
      await Api.post(endpoint, {
        name: name,
        type: type,
      });
      alertService.success('Successfully added custom attribute');
      await fetchProduct(brand && brand);
      setNewName('');
      setNewType('TEXT');
    } catch (e) {
      errorAlert('Unable to add custom attribute', e);
    }
  }

  async function deleteAttribute(name) {
    const endpoint = brand ? `/brand-custom-attribute/${name}` : `/custom-attribute/config/${name}`;
    try {
      await Api.delete(endpoint);
      alertService.success('Successfully deleted custom attribute');
      await fetchProduct(brand && brand);
      setToDelete(null);
    } catch (e) {
      errorAlert('Unable to delete custom attribute', e);
    }
  }

  async function editAttribute(name, type) {
    isSubmitting.add(name);
    setIsSubmitting(new Set(isSubmitting));
    const endpoint = brand ? '/brand-custom-attribute' : '/custom-attribute/config';
    try {
      await Api.put(endpoint, {
        name: name,
        type: type,
      });
      alertService.success('Successfully edited custom attribute');
      await fetchProduct(brand && brand);
      editAttributes.delete(name);
      setEditAttributes(new Set(editAttributes));
      isSubmitting.delete(name);
      setIsSubmitting(new Set(isSubmitting));
    } catch (e) {
      errorAlert('Unable to edit custom attribute', e);
      isSubmitting.delete(name);
      setIsSubmitting(new Set(isSubmitting));
    }
  }

  return (
    <Grid container justifyContent={'center'} style={{padding: '50px 25px'}}>
      {(brandCode || (marketplace && sku)) && (
        <Grid container>
          <Link
            to={
              brandCode
                ? `/brands/update/${brandCode}/custom-attributes`
                : `/amazon-listings-item/${marketplace}/${sku}/custom_attributes`
            }
          >
            <Typography color="secondary" variant="h5" style={{display: 'flex', alignItems: 'center'}}>
              <ArrowBack style={{margin: '0 10px'}} /> Go Back
            </Typography>
          </Link>
        </Grid>
      )}
      <Grid container spacing={1} justifyContent="center" style={{paddingTop: 30}}>
        <Grid item xs={12} sm={8} md={6} lg={6} style={{maxWidth: '600px'}}>
          <Grid container spacing={1} justifyContent={'space-between'} style={{paddingTop: 30}}>
            <Grid item lg={8} className={classes.customField}>
              <TextField
                style={{width: '70%'}}
                label="Name"
                size="medium"
                value={newName}
                variant="outlined"
                onChange={(e) => setNewName(e.target.value)}
              />
              <Select size="medium" value={newType} onChange={(e) => setNewType(e.target.value)} label="Type">
                <MenuItem value={'TEXT'}>TEXT</MenuItem>
                <MenuItem value={'LINK'}>LINK</MenuItem>
                <MenuItem value={'NUMBER'}>NUMBER</MenuItem>
                <MenuItem value={'USER'}>USER</MenuItem>
              </Select>
            </Grid>

            <Grid item alignItems={'center'}>
              <Button disabled={!newName} onClick={() => addAttribute(newName, newType)}>
                Add
              </Button>
            </Grid>
          </Grid>
          {loading ? (
            <Grid container style={{margin: '30px 0'}}>
              <SkeletonLoader numberOfLines={10} xs={12} height={25} />
            </Grid>
          ) : (
            attributes.map((x) => {
              return (
                <Grid container spacing={1} justifyContent={'space-between'} style={{paddingTop: 30}}>
                  <Grid item xs={8} sm={7} lg={8}>
                    <Typography component="h2" variant="h6" color="secondary" gutterBottom>
                      {x?.name}
                    </Typography>
                    {editAttributes.has(x.name) ? (
                      <>
                        <Select
                          style={{width: '100%'}}
                          defaultValue={x.type}
                          onChange={(e) => setEditValues({...editValues, [x.name]: e.target.value})}
                          label="Type"
                          disabled={!editAttributes.has(x.name)}
                          size="small"
                          required
                        >
                          <MenuItem value={'TEXT'}>TEXT</MenuItem>
                          <MenuItem value={'LINK'}>LINK</MenuItem>
                          <MenuItem value={'NUMBER'}>NUMBER</MenuItem>
                          <MenuItem value={'USER'}>USER</MenuItem>
                        </Select>
                      </>
                    ) : (
                      <>
                        <Typography component="h2" variant="subtitle1" color="primary" gutterBottom>
                          {x?.type ? x.type : 'N/A'}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={4} sm={5} lg={4} container justifyContent={'flex-end'}>
                    {editAttributes.has(x.name) ? (
                      <div className={classes.buttons}>
                        <Button
                          color="primary"
                          onClick={() => {
                            if (editValues[x.name] && editValues[x.name] !== x.type) {
                              editAttribute(x.name, editValues[x.name]);
                            } else {
                              editAttributes.delete(x.name);
                              setEditAttributes(new Set(editAttributes));
                            }
                          }}
                          disabled={isSubmitting.has(x.name)}
                          size="small"
                        >
                          Save
                        </Button>
                        <Button
                          color="error"
                          onClick={() => {
                            editAttributes.delete(x.name);
                            setEditAttributes(new Set(editAttributes));
                          }}
                          size="small"
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <div className={classes.buttons}>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            editAttributes.add(x.name);
                            setEditAttributes(new Set(editAttributes));
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton color={'error'} onClick={() => setToDelete(x.name)}>
                          <Delete />
                        </IconButton>
                      </div>
                    )}
                  </Grid>
                </Grid>
              );
            })
          )}
        </Grid>
        <Dialog open={toDelete} onClose={() => setToDelete(null)} fullWidth={true}>
          <DialogTitle>Delete custom attribute</DialogTitle>
          <DialogContent>
            <Typography variant={'subtitle1'}>
              Are you sure that you want to delete custom attribute {toDelete}?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setToDelete(null)}>No</Button>
            <Button onClick={() => deleteAttribute(toDelete)} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <CustomAlert className={classes.inputField} id="default-alert" />
      </Grid>
    </Grid>
  );
}
