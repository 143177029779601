import {useEffect, useState} from 'react';
import {Typography, Grid} from '@mui/material';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-mui';
import * as Yup from 'yup';
import {useHistory, useParams} from 'react-router-dom';
// components
import TableContainer from '../../../../../../components/TableContainer/TableContainer';
import Button from '../../../../../../components/Button/Button';
import {GoogleDrive} from '../../../../components/GoogleDrive';
import {AuthorizeSpApiForFFPBrand} from '../../components/AuthorizeSpApiForFFPBrand';
import {AuthorizeAdvertisingForFFPBrand} from '../../components/AuthorizeAdvertisingForFFPBrand';
import {GoogleDriveTopFolder} from '../../../../components/GoogleDriveTopFolder';
import Spacer from '../../../../../../components/Spacer/Spacer';
import {CustomAlert} from '../../../../../Login/components/CustomAlert';
// styles
import {useStyles} from '../../../../Styles';
// services
import {alertService} from '../../../../../../services/alert.service';
import {accountService} from '../../../../../../services/account.service';
// utils
import {withTracker} from '../../../../../../utils/withTracker';
import {Api, errorAlert} from '../../../../../../utils/api';
import {BrandTypesAlias} from '../../../../../../utils/brand-types';
import {getArticle} from '../../../../../../utils/FFPBeacon';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

function UpdateFfpSubBrand() {
  const classes = useStyles();
  const [values, setValues] = useState(null);
  const {id} = useParams<Record<string, string | undefined>>();
  const history = useHistory();

  async function pullAllData() {
    try {
      const {data} = await Api.get(`brands/${id}`);
      setValues(data);
    } catch (e) {
      errorAlert('Unable to load brand data', e);
    }
  }

  async function onSubmit(data, {setSubmitting}) {
    alertService.clear();
    try {
      await Api.put(`brands/${accountService.userValue.brand_code}/sub-brands/${id}`, data);
      alertService.success('Sub brand updated successfully');
      history.push(`/account/connectors`);
    } catch (e: any) {
      setSubmitting(false);
      errorAlert(e?.response?.data?.message || e?.message || 'Something went wrong', e);
    }
  }

  useEffect(() => {
    pullAllData();
  }, []);

  useEffect(() => {
    getArticle({filterPath: true});
  }, [values]);

  return (
    <TableContainer container style={{paddingTop: 60}}>
      <Typography component="h2" variant="h4" color="primary" gutterBottom>
        Update sub brand
      </Typography>

      {[BrandTypesAlias.ffp, BrandTypesAlias.ecom3k].includes(accountService.userValue.brand_type) && (
        <>
          <Grid container style={{marginBottom: 20}} spacing={1.5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={5} className={classes.spApiCard}>
              <AuthorizeSpApiForFFPBrand
                brandInfo={values}
                brandCode={values?.brand_code}
                subBrand
                GAEvents={(category, action, label) => withTracker(category, action, label)}
                advertisingAPI={
                  accountService.userValue.brand_type === 'ecom3k' && (
                    <AuthorizeAdvertisingForFFPBrand brandInfo={values} brandCode={values?.brand_code} />
                  )
                }
              />
            </Grid>
            {accountService.userValue.brand_type === BrandTypesAlias.ecom3k && (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <GoogleDriveTopFolder />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} xl={12}>
            {values && (
              <Formik
                initialValues={values}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange={true}
                enableReinitialize={true}
              >
                {({errors, touched, isSubmitting, values: formValues, submitForm, setFieldValue}) => (
                  <Form>
                    <Grid container>
                      <Grid container>
                        <Field
                          className={classes.inputField}
                          component={TextField}
                          variant="outlined"
                          name="name"
                          label="Brand name"
                          type="text"
                          size="small"
                        />
                      </Grid>

                      {accountService.userValue.brand_type === BrandTypesAlias.ecom3k && (
                        <>
                          <Spacer height={10} />
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Grid item lg={12}>
                              <Typography component="h2" variant="h6" color="secondary" gutterBottom>
                                Google Drive
                              </Typography>
                              <GoogleDrive
                                brandReports={formValues.google_drive_folders}
                                lastSuccess={formValues.last_success}
                                brandCode={values?.brand_code}
                                onSubmit={submitForm}
                                setValues={setFieldValue}
                                keyValue="google_drive_folders"
                                isSubmitting={isSubmitting}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <div style={{height: 10}} />

                      <Grid
                        item
                        lg={12}
                        md={4}
                        sm={6}
                        xs={12}
                        style={{display: 'flex', justifyContent: 'flex-start', padding: '20px 0'}}
                      >
                        <Button
                          width="100px"
                          disabled={isSubmitting}
                          type="submit"
                          onClick={() => {
                            withTracker('Sub Brand', 'Update', values.name);
                          }}
                        >
                          Save
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomAlert className={classes.inputField} id="default-alert" />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </Grid>
        </>
      )}
    </TableContainer>
  );
}

export {UpdateFfpSubBrand};
