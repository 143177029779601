import {useState} from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/system';
import {makeStyles} from '@mui/styles';
import {useTheme} from '@mui/material/styles';
import {Box, AppBar, Tab, Tabs, Theme} from '@mui/material';
// components
import TableContainer from '../../../components/TableContainer/TableContainer';
import AlertsTable from '../Alerts';
import LateFBMShipments from '../subpages/LateFBMShipments/LateFBMShipments';
import DeletedSkus from '../subpages/DeletedSkus/DeletedSkus';
import ParentChanges from '../subpages/ParentChanges/ParentChanges';
// services
import {accountService} from '../../../services/account.service';
// utils
import {Role} from '../../../utils/role';

const CustomAppBar = styled(AppBar)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  '& .MuiTabs-root, & .MuiTabs-scroller,  & .MuiTabs-flexContainer': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '700px',
    justifyContent: 'center',
  },
  '& .MuiTabs-flexContainer > button': {
    padding: '0 1.5em',
  },
  '& .Mui-selected ': {
    fontWeight: 'bold',
  },
  [theme.breakpoints.down(767)]: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .Mui-selected': {
      borderBottom: '2px solid',
      borderColor: theme.palette.mode === 'light' ? 'primary' : 'secondary',
    },
  },
  [theme.breakpoints.down(620)]: {
    '& .MuiTabs-flexContainer > button': {
      minHeight: 'auto',
      fontSize: '12px',
      padding: '1em',
    },
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'flex',
    flexDirection: 'column-reverse',
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgb(23 69 130 / 8%)',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '&.load-headers': {
      opacity: 0.5,
    },
    '& .textButton': {
      fontSize: '12px',
      textDecoration: 'underline',
      padding: '0!important',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '75vh!important',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5f5f5',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
      background: '#999',
      borderRadius: '3px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
      background: '#777',
    },
    '& .MuiDataGrid-virtualScrollerContent': {
      display: 'flex',
    },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      transform: 'none',
    },
  },
  inputField: {
    margin: '0.75em 0',
    width: '100%',
    '& .MuiInputLabel-shrink': {
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main,
    },
  },
  button: {
    textTransform: 'none',
  },
  radioGroup: {
    '&.MuiFormGroup-root': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      color: theme.palette.mode === 'light' ? '#000' : '#f0f0f0',
    },
    '&.MuiFormGroup-root > .MuiFormControlLabel-root': {
      padding: '10px 10px',
    },
    '& .MuiTypography-root': {
      padding: '0 2px',
    },
  },

  alertsToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiGrid-root:last-of-type': {
      display: 'flex',
    },

    [theme.breakpoints.down(1130)]: {
      '& .MuiFormControlLabel-root .MuiTypography-root': {
        fontSize: '12px',
      },
    },
    [theme.breakpoints.down(767)]: {
      display: 'flex',
      flexDirection: 'column',
      '& > div, & a, button:not(.MuiOutlinedInput-root button), & > .MuiAutocomplete-root': {
        width: '100%',
      },
      '& .MuiFormGroup-root': {
        flexDirection: 'row',
        alignItems: 'center',
      },
      '& .MuiFormControlLabel-root .MuiTypography-root': {
        fontSize: '14px',
      },
      '& .MuiGrid-root:last-of-type': {
        flexDirection: 'column',
        '& a div': {
          margin: 0,
        },
      },
    },
    [theme.breakpoints.down(420)]: {
      '& .MuiTypography-root, & .MuiButton-root': {
        width: '100%',
      },
    },
  },
  toolbar: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '& > .MuiAutocomplete-root': {
      width: '200px',
    },
    '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
      color: theme.palette.mode === 'light' ? '#000' : '#f0f0f0',
    },
    [theme.breakpoints.down(1130)]: {
      '& .MuiFormControlLabel-root .MuiTypography-root': {
        fontSize: '12px',
      },
    },
    [theme.breakpoints.down(720)]: {
      display: 'flex',
      flexDirection: 'column',
      '& > div, & a, button:not(.MuiOutlinedInput-root button), & > .MuiAutocomplete-root': {
        width: '100%',
      },
      '& .MuiFormGroup-root': {
        flexDirection: 'row',
        alignItems: 'center',
      },
      '& .MuiFormControlLabel-root .MuiTypography-root': {
        fontSize: '14px',
      },
    },
    [theme.breakpoints.down(420)]: {
      '& .MuiTypography-root, & .MuiButton-root': {
        width: '100%',
      },
    },
  },
  toolbarButton: {
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '.5rem 1rem .4rem 1rem',
    [theme.breakpoints.down(820)]: {
      fontSize: '12px',
    },
  },
  iconFlag: {
    padding: '8px 8px 2px 8px',
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : '#f3f3f3',
    borderRadius: '50%',
  },
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{flexGrow: 1}}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function Alerts() {
  const theme = useTheme();
  const classes = useStyles();
  document.title = 'Stonehenge.ai/Alerts';
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let extraTabs = [];
  switch (accountService.userValue.role) {
    case Role.BrandUser:
      break;
    case Role.Admin:
    case Role.Manager:
    default:
      extraTabs = [
        {label: 'Late FBM Shipments', value: 1, props: {...a11yProps(1)}},
        {label: 'Deleted SKUs', value: 2, props: {...a11yProps(2)}},
        {label: 'Parent Changes', value: 3, props: {...a11yProps(3)}},
      ];

      break;
  }

  return (
    <>
      <CustomAppBar position={'static'} color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
          textColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="All Alerts" value={0} {...a11yProps(0)} />
          {extraTabs.map((tab, index) => (
            <Tab key={index} label={tab.label} value={tab.value} {...tab.props} />
          ))}
        </Tabs>
      </CustomAppBar>
      <TableContainer>
        <TabPanel value={value} index={0}>
          <AlertsTable alertType="All" classes={classes} key={`tabPanel-0`} />
        </TabPanel>
        <TabPanel value={value} index={1} key={`tabPanel-1}`}>
          <LateFBMShipments classes={classes} alertType={'late-fbm-shipments'} />
        </TabPanel>
        <TabPanel value={value} index={2} key={`tabPanel-2`}>
          <DeletedSkus classes={classes} alertType={'deleted-skus'} />
        </TabPanel>
        <TabPanel value={value} index={3} key={`tabPanel-3`}>
          <ParentChanges classes={classes} alertType={'parent-changes'} />
        </TabPanel>
      </TableContainer>
    </>
  );
}

export {Alerts};
