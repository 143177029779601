import {Paper, Typography} from '@mui/material';
import {useHistory} from 'react-router-dom';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-mui';
import * as Yup from 'yup';
// services
import {alertService} from '../../../../services/alert.service';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// components
import Button from '../../../../components/Button/Button';
import {CustomAlert} from '../../../Login/components/CustomAlert';

const initialValues = {
  asin: '',
};
const validationSchema = Yup.object().shape({
  asin: Yup.string().required('ASIN number is required'),
});

function AsinLookupWidget({classes}) {
  let history = useHistory();

  async function onSubmit(data, {setSubmitting}) {
    alertService.clear('asin-lookup-alert');
    try {
      await Api.get(`asins/${data.asin}`);
      history.push(`/asins/view/${data.asin}`);
    } catch (e) {
      errorAlert('Asin not found', e, 'asin-lookup-alert');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Paper className={classes.wrapperDiv} variant={'outlined'}>
      <Typography component="h2" variant="h5" color="secondary" gutterBottom>
        Lookup ASIN
      </Typography>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({errors, touched, isSubmitting}) => (
          <Form>
            <div>
              <div>
                <Field
                  className={classes.inputField}
                  component={TextField}
                  variant="outlined"
                  name="asin"
                  label="Asin number"
                  type="text"
                  size="small"
                />
              </div>
              <div>
                <Button type="submit">Search</Button>
                <CustomAlert className={classes.inputField} id="asin-lookup-alert" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Paper>
  );
}

export {AsinLookupWidget};
