import {useState, useEffect} from 'react';
import {Grid, Tabs, useTheme, Tab, Theme} from '@mui/material';
import {TabPanel, TabContext} from '@mui/lab';
import {useHistory, useParams} from 'react-router-dom';
// components
import CustomTabs from '../../../../components/CustomTabs/CustomTabs';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {UpdateBrand} from '../UpdateBrand/UpdateBrand';
import {Channels} from '../Channels/Channels';
import TableContainer from '../../../../components/TableContainer/TableContainer';
import CustomAttributes from '../../../ProductDetails/subpages/CustomAttributes/CustomAttributes';
import Notes from '../Notes/Notes';
import {BuildMenu} from '../../components/menu';
import {MenuStore} from '../../../../App';
// styles
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down(1024)]: {
      marginTop: '-64px',
    },
  },
}));

const tabNames = [
  {tabKey: 'edit', tabLabel: 'Edit'},
  {tabKey: 'custom-attributes', tabLabel: 'Custom Attributes'},
  {tabKey: 'notes', tabLabel: 'Notes'},
  {tabKey: 'channels', tabLabel: 'Channels'},
];

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default function UpdateBrandView() {
  let history = useHistory();
  const theme = useTheme();
  const classes = useStyles();

  const {id, tabValue} = useParams<Record<string, string | undefined>>();
  const menuItems = BuildMenu('update', id, history);
  const [value, setValue] = useState(tabValue);

  document.title = `Stonehenge.ai/UpdateBrand`;

  const navigateTo = (tabName) => {
    history.push(`/brands/update/${id}/${tabName}`);
  };

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = menuItems;
    });
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <TabContext value={value}>
          <CustomTabs position={'static'} color="default">
            <Tabs
              value={value}
              onChange={(e, v) => {
                setValue(v);
              }}
              indicatorColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
              textColor={theme.palette.mode === 'light' ? 'primary' : 'secondary'}
              variant="scrollable"
              scrollButtons="auto"
              style={{justifyContent: 'center'}}
            >
              {tabNames.map(({tabKey, tabLabel}, idx) => (
                <Tab
                  label={tabLabel}
                  value={tabKey}
                  key={tabKey}
                  {...a11yProps(idx)}
                  onClick={() => {
                    navigateTo(tabKey);
                  }}
                />
              ))}
            </Tabs>
          </CustomTabs>
          <TabPanel value={value} style={{padding: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
            <TableContainer className="sub-page">
              {value === 'edit' && <UpdateBrand />}
              {value === 'custom-attributes' && (
                <TableContainer className="sub-page">
                  <CustomAttributes brand={id} />
                </TableContainer>
              )}
              {value === 'notes' && <Notes />}
              {value === 'channels' && <Channels />}
            </TableContainer>
          </TabPanel>
        </TabContext>
      </Grid>
      <Grid item xs={12}>
        <CustomAlert id="ads-performance-tab-alert" />
      </Grid>
    </Grid>
  );
}
