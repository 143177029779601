import React from 'react';
// images
import Subscriber from '../../assets/images/Subscriber.svg';
import SubscriberDark from '../../assets/images/Subscriber-Dark.svg';
import Logo from '../../assets/images/logo.png';
import ECOM3K from '../../assets/images/ECOM3K_logo.png';

interface ImagesProps {
  name: string;
  className?: string;
  width?: number | string;
  height?: number;
  tooltip?: boolean;
  onClick?: () => void;
  maxWidth?: number | string;
}

export default function Images({name, className, width, height, tooltip, onClick, maxWidth}: ImagesProps): JSX.Element {
  const imagesMap: Map<string, {component: any; alt: string}> = new Map([
    ['Subscriber', {component: Subscriber, alt: 'subscriber'}],
    ['SubscriberDark', {component: SubscriberDark, alt: 'subscriberDark'}],
    ['Logo', {component: Logo, alt: 'Logo'}],
    ['ECOM3K', {component: ECOM3K, alt: 'ECOM3K'}],
  ]);
  const image = imagesMap.get(name);

  if (!image) {
    return <React.Fragment />;
  }

  return (
    <img
      src={image.component}
      alt={image.alt}
      className={className}
      style={{maxWidth: maxWidth}}
      width={width}
      height={height}
      onClick={onClick}
    />
  );
}
