import {Theme, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useParams} from 'react-router-dom';
// components
import SummaryItem from './SummaryItem';
import {CustomAlert} from '../../../../Login/components/CustomAlert';
import {metricOptionsData} from './Metrics';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    gridGap: '15px',
    '& .MuiTypography-subtitle1 ': {
      fontSize: '.8rem',
      whiteSpace: 'nowrap',
    },
    '& .MuiTypography-h5': {
      fontSize: '1rem',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down(1320)]: {
      '& .MuiTypography-subtitle1 ': {
        fontSize: '.7rem',
      },
      '& .MuiTypography-h5': {
        fontSize: '.8rem',
      },
    },
    [theme.breakpoints.down(380)]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
    },
  },
}));

interface BrandPerformanceSummaryProps {
  data: any;
  loading?: boolean;
  hiddenMetrics?: string[];
}

function BrandPerformanceSummary({data, loading, hiddenMetrics}: BrandPerformanceSummaryProps) {
  const {adsType} = useParams<any>();
  const metricsOptions = metricOptionsData[adsType] || {};
  const classes = useStyles();
  return (
    <Grid container className={classes.grid}>
      {Object.keys(metricsOptions).map((metricKey: any, idx) =>
        hiddenMetrics?.includes(metricKey) ? null : (
          <SummaryItem loading={loading} key={idx} data={data?.[metricKey]} metricOption={metricsOptions[metricKey]} />
        )
      )}
      <CustomAlert id="brand-summary" />
    </Grid>
  );
}

export {BrandPerformanceSummary};
