import {Typography} from '@mui/material';
import {GridColDef, GridCellParams} from '@mui/x-data-grid';
// utils
import {Helper} from '../../../utils/helper';

export function getColumns(adsType, groupName): GridColDef[] {
  return ['sb', 'sp'].includes(adsType)
    ? [
        {
          field: 'name',
          headerName: groupName,
          minWidth: 350,
          filterable: true,
        },
        {
          field: 'impressions',
          headerName: 'Impressions',
          minWidth: 150,
          filterable: false,
          renderCell: (params: GridCellParams) => (
            <Typography variant="subtitle1">{Helper.formatAmount(params.row.impressions)}</Typography>
          ),
        },
        {
          field: 'clicks',
          headerName: 'Clicks',
          minWidth: 100,
          filterable: false,
          renderCell: (params: GridCellParams) => (
            <Typography variant="subtitle1">{Helper.formatAmount(params.row.clicks)}</Typography>
          ),
        },
        {
          field: 'advertising_cost',
          headerName: 'Spend',
          minWidth: 150,
          filterable: false,
          renderCell: (params: GridCellParams) => (
            <Typography variant="subtitle1">{Helper.formatAmount(params.row.advertising_cost)}</Typography>
          ),
        },
        {
          field: 'advertising_revenue',
          headerName: 'Advertising Revenue',
          minWidth: 200,
          filterable: false,
          renderCell: (params: GridCellParams) => (
            <Typography variant="subtitle1">{Helper.formatAmount(params.row.advertising_revenue)}</Typography>
          ),
        },
        {
          field: 'orders',
          headerName: 'Orders',
          minWidth: 100,
          filterable: false,
          renderCell: (params: GridCellParams) => (
            <Typography variant="subtitle1">{Helper.formatAmount(params.row?.orders)}</Typography>
          ),
        },
        {
          field: 'acos',
          headerName: 'ACOS',
          minWidth: 200,
          filterable: false,
          renderCell: (params: GridCellParams) => (
            <Typography variant="subtitle1">{Helper.formatAmount(params.row?.acos)}</Typography>
          ),
        },
      ]
    : [
        {
          field: 'name',
          headerName: groupName,
          minWidth: 200,
          filterable: true,
        },
        {
          field: 'click_throughs',
          headerName: 'Click-Throughs',
          minWidth: 200,
          filterable: false,
          renderCell: (params: GridCellParams) => (
            <Typography variant="subtitle1">{Helper.formatAmount(params.row.click_throughs)}</Typography>
          ),
        },
        {
          field: 'dpv',
          headerName: 'DPV',
          minWidth: 200,
          filterable: false,
          renderCell: (params: GridCellParams) => (
            <Typography variant="subtitle1">{Helper.formatAmount(params.row.dpv)}</Typography>
          ),
        },
        {
          field: 'atc',
          headerName: 'ATC',
          minWidth: 200,
          filterable: false,
          renderCell: (params: GridCellParams) => (
            <Typography variant="subtitle1">{Helper.formatAmount(params.row.atc)}</Typography>
          ),
        },
        {
          field: 'total_purchases',
          headerName: 'Total Purchases',
          minWidth: 200,
          filterable: false,
          renderCell: (params: GridCellParams) => (
            <Typography variant="subtitle1">{Helper.formatAmount(params.row.total_purchases)}</Typography>
          ),
        },
        {
          field: 'total_product_sales',
          headerName: 'Total Product Sales',
          minWidth: 300,
          filterable: false,
          renderCell: (params: GridCellParams) => (
            <Typography variant="subtitle1">{Helper.formatAmount(params.row.total_product_sales)}</Typography>
          ),
        },
      ];
}
