import Grid from '@mui/material/Grid';
import {Chip, Paper, Tooltip, Typography, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
// components
import SkeletonLoader from '../../../../../components/SkeletonLoader/SkeletonLoader';
// utils
import {Helper} from '../../../../../utils/helper';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: '10px',
  },
  green: {
    backgroundColor: '#4caf50',
  },
  red: {
    backgroundColor: '#f44336',
  },
  compare: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

interface SummaryItemProps {
  loading?: boolean;
  data: any;
  metricOption: any;
}

export default function SummaryItem({data, metricOption, loading}: SummaryItemProps) {
  const classes = useStyles();
  function getPercent(current, previous) {
    return previous === 0 ? null : Helper.formatAmount(Number((((current - previous) * 100) / previous).toFixed(2)));
  }

  return (
    <Grid item style={{width: '100%'}}>
      <Paper className={classes.box} variant={'elevation'}>
        {loading ? (
          <SkeletonLoader numberOfLines={7} xs={12} height={11} />
        ) : (
          <>
            {metricOption?.tooltip ? (
              <Tooltip title={metricOption?.tooltip} style={{cursor: 'help'}}>
                <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                  {metricOption?.label}
                </Typography>
              </Tooltip>
            ) : (
              <Typography component="h2" variant="subtitle1" color="secondary" gutterBottom>
                {metricOption?.label}
              </Typography>
            )}
            <Typography component="h2" variant="h5" className={classes.compare}>
              <span style={{paddingRight: '5px'}}>
                {data?.current ? Helper.formatAmount(Number(data.current)) : 'N/A'}
              </span>
              {data?.hasOwnProperty('previous') && data?.previous > 0 && (
                <Chip
                  className={data.current >= data.previous ? classes.green : classes.red}
                  size="medium"
                  label={`${data.current < data.previous ? '' : '+'}${getPercent(data.current, data.previous)}%`}
                  color={'primary'}
                />
              )}
            </Typography>
            <Typography component="h2" variant="subtitle1" color="textSecondary" gutterBottom>
              {data?.previous ? Helper.formatAmount(Number(data?.previous)) : 'N/A'}
            </Typography>
          </>
        )}
      </Paper>
    </Grid>
  );
}
