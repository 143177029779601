import moment from 'moment';
import {CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Line, LineChart} from 'recharts';
import {Checkbox, FormControlLabel, LinearProgress, Paper, Typography, Grid} from '@mui/material';
// components
import {CustomAlert} from '../../../Login/components/CustomAlert';

const colors = ['#4dc2c2', '#cf2727', '#a337cb', '#19c45d', '#e4ff00', '#ff5100'];

function AsinKeywordsChart({data, /* metric, onMetricChange, */ loading}) {
  const colorsWithKeyword = {};
  let i = 0;
  data?.data.forEach((item) => {
    if (!colorsWithKeyword.hasOwnProperty(item.keyword)) {
      colorsWithKeyword[item.keyword] = colors[i];
      i++;
    }
  });

  function formatXAxis(tickItem) {
    switch (data.interval) {
      case 'day':
        return moment.utc(tickItem).format('YYYY-MM-DD');
      default:
        return moment.utc(tickItem).format('YYYY-MM-DD HH:mm');
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item lg={12} style={{width: '100%'}}>
        {
          /* metric?.length && */ data?.data.some((x) => x.data?.length) ? (
            <ResponsiveContainer height={450} width={'100%'}>
              <LineChart>
                <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                <XAxis
                  type={'number'}
                  allowDuplicatedCategory={false}
                  allowDataOverflow={true}
                  domain={['dataMin', 'dataMax']}
                  dataKey={(x) => moment.utc(x.f1).valueOf()}
                  tickFormatter={formatXAxis}
                  minTickGap={100}
                />
                <Tooltip
                  labelFormatter={(label) =>
                    data.interval === 'day'
                      ? moment.utc(label).format('YYYY-MM-DD')
                      : moment.utc(label).format('YYYY-MM-DD HH:mm')
                  }
                />
                {data.data.map((x, i) => {
                  return (
                    <Line
                      key={i}
                      data={x.data}
                      type="monotone"
                      dataKey="f2"
                      name={x.keyword}
                      stroke={colorsWithKeyword[x.keyword]}
                      label={x.keyword}
                      strokeWidth={4}
                      dot={false}
                      yAxisId={x.keyword}
                      strokeDasharray={x.type === 'previous' ? '5 5' : ''}
                      connectNulls
                    />
                  );
                })}
                {data.data.map((x, i) => {
                  return (
                    <YAxis
                      key={`axis_${i}`}
                      domain={['auto', 'auto']}
                      hide={true}
                      axisLine={false}
                      yAxisId={x.keyword}
                      type={'number'}
                    />
                  );
                })}
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Paper
              variant={'outlined'}
              style={{
                width: '100%',
                boxShadow: '0px 0px 6px 0px #94949433',
                height: '300px',
                textAlign: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Typography color={'textSecondary'} variant={'h4'}>
                No data
              </Typography>
            </Paper>
          )
        }
        {loading ? <LinearProgress color={'primary'} /> : <></>}
        <div style={{textAlign: 'center'}}>
          {Object.keys(colorsWithKeyword).map((x, i) => (
            <FormControlLabel
              key={i}
              control={<Checkbox style={{color: colorsWithKeyword[x]}} checked={true} disabled={loading} />}
              label={x}
            ></FormControlLabel>
          ))}
        </div>
        <CustomAlert id="performance-chart" />
      </Grid>
    </Grid>
  );
}

export {AsinKeywordsChart};
