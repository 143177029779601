import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import moment from 'moment';
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Theme,
} from '@mui/material';
import _ from 'lodash';
import {makeStyles} from '@mui/styles';
import {useLocation} from 'react-router-dom';
import {DataGrid, GridCellParams, GridColDef, GridSortDirection} from '@mui/x-data-grid';
// services
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import DateRangeButtons from '../../components/DateRangeButtons/DateRangeButtons';
import {CustomAlert} from '../Login/components/CustomAlert';
import {CommissionChart} from './components/CommissionChart';
// utils
import {getDefaultQuery, updateQueryParams} from '../../utils/urlParams';
import {onFromToChange} from '../../utils/OnFromToChange';
import {Helper} from '../../utils/helper';
import {MenuStore} from '../../App';
import {Api, errorAlert} from '../../utils/api';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: '10px',
  },
  grid: {
    display: 'flex',
    flexDirection: 'column-reverse',
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgb(23 69 130 / 8%)',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  green: {
    color: '#4caf50',
  },
  red: {
    color: '#f44336',
  },
  formGroup: {
    '&.MuiGrid-root': {
      width: '100%',
      paddingTop: '30px',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& > div:first-of-type': {
        padding: '20px 0',
        display: 'flex',
        width: '60%',
        '& > div': {
          width: '50%',
          maxWidth: '300px',
          '&:first-of-type': {
            paddingRight: '10px',
          },
        },
      },
      '& > div:last-of-type': {
        width: '100%',
        maxWidth: '300px',
      },
      [theme.breakpoints.down(1024)]: {
        '& > div:first-of-type': {
          width: '70%',
          paddingRight: '10px',
        },
        '& > div:last-of-type': {
          maxWidth: '200px',
        },
      },
      [theme.breakpoints.down(767)]: {
        paddingTop: 0,
        '& > div:first-of-type': {
          justifyContent: 'space-between',
          width: '100%',
          paddingRight: '0',
          '& > .MuiAutocomplete-root': {
            maxWidth: '100%',
            paddingRight: '0',
          },
        },
        '& > div:last-of-type': {
          maxWidth: '100%',
        },
      },
      [theme.breakpoints.down(520)]: {
        '& > div:first-of-type': {
          padding: '10px 0',
          width: '100%',
          flexDirection: 'column',
          '& > .MuiAutocomplete-root': {
            width: '100%',
            '&:first-of-type': {
              padding: '10px 0',
            },
          },
        },
      },
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      '&.MuiButtonGroup-root .MuiButton-root:first-of-type ': {
        borderRadius: '0',
        borderTopLeftRadius: '3px',
      },
      '&.MuiButtonGroup-root .MuiButton-root': {
        border: '1px solid #174580',
        borderBottomColor: 'transparent',
      },
      '& .MuiButton-root:nth-of-type(3n)': {
        borderLeftColor: 'transparent',
      },
      '& .MuiButton-root:nth-of-type(3)': {
        borderTopRightRadius: '3px',
      },
      '& .MuiButton-root:nth-of-type(4n)': {
        borderRightColor: 'transparent',
        '&:hover': {
          borderRightColor: 'transparent',
        },
      },
      '& .MuiButton-root:last-of-type': {
        gridArea: '4 / 1 / 4/ 4',
        borderBottomColor: theme.palette.primary.main,
        borderRadius: '0',
        borderBottomRightRadius: '3px',
        borderBottomLeftRadius: '3px',
      },
      '& .MuiButton-outlined': {
        border: 'none',
        '&:last-of-type': {
          backgroundColor: '#e9e9e9',
        },
      },
      '& .MuiButton-containedPrimary': {
        border: 'none',
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          outline: 'none',
        },
      },
    },
  },
}));

function Commissions() {
  document.title = 'Stonehenge.ai/Commissions';
  const columns: GridColDef[] = [
    {
      field: 'brand_code',
      headerName: 'Brand',
      width: 250,
      renderCell: (x) => <Link to={`/commissions-detail?brand=${x.value}`}>{x.value}</Link>,
    },
    {field: 'category', headerName: 'Category', width: 200},
    {field: 'account_manager', headerName: 'Account manager', width: 180},
    {
      field: 'value',
      align: 'right',
      headerName: 'Commission',
      width: 220,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'predicted',
      align: 'right',
      headerName: 'Forecast',
      width: 220,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
    },
  ];

  const categoryColumns: GridColDef[] = [
    {field: 'category', headerName: 'Category', flex: 0.2},
    {
      field: 'value',
      align: 'right',
      headerName: 'Commission',
      width: 200,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
      sortComparator: sortCompare,
    },
    {
      field: 'predicted',
      align: 'right',
      headerName: 'Predicted',
      width: 180,
      filterable: false,
      renderCell: (x) => renderAmountCell(x),
    },
  ];

  function sortCompare(v1, v2, cellParams1, cellParams2) {
    const value1 = cellParams1.value != null ? Math.round(parseFloat(cellParams1.value.toString()) * 100) / 100 : null;
    const value2 = cellParams2.value != null ? Math.round(parseFloat(cellParams2.value.toString()) * 100) / 100 : null;
    if (!compare) {
      return value1 - value2;
    }
    const oldValue1 =
      'old_' + cellParams1.field in cellParams1.row && cellParams1.row['old_' + cellParams1.field] != null
        ? Math.round(parseFloat(cellParams1.row['old_' + cellParams1.field].toString()) * 100) / 100
        : null;
    const percentage1 =
      cellParams1.value !== null &&
      cellParams1.row['old_' + cellParams1.field] != null &&
      cellParams1.row['old_' + cellParams1.field] !== 0
        ? ((value1 - oldValue1) * 100) / oldValue1
        : null;
    const oldValue2 =
      'old_' + cellParams2.field in cellParams2.row && cellParams2.row['old_' + cellParams2.field]
        ? Math.round(parseFloat(cellParams2.row['old_' + cellParams2.field].toString()) * 100) / 100
        : null;
    const percentage2 =
      cellParams2.value !== null &&
      cellParams2.row['old_' + cellParams2.field] != null &&
      cellParams2.row['old_' + cellParams2.field] !== 0
        ? ((value2 - oldValue2) * 100) / oldValue2
        : null;
    if (percentage1 === null) {
      return -1;
    }
    if (percentage2 === null) {
      return 1;
    }
    return percentage1 - percentage2;
  }

  function renderAmountCell(x: GridCellParams) {
    let value = x.value != null ? Math.round(parseFloat(x.value.toString()) * 100) / 100 : null;
    let oldValue = null;
    let percentage = null;
    if ('old_' + x.field in x.row) {
      oldValue =
        x.row['old_' + x.field] != null
          ? Math.round(parseFloat(x.row['old_' + x.field].toString()) * 100) / 100
          : 'N/A';
      if (value !== null && x.row['old_' + x.field] != null && x.row['old_' + x.field] !== 0)
        percentage = (((value - oldValue) * 100) / oldValue).toFixed(2);
    }
    return (
      <div>
        <Typography color={'primary'}>{x.value != null ? Helper.formatAmount(value) : 'N/A'}</Typography>
        {'old_' + x.field in x.row ? <Typography color={'textSecondary'}>{oldValue}</Typography> : ''}
        {percentage ? (
          <Typography className={percentage >= 0 ? classes.green : classes.red} color={'primary'}>
            {percentage < 0 ? '' : '+'}
            {percentage}%
          </Typography>
        ) : (
          ''
        )}
      </div>
    );
  }
  const classes = useStyles();

  const location = useLocation();
  const [fromTo, setFromTo] = useState({from: null, to: null, compareFrom: null, compareTo: null});
  const [metric, setMetric] = useState([]);
  const [period, setPeriod] = useState(null);
  const [groupBy, setGroupBy] = useState('brand_code');
  const [groupRows, setGroupRows] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const [skuRows, setSkuRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filter, setFilter] = useState(null);
  const [metricData, setMetricData] = useState(null);
  const [filterData, setFilterData] = useState(null);

  const [loadingSku, setLoadingSku] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [compare, setCompare] = useState(false);

  const params = {
    metric: metric?.length !== skuRows.length ? metric.toString() : null,
    from: fromTo.from,
    to: fromTo.to,
    compareFrom: fromTo.compareFrom,
    compareTo: fromTo.compareTo,
    filterBrands: filter?.brands?.toString(),
    filterCategories: filter?.categories?.toString(),
    groupBy: groupBy !== 'brand_code' ? groupBy : null,
    sortBy: sortBy,
    sortDirection: sortDirection,
  };

  useEffect(() => {
    let toValue = moment(new Date());
    const defaultQuery = getDefaultQuery(location, params, {
      from: moment(toValue).subtract(5, 'months').startOf('month').format('YYYY-MM-DD'),
      to: moment(toValue).endOf('month').format('YYYY-MM-DD'),
      preset: '6m',
    });

    setMetric(
      defaultQuery.metric && defaultQuery.metric.length > 0 ? defaultQuery.metric?.split(',') : ['commissions']
    );
    loadSkus(
      defaultQuery.from,
      defaultQuery.to,
      defaultQuery.compareFrom,
      defaultQuery.compareTo,
      defaultQuery.metric,
      {brands: defaultQuery.filterBrands, categories: defaultQuery.filterCategories},
      defaultQuery.groupBy
    );
    setFromTo({
      from: defaultQuery.from,
      to: defaultQuery.to,
      compareFrom: defaultQuery.compareFrom,
      compareTo: defaultQuery.compareTo,
    });
    setPeriod(defaultQuery.preset);
    setCompare(defaultQuery.compareFrom != null);
    if (defaultQuery.groupBy) setGroupBy(defaultQuery.groupBy);
    if (defaultQuery.sortBy && defaultQuery.sortDirection) {
      setSortBy(defaultQuery.sortBy);
      setSortDirection(defaultQuery.sortDirection);
    }
    setFilterData(defaultQuery);
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, []);

  useEffect(() => {
    updateQueryParams(location, params);
  }, [fromTo, skuRows, period, metric, filter, groupBy, sortBy, sortDirection]);

  async function loadSkus(from, to, compareFrom, compareTo, metric, filter, groupBy) {
    setLoadingSku(true);
    try {
      const {data: brandData} = await Api.get(
        `brand-commission/all?from=${from}&to=${to}${compareFrom ? `&compareFrom=${compareFrom}` : ''}${
          compareTo ? `&compareTo=${compareTo}` : ''
        }`
      );
      const data = brandData.map((x, i) => {
        return {id: x.brand_code, ...x, category: x.category ? x.category?.split(' , ') : []};
      });
      setSkuRows(data);
      setFilteredRows(data);
      onFilterChange(filter, data, from, to, compareFrom, compareTo, metric, groupBy);
    } catch (e) {
      errorAlert('Unable to get Commissions', e, {id: 'commissions'});
    } finally {
      setLoadingSku(false);
    }
  }

  async function loadMetric(selected, from, to, compareFrom, compareTo) {
    setLoadingChart(true);
    try {
      const {data} = await Api.post(`brand-commission/metric`, {
        brands: selected,
        from: from,
        to: to,
        compareFrom: compareFrom,
        compareTo: compareTo,
      });
      setLoadingChart(false);
      setMetricData({data: data});
    } catch (e) {
      errorAlert('Unable to get metric data', e, {id: 'commission-chart'});
    } finally {
      setLoadingChart(false);
    }
  }

  function toggleCompare(checked) {
    setCompare(checked);
    onFromToChange({
      from: fromTo.from,
      to: fromTo.to,
      cFrom: null,
      cTo: null,
      preset: period,
      compare: checked,
      setFromTo: setFromTo,
    });
  }

  function onFilterChange(filter, data, from, to, compareFrom, compareTo, metric, groupBy) {
    let result = data;
    if (filter?.brands && filter.brands.length) {
      result = result.filter((x) => filter.brands.includes(x.brand_code));
    }
    if (filter?.categories && filter.categories.length) {
      result = result.filter((x) => x.category.some((y) => filter.categories.includes(y)));
    }
    setFilter(filter);
    setFilteredRows(result);
    if (groupBy !== 'brand_code') {
      calculateGroupBy(result, groupBy, compareFrom && compareTo, filter);
    }
    const selected = result.map((x) => x.brand_code);
    if (selected.length > 0) {
      loadMetric(selected, from, to, compareFrom, compareTo);
    } else {
      setMetricData(null);
    }

    setFilterData((prev) => {
      return {
        ...prev,
        filterBrands: filter.brands,
        filterCategories: filter.categories,
      };
    });
  }
  function calculateGroupBy(rows, groupBy, compare, filter) {
    setGroupRows(
      _.map(_.uniq(_.flatMap(rows, 'category')), (idx) => {
        const filteredRows = _.filter(rows, (y) => y.category.includes(idx));
        const result = compare
          ? {
              id: idx,
              value: _.sumBy(filteredRows, 'value'),
              old_value: _.sumBy(filteredRows, 'old_value'),
              predicted: _.sumBy(filteredRows, 'predicted'),
              old_predicted: _.sumBy(filteredRows, 'old_predicted'),
            }
          : {
              id: idx,
              value: _.sumBy(filteredRows, 'value'),
              predicted: _.sumBy(filteredRows, 'predicted'),
            };
        switch (groupBy) {
          default:
            return {
              category: idx,
              ...result,
            };
        }
      })
        .filter((x) => x.id !== 'null' && x.id !== '')
        .filter((x) => (filter?.categories?.length ? filter.categories.includes(x.id) : true))
    );
  }

  function onGroupBy(e) {
    setGroupBy(e.target.value);
    setSortBy(null);
    setSortDirection('asc');
    calculateGroupBy(filteredRows, e.target.value, compare, filter);
  }

  function onFilterData() {
    loadSkus(fromTo.from, fromTo.to, fromTo.compareFrom, fromTo.compareTo, metric, filter, groupBy);
  }

  return (
    <TableContainer>
      <DateRangeButtons
        fromTo={fromTo}
        compare={compare}
        period={period}
        setPeriod={(v) => setPeriod(v)}
        setFromTo={(v) => setFromTo(v)}
        compareOnChange={(e) => toggleCompare(e.target.checked)}
        compareComponent
        loadData={(preset, from, to, compareFrom, compareTo) => {
          const queryParams = {
            ...filterData,
            from: from,
            to: to,
            compareFrom: compareFrom,
            compareTo: compareTo,
            metric: metric,
          };
          setFilterData(queryParams);
        }}
        showButton
        buttonOnClick={() => onFilterData()}
        pageTitle="Commissions"
      />
      <CommissionChart data={metricData} loading={loadingChart} />
      <Grid container className={classes.formGroup}>
        <div>
          <Autocomplete
            size="small"
            multiple
            options={Array.from(
              new Set(
                skuRows
                  .map((x) => x.brand_code)
                  .filter((x) => x !== null)
                  .sort()
              )
            )}
            getOptionLabel={(option) => {
              const brandName = skuRows.find((x) => x.brand_code === option)?.name;
              return brandName ? `${option} -  ${brandName}` : option;
            }}
            value={filter?.brands ? filter.brands : []}
            onChange={(event: object, value: any) => {
              onFilterChange(
                {...filter, brands: value},
                skuRows,
                fromTo.from,
                fromTo.to,
                fromTo.compareFrom,
                fromTo.compareTo,
                metric,
                groupBy
              );
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Filter by brands" placeholder="Brands" />
            )}
          />
          <Autocomplete
            size="small"
            multiple
            options={Array.from(
              new Set(
                [].concat
                  .apply(
                    [],
                    skuRows.map((x) => x.category)
                  )
                  .filter((x) => x !== null)
              )
            )}
            getOptionLabel={(option) => option}
            value={filter?.categories ? filter.categories : []}
            onChange={(event: object, value: any) => {
              onFilterChange(
                {...filter, categories: value},
                skuRows,
                fromTo.from,
                fromTo.to,
                fromTo.compareFrom,
                fromTo.compareTo,
                metric,
                groupBy
              );
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Filter by categories" placeholder="Category" />
            )}
          />
        </div>
        <FormControl variant="outlined" style={{width: '100%'}} size="small">
          <InputLabel id="demo-simple-select-outlined-label">Group by</InputLabel>
          <Select
            value={groupBy ? groupBy : 'brand_code'}
            onChange={onGroupBy}
            label={'Group by'}
            labelId="demo-simple-select-outlined-label"
            variant="outlined"
          >
            <MenuItem value={'brand_code'}>Brand</MenuItem>
            <MenuItem value={'category'}>Category</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={12} style={{width: '100%', paddingTop: 30}}>
        <CustomAlert id="commissions" />
        <DataGrid
          className={classes.grid}
          autoHeight={true}
          rows={groupBy === 'brand_code' ? filteredRows : groupRows}
          disableSelectionOnClick={true}
          columns={groupBy === 'brand_code' ? columns : categoryColumns}
          loading={loadingSku}
          disableColumnFilter
          rowHeight={72}
          pagination
          pageSize={40}
          onSortModelChange={(x: any) => {
            setSortBy(x[0]?.field);
            setSortDirection(x[0]?.sort);
          }}
          sortModel={
            sortBy && sortDirection
              ? [
                  {
                    field: sortBy,
                    sort: sortDirection as GridSortDirection,
                  },
                ]
              : []
          }
        />
      </Grid>
    </TableContainer>
  );
}

export {Commissions};
