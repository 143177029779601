import React, {useEffect, useState} from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Typography, Chip, Grid} from '@mui/material';
import {useHistory, useParams} from 'react-router-dom';
import {ExpandMore} from '@mui/icons-material';
import {DataGrid, GridCellParams, GridColDef} from '@mui/x-data-grid';
import moment from 'moment';
// components
import {MenuStore} from '../../../../App';
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {BuildMenu} from '../../components/menu';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// utils
import {Api, errorAlert} from '../../../../utils/api';

const sellerContactCols: GridColDef[] = [
  {
    field: 'averageResponseTimeInHours',
    headerName: 'Avg. Response (Hours)',
    flex: 0.15,
    sortable: false,
    filterable: false,
  },
  {
    field: 'responseUnder24Hours',
    headerName: 'Responses under 24h',
    flex: 0.2,
    sortable: false,
    filterable: false,
  },
  {
    field: 'noResponseForContactsOlderThan24Hours',
    headerName: 'Responses to contacts older than 24h',
    flex: 0.2,
    sortable: false,
    filterable: false,
  },
  {
    field: 'responseTimeGreaterThan24Hours',
    headerName: 'Responses older than 24h',
    flex: 0.2,
    sortable: false,
    filterable: false,
  },
  {
    field: 'timeFrame',
    headerName: 'Time Period',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">
          {moment(params.row.timeFrame.start).utc().format('M/d/y HH:mm')} -{' '}
          {moment(params.row.timeFrame.end).utc().format('M/d/y HH:mm')}
        </Typography>
      </>
    ),
  },
];

const customerExperienceCols: GridColDef[] = [
  {
    field: 'lateShipment',
    headerName: 'Late Shipments (%)',
    flex: 0.15,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.lateShipment.rate}</Typography>
      </>
    ),
  },
  {
    field: 'preFulfillmentCancellation',
    headerName: 'Pre-fulfillments Cancellations (%)',
    flex: 0.2,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.preFulfillmentCancellation.rate}</Typography>
      </>
    ),
  },
  {
    field: 'refunds',
    headerName: 'Refunds (%)',
    flex: 0.2,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.refunds.rate}</Typography>
      </>
    ),
  },
  {
    field: 'orderCount',
    headerName: 'Total orders',
    flex: 0.2,
    sortable: false,
    filterable: false,
  },
  {
    field: 'timeFrame',
    headerName: 'Time Period',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">
          {moment(params.row.timeFrame.start).utc().format('M/d/y HH:mm')} -{' '}
          {moment(params.row.timeFrame.end).utc().format('M/d/y HH:mm')}
        </Typography>
      </>
    ),
  },
];

const customerServiceCols: GridColDef[] = [
  {
    field: 'customerServiceDissatisfaction',
    headerName: 'Dissatisfaction (%)',
    flex: 0.15,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.customerServiceDissatisfaction.rate}</Typography>
      </>
    ),
  },

  {
    field: 'timeFrame',
    headerName: 'Time Period',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">
          {moment(params.row.timeFrame.start).utc().format('M/d/y HH:mm')} -{' '}
          {moment(params.row.timeFrame.end).utc().format('M/d/y HH:mm')}
        </Typography>
      </>
    ),
  },
];

const orderDefectCols: GridColDef[] = [
  {
    field: 'a_z_claims',
    headerName: 'AZ Claims (%)',
    flex: 0.15,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.a_z_claims.rate}</Typography>
      </>
    ),
  },
  {
    field: 'chargebacks',
    headerName: 'Charge Backs (%)',
    flex: 0.15,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.chargebacks.rate}</Typography>
      </>
    ),
  },
  {
    field: 'orderWithDefects',
    headerName: 'Orders with defects (%)',
    flex: 0.15,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.orderWithDefects.rate}</Typography>
      </>
    ),
  },
  {
    field: 'negativeFeedbacks',
    headerName: 'Negative feedback (%)',
    flex: 0.15,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.negativeFeedbacks.rate}</Typography>
      </>
    ),
  },
  {
    field: 'orderCount',
    headerName: 'Total orders',
    flex: 0.2,
    sortable: false,
    filterable: false,
  },
  {
    field: 'timeFrame',
    headerName: 'Time Period',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">
          {moment(params.row.timeFrame.start).utc().format('M/d/y HH:mm')} -{' '}
          {moment(params.row.timeFrame.end).utc().format('M/d/y HH:mm')}
        </Typography>
      </>
    ),
  },
];

const returnDissatisfactionCols: GridColDef[] = [
  {
    field: 'invalidRejection',
    headerName: 'Invalid Rejections (%)',
    flex: 0.15,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.invalidRejection.rate}</Typography>
      </>
    ),
  },
  {
    field: 'returnDissatisfaction',
    headerName: 'Return Dissatisfactions (%)',
    flex: 0.15,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.returnDissatisfaction.rate}</Typography>
      </>
    ),
  },
  {
    field: 'negativeReturnFeedback',
    headerName: 'Negative Feedback (%)',
    flex: 0.15,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.negativeReturnFeedback.rate}</Typography>
      </>
    ),
  },
  {
    field: 'lateResponse',
    headerName: 'Late Responses (%)',
    flex: 0.15,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.lateResponse.rate}</Typography>
      </>
    ),
  },
  {
    field: 'returnOrderCount',
    headerName: 'Total orders',
    flex: 0.2,
    sortable: false,
    filterable: false,
  },
  {
    field: 'timeFrame',
    headerName: 'Time Period',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">
          {moment(params.row.timeFrame.start).utc().format('M/d/y HH:mm')} -{' '}
          {moment(params.row.timeFrame.end).utc().format('M/d/y HH:mm')}
        </Typography>
      </>
    ),
  },
];

const trackingInformationCols: GridColDef[] = [
  {
    field: 'validTracking',
    headerName: 'Valid Tracking (%)',
    flex: 0.15,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.validTracking.rate}</Typography>
      </>
    ),
  },
  {
    field: 'onTimeDelivery',
    headerName: 'On Time Delivery (%)',
    flex: 0.15,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">{params.row.onTimeDelivery.rate}</Typography>
      </>
    ),
  },
  {
    field: 'requiredCategories',
    headerName: 'Required Categories',
    flex: 0.15,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        {params.row.requiredCategories === '' ? (
          <></>
        ) : (
          params.row.requiredCategories.split(',').map((cat) => <Typography variant="subtitle1">{cat}</Typography>)
        )}
      </>
    ),
  },
  {
    field: 'timeFrame',
    headerName: 'Time Period',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="subtitle1">
          {moment(params.row.timeFrame.start).utc().format('M/d/y HH:mm')} -{' '}
          {moment(params.row.timeFrame.end).utc().format('M/d/y HH:mm')}
        </Typography>
      </>
    ),
  },
];

function StatusChip(props: any) {
  return (
    <Chip
      size="small"
      label={props.label}
      style={{backgroundColor: props.label === 'Good' ? 'green' : 'red', color: 'white'}}
    />
  );
}

function BrandPerformance(props) {
  const firstMonth = '2021-04-01';
  let history = useHistory();
  const [sellerContactRows, setSellerContactRows] = useState([]);
  const [customerExperienceRows, setCustomerExperienceRows] = useState([]);
  const [customerServiceRows, setCustomerServiceRows] = useState([]);
  const [orderDefectRows, setOrderDefectRows] = useState([]);
  const [returnDissatisfactionRows, setReturnDissatisfactionRows] = useState([]);
  const [trackingInformationRows, setTrackingInformationRows] = useState([]);
  const [intellectualProperty, setIntellectualProperty] = useState<any>();
  const [listingPolicy, setListingPolicy] = useState<any>();
  const [productAuthenticity, setProductAuthenticity] = useState<any>();
  const [productSafety, setProductSafety] = useState<any>();
  const [performanceCheckList, setPerformanceCheckList] = useState<any>();

  const {id} = useParams<Record<string, string | undefined>>();

  const menuItems = BuildMenu('performance', id, history);

  var dateStart = moment(new Date(firstMonth));
  var dateEnd = moment(new Date());
  var timeValues = [];

  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    timeValues.push(dateStart.format('YYYY-MM'));
    dateStart.add(1, 'month');
  }

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = menuItems;
    });
    loadData();
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, []);

  async function loadData() {
    try {
      const {data} = await Api.get(`brand-performance/${id}`);
      setSellerContactRows(
        data.buyer_seller_contact_response_time_metrics.responseTimeMetrics.map((metrics, index) => {
          return {
            id: index,
            averageResponseTimeInHours: metrics.averageResponseTimeInHours,
            responseUnder24Hours: metrics.responseUnder24Hours,
            noResponseForContactsOlderThan24Hours: metrics.noResponseForContactsOlderThan24Hours,
            responseTimeGreaterThan24Hours: metrics.responseTimeGreaterThan24Hours,
            timeFrame: metrics.timeFrame,
          };
        })
      );
      setCustomerExperienceRows(
        data.customer_experience.customerExperienceMetrics.map((metrics, index) => {
          return {
            id: index,
            lateShipment: metrics.lateShipment,
            preFulfillmentCancellation: metrics.preFulfillmentCancellation,
            refunds: metrics.refunds,
            timeFrame: metrics.timeFrame,
            orderCount: metrics.orderCount,
          };
        })
      );
      setCustomerServiceRows(
        data.customer_service.customerServiceMetrics.map((metrics, index) => {
          return {
            id: index,
            customerServiceDissatisfaction: metrics.customerServiceDissatisfaction,
            timeFrame: metrics.timeFrame,
          };
        })
      );
      setOrderDefectRows(
        data.order_defects.orderDefectMetrics.map((metrics, index) => {
          return {
            id: index,
            a_z_claims: metrics.a_z_claims,
            orderCount: metrics.orderCount,
            chargebacks: metrics.chargebacks,
            orderWithDefects: metrics.orderWithDefects,
            negativeFeedbacks: metrics.negativeFeedbacks,
            timeFrame: metrics.timeFrame,
          };
        })
      );
      setReturnDissatisfactionRows(
        data.return_dissatisfaction.returnDissatisfactionMetrics.map((metrics, index) => {
          return {
            id: index,
            lateResponse: metrics.lateResponse,
            invalidRejection: metrics.invalidRejection,
            returnOrderCount: metrics.returnOrderCount,
            returnDissatisfaction: metrics.returnDissatisfaction,
            negativeReturnFeedback: metrics.negativeReturnFeedback,
            timeFrame: metrics.timeFrame,
          };
        })
      );
      setTrackingInformationRows(
        data.tracking_information.trackingMetrics.map((metrics, index) => {
          return {
            id: index,
            validTracking: metrics.validTracking,
            onTimeDelivery: metrics.onTimeDelivery,
            requiredCategories: metrics.requiredCategories,
            timeFrame: metrics.timeFrame,
          };
        })
      );
      setIntellectualProperty(data.intellectual_property_data);
      setListingPolicy(data.listing_policy_data);
      setProductAuthenticity(data.product_authenticity_data);
      setProductSafety(data.product_safety_data);
      setPerformanceCheckList(data.performance_checklist);
    } catch (e) {
      errorAlert('Unable to get brand performance', e);
    }
  }

  return (
    <TableContainer className="sub-page">
      <Grid item xs={12} style={{paddingBottom: 30}}>
        <Grid container spacing={1}>
          <Grid item lg={12} style={{width: '100%'}}>
            {/* Contact response */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <Typography component="h2" variant="subtitle1" gutterBottom>
                  Contact Response
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DataGrid
                  rows={sellerContactRows}
                  columns={sellerContactCols}
                  autoHeight={true}
                  hideFooterPagination={true}
                  hideFooter={true}
                  disableSelectionOnClick={true}
                />
              </AccordionDetails>
            </Accordion>
            {/* Customer Experience */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <Typography component="h2" variant="subtitle1" gutterBottom>
                  Customer Experience
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DataGrid
                  rows={customerExperienceRows}
                  columns={customerExperienceCols}
                  autoHeight={true}
                  hideFooterPagination={true}
                  hideFooter={true}
                  disableSelectionOnClick={true}
                />
              </AccordionDetails>
            </Accordion>
            {/* Customer Service */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <Typography component="h2" variant="subtitle1" gutterBottom>
                  Customer Service
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DataGrid
                  rows={customerServiceRows}
                  columns={customerServiceCols}
                  autoHeight={true}
                  hideFooterPagination={true}
                  hideFooter={true}
                  disableSelectionOnClick={true}
                />
              </AccordionDetails>
            </Accordion>
            {/* Intellectual Property Data */}
            {intellectualProperty && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  <Typography component="h2" variant="subtitle1" gutterBottom>
                    Intellectual Property Data
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Defects found: {intellectualProperty.defectCount}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" variant="subtitle1" gutterBottom>
                        Between the dates {moment(intellectualProperty.timeFrame.start).utc().format('M/d/y')} -{' '}
                        {moment(intellectualProperty.timeFrame.end).utc().format('M/d/y')}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {/* Listing Policy */}
            {listingPolicy && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  <Typography component="h2" variant="subtitle1" gutterBottom>
                    Listing Policy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Defects found: {listingPolicy.defectCount}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" variant="subtitle1" gutterBottom>
                        Between the dates {moment(listingPolicy.timeFrame.start).utc().format('M/d/y')} -{' '}
                        {moment(listingPolicy.timeFrame.end).utc().format('M/d/y')}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {/* Order Defects */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <Typography component="h2" variant="subtitle1" gutterBottom>
                  Order Defects
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DataGrid
                  rows={orderDefectRows}
                  columns={orderDefectCols}
                  autoHeight={true}
                  hideFooterPagination={true}
                  hideFooter={true}
                  disableSelectionOnClick={true}
                />
              </AccordionDetails>
            </Accordion>
            {/* Performance Checklist */}
            {performanceCheckList && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  <Typography component="h2" variant="subtitle1" gutterBottom>
                    Performance Checklist
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        On Time Delivery: <StatusChip label={performanceCheckList.onTimeDelivery.status} />
                      </Typography>
                    </Grid>

                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Policy Violation: <StatusChip label={performanceCheckList.policyViolation.status} />
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Contact Response Time: <StatusChip label={performanceCheckList.contactResponseTime.status} />
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Listing Policy Status: <StatusChip label={performanceCheckList.listingPolicyStatus.status} />
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Product Safety Status: <StatusChip label={performanceCheckList.productSafetyStatus.status} />
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Product Authenticity Status:{' '}
                        <StatusChip label={performanceCheckList.productAuthenticityStatus.status} />
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Intellectual Property Status:{' '}
                        <StatusChip label={performanceCheckList.intellectualPropertyStatus.status} />
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Late Shipment Rate: <StatusChip label={performanceCheckList.lateShipmentRate.status} />
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Valid Tracking Rate: <StatusChip label={performanceCheckList.validTrackingRate.status} />
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Order Defect Rate: <StatusChip label={performanceCheckList.orderDefectRate.status} />
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Order Cacellation Rate: <StatusChip label={performanceCheckList.orderCancellationRate.status} />
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Return Dissatisfaction Rate:{' '}
                        <StatusChip label={performanceCheckList.returnDissatisfactionRate.status} />
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Customer Service Dissatisfaction Rate:{' '}
                        <StatusChip label={performanceCheckList.customerServiceDissatisfactionRate.status} />
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {/* Product Authenticity */}
            {productAuthenticity && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  <Typography component="h2" variant="subtitle1" gutterBottom>
                    Product Authenticity
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Defects found: {productAuthenticity.defectCount}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" variant="subtitle1" gutterBottom>
                        Between the dates {moment(productAuthenticity.timeFrame.start).utc().format('M/d/y')} -{' '}
                        {moment(productAuthenticity.timeFrame.end).utc().format('M/d/y')}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {/* Product Safety */}
            {productSafety && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  <Typography component="h2" variant="subtitle1" gutterBottom>
                    Product Safety
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" gutterBottom>
                        Defects found: {productSafety.defectCount}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} style={{width: '100%'}}>
                      <Typography component="h2" variant="subtitle1" gutterBottom>
                        Between the dates {moment(productSafety.timeFrame.start).utc().format('M/d/y')} -{' '}
                        {moment(productSafety.timeFrame.end).utc().format('M/d/y')}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {/* Return Dissatisfaction */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <Typography component="h2" variant="subtitle1" gutterBottom>
                  Return Dissatisfaction
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DataGrid
                  rows={returnDissatisfactionRows}
                  columns={returnDissatisfactionCols}
                  autoHeight={true}
                  hideFooterPagination={true}
                  hideFooter={true}
                  disableSelectionOnClick={true}
                />
              </AccordionDetails>
            </Accordion>
            {/* Tracking Information */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <Typography component="h2" variant="subtitle1" gutterBottom>
                  Tracking Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DataGrid
                  rows={trackingInformationRows}
                  columns={trackingInformationCols}
                  autoHeight={true}
                  hideFooterPagination={true}
                  hideFooter={true}
                  disableSelectionOnClick={true}
                />
              </AccordionDetails>
            </Accordion>
            <CustomAlert id="default-alert" />
          </Grid>
        </Grid>
      </Grid>
    </TableContainer>
  );
}

export {BrandPerformance};
