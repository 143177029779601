import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Grid, Skeleton, Typography} from '@mui/material';
import {Formik} from 'formik';
// utils
import {Api, errorAlert} from '../../../../utils/api';
import {getArticle} from '../../../../utils/FFPBeacon';
import {BrandTypesAlias} from '../../../../utils/brand-types';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import Button from '../../../../components/Button/Button';
import {AuthorizeSpApiForFFPBrand} from './components/AuthorizeSpApiForFFPBrand';
import {AuthorizeAdvertisingForFFPBrand} from './components/AuthorizeAdvertisingForFFPBrand';
import Spacer from '../../../../components/Spacer/Spacer';
import {GoogleDrive} from '../../components/GoogleDrive';
import {GoogleDriveTopFolder} from '../../components/GoogleDriveTopFolder';
import {CustomAlert} from '../../../Login/components/CustomAlert';
//services
import mixPanel from '../../../../services/mixpanel';
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';
// styles
import {useStyles} from '../../Styles';

interface FfpSubBrandsProps {
  GAEvents?: (category, action, label?) => void;
}

function FfpSubBrands({GAEvents}: FfpSubBrandsProps) {
  const classes = useStyles();
  document.title = 'Stonehenge.ai | Sub Connectors';

  const [loading, setLoading] = useState(true);
  const [brandInfo, setBrandInfo] = useState(null);
  const [googleDriveFolders, setGoogleDriveFolders] = useState([]);
  const [lastSuccess, setLastSuccess] = useState([]);
  const [googleDriveLoaded, setGoogleDriveLoaded] = useState(false);

  async function getBrandInfo(): Promise<any> {
    try {
      const {data} = await Api.get(`brands/${accountService.userValue.brand_code}`);
      setBrandInfo(data);
      setGoogleDriveFolders(data.google_drive_folders || []);
      setLastSuccess(data.last_success || []);
      setGoogleDriveLoaded(true);
    } catch (e) {
      errorAlert('Unable to get Brand Info', e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getBrandInfo();
  }, []);

  async function onSubmit(data, {setSubmitting}) {
    alertService.clear();
    const fields = {
      google_drive_folders: data.googleDriveFolders,
    };
    try {
      await Api.patch(`brands/${accountService.userValue.brand_code}`, fields);
      alertService.success('Google Drive data updated successfully');
    } catch (e: any) {
      errorAlert(e?.response?.data?.message || 'Unable to update Google Drive data', e);
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) && getArticle({});
  }, [loading]);

  useEffect(() => {
    brandInfo &&
      ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
      mixPanel({
        eventName: 'Account Page - Connectors',
        eventProperties: {connectorsInfo: brandInfo},
      });
  }, [brandInfo]);

  return (
    <TableContainer>
      <Grid container style={{width: '100%'}}>
        <Grid item xs={12} lg={12} style={{textAlign: 'right'}}>
          {brandInfo?.sp_api_authorized_marketplaces.length > 0 && (
            <Link to="/brands/create-sub-brand">
              <Button
                onClick={() => {
                  GAEvents('Click', 'Create Sub Connector');
                }}
              >
                Connect Another Seller Central Account
              </Button>
            </Link>
          )}
          <Spacer height={20} />
        </Grid>
        <Grid container style={{marginBottom: 20}} rowSpacing={1.5} columnSpacing={{xs: 0, md: 1.5, xl: 1.5}}>
          {!loading ? (
            <>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={5} className={classes.spApiCard}>
                <AuthorizeSpApiForFFPBrand
                  editable
                  brandInfo={brandInfo}
                  refreshBrand={getBrandInfo}
                  brandCode={accountService.userValue.brand_code}
                  hideButton={brandInfo?.sp_api_authorized_marketplaces.length > 0}
                  GAEvents={(category, action, label) => {
                    GAEvents(category, action, label);
                  }}
                  advertisingAPI={
                    accountService.userValue.brand_type === 'ecom3k' && (
                      <AuthorizeAdvertisingForFFPBrand
                        brandInfo={brandInfo}
                        refreshBrand={getBrandInfo}
                        brandCode={accountService.userValue.brand_code}
                        hideButton={brandInfo?.sp_api_authorized_marketplaces.length > 0}
                        GAEvents={(category, action, label) => {
                          GAEvents(category, action, label);
                        }}
                      />
                    )
                  }
                />
              </Grid>
              {accountService.userValue.brand_type === BrandTypesAlias.ecom3k && (
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <GoogleDriveTopFolder />
                </Grid>
              )}
            </>
          ) : (
            <Grid xs={12} lg={12} item style={{padding: '42px 0'}}>
              <Typography variant="body1">
                <Skeleton height={30} animation="wave" />
                <Skeleton height={30} animation="wave" />
                <Skeleton height={30} animation="wave" />
                <Skeleton height={30} animation="wave" />
                <Skeleton height={30} animation="wave" />
              </Typography>
            </Grid>
          )}
        </Grid>
        <Spacer height={20} />
        {accountService.userValue.brand_type === 'ecom3k' &&
          brandInfo?.selling_partner_api_access &&
          googleDriveLoaded && (
            <Grid item xs={12} lg={12} style={{marginBottom: '25px'}}>
              <Formik
                initialValues={{
                  googleDriveFolders,
                  lastSuccess,
                }}
                onSubmit={onSubmit}
                validateOnChange={true}
                enableReinitialize={true}
              >
                {({errors, touched, isSubmitting, values: formValues, submitForm, setFieldValue}) => {
                  return (
                    <Grid>
                      <GoogleDrive
                        brandReports={formValues.googleDriveFolders}
                        lastSuccess={formValues.lastSuccess}
                        brandCode={accountService.userValue.brand_code}
                        onSubmit={submitForm}
                        setValues={setFieldValue}
                        keyValue="googleDriveFolders"
                        isSubmitting={isSubmitting}
                      />
                    </Grid>
                  );
                }}
              </Formik>
            </Grid>
          )}
      </Grid>
      <CustomAlert id="default-alert" />
    </TableContainer>
  );
}

export {FfpSubBrands};
