import {useEffect, useState} from 'react';
import {Grid, Typography, List, ListItem, ListItemText, Chip, Tooltip} from '@mui/material';
import {DataGrid, GridColDef, GridCellParams} from '@mui/x-data-grid';
import {Link} from 'react-router-dom';
import {Edit, Delete, Circle} from '@mui/icons-material';
// compoents
import ModalButton from '../../../../../../components/ModalButton/ModalButton';
import {AuthorizeSpApiForFFPBrand} from '../../components/AuthorizeSpApiForFFPBrand';
import {AuthorizeAdvertisingForFFPBrand} from '../../components/AuthorizeAdvertisingForFFPBrand';
import Spacer from '../../../../../../components/Spacer/Spacer';
import Button from '../../../../../../components/Button/Button';
// utils
import getLabel from '../../../../../../utils/getLabel';
import {BrandTypesAlias} from '../../../../../../utils/brand-types';
import {Api, errorAlert} from '../../../../../../utils/api';
import {getSubBrands} from '../../../../../../utils/getSubBrands';
// services
import mixPanel from '../../../../../../services/mixpanel';
import {accountService} from '../../../../../../services/account.service';

interface FfpSubAccountsProps {
  GAEvents?: (category, action, label?) => void;
}

function FfpSubAccounts({GAEvents}: FfpSubAccountsProps) {
  document.title = 'Stonehenge.ai | Sub Accounts';

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const checkListComponent = (data) => {
    return data.map((value, index) => {
      return (
        <Tooltip title={getLabel(value.label)} key={`${value.label}-${index}`}>
          <Circle style={{color: value.value === 'Good' ? 'green' : 'red', fontSize: '12px'}} />
        </Tooltip>
      );
    });
  };

  const columns: GridColDef[] = [
    {field: 'name', headerName: 'Name', flex: 1, minWidth: 240, type: 'string', filterable: false},
    {
      field: 'performance_reports',
      headerName: 'Performance Checklist',
      flex: 1,
      minWidth: 200,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const checklist = params.row.performance_reports?.[0]?.performance_checklist;
        if (checklist && Object.keys(checklist)?.length > 0) {
          const newData = Object.entries(checklist).map(([key, value]: any) => {
            return {
              label: key,
              value: value?.status,
            };
          });
          return (
            <Grid
              style={{cursor: 'pointer'}}
              onClick={() => setSelectedBrand({...params.row, performance_checklist: newData})}
            >
              {checkListComponent(newData)}
            </Grid>
          );
        } else {
          return <Typography variant="body2">N/A</Typography>;
        }
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      align: 'right',
      flex: 1,
      minWidth: 850,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <div style={{display: 'flex'}}>
          <Link to={`/brands/update-sub-brand/${params.row.brand_code}`}>
            <Button
              size="small"
              startIcon={<Edit />}
              onClick={() => {
                GAEvents('Sub Connector', 'Edit', params.row.name);
              }}
            >
              Edit
            </Button>
          </Link>
          <Spacer width={15} />

          <ModalButton
            modalTitle="Delete Sub Connector"
            buttonText="Delete"
            startIcon={<Delete />}
            closable
            onCloseText="No"
            buttonSize="small"
            loading={deleting}
            actions={(closeModal): JSX.Element => {
              return (
                <Button
                  disabled={deleting}
                  onClick={() => {
                    deleteSubBrand(params.row.parent_brand_code, params.row.brand_code, closeModal);
                    ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
                      GAEvents('Sub Connector', 'Delete Sub Connector', `Sub Connector deleted: ${params.row.name}`);
                  }}
                >
                  Yes
                </Button>
              );
            }}
          >
            {(closeModal): JSX.Element => {
              return (
                <span>
                  Are you sure you want to delete Sub Connector: <b>{params.row.name}</b> ?
                </span>
              );
            }}
          </ModalButton>

          <Spacer width={15} />
          <AuthorizeSpApiForFFPBrand
            brandInfo={params.row}
            buttonSize="small"
            brandCode={params.row.brand_code}
            buttonOnly={true}
            GAEvents={(category, action, label) => {
              GAEvents(category, action, label);
            }}
          />
          {accountService.userValue.brand_type === BrandTypesAlias.ecom3k && (
            <>
              <Spacer width={15} />
              <AuthorizeAdvertisingForFFPBrand
                brandInfo={params.row}
                buttonSize="small"
                brandCode={params.row.brand_code}
                buttonOnly={true}
                GAEvents={(category, action, label) => {
                  GAEvents(category, action, label);
                }}
              />
            </>
          )}
        </div>
      ),
    },
  ];

  async function deleteSubBrand(brandCode: string, subBrandCode: string, closeModal: any) {
    setDeleting(true);
    try {
      await Api.delete(`brands/${brandCode}/sub-brands/${subBrandCode}`);
      loadData();
    } catch (e) {
      errorAlert('Unable to delete Sub Connector', e);
    } finally {
      setDeleting(false);
      closeModal();
    }
  }

  async function loadData() {
    const data = await getSubBrands([accountService.userValue.brand_code], setLoading);
    if (data) {
      setRows(
        data.map((x, i) => {
          return {id: i, ...x};
        })
      );
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    rows.length > 0 &&
      ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
      mixPanel({
        eventName: 'Account Page - Sub Accounts',
        eventProperties: {subAccounts: rows},
      });
  }, [rows]);

  return (
    <Grid>
      {accountService.userValue.brand_type === 'ecom3k' && rows?.length === 0 ? null : (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="h5" variant="h5" color="secondary" gutterBottom>
              Sub Accounts
            </Typography>
            <DataGrid
              className="custom-table"
              autoHeight={true}
              pagination
              paginationMode="client"
              sortingMode="client"
              filterMode="client"
              disableSelectionOnClick={true}
              rows={rows}
              columns={columns}
              loading={loading}
            />
          </Grid>
          <ModalButton
            openModal={selectedBrand}
            hideButton
            closable
            modalTitle={`Performance Checklist for ${selectedBrand?.name || selectedBrand?.brand_code}`}
            onCloseAction={() => {
              setSelectedBrand(null);
            }}
          >
            {(closeModal) => {
              return (
                <List dense>
                  {selectedBrand?.performance_checklist?.length > 0 ? (
                    selectedBrand.performance_checklist?.map((value: any, index) => {
                      return (
                        <ListItem key={`${value.label}-${index}`}>
                          <ListItemText
                            primary={
                              <Typography variant="body2" style={{textTransform: 'capitalize'}}>
                                {getLabel(value.label)}:{' '}
                                <Chip
                                  size="small"
                                  label={value.value}
                                  style={{
                                    backgroundColor: value.value === 'Good' ? 'green' : 'red',
                                    color: 'white',
                                  }}
                                />
                              </Typography>
                            }
                          />
                        </ListItem>
                      );
                    })
                  ) : (
                    <Typography variant="h6" style={{textAlign: 'center', fontSize: '1.1rem'}}>
                      There are not data to show
                    </Typography>
                  )}
                </List>
              );
            }}
          </ModalButton>
        </>
      )}
    </Grid>
  );
}

export {FfpSubAccounts};
