import {LoadingButton} from '@mui/lab';
import {styled} from '@mui/system';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  variant?: 'text' | 'outlined' | 'contained';
  disabled?: boolean;
  width?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium' | 'large';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  color?: string;
  fontColor?: string;
  loading?: boolean;
  style?: React.CSSProperties;
  loadingPosition?: 'start' | 'end' | 'center';
  autoFocus?: boolean;
}

const CustomButton = styled(LoadingButton)(({theme, className, size}) => ({
  '&.MuiButton-root': {
    padding: '.5rem 1rem .4rem 1rem',
    minWidth: 'auto',
  },

  ...(theme.palette.type === 'dark' && {
    '&.MuiButton-root:hover': {
      backgroundColor: '#4d6779',
    },
  }),

  [theme.breakpoints.down(820)]: {
    ...(size === 'medium' && {
      '&.MuiButton-root': {
        fontSize: '12px',
      },
    }),
    ...(size === 'small' && {
      '&.MuiButton-root': {
        fontSize: '11px',
      },
    }),
  },
}));

export default function Button({
  children,
  className,
  onClick,
  disabled = false,
  type = 'button',
  variant = 'contained',
  size = 'medium',
  color = 'primary',
  fullWidth = false,
  width,
  fontColor = '#fff',
  loading,
  loadingPosition,
  startIcon,
  endIcon,
  autoFocus,
  ...rest
}: ButtonProps) {
  return (
    <CustomButton
      color={color as any}
      className={className}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      style={{width: fullWidth ? '100%' : width, ...rest.style}}
      loading={loading}
      loadingPosition={loadingPosition}
      autoFocus={autoFocus}
      {...rest}
    >
      {children}
    </CustomButton>
  );
}
