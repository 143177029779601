import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

interface CustomDataGridProps {
  columns: any[];
  rows: any[];
}

export default function CustomDataGrid({columns, rows}: CustomDataGridProps) {
  return (
    <TableContainer component={Paper} className="custom-datagrid">
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index}>{column.headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
              {columns.map((column, i) => (
                <TableCell
                  key={`${row.id}-${i}`}
                  component="th"
                  scope="row"
                  align={column.align || 'left'}
                  style={{flex: column.flex || 0, minWidth: column.minWidth || 100}}
                >
                  {column?.renderCell
                    ? column?.renderCell({row: row, field: column.field, value: row[column.field]})
                    : row[column.field]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
