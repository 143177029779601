import React, {memo} from 'react';
import {Grid, Typography} from '@mui/material';
// styles
import {useStyles} from '../Styles';
// utils
import getLabel from '../../../utils/getLabel';

interface AttributeContainerProps {
  children: any;
  inline?: boolean;
  label?: string;
  id?: string;
  icon?: JSX.Element;
  styles?: any;
  className?: string;
  subField?: boolean;
  subFieldChild?: boolean;
  border?: boolean;
  spacing?: number;
  rowSpacing?: number;
  columnSpacing?: any;
  container?: boolean;
  subLabel?: boolean;
}

function AttributeContainer({
  children,
  inline,
  label,
  id,
  icon,
  styles,
  className,
  subField,
  subFieldChild,
  border,
  spacing,
  rowSpacing,
  columnSpacing,
  container,
  subLabel,
}: AttributeContainerProps) {
  const classes = useStyles();
  return (
    <Grid item xs={12} lg={inline ? 6 : 12} key={id} className={`${classes.detailsGrid} ${className}`} style={styles}>
      {label && (
        <Typography variant="body1" gutterBottom className={subLabel ? classes.subLabel : classes.parentLabel}>
          {getLabel(label)}
          {icon && icon}
        </Typography>
      )}
      <Grid
        container
        id={id}
        spacing={spacing}
        rowSpacing={rowSpacing}
        columnSpacing={columnSpacing}
        className={`${container ? 'container' : subField ? 'sub-container' : 'sub-container-child'}`}
      >
        {children}
      </Grid>
    </Grid>
  );
}

export default memo(AttributeContainer);
