import {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
// utils
import generatedGitInfo from '../../generatedGitInfo.json';
import {Api} from '../../utils/api';
import {frontendMajorVersion} from '../../utils/constants';

const useStyles = makeStyles({
  copyright: {
    width: '100%',
    height: '50px',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '50px',
  },
});

function Footer(hidden?) {
  const classes = useStyles();
  const [backendVersion, setBackendVersion] = useState('');
  useEffect(() => {
    Api.get(`version`, {})
      .then((res) => {
        setBackendVersion(res.data);
      })
      .catch((error) => {
        console.log('Error fetching backend version');
      });
  }, []);

  return (
    <footer className={classes.copyright} style={{display: hidden ? 'none' : 'block'}}>
      <Typography variant="body2" color="textSecondary" align="center">
        Front End version: {frontendMajorVersion}.{generatedGitInfo.gitCommitHash}
        &emsp;&emsp;&emsp;|&emsp;&emsp;&emsp;Back End version: {backendVersion}
      </Typography>
    </footer>
  );
}

export {Footer};
