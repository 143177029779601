import {useState, useEffect, useRef} from 'react';
import {DateRange} from 'react-date-range';
import {renderToString} from 'react-dom/server';
import {makeStyles} from '@mui/styles';
import moment from 'moment';
import {Theme} from '@mui/material';
import {useTheme} from '@mui/material/styles';
// styles
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
// icons - images
import DateIcon from '../../assets/images/calendar-alt-regular.svg';
import DateIconDark from '../../assets/images/calendar-alt-regular-dark.svg';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    padding: '10px 0',
    '&.disabled .rdrDateDisplayWrapper': {
      pointerEvents: 'none',
      opacity: theme.palette.mode === 'dark' ? 1 : 0.5,
    },
  },
  dateRangePicker: {
    width: '100%',
    '& .date-range-button-container': {
      padding: '0 10px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .date-range-button': {
      textAlign: 'right',
      padding: '0 10px 10px 0',
      '& button': {
        cursor: 'pointer',
        fontSize: '12px',
        color: '#fff!important',
        border: 'none',
        padding: '8px 12px',
        borderRadius: '3px',
        background: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
      },
      '& button:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.dark,
      },
    },
    '& input': {
      borderBottom: '1px solid #bdbdbd',
      boxShadow: 'none',
      outline: 'none',
      border: 'none',
      textAlign: 'left',
    },
    '& input:hover, & input:focus': {
      borderBottom: theme.palette.mode === 'dark' ? '1px solid #ffffff' : '1px solid #174582',
    },
    '& .rdrDateInput': {
      boxShadow: 'none',
      border: 'none',
      margin: '0',
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
    },
    '& .rdrDateInput::before': {
      position: 'absolute',
      top: '-18px',
      color: '#bbbbbb',
      zIndex: '1',
      fontWeight: '400',
    },
    '& .rdrDateInput:first-of-type::before': {
      content: '"From"',
    },
    '& .rdrDateInput::after': {
      content: '"-"',
      position: 'absolute',
      top: '-22px',
      right: '18px',
      fontSize: '50px',
      color: 'transparent',
      backgroundImage: theme.palette.mode === 'dark' ? `url(${DateIconDark})` : `url(${DateIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      opacity: '0.5',
      cursor: 'pointer',
    },
    '& .rdrDateInput:last-child::before': {
      content: '"To"',
    },
    '& .rdrMonthAndYearWrapper, & .rdrMonthsVertical': {
      display: 'none',
    },
    '& .rdrDateDisplayWrapper': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
    },
    '&.show .rdrMonthAndYearWrapper, &.show .rdrMonthsVertical': {
      display: 'flex',
      position: 'absolute',
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
      boxShadow: '0px 11px 15px -1px rgba(0,0,0,0.32)',
      zIndex: 5,
      width: '100%',
    },
    '&.show .rdrMonthAndYearWrapper': {
      top: '58px',
    },
    '&.show .rdrMonthsVertical': {
      top: '118px',
    },
    '& .rdrMonth': {
      width: '95%',
    },
    '& .rdrDayNumber span': {
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#1d2429',
    },

    '& .rdrDayPassive span': {
      color: theme.palette.mode === 'dark' && '#a3a3a3',
    },
    '& .rdrDayDisabled': {
      backgroundColor: theme.palette.mode === 'dark' ? '#5e5e5e9d' : '#f5f5f5',
    },
    '& .rdrMonthPicker, & .rdrYearPicker': {
      backgroundColor: theme.palette.mode === 'dark' ? '#e5e5e5' : '#fff',
      borderRadius: '5px',
    },
  },
}));

interface DateRangePickerProps {
  onChange: (dateRange: DateRange) => void;
  disabled?: boolean;
  from: Date | string | number;
  to: Date | string | number;
  width?: string;
  customPicker?: boolean;
  open?: boolean;
  setOpen?: any;
}

export default function DateRangePicker({
  onChange,
  disabled,
  from,
  to,
  width,
  customPicker,
  open,
  setOpen,
}: DateRangePickerProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const datePickerRef = useRef<any>(null);

  const [showDatePicker, setShowDatePicker] = useState(customPicker);
  const [selectedValue, setSelectedValue] = useState(null);
  const [state, setState] = useState([
    {
      startDate: moment(from).toDate(),
      key: 'selection',
      endDate: moment(to).toDate(),
    },
  ]);

  const sendData = (v?) => {
    if (selectedValue || v) {
      onChange(selectedValue || v);
      setOpen && setOpen(false);
      !customPicker && setShowDatePicker(false);
      setSelectedValue(null);
    }
  };

  const cancel = () => {
    setShowDatePicker(false);
    setSelectedValue(null);
    setOpen && setOpen(false);
    setState([
      {
        startDate: moment(from).toDate(),
        key: 'selection',
        endDate: moment(to).toDate(),
      },
    ]);
  };

  const showButton = () => {
    const id = 'custom-date-range';
    const idTwo = 'cancel-button';
    const containerId = 'date-range-btn-container';
    document.getElementById(id)?.remove();
    document.getElementById(idTwo)?.remove();
    document.getElementById(containerId)?.remove();

    const selectDate = <button color="primary">Select Date</button>;
    const cancelButton = <button color="primary">Cancel</button>;
    const selectDateElement = document.createElement('div');
    const cancelButtonElement = document.createElement('div');
    const container = document.createElement('div');
    const staticElement = renderToString(selectDate); // eslint-disable-line
    const staticElementTwo = renderToString(cancelButton); // eslint-disable-line
    selectDateElement.innerHTML = staticElement;
    cancelButtonElement.innerHTML = staticElementTwo;
    selectDateElement.id = id;
    cancelButtonElement.id = idTwo;
    selectDateElement.onclick = sendData;
    cancelButtonElement.onclick = cancel;
    selectDateElement.className = 'date-range-button';
    cancelButtonElement.className = 'date-range-button';
    container.id = containerId;
    container.className = 'date-range-button-container';
    if (!document.getElementById(id)) {
      container.appendChild(selectDateElement);
    }
    if (!document.getElementById(idTwo)) {
      container.appendChild(cancelButtonElement);
    }
    const row = document.getElementsByClassName(`rdrMonthsVertical`)[0];
    if (!document.getElementById(containerId) && row) {
      row?.appendChild(container);
    }
  };

  useEffect(() => {
    !customPicker && showButton();
  }, [selectedValue]);

  useEffect(() => {
    setState([
      {
        startDate: moment(from).toDate(),
        key: 'selection',
        endDate: moment(to).toDate(),
      },
    ]);
  }, [from, to]);

  useEffect(() => {
    setShowDatePicker(open);
  }, [open]);

  useEffect(() => {
    if (!customPicker) {
      const button = document.getElementsByClassName('rdrDateInput');
      for (let i = 0; i < button.length; i++) {
        button[i]?.addEventListener('click', () => {
          setShowDatePicker((p) => !p);
          setOpen && setOpen((p) => !p);
        });
      }
    }
  }, []);

  return (
    <div
      style={{maxWidth: width, width: '100%'}}
      className={`${classes.container} ${disabled && 'disabled'}`}
      ref={datePickerRef}
    >
      <DateRange
        disabled={disabled}
        readOnly={true}
        onKeyDown={(e) => e.preventDefault()}
        ranges={state}
        maxDate={moment().toDate()}
        rangeColors={theme.palette.mode === 'dark' ? ['#618299'] : ['#174582']}
        color={theme.palette.mode === 'dark' ? '#618299' : '#174582'}
        onClick={() => setShowDatePicker(true)}
        className={`${classes.dateRangePicker} ${showDatePicker && 'show'} custom-date-range`}
        editableDateInputs={true}
        onChange={(item): void => {
          setState([item.selection]);
          setSelectedValue(item);
          customPicker && sendData(item);
        }}
      />
    </div>
  );
}
