import React, {useEffect, useState} from 'react';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import {
  AccountBalanceWallet,
  AddAlert,
  Assessment,
  Equalizer,
  ListAlt,
  MonetizationOn,
  PeopleRounded,
  PictureInPictureAltRounded,
  ShoppingBasket,
  SsidChart,
  Summarize,
  ManageSearch,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
// components
import {Drawer, DrawerHeader} from './components';
import {DrawerListMenuItem} from '../DrawerListMenuItem/DrawerListMenuItem';
// utils
import {Role} from '../../utils/role';
import PATH from '../../utils/path';
import {BrandTypesAlias} from '../../utils/brand-types';
// services
import {accountService} from '../../services/account.service';

interface SideBarProps {
  open: boolean;
  setOpen: () => void;
  mobile: boolean;
}

export default function SideBar({open, setOpen, mobile}: SideBarProps) {
  const [selectedMenu, setSelectedMenu] = useState(PATH.DASHBOARD);

  const pathName = `/${window.location.pathname.split('/')[1]}`;

  const getSelectedMenu = (path: string) => {
    pathName === path && setSelectedMenu(path);
  };

  useEffect(() => {
    getSelectedMenu(pathName);
  }, [pathName]); // eslint-disable-line

  const user = accountService.userValue;

  const menu = [
    {
      icon: <Assessment />,
      label: 'Dashboard',
      linkTo: PATH.DASHBOARD,
      roles: [Role.Admin, Role.Manager, Role.User, Role.BrandUser, Role.StandardAgencyUser],
      hideForFFPAdmin: true,
    },
    {
      icon: <AccountBalanceWallet />,
      label: 'Account',
      linkTo: PATH.ACCOUNT,
      roles: [Role.BrandUser],
      brandTypes: [BrandTypesAlias.ffp, BrandTypesAlias.ecom3k],
      requiresBrandAdminPermissions: true,
    },
    {
      icon: <AddAlert />,
      label: 'Alerts',
      linkTo: PATH.ALERTS,
      roles: [Role.Admin, Role.Manager, Role.User, Role.BrandUser, Role.StandardAgencyUser],
      brandTypes: [BrandTypesAlias.mr, BrandTypesAlias.mc],
    },
    {
      icon: <Equalizer />,
      label: 'Performance',
      linkTo: PATH.PERFORMANCE,
      roles: [Role.Admin, Role.Manager, Role.User, Role.BrandUser, Role.StandardAgencyUser],
      brandTypes: [BrandTypesAlias.mr, BrandTypesAlias.mc, BrandTypesAlias.ecom3k],
      subMenu: [
        // {
        //   icon: <ProductionQuantityLimitsIcon />,
        //   label: 'Sales',
        //   linkTo: PATH.PERFORMANCE_SALES,
        //   roles: [Role.Admin, Role.Manager, Role.User, Role.BrandUser],
        //   brandTypes: [BrandTypesAlias.mr, BrandTypesAlias.mc],
        // },
        {
          icon: <SsidChart />,
          label: 'Insights',
          linkTo: PATH.PERFORMANCE_INSIGHTS,
          roles: [Role.Admin, Role.Manager, Role.User, Role.BrandUser, Role.StandardAgencyUser],
          brandTypes: [BrandTypesAlias.mr, BrandTypesAlias.mc, BrandTypesAlias.ecom3k],
        },
      ],
    },
    {
      icon: <ProductionQuantityLimitsIcon />,
      label: 'Advertising',
      linkTo: PATH.ADVERTISING_PERFORMANCE,
      roles: [Role.Admin, Role.Manager, Role.StandardAgencyUser, Role.BrandUser],
      brandTypes: [BrandTypesAlias.mr, BrandTypesAlias.mc, BrandTypesAlias.ecom3k],
    },
    {
      icon: <PictureInPictureAltRounded />,
      label: 'Accounts',
      linkTo: PATH.BRANDS,
      roles: [Role.Admin, Role.Manager, Role.User, Role.StandardAgencyUser],
      subMenu: [
        {
          icon: <ManageSearch />,
          label: 'Overview',
          linkTo: PATH.BRANDS_OVERVIEW,
          roles: [Role.Admin, Role.Manager, Role.User, Role.BrandUser, Role.StandardAgencyUser],
        },
      ],
    },
    {
      icon: <PeopleRounded />,
      label: 'Users',
      linkTo: PATH.USERS,
      roles: [Role.Admin, Role.Manager, Role.BrandUser],
      requiresCreateUserPermissions: true,
      brandTypes: [BrandTypesAlias.mr, BrandTypesAlias.mc],
    },
    {
      icon: <ShoppingBasket />,
      label: 'ASINs',
      linkTo: PATH.ASINS,
      roles: [Role.Admin, Role.Manager, Role.User, Role.BrandUser],
      brandTypes: [BrandTypesAlias.mr, BrandTypesAlias.mc],
      notAllowSubBrandManagers: true,
    },
    {
      icon: <Summarize />,
      label: 'Reports',
      linkTo: PATH.REPORTS,
      roles: [Role.Admin, Role.Manager, Role.StandardAgencyUser],
    },
    {
      icon: <MonetizationOn />,
      label: 'Commissions',
      linkTo: PATH.COMMISSIONS,
      roles: [Role.Admin, Role.Manager],
    },
    {
      icon: <ListAlt />,
      label: 'Listings',
      linkTo: PATH.LISTINGS,
      roles: [Role.Admin, Role.Manager, Role.BrandUser, Role.StandardAgencyUser],
      brandTypes: [BrandTypesAlias.ffp],
      requireFfpActiveSubscription: true,
    },
  ];

  const menuItems = [];

  for (let i = 0; i < menu.length; i++) {
    const item = menu[i];
    if (user.role === Role.BrandUser && item.requiresCreateUserPermissions && user.create_users !== true) {
      continue;
    }

    if (item.notAllowSubBrandManagers) {
      if (user.sub_brand_codes?.length > 0) {
        continue;
      }
    }

    if (user.role === Role.BrandUser && item.brandTypes && item.brandTypes.length > 0) {
      if (item.brandTypes.indexOf(user.brand_type) === -1) {
        continue;
      }
    }

    if (user.role === Role.BrandUser && item.requiresBrandAdminPermissions && user.brand_admin !== true) {
      continue;
    }

    if (
      user.role === Role.BrandUser &&
      user.brand_type === 'ffp' &&
      item.requireFfpActiveSubscription &&
      !user.active_brand_subscription
    ) {
      continue;
    }

    if (item.hideForFFPAdmin && ['ffp', 'ecom3k'].includes(user.brand_type) && user.brand_admin) {
      continue;
    }

    if (
      item.hideForFFPAdmin &&
      ['ffp', 'ecom3k'].includes(user.brand_type) &&
      !user.brand_admin &&
      user.active_brand_subscription
    ) {
      continue;
    }
    menuItems.push(
      <DrawerListMenuItem
        key={i}
        icon={item.icon}
        label={item.label}
        linkTo={item.linkTo}
        roles={item.roles}
        selected={selectedMenu.split('/')[1] === item.linkTo.split('/')[1]}
        onClick={() => !mobile && setOpen()}
        subItems={item.subMenu}
        url={pathName}
      />
    );
  }

  return (
    <Drawer variant="permanent" open={open}>
      <div>
        <List>{menuItems}</List>
      </div>
      <DrawerHeader>
        <IconButton onClick={setOpen}>{open ? <ChevronLeft /> : <ChevronRight />}</IconButton>
      </DrawerHeader>
    </Drawer>
  );
}
