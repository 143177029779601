import {useEffect, useState} from 'react';
import {Grid, Card, Typography, List, ListItem, ListItemIcon, ListItemText, Skeleton, useTheme} from '@mui/material';
import {Equalizer, Star, Edit, Add} from '@mui/icons-material';
import {useLocation} from 'react-router-dom';
// component
import Button from '../../../../components/Button/Button';
import ModalButton from '../../../../components/ModalButton/ModalButton';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Images from '../../../../components/Images/Images';
import UpdateExtraProductForm from '../../components/UpdateExtraProductForm';
import {CustomAlert} from '../../../Login/components/CustomAlert';
// styles
import {useStyles} from '../../Styles';
// utils
import {BrandTypesAlias} from '../../../../utils/brand-types';
import {getArticle} from '../../../../utils/FFPBeacon';
import {Helper} from '../../../../utils/helper';
import {Api, errorAlert} from '../../../../utils/api';
// services
import mixPanel from '../../../../services/mixpanel';
import {accountService} from '../../../../services/account.service';

interface PaymentAndBillingProps {
  GAEvents?: (category, action, label?) => void;
}

export default function PaymentAndBilling({GAEvents}: PaymentAndBillingProps) {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [subscritionDetails, setSubscritionDetails] = useState(null);
  const [openCouponForm, setOpenCouponForm] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [productId, setProductId] = useState({
    user: null,
    extra_account: null,
  });

  async function pullSubscriptionItems() {
    setLoading(true);
    setButtonLoading(true);
    try {
      const {data} = await Api.get(`/brands/${accountService.userValue.brand_code}/subscription-items`);
      const newData = data?.reduce(function (result, item) {
        let key = item.name.toLowerCase().replace(/\s/g, '_');
        result[key] = item;
        return result;
      }, {});
      mixPanel({
        eventProperties: {
          subscriptionPlan: newData ? newData : data,
        },
      });
      setSubscritionDetails(newData ? newData : data);
    } catch (e: any) {
      errorAlert('Something went wrong', e);
    } finally {
      setLoading(false);
      setButtonLoading(false);
    }
  }

  useEffect(() => {
    pullSubscriptionItems();
    accountService.refreshToken(); // Refresh the user's subscription status
    const _productId = {user: null, extra_account: null};
    switch (accountService.userValue.brand_type) {
      case BrandTypesAlias.ffp:
        _productId.user = process.env.REACT_APP_STRIPE_EXTRA_USER_KEY;
        _productId.extra_account = process.env.REACT_APP_STRIPE_EXTRA_CONNECTOR_ACCOUNT_KEY;
        break;
      case BrandTypesAlias.ecom3k:
        _productId.extra_account = process.env.REACT_APP_STRIPE_ECOM3K_BASIC_SUBSCRIPTION_PRODUCT_ID;
        break;
      default:
        break;
    }
    setProductId(_productId);
  }, []);

  useEffect(() => {
    ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) && getArticle({});
  }, [loading]);

  useEffect(() => {
    let coupon;
    if (window.location.href.includes('couponCode')) {
      coupon = urlParams.get('couponCode');
      window.localStorage.setItem('couponCode', coupon);
    }
    if (window.localStorage.getItem('couponCode')) {
      coupon = window.localStorage.getItem('couponCode');
    }
    if (coupon) {
      setOpenCouponForm(true);
      setCouponCode(coupon);
      window.localStorage.removeItem('coupon');
    }
  }, []);

  async function onSubscribeClick() {
    try {
      const response = await Api.post('/users/stripe-checkout-session', {
        productId: 'ffp_basic_plan',
        brandType: accountService.userValue.brand_type,
      });
      if (response.data) {
        window.location.href = response.data.url;
      }
    } catch (e: any) {
      errorAlert('Unable to create checkout session', e);
    }
  }

  async function onManageSubscriptionClick() {
    setButtonLoading(true);
    try {
      const response = await Api.post('/users/stripe/create-portal-session', null);
      if (response.data) {
        window.location.href = response.data.url;
      }
    } catch (e: any) {
      errorAlert('Unable to open the portal', e);
    } finally {
      setButtonLoading(false);
    }
  }

  useEffect(() => {
    mixPanel({
      eventName: 'Account Page - Billing',
    });
  }, []);

  return (
    <Grid container flexDirection="column">
      <PageTitle icon={<Equalizer />}>Payment and Billing</PageTitle>
      {accountService.userValue.active_brand_subscription ? (
        <Grid container style={{margin: '50px 0'}}>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.grid}>
            <Card className={classes.card}>
              <div className={classes.cardHeader}>
                <Typography gutterBottom component="h4">
                  Subscription Plan
                </Typography>
                <Equalizer />
              </div>
              {accountService.userValue.brand_type === BrandTypesAlias.ffp && (
                <div className={classes.cardContent}>
                  <Typography gutterBottom component="h4" align="center">
                    FFP Basic Plan
                  </Typography>
                  <Typography gutterBottom variant="h6" component="span">
                    Includes:
                  </Typography>

                  {!loading ? (
                    <List sx={{width: '100%', maxWidth: 360}} aria-label="contacts">
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <Star />
                        </ListItemIcon>
                        <ListItemText primary={'1 Amazon account'} />
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <Star />
                        </ListItemIcon>
                        <ListItemText primary={`2 Users`} />
                      </ListItem>
                      {(subscritionDetails?.ffp_extra_amazon_connectors || subscritionDetails?.ffp_extra_users) && (
                        <>
                          <ListItem disablePadding>
                            <Typography variant="subtitle1" component="span" style={{padding: '10px 0'}}>
                              Additional Features:
                            </Typography>
                          </ListItem>

                          {subscritionDetails?.ffp_extra_amazon_connectors && (
                            <ListItem disablePadding>
                              <ListItemIcon>
                                <Star />
                              </ListItemIcon>
                              <ListItemText
                                primary={`${subscritionDetails?.ffp_extra_amazon_connectors?.quantity} Extra Amazon ${
                                  subscritionDetails?.ffp_extra_amazon_connectors?.quantity === 1
                                    ? 'Account'
                                    : 'Accounts'
                                }`}
                              />
                            </ListItem>
                          )}
                          {subscritionDetails?.ffp_extra_users && (
                            <ListItem disablePadding>
                              <ListItemIcon>
                                <Star />
                              </ListItemIcon>
                              <ListItemText
                                primary={`${subscritionDetails?.ffp_extra_users?.quantity} Extra ${
                                  subscritionDetails?.ffp_extra_users?.quantity === 1 ? 'User' : 'Users'
                                }`}
                              />
                            </ListItem>
                          )}
                        </>
                      )}
                    </List>
                  ) : (
                    <Grid xs={12} sm={12} md={12} lg={12} item style={{padding: '30px 0'}}>
                      <Skeleton height={15} animation="wave" />
                      <Skeleton height={15} animation="wave" />
                      <Skeleton height={15} animation="wave" />
                      <Skeleton height={15} animation="wave" />
                    </Grid>
                  )}
                </div>
              )}
              {accountService.userValue.brand_type === BrandTypesAlias.ecom3k && (
                <div className={classes.cardContent}>
                  <Typography gutterBottom component="h4" align="center">
                    ECOM3K Basic Plan
                  </Typography>
                  <Typography gutterBottom variant="h6" component="span">
                    Includes:
                  </Typography>

                  {!loading ? (
                    <List sx={{width: '100%', maxWidth: 360}} aria-label="contacts">
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <Star />
                        </ListItemIcon>
                        <ListItemText
                          primary={Helper.formatAmountAndUnitLabel(subscritionDetails?.product?.quantity, ' Connector')}
                        />
                      </ListItem>
                      {accountService.userValue.brand_type === BrandTypesAlias.ffp && subscritionDetails?.product && (
                        <>
                          <ListItem disablePadding>
                            <Typography variant="subtitle1" component="span" style={{padding: '10px 0'}}>
                              Additional Features:
                            </Typography>
                          </ListItem>
                          {subscritionDetails?.product && (
                            <ListItem disablePadding>
                              <ListItemIcon>
                                <Star />
                              </ListItemIcon>
                              <ListItemText
                                primary={Helper.formatAmountAndUnitLabel(
                                  subscritionDetails?.product?.quantity,
                                  'Extra Connector'
                                )}
                              />
                            </ListItem>
                          )}
                        </>
                      )}
                    </List>
                  ) : (
                    <Grid xs={12} sm={12} md={12} lg={12} item style={{padding: '30px 0'}}>
                      <Skeleton height={15} animation="wave" />
                      <Skeleton height={15} animation="wave" />
                      <Skeleton height={15} animation="wave" />
                      <Skeleton height={15} animation="wave" />
                    </Grid>
                  )}
                </div>
              )}
              <div className={classes.cardFooter}>
                <Button
                  onClick={() => {
                    onManageSubscriptionClick();
                    GAEvents('Subcription', 'Manage your Subscription', `user: ${accountService.userValue.userId}`);
                  }}
                  endIcon={<Edit />}
                  loading={buttonLoading}
                >
                  Manage your subscription
                </Button>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.grid}>
            {accountService.userValue.brand_type === 'ffp' && (
              <ModalButton
                modalTitle="Add a seat to your plan"
                buttonText="Add a seat to your plan"
                startIcon={<Add />}
                onClick={() => {
                  GAEvents('Click', 'Add a seat to your plan', `user: ${accountService.userValue.userId}`);
                }}
              >
                {(closeModal): JSX.Element => {
                  return (
                    <UpdateExtraProductForm
                      productId={process.env.REACT_APP_STRIPE_EXTRA_USER_KEY}
                      label="Number of seats"
                      currentQuantity={
                        subscritionDetails?.ffp_extra_users ? subscritionDetails?.ffp_extra_users?.quantity : 0
                      }
                      onSuccess={() => {
                        closeModal();
                        pullSubscriptionItems();
                      }}
                      GAEvents={(category, action, label?) => GAEvents(category, action, label)}
                    />
                  );
                }}
              </ModalButton>
            )}
            <ModalButton
              modalTitle={
                accountService.userValue.brand_type === 'ffp'
                  ? 'Add another Seller Central Account to your plan'
                  : 'Add another Connector to your plan'
              }
              buttonText={
                accountService.userValue.brand_type === 'ffp'
                  ? 'Add another Seller Central Account to your plan'
                  : 'Add another Connector to your plan'
              }
              startIcon={<Add />}
              onClick={() => {
                GAEvents(
                  'Click',
                  accountService.userValue.brand_type === 'ffp'
                    ? 'Add another Seller Central Account to your plan'
                    : 'Add another Connector to your plan',
                  `user: ${accountService.userValue.userId}`
                );
              }}
              disable={!productId?.extra_account}
            >
              {(closeModal): JSX.Element => {
                return (
                  <UpdateExtraProductForm
                    productId={
                      accountService.userValue.brand_type === 'ffp'
                        ? process.env.REACT_APP_STRIPE_EXTRA_CONNECTOR_ACCOUNT_KEY
                        : process.env.REACT_APP_STRIPE_ECOM3K_BASIC_SUBSCRIPTION_PRODUCT_ID
                    }
                    label={
                      accountService.userValue.brand_type === 'ffp'
                        ? 'Number of Seller Central Accounts'
                        : 'Number of Connectors'
                    }
                    currentQuantity={
                      accountService.userValue.brand_type === 'ffp'
                        ? subscritionDetails?.ffp_extra_amazon_connectors?.quantity || 0
                        : accountService.userValue.brand_type === 'ecom3k'
                        ? subscritionDetails?.product?.quantity
                        : 0
                    }
                    onSuccess={() => {
                      closeModal();
                      pullSubscriptionItems();
                    }}
                    GAEvents={(category, action, label?) => GAEvents(category, action, label)}
                  />
                );
              }}
            </ModalButton>
            <ModalButton
              modalTitle="Apply a Coupon Code to Your Plan"
              buttonText="Apply a Coupon Code to Your Plan"
              startIcon={<Add />}
              onClick={() => {
                GAEvents('Click', 'Add a coupon code to your plan', `user: ${accountService.userValue.userId}`);
              }}
              openModal={openCouponForm}
              onClose={() => {
                setOpenCouponForm(false);
                window.localStorage.removeItem('couponCode');
                urlParams.delete('couponCode');
                window.history.replaceState({}, '', `${location.pathname}?${urlParams.toString()}`);
              }}
            >
              {(closeModal): JSX.Element => {
                return (
                  <UpdateExtraProductForm
                    coupon
                    defaultValue={couponCode}
                    productId={
                      accountService.userValue.brand_type === 'ffp'
                        ? process.env.REACT_APP_STRIPE_EXTRA_USER_KEY
                        : process.env.REACT_APP_STRIPE_ECOM3K_BASIC_SUBSCRIPTION_PRODUCT_ID
                    }
                    label="Apply a Coupon Code"
                    onSuccess={() => {
                      closeModal();
                      pullSubscriptionItems();
                    }}
                    GAEvents={(category, action, label?) => GAEvents(category, action, label)}
                  />
                );
              }}
            </ModalButton>
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{margin: '50px 0'}} display="flex" justifyContent="center">
          <Grid item className={classes.grid}>
            <Images name={theme.palette.mode === 'dark' ? 'SubscriberDark' : 'Subscriber'} />
            <Button
              color="primary"
              onClick={() => {
                onSubscribeClick();
                GAEvents('Subscription', 'Subscribe', `user: ${accountService.userValue.userId}`);
              }}
              loading={loading}
              disabled={accountService.userValue.active_brand_subscription}
            >
              Enable monthly subscription for{' '}
              {accountService.userValue.brand_type === BrandTypesAlias.ffp ? '$49.99' : '$19.99'}
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid item lg={12}>
        <CustomAlert id="default-alert" />
      </Grid>
    </Grid>
  );
}
