// utils
import {Api} from '../utils/api';
// services
import {accountService} from '../services/account.service';

interface userPreferencesProps {
  list: any[];
  tableName: string;
  visibilityModel: any;
  defaultVisibilityModel: any;
  loading: any;
  currentOrder?: any;
  groupBy?: string;
}

interface getColumnsItemsProps {
  list: any[];
  columnsVisibility: any;
  columnsOrder: any;
  currentOrder?: any;
}

const getColumnsItems = ({list, columnsVisibility, columnsOrder, currentOrder}: getColumnsItemsProps) => {
  const state = columnsOrder;

  let newList = state && state.length > 0 && !currentOrder ? [] : currentOrder ? currentOrder : list;

  state?.length > 0 &&
    !currentOrder &&
    state?.forEach(function (value, index) {
      newList[index] = list.find((x: any) => x.field === value);
    });

  return newList.map((x) => {
    return {
      ...x,
      id: x?.field,
      hide: columnsVisibility?.[x?.field] === false,
    };
  });
};

async function getUserPreferences({
  list,
  tableName,
  defaultVisibilityModel,
  visibilityModel,
  loading,
  currentOrder,
  groupBy,
}: userPreferencesProps) {
  loading(true);
  try {
    const {data} = await Api.get(`users/${accountService.userValue.userId}/table-sort/${tableName}`);
    let visibility;

    if (data.sort_data?.columnVisibilityModel) {
      visibility = data.sort_data.columnVisibilityModel;
    } else {
      visibility = defaultVisibilityModel;
    }

    const added = [];
    const deleted = [];

    let columns = data?.sort_data?.columnsOrder;

    const listKeys = list.map((x, v) => x.field);

    columns && listKeys?.filter((v, x) => !columns.includes(v) && added.push(v));

    columns && columns?.filter((v, x) => !listKeys.includes(v) && deleted.push(v));

    if (added.length > 0) {
      columns = added.concat(columns);
    }

    if (deleted.length > 0) {
      columns = columns.filter((v, x) => {
        return !deleted.includes(v);
      });
      Object.keys(visibility).forEach((key) => {
        if (deleted.includes(key)) {
          return delete visibility[key];
        }
      });
    }

    const newColumnsArray = getColumnsItems({
      list: list,
      columnsVisibility: visibility,
      columnsOrder: columns,
      currentOrder,
    });

    return {
      columns: newColumnsArray,
      visibility,
    };
  } catch (error: any) {
  } finally {
    loading(false);
  }
}

async function setUserPreferences(values: any, tableName: string, setTableLoading: any) {
  setTableLoading(true);
  try {
    await Api.patch(`users/${accountService.userValue.userId}/table-sort/${tableName}`, {sortData: values});
  } catch (error: any) {
  } finally {
    setTableLoading(false);
  }
}

export {setUserPreferences, getUserPreferences, getColumnsItems};
