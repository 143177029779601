import {Api, errorAlert} from './api';

export async function getSubBrands(brandCodes, setLoading?) {
  if (!brandCodes && brandCodes.length === 0) return;
  setLoading?.(true);
  try {
    const {data} = await Api.get(`/brands/sub-brands?brandCodes=${brandCodes}`);
    return data;
  } catch (e) {
    errorAlert('Error getting sub brands', e);
  } finally {
    setLoading?.(false);
  }
}
