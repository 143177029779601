import TableContainer from '../TableContainer/TableContainer';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-mui';
import {Link} from 'react-router-dom';
import {Grid, Paper, Theme, InputAdornment, Button, Typography, CircularProgress, Avatar} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {AlternateEmail, LockOutlined, PersonOutlineOutlined, FormatQuote} from '@mui/icons-material';
import {ThemeProvider, createTheme} from '@mui/material/styles';
// style
import './global-styles.css';
// components
import Spacer from '../Spacer/Spacer';

const useStyles = makeStyles((theme: Theme) => ({
  loginPaper: {
    textAlign: 'center',
    padding: '1em 2em 3em 2em',
    margin: '2em 0em',
    maxWidth: '500px',
    width: '100%',
    background: 'transparent',
    '& .header': {
      color: '#5A38FD',
      fontWeight: 600,
    },
    [theme.breakpoints.down(900)]: {
      padding: '1em 2em 3em 2em',
      background: '#ffffff9d',
      borderRadius: '40px',
    },
  },
  inputField: {
    width: '100%',
    margin: '10px 0',
    '& .MuiInputLabel-shrink': {
      fontWeight: '600',
    },
    '& .MuiInputBase-input:-webkit-autofill': {
      boxShadow: '0 0 0 100px #fff inset!important',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.3rem',
      color: '#adadad',
    },
    [theme.breakpoints.down(900)]: {
      '& .MuiOutlinedInput-root': {
        background: '#fff',
        borderRadius: '5px',
      },
    },
  },
  loginButton: {
    margin: '20px 0 30px 0',
    width: '100%',
    padding: '13px 0',
    background: '#5A38FD',
    '&:hover': {
      background: '#4c2de7',
    },
  },
  customView: {
    height: '100vh',
    '& > div:last-of-type': {
      background: '#5A38FD',
    },
  },
  grid: {
    background: '#fff',
    maxWidth: '100%',
    '& > .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    [theme.breakpoints.down(1024)]: {
      padding: '0 15px',
    },
    [theme.breakpoints.down(900)]: {
      '& > .MuiGrid-item:first-of-type': {
        display: 'none',
      },
    },
  },
  quote: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .quote-container': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '60px!important',
      opacity: 0.8,
      transition: '.3s ease-out',
      '& .card': {
        flexWrap: 'nowrap!important',
        alignItems: 'center',
      },
      '& p': {
        fontStyle: 'italic',
        fontSize: '18px',
        lineHeight: '24px',
      },
      '& span': {
        lineHeight: '20px',
      },
      '& .MuiAvatar-root': {
        width: '45px',
        height: '45px',
      },
      '&:hover': {
        opacity: 1,
        transition: '.3s ease-in',
      },
      [theme.breakpoints.down(1024)]: {
        padding: '40px 35px!important',
        '& p': {
          fontSize: '16px',
          lineHeight: '20px',
        },
        '& span': {
          fontSize: '15px',
          lineHeight: '20px',
        },
      },
    },
    '& .relative': {
      position: 'relative',
      width: '100%',
      '& .MuiAvatar-root': {
        marginRight: '20px',
      },
      '& svg': {
        position: 'absolute',
        top: '3px',
        left: '-20px',
        transform: 'scaleX(-1)',
        fontSize: '48px',
        color: '#7c61ff',
        [theme.breakpoints.down(1024)]: {
          fontSize: '35px',
        },
      },
      '& .author-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    '& .absolute': {
      position: 'absolute',
    },
  },
}));

interface ECOM3KRegistrationViewProps {
  validationSchema: any;
  onSubmit: any;
  initialValues: any;
  fields: any[];
  submitText: string;
  forgotPassword?: string;
  formDescription?: string;
  logo?: any;
  itemRef?: any;
  actionButton?: any;
  isInvalid?: boolean;
  message?: string;
}

export default function ECOM3KRegistrationView({
  validationSchema,
  onSubmit,
  initialValues,
  fields,
  submitText,
  forgotPassword,
  formDescription,
  logo,
  itemRef,
  actionButton,
  isInvalid,
  message,
}: ECOM3KRegistrationViewProps) {
  const classes = useStyles();

  const icons = {
    email: <AlternateEmail />,
    password: <LockOutlined />,
    user: <PersonOutlineOutlined />,
  };

  const newTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        light: '#fff',
        main: '#174582',
        dark: '#052d61',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ffffff',
        main: '#174582',
        dark: '#1b2132',
        contrastText: '#000',
      },
      success: {
        light: '#81c784',
        main: '#4caf50',
        dark: '#388e3c',
        contrastText: '#fff',
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: '#24242488',
      },
      background: {
        default: '#fff',
        paper: '#fff',
      },
    },
    typography: {
      fontFamily: [
        'Karla',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });

  const testimonials = [
    {
      author: 'Matthew Turner',
      job: 'Founder and 7-figure seller',
      content: "Wow. That's Amazing",
      avatar: 'https://ecom3k.com/wp-content/uploads/2022/06/unnamed.jpeg',
    },
    {
      author: 'Ian Sells',
      job: ' Founder, Million Dollar Sellers',
      content: 'This is a homerun. We use Ecom3k data to improve conversion rates on our ASINs',
      avatar: 'https://ecom3k.com/wp-content/uploads/2022/08/1516341140773.jpeg',
    },
  ];

  return (
    <ThemeProvider theme={newTheme}>
      <TableContainer className={classes.grid} container justifyContent="space-between" alignItems="center">
        <Grid container className={classes.customView}>
          <Grid item lg={7} md={7} sm={12} xs={12} container alignItems="center" justifyContent="center">
            <Paper className={classes.loginPaper} elevation={0}>
              {logo}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                innerRef={itemRef}
              >
                {({errors, touched, values, isSubmitting}) => {
                  return (
                    <Form>
                      {isInvalid ? (
                        <Typography component="h2" variant="h4" color={'error'} gutterBottom>
                          {message}
                        </Typography>
                      ) : (
                        <>
                          {formDescription && (
                            <div>
                              <Typography component="h3" variant="h6" className="header" gutterBottom>
                                {formDescription}
                              </Typography>
                              <br />
                            </div>
                          )}
                          <div>
                            {fields.map((item, key) => {
                              return (
                                <div key={key}>
                                  <div>{item.extra}</div>
                                  <Field
                                    autocomplete="none"
                                    className={classes.inputField}
                                    component={TextField}
                                    variant="outlined"
                                    name={item.name}
                                    placeholder={item.label}
                                    type={item.type}
                                    size="large"
                                    InputLabelProps={{shrink: false}}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" style={{outline: 'none'}}>
                                          {icons[item.icon]}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                              );
                            })}
                            <div>
                              <Button
                                variant="contained"
                                size="large"
                                type="submit"
                                disabled={isSubmitting}
                                className={classes.loginButton}
                                onClick={() => actionButton && actionButton(values)}
                              >
                                {submitText}
                                {isSubmitting && <CircularProgress size={12} style={{margin: '0 10px'}} />}
                              </Button>
                            </div>
                            {forgotPassword && (
                              <div>
                                <Link to={forgotPassword}>
                                  <Button variant="outlined" color="secondary" size="large">
                                    Forgot password
                                  </Button>
                                </Link>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </Paper>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12} className={classes.quote}>
            <div className="quote-container">
              {testimonials.map((item, index) => (
                <blockquote className="relative" key={index}>
                  <FormatQuote />
                  <p className="relative">{item.content}</p>
                  <Grid container className="card">
                    <Avatar src={item.avatar} />
                    <div className="author-container">
                      <span style={{fontWeight: 600, textTransform: 'uppercase'}}>{item.author}</span>
                      <span>{item.job}</span>
                    </div>
                  </Grid>
                  <Spacer height={15} />
                </blockquote>
              ))}
            </div>
          </Grid>
        </Grid>
      </TableContainer>
    </ThemeProvider>
  );
}
