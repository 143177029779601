import React, {useEffect, useState, useContext} from 'react';
import {
  LinearProgress,
  MenuItem,
  Tooltip,
  TextField,
  Link as MuiLink,
  List,
  ListItem,
  ListItemText,
  Typography,
  Chip,
  Grid,
  IconButton,
} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import moment from 'moment';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridPagination,
  useGridApiContext,
} from '@mui/x-data-grid';
import {Edit, Visibility, Check, Close, ContentPaste, Circle} from '@mui/icons-material';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import Button from '../../components/Button/Button';
import SearchBar from '../../components/SearchBar/SearchBar';
import DropDownButton from '../../components/DropdownButton/DropDownButton';
import DraggableList from '../../components/DraggableList/DraggableList';
import TableSelectInput from '../../components/TableSelectInput/TableSelectInput';
import ModalButton from '../../components/ModalButton/ModalButton';
import {CustomAlert} from '../Login/components/CustomAlert';
// utils
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../utils/tableSettings';
import {tableFields as customTableFields} from './utils/BrandProperties';
import {getDefaultQuery, updateQueryParams} from '../../utils/urlParams';
import getLabel from '../../utils/getLabel';
import {BrandTypesAlias} from '../../utils/brand-types';
import {CustomColumn, makeColumnFreeze, fixedPlaceHolder} from '../../utils/makeColumnFreeze';
import {rowsPerPageOptions} from '../../utils/constants';
import {Role} from '../../utils/role';
import {Api, errorAlert} from '../../utils/api';
// services
import {accountService} from '../../services/account.service';
import {alertService} from '../../services/alert.service';
// styles
import {useStyles} from './Styles';
// context
import {AppContext} from '../../context/AppContext/AppContext';

React.useLayoutEffect = React.useEffect as typeof React.useEffect;

function Brands() {
  const classes = useStyles();
  const location = useLocation();
  document.title = 'Stonehenge.ai/Brands';
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(40);
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState<any>([
    {
      field: null,
      sort: null,
    },
  ]);
  const [filter, setFilter] = useState({
    columnField: null,
    value: null,
  });
  const [nameFilter, setNameFilter] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [orderColumns, setOrderColumns] = useState([]);
  const [visibilityModel, setVisibilityModel] = useState<any>({});
  const [tableLoading, setTableLoading] = useState(false);
  const [tableFields, setTableFields] = useState({...customTableFields});
  const [selectedCustomAttrData, setSelectedCustomAttrData] = useState<any>({field: null, data: []});
  const [selectedBrand, setSelectedBrand] = useState<any>(null);

  const {accountManagers, getBrandUtils, brandUtils, brandUtilsLoading, setBrandUtilsLoading} = useContext(AppContext);

  let selectedCustomAttr: any = {field: null, data: []};
  let brandsByPage = [];

  useEffect(() => {
    async function getTableData() {
      setTableLoading(true);
      try {
        const data = await getUserPreferences({
          list: newColumns,
          tableName: 'brands',
          visibilityModel: visibilityModel,
          defaultVisibilityModel: {},
          loading: setTableLoading,
        });
        if (data) {
          setOrderColumns(data.columns);
          setVisibilityModel(data.visibility);
        }
      } catch (e) {
      } finally {
        setTableLoading(false);
      }
    }
    getTableData();
  }, [tableFields]);

  const thisRef = React.useRef<any>(null);

  const params = {
    sortBy: sort[0]?.field,
    sortDirection: sort[0]?.sort,
    filterBy: filter.columnField,
    filterValue: filter.value,
  };

  useEffect(() => {
    updateQueryParams(location, params);
  }, [sort[0], filter]);

  async function loadData(page, pageSize, sortModel, filterModel, updateCustomAttr?) {
    setLoadingData(true);
    updateCustomAttr && setBrandUtilsLoading(true);
    try {
      const {data} = await Api.get('brands', {
        params: {
          page: page,
          pageSize: pageSize,
          sortField: sortModel?.field || null,
          sortOrder: sortModel?.sort || null,
          filterField: filterModel?.columnField || null,
          filterValue: filterModel?.value || null,
        },
      });
      setCount(data.count);
      const pageBrandCodes = data.rows.map((i) => i.brand_code);

      const getAttributes = (data) => {
        if(!data) return {};
        let customAttributes = {};
        Object.entries(data).forEach(([key, row]: any, index) => {
          customAttributes = {
            ...customAttributes,
            [key.replaceAll(' ', '_')]: row.value,
          };
        });
        return customAttributes;
      };

      if (sortModel?.field !== 'account_manager') {
        setRows(
          data.rows
            .map((x, i) => {
              const attr = getAttributes(x?.custom_attributes);
              return {id: i, ...x, ...attr, brand_codes: pageBrandCodes};
            })
            .sort((a, b) => {
              if (sortModel?.sort === 'asc') {
                return a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1;
              } else {
                return a.name?.toLowerCase() < b.name?.toLowerCase() ? 1 : -1;
              }
            })
        );
      } else {
        setRows(
          data.rows.map((x, i) => {
            const attr = getAttributes(x?.custom_attributes);

            return {id: i, ...x, ...attr, brand_codes: pageBrandCodes};
          })
        );
      }

      let customAttributes = {};
      const attributeType = {
        TEXT: 'text',
        LINK: 'text',
        NUMBER: 'number',
        USER: 'select',
      };

      if(data.rows.length > 0 && data.rows[0]?.custom_attributes)
        Object.entries(data.rows[0]?.custom_attributes).forEach(([key, row]: any, index) => {
          customAttributes = {
            ...customAttributes,
            [key.replaceAll(' ', '_')]: {
              type: attributeType[row.type],
              value: null,
              label: key,
              custom_attribute: true,
              options: attributeType[row.type] === 'select' ? 'role' : null,
            },
          };
        });

      setTableFields({...customTableFields, ...customAttributes});
    } catch (e) {
      errorAlert('Unable to get brands', e);
    } finally {
      setLoadingData(false);
      updateCustomAttr && setBrandUtilsLoading(false);
    }
  }

  function handleSortModelChange(params) {
    loadData(page, pageSize, params[0], filter, true);
    setSort(params);
    resetCheckboxes();
  }

  function handleFilterModelChange(params) {
    loadData(0, pageSize, sort[0], params.items[0], true);
    setFilter(params.items[0]);
    setPage(0);
    resetCheckboxes();
  }

  function onSearch() {
    const filterModel = {columnField: 'name', value: nameFilter};
    setFilter(filterModel);
    loadData(0, pageSize, sort[0], filterModel, true);
    resetCheckboxes();
  }

  function onPageChange(params) {
    loadData(params, pageSize, sort[0], filter);
    setPage(params);
  }

  function handleChangePageSize(params: any) {
    setPageSize(params);
    setPage(0);
    loadData(page, params, sort[0], filter, true);
    resetCheckboxes();
  }

  async function updateTable(id, field, value, columnId?) {
    setLoadingData(true);
    try {
      await Api.patch(`brands/${id}`, {
        [field]: value,
      });
      alertService.success('Brand updated successfully');
    } catch (e) {
      errorAlert('Unable to update brand', e);
    } finally {
      setLoadingData(false);
    }
  }

  async function updateCustomAttr(selectedAttributes, value, field) {
    setLoadingData(true);
    const data = selectedAttributes.data.filter((item, index) => selectedAttributes.data.indexOf(item) === index);
    const attributes = data.map((i) => {
      return {
        brand_code: i,
        name: selectedAttributes.field,
        value: value,
      };
    });
    try {
      await Api.put(`brand-custom-attribute/bulk`, attributes);
      alertService.success('Brand updated successfully');
      selectedAttributes.data.forEach((i) => {
        const foundIndex = rows.findIndex((x) => x.brand_code === i);
        rows[foundIndex][field] = value;
      });
    } catch (e) {
      errorAlert('Unable to update brand custom attribute', e);
    } finally {
      setLoadingData(false);
      setSelectedCheckboxes(selectedAttributes.field, true, false, selectedAttributes.data);
      setSelectedCustomAttrData({field: null, data: []});
    }
  }

  const getFieldOptions = (field: string) => {
    const optionsArray =
      field === 'role'
        ? brandUtils.brandRoles
        : field === 'manager'
        ? accountManagers
        : field === 'type'
        ? brandUtils.brandTypes
        : field === 'status'
        ? brandUtils.brandStatuses
        : field === 'brands'
        ? brandUtils.brandList
        : field === 'agreement'
        ? brandUtils.agreementTypes
        : field === 'ordering'
        ? brandUtils.orderMethods
        : [];
    return optionsArray;
  };

  const copyToClipboard = (field, fieldValue) => {
    const value = tableFields[field];
    if (typeof fieldValue === 'boolean' || !fieldValue) {
      return;
    }
    let finalValue;
    if (value.options) {
      const options = getFieldOptions(value.options);
      const newValue = options.find((x) => x.id.toString() === fieldValue?.toString());
      if (newValue) {
        finalValue = newValue.name || newValue.email || newValue;
      } else {
        finalValue = fieldValue;
      }
    } else {
      finalValue = fieldValue?.toString();
    }
    finalValue && navigator.clipboard.writeText(finalValue);
  };

  const putIcon = (field, value) => (
    <div className="edit-cell-icon">
      <Edit />
      {value && (
        <Tooltip title="Copy to Clipboard">
          <ContentPaste onClick={() => copyToClipboard(field, value)} />
        </Tooltip>
      )}
    </div>
  );

  const setSelectedCheckboxes = (id, all, value, brands?, setProperty?) => {
    const input = document.getElementById(id) as HTMLInputElement | null;
    if (input) {
      input.checked = value;

      if (setProperty) {
        input.setAttribute('clear', 'true');
      } else {
        input.removeAttribute('clear');
      }
    }

    if (all) {
      const selectedBrands = brands ? brands : selectedCustomAttrData.data;
      selectedBrands?.forEach((i) => {
        const input = document.getElementById(`${id}-${i}`) as HTMLInputElement | null;
        if (input) {
          input.checked = value;

          if (setProperty) {
            input.setAttribute('clear', 'true');
          } else {
            input.removeAttribute('clear');
          }
        }
      });
    }
  };

  const resetCheckboxes = () => {
    setSelectedCheckboxes(selectedCustomAttrData.field, true, false, selectedCustomAttrData.data, true);
  };

  const checkboxHandleChange = (v, field, selectedAll, brandsByPage, brandCode?) => {
    const currentValue = v.target.value;

    if (selectedCustomAttr.field !== field) {
      setSelectedCheckboxes(selectedCustomAttr.field, true, false);
      selectedCustomAttr.data = [];
      selectedCustomAttr.field = null;
    }

    if (selectedAll) {
      if (v.target.checked) {
        selectedCustomAttr.data = [...selectedCustomAttr.data, ...brandsByPage];
        selectedCustomAttr.data = selectedCustomAttr.data.filter(
          (item, index) => selectedCustomAttr.data.indexOf(item) === index
        );
        selectedCustomAttr.field = field;
        setSelectedCheckboxes(currentValue, true, true, brandsByPage);
      } else {
        selectedCustomAttr.data = selectedCustomAttr.data.filter((i) => !brandsByPage.includes(i));
        selectedCustomAttr.field = selectedCustomAttr.data.length > 0 ? selectedCustomAttr.field : null;
        setSelectedCheckboxes(currentValue, true, false, brandsByPage);
      }
    } else {
      if (v.target.checked) {
        if (selectedCustomAttr.field === field) {
          selectedCustomAttr.data.push(brandCode);
        } else {
          selectedCustomAttr.field = field;
          selectedCustomAttr.data = [brandCode];
        }
      } else {
        const index = selectedCustomAttr.data.indexOf(brandCode);
        if (index > -1) {
          selectedCustomAttr.data.splice(index, 1);
        }
      }
    }
    setSelectedCustomAttrData({...selectedCustomAttr});
  };

  const BulkCustomAttributes = ({value, selectAll}: any) => {
    let isSelected = false;
    const checkboxes = document.querySelectorAll("input[clear='true']:checked");

    if (!selectAll && value.brand_codes?.length > 0) {
      brandsByPage = value.brand_codes;
    }

    if (checkboxes.length > 0) {
      selectedCustomAttr.data = [];
      selectedCustomAttr.field = null;
      checkboxes.forEach((i) => i.removeAttribute('clear'));
    }

    if (!selectAll) {
      if (selectedCustomAttr.field === value.label && selectedCustomAttr.data.includes(value.brand_code)) {
        isSelected = true;
      }
    }

    if (
      selectAll &&
      selectedCustomAttr.field === value.label &&
      brandsByPage.every((i) => selectedCustomAttr.data.includes(i))
    ) {
      isSelected = true;
    }

    return (
      <input
        className="column-checkbox"
        type="checkbox"
        id={selectAll ? value.label : `${value.label}-${value.brand_code}`}
        value={selectAll ? value.label : `${value.label}-${value.brand_code}`}
        checked={isSelected}
        onChange={(v) => {
          checkboxHandleChange(v, value.label, selectAll, brandsByPage, value.brand_code && value.brand_code);
          if (v.target.checked) {
            isSelected = true;
          } else {
            isSelected = false;
          }
        }}
      />
    );
  };

  const CustomEditComponent = ({props, currentValue}: any) => {
    const {id, value, field} = props;
    const apiRef = useGridApiContext();
    const handleValueChange = (event) => {
      const v = event.target.value;
      apiRef.current.setEditCellValue({id, field, value: v});
    };
    const handleRef = (element) => {
      if (element) {
        const input = element.querySelector(`input[value="${value}"]`);
        input?.focus();
      }
    };

    return (
      <TextField
        className="custom-textfield"
        ref={handleRef}
        onChange={handleValueChange}
        onBlurCapture={() => {
          if (value !== currentValue) {
            selectedCustomAttr = {data: [], field: null};
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && value !== currentValue) {
            selectedCustomAttr = {data: [], field: null};
          }
        }}
        value={value}
        variant="outlined"
      />
    );
  };

  const SelectInputCell = ({props, optionsArray, currentItem, required}: any) => {
    const {id, value, field} = props;
    const apiRef = useGridApiContext();

    const handleChange = (event) => {
      apiRef.current.setEditCellValue({id, field, value: event});
    };

    const handleRef = (element) => {
      if (element) {
        const input = element.querySelector(`input[value="${value}"]`);
        input?.focus();
      }
    };

    return (
      <TableSelectInput
        ref={handleRef}
        required={required}
        onBlurCapture={() => {
          selectedCustomAttr = {data: [], field: null};
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            selectedCustomAttr = {data: [], field: null};
          }
        }}
        value={currentItem?.id ? currentItem.id : currentItem?.brand_code}
        renderValue={
          currentItem?.name ? currentItem.name : currentItem?.email ? currentItem.email : currentItem?.brand_code
        }
        onChange={handleChange}
        options={optionsArray?.map((item) => (
          <MenuItem key={item.id ? item.id : item?.brand_code} value={item.id ? item.id : item?.brand_code}>
            {item.name ? item.name : item.email ? item.email : item?.brand_code}
          </MenuItem>
        ))}
      />
    );
  };

  const newColumns: GridColDef[] = Object.entries(tableFields)
    .map(([key, value]: any) => {
      let component = null;
      if (value.type === 'select') {
        component = {
          field: key,
          headerName: value.label,
          flex: 1,
          minWidth: 280,
          editable: true,
          renderHeader: (params) => {
            return (
              <div style={{display: 'flex', alignItems: 'center'}}>
                {value.custom_attribute && <BulkCustomAttributes value={{label: params.colDef.headerName}} selectAll />}
                {value.label}
              </div>
            );
          },
          filterable: !value.custom_attribute,
          sortable: !value.custom_attribute,
          renderCell: (params) => {
            const optionsArray = getFieldOptions(value.options);
            const values = {
              ...value,
              brand_code: params.row.brand_code,
              brand_codes: params.row.brand_codes,
            };
            const currentItem = optionsArray?.find((item) => {
              if (value.options === 'brands') {
                return item.brand_code === params.row[key];
              } else {
                return item.email === params.row[key] || item.id === params.row[key] || item.name === params.row[key];
              }
            });

            return (
              <>
                {value.custom_attribute && <BulkCustomAttributes value={values} />}
                <TableSelectInput
                  readOnly
                  copyToClipboard={() => copyToClipboard(key, params.row[key])}
                  value={currentItem?.id ? currentItem.id : currentItem?.brand_code}
                  loading={brandUtilsLoading}
                  renderValue={
                    currentItem?.name
                      ? currentItem.name
                      : currentItem?.email
                      ? currentItem.email
                      : currentItem?.brand_code
                  }
                />
              </>
            );
          },
          renderEditCell: (params: any) => {
            const optionsArray = getFieldOptions(value.options);
            const currentItem = optionsArray?.find((item) => {
              if (value.options === 'brands') {
                return item.brand_code === params.row[key];
              } else {
                return item.email === params.row[key] || item.id === params.row[key] || item.name === params.row[key];
              }
            });

            return (
              <SelectInputCell
                props={params}
                optionsArray={optionsArray}
                currentItem={currentItem}
                required={value.required}
              />
            );
          },
        };
      } else if (value.type === 'text' || value.type === 'number') {
        if (value.customURL) {
          component = {
            field: key,
            headerName: value.label,
            flex: 1,
            minWidth: 280,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
              let url = '';
              switch (value.customURL) {
                case 'amazonListings':
                  url = `/amazon-listings-items?brands=${params.row.brand_code}`;
                  break;
                case 'alerts':
                  url = `/alerts?brands=${params.row.brand_code}`;
                  break;
              }
              const site = window.location.origin;
              return (
                <a target="_blank" rel="noreferrer" href={`${site}${url}`} style={{color: '#3c8dc7'}}>
                  {`${site}${url}`}
                </a>
              );
            },
          };
        } else if (value.modal) {
          component = {
            field: key,
            headerName: value.label,
            flex: 1,
            minWidth: 200,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
              if (value.customComponent) {
                const checklist = params.row.performance_checklist;
                if (
                  value.customComponent === 'PerformanceChecklist' &&
                  checklist &&
                  Object.keys(checklist)?.length > 0
                ) {
                  const newData = Object.entries(checklist).map(([key, value]: any) => {
                    return {
                      label: key,
                      value: value?.status,
                    };
                  });
                  return (
                    <Grid
                      style={{cursor: 'pointer'}}
                      onClick={() => setSelectedBrand({...params.row, performance_checklist: newData})}
                    >
                      {checkListComponent(newData)}
                    </Grid>
                  );
                } else {
                  return <Typography variant="body2">N/A</Typography>;
                }
              } else {
                return (
                  <MuiLink style={{cursor: 'pointer'}} onClick={() => setSelectedBrand(params.row)}>
                    {value.buttonText}
                  </MuiLink>
                );
              }
            },
          };
        } else {
          component = {
            field: key,
            headerName: value.label,
            flex: 1,
            minWidth: 250,
            filterable: !value.custom_attribute,
            renderHeader: (params) => {
              return (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  {value.custom_attribute && (
                    <BulkCustomAttributes value={{label: params.colDef.headerName}} selectAll />
                  )}
                  {value.label}
                </div>
              );
            },
            sortable: !value.custom_attribute,
            editable: !value.noEditable,
            renderCell: (params) => {
              const values = {
                ...value,
                brand_code: params.row.brand_code,
                brand_codes: params.row.brand_codes,
              };
              const newValue = params.row[key]?.toString().includes('https://') ? (
                <a target="_blank" rel="noopener noreferrer" style={{color: '#3c8dc7'}} href={params.row[key]}>
                  {params.row[key]}
                </a>
              ) : (
                params.row[key]
              );
              return !value.noEditable ? (
                <Tooltip title="Double Click to Edit">
                  <span style={{width: '100%', height: '100%'}}>
                    {value.custom_attribute && <BulkCustomAttributes value={values} />}
                    {params.row[key] ? newValue : 'N/A'}
                    {putIcon(key, params.row[key])}
                  </span>
                </Tooltip>
              ) : params.row[key] ? (
                newValue
              ) : (
                'N/A'
              );
            },
            renderEditCell: (params: any) => {
              return <CustomEditComponent props={params} currentValue={params.row[key]} />;
            },
          };
        }
      } else if (value.type === 'date') {
        component = {
          field: key,
          headerName: value.label,
          flex: 1,
          type: value.type,
          minWidth: 250,
          editable: true,
          renderCell: (params) => {
            return (
              <Tooltip title="Double Click to Edit">
                <span style={{width: '100%', height: '100%'}}>
                  <em>{params.row[key] ? moment(params.row[key]).format('YYYY-MM-DD') : 'YYYY-MM-DD'}</em>
                  {putIcon(key, params.row[key])}
                </span>
              </Tooltip>
            );
          },
        };
      } else if (value.type === 'actions') {
        component = {
          field: key,
          headerName: value.label,
          flex: 1,
          align: 'right',
          type: 'actions',
          cellClassName: 'actions-cell',
          hide: true,
          minWidth: 200,
          sortable: false,
          filterable: false,
          renderCell: (params: GridCellParams) => (
            <>
              <Link to={`/brands/${params.row.brand_code}/detail`}>
                <IconButton>
                  <Visibility />
                </IconButton>
              </Link>
              <Link to={`/brands/update/${params.row.brand_code}`}>
                <IconButton>
                  <Edit />
                </IconButton>
              </Link>
            </>
          ),
        };
      } else {
        if (value.type !== 'image') {
          component = {
            field: key,
            headerName: value.label,
            flex: 1,
            type: value.type,
            minWidth: 150,
            filterable: false,
            editable: true,
            renderCell: (params: GridCellParams) => (
              <Tooltip title="Double Click to Edit">
                <span style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
                  {params.row[key] ? <Check /> : <Close />}
                  {putIcon(key, params.row[key])}
                </span>
              </Tooltip>
            ),
          };
        }
      }

      return component;
    })
    .filter((item) => item !== null);

  const DataGridToolbar = () => (
    <GridToolbarContainer className="toolbar-container">
      <GridToolbarColumnsButton className={classes.toolbarButton} disabled={loadingData || tableLoading} />
      <DropDownButton buttonType="text" buttonText="Order Columns" bold loading={loadingData || tableLoading}>
        <DraggableList
          items={[...orderColumns]}
          ignoreItems={['actions']}
          setItems={(v) => {
            setOrderColumns(v);
            setUserPreferences(
              {
                columnVisibilityModel: visibilityModel,
                columnsOrder: v.map((x) => x.field),
              },
              'brands',
              setTableLoading
            );
            resetCheckboxes();
          }}
        />
      </DropDownButton>
      <GridPagination />
    </GridToolbarContainer>
  );

  const onModelVisibilityChange = (newModel: any) => {
    let data = {};
    resetCheckboxes();
    Object.entries(newModel).forEach(([x, v]) => {
      if (v === false) {
        data = {...data, [x]: v};
      }
    });
    const newOrder = getColumnsItems({
      list: newColumns,
      columnsVisibility: data,
      columnsOrder: orderColumns,
      currentOrder: orderColumns,
    });
    setVisibilityModel(data);
    setOrderColumns(newOrder);
    setUserPreferences(
      {
        columnVisibilityModel: data,
        columnsOrder: newOrder.map((x) => x.field),
      },
      'brands',
      setTableLoading
    );
  };

  const customButtons = [
    {
      icon: <Visibility />,
      url: (v) => `/brands/${v.brand_code}/detail`,
      tooltip: 'View',
    },
    {
      icon: <Edit />,
      url: (v) => `/brands/update/${v.brand_code}/edit`,
      tooltip: 'Edit',
    },
  ];

  useEffect(() => {
    const defaultQuery = getDefaultQuery(location, params);
    getBrandUtils();
    setSort([
      {
        field: defaultQuery.sortBy || 'brand_status',
        sort: defaultQuery.sortDirection || 'asc',
      },
    ]);
    setFilter({
      columnField: defaultQuery.filterBy,
      value: defaultQuery.filterValue,
    });
    setNameFilter(defaultQuery.filterValue || '');
    loadData(
      page,
      pageSize,
      {
        field: defaultQuery.sortBy || 'brand_status',
        sort: defaultQuery.sortDirection || 'asc',
      },
      {
        columnField: defaultQuery.filterBy,
        value: defaultQuery.filterValue,
      }
    );
  }, []);

  useEffect(() => {
    !brandUtilsLoading && makeColumnFreeze(thisRef, visibilityModel, rows);
  }, [visibilityModel, rows, brandUtilsLoading]);

  const checkListComponent = (data) => {
    return data.map((value, index) => {
      return (
        <Tooltip title={getLabel(value.label)} key={`${value.label}-${index}`}>
          <Circle style={{color: value.value === 'Good' ? 'green' : 'red', fontSize: '12px'}} />
        </Tooltip>
      );
    });
  };

  return (
    <TableContainer>
      <div style={{display: 'none'}}>
        <CustomColumn thisRef={thisRef} rows={rows} buttons={customButtons} />
      </div>
      <Grid item lg={12} style={{width: '100%'}}>
        <Grid container spacing={2} style={{paddingBottom: '.3em'}}>
          <Grid item sm={6}>
            <SearchBar
              placeholder="Search By Brand Name"
              value={nameFilter}
              onChange={(newValue) => setNameFilter(newValue)}
              onSearch={() => onSearch()}
            />
          </Grid>
          <Grid item sm={6} style={{textAlign: 'right'}}>
            {accountService.userValue.role === Role.Admin ? (
              <Link to="brands/create">
                <Button size="large">Create brand</Button>
              </Link>
            ) : (
              ''
            )}
            {accountService.userValue.brand_type === BrandTypesAlias.ffp && (
              <Link to="brands/create-sub-brand">
                <Button>Create sub brand</Button>
              </Link>
            )}
          </Grid>
        </Grid>

        <Grid item lg={12} position="relative">
          {(loadingData || tableLoading) && <LinearProgress style={{position: 'absolute', width: '100%'}} />}
          <DataGrid
            components={{
              Pagination: DataGridToolbar,
              Toolbar: DataGridToolbar,
            }}
            onCellEditCommit={(params) => {
              const field = rows.find((item) => item.id === params.id);
              const allowed = tableFields[params.field]?.type !== 'select';
              const currentValue = field[params.field];
              const columnField = tableFields[params.field];
              const customAttr = columnField.custom_attribute;
              const setNewValue = currentValue !== params.value;
              if (setNewValue) {
                if (customAttr) {
                  let value;
                  if (selectedCustomAttrData.field) {
                    value = !selectedCustomAttrData.data.includes(field.brand_code)
                      ? {...selectedCustomAttrData, data: [...selectedCustomAttrData.data, field.brand_code]}
                      : selectedCustomAttrData;
                  } else {
                    value = {field: columnField.label, data: [field.brand_code]};
                  }
                  updateCustomAttr(value, params.value, params.field);
                } else {
                  allowed && updateTable(field.brand_code, params.field, params.value);
                  !allowed &&
                    updateTable(field.brand_code, columnField.as ? columnField.as : params.field, params.value);
                }
              }
            }}
            className={`${classes.grid} custom-table `}
            autoHeight={true}
            pageSize={pageSize}
            page={page}
            rowCount={count}
            onPageChange={onPageChange}
            pagination
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            columnBuffer={1}
            columnThreshold={0}
            loading={loadingData || brandUtilsLoading || orderColumns.length === 0}
            onFilterModelChange={handleFilterModelChange}
            onPageSizeChange={handleChangePageSize}
            onSortModelChange={(params) => handleSortModelChange(params)}
            disableSelectionOnClick={true}
            rowsPerPageOptions={rowsPerPageOptions}
            rows={rows}
            columns={[...orderColumns, fixedPlaceHolder({})]}
            columnVisibilityModel={visibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              onModelVisibilityChange(newModel);
            }}
          />
        </Grid>

        <CustomAlert id="default-alert" />

        <ModalButton
          openModal={selectedBrand}
          hideButton
          closable
          modalTitle={`Performance Checklist for ${selectedBrand?.name || selectedBrand?.brand_code}`}
          onCloseAction={() => {
            setSelectedBrand(null);
          }}
        >
          {(closeModal) => {
            return (
              <List dense>
                <>
                  {selectedBrand?.performance_checklist?.length > 0 ? (
                    selectedBrand.performance_checklist?.map((value: any, index) => {
                      return (
                        <ListItem key={`${value.label}-${index}`}>
                          <ListItemText
                            primary={
                              <Typography variant="body2" style={{textTransform: 'capitalize'}}>
                                {getLabel(value.label)}:{' '}
                                <Chip
                                  size="small"
                                  label={value.value}
                                  style={{
                                    backgroundColor: value.value === 'Good' ? 'green' : 'red',
                                    color: 'white',
                                  }}
                                />
                              </Typography>
                            }
                          />
                        </ListItem>
                      );
                    })
                  ) : (
                    <Typography variant="h6" style={{textAlign: 'center', fontSize: '1.1rem'}}>
                      There are not data to show
                    </Typography>
                  )}
                </>
              </List>
            );
          }}
        </ModalButton>
      </Grid>
    </TableContainer>
  );
}

export {Brands};
