import {Button, ButtonGroup, CircularProgress, Grid, Paper, Typography} from '@mui/material';
import {useState} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import { alertService } from '../../../services/alert.service';
// utils
import {Api, errorAlert} from '../../../utils/api';
import { imageMaxFileSize } from '../../../utils/constants';

const fileTypes = ['JPG'];

const ImageUploader: React.FC<any> = ({setImage}) => {
  const handleChange = (file: any) => {
    setImage(file);
  };

  return <FileUploader multiple={false} handleChange={handleChange} name="file" types={fileTypes} label={' '} maxSize={imageMaxFileSize} onSizeError={()=>{alertService.error(`File size should be less than ${imageMaxFileSize} MB.`)}}/>;
};

export default function ImageCard(props) {
  const [isUploading, setIsUploading] = useState(false);
  const {disabledDirection, label, link, handleChangeImagesArray, onMove, disabled} = props;

  async function uploadImage(file: any) {
    try {
    setIsUploading(true);
    const formData = new FormData();
    formData.append('image', file);
      const {data} = await Api.put(`amazon-listings/uploadImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      handleChangeImagesArray(data);
    } catch (e) {
      errorAlert('Error uploading image', e);
    } finally {
      setIsUploading(false);
    }
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} style={{padding: '20px 5px'}}>
      {link ? (
        <Paper
          elevation={6}
          style={{backgroundImage: `url(${link})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}
        >
          {!disabled && (
            <ButtonGroup variant="contained" aria-label="outlined primary button group" fullWidth>
              <Button color="primary" onClick={() => onMove(-1)} disabled={disabledDirection === 'left'}>
                Left
              </Button>
              <Button color="primary" onClick={() => onMove(1)} disabled={disabledDirection === 'right'}>
                Right
              </Button>
              <Button color="primary" onClick={() => handleChangeImagesArray('')}>
                Delete
              </Button>
            </ButtonGroup>
          )}
        </Paper>
      ) : (
        <Paper elevation={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          {disabled ? (
            <Typography>Disabled</Typography>
          ) : isUploading ? (
            <CircularProgress />
          ) : (
            <ImageUploader setImage={(file: any) => uploadImage(file)} />
          )}
        </Paper>
      )}
      <Typography color="secondary" fontSize={16} lineHeight={1.5} textAlign={'center'}>
        {label.replace('_product_image_locator', '')}
      </Typography>
    </Grid>
  );
}
