import {useEffect, useState} from 'react';
import {Grid, Paper, Typography, Autocomplete, TextField} from '@mui/material';
import {useLocation} from 'react-router-dom';
import SummarizeIcon from '@mui/icons-material/Summarize';
import moment from 'moment';
import {alertService, AlertType} from '../../services/alert.service';
// styles
import {useStyles} from '../Performance/Styles';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import DateRangeButtons from '../../components/DateRangeButtons/DateRangeButtons';
import Button from '../../components/Button/Button';
import {CustomAlert} from '../Login/components/CustomAlert';
import {CsvExportButton} from './components/CsvExportButton';
// utils
import {Api} from '../../utils/api';
import {updateQueryParams, getDefaultQuery} from '../../utils/urlParams';

const reports = [
  {reportsType: 'amazon_products', reportFileName: `Amazon Products`},
  {reportsType: 'amazon_order_products', reportFileName: `Amazon Order Products`},
  {reportsType: 'amazon_listings', reportFileName: 'Amazon Listing Defect'},
  {reportsType: 'fba_returns', reportFileName: 'Fba Returns'},
  {reportsType: 'amazon_fba_reimbursements', reportFileName: 'Amazon Fba Reimbursements'},
  {reportsType: 'amazon_stranded_inventory', reportFileName: 'Amazon Stranded Inventory'},
  {reportsType: 'amazon_fba_aged_inventory', reportFileName: 'Amazon Fba Aged Inventory'},
  {reportsType: 'amazon_fees_estimate', reportFileName: 'Amazon Asin Fees'},
  {reportsType: 'amazon_settlements', reportFileName: `Amazon Settlements`},
  {reportsType: 'advertising_sp_campaigns', reportFileName: `Advertising SP Campaigns`},
  {reportsType: 'advertising_sp_adGroups', reportFileName: `Advertising SP AdGroups`},
  {reportsType: 'advertising_sp_productAds', reportFileName: `Advertising SP Product Ads`},
  {reportsType: 'advertising_sp_asins', reportFileName: `Advertising SP Asins`},
  {reportsType: 'advertising_sp_keywords', reportFileName: `Advertising SP Keywords`},
  {reportsType: 'advertising_sb_campaigns', reportFileName: `Advertising SB Campaigns`},
  {reportsType: 'advertising_sb_adGroups', reportFileName: `Advertising SB AdGroups`},
  {reportsType: 'advertising_sb_keywords', reportFileName: `Advertising SB Keywords`},
  {reportsType: 'amazon_orders', reportFileName: `Amazon Orders`},
  {
    reportsType: 'fulfilled_shipments',
    reportFileName: `Fulfilled Shipments`,
  },
  {
    reportsType: 'attribution_performance',
    reportFileName: `Attribution Performance`,
  },
  {reportsType: 'dsp_audience', reportFileName: `Dsp Audience`},
  {reportsType: 'dsp_campaigns', reportFileName: `Dsp Campaigns`},
  {reportsType: 'dsp_geography', reportFileName: `Dsp Geography`},
  {reportsType: 'dsp_inventory', reportFileName: `Dsp Inventory`},
  {reportsType: 'dsp_products', reportFileName: `Dsp Products`},
  {reportsType: 'dsp_technology', reportFileName: `Dsp Technology`},
  {
    reportsType: 'amazon_settlements_items',
    reportFileName: `Amazon Settlements Items`,
  },
  {
    reportsType: 'fba_recommended_removal_data',
    reportFileName: `Fba Recommended Removal Data`,
  },
  {
    reportsType: 'fba_fulfillment_removal_order_detail_data',
    reportFileName: `Fba Fulfillment Removal Order Data`,
  },
  {
    reportsType: 'fba_fulfillment_removal_shipment_detail_data',
    reportFileName: `Fba Fulfillment Removal Shipment Data`,
  },
  {
    reportsType: 'monthly_inventory',
    reportFileName: `Monthly Inventory`,
  },
  {
    reportsType: 'amazon_payments',
    reportFileName: `Amazon Payments`,
  },
  {
    reportsType: 'coupon_performance',
    reportFileName: `Coupon Performance`,
  },
  {
    reportsType: 'promotion_performance',
    reportFileName: `Promotion Performance`,
  },
  {
    reportsType: 'sales_and_traffic_by_asin',
    reportFileName: `Sales And Traffic By ASIN`,
  },
];
interface AlertObj {
  type: string;
  message: string;
}

const apiUrl = 'reports/agency/';

function AgencyReports() {
  document.title = 'Stonehenge.ai/Reports';
  const location = useLocation();
  const classes = useStyles();
  const [fromTo, setFromTo] = useState({
    from: null,
    to: null,
  });
  const [buttons, setButtons] = useState([]);
  const [alertObj, setAlertObj] = useState(null);
  const [period, setPeriod] = useState('');

  useEffect(() => {
    if (alertObj?.message?.length > 0) {
      switch (alertObj?.type) {
        case AlertType.Success:
          alertService.success(alertObj.message);
          break;
        case AlertType.Warning:
          alertService.warn(alertObj.message);
          break;
        case AlertType.Info:
          alertService.info(alertObj.message);
          break;
        case AlertType.Error:
          alertService.error(alertObj.message);
          break;
        default:
          break;
      }
    }
  }, [alertObj]);

  async function downloadReports() {
    try {
      const res = await Api.post(`reports/download-agency-reports`, {
        from: fromTo.from,
        to: fromTo.to,
        reportsTypes: buttons.map((report) => report.reportsType),
      });
      switch (res.status) {
        case 200:
          setAlertObj({type: AlertType.Success, message: 'Report generation in progress, please check your email'});
          break;
        case 202:
          setAlertObj({type: AlertType.Info, message: res.data});
          break;
        case 204:
          setAlertObj({type: AlertType.Warning, message: 'No data!'});
          break;
        default:
          setAlertObj({type: AlertType.Info, message: res.data?.message});
          break;
      }
    } catch (err: any) {
      if (err.response.status === 401) {
        setAlertObj({
          type: AlertType.Error,
          message: 'You are not authorized to download reports',
        });
      } else {
        setAlertObj({
          type: AlertType.Error,
          message: 'Error downloading reports',
        });
      }
    }
  }

  const params = {
    from: fromTo.from,
    to: fromTo.to,
    reports: buttons.map((report) => report.reportsType),
  };

  useEffect(() => {
    updateQueryParams(location, params);
  }, [fromTo, buttons]);

  useEffect(() => {
    const defaultQuery = getDefaultQuery(location, params, {
      to: moment().format('YYYY-MM-DD'),
      from: moment().format('YYYY-MM-DD'),
      preset: 'custom',
    });
    setFromTo({
      from: defaultQuery.from || fromTo.from,
      to: defaultQuery.to || fromTo.to,
    });
    setPeriod(defaultQuery.preset || period);
    const getReports = reports?.filter((report) =>
      defaultQuery.reports
        ?.split(',')
        ?.map((reportType) => reportType)
        .includes(report.reportsType)
    );
    setButtons(getReports || buttons);
  }, []);

  return (
    <TableContainer style={{paddingTop: 30}}>
      <DateRangeButtons
        fromTo={fromTo}
        period={period}
        setFromTo={(v) => setFromTo(v)}
        setPeriod={(v) => setPeriod(v)}
        pageTitle="Agency Reports"
      />
      <Grid container spacing={1}>
        <Grid item lg={5} md={8} sm={9} xs={12}>
          <Autocomplete
            multiple
            size="small"
            options={reports}
            getOptionLabel={(option) => option.reportFileName}
            value={buttons}
            isOptionEqualToValue={(option, value) => option.reportsType === value.reportsType}
            onChange={(event: object, value: any) => {
              setButtons(value);
              value.length === 0 && setButtons([]);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Select Report Type" placeholder="Report Type" />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} style={{padding: '20px 0'}}>
          <Paper variant={'outlined'} className={`${classes.chartContainer} reports`}>
            {buttons.length > 1 && (
              <Grid container display="flex">
                <Button size="small" onClick={() => downloadReports()}>
                  Download All
                </Button>
              </Grid>
            )}
            <Grid container spacing={1}>
              {buttons.length > 0 ? (
                buttons?.map((item: any) => (
                  <Grid item lg={6} md={6} sm={12} xs={12} key={item.reportsType}>
                    <CsvExportButton
                      apiUrl={apiUrl + item.reportsType}
                      reportFileName={item.reportFileName}
                      fromTo={fromTo || null}
                      handleAlert={(alert: AlertObj) => setAlertObj(alert)}
                    ></CsvExportButton>
                  </Grid>
                ))
              ) : (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <SummarizeIcon className="default-icon" />
                  <Typography variant="h6" color="textSecondary">
                    No reports generated
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CustomAlert id="default-alert" />
      </Grid>
    </TableContainer>
  );
}

export {AgencyReports};
