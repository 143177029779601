import {useEffect, useState} from 'react';
import {Typography, Grid} from '@mui/material';
import {useHistory, useParams} from 'react-router-dom';
import {DataGrid, getGridStringOperators, GridCellParams, GridColDef, GridColTypeDef} from '@mui/x-data-grid';
import moment from 'moment';
// services
import {accountService} from '../../../../services/account.service';
import {alertService} from '../../../../services/alert.service';
// utils
import {Api, errorAlert} from '../../../../utils/api';
// components
import TableContainer from '../../../../components/TableContainer/TableContainer';
import {CustomAlert} from '../../../Login/components/CustomAlert';
import {MenuStore} from '../../../../App';
import {buildMenu} from '../../components/menu';

const stringColumnType: GridColTypeDef = {
  extendType: 'string',
  filterOperators: getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => {
      return {
        ...operator,
      };
    }),
};

const columns: GridColDef[] = [
  {
    field: 'seller_sku',
    headerName: 'SKU',
    flex: 0.15,
    sortable: false,
    filterable: false,
  },
  {
    field: 'fnsku',
    headerName: 'FNSKU',
    flex: 0.15,
    sortable: false,
    filterable: false,
  },
  {
    field: 'product_name',
    headerName: 'Name',
    flex: 0.15,
    sortable: false,
    filterable: false,
  },
  {
    field: 'country',
    headerName: 'Country',
    flex: 0.1,
    sortable: false,
    filterable: false,
  },
  {
    field: 'fulfillment_center_id',
    headerName: 'Fulfullment Center',
    flex: 0.15,
    sortable: false,
    filterable: false,
  },
  {
    field: 'detailed_disposition',
    headerName: 'Detailed Disposition',
    flex: 0.25,
    sortable: false,
    filterable: false,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    flex: 0.1,
    sortable: false,
    filterable: false,
  },
  {
    field: 'date',
    headerName: 'Date',
    flex: 0.25,
    sortable: false,
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <Typography variant="subtitle1">{moment(params.row.date).utc().format('M/d/y HH:mm')}</Typography>
    ),
  },
];

function AsinInventory(props) {
  const firstMonth = '2021-04-01';
  let history = useHistory();

  const {id} = useParams<Record<string, string | undefined>>();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState<number>(0);
  const [sort, setSort] = useState({field: 'month', sort: 'DESC'});
  const [filter, setFilter] = useState(null);

  const menuItems = buildMenu('inventory', id, history);

  var dateStart = moment(new Date(firstMonth));
  var dateEnd = moment(new Date());
  var timeValues = [];

  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    timeValues.push(dateStart.format('YYYY-MM'));
    dateStart.add(1, 'month');
  }

  useEffect(() => {
    MenuStore.update((s) => {
      s.menuItems = menuItems;
    });
    loadData(page, pageSize, sort, {});
    return () => {
      MenuStore.update((s) => {
        s.menuItems = null;
      });
    };
  }, []);

  function onPageChange(params) {
    loadData(params.page, pageSize, sort, filter);
    setPage(params.page);
  }

  async function loadData(page, pageSize, sortModel, filterModel) {
    try {
      const {data} = await Api.get(`asin-inventory/${id}`, {
        params: {
          page: page,
          pageSize: pageSize,
          sortField: sortModel ? sortModel.field : null,
          sortOrder: sortModel ? sortModel.sort : null,
          filterField: filterModel ? filterModel.columnField : null,
          filterValue: filterModel ? filterModel.value : null,
        },
      });
      setTotal(data.count);
      setRows(
        data.rows.map((item, index) => {
          return {
            ...item,
            id: index,
          };
        })
      );
    } catch (e: any) {
      errorAlert('Unable to get inventory data', e);
    }
  }

  function handleSortModelChange(params) {
    loadData(page, pageSize, params.sortModel[0], filter);
    setSort(params.sortModel[0]);
  }

  function handleFilterModelChange(params) {
    loadData(page, pageSize, sort, params.filterModel.items[0]);
    setFilter(params.filterModel.items[0]);
  }

  return (
    <TableContainer>
      <Grid item xs={12} style={{paddingBottom: 30}}>
        <Grid container spacing={1}>
          <Grid item lg={12} style={{width: '100%'}}>
            <DataGrid
              className="custom-table"
              autoHeight={true}
              pageSize={pageSize}
              page={page}
              rowCount={total}
              columnTypes={{filterString: stringColumnType}}
              onPageChange={onPageChange}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              onSortModelChange={handleSortModelChange}
              disableSelectionOnClick={true}
              rows={rows}
              columns={columns}
            />
            <CustomAlert id="default-alert" />
          </Grid>
        </Grid>
      </Grid>
    </TableContainer>
  );
}

export {AsinInventory};
