import {useEffect, useState, useContext} from 'react';
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  LinearProgress,
  useMediaQuery,
  Box,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {Square, ArrowRightAlt} from '@mui/icons-material';
import {DataGrid, GridToolbarContainer, GridPagination, GridToolbarColumnsButton} from '@mui/x-data-grid';
import {useTheme} from '@mui/material/styles';
import {useLocation} from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import {CSVLink} from 'react-csv';
// components
import TableContainer from '../../components/TableContainer/TableContainer';
import DateRangeButtons from '../../components/DateRangeButtons/DateRangeButtons';
import PieChart, {getAmountDiff} from './components/pieChart';
import ComposedChart from './components/composedChart';
import {BreadcrumbsComponent, columns} from './components/components';
import DropDownButton from '../../components/DropdownButton/DropDownButton';
import DraggableList from '../../components/DraggableList/DraggableList';
import Button from '../../components/Button/Button';
import OpenModalButton from '../../components/ModalButton/ModalButton';
import SalesCard from './components/salesCard';
import GeoMap from './components/geomap';
import Heatmap from './components/heatmap';
import {CustomAlert} from '../Login/components/CustomAlert';
import Spacer from '../../components/Spacer/Spacer';
// services
import mixPanel from '../../services/mixpanel';
import {accountService} from '../../services/account.service';
// utils
import {onFromToChange} from '../../utils/OnFromToChange';
import {setUserPreferences, getUserPreferences, getColumnsItems} from '../../utils/tableSettings';
import {updateQueryParams, getDefaultQuery} from '../../utils/urlParams';
import {getAccountManagers} from '../../utils/getSelectedAccountManager';
import {
  transformReturnRateData,
  transformPurchaserData,
  transformPurchaserBrandData,
  transformSummaryData,
  transformGeoData,
  transformHeatmapData,
  transformHeatmapBrandData,
  transformFBAData,
  transformFBACountData,
  transformAverageOrderBrandData,
  transformAverageOrderSkuData,
  transformSubsAndSaveData,
  transformSubsAndSaveBrandData,
  transformSubsAndSaveBrandChartData,
  transformPromotionCodesData,
  transformPromotionCodesBrandData,
  transformPromotionCodesSkuData,
} from './utils/transformData';
import {InsightsApiCall} from './utils/ApiCalls';
import getLabel from '../../utils/getLabel';
import {Role} from '../../utils/role';
import {errorAlert} from '../../utils/api';
// styles
import {useStyles} from '../Performance/Styles';
// context
import {AppContext} from '../../context/AppContext/AppContext';

export default function Insights() {
  document.title = 'Stonehenge.ai/Performance - Insights';
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const rowsPerPageOptions = [10, 25, 40, 60];
  const [checked, setChecked] = useState(false);
  const [dateRange, setDateRange] = useState('');
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [fromTo, setFromTo] = useState({from: null, to: null, compareFrom: null, compareTo: null});
  const [compare, setCompare] = useState(false);
  const [period, setPeriod] = useState(null);
  const [selectedAM, setSelectedAM] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  // purchaser
  const [selectedPurchaserBrand, setSelectedPurchaserBrand] = useState(null);
  const [purchaserBrandsData, setPurchaserBrandsData] = useState([]);
  const [purchasers, setPurchasers] = useState([]);
  const [selectedPurchaserSKU, setSelectedPurchaserSKU] = useState(null);
  const [purchaserSKUData, setPurchaserSKUData] = useState([]);
  const [purchaserBrandLoading, setPurchaserBrandLoading] = useState(false);
  const [purchaserLoading, setPurchaserLoading] = useState(false);
  // fba returns
  const [fbaLoading, setFbaLoading] = useState(false);
  const [selectedFbaReturnBy, setSelectedFbaReturnBy] = useState({
    reason: null,
    disposition: null,
  });
  const [fbaChartData, setFbaChartData] = useState({
    reason: [],
    disposition: [],
  });
  const [reasonChartData, setReasonChartData] = useState([]);
  const [dispositionChartData, setDispositionChartData] = useState([]);
  const [fbaChartLoading, setFbaChartLoading] = useState({
    reason: false,
    disposition: false,
  });
  const [selectedFbaSKUData, setSelectedFbaSKUData] = useState([]);
  const [selectedFbaSKU, setSelectedFbaSKU] = useState<any>(null);
  const [fbaSkuLoading, setFbaSkuLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [orderColumns, setOrderColumns] = useState([]);
  const [visibilityModel, setVisibilityModel] = useState<any>({});
  const [tableLoading, setTableLoading] = useState(false);
  // average order
  const [averageOrder, setAverageOrder] = useState([]);
  const [averageOrderLoading, setAverageOrderLoading] = useState(false);
  const [averageBrands, setAverageBrands] = useState([]);
  const [selectedAverageBrand, setSelectedAverageBrand] = useState(null);
  const [selectedAverageBrandData, setSelectedAverageBrandData] = useState([]);
  const [averageBrandLoading, setAverageBrandLoading] = useState(false);
  // return rate
  const [returnRate, setReturnRate] = useState([]);
  const [returnRateLoading, setReturnRateLoading] = useState(false);
  const [returnRateBrands, setReturnRateBrands] = useState({
    returnRate: [],
    returnRateChartData: [],
  });
  const [selectedReturnRateBrand, setSelectedReturnRateBrand] = useState(null);
  const [selectedReturnRateBrandData, setSelectedReturnRateBrandData] = useState<any>({
    returnRate: [],
    returnRateChartData: [],
  });
  const [returnRateBrandLoading, setReturnRateBrandLoading] = useState(false);
  // summaries
  const [summaries, setSummaries] = useState([]);
  const [summaryBrands, setSummaryBrands] = useState([]);
  const [selectedSummaryBrand, setSelectedSummaryBrand] = useState(null);
  const [summarySkus, setSummarySkus] = useState([]);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [summarySkuLoading, setSummarySkuLoading] = useState(false);
  // geo map
  const [totalGeoData, setTotalGeoData] = useState([]);
  const [geoMapLoading, setGeoMapLoading] = useState(false);
  const [geoDataByBrands, setGeoDataByBrands] = useState([]);
  const [geoMapDataBySkus, setGeoMapDataBySkus] = useState([]);
  const [geoSkuLoading, setGeoSkuLoading] = useState(false);
  const [selectedGeoBrand, setSelectedGeoBrand] = useState(null);
  const [selectedGeoSku, setSelectedGeoSku] = useState(null);
  // heatmap
  const [totalHeatmap, setTotalHeatmap] = useState({});
  const [heatmapDataByBrands, setHeatmapDataByBrands] = useState([]);
  const [heatmapLoading, setHeatmapLoading] = useState(false);
  const [selectedHeatmapBrand, setSelectedHeatmapBrand] = useState(null);
  const [selectedHeatmapSku, setSelectedHeatmapSku] = useState(null);
  const [heatmapDataBySkus, setHeatmapDataBySkus] = useState([]);
  const [heatmapSkuLoading, setHeatmapSkuLoading] = useState(false);
  // subscribe and save
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [subscriptionBrands, setSubscriptionBrands] = useState([]);
  const [selectedSubscriptionBrand, setSelectedSubscriptionBrand] = useState(null);
  const [subscriptionSkus, setSubscriptionSkus] = useState([]);
  const [subscriptionSkuLoading, setSubscriptionSkuLoading] = useState(false);
  const [subscriptionBrandChartData, setSubscriptionBrandChartData] = useState([]);
  const [subscriptionSkuChartData, setSubscriptionSkuChartData] = useState([]);
  // promo codes
  const [promoCodes, setPromoCodes] = useState([]);
  const [promoCodesChartData, setPromoCodesChartData] = useState([]);
  const [totalPromoCodesChartData, setTotalPromoCodesChartData] = useState([]);
  const [promoCodesLoading, setPromoCodesLoading] = useState(false);
  const [promoCodesBrands, setPromoCodesBrands] = useState([]);
  const [selectedPromoCodesBrand, setSelectedPromoCodesBrand] = useState(null);
  const [selectedPromoCodesBrandData, setSelectedPromoCodesBrandData] = useState<any>(null);
  const [selectedPromoCodesSku, setSelectedPromoCodesSku] = useState(null);
  const [selectedPromoCodesSkuData, setSelectedPromoCodesSkuData] = useState<any>();
  const [promoCodesSkus, setPromoCodesSkus] = useState([]);
  const [promoCodesSkuLoading, setPromoCodesSkuLoading] = useState(false);

  const {brands, accountManagers} = useContext(AppContext);

  const allLoading =
    fbaLoading ||
    fbaChartLoading.reason ||
    fbaChartLoading.disposition ||
    fbaSkuLoading ||
    purchaserBrandLoading ||
    purchaserLoading ||
    averageOrderLoading ||
    averageBrandLoading ||
    returnRateLoading ||
    returnRateBrandLoading ||
    summaryLoading ||
    summarySkuLoading ||
    geoMapLoading ||
    geoSkuLoading ||
    heatmapLoading ||
    heatmapSkuLoading ||
    subscriptionLoading ||
    subscriptionSkuLoading ||
    promoCodesLoading ||
    promoCodesSkuLoading;

  const params = {
    skuReason: selectedFbaSKU?.reason,
    skuDisposition: selectedFbaSKU?.disposition,
    reason: selectedFbaReturnBy.reason,
    disposition: selectedFbaReturnBy.disposition,
    from: fromTo.from,
    to: fromTo.to,
    RP_brand: selectedPurchaserBrand?.value,
    RP_sku: selectedPurchaserSKU,
    compareFrom: fromTo.compareFrom,
    compareTo: fromTo.compareTo,
    am: selectedAM?.map((item) => item.email).join(','),
    brands: selectedBrands.toString(),
    average_brand: selectedAverageBrand?.value,
    return_rate_brand: selectedReturnRateBrand?.value,
    summaryBrand: selectedSummaryBrand?.value,
    geoBrand: selectedGeoBrand?.value,
    geoSku: selectedGeoSku,
    heatmapBrand: selectedHeatmapBrand?.value,
    heatmapSku: selectedHeatmapSku,
    subsBrand: selectedSubscriptionBrand,
    promoCodesBrand: selectedPromoCodesBrand,
    promoCodesSku: selectedPromoCodesSku,
  };

  useEffect(() => {
    async function getTableData() {
      const data = await getUserPreferences({
        list: columns,
        tableName: 'insights-sku',
        visibilityModel: visibilityModel,
        defaultVisibilityModel: {},
        loading: setTableLoading,
      });
      if (data) {
        setOrderColumns(data.columns);
        setVisibilityModel(data.visibility);
      }
    }
    getTableData();
  }, []);

  async function getFBAReturns(query) {
    setFbaLoading(true);
    try {
      const {data: reasonData} = await InsightsApiCall({
        url: '/fba-returns',
        data: {...query, returnBy: 'reason'},
      });
      try {
        const {data: dispositionData} = await InsightsApiCall({
          url: '/fba-returns',
          data: {...query, returnBy: 'disposition'},
        });
        setFbaChartData({
          reason: transformFBAData(reasonData),
          disposition: transformFBAData(dispositionData),
        });
      } catch (e) {
        errorAlert('Unable to get FBA by Disposition data', e);
      } finally {
        setFbaLoading(false);
      }
    } catch (e) {
      setFbaLoading(false);
      errorAlert('Unable to get FBA by Reason data', e);
    }
  }

  async function getFBACount(query) {
    setFbaChartLoading((prev) => ({...prev, [query.returnBy]: true}));
    try {
      const {data} = await InsightsApiCall({
        url: '/fba-returns/count',
        data: query,
      });
      if (query.returnBy === 'reason') {
        setReasonChartData(transformFBACountData(data));
      } else {
        setDispositionChartData(transformFBACountData(data));
      }
    } catch (e) {
      errorAlert('Unable to get FBA Count data', e);
    } finally {
      setFbaChartLoading((prev) => ({...prev, [query.returnBy]: false}));
    }
  }

  async function getFBABySku(query) {
    setFbaSkuLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/fba-returns/sku',
        data: query,
        sku: true,
      });
      setSelectedFbaSKUData(
        data.map((x, i) => {
          return {id: i, ...x};
        })
      );
    } catch (e) {
      errorAlert('Unable to get FBA by SKU data!', e);
    } finally {
      setFbaSkuLoading(false);
    }
  }

  async function getSummary(query, currentBrand) {
    setSummaryLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/summary',
        data: query,
      });
      setSummaries(transformSummaryData(data));
      try {
        const {data} = await InsightsApiCall({
          url: '/summary/brand',
          data: query,
        });
        if (currentBrand) {
          const getBrand = data.find((item) => item.brand_code === currentBrand);
          setSelectedSummaryBrand({name: getBrand?.brand_name, value: getBrand?.brand_code});
        }
        setSummaryBrands(transformSummaryData(data));
      } catch (e) {
        errorAlert('Unable to get Summary Brand data', e);
      } finally {
        setSummaryLoading(false);
      }
    } catch (e) {
      setSummaryLoading(false);
      errorAlert('Unable to get Summary data', e);
    }
  }

  async function getSummarySku(brand, query) {
    setSummarySkuLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/summary/sku',
        data: {...query, brand},
        sku: true,
      });
      setSummarySkus(transformSummaryData(data));
    } catch (e) {
      errorAlert('Unable to get Summary SKU Brand data', e);
    } finally {
      setSummarySkuLoading(false);
    }
  }

  async function getAverageOrder(query, currentBrand) {
    setAverageOrderLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/average-order',
        data: query,
      });
      setAverageOrder([
        {
          metric: 'Average Order',
          value: parseFloat(data[0].average_order),
          old_value: data[0].old_average_order ? parseFloat(data[0].old_average_order) : 0,
          invertedcolor: true,
        },
      ]);
      try {
        const {data} = await InsightsApiCall({
          url: '/average-order/brand',
          data: query,
        });
        const newData = transformAverageOrderBrandData(data);
        setAverageBrands(newData);
        if (currentBrand) {
          const getBrand = newData?.find((item) => item.metric === currentBrand);
          setSelectedAverageBrand({name: getBrand?.label, value: getBrand?.metric});
        }
      } catch (e) {
        errorAlert('Unable to get Average Order brand data', e);
      } finally {
        setAverageOrderLoading(false);
      }
    } catch (e) {
      setAverageOrderLoading(false);
      errorAlert('Unable to get Average Order data', e);
    }
  }

  async function getAverageSku(brand, query) {
    setAverageBrandLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/average-order/sku',
        data: {...query, brand},
        sku: true,
      });
      setSelectedAverageBrandData(transformAverageOrderSkuData(data));
    } catch (e) {
      errorAlert('Unable to get Average Order by SKU data!', e);
    } finally {
      setAverageBrandLoading(false);
    }
  }

  async function getPurchaserData(query, currentBrand) {
    setPurchaserLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/purchasers',
        data: query,
      });
      data[0]?.repeating_percentage && setPurchasers(transformPurchaserData(data[0]));
      try {
        const {data} = await InsightsApiCall({
          url: '/purchasers/brand',
          data: query,
        });
        const newData = transformPurchaserBrandData(data, 'brand_code');
        setPurchaserBrandsData(newData);
        if (currentBrand) {
          const getBrand = data?.find((item) => item.brand_code === currentBrand);
          setSelectedPurchaserBrand({name: getBrand?.brand_name, value: getBrand?.brand_code});
        }
      } catch (e) {
        errorAlert('Unable to get Purchasers brand data', e);
      } finally {
        setPurchaserLoading(false);
      }
    } catch (e) {
      setPurchaserLoading(false);
      errorAlert('Unable to get Purchasers data', e);
    }
  }

  async function getPurchaserSku(brand, query) {
    setPurchaserBrandLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/purchasers/sku',
        data: {...query, brand},
        sku: true,
      });
      setPurchaserSKUData(transformPurchaserBrandData(data, 'seller_sku'));
    } catch (e) {
      errorAlert('Unable to get Purchaser by SKU data!', e);
    } finally {
      setPurchaserBrandLoading(false);
    }
  }

  async function getReturnRate(query, currentBrand) {
    setReturnRateLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/return-rate',
        data: query,
      });
      setReturnRate(transformReturnRateData({data: data, complete: true}));
      try {
        const {data: brandsData} = await InsightsApiCall({
          url: '/return-rate/brand',
          data: query,
        });
        setReturnRateBrands({
          returnRate: transformReturnRateData({data: brandsData, complete: true, color: true, brands: true}),
          returnRateChartData: transformReturnRateData({data: brandsData, color: true, total: data[0].units_returned})
            .filter((item) => item.value_porcent >= 0.5)
            .sort((a, b) => b.value - a.value)
            .slice(0, 30),
        });
        if (currentBrand) {
          const getBrand = brandsData.find((item) => item.brand_code === currentBrand);
          setSelectedReturnRateBrand({name: getBrand?.brand_name, value: getBrand?.brand_code});
        }
      } catch (e) {
        errorAlert('Unable to get Return Rate brand data', e);
      } finally {
        setReturnRateLoading(false);
      }
    } catch (e) {
      setReturnRateLoading(false);
      errorAlert('Unable to get Return Rate data', e);
    }
  }

  async function getReturnRateSku(brand, query) {
    setReturnRateBrandLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/return-rate/sku',
        data: {...query, brand},
        sku: true,
      });

      setSelectedReturnRateBrandData({
        returnRate: transformReturnRateData({data: data, complete: true, color: true, brands: true}),
        returnRateChartData: transformReturnRateData({
          data: data,
          color: true,
          total: data?.reduce((acc, curr) => acc + curr.units_returned, 0),
        })
          .filter((item) => item.value >= 1)
          .sort((a, b) => b.value - a.value)
          .slice(0, 30),
      });
    } catch (e) {
      errorAlert('Unable to get Return Rate SKU data!', e);
    } finally {
      setReturnRateBrandLoading(false);
    }
  }
/* 
  async function getTotalGeoData(query, currentBrand) {
    setGeoMapLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/geo',
        data: query,
      });
      setTotalGeoData(data);
      try {
        const {data} = await InsightsApiCall({
          url: '/geo/brand',
          data: query,
        });
        const newData = transformGeoData(data, 'brand');
        setGeoDataByBrands(newData);
        if (currentBrand) {
          const getBrand = newData?.find((item) => item.label === currentBrand);
          setSelectedGeoBrand({name: getBrand?.name, value: getBrand?.label});
        }
      } catch (e) {
        errorAlert('Unable to get Geomap brand data', e);
      } finally {
        setGeoMapLoading(false);
      }
    } catch (e) {
      setGeoMapLoading(false);
      errorAlert('Unable to get Geomap data', e);
    }
  }

  async function getGeoDataBySku(brand, query) {
    setGeoSkuLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/geo/sku',
        data: {...query, brand},
        sku: true,
      });
      setGeoMapDataBySkus(transformGeoData(data, 'sku'));
    } catch (e) {
      errorAlert('Unable to get Geomap SKU data!', e);
    } finally {
      setGeoSkuLoading(false);
    }
  } 
  */

  async function getTotalHeatMapData(query, currentBrand) {
    setHeatmapLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/heatmap',
        data: query,
      });
      setTotalHeatmap({labels: Object.keys(data).map((i: any) => i.substring(0, 3)), data: transformHeatmapData(data)});
      try {
        const {data} = await InsightsApiCall({
          url: '/heatmap/brand',
          data: query,
        });
        const newData = transformHeatmapBrandData(data, 'brand');
        setHeatmapDataByBrands(newData);
        if (currentBrand) {
          const getBrand = newData.find((item) => item.label === currentBrand);
          setSelectedHeatmapBrand({
            name: getBrand?.name,
            value: getBrand?.label,
          });
        }
      } catch (e) {
        errorAlert('Unable to get Heatmap brand data', e);
      } finally {
        setHeatmapLoading(false);
      }
    } catch (e) {
      setHeatmapLoading(false);
      errorAlert('Unable to get Heatmap data', e);
    }
  }

  async function getHeatmapBySku(brand, query) {
    setHeatmapSkuLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/heatmap/sku',
        data: {...query, brand},
        sku: true,
      });
      setHeatmapDataBySkus(transformHeatmapBrandData(data, 'sku'));
    } catch (e) {
      errorAlert('Unable to get Heatmap SKU data!', e);
    } finally {
      setHeatmapSkuLoading(false);
    }
  }

  async function getSubsAndSaveData(query) {
    setSubscriptionLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/sub-and-save',
        data: query,
      });
      data[0]?.percentage && setSubscriptions(transformSubsAndSaveData(data[0]));
      try {
        const {data} = await InsightsApiCall({
          url: '/sub-and-save/brand',
          data: query,
        });
        setSubscriptionBrandChartData(transformSubsAndSaveBrandChartData(data, 'brand_name' || 'brand_code'));
        setSubscriptionBrands(transformSubsAndSaveBrandData(data, 'brand_code'));
      } catch (e) {
        errorAlert('Unable to get Subs and Save brand data', e);
      } finally {
        setSubscriptionLoading(false);
      }
    } catch (e) {
      setSubscriptionLoading(false);
      errorAlert('Unable to get Subs and Save data', e);
    }
  }

  async function getSubAndSaveSku(brand, query) {
    setSubscriptionSkuLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/sub-and-save/sku',
        data: {...query, brand},
        sku: true,
      });
      setSubscriptionSkuChartData(transformSubsAndSaveBrandChartData(data, 'seller_sku'));
      setSubscriptionSkus(transformSubsAndSaveBrandData(data, 'seller_sku'));
    } catch (e) {
      errorAlert('Unable to get Subs Ans Save SKU data!', e);
    } finally {
      setSubscriptionSkuLoading(false);
    }
  }

  async function getPromotionCodeData(query, selectedBrand, selectedSku) {
    setPromoCodesLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/promo-codes',
        data: query,
      });
      setPromoCodes([
        {
          metric: 'Total Promotion Codes Used',
          label: 'Total Promotion Codes Used',
          value: data.reduce((acc, item) => acc + item.count, 0),
          old_value: data.reduce((acc, item) => acc + item.old_count, 0) || null,
          invertedColor: true,
        },
      ]);
      setTotalPromoCodesChartData(transformPromotionCodesData(data));
      if (!selectedBrand && !selectedSku) {
        setPromoCodesChartData(transformPromotionCodesData(data));
      }
      try {
        const {data} = await InsightsApiCall({
          url: '/promo-codes/brand',
          data: query,
        });
        const getData = transformPromotionCodesBrandData(data) as any;
        setPromoCodesBrands(getData?.sort((a, b) => b.value - a.value));
        if (selectedBrand) {
          const currentBrand = getData?.find((item) => item.brand_code === selectedBrand);
          setSelectedPromoCodesBrandData(currentBrand);
          if (!selectedSku) {
            setPromoCodesChartData(currentBrand?.values);
          }
        }
      } catch (e) {
        errorAlert('Unable to get Promotion Codes brand data', e);
      } finally {
        setPromoCodesLoading(false);
      }
    } catch (e) {
      setPromoCodesLoading(false);
      errorAlert('Unable to get Promotion Codes data', e);
    }
  }

  async function getPromotionCodesSkus(brand, query, sku?) {
    setPromoCodesSkuLoading(true);
    try {
      const {data} = await InsightsApiCall({
        url: '/promo-codes/sku',
        data: {...query, brand},
        sku: true,
      });
      const getData = transformPromotionCodesSkuData(data, compare) as any;
      setPromoCodesSkus(getData?.sort((a, b) => b.value - a.value));
      if (brand && sku) {
        const currentSku = getData?.find((item) => item.metric === sku);
        setSelectedPromoCodesSkuData(currentSku);
        setPromoCodesChartData(currentSku?.values);
      }
    } catch (e) {
      errorAlert('Unable to get Promotion Codes SKU data!', e);
    } finally {
      setPromoCodesSkuLoading(false);
    }
  }

  function getInsightsInfo({
    currentFromTo,
    currentSelectedBrands,
    currentSelectedSummaryBrand,
    currentSelectedReturnBy,
    currentSelectedSKU,
    currentSelectedAverageBrand,
    currentSelectedPurchaserBrand,
    currentSelectedReturnRateBrand,
    currentSelectedGeoBrand,
    currentSelectedHeatmapBrand,
    currentSelectedSubscriptionBrand,
    currentSelectedPromoCodesBrand,
    currentSelectedPromoCodesSku,
  }: any) {
    const query = {
      from: currentFromTo.from,
      to: currentFromTo.to,
      brands: currentSelectedBrands,
      compareFrom: currentFromTo.compareFrom,
      compareTo: currentFromTo.compareTo,
    };
    // summary
    getSummary(query, currentSelectedSummaryBrand);
    currentSelectedSummaryBrand && getSummarySku(currentSelectedSummaryBrand, currentFromTo);
    // fba return
    getFBAReturns(query);
    if (currentSelectedReturnBy?.reason) {
      getFBACount({...query, value: currentSelectedReturnBy.reason, returnBy: 'reason'});
    }
    if (currentSelectedReturnBy?.disposition) {
      getFBACount({...query, value: currentSelectedReturnBy.disposition, returnBy: 'disposition'});
    }
    if (currentSelectedSKU?.reason) {
      getFBABySku({from: currentFromTo.from, to: currentFromTo.to, seller_sku: currentSelectedSKU.reason});
    }
    if (currentSelectedSKU?.disposition) {
      getFBABySku({from: currentFromTo.from, to: currentFromTo.to, seller_sku: currentSelectedSKU.disposition});
    }
    // average order value
    getAverageOrder(query, currentSelectedAverageBrand);
    currentSelectedAverageBrand && getAverageSku(currentSelectedAverageBrand, currentFromTo);
    // purchaser
    getPurchaserData(query, currentSelectedPurchaserBrand);
    currentSelectedPurchaserBrand && getPurchaserSku(currentSelectedPurchaserBrand, currentFromTo);
    // return rate
    getReturnRate(query, currentSelectedReturnRateBrand);
    currentSelectedReturnRateBrand && getReturnRateSku(currentSelectedReturnRateBrand, currentFromTo);
    // geomap /* Disabled temparary */
    // getTotalGeoData(query, currentSelectedGeoBrand);
    // currentSelectedGeoBrand && getGeoDataBySku(currentSelectedGeoBrand, currentFromTo);
    // heatmap
    getTotalHeatMapData(query, currentSelectedHeatmapBrand);
    currentSelectedHeatmapBrand && getHeatmapBySku(currentSelectedHeatmapBrand, currentFromTo);
    // subs and save
    getSubsAndSaveData(query);
    currentSelectedSubscriptionBrand && getSubAndSaveSku(currentSelectedSubscriptionBrand, currentFromTo);
    // promo codes
    getPromotionCodeData(query, currentSelectedPromoCodesBrand, currentSelectedPromoCodesSku);
    currentSelectedPromoCodesBrand &&
      getPromotionCodesSkus(currentSelectedPromoCodesBrand, currentFromTo, currentSelectedPromoCodesSku);
  }

  useEffect(() => {
    updateQueryParams(location, params);
  }, [JSON.stringify(params)]);

  useEffect(() => {
    const defaultQuery: any = getDefaultQuery(location, params);
    const brands = defaultQuery.brands?.split(',');
    setFromTo({
      from: defaultQuery.from,
      to: defaultQuery.to,
      compareFrom: defaultQuery.compareFrom,
      compareTo: defaultQuery.compareTo,
    });
    setSelectedBrands(brands || []);
    setCompare(defaultQuery.compareFrom !== null);
    setChecked(defaultQuery.compareFrom !== null);
    setSelectedFbaReturnBy({
      reason: defaultQuery.reason,
      disposition: defaultQuery.disposition,
    });
    setSelectedFbaSKU({reason: defaultQuery.skuReason, disposition: defaultQuery.skuDisposition});
    setSelectedPurchaserSKU(defaultQuery.RP_sku);
    setSelectedGeoSku(defaultQuery.geoSku);
    setSelectedHeatmapSku(defaultQuery.heatmapSku);
    setSelectedSubscriptionBrand(defaultQuery.subsBrand);
    setSelectedPromoCodesBrand(defaultQuery.promoCodesBrand);
    setSelectedPromoCodesSku(defaultQuery.promoCodesSku);
    setDateRange(`${defaultQuery.from} - ${defaultQuery.to}`);
    setFilteredBrands(brands || []);
    getInsightsInfo({
      currentFromTo: {
        from: defaultQuery.from,
        to: defaultQuery.to,
        compareFrom: defaultQuery.compareFrom,
        compareTo: defaultQuery.compareTo,
      },
      currentSelectedBrands: brands || [],
      currentSelectedSummaryBrand: defaultQuery.summaryBrand,
      currentSelectedReturnBy: {
        reason: defaultQuery.reason,
        disposition: defaultQuery.disposition,
      },
      currentSelectedSKU: {
        reason: defaultQuery.skuReason,
        disposition: defaultQuery.skuDisposition,
      },
      currentSelectedAverageBrand: defaultQuery.average_brand,
      currentSelectedPurchaserBrand: defaultQuery.RP_brand,
      currentSelectedReturnRateBrand: defaultQuery.return_rate_brand,
      currentSelectedGeoBrand: defaultQuery.geoBrand,
      currentSelectedHeatmapBrand: defaultQuery.heatmapBrand,
      currentSelectedSubscriptionBrand: defaultQuery.subsBrand,
      currentSelectedPromoCodesBrand: defaultQuery.promoCodesBrand,
      currentSelectedPromoCodesSku: defaultQuery.promoCodesSku,
    });
  }, []);

  useEffect(() => {
    const defaultQuery = getDefaultQuery(location, params);
    if (accountManagers?.length > 0) {
      const AMS = getAccountManagers(defaultQuery, 'am', selectedAM, accountManagers);
      setSelectedAM(AMS);
    }
  }, [accountManagers, location, params, selectedAM]);

  useEffect(() => {
    ['ffp', 'ecom3k'].includes(accountService.userValue.brand_type) &&
      mixPanel({
        eventName: `Performance - Insights`,
      });
  }, []);

  const getFilters = () => {
    const filters = {
      'Date Range': dateRange,
      'Filtered Brands': filteredBrands,
    };
    Object.entries(filters).forEach(([key, value]) => {
      if (!value || value.length === 0) {
        delete filters[key];
      }
    });
    return filters;
  };

  function toggleCompare(value) {
    setChecked(value);
    onFromToChange({
      from: fromTo.from,
      to: fromTo.to,
      cFrom: null,
      cTo: null,
      preset: period,
      compare: value,
      setFromTo: setFromTo,
    });
  }

  const onModelVisibilityChange = (newModel: any) => {
    let data = {};
    Object.entries(newModel).forEach(([x, v]) => {
      if (v === false) {
        data = {...data, [x]: v};
      }
    });
    const newOrder = getColumnsItems({
      list: columns,
      columnsVisibility: data,
      columnsOrder: orderColumns,
      currentOrder: orderColumns,
    });
    setVisibilityModel(data);
    setOrderColumns(newOrder);
    setUserPreferences(
      {
        columnVisibilityModel: data,
        columnsOrder: newOrder.map((x) => x.field),
      },
      'insights-sku',
      setTableLoading
    );
  };

  const DataGridToolbar = () => (
    <GridToolbarContainer className="toolbar-container">
      <GridToolbarColumnsButton className={classes.toolbarButton} disabled={tableLoading} />
      <DropDownButton buttonType="text" buttonText="Order Columns" bold loading={tableLoading}>
        <DraggableList
          items={orderColumns}
          ignoreItems={['actions']}
          setItems={(v) => {
            setOrderColumns(v);
            setUserPreferences(
              {
                columnVisibilityModel: visibilityModel,
                columnsOrder: v.map((x) => x.field),
              },
              'insights-sku',
              setTableLoading
            );
          }}
        />
      </DropDownButton>
      <GridPagination />
    </GridToolbarContainer>
  );

  const exportChartPdf = (id) => {
    const element = document.getElementById(id);
    const options = {
      filename: 'performance-insights.pdf',
      html2canvas: {scale: 4, x: 0, y: 0, backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff'},
      jsPDF: {orientation: 'landscape'},
    };
    html2pdf(element, options);
  };

  const averageListData = selectedAverageBrand ? selectedAverageBrandData : averageBrands;
  const returnRateListData = selectedReturnRateBrand
    ? selectedReturnRateBrandData.returnRate
    : returnRateBrands.returnRate;
  const purchaserCustomListData = selectedPurchaserBrand ? purchaserSKUData : purchaserBrandsData;
  const purchaserCustomChartData = selectedPurchaserSKU
    ? purchaserSKUData.find((d) => d[0].item === selectedPurchaserSKU) || []
    : purchaserBrandsData.find((d) => d[0].item === selectedPurchaserBrand?.value) || [];
  const subscriptionListData = selectedSubscriptionBrand ? subscriptionSkus : subscriptionBrands;
  const subscriptionChartData = selectedSubscriptionBrand ? subscriptionSkuChartData : subscriptionBrandChartData;
  const promoCodeListData = selectedPromoCodesBrand ? promoCodesSkus : promoCodesBrands;

  function onFilterChange() {
    setCompare(checked);
    setDateRange(`${fromTo.from} - ${fromTo.to}`);
    setFilteredBrands(selectedBrands);
    setSelectedFbaSKUData([]);
    setSelectedFbaSKU(null);
    setSelectedAverageBrandData([]);
    setSelectedAverageBrand(null);
    setSelectedPurchaserBrand(null);
    setPurchaserBrandsData([]);
    setSelectedSummaryBrand(null);
    setSummarySkus([]);
    setSelectedGeoBrand(null);
    setSelectedGeoSku(null);
    setGeoMapDataBySkus([]);
    setSelectedHeatmapBrand(null);
    setSelectedHeatmapSku(null);
    setHeatmapDataBySkus([]);
    setSelectedSubscriptionBrand(null);
    setSubscriptionSkus([]);
    setPromoCodesSkus([]);
    setSelectedPromoCodesBrand(null);
    setSelectedPromoCodesSku(null);
    setSelectedPromoCodesBrandData(null);
    setSelectedPromoCodesSkuData(null);
    getInsightsInfo({
      currentFromTo: fromTo,
      currentSelectedBrands: selectedBrands,
      currentSelectedSummaryBrand: null,
      currentSelectedReturnBy: null,
      currentSelectedAverageBrand: null,
      currentSelectedPurchaserBrand: null,
      currentSelectedReturnRateBrand: null,
      currentSelectedGeoBrand: null,
      currentSelectedHeatmapBrand: null,
      currentSelectedSubscriptionBrand: null,
      currentSelectedPromoCodesBrand: null,
    });
  }

  return (
    <TableContainer>
      <DateRangeButtons
        loading={allLoading}
        fromTo={fromTo}
        compare={checked}
        period={period}
        setPeriod={(v) => setPeriod(v)}
        setFromTo={(v) => setFromTo(v)}
        compareOnChange={(e) => toggleCompare(e.target.checked)}
        compareComponent
        pageTitle="Performance Insights"
      />
      <Grid container spacing={1.5} style={{padding: '10px 0 25px 0'}}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Autocomplete
            size="small"
            multiple
            disabled={allLoading}
            options={brands.map((x) => x.brand_code)}
            getOptionLabel={(option) => {
              const brandName = brands.find((x) => x.brand_code === option)?.name;
              return brandName ? `${option} -  ${brandName}` : ` ${option}`;
            }}
            value={selectedBrands}
            onChange={(event, newValue) => {
              setSelectedBrands(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Filter by brands" placeholder="Brands" />
            )}
          />
        </Grid>
        {accountService.userValue.role !== Role.BrandUser && (
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Autocomplete
              size="small"
              multiple
              disabled={allLoading}
              options={accountManagers}
              getOptionLabel={(option) => option.email}
              value={selectedAM}
              onChange={(event, newValue) => {
                const brands = [];
                newValue.forEach((x, i) => {
                  return x.brands.map((brand) => brands.push(brand.brand_code));
                });
                setSelectedBrands(brands);
                setSelectedAM(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Filter by Account Manager"
                  placeholder="Account Manager"
                />
              )}
            />
          </Grid>
        )}

        <Grid item lg={3}>
          <Button
            size="small"
            onClick={() => {
              onFilterChange();
            }}
            disabled={allLoading}
          >
            Apply Filters
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <SalesCard
          id="totalSummary"
          title="Total Summary"
          data={summaries}
          dateInterval={dateRange}
          actionButton={[
            {
              label: 'Export as PDF',
              action: () => exportChartPdf('totalSummary'),
            },
          ]}
          loading={summaryLoading}
          compare={compare}
        />
        <SalesCard
          id="summaryByBrands"
          title={
            <BreadcrumbsComponent
              parent={'Total Summary by Brands'}
              child={selectedSummaryBrand?.name}
              onChange={() => {
                setSelectedSummaryBrand(null);
              }}
              containerId="card-container"
            />
          }
          data={selectedSummaryBrand ? summarySkus : summaryBrands}
          dateInterval={dateRange}
          setSelectedItem={
            selectedSummaryBrand
              ? null
              : (v) => {
                  setSelectedSummaryBrand({
                    name: v.brandName,
                    value: v.brandCode,
                  });
                  getSummarySku(v.brandCode, fromTo);
                }
          }
          actionButton={[
            {
              label: 'Export as PDF',
              action: () => exportChartPdf('summaryByBrands'),
            },
          ]}
          loading={selectedSummaryBrand ? summarySkuLoading : summaryLoading}
          compare={compare}
        />
      </Grid>
      <Spacer height={15} />
      <Grid id="heatmap">
        <Heatmap
          data={totalHeatmap}
          loading={heatmapLoading}
          brandsData={heatmapDataByBrands}
          heatmapBrand={selectedHeatmapBrand}
          getSkuData={(brand) => getHeatmapBySku(brand, fromTo)}
          skuData={heatmapDataBySkus}
          setSkuData={() => setHeatmapDataBySkus([])}
          setHeatmapBrand={(v) => {
            v
              ? setSelectedHeatmapBrand({
                  name: v.name,
                  value: v.label,
                })
              : setSelectedHeatmapBrand(null);
          }}
          heatmapSku={selectedHeatmapSku}
          setHeatmapSku={(v) => setSelectedHeatmapSku(v)}
          compare={compare}
          filters={getFilters()}
          skuLoading={heatmapSkuLoading}
          actionButton={[
            {
              label: 'Export as PDF',
              action: () => exportChartPdf('heatmap'),
            },
          ]}
        />
      </Grid>
      {/* 
      <Spacer height={15} />
      <Grid container id="geomap">
        <GeoMap
          data={totalGeoData}
          compare={compare}
          loading={geoMapLoading}
          brandsData={geoDataByBrands}
          getSkuData={(brand) => getGeoDataBySku(brand, fromTo)}
          skuData={geoMapDataBySkus}
          setSkuData={() => setGeoMapDataBySkus([])}
          skuLoading={geoSkuLoading}
          geoBrand={selectedGeoBrand}
          setGeoBrand={(v) => {
            v ? setSelectedGeoBrand({name: v?.name, value: v?.label}) : setSelectedGeoBrand(null);
          }}
          geoSku={selectedGeoSku}
          setGeoSku={(v) => setSelectedGeoSku(v)}
          filters={getFilters()}
          actionButton={[
            {
              label: 'Export as PDF',
              action: () => exportChartPdf('geomap'),
            },
          ]}
        />
      </Grid>
    */}
      <Spacer height={15} /> 
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <div id="pieChartReason">
            <PieChart
              subChart={selectedFbaReturnBy.reason}
              chartTitle={
                <BreadcrumbsComponent
                  parent={'Return by: Reason'}
                  child={selectedFbaReturnBy?.reason}
                  onChange={() => {
                    setSelectedFbaReturnBy((prev) => ({
                      ...prev,
                      reason: null,
                    }));
                  }}
                />
              }
              selectedItem={selectedFbaReturnBy.reason ? selectedFbaSKU?.reason : selectedFbaReturnBy.reason}
              className={classes.chart}
              listClassName={classes.chartList}
              loading={selectedFbaReturnBy.reason ? fbaChartLoading.reason || fbaLoading : fbaLoading}
              filters={getFilters()}
              data={selectedFbaReturnBy.reason ? reasonChartData : fbaChartData.reason}
              compare={compare}
              getItem={(item) => {
                if (selectedFbaReturnBy.reason) {
                  getFBABySku({from: fromTo.from, to: fromTo.to, seller_sku: item});
                  setSelectedFbaSKU({
                    disposition: null,
                    reason: item,
                  });
                } else {
                  getFBACount({
                    from: fromTo.from,
                    to: fromTo.to,
                    brands: selectedBrands,
                    compareFrom: fromTo.compareFrom,
                    compareTo: fromTo.compareTo,
                    value: item,
                    returnBy: 'reason',
                  });
                  setSelectedFbaReturnBy((prev) => ({
                    ...prev,
                    reason: item,
                  }));
                }
              }}
              actionButton={[
                {
                  label: 'Export as PDF',
                  action: () => exportChartPdf('pieChartReason'),
                },
              ]}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <div id="pieChartDisposition">
            <PieChart
              subChart={selectedFbaReturnBy.disposition}
              chartTitle={
                <BreadcrumbsComponent
                  parent={'Return by: Disposition'}
                  child={selectedFbaReturnBy?.disposition}
                  onChange={() => {
                    setSelectedFbaReturnBy((prev) => ({
                      ...prev,
                      disposition: null,
                    }));
                  }}
                />
              }
              selectedItem={
                selectedFbaReturnBy.disposition ? selectedFbaSKU?.disposition : selectedFbaReturnBy.disposition
              }
              className={classes.chart}
              listClassName={classes.chartList}
              loading={selectedFbaReturnBy.disposition ? fbaChartLoading.disposition || fbaLoading : fbaLoading}
              filters={getFilters()}
              data={selectedFbaReturnBy.disposition ? dispositionChartData : fbaChartData.disposition}
              compare={compare}
              getItem={(item) => {
                if (selectedFbaReturnBy.disposition) {
                  getFBABySku({from: fromTo.from, to: fromTo.to, seller_sku: item});
                  setSelectedFbaSKU({
                    reason: null,
                    disposition: item,
                  });
                } else {
                  getFBACount({
                    from: fromTo.from,
                    to: fromTo.to,
                    brands: selectedBrands,
                    compareFrom: fromTo.compareFrom,
                    compareTo: fromTo.compareTo,
                    value: item,
                    returnBy: 'disposition',
                  });
                  setSelectedFbaReturnBy((prev) => ({
                    ...prev,
                    disposition: item,
                  }));
                }
              }}
              actionButton={[
                {
                  label: 'Export as PDF',
                  action: () => exportChartPdf('pieChartDisposition'),
                },
              ]}
            />
          </div>
        </Grid>
        <OpenModalButton
          modalTitle={`Selected SKU: ${selectedFbaSKU?.reason || selectedFbaSKU?.disposition}`}
          openModal={selectedFbaSKU?.reason || selectedFbaSKU?.disposition}
          onClose={() => {
            setSelectedFbaSKU(null);
            setSelectedFbaSKUData([]);
          }}
          hideButton
          fullWidth
          fullScreen={fullScreen}
          maxWidth={fullScreen ? false : 'xl'}
          modalStyle={classes.chartModal}
          actionButton={
            selectedFbaSKUData && (
              <CSVLink
                filename={`${selectedFbaSKU?.reason || selectedFbaSKU?.disposition} Performance Insights.csv`}
                data={selectedFbaSKUData.map((data) => {
                  const mappedData = {};

                  for (const key of Object.keys(data)) {
                    const header = columns.find((c) => c.field === key);

                    if (header) {
                      mappedData[header.headerName] = data[key];
                    }
                  }

                  return mappedData;
                })}
                headers={columns.map((c) => c.headerName)}
              >
                <Box ml={2}>
                  <Button size="small" disabled={fbaSkuLoading} variant="outlined">
                    Export to CSV
                  </Button>
                </Box>
              </CSVLink>
            )
          }
        >
          <Grid item lg={12} position="relative">
            {fbaSkuLoading && <LinearProgress style={{position: 'absolute', width: '100%'}} />}
            <DataGrid
              className={`${classes.grid} custom-table`}
              components={{
                Pagination: DataGridToolbar,
              }}
              columnBuffer={1}
              columnThreshold={0}
              rowsPerPageOptions={rowsPerPageOptions}
              pageSize={pageSize}
              onPageChange={(page) => {
                setPage(page);
              }}
              onPageSizeChange={(v) => {
                setPageSize(v);
                setPage(0);
              }}
              page={page}
              disableVirtualization
              rowHeight={90}
              columns={orderColumns}
              rows={selectedFbaSKUData}
              autoHeight
              loading={fbaSkuLoading}
              columnVisibilityModel={visibilityModel}
              onColumnVisibilityModelChange={(newModel) => {
                onModelVisibilityChange(newModel);
              }}
            />
          </Grid>
        </OpenModalButton>
      </Grid>
      <Spacer height={15} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <div id="pieChartAverageOrder">
            <ComposedChart
              format={'$'}
              chartTitle={
                <Typography variant="h6" component="h6" color="secondary">
                  Average Order Value
                </Typography>
              }
              dataTitle={
                selectedAverageBrand ? `Selected Brand: ${selectedAverageBrand?.name}` : 'Total Average Order Value'
              }
              secondaryDataTitle={
                <BreadcrumbsComponent
                  parent={'Brands'}
                  child={selectedAverageBrand?.name}
                  onChange={() => {
                    setSelectedAverageBrand(null);
                  }}
                  textTransform="uppercase"
                  fontSize={14}
                />
              }
              chartDescription={
                selectedAverageBrand
                  ? `Average Order Value by SKU - Selected Brand: ${selectedAverageBrand?.name}`
                  : 'Average Order Value by Brands'
              }
              showCount={false}
              className={`${classes.chart} composed-chart`}
              listClassName={classes.chartList}
              loading={averageBrandLoading || averageOrderLoading}
              data={averageOrder}
              showTotal={false}
              filters={getFilters()}
              chartData={averageListData}
              clickable={false}
              compare={compare}
              actionButton={[
                {
                  label: 'Export as PDF',
                  action: () => exportChartPdf('pieChartAverageOrder'),
                },
              ]}
              customList={
                <List className={`${classes.chartList}`}>
                  {averageListData?.length > 0
                    ? averageListData?.map((entry, index) => {
                        return (
                          <ListItem
                            key={index}
                            style={{
                              padding: '4px 0',
                            }}
                            className={`${selectedAverageBrand?.metric === entry.metric ? 'active' : ''} ${
                              !selectedAverageBrand ? 'clickable' : ''
                            }`}
                            onClick={() => {
                              if (!selectedAverageBrand) {
                                setSelectedAverageBrand({
                                  name: entry.label,
                                  value: entry.metric,
                                });
                                getAverageSku(entry.metric, fromTo);
                              }
                            }}
                          >
                            <ListItemText
                              primary={
                                <>
                                  <Typography
                                    component="span"
                                    variant="overline"
                                    style={{textTransform: 'capitalize', color: '#64aada'}}
                                  >
                                    {entry.label || getLabel(entry.metric)}
                                  </Typography>
                                  <Typography component="span" variant="overline">
                                    {entry.value}$
                                  </Typography>
                                </>
                              }
                              secondary={
                                compare && (
                                  <>
                                    <Typography component="span" variant="overline">
                                      {entry.old_value}$ <ArrowRightAlt />
                                      {entry.value}$ ={' '}
                                      {getAmountDiff({
                                        current: parseFloat(entry.value),
                                        old: parseFloat(entry.old_value),
                                        classes: classes,
                                        invertedColor: entry.invertedColor,
                                      })}
                                    </Typography>
                                  </>
                                )
                              }
                            />
                          </ListItem>
                        );
                      })
                    : 'No data found'}
                </List>
              }
            />
          </div>
        </Grid>
      </Grid>
      <Spacer height={15} />
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <div id="pieChartRepeatPurchasers">
            <PieChart
              minHeight={compare ? 900 : 800}
              chartTitle={
                <Typography variant="h6" component="h6" color="secondary">
                  Repeat Purchasers
                </Typography>
              }
              dataTitle={
                selectedPurchaserBrand ? `Selected Brand: ${selectedPurchaserBrand?.name}` : 'Total Purchasers'
              }
              secondaryDataTitle={
                <BreadcrumbsComponent
                  parent={'Brands'}
                  child={selectedPurchaserBrand?.name}
                  onChange={() => {
                    setSelectedPurchaserBrand(null);
                    setSelectedPurchaserSKU(null);
                    setPurchaserSKUData([]);
                  }}
                  textTransform="uppercase"
                  fontSize={14}
                />
              }
              chartDescription={
                selectedPurchaserSKU
                  ? `Selected SKU: ${selectedPurchaserSKU}`
                  : selectedPurchaserBrand
                  ? `Selected Brand: ${selectedPurchaserBrand?.name}`
                  : 'Total Purchasers'
              }
              showCount={false}
              className={classes.chart}
              listClassName={classes.chartList}
              loading={purchaserBrandLoading || purchaserLoading}
              data={
                selectedPurchaserBrand
                  ? purchaserBrandsData?.find((d) => d[0].item === selectedPurchaserBrand.value) || []
                  : purchasers
              }
              showTotal={false}
              filters={getFilters()}
              chartData={selectedPurchaserBrand && purchaserCustomChartData}
              clickable={false}
              compare={compare}
              actionButton={[
                {
                  label: 'Export as PDF',
                  action: () => exportChartPdf('pieChartRepeatPurchasers'),
                },
              ]}
              customList={
                <List className={`${classes.chartList} custom-list`}>
                  {purchaserCustomListData?.map((entry, index) => {
                    return (
                      <ListItem
                        key={index}
                        style={{
                          padding: '4px 0',
                        }}
                        className={`${selectedPurchaserSKU === entry[0].item ? 'active' : ''} clickable`}
                        onClick={() => {
                          if (selectedPurchaserBrand) {
                            setSelectedPurchaserSKU(entry[0].item);
                          } else {
                            setSelectedPurchaserBrand({
                              name: entry[0].label,
                              value: entry[0].item,
                            });
                            getPurchaserSku(entry[0].item, fromTo);
                          }
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              component="span"
                              variant="overline"
                              style={{textTransform: 'capitalize', color: '#64aada'}}
                            >
                              {entry[0].label || getLabel(entry[0].item)}
                            </Typography>
                          }
                          secondary={
                            <List
                              className={`${classes.chartList} custom-list`}
                              style={{display: 'flex', flexDirection: 'column'}}
                            >
                              {entry.map((entry, index) => {
                                return (
                                  <ListItem key={index}>
                                    <ListItemIcon>
                                      <Square style={{borderRadius: '50px', color: entry.color}} />{' '}
                                    </ListItemIcon>

                                    <ListItemText
                                      primary={
                                        <Typography
                                          component="span"
                                          variant="overline"
                                          style={{textTransform: 'capitalize'}}
                                        >
                                          {entry.metric}: {entry.value}% {compare && <br />}
                                          {compare && (
                                            <span>
                                              {entry.old_value}% <ArrowRightAlt /> {entry.value}% ={' '}
                                              {getAmountDiff({
                                                current: parseFloat(entry.value),
                                                old: parseFloat(entry.old_value),
                                                classes: classes,
                                                invertedColor: entry.invertedColor,
                                              })}
                                            </span>
                                          )}
                                        </Typography>
                                      }
                                    />
                                  </ListItem>
                                );
                              })}
                            </List>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <div id="pieChartReturnRate">
            <PieChart
              minHeight={compare ? 900 : 800}
              chartTitle={
                <Typography variant="h6" component="h6" color="secondary">
                  Return Rate
                </Typography>
              }
              dataTitle={selectedReturnRateBrand ? `Selected Brand: ${selectedReturnRateBrand?.name}` : 'Return Rate'}
              secondaryDataTitle={
                <BreadcrumbsComponent
                  parent={'Brands'}
                  child={selectedReturnRateBrand?.name}
                  onChange={() => {
                    setSelectedReturnRateBrand(null);
                    setSelectedReturnRateBrandData([]);
                  }}
                  textTransform="uppercase"
                  fontSize={14}
                />
              }
              chartDescription={
                selectedReturnRateBrand
                  ? `Return Rate by SKU - Selected Brand: ${selectedReturnRateBrand?.name}`
                  : 'Return Rate by Brands'
              }
              showCount={false}
              className={classes.chart}
              listClassName={classes.chartList}
              loading={returnRateBrandLoading || returnRateLoading}
              data={
                selectedReturnRateBrand
                  ? returnRateBrands?.returnRate?.find((d) => d[0].item === selectedReturnRateBrand.value) || []
                  : returnRate
              }
              showTotal={false}
              labelIcon={false}
              filters={getFilters()}
              chartData={
                selectedReturnRateBrand
                  ? selectedReturnRateBrandData.returnRateChartData
                  : returnRateBrands.returnRateChartData
              }
              clickable={false}
              compare={compare}
              actionButton={[
                {
                  label: 'Export as PDF',
                  action: () => exportChartPdf('pieChartReturnRate'),
                },
              ]}
              sortData={false}
              customList={
                <List className={`${classes.chartList} custom-list`}>
                  {returnRateListData?.length > 0
                    ? returnRateListData?.map((entry, index) => {
                        return (
                          <ListItem
                            key={index}
                            style={{
                              padding: '4px 0',
                            }}
                            className={`${selectedReturnRateBrand?.value === entry[0].item ? 'active' : ''} ${
                              !selectedReturnRateBrand && 'clickable'
                            }`}
                            onClick={() => {
                              if (!selectedReturnRateBrand) {
                                setReturnRateBrandLoading(true);
                                setSelectedReturnRateBrand({name: entry[0].label, value: entry[0].item});
                                getReturnRateSku(entry[0].item, fromTo);
                              }
                            }}
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                  <ListItemIcon>
                                    <Square style={{borderRadius: '50px', color: entry[0].color}} />{' '}
                                  </ListItemIcon>
                                  <Typography
                                    component="span"
                                    variant="overline"
                                    style={{textTransform: 'capitalize', color: '#64aada'}}
                                  >
                                    {entry[0].label || getLabel(entry[0].item)}
                                  </Typography>
                                </div>
                              }
                              secondary={
                                <List
                                  className={`${classes.chartList} custom-list`}
                                  style={{display: 'flex', flexDirection: 'column'}}
                                >
                                  {entry.map((entry, index) => {
                                    return (
                                      <ListItem key={index}>
                                        <ListItemText
                                          primary={
                                            <Typography
                                              component="span"
                                              variant="overline"
                                              style={{textTransform: 'capitalize'}}
                                            >
                                              {entry.metric}: {entry.value}
                                              {entry.metric === 'Return Rate' && '%'}
                                              {compare &&
                                                (entry.metric === 'Return Rate' ? (
                                                  <span>
                                                    {parseFloat(entry.old_value).toFixed(2)}
                                                    % <ArrowRightAlt /> {entry.value}% ={' '}
                                                    {getAmountDiff({
                                                      current: parseFloat(entry.value),
                                                      old: parseFloat(entry.old_value),
                                                      classes: classes,
                                                      invertedColor: entry.invertedColor,
                                                    })}
                                                  </span>
                                                ) : (
                                                  <span>
                                                    {entry.old_value}
                                                    <ArrowRightAlt /> {entry.value}={' '}
                                                    {getAmountDiff({
                                                      current: parseFloat(entry.value),
                                                      old: parseFloat(entry.old_value),
                                                      classes: classes,
                                                      invertedColor: entry.invertedColor,
                                                    })}
                                                  </span>
                                                ))}
                                            </Typography>
                                          }
                                        />
                                      </ListItem>
                                    );
                                  })}
                                </List>
                              }
                            />
                          </ListItem>
                        );
                      })
                    : 'No data found'}
                </List>
              }
            />
          </div>
        </Grid>
      </Grid>
      <Spacer height={15} />
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} xl={12}>
          <div id="subscribeAndSave">
            <PieChart
              minHeight={compare ? 900 : 800}
              chartTitle={
                <Typography variant="h6" component="h6" color="secondary">
                  Subscribe And Save
                </Typography>
              }
              dataTitle={
                selectedSubscriptionBrand
                  ? `Selected Brand: ${subscriptionBrands?.find((d) => d.item === selectedSubscriptionBrand)?.label}`
                  : 'Total Subscribe And Save'
              }
              secondaryDataTitle={
                <BreadcrumbsComponent
                  parent={'Brands'}
                  child={subscriptionBrands?.find((d) => d.item === selectedSubscriptionBrand)?.label}
                  onChange={() => {
                    setSelectedSubscriptionBrand(null);
                    setSubscriptionSkus([]);
                  }}
                  textTransform="uppercase"
                  fontSize={14}
                />
              }
              chartDescription={
                selectedSubscriptionBrand
                  ? `Selected Brand: ${subscriptionBrands?.find((d) => d.item === selectedSubscriptionBrand)?.label}`
                  : 'Total by Brands'
              }
              showCount={false}
              className={`${classes.chart} subscription`}
              listClassName={classes.chartList}
              loading={subscriptionLoading || subscriptionSkuLoading}
              data={
                selectedSubscriptionBrand
                  ? subscriptionBrands?.find((d) => d.item === selectedSubscriptionBrand)?.items || []
                  : subscriptions
              }
              showTotal={false}
              filters={getFilters()}
              chartData={subscriptionChartData}
              clickable={false}
              compare={compare}
              actionButton={[
                {
                  label: 'Export as PDF',
                  action: () => exportChartPdf('subscribeAndSave'),
                },
              ]}
              customList={
                <List className={`${classes.chartList} custom-list`}>
                  {subscriptionListData?.length > 0
                    ? subscriptionListData?.map((entry, index) => {
                        return (
                          <ListItem
                            key={index}
                            style={{
                              padding: '4px 0',
                            }}
                            className={`${selectedSubscriptionBrand === entry.item ? 'active' : ''} ${
                              !selectedSubscriptionBrand && 'clickable'
                            }`}
                            onClick={() => {
                              if (!selectedSubscriptionBrand) {
                                setSelectedSubscriptionBrand(entry.item);
                                getSubAndSaveSku(entry.item, fromTo);
                              }
                            }}
                          >
                            <ListItemText
                              disableTypography
                              primary={
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                  <ListItemIcon>
                                    <Square style={{borderRadius: '50px', color: entry.color}} />{' '}
                                  </ListItemIcon>
                                  <Typography
                                    component="span"
                                    variant="overline"
                                    style={{textTransform: 'capitalize', color: '#64aada'}}
                                  >
                                    {entry.label || getLabel(entry.item)}
                                  </Typography>
                                </div>
                              }
                              secondary={
                                <List
                                  className={`${classes.chartList} custom-list`}
                                  style={{display: 'flex', flexDirection: 'column'}}
                                >
                                  {entry.items.map((entry, index) => {
                                    return (
                                      <ListItem key={index}>
                                        <ListItemText
                                          primary={
                                            <Typography
                                              component="span"
                                              variant="overline"
                                              style={{textTransform: 'capitalize'}}
                                            >
                                              {entry.metric}: {entry.value}
                                              {entry.format}
                                              {compare && (
                                                <span>
                                                  {entry.old_value}
                                                  {entry.format}
                                                  <ArrowRightAlt /> {entry.value}
                                                  {entry.format}={' '}
                                                  {getAmountDiff({
                                                    current: parseFloat(entry.value),
                                                    old: parseFloat(entry.old_value),
                                                    classes: classes,
                                                    invertedColor: entry.invertedColor,
                                                  })}
                                                </span>
                                              )}
                                            </Typography>
                                          }
                                        />
                                      </ListItem>
                                    );
                                  })}
                                </List>
                              }
                            />
                          </ListItem>
                        );
                      })
                    : 'No data found'}
                </List>
              }
            />
          </div>
        </Grid>
      </Grid>
      <Spacer height={15} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <div id="promotionCodesChart">
            <ComposedChart
              format={''}
              chartTitle={
                <Typography variant="h6" component="h6" color="secondary">
                  Promotion Codes Used
                </Typography>
              }
              dataTitle={
                selectedPromoCodesSku
                  ? `Selected SKU: ${selectedPromoCodesSkuData?.label}`
                  : selectedPromoCodesBrand
                  ? `Selected Brand: ${selectedPromoCodesBrandData?.label}`
                  : 'Promotion Codes Used'
              }
              secondaryDataTitle={
                <BreadcrumbsComponent
                  parent={'Brands'}
                  child={selectedPromoCodesBrandData?.label}
                  onChange={() => {
                    setSelectedPromoCodesBrand(null);
                    setSelectedPromoCodesSku(null);
                    setSelectedPromoCodesBrandData(null);
                    setSelectedPromoCodesSkuData(null);
                    setPromoCodesChartData(totalPromoCodesChartData);
                  }}
                  textTransform="uppercase"
                  fontSize={14}
                />
              }
              chartDescription={
                selectedPromoCodesSku
                  ? `Promotion Codes Used by Selected SKU: ${selectedPromoCodesSkuData?.label}`
                  : selectedPromoCodesBrand
                  ? `Promotion Codes Used by Selected Brand: ${selectedPromoCodesBrandData?.label}`
                  : 'Promotion Codes Used'
              }
              showCount={false}
              className={`${classes.chart} composed-chart`}
              listClassName={classes.chartList}
              loading={promoCodesLoading || promoCodesSkuLoading}
              data={
                selectedPromoCodesSkuData
                  ? [selectedPromoCodesSkuData]
                  : selectedPromoCodesBrandData
                  ? [selectedPromoCodesBrandData]
                  : promoCodes
              }
              showTotal={false}
              filters={getFilters()}
              chartData={promoCodesChartData}
              clickable={false}
              compare={compare}
              actionButton={[
                {
                  label: 'Export as PDF',
                  action: () => exportChartPdf('promotionCodesChart'),
                },
              ]}
              customList={
                <List className={`${classes.chartList}`}>
                  {promoCodeListData?.length > 0
                    ? promoCodeListData?.map((entry, index) => {
                        return (
                          <ListItem
                            key={index}
                            style={{
                              padding: '4px 0',
                            }}
                            className={`clickable ${
                              (selectedPromoCodesBrand === entry.brand_code ||
                                selectedPromoCodesSku === entry.metric) &&
                              'active'
                            }`}
                            onClick={() => {
                              if (!selectedPromoCodesBrand) {
                                setSelectedPromoCodesBrand(entry.brand_code);
                                setSelectedPromoCodesBrandData(entry);
                                getPromotionCodesSkus(entry.brand_code, fromTo);
                                setPromoCodesChartData(entry.values);
                              } else {
                                setSelectedPromoCodesSku(entry.metric);
                                setSelectedPromoCodesSkuData(entry);
                                setPromoCodesChartData(entry.values);
                              }
                            }}
                          >
                            <ListItemText
                              primary={
                                <>
                                  <Typography
                                    component="span"
                                    variant="overline"
                                    style={{textTransform: 'capitalize', color: '#64aada'}}
                                  >
                                    {entry.label || getLabel(entry.metric)}
                                  </Typography>
                                  <Typography component="span" variant="overline">
                                    {entry.value}
                                  </Typography>
                                </>
                              }
                              secondary={
                                compare &&
                                entry.old_value && (
                                  <>
                                    <Typography component="span" variant="overline">
                                      {entry.old_value} <ArrowRightAlt />
                                      {entry.value} ={' '}
                                      {getAmountDiff({
                                        current: parseFloat(entry.value),
                                        old: parseFloat(entry.old_value),
                                        classes: classes,
                                        invertedColor: entry.invertedColor,
                                      })}
                                    </Typography>
                                  </>
                                )
                              }
                            />
                          </ListItem>
                        );
                      })
                    : 'No data found'}
                </List>
              }
            />
          </div>
        </Grid>
      </Grid>
      <CustomAlert id="default-alert" />
    </TableContainer>
  );
}
