import React, {useState, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import {Close} from '@mui/icons-material';
import {Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Grid} from '@mui/material';
// components
import Button from '../Button/Button';

const Modal = styled(Dialog)(({theme}) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.background.default,
  },
  '& .MuiDialogContent-root': {
    padding: '30px!important',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingRight: '50px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
      whiteSpace: 'break-spaces',
    },
  },
  '& .MuiListItem-root': {
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces',
  },
  '& .modal-header': {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    '& button': {
      marginRight: '20px',
      fontSize: '12px',
      fontWeight: 'bold',
      background: '#FFFFFF',
    },
  },
}));

interface OpenModalButtonProps {
  modalTitle?: string;
  modalStyle?: string;
  children: JSX.Element | ((closeModal: () => void) => JSX.Element);
  buttonText?: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  buttonStyle?: string;
  buttonSize?: any;
  disable?: boolean;
  block?: boolean;
  tooltipTitle?: string;
  tooltipZIndex?: number;
  primary?: any;
  onClick?: () => void;
  onClose?: any;
  closed?: boolean;
  loading?: boolean;
  closable?: boolean;
  actions?: JSX.Element | ((closeModal: () => void) => JSX.Element);
  hideButton?: boolean;
  onCloseText?: string;
  onCloseAction?: () => void;
  openModal?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  fullWidth?: boolean;
  fullScreen?: boolean;
  actionButton?: any;
  buttonType?: 'button' | 'icon';
}

export default function OpenModalButton({
  modalTitle,
  modalStyle,
  children,
  buttonText,
  startIcon,
  endIcon,
  buttonStyle,
  buttonSize,
  disable,
  block,
  tooltipTitle,
  tooltipZIndex = 3,
  primary = 'primary',
  onClick,
  onClose,
  closed = false,
  loading,
  closable,
  actions,
  hideButton,
  onCloseText,
  onCloseAction,
  openModal,
  maxWidth,
  fullWidth,
  fullScreen,
  actionButton,
  buttonType = 'button',
}: OpenModalButtonProps): JSX.Element {
  const [modalVisible, setModalVisible] = useState(false);

  function closeModal(): void {
    setModalVisible(false);
    onCloseAction && onCloseAction();
    onClose && onClose();
  }

  useEffect(() => {
    if (openModal) {
      setModalVisible(true);
    }
  }, [openModal]);

  let content = children;
  let actionsContent = actions;

  if (typeof children === 'function') {
    content = children(closeModal);
  }

  if (typeof actions === 'function') {
    actionsContent = actions(closeModal);
  }

  return (
    <>
      <Modal
        className={modalStyle}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        onClose={closeModal}
        open={modalVisible}
        style={{width: '100%'}}
        aria-labelledby="alert-dialog-title2"
        aria-describedby="alert-dialog-description2"
      >
        <DialogTitle id="alert-dialog-title-2">
          <Grid item lg={12} className="modal-header">
            {modalTitle} {actionButton}
          </Grid>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        {actions && (
          <DialogActions>
            {closable && (
              <Button
                disabled={loading}
                onClick={() => {
                  closeModal();
                }}
              >
                {onCloseText}
              </Button>
            )}
            {actions && actionsContent}
          </DialogActions>
        )}
      </Modal>
      {!hideButton && (
        <>
          {buttonType === 'button' ? (
            <Button
              disabled={disable ? true : false}
              className={buttonStyle}
              startIcon={startIcon}
              endIcon={endIcon}
              type={primary}
              size={buttonSize}
              onClick={(e): void => {
                e.stopPropagation();
                setModalVisible(true);
                onClick && onClick();
              }}
              loading={loading}
            >
              {buttonText}
            </Button>
          ) : (
            <IconButton
              disabled={disable ? true : false}
              className={buttonStyle}
              type={primary}
              size={buttonSize}
              onClick={(e): void => {
                e.stopPropagation();
                setModalVisible(true);
                onClick && onClick();
              }}
            >
              {startIcon}
            </IconButton>
          )}
        </>
      )}
    </>
  );
}
